/** @jsxImportSource @emotion/react */
import { useState } from 'react';

import { colors, preset } from 'styles';

const FloatLabelContainer = ({
  label,
  floating = false,
  children,
  defaultStyle = {},
  floatingStyle = {},
}) => {
  const [focus, setFocus] = useState(false);

  const isFloating = focus || floating;

  return (
    <div
      role="presentation"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
      css={{
        position: 'relative',
        '> label': {
          position: 'absolute',
          zIndex: 2,
          top: preset.spacing(1),
          left: preset.spacing(1),
          right: preset.spacing(5),
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          color: colors.typography.secondary,
          pointerEvents: 'none',
          paddingLeft: 2,
          paddingRight: 2,
          backgroundColor: 'transparent',
          fontSize: 14,
          lineHeight: '16px',
          transition: 'all 200ms',
          ...(isFloating
            ? {
                top: -preset.spacing(1),
                right: 'auto',
                maxWidth: `calc(100% - ${preset.spacing(2)}px)`,
                backgroundColor: '#fff',
                fontSize: 12,
                lineHeight: '12px',
                ...floatingStyle,
              }
            : defaultStyle),
        },
      }}
    >
      <label>{label}</label>
      {children}
    </div>
  );
};

export default FloatLabelContainer;
