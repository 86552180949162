/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';

import { Col, Form } from 'antd';

import { getFilterInputGroupConfig } from '@modules/filterBarHelpers';
import formatLabel from '@modules/formatLabel';

import FilterValueField from './FilterValueField';
import FloatLabelContainer from '../../FloatLabelContainer';

const CustomClassifierFilterFields = ({
    customClassifierFields = [],
    moreFilters = [],
    documentFilterOptions = {},
    loading = false,
    onChange,
}) => {
    const fields = useMemo(() => {
        return customClassifierFields.map(customClassifierField => {
            const { name } = customClassifierField;
            // Get field config
            const { formItemType, displayName } = getFilterInputGroupConfig(customClassifierField);
            // Get select options
            const valueOptions = documentFilterOptions[name] || [];

            const defaultCustomClassifierFilterField = {
                attribute: name,
                condition: '=',
                value: [],
                getNewFilters: val => [
                    ...moreFilters,
                    { attribute: name, condition: '=', value: val },
                ],
                config: {
                    formItemType,
                    valueOptions,
                    attributeDisplay: displayName,
                    selectProps: {
                        showArrow: true,
                        dropdownMatchSelectWidth: false,
                        maxTagCount: 1,
                        maxTagTextLength: 28,
                        placeholder: '',
                    },
                },
            };

            // Look for custom classifier filter in moreFilters
            const customClassifierFilterIndex = moreFilters.findIndex(moreFilter => (
                moreFilter.attribute === name && moreFilter.condition === '='
            ));
            if (customClassifierFilterIndex > -1) {
                const customClassifierFilter = moreFilters[customClassifierFilterIndex];
                const filterValue = moreFilters[customClassifierFilterIndex].value;
                const filterValueLength = Array.isArray(filterValue) ? filterValue.length : 0;

                return {
                    ...defaultCustomClassifierFilterField,
                    value: filterValue,
                    getNewFilters: val => [
                        ...moreFilters.slice(0, customClassifierFilterIndex),
                        {
                            ...customClassifierFilter,
                            value: val,
                        },
                        ...moreFilters.slice(customClassifierFilterIndex + 1),
                    ],
                    config: {
                        ...defaultCustomClassifierFilterField.config,
                        selectProps: {
                            ...defaultCustomClassifierFilterField.config.selectProps,
                            ...filterValueLength > 1 ? {
                                maxTagTextLength: 20,
                            } : {},
                        },
                    },
                };
            }

            return defaultCustomClassifierFilterField;
        });
    }, [customClassifierFields, moreFilters, documentFilterOptions]);

    return fields.length > 0 && (fields.map(customClassifierFilterField => (
        <Col
            span={6}
            key={customClassifierFilterField.attribute}
        >
            <Form.Item>
                <FloatLabelContainer
                    label={customClassifierFilterField.config.attributeDisplay
                        || formatLabel(customClassifierFilterField.attribute)}
                    floating={customClassifierFilterField.value.length > 0}
                >
                    <FilterValueField
                        loading={loading}
                        {...customClassifierFilterField}
                        onChange={val => onChange(
                            customClassifierFilterField.getNewFilters(val),
                        )}
                    />
                </FloatLabelContainer>
            </Form.Item>
        </Col>
    )));
};

export default CustomClassifierFilterFields;
