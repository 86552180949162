import { combineReducers } from 'redux';
import drilldown from './reducers/drilldown';
import chartDownload from './reducers/chartDownload';
import dashboard from './reducers/dashboard';

const rootReducer = combineReducers({
    // new redux states
    dashboard,
    drilldown,
    chartDownload,
});

export default rootReducer;
