/** @jsxImportSource @emotion/react */
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';

import { trackEvent } from 'analytics';

const Instagram = ({ embedMaxWidth, link }) => {
  const [html, setHtml] = useState('');
  const [loading, setLoading] = useState(true);

  const insEmbedContainer = useRef();

  useEffect(() => {
    const fetchInsEmbed = async () => {
      const url = new URL(link);
      url.protocol = 'http';
      url.hostname = 'instagr.am';
      const safeLink = url.href;

      try {
        const res = await axios.get('https://api.instagram.com/oembed', {
          params: {
            url: safeLink,
            omitscript: true,
            maxwidth: embedMaxWidth < 320 ? 320 : embedMaxWidth,
          },
          headers: {
            Accept: 'application/json',
          },
        });

        const embedHtml = res.data.html;

        setHtml(embedHtml);
        if (window.instgrm) {
          window.instgrm.Embeds.process();
        }
      } catch (error) {
        setHtml(`<a href='${safeLink}' target="_blank">${safeLink}</a>`);
      }

      setLoading(false);
    };

    fetchInsEmbed();
  }, [embedMaxWidth, link]);

  // Track Ins post click-through
  useEffect(() => {
    const insClickTrack = () => {
      if (
        insEmbedContainer.current &&
        insEmbedContainer.current.childNodes[0]
      ) {
        if (
          document.activeElement.id ===
          insEmbedContainer.current.childNodes[0].id
        ) {
          trackEvent('Explore Instagram Post Click', { postLink: link });
        }
      }
    };

    window.addEventListener('blur', insClickTrack);

    return () => {
      window.removeEventListener('blur', insClickTrack);
    };
  }, [link]);

  const createMarkup = (markupHtml) => {
    return { __html: markupHtml };
  };

  return (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <div
          // eslint-disable-next-line
          dangerouslySetInnerHTML={createMarkup(html)}
          ref={insEmbedContainer}
        />
      )}
    </div>
  );
};

export default Instagram;
