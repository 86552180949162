import { preset } from 'styles';

const getChartFloatBoxPos = (boxPos = {}, chartElement) => {
  const {
    x: boxX, // Drilldown click coord X relative to chart
    y: boxY, // Drilldown click coord Y relative to chart
    w: boxW, // Drilldown box width
    h: boxH, // Drilldown box height
  } = boxPos;

  // Initialise box position
  let positionObj = {
    position: 'fixed',
    zIndex: 1,
    width: boxW,
    height: boxH,
    top: 'auto',
    right: 'auto',
    bottom: 'auto',
    left: 'auto',
  };

  if (!chartElement) {
    return positionObj;
  }

  const {
    x: chartX = 0, // Chart coord X relative to window
    y: chartY = 0, // Chart coord Y relative to window
  } = chartElement.getBoundingClientRect();

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  // If drilldown overflows the right side of browser, drilldown placement should be towards left; othersie, towards right
  if (chartX + boxX + boxW + preset.spacing(3) > windowWidth) {
    positionObj = {
      ...positionObj,
      left: 'auto',
      right: windowWidth - chartX - boxX,
    };
  } else {
    positionObj = {
      ...positionObj,
      left: chartX + boxX,
      right: 'auto',
    };
  }

  // If drilldown overflows the bottom side of browser, drilldown placement should be towards top; othersie, towards bottom
  if (chartY + boxY + boxH + preset.spacing(3) > windowHeight) {
    positionObj = {
      ...positionObj,
      top: 'auto',
      bottom: windowHeight - chartY - boxY,
    };
  } else {
    positionObj = {
      ...positionObj,
      top: chartY + boxY,
      bottom: 'auto',
    };
  }

  return positionObj;
};

export default getChartFloatBoxPos;
