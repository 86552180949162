import deepGet from '../deepGet';
import isNumber from '../lib/isNumber';

const getXAxisLabelConfig = config => {
    const axisLabelOffset = deepGet(config, ['label', 'offset']);
    const axisLabelFontSize = deepGet(config, ['label', 'fontSize']);
    const axisLabelRotate = deepGet(config, ['label', 'rotate']);

    return {
        ...isNumber(axisLabelOffset) ? { offset: axisLabelOffset } : {},
        ...isNumber(axisLabelRotate) ? { rotate: axisLabelRotate } : {},
        ...isNumber(axisLabelFontSize) ? {
            textStyle: { fontSize: `${axisLabelFontSize}` },
        } : {},
        autoHide: true,
    };
};

export default getXAxisLabelConfig;
