/** @jsxImportSource @emotion/react */
import { useContext, useEffect } from 'react';
import { Select, Tooltip } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import { useAuth } from '@otso/auth-wrapper';

import { DatasetsContext } from '@providers/DatasetsProvider';
import { getApi } from '@modules/api';

import UsageBar from './usageBar';

const { Option } = Select;

const storeFormat = 'YYYY-MM-DD';
const displayFormat = 'MMM YYYY';

const DocumentUsage = () => {
  const {
    datasetsUsage,
    usageLoading,
    setDatasetsUsage,
    setUsageLoading,
    month,
    setMonth,
  } = useContext(DatasetsContext);

  const { currentOrg } = useAuth();
  const { config } = currentOrg || {};
  const { trial } = config || {};

  useEffect(() => {
    const getUsage = async () => {
      setUsageLoading(true);
      const { data } = await getApi('datasets/usage', { month });
      setDatasetsUsage({ ...datasetsUsage, [month]: data });
      setUsageLoading(false);
    };

    if (!datasetsUsage[month]) {
      getUsage();
    }
  }, [datasetsUsage, month, setDatasetsUsage, setUsageLoading]);

  const { total } = datasetsUsage[month] || {};

  const documentMonthlySoftCap = config ? config.documentMonthlySoftCap : 0;
  const unenrichedDocumentMonthlySoftcap =
    config && config.unenrichedDocumentMonthlySoftcap
      ? config.unenrichedDocumentMonthlySoftcap
      : documentMonthlySoftCap;

  if (usageLoading || !total) {
    return (
      <span>
        <SyncOutlined spin css={{ margin: '0 10px' }} />
        Calculating total document usage
      </span>
    );
  }

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        margin: '15px 0',
      }}
    >
      <div>
        <b>Document Usage:</b>
      </div>
      {trial ? (
        <div
          css={{
            width: '300px',
            margin: '0px 20px',
          }}
        >
          <Tooltip title="Trial document usage" placement="top">
            <div>
              <UsageBar
                color
                type="absolute"
                current={total.total}
                cap={documentMonthlySoftCap}
                loading={usageLoading}
              />
            </div>
          </Tooltip>
        </div>
      ) : (
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Select
            css={{ width: 120, margin: '0 20px' }}
            onChange={(value) => setMonth(value)}
            value={moment(month, storeFormat).format(displayFormat)}
          >
            {[0, 1, 2, 3, 4, 5].map((minus) => {
              const optionMonth = moment()
                .subtract(minus, 'month')
                .startOf('month');
              return (
                <Option key={minus} value={optionMonth.format(storeFormat)}>
                  {optionMonth.format(displayFormat)}
                </Option>
              );
            })}
          </Select>
          <div css={{ width: '300px' }}>
            <Tooltip title="Enriched document usage" placement="top">
              <div>
                <UsageBar
                  color
                  type="absolute"
                  current={total.enriched}
                  cap={documentMonthlySoftCap}
                  loading={usageLoading}
                />
              </div>
            </Tooltip>
            <Tooltip title="Un-enriched document usage" placement="bottom">
              <div>
                <UsageBar
                  color
                  type="absolute"
                  current={total.unenriched}
                  cap={unenrichedDocumentMonthlySoftcap}
                  loading={usageLoading}
                />
              </div>
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentUsage;
