/** @jsxImportSource @emotion/react */

// Import libraries
import { useState, useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useAuth } from '@otso/auth-wrapper';

// Import Ant Design components
import { Badge, Button, Tooltip, Table, Input, Tag } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';

// Import stylesheets
import { preset } from 'styles';

// Import modules
import advancedSearch from '@modules/advancedSearch';
import { formatDate } from '@modules/date/formatDate';
import deepGet from '@modules/deepGet';
import Container from '@components/Container';
import TitleSubTitle from '@components/TitleSubTitle';
// Import forms
import { DatasetsContext } from '@providers/DatasetsProvider';

// Import components
import { formatDateInTZ } from 'modules';
import UsageBar from './components/usageBar';
import DocumentUsage from './components/documentUsage';

// Import additional Ant Design components
const { Search } = Input;

const Datasets = (props) => {
  // Extract values from context
  const {
    datasets,
    loadingDatasets: loading,
    datasetsUsage,
    usageLoading,
    month,
  } = useContext(DatasetsContext);

  // Extract and check organisation trial status
  const { currentOrg } = useAuth();
  const { config } = currentOrg || {};
  const { trial, documentMonthlySoftCap = 0 } = config || {};
  const { unenrichedDocumentMonthlySoftcap = documentMonthlySoftCap } =
    config || {};

  // Initialisation
  const history = useHistory();
  const [searchText, setSearchText] = useState(null);

  const filteredDatasetArray = advancedSearch(
    datasets.map((dataset) => {
      return {
        ...dataset,
        usage: deepGet(datasetsUsage, [month, 'datasets', dataset.id]) || {},
      };
    }),
    searchText,
    {
      name: 'name',
      desc: 'description',
      description: 'description',
      type: 'uploadType',
      uploadType: 'uploadType',
      status: 'status',
    }
  );

  // Prepare columns
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 230,
      sorter: (currentItem, nextItem) => {
        const currentName = currentItem.name && currentItem.name.toLowerCase();
        const nextName = nextItem.name && nextItem.name.toLowerCase();
        return currentName < nextName ? -1 : 1;
      },
      render: (text, row) => (
        <span>
          <NavLink to={`datasets/${row.id}`}>{row.name}</NavLink>
          {row.integrationMeta && row.integrationMeta.isGlobal && (
            <Tooltip title="Global Dataset">
              {' '}
              <GlobalOutlined />
            </Tooltip>
          )}
        </span>
      ),
      filters: [
        { text: 'Global', value: 'global' },
        { text: 'Local', value: 'local' },
      ],
      onFilter: (value, record) => {
        if (value === 'global') return record.integrationMeta.isGlobal;
        if (value === 'local') return !record.integrationMeta.isGlobal;
        return true;
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: 230,
      sorter: (currentItem, nextItem) => {
        const currentDescription =
          currentItem.description && currentItem.description.toLowerCase();
        const nextDescription =
          nextItem.description && nextItem.description.toLowerCase();
        return currentDescription < nextDescription ? -1 : 1;
      },
    },
    {
      title: 'Upload Type',
      dataIndex: 'integrationMeta',
      width: 150,
      sorter: (currentItem, nextItem) => {
        const currentType =
          currentItem.integrationMeta &&
          currentItem.integrationMeta.type &&
          currentItem.integrationMeta.type.toLowerCase();
        const nextType =
          nextItem.integrationMeta &&
          nextItem.integrationMeta.type &&
          nextItem.integrationMeta.type.toLowerCase();
        return currentType < nextType ? -1 : 1;
      },
      render: (integrationMeta) => integrationMeta.type,
      filters: [
        { text: 'Meltwater', value: 'meltwater' },
        { text: 'Reddit', value: 'reddit' },
        { text: 'Facebook', value: 'facebook' },
        { text: 'File Upload', value: 'custom' },
      ],
      onFilter: (value, record) => {
        return value ? record.integrationMeta.type === value : true;
      },
    },
    ...(currentOrg
      ? [
          {
            title: 'Document Usage',
            dataIndex: 'usage',
            render: (text, row) => {
              return (
                <div
                  css={{
                    '.ant-progress-show-info .ant-progress-outer': {
                      paddingRight: 'calc(6em + 8px)',
                      marginRight: 'calc(-6em - 8px)',
                    },
                  }}
                >
                  {!trial ? (
                    <div>
                      <Tooltip title="Enriched document usage" placement="top">
                        <div>
                          <UsageBar
                            color={false}
                            current={row.usage.enriched}
                            cap={documentMonthlySoftCap}
                            loading={usageLoading}
                          />
                        </div>
                      </Tooltip>
                      <Tooltip
                        title="Un-enriched document usage"
                        placement="bottom"
                      >
                        <div>
                          <UsageBar
                            color={false}
                            current={row.usage.unenriched}
                            cap={unenrichedDocumentMonthlySoftcap}
                            loading={usageLoading}
                          />
                        </div>
                      </Tooltip>
                    </div>
                  ) : (
                    <Tooltip
                      title="Percentage of trial document usage (enriched and unenriched)"
                      placement="top"
                    >
                      <div>
                        <UsageBar
                          color={false}
                          current={row.usage.enriched + row.usage.unenriched}
                          cap={documentMonthlySoftCap}
                          loading={usageLoading}
                        />
                      </div>
                    </Tooltip>
                  )}
                </div>
              );
            },
            sorter: (currentItem, nextItem) => {
              return currentItem.usage.enriched < nextItem.usage.enriched
                ? -1
                : 1;
            },
          },
        ]
      : []),
    {
      title: 'Enrichment',
      dataIndex: 'enrichmentId',
      width: 150,
      render: (enrichmentId, row) => {
        if (row.integrationMeta.isGlobal) {
          return (
            <Tag color={row.source ? 'green' : 'red'}>
              {row.source ? 'Enriching' : 'Not Enriching'}
            </Tag>
          );
        }
        return (
          <Tag color={enrichmentId ? 'green' : 'red'}>
            {enrichmentId ? 'Enriching' : 'Not Enriching'}
          </Tag>
        );
      },
      filters: [
        { text: 'Enriching', value: 'enriching' },
        { text: 'Not enriching', value: 'notEnriching' },
      ],
      onFilter: (value, record) => {
        if (value === 'enriching') return !!record.enrichmentId;
        if (value === 'notEnriching') return !record.enrichmentId;
        return true;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 125,
      render: (text, row) => {
        if (row.status === 'archived') {
          return <Badge status="error" text="Archived" />;
        }
        return <Badge status="success" text="Active" />;
      },
      sorter: (currentItem, nextItem) => {
        return currentItem.status.toLowerCase() < nextItem.status.toLowerCase()
          ? -1
          : 1;
      },
      filters: [
        { text: 'Active', value: 'active' },
        { text: 'Archived', value: 'archived' },
      ],
      defaultFilteredValue: ['active'],
      onFilter: (value, record) => {
        return record.status ? record.status === value : true;
      },
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      width: 150,
      render: (text, row) => {
        return (
          <Tooltip title={formatDateInTZ(row.createdAt, 'second', '/')}>
            <span>{formatDateInTZ(row.createdAt, 'day', '/')}</span>
          </Tooltip>
        );
      },
      sorter: (currentItem, nextItem) => {
        return currentItem.createdAt < nextItem.createdAt ? -1 : 1;
      },
      defaultSortOrder: 'descend',
    },
    {
      title: 'Data Ingested',
      datasetIndex: 'dataIngestedAt',
      width: 150,
      render: (text, row) => {
        return (
          <Tooltip title={formatDateInTZ(row.dataIngestedAt, 'second', '/')}>
            <span>{formatDateInTZ(row.dataIngestedAt, 'day', '/')}</span>
          </Tooltip>
        );
      },
      sorter: (currentItem, nextItem, sortOrder) => {
        if (currentItem.dataIngestedAt && nextItem.dataIngestedAt) {
          return currentItem.dataIngestedAt < nextItem.dataIngestedAt ? -1 : 1;
        }

        if (currentItem.dataIngestedAt) return sortOrder === 'ascend' ? -1 : 1;
        if (nextItem.dataIngestedAt) return sortOrder === 'ascend' ? 1 : -1;
        return 0;
      },
    },
  ];

  return (
    <Container>
      <div
        css={{
          paddingTop: preset.spacing(2),
          paddingBottom: preset.spacing(2),
        }}
      >
        <TitleSubTitle
          title="Dataset"
          subtitle={`${datasets.length} ${
            datasets.length === 1 ? 'Dataset' : 'Datasets'
          }`}
        />
      </div>
      <div
        css={{
          marginTop: 12,
          marginBottom: preset.spacing(3),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Search
          placeholder="Search Datasets"
          onSearch={(value) => setSearchText(value)}
          css={{
            flex: 1,
            marginRight: preset.spacing(5),
            maxWidth: 500,
          }}
        />

        <Tooltip
          title={
            trial
              ? 'Maximum of one dataset can be created during your trial'
              : null
          }
        >
          <Button
            disabled={trial && datasets.length >= 2}
            type="primary"
            onClick={() => history.push('datasets/create')}
          >
            Create Dataset
          </Button>
        </Tooltip>
      </div>

      {currentOrg && <DocumentUsage />}

      <Table
        locale={{ emptyText: 'Create your first Dataset here' }}
        loading={loading}
        columns={columns}
        dataSource={filteredDatasetArray}
        rowKey="id"
        scroll={props.scroll ? { x: props.scroll } : undefined}
        pagination={{ position: 'bottom' }}
      />
    </Container>
  );
};

export default Datasets;
