export const initialState = {
  name: '',
  collaborators: [],
  datasets: [],
  filters: {},
};

export const createProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PROJECT_FORM':
      return {
        ...state,
        [action.key]: action.value,
      };

    default:
      return state;
  }
};
