/* eslint-disable camelcase */
/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import { Divider, Descriptions } from 'antd';

import ExploreCard from '@components/ExploreCard';
import CXToolTip from '@components/CXToolTip';
import DocLabelTag from '@components/DocLabelTag';

const DialogueDocumentRatings = props => {
    const {
        agentOtherEmotions,
        agentPrimaryEmotion,
        agentSentimentLabel,
        customerOtherEmotions,
        customerPrimaryEmotion,
        customerSentimentLabel,
    } = props;

    const notDetected = (
        <span>
            Not Detected
        </span>
    );

    const actualAgentOtherEmotions = agentOtherEmotions ? agentOtherEmotions.filter(emotion => (
        emotion !== agentPrimaryEmotion
    )) : [];

    const actualCustomerOtherEmotions = customerOtherEmotions ? customerOtherEmotions.filter(emotion => (
        emotion !== customerPrimaryEmotion
    )) : [];

    return (
        <ExploreCard title="Document Ratings">
            <div
                css={{
                    fontWeight: 'bold',
                    '.ant-descriptions-item-content': { float: 'right' },
                    '.ant-descriptions-item-label': {
                        ':after': { content: 'none' },
                        marginRight: 30,
                    },
                }}
            >
                <Descriptions column={1}>
                    <Descriptions.Item
                        label={(
                            <CXToolTip
                                text="Agent Primary Emotion"
                                placement="rightTop"
                                tip="The emotion with the highest confidence score above the minimum threshold, detected at a document level."
                            />
                        )}
                    >
                        {agentPrimaryEmotion
                            ? (
                                <DocLabelTag
                                    value={agentPrimaryEmotion}
                                    name="emotions"
                                />
                            ) : notDetected}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={(
                            <CXToolTip
                                text="Agent Other Emotions"
                                placement="rightTop"
                                tip="Other emotions with a confidence score above the minimum threshold, detected at a document level."
                            />
                        )}
                    >
                        {actualAgentOtherEmotions.length > 0
                            ? actualAgentOtherEmotions.map(emotion => (
                                <DocLabelTag
                                    key={emotion}
                                    value={emotion}
                                    name="emotions"
                                />
                            )) : notDetected}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={(
                            <CXToolTip
                                text="Agent Sentiment"
                                placement="rightTop"
                                tip="The overall sentiment of the document on a scale from -1 (negative) to 1 (positive)."
                            />
                        )}
                    >
                        {agentSentimentLabel
                            ? (
                                <DocLabelTag
                                    value={agentSentimentLabel}
                                    name="sentiment"
                                />
                            ) : notDetected}
                    </Descriptions.Item>
                </Descriptions>

                <Divider />

                <Descriptions column={1}>
                    <Descriptions.Item
                        label={(
                            <CXToolTip
                                text="Customer Primary Emotion"
                                placement="rightTop"
                                tip="The emotion with the highest confidence score above the minimum threshold, detected at a document level."
                            />
                        )}
                    >
                        {customerPrimaryEmotion
                            ? (
                                <DocLabelTag
                                    value={customerPrimaryEmotion}
                                    name="emotions"
                                />
                            ) : notDetected}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={(
                            <CXToolTip
                                text="Customer Other Emotions"
                                placement="rightTop"
                                tip="Other emotions with a confidence score above the minimum threshold, detected at a document level."
                            />
                        )}
                    >
                        {actualCustomerOtherEmotions.length > 0
                            ? actualCustomerOtherEmotions.map(emotion => (
                                <DocLabelTag
                                    key={emotion}
                                    value={emotion}
                                    name="emotions"
                                />
                            )) : notDetected}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={(
                            <CXToolTip
                                text="Customer Sentiment"
                                placement="rightTop"
                                tip="The overall sentiment of the document on a scale from -1 (negative) to 1 (positive)."
                            />
                        )}
                    >
                        {customerSentimentLabel
                            ? (
                                <DocLabelTag
                                    value={customerSentimentLabel}
                                    name="sentiment"
                                />
                            ) : notDetected}
                    </Descriptions.Item>
                </Descriptions>
            </div>
        </ExploreCard>
    );
};

DialogueDocumentRatings.propTypes = {
    agentPrimaryEmotion: PropTypes.string,
    agentOtherEmotions: PropTypes.arrayOf(PropTypes.string),
    agentSentimentLabel: PropTypes.string,
    customerPrimaryEmotion: PropTypes.string,
    customerOtherEmotions: PropTypes.arrayOf(PropTypes.string),
    customerSentimentLabel: PropTypes.string,
};

DialogueDocumentRatings.defaultProps = {
    agentPrimaryEmotion: null,
    agentOtherEmotions: [],
    agentSentimentLabel: null,
    customerPrimaryEmotion: null,
    customerOtherEmotions: [],
    customerSentimentLabel: null,
};

export default DialogueDocumentRatings;
