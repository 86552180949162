/** @jsxImportSource @emotion/react */
import { Legend } from 'bizcharts';

const LegendConfig = ({ config = {} }) => {
    const legendDisplay = config.legend ? config.legend.display : true;
    const legendPosition = config.legend ? config.legend.config.position : 'bottom';

    return (legendDisplay && (
        <Legend position={legendPosition} />
    ));
};

export default LegendConfig;
