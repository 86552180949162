/** @jsxImportSource @emotion/react */
import { useState, useMemo, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import { Tooltip, message, Popconfirm } from 'antd';
import {
  StarOutlined,
  PlusOutlined,
  SaveOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { preset } from 'styles';

import { transformLegacyDashboardFilters } from '@modules/filterBarHelpers';
import { putApi, deleteApi, fetchApiErrorMessage } from '@modules/api';
import { queryStringToObject } from '@modules/queryStringConverter';
import { formatEmptyObject } from '@modules/items';
import FilterBar from '@components/FilterBar';
import useCheckPermission from '@modules/hooks/useCheckPermission';
import NewSavedFilterNameModal from './NewSavedFilterNameModal';

const mapStateToProps = (state) => {
  return {
    isDashboardFilterShowing: state.dashboard.isDashboardFilterShowing,
    savedFilters: state.dashboard.savedFilters,
    selectedSavedFilterSlug: state.dashboard.selectedSavedFilterSlug,
    isPDFModalOpen: state.dashboard.isPDFModalOpen,
  };
};

const DashboardFilter = ({
  // redux state
  isDashboardFilterShowing,
  savedFilters,
  selectedSavedFilterSlug,
  isPDFModalOpen,
  // redux dispatch
  dispatch,
}) => {
  const { slug: projectSlug, dashboardSlug } = useParams();
  const location = useLocation();

  const [hasDefaultFilterApplied, setHasDefaultFilterApplied] = useState(false);
  const [isNewFilterNameModalOpen, setIsNewFilterNameModalOpen] =
    useState(false);

  const filterBarRef = useRef();

  const canEditDashboard = useCheckPermission(['insight.manageDashboards']);

  // Default filters apply
  useEffect(() => {
    const urlSearchParams = queryStringToObject(location.search);
    const filterSlug = urlSearchParams && urlSearchParams.filterSlug;

    const defaultSavedFilter = filterSlug
      ? savedFilters.find((filter) => filter.slug === filterSlug)
      : savedFilters.find((filter) => filter.dashboardDefault);

    if (defaultSavedFilter && !hasDefaultFilterApplied) {
      setHasDefaultFilterApplied(true);
      dispatch({
        type: 'SET_DASHBOARD_ACTIVE_FILTERS',
        ...(Array.isArray(defaultSavedFilter.filterConfig)
          ? {
              filters: defaultSavedFilter.filterConfig,
              timeFilter: null,
            }
          : {
              filters: defaultSavedFilter.filterConfig
                ? defaultSavedFilter.filterConfig.filters
                : [],
              timeFilter: defaultSavedFilter.filterConfig
                ? defaultSavedFilter.filterConfig.timeFilter
                : null,
            }),
        slug: filterSlug,
      });
    }
  }, [savedFilters, dispatch, hasDefaultFilterApplied, location.search]);

  // URL filters apply
  useEffect(() => {
    const urlSearchParams = queryStringToObject(location.search);
    if (
      urlSearchParams &&
      (urlSearchParams.filters || urlSearchParams.timeFilter)
    ) {
      dispatch({
        type: 'LOAD_DASHBOARD_CUSTOM_FILTER',
        filters: Array.isArray(urlSearchParams.filters)
          ? urlSearchParams.filters
          : [],
        timeFilter:
          urlSearchParams.timeFilter &&
          formatEmptyObject(urlSearchParams.timeFilter)
            ? formatEmptyObject(urlSearchParams.timeFilter)
            : null,
      });
    }
  }, [dispatch, location.search]);

  // Pass exported filters to PDF
  useEffect(() => {
    if (isPDFModalOpen) {
      const exportedFilters = filterBarRef.current.getExportedFilters();
      dispatch({
        type: 'SET_DASHBOARD_EXPORTED_FILTERS',
        filters: exportedFilters,
      });
    }
  }, [dispatch, isPDFModalOpen]);

  // Save dashbaord save filter changes
  const saveFilterChanges = async () => {
    try {
      const { data: updatedFilter } = await putApi(
        `projects/${projectSlug}/dashboards/${dashboardSlug}/filters/${selectedSavedFilterSlug}`,
        {
          filter: {
            filterConfig: filterBarRef.current.getExportedFilters(),
          },
        }
      );

      dispatch({
        type: 'SET_DASHBOARD_SAVED_FILTER',
        filter: updatedFilter,
      });
      message.success('Dashboard Filter Saved');
    } catch (error) {
      message.error(
        fetchApiErrorMessage(error, 'Unable to Save Dashboard Filter')
      );
    }
  };

  // Save default dashboard filter
  const saveDefaultSavedFilter = async () => {
    try {
      await putApi(
        `projects/${projectSlug}/dashboards/${dashboardSlug}/filters/${selectedSavedFilterSlug}/default`
      );

      dispatch({
        type: 'SET_DASHBOARD_DEFAULT_FILTER',
        slug: selectedSavedFilterSlug,
      });
      message.success('Default Dashboard Filter Set');
    } catch (error) {
      message.error(
        fetchApiErrorMessage(error, 'Unable to Set Default Dashboard Filter')
      );
    }
  };

  // Delete dashboard filter
  const deleteSavedFilter = async () => {
    try {
      await deleteApi(
        `projects/${projectSlug}/dashboards/${dashboardSlug}/filters/${selectedSavedFilterSlug}`
      );

      dispatch({
        type: 'DELETE_DASHBOARD_SAVED_FILTER',
        slug: selectedSavedFilterSlug,
      });
      message.success('Dashboard Filter Deleted');
    } catch (error) {
      message.error(
        fetchApiErrorMessage(error, 'Unable to Delete Dashboard Filter')
      );
    }
  };

  const currentTabFilters = useMemo(() => {
    const currentTabSavedFilter = savedFilters.find(
      (filter) => filter.slug === selectedSavedFilterSlug
    );
    if (currentTabSavedFilter && currentTabSavedFilter.filterConfig) {
      return transformLegacyDashboardFilters(
        currentTabSavedFilter.filterConfig
      );
    }
    return { filters: [], timeFilter: null };
  }, [selectedSavedFilterSlug, savedFilters]);

  return (
    <div
      id="dashboard-filter-bar"
      css={{
        marginTop: preset.spacing(3),
        '.ant-tabs-extra-content': {
          fontSize: 16,
          '> span': {
            cursor: 'pointer',
            marginLeft: preset.spacing(3),
          },
        },
      }}
    >
      <FilterBar
        ref={filterBarRef}
        initialFilters={currentTabFilters.filters}
        initialTimeFilter={currentTabFilters.timeFilter}
        filterCardProps={{
          tabList: savedFilters.map((savedFilter) => ({
            key: savedFilter.slug,
            tab: savedFilter.dashboardDefault ? (
              <span>
                <StarOutlined />
                {savedFilter.name}
              </span>
            ) : (
              savedFilter.name
            ),
          })),
          activeTabKey: selectedSavedFilterSlug,
          onTabChange: (tabKey) =>
            dispatch({
              type: 'SET_SELECTED_SAVED_FILTER_SLUG',
              slug: tabKey,
            }),
          tabBarExtraContent:
            canEditDashboard &&
            (selectedSavedFilterSlug === 'custom' ? (
              <Tooltip key="plus" title="Save as New Filter">
                <PlusOutlined
                  onClick={() => setIsNewFilterNameModalOpen(true)}
                />
              </Tooltip>
            ) : (
              <>
                <Tooltip key="save" title="Update the Current Filter">
                  <SaveOutlined onClick={() => saveFilterChanges()} />
                </Tooltip>
                <Tooltip key="plus" title="Save as New Filter">
                  <PlusOutlined
                    onClick={() => setIsNewFilterNameModalOpen(true)}
                  />
                </Tooltip>
                <Tooltip key="star" title="Set as Default Filter">
                  <StarOutlined onClick={() => saveDefaultSavedFilter()} />
                </Tooltip>
                <Tooltip key="delete" title="Delete Filter">
                  <Popconfirm
                    title="Delete this filter?"
                    onConfirm={() => deleteSavedFilter()}
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                </Tooltip>
              </>
            )),
        }}
        onApply={({ filters, timeFilter }) => {
          dispatch({
            type: 'SET_DASHBOARD_ACTIVE_FILTERS',
            filters,
            timeFilter,
          });
        }}
        css={{
          display: isDashboardFilterShowing ? 'block' : 'none',
          marginBottom: preset.spacing(3),
        }}
      />

      <NewSavedFilterNameModal
        visible={isNewFilterNameModalOpen}
        onCancel={() => setIsNewFilterNameModalOpen(false)}
        getExportedFilters={
          filterBarRef.current && filterBarRef.current.getExportedFilters
        }
      />
    </div>
  );
};

export default connect(mapStateToProps)(DashboardFilter);
