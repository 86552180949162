/** @jsxImportSource @emotion/react */
import { Row, Col, Form, InputNumber, Collapse } from 'antd';

import { preset } from 'styles';
import ColorPicker from '@components/ColorPicker';

const { Panel } = Collapse;

const RangeColorPanelBody = ({
  defaultConfig = {},
  defaultColor = {},
  dispatch,
}) => {
  return (
    <Row gutter={preset.spacing(3)}>
      {[
        { zKey: 'min', colorKey: 'fromColor', legacyColorKey: 'from' },
        { zKey: 'mid', colorKey: 'midColor', legacyColorKey: 'mid' },
        { zKey: 'max', colorKey: 'toColor', legacyColorKey: 'to' },
      ].map((rangeObj) => (
        <Col key={rangeObj.colorKey} span={8}>
          <Form.Item
            label={rangeObj.zKey}
            help="Press Enter to Apply"
            css={{
              label: {
                textTransform: 'capitalize',
              },
              '.ant-form-item-control-input-content': {
                display: 'flex',
                alignItems: 'center',
                '.ant-input-number': {
                  marginRight: 12,
                },
              },
            }}
          >
            <InputNumber
              defaultValue={
                defaultConfig.zRange && defaultConfig.zRange[rangeObj.zKey]
              }
              onPressEnter={(e) => {
                dispatch({
                  type: 'SET_CONFIG',
                  value: e.target.value ? Number(e.target.value) : null,
                  key: 'zRange',
                  childKey: rangeObj.zKey,
                });
              }}
            />
            <ColorPicker
              color={
                defaultColor[rangeObj.colorKey] ||
                defaultColor[rangeObj.legacyColorKey]
              }
              onColorChange={(color) =>
                dispatch({
                  type: 'SET_COLOR',
                  value: color.hex,
                  key: rangeObj.colorKey,
                })
              }
            />
          </Form.Item>
        </Col>
      ))}
    </Row>
  );
};

const RangeColorPanel = (props) => {
  const { defaultConfig, defaultColor, dispatch, ...panelProps } = props;

  return (
    <Panel {...panelProps} key="chart_range_color" header="Chart Colour">
      <RangeColorPanelBody {...props} />
    </Panel>
  );
};

RangeColorPanel.Body = RangeColorPanelBody;

export default RangeColorPanel;
