/** @jsxImportSource @emotion/react */
import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Button, AutoComplete, Input, Space, Tag, Tooltip } from 'antd';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import { SearchOutlined, CheckOutlined } from '@ant-design/icons';
import lucene, { AST, NodeTerm, NodeRangedTerm } from 'lucene';
import orderBy from 'lodash/orderBy';

import { colors, preset } from 'styles';
import { FilterOptions, SchemaField, Filter, LuceneNodeNested } from 'types';
import {
  getPresetDateRanges,
  deepFindLuceneNode,
  formatFinalLuceneInput,
  deepFindAllNestedTermStrings,
  LUCENE_DEFAULT_TERM_NODE,
  addTermToNestedNode,
  getLuceneNodeRange,
  removeTermFromNestedNode,
} from 'modules';

const { allValues, byValue } = getPresetDateRanges();

type Props = {
  loading?: boolean;
  defaultValue: string;
  documentFilterOptions: FilterOptions;
  sharedSchemaFields: SchemaField[];
  suffix: React.ReactNode;
  onSearch: (queryInputData: {
    moreFilters?: Filter[];
    rawSearch?: string;
  }) => void;
  recentSearches: { time: string; search: string }[];
  className?: string;
};

type FilterOption = {
  key: string;
  displayName: string;
  options: { value: string | number; valueDisplayName?: string }[];
};

type AutoCompleteQuery = {
  field?: string;
  value?: string | string[];
} | null;

type AutoCompleteOptionItem = {
  label: React.ReactNode;
  value: string;
  field?: string;
  className?: string;
};

type AutoCompleteOption =
  | { label: React.ReactNode; options: AutoCompleteOptionItem[] }
  | { label: React.ReactNode; value: string };

const renderOptionItem = (
  label: string,
  value: string
): AutoCompleteOptionItem => {
  return {
    label: (
      <div css={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title="Field">
          <Tag color="gold" css={{ marginRight: 12 }}>
            F
          </Tag>
        </Tooltip>
        <span>{label}</span>
      </div>
    ),
    value,
  };
};

const renderOptionGroupItem = (
  fieldKey: string,
  option: {
    value: string | number;
    valueDisplayName?: string;
  },
  selected?: boolean
): AutoCompleteOptionItem => {
  return {
    field: fieldKey,
    className: selected ? 'ant-select-item-option-selected' : '',
    label: (
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingRight: 12,
        }}
      >
        <div css={{ display: 'flex', alignItems: 'center' }}>
          {fieldKey === 'recent_search' ? (
            <Tooltip title="Recent search">
              <SearchOutlined
                css={{ color: colors.darkGrey, marginRight: 12 }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Value">
              <Tag color="green" css={{ marginRight: 12 }}>
                V
              </Tag>
            </Tooltip>
          )}
          <span>{option.valueDisplayName || option.value}</span>
        </div>
        {selected && <CheckOutlined css={{ color: colors.primary }} />}
      </div>
    ),
    value: option.value.toString(),
  };
};

const filterItemOptions = (
  itemOptions: FilterOption['options'],
  strArray: string[]
): FilterOption['options'] => {
  return itemOptions.filter((option) => {
    const { value, valueDisplayName } = option;
    return !!strArray.find((str) => {
      if (!str) return false;
      return (
        value.toString().toLowerCase().includes(str.toLowerCase()) ||
        (valueDisplayName &&
          valueDisplayName.toLowerCase().includes(str.toLowerCase()))
      );
    });
  });
};

export const QueryInput: React.FC<Props> = ({
  loading,
  defaultValue,
  documentFilterOptions,
  sharedSchemaFields,
  suffix,
  onSearch,
  recentSearches,
  className,
}) => {
  const [input, setInput] = useState<string>('');
  const [autoCompleteQuery, setAutoCompleteQuery] = useState<AutoCompleteQuery>(
    {}
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const inputRef = useRef<TextAreaRef | null>(null);

  useEffect(() => {
    setInput(defaultValue);
  }, [defaultValue]);

  const filterOptions: FilterOption[] = useMemo(() => {
    if (Object.keys(documentFilterOptions).length > 0) {
      const sortedSchemaFields = orderBy(sharedSchemaFields, 'order');

      const rawFilterOptions = sortedSchemaFields.reduce(
        (prev: FilterOption[], field) => {
          const { name, displayName } = field;
          if (
            [
              'otso_doc_body',
              'document_body',
              'document_publish_date',
            ].includes(name)
          ) {
            return prev;
          }
          if (name === 'otso_doc_publish_date') {
            const dateFilterOption: FilterOption = {
              key: 'otso_doc_publish_date',
              displayName: 'Date',
              options: allValues
                .reduce((prevOptions: FilterOption['options'], currentVal) => {
                  if (currentVal === 'custom') return prevOptions;
                  return typeof currentVal === 'string'
                    ? prevOptions.concat({
                        value: currentVal,
                        valueDisplayName: byValue[currentVal].label,
                      })
                    : prevOptions.concat(
                        currentVal.values.map((x) => ({
                          value: x,
                          valueDisplayName: byValue[x].label,
                        }))
                      );
                }, [])
                .concat({
                  value: 'otso_doc_publish_date:custom',
                  valueDisplayName: 'Custom',
                }),
            };

            return [...prev, dateFilterOption];
          }
          const options = (documentFilterOptions[name] || []).map(
            (optionVal) => ({ value: optionVal })
          );
          const filterOption: FilterOption = {
            key: name,
            displayName: displayName || name,
            options,
          };
          return [...prev, filterOption];
        },
        []
      );

      const sortedFilterOptions = rawFilterOptions.sort((a, b) => {
        if (a.options.length === 0) return 1;
        if (b.options.length === 0) return -1;
        return 0;
      });

      return sortedFilterOptions;
    }
    return [];
  }, [documentFilterOptions, sharedSchemaFields]);

  const onInputChange = (newValue: string, cursorPos: number) => {
    setInput(newValue);
    try {
      const luceneAST = lucene.parse(newValue);
      const targetNode = deepFindLuceneNode(luceneAST, cursorPos);
      if (targetNode) {
        if ('term' in targetNode) {
          // Term
          if (targetNode.field === '<implicit>') {
            // Term Only
            setAutoCompleteQuery({ field: targetNode.term });
          } else {
            // Field + Term
            const matchedFilterOption = filterOptions.find(
              (filterOption) => filterOption.key === targetNode.field
            );
            if (
              matchedFilterOption &&
              matchedFilterOption.options.length === 0
            ) {
              setAutoCompleteQuery(null);
            } else {
              setAutoCompleteQuery({
                field: targetNode.field,
                value: targetNode.term,
              });
            }
          }
        } else if ('term_max' in targetNode) {
          // Ranged Term
          setAutoCompleteQuery(null);
        } else if ('left' in targetNode && targetNode.parenthesized) {
          // Nested Term
          const allTerms = deepFindAllNestedTermStrings(targetNode);
          setAutoCompleteQuery({ field: targetNode.field, value: allTerms });
        } else {
          setAutoCompleteQuery(null);
        }
      } else {
        setAutoCompleteQuery({});
      }
    } catch {
      setAutoCompleteQuery(null);
    }

    setIsDropdownOpen(true);
  };

  const onOptionSelect = (val: string, option: any) => {
    const selectedOption = option as AutoCompleteOptionItem;
    if (inputRef.current?.resizableTextArea) {
      const textareaRef = inputRef.current.resizableTextArea.textArea;
      const cursorPos = textareaRef.selectionStart;

      const luceneAST = lucene.parse(input);
      const targetNode = deepFindLuceneNode(luceneAST, cursorPos);

      let isDropdownOpenAfterSelect = true;

      let lastInputStart = cursorPos;
      let lastInputEnd = cursorPos;
      let lastInputStringLength = 0;

      if (targetNode) {
        const termNodeRange = getLuceneNodeRange(targetNode);
        if (termNodeRange) {
          lastInputStart = termNodeRange.start;
          lastInputEnd = termNodeRange.end;
        }
        lastInputStringLength = lastInputEnd - lastInputStart;
      }

      const slicedLeftInput = input.slice(0, lastInputStart);
      const slicedRightInput = input.slice(lastInputEnd);

      let newLuceneAST: AST = { left: LUCENE_DEFAULT_TERM_NODE };
      let newLuceneString = '';

      if (selectedOption.value.startsWith('search_string:')) {
        const searchString = selectedOption.value.replace(
          /search_string:/g,
          ''
        );
        const searchStringTermNode: NodeTerm = {
          ...LUCENE_DEFAULT_TERM_NODE,
          field: '<implicit>',
          term: searchString,
          quoted:
            targetNode && 'term' in targetNode ? targetNode.quoted : false,
        };
        newLuceneAST.left = searchStringTermNode;

        isDropdownOpenAfterSelect = false;
      } else if (selectedOption.field === 'otso_doc_publish_date') {
        if (selectedOption.value === 'otso_doc_publish_date:custom') {
          const rangedNode: NodeRangedTerm = {
            field: selectedOption.field,
            fieldLocation: null,
            inclusive: 'both',
            term_max: 'YYYY-MM-DD',
            term_min: 'YYYY-MM-DD',
          };
          newLuceneAST.left = rangedNode;
        } else {
          const presetDateTermNode: NodeTerm = {
            ...LUCENE_DEFAULT_TERM_NODE,
            field: selectedOption.field,
            term: selectedOption.value,
            quoted: true,
          };
          newLuceneAST.left = presetDateTermNode;
        }

        isDropdownOpenAfterSelect = false;
      } else if (selectedOption.field === 'recent_search') {
        newLuceneString = selectedOption.value;

        isDropdownOpenAfterSelect = false;
      } else if (selectedOption.field) {
        if (targetNode && 'left' in targetNode && targetNode.parenthesized) {
          if (selectedOption.className === 'ant-select-item-option-selected') {
            newLuceneAST = removeTermFromNestedNode(
              targetNode,
              selectedOption.value
            );
          } else {
            const newNestedNode = addTermToNestedNode(
              targetNode,
              selectedOption.value
            );
            // @types/lucene doesn't support LuceneNodeNested
            newLuceneAST.left = newNestedNode as any;
          }
        } else {
          const nestedTermNode: LuceneNodeNested = {
            field: selectedOption.field,
            fieldLocation: null,
            left: {
              ...LUCENE_DEFAULT_TERM_NODE,
              term: selectedOption.value,
              quoted: true,
            },
            parenthesized: true,
          };
          // @types/lucene doesn't support LuceneNodeNested
          newLuceneAST.left = nestedTermNode as any;
        }
      } else {
        const emptyTermNode: NodeTerm = {
          ...LUCENE_DEFAULT_TERM_NODE,
          field: selectedOption.value,
          term: '',
          quoted: false,
        };
        newLuceneAST.left = emptyTermNode;
      }

      const insertedInput = newLuceneString || lucene.toString(newLuceneAST);

      const newInput = `${slicedLeftInput}${insertedInput}${
        slicedRightInput ? ` ${slicedRightInput}` : ''
      }`;
      const newCursorPos =
        cursorPos - lastInputStringLength + insertedInput.length;

      onInputChange(newInput, newCursorPos);
      setIsDropdownOpen(isDropdownOpenAfterSelect);

      // I hate myself
      setTimeout(() => {
        textareaRef.setSelectionRange(newCursorPos, newCursorPos);
      }, 0);
    }
  };

  const onEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const activeOptionElements = document.querySelectorAll(
      '.query-input-dropdown .ant-select-item-option-active:not(.ant-select-item-option-selected)'
    );
    if (activeOptionElements.length === 0 || !isDropdownOpen) {
      e.preventDefault();

      const formattedInput = formatFinalLuceneInput(input);

      onSearch({
        moreFilters: formattedInput
          ? [
              {
                attribute: 'otso_doc_body',
                condition: 'query_string',
                value: formattedInput,
              },
            ]
          : [],
        rawSearch: input.trim(),
      });

      inputRef.current?.blur();
    }
  };

  const autoCompleteOptions: AutoCompleteOption[] = useMemo(() => {
    if (autoCompleteQuery === null) return [];

    const { field: fieldQuery, value: valueQuery } = autoCompleteQuery;

    const filteredAutoCompleteOptions = filterOptions.reduce(
      (prev: AutoCompleteOption[], filterOption) => {
        const { key, displayName, options } = filterOption;
        if (!fieldQuery) {
          return [
            ...prev,
            options.length > 0
              ? {
                  label: displayName,
                  options: options.map((option) =>
                    renderOptionGroupItem(key, option)
                  ),
                }
              : renderOptionItem(displayName, key),
          ];
        }

        const isFilterOptionKeyMatched =
          key.toLowerCase().includes(fieldQuery.toLowerCase()) ||
          displayName.toLowerCase().includes(fieldQuery.toLowerCase());

        if (options.length === 0) {
          if (!isFilterOptionKeyMatched) return prev;
          return [...prev, renderOptionItem(displayName, key)];
        }

        const filteredOptionItems = isFilterOptionKeyMatched
          ? options
          : filterItemOptions(
              options,
              valueQuery
                ? Array.isArray(valueQuery)
                  ? valueQuery
                  : [valueQuery]
                : [fieldQuery]
            );

        if (filteredOptionItems.length > 0) {
          return [
            ...prev,
            {
              label: displayName,
              options: filteredOptionItems.map((option) =>
                renderOptionGroupItem(
                  key,
                  option,
                  valueQuery
                    ? Array.isArray(valueQuery)
                      ? valueQuery.includes(option.value.toString())
                      : valueQuery === option.value.toString()
                    : false
                )
              ),
            },
          ];
        }

        return prev;
      },
      []
    );

    if (fieldQuery && !valueQuery) {
      filteredAutoCompleteOptions.push({
        label: (
          <Space>
            <SearchOutlined css={{ color: colors.darkGrey }} />
            <span>{`Search "${fieldQuery}"`}</span>
          </Space>
        ),
        value: `search_string:${fieldQuery}`,
      });
    }

    if (
      !fieldQuery &&
      !valueQuery &&
      Array.isArray(recentSearches) &&
      recentSearches.length > 0
    ) {
      filteredAutoCompleteOptions.unshift({
        label: 'Recent searches',
        options: recentSearches.map((searchObj) =>
          renderOptionGroupItem('recent_search', { value: searchObj.search })
        ),
      });
    }

    return filteredAutoCompleteOptions;
  }, [filterOptions, autoCompleteQuery, recentSearches]);

  return (
    <div className={className} css={{ position: 'relative' }}>
      {/* Search button */}
      <Button
        loading={loading}
        type="text"
        size="large"
        icon={<SearchOutlined style={{ color: colors.darkGrey }} />}
        style={{
          position: 'absolute',
          zIndex: 2,
          top: 7,
          left: 10,
        }}
      />

      {/* Search input with auto-completion */}
      <AutoComplete
        open={isDropdownOpen}
        value={input}
        options={autoCompleteOptions}
        onSelect={onOptionSelect}
        onFocus={() => setIsDropdownOpen(true)}
        onBlur={() => setIsDropdownOpen(false)}
        dropdownClassName="query-input-dropdown"
        css={{
          width: '100%',
          'textarea.ant-input': {
            paddingLeft: preset.spacing(5),
            overflow: 'hidden',
          },
        }}
      >
        <Input.TextArea
          ref={inputRef}
          allowClear
          bordered={false}
          autoSize
          size="large"
          placeholder="Search Documents"
          onChange={(e) =>
            onInputChange(e.target.value, e.target.selectionStart)
          }
          onPressEnter={onEnter}
        />
      </AutoComplete>

      {/* Render suffix if provided */}
      {suffix}
    </div>
  );
};
