import { containsAnyString } from './items';
import isURL from './lib/isURL';

const isImageURL = url => {
    return isURL(url) && url.match(/\.(jpeg|jpg|gif|png)$/) != null;
};

const getDocumentMediaURL = (document, schemaFields) => {
    if (!document || !schemaFields) return null;

    const { link, image, source } = schemaFields;

    if (link && document[link] && isURL(document[link])) {
        const { hostname } = new URL(document[link]);
        if (containsAnyString(hostname, ['facebook', 'twitter', 'instagram', 'youtube', 'reddit'])
        && (!document[source] || !document[source].toLowerCase().includes('facebook messenger'))) {
            return {
                type: 'embed',
                url: document[link],
            };
        }
    }
    if (image && document[image] && isImageURL(document[image])) {
        return {
            type: 'image',
            url: document[image],
        };
    }
    return null;
};

export default getDocumentMediaURL;
