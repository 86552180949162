import { Step } from 'react-joyride';

export const GUIDED_TOURS: {
  pagePath: string;
  localStoreItemName: string;
  steps: Step[];
}[] = [
  {
    pagePath: '/projects/:slug/dashboards/:dashboardSlug',
    localStoreItemName: 'dashboardTourStatus',
    steps: [
      {
        content:
          'It looks like this is your first time on a dashboard. Click Start to take a quick tour or skip if you already know what your doing.',
        placement: 'center',
        target: 'body',
        locale: {
          skip: 'Skip Tour',
          next: 'Start',
        },
        styles: {
          options: {
            width: 500,
          },
        },
      },
      {
        title: 'Filters',
        content:
          'The filter bar allows you to filter, and narrow down the data used your charts so that you can find more detailed insights.',
        placement: 'bottom-start',
        target: '#dashboard-filter-bar',
      },
      {
        content:
          'You can switch between saved filter configurations with these tabs. The star represents the default filter for this dashboard.',
        placement: 'bottom-start',
        target: '.ant-tabs-nav-wrap',
      },
      {
        content:
          'Here you can save your current filter configuration, set the current filter as the dashbaord default or delete the current saved filter.',
        placement: 'bottom-start',
        target: '.ant-tabs-extra-content',
      },
      {
        content:
          'Commonly filtered attributes such as Date, Verbatim and Document Sentiment are quickly accessable here.',
        target: '.ant-form.ant-form-inline',
      },
      {
        content:
          'You can add more filters for other attributes of your data with this button.',
        target: '#filter-bar-more-button',
      },
      {
        content:
          'To show another user the dashboard using the filters applied, click this link to create a shareable hyperlink to the view.',
        placement: 'bottom-start',
        target: '#filter-bar-share-button',
      },
      {
        content:
          'Once you are happy with the filters, click here to apply them to the charts currently on the dashboard.',
        target: '#filter-bar-search-button',
        locale: { last: 'Complete' },
      },
    ],
  },
  {
    pagePath: '/',
    localStoreItemName: 'basicIntroTourStatus',
    steps: [
      {
        content: (
          <div>
            <h2>Welcome to otso Insight</h2>
            <p>
              otso Insight makes it easy to analyse all of your customer
              feedback and media mentions, from any source, at scale.
            </p>
            <p>
              Discover new insights and explore relationships within your world
              of data, powered by the latest advances in AI.
            </p>
          </div>
        ),
        placement: 'center',
        target: 'body',
        locale: {
          skip: 'Skip Tour',
          next: 'Start',
        },
        styles: {
          options: {
            width: 500,
          },
        },
      },
      {
        title: 'Navigation',
        content: 'You can click on tabs to switch among different sections.',
        target: '#headerMenu',
      },
      {
        title: 'Switch Organisation',
        content:
          'You can switch organisation here, if you have access to more than one. Each time you login you will automatically continue with the organisation you were last using.',
        target: '#orgSelector',
      },
      {
        title: 'User Profile',
        content:
          'You can edit your personal profile or submit a support ticket here.',
        target: '#userDropdownMenu',
        locale: { last: 'Complete' },
      },
    ],
  },
  {
    pagePath: '/admin/datasets/:datasetId',
    localStoreItemName: 'viewDatasetTourStatus',
    steps: [
      {
        content:
          'Here you can view and update some key information about the this dataset.',
        target: '#editDatasetInformation',
      },
      {
        content:
          'Here you can see information about the source of this dataset. Depending on the source you may also be able to create a new data export or change the enrichment.',
        target: '#datasetSourceInformation',
      },
      {
        content:
          'Any Projects currently using this dataset will be displayed here.',
        target: '#datasetProjects',
      },
      {
        content: `The volume of documents ingested ingested by this dataset is displayed here. Note that this uses when the document was received by the Insight platform and will often differ from the date/time the document was published.`,
        target: '#datasetUsage',
        locale: { last: 'Complete' },
      },
    ],
  },
];
