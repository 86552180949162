import truncate from 'lodash/truncate';

const MIN_LABEL_WIDTH = 20;
const MIN_LABEL_HEIGHT = 16;
const LETTER_WIDTH = 10;

const formatOverflowLabel = (label, containerW, containerH) => {
    if (!label) return label;

    if (containerW < MIN_LABEL_WIDTH || containerH < MIN_LABEL_HEIGHT) return '';

    const maxLabelStrLength = Math.floor(containerW / LETTER_WIDTH);
    const labelStrLength = `${label}`.length;

    if (labelStrLength > maxLabelStrLength) {
        return truncate(label, { length: maxLabelStrLength, separator: /,? +/ });
    }

    return label;
};

export default formatOverflowLabel;
