import { Interpolation } from '@emotion/react';

import * as preset from './preset';
import * as colors from './colors';

const globalStyle: Interpolation<any> = {
  '.ant-page-header': {
    paddingLeft: 0,
    paddingRight: 0,
    '.ant-page-header-heading, .ant-page-header-content': {
      maxWidth: preset.containerWidth,
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: preset.spacing(3),
      paddingRight: preset.spacing(3),
      [preset.breakpoints.lg]: {
        paddingLeft: preset.spacing(5),
        paddingRight: preset.spacing(5),
      },
    },
    '.ant-page-header-heading-extra': {
      display: 'inline-flex',
    },
  },
  // react-grid-layout
  '.react-grid-item': {
    '&.disabled .react-resizable-handle': {
      display: 'none !important',
    },
  },
  // react-quill
  '.ql-container': {
    fontFamily: 'inherit',
    fontSize: 'inherit',
  },
  '.ql-editor': {
    lineHeight: 'inherit',
  },
  '.ql-snow a': {
    color: colors.primary,
  },
  '.ql-snow .ql-tooltip input[type=text]': {
    width: 200,
  },
  '.row-dragging': {
    background: '#fafafa',
    border: '1px solid #ccc',
    zIndex: 9999,
  },
  '.row-dragging td.ant-table-cell': {
    padding: '8px',
  },
  '.row-dragging .drag-visible': {
    visibility: 'visible',
  },
  '.query-input-dropdown': {
    '.ant-select-item': {
      '&.ant-select-item-option-selected.ant-select-item-option-active': {
        backgroundColor: '#f5f5f5',
      },
      '.ant-tag': {
        padding: '0 4px',
        fontSize: 8,
        lineHeight: '14px',
      },
    },
  },
};

export default globalStyle;
