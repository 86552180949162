/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import { Layout, Row, Col } from 'antd';
import { Logo, OrgSelect, NavMenu } from '@otso/auth-wrapper';

import { Container, UserDropdownMenu } from 'components';
import { preset, colors } from 'styles';
import { useIsSuperAdminRoute } from 'modules';

type Props = { children: React.ReactNode };

const ADMIN_MENU_LIST = [
  {
    title: 'Projects',
    path: '/projects',
  },
  {
    title: 'Datasets',
    path: '/admin/datasets',
    requiredAction: 'insight.manageProjects',
  },
];

const SUPER_ADMIN_MENU_LIST = [
  { title: 'Global Datasets', path: '/superadmin/datasets' },
  { title: 'Global Integrations', path: '/superadmin/integrations' },
];

export const AppLayout: React.FC<Props> = ({ children }) => {
  const isSuperAdminRoute = useIsSuperAdminRoute();

  const navMenuRoutes = useMemo(() => {
    return isSuperAdminRoute ? SUPER_ADMIN_MENU_LIST : ADMIN_MENU_LIST;
  }, [isSuperAdminRoute]);

  return (
    <Layout css={{ minHeight: '100vh' }}>
      <Layout.Header
        css={{
          position: 'relative',
          zIndex: 1,
          backgroundColor: colors.white,
          boxShadow: preset.boxShadow,
          height: preset.headerHeight,
          lineHeight: `${preset.headerHeight}px`,
          padding: 0,
        }}
      >
        <Container>
          <Row
            gutter={preset.spacing(2)}
            wrap={false}
            style={{ height: '100%' }}
          >
            <Col>
              <Logo />
            </Col>
            <Col flex="auto">
              <NavMenu routes={navMenuRoutes} />
            </Col>
            <Col flex="240px">{!isSuperAdminRoute && <OrgSelect />}</Col>
            <Col>
              <UserDropdownMenu />
            </Col>
          </Row>
        </Container>
      </Layout.Header>
      <Layout.Content>{children}</Layout.Content>
    </Layout>
  );
};
