/** @jsxImportSource @emotion/react */
import { useEffect, useRef } from 'react';

import { trackEvent } from 'analytics';

const Facebook = ({ link, embedMaxWidth }) => {
  const fbEmbedContainer = useRef();

  // Track FB post click-through
  useEffect(() => {
    const fbClickTrack = () => {
      if (
        fbEmbedContainer.current &&
        fbEmbedContainer.current.childNodes[0] &&
        fbEmbedContainer.current.childNodes[0].childNodes[0]
      ) {
        if (
          document.activeElement.name ===
          fbEmbedContainer.current.childNodes[0].childNodes[0].name
        ) {
          trackEvent('Explore Facebook Post Click', { postLink: link });
        }
      }
    };

    window.addEventListener('blur', fbClickTrack);
    if (window.FB) {
      window.FB.XFBML.parse();
    }

    return () => {
      window.removeEventListener('blur', fbClickTrack);
    };
  }, [link]);

  return (
    <div
      className="fb-post"
      data-href={link}
      data-width={embedMaxWidth}
      ref={fbEmbedContainer}
    />
  );
};

export default Facebook;
