/** @jsxImportSource @emotion/react */
import {
  useContext,
  useReducer,
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import {
  Card,
  Form,
  Select,
  Row,
  Col,
  Input,
  Button,
  Popover,
  Collapse,
  Badge,
  Alert,
} from 'antd';
import {
  PlusOutlined,
  StopOutlined,
  SearchOutlined,
  CaretDownOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import { trackEvent } from 'analytics';
import { colors, preset } from 'styles';
import {
  getSharedSchemaFields,
  exportFilterBarFilters,
  importFilterBarFilters,
  getMainVerbatimFieldName,
  getCustomClassifierFields,
  getFilterDisplay,
} from '@modules/filterBarHelpers';
import useFilterOptions from '@modules/hooks/useFilterOptions';
import localStore from '@modules/localStore';
import { formatEmptyObject, omit } from '@modules/items';
import deepGet from '@modules/deepGet';
import {
  queryStringToObject,
  convertToURLParams,
} from '@modules/queryStringConverter';

import DateRangePicker from '../DateRangePicker';
import NewFilterForm from './components/NewFilterForm';
import FilterShareButton from './components/FilterShareButton';
import FilterTags from './components/FilterTags';
import CustomClassifierFilterFields from './components/CustomClassifierFilterFields';
import FloatLabelContainer from '../FloatLabelContainer';
import { QueryInput } from './components/NewQueryInput';
import { initialState, filterReducer } from './reducer';
import { ProjectContext } from '../../providers/ProjectProvider';

const { Option } = Select;

const FilterBar = (props, ref) => {
  const {
    initialDatasetIds = [],
    initialFilters = [],
    initialTimeFilter,
    isSearchLoading = false,
    onApply,
    onClear,
    onFilterChange, // Notes: this allow parent components to track updates to any filters outside of this component state reducer
    filterCardProps = {},
    reInitWhenFiltersChanged = false,
    useQueryInput = false,
    helpMessage,
    allowTextSearch = true,
    allowCustomClassifierFilters = true,
    allowShareFilters = true,
    allowMoreFilters = true,
    addMoreFiltersButtonText = 'More Filters',
    searchButtonText = 'Search',
    searchButtonIcon = <SearchOutlined />,
    className,
  } = props;

  const { dashboardSlug, slug: projectSlug } = useParams();
  const location = useLocation();
  const history = useHistory();
  const isOnProjectsPage =
    location.pathname.includes('/settings') ||
    location.pathname.includes('/projects/create');

  const currentProjectRecentSearches = useMemo(() => {
    const cachedRecentSearches = JSON.parse(
      localStore.getItem('recentSearches')
    );
    return Array.isArray(deepGet(cachedRecentSearches, [projectSlug]))
      ? cachedRecentSearches[projectSlug]
      : [];
  }, [projectSlug]);

  const [recentSearches, setRecentSearches] = useState(
    currentProjectRecentSearches
  );
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [isNewFilterPopoverOpen, setIsNewFilterPopoverOpen] = useState(false);
  const [hasFiltersInit, setHasFiltersInit] = useState(
    dashboardSlug ? false : initialDatasetIds.length === 0
  );
  const [filters, dispatch] = useReducer(filterReducer, {
    ...initialState,
    datasetIds: initialDatasetIds,
  });
  const [isHelpAlertOpen, setIsHelpAlertOpen] = useState(false);

  const { datasetIds = [], verbatim, moreFilters = [], timeFilter } = filters;

  // Get Project Datasets from Provider
  const {
    project: { datasets: projectDatasets = [], projectDatasetsById },
    loading: isProjectDatasetsLoading,
  } = useContext(ProjectContext);

  // Get actual active datasets
  const selectedDatasets = useMemo(() => {
    if (Object.keys(projectDatasetsById).length > 0) {
      // All datasets are selected by default
      if (datasetIds.length === 0) {
        return projectDatasets;
      }
      return datasetIds.map((datasetId) => projectDatasetsById[datasetId]);
    }
    return [];
  }, [projectDatasetsById, datasetIds, projectDatasets]);

  const selectedDatasetIds = useMemo(
    () => selectedDatasets.map((dataset) => dataset.id),
    [selectedDatasets]
  );

  // Get common schema fields for multi datasets
  const sharedSchemaFields = useMemo(
    () => getSharedSchemaFields(selectedDatasets),
    [selectedDatasets]
  );

  // Get custom classifier fields
  const customClassifierFields = useMemo(
    () => getCustomClassifierFields(sharedSchemaFields),
    [sharedSchemaFields]
  );

  const getFormattedFilters = (newFilters) => {
    return exportFilterBarFilters(newFilters, { mainVerbatimFieldName });
  };

  // Set default datasetIds and filters from URL or dashboard saved filters or location filters
  useEffect(() => {
    if (
      sharedSchemaFields.length > 0 &&
      (!hasFiltersInit ||
        filterCardProps.activeTabKey ||
        reInitWhenFiltersChanged)
    ) {
      setHasFiltersInit(true);
      dispatch({
        type: 'INIT_FILTERS',
        filters: importFilterBarFilters(
          initialFilters,
          sharedSchemaFields,
          useQueryInput
        ),
        timeFilter: initialTimeFilter,
      });
    }
  }, [
    sharedSchemaFields,
    hasFiltersInit,
    initialFilters,
    filterCardProps.activeTabKey,
    reInitWhenFiltersChanged,
    initialTimeFilter,
    useQueryInput,
  ]);

  // Get filterOptions for fields like 'channel', 'source', 'language', 'sentiment', 'entity_type'
  const filterOptions = useFilterOptions(sharedSchemaFields, selectedDatasets);

  // Get main verbatim field name and time field name
  const mainVerbatimFieldName = useMemo(
    () => getMainVerbatimFieldName(sharedSchemaFields),
    [sharedSchemaFields]
  );

  // Trigger Filter Apply when click Search or More Filters change
  const onMoreFiltersChange = (value, actionType) => {
    let newMoreFilters = [...moreFilters];
    if (actionType === 'add') {
      const newFilter = value;
      const isNewFilterExisted = moreFilters.find((filter) =>
        isEqual(filter, newFilter)
      );
      newMoreFilters = isNewFilterExisted
        ? newMoreFilters
        : [...moreFilters, newFilter];
    } else if (actionType === 'remove') {
      const removeIndex = value;
      newMoreFilters = [
        ...moreFilters.slice(0, removeIndex),
        ...moreFilters.slice(removeIndex + 1),
      ];
    }

    onFiltersApply({
      ...filters,
      moreFilters: newMoreFilters,
    });

    dispatch({ type: 'SET_MORE_FILTERS', moreFilters: newMoreFilters });

    if (onFilterChange) {
      onFilterChange(
        getFormattedFilters({
          ...filters,
          moreFilters: newMoreFilters,
        })
      );
    }

    setIsNewFilterPopoverOpen(false);
  };

  // Handle Clear Filters
  const onClearFilters = () => {
    dispatch({ type: 'CLEAR_FILTERS' });
    if (onClear) {
      onClear();
    }
    const urlSearchParams = queryStringToObject(location.search);
    const clearedSearchParams = omit(urlSearchParams, [
      'filters',
      'datasetIds',
      'timeFilter',
    ]);
    const newSearch = convertToURLParams(clearedSearchParams);
    history.push(`${location.pathname}${newSearch ? `?${newSearch}` : ''}`);
  };

  // Get default QueryInput value from URL
  const defaultQueryInputValue = useMemo(() => {
    const queryInputFilter = initialFilters.find(
      (filter) =>
        filter.attribute === 'otso_doc_body' &&
        filter.condition === 'query_string'
    );
    return queryInputFilter ? queryInputFilter.value : '';
  }, [initialFilters]);

  // Trigger Filter Apply for QueryInput
  const onQueryInputApply = (queryInputData = {}) => {
    const { moreFilters: newMoreFilters = [], rawSearch } = queryInputData;

    const newFilters = {
      ...filters,
      moreFilters: newMoreFilters,
    };

    if (rawSearch) {
      const existingSearchIndex = recentSearches.findIndex(
        (search) => search.search === rawSearch
      );
      if (existingSearchIndex > -1) {
        recentSearches[existingSearchIndex] = {
          time: new Date(),
          search: rawSearch,
        };
      } else {
        if (recentSearches.length >= 5) recentSearches.splice(0, 1);
        recentSearches.push({ time: new Date(), search: rawSearch });
      }
      setRecentSearches(recentSearches);

      const cachedRecentSearches = JSON.parse(
        localStore.getItem('recentSearches')
      );
      const newCachedRecentSearches = {
        ...(cachedRecentSearches && typeof cachedRecentSearches === 'object'
          ? cachedRecentSearches
          : {}),
        [projectSlug]: recentSearches,
      };
      localStore.setItem(
        'recentSearches',
        JSON.stringify(newCachedRecentSearches)
      );
    }

    onFiltersApply(newFilters);

    dispatch({
      type: 'SET_FILTERS',
      filters: newFilters,
    });
  };

  const onFiltersApply = (newFilters) => {
    const exportedFilters = exportFilterBarFilters(newFilters, {
      mainVerbatimFieldName,
    });

    trackEvent('Apply Filters', {
      timeFilterJSON: JSON.stringify(exportedFilters.timeFilter),
      filtersJSON: JSON.stringify(exportedFilters.filters),
      filtersLength: exportedFilters.filters.length,
      filtersAttributes: exportedFilters.filters.map(
        (filter) => filter.attribute
      ),
      filtersAt: dashboardSlug ? 'Dashboard' : 'Explore',
      isNewFilterUI: useQueryInput,
    });

    onApply({
      datasetIds:
        Array.isArray(newFilters.datasetIds) && newFilters.datasetIds.length > 0
          ? newFilters.datasetIds
          : selectedDatasetIds,
      filters: exportedFilters.filters,
      timeFilter: exportedFilters.timeFilter,
    });
  };

  // For share button and dashboard filter parent to get filters
  const getExportedFilters = () =>
    exportFilterBarFilters(filters, {
      mainVerbatimFieldName,
    });

  // Dashboard filter parent can now access exported filter from parent action
  useImperativeHandle(ref, () => ({
    getExportedFilters,
  }));

  // Add field displayName to moreFilters
  const formattedMoreFilters = useMemo(() => {
    const hiddenFilterAttributes = [];
    return moreFilters.reduce((prev, filter) => {
      // Hide first custom filter
      if (
        customClassifierFields.find(
          (customClassifierField) =>
            customClassifierField.name === filter.attribute
        ) &&
        filter.condition === '=' &&
        !hiddenFilterAttributes.includes(filter.attribute)
      ) {
        hiddenFilterAttributes.push(filter.attribute);
        return [...prev, { ...filter, hidden: true }];
      }
      // Hiden empty filter
      if (!getFilterDisplay(filter)) {
        return [...prev, { ...filter, hidden: true }];
      }
      // Hide query input filter
      if (
        filter.attribute === 'otso_doc_body' &&
        filter.condition === 'query_string'
      ) {
        return prev;
      }
      return [...prev, filter];
    }, []);
  }, [moreFilters, customClassifierFields]);

  const colProps = useMemo(() => {
    const defaultColProps = {
      datasets: { span: useQueryInput ? 5 : 6 },
      date: { span: useQueryInput ? 7 : 8 },
      textSearch: { span: useQueryInput ? 0 : 4 },
    };
    if (customClassifierFields.length === 0) {
      return useQueryInput || dashboardSlug
        ? {
            ...defaultColProps,
            actions: { span: 12 },
            filterTags: { span: 24 },
          }
        : {
            datasets: { span: 8 },
            date: { span: 10 },
            textSearch: { span: 6 },
            actions: { span: 12, push: 12 },
            filterTags: { span: 12, pull: 12 },
          };
    }
    if (
      customClassifierFields.length < (useQueryInput || dashboardSlug ? 3 : 2)
    ) {
      return {
        ...defaultColProps,
        actions: { span: 12, push: 12 },
        filterTags: { span: 12, pull: 12 },
      };
    }
    const overflowFieldsCount =
      (customClassifierFields.length -
        (useQueryInput || dashboardSlug ? 2 : 1)) %
      4;
    if (overflowFieldsCount > 2) {
      return {
        ...defaultColProps,
        actions: { span: 12, push: 12 },
        filterTags: { span: 12, pull: 12 },
      };
    }
    return {
      ...defaultColProps,
      actions: { span: 24 - 6 * overflowFieldsCount },
      filterTags: { span: 24 },
    };
  }, [dashboardSlug, customClassifierFields.length, useQueryInput]);

  const filterForm = (
    <Form {...(isOnProjectsPage && { component: false })}>
      <Row
        gutter={[preset.spacing(2), 0]}
        type="flex"
        css={{ padding: preset.spacing(2) }}
      >
        {/* Dataset filters, hide on the dashboard page and on project creation page */}
        {!dashboardSlug && !isOnProjectsPage && (
          <Col {...colProps.datasets}>
            <Form.Item>
              <FloatLabelContainer
                label="Datasets"
                floating={datasetIds.length > 0}
              >
                <Select
                  showSearch
                  showArrow
                  allowClear
                  maxTagCount={1}
                  maxTagTextLength={
                    datasetIds.length > 1
                      ? useQueryInput
                        ? 18
                        : 24
                      : useQueryInput
                      ? 24
                      : 30
                  }
                  mode="multiple"
                  optionFilterProp="children"
                  loading={isProjectDatasetsLoading}
                  value={datasetIds}
                  onChange={(value) =>
                    dispatch({
                      type: 'SET_DEFAULT_FILTER',
                      key: 'datasetIds',
                      value,
                    })
                  }
                  dropdownMatchSelectWidth={false}
                >
                  {projectDatasets.map((dataset) => (
                    <Option key={dataset.id} value={dataset.id}>
                      {dataset.name}
                    </Option>
                  ))}
                </Select>
              </FloatLabelContainer>
            </Form.Item>
          </Col>
        )}

        {/* Time filters */}
        <Col {...colProps.date}>
          <Form.Item>
            <FloatLabelContainer
              label="Date"
              floating={
                timeFilter
                  ? !!(timeFilter.from || timeFilter.to || timeFilter.relative)
                  : false
              }
              defaultStyle={{ left: 32 }}
            >
              <DateRangePicker
                value={timeFilter}
                onChange={(value) => {
                  dispatch({
                    type: 'SET_TIME_FILTER',
                    value,
                  });
                  if (onFilterChange) {
                    onFilterChange(
                      getFormattedFilters({
                        ...filters,
                        timeFilter: value,
                      })
                    );
                  }
                }}
              />
            </FloatLabelContainer>
          </Form.Item>
        </Col>

        {/* Text filters */}
        {allowTextSearch && (
          <Col {...colProps.textSearch}>
            <Form.Item>
              <FloatLabelContainer label="Text" floating={verbatim}>
                <Input
                  allowClear
                  disabled={!mainVerbatimFieldName}
                  value={verbatim}
                  onChange={(e) => {
                    dispatch({
                      type: 'SET_DEFAULT_FILTER',
                      key: 'verbatim',
                      value: e.target.value,
                    });
                    if (onFilterChange) {
                      onFilterChange(
                        getFormattedFilters({
                          ...filters,
                          verbatim: e.target.value,
                        })
                      );
                    }
                  }}
                />
              </FloatLabelContainer>
            </Form.Item>
          </Col>
        )}

        {/* Custom classifier filters */}
        {allowCustomClassifierFilters && (
          <CustomClassifierFilterFields
            customClassifierFields={customClassifierFields}
            moreFilters={moreFilters}
            documentFilterOptions={filterOptions.document}
            loading={filterOptions.loading}
            onChange={(newMoreFilters) => {
              dispatch({
                type: 'SET_MORE_FILTERS',
                moreFilters: newMoreFilters,
              });
              if (onFilterChange) {
                onFilterChange(
                  getFormattedFilters({
                    ...filters,
                    moreFilters: newMoreFilters,
                  })
                );
              }
            }}
          />
        )}

        <Col
          {...colProps.actions}
          {...(isOnProjectsPage && {
            span: 24,
            order: 2,
            css: { left: 0 },
          })}
        >
          <Form.Item css={{ textAlign: !isOnProjectsPage ? 'right' : 'left' }}>
            {/* More filters */}
            {allowMoreFilters && (
              <Popover
                placement="bottomLeft"
                trigger="click"
                visible={isNewFilterPopoverOpen}
                onVisibleChange={(visible) =>
                  setIsNewFilterPopoverOpen(visible)
                }
                content={
                  <NewFilterForm
                    filterOptions={filterOptions}
                    sharedSchemaFields={sharedSchemaFields}
                    datasetIds={selectedDatasetIds}
                    onNewFilterApply={(newFilter) =>
                      onMoreFiltersChange(newFilter, 'add')
                    }
                  />
                }
              >
                <Button
                  id="filter-bar-more-button"
                  type="link"
                  icon={<PlusOutlined />}
                  disabled={isProjectDatasetsLoading}
                >
                  {addMoreFiltersButtonText}
                </Button>
              </Popover>
            )}

            {/* Clear filters button */}
            <Button icon={<StopOutlined />} onClick={onClearFilters}>
              Clear Filters
            </Button>

            {/* Share filters button */}
            {allowShareFilters && (
              <FilterShareButton
                id="filter-bar-share-button"
                disabled={isProjectDatasetsLoading}
                datasetIds={selectedDatasetIds}
                getExportedFilters={getExportedFilters}
                filterSlug={dashboardSlug && filterCardProps.activeTabKey}
                css={{
                  marginLeft: preset.spacing(1),
                  marginRight: preset.spacing(1),
                }}
              />
            )}

            {/* Search button */}
            <Button
              id="filter-bar-search-button"
              type="primary"
              icon={searchButtonIcon}
              disabled={isProjectDatasetsLoading}
              loading={isSearchLoading}
              onClick={() => onFiltersApply(filters)}
              css={{
                marginLeft: !allowShareFilters ? preset.spacing(1) : 0,
              }}
            >
              {searchButtonText}
            </Button>
          </Form.Item>
        </Col>

        {/* Filter tags if there are more filters */}
        {allowMoreFilters && formattedMoreFilters.length > 0 && (
          <Col
            {...colProps.filterTags}
            {...(isOnProjectsPage && {
              order: 1,
              pull: 0,
              css: { marginBottom: preset.spacing(2) },
            })}
          >
            <FilterTags
              filters={formattedMoreFilters}
              sharedSchemaFields={sharedSchemaFields}
              onTagRemove={(index) => onMoreFiltersChange(index, 'remove')}
            />
          </Col>
        )}
      </Row>
    </Form>
  );

  return (
    <>
      {!useQueryInput ? (
        <Card bordered={false} {...filterCardProps} className={className}>
          {filterForm}
        </Card>
      ) : (
        <div>
          <Collapse
            ghost
            activeKey={isCollapseOpen ? 'filter_bar' : ''}
            className={className}
            css={{
              backgroundColor: '#fff',
              '.ant-collapse-item': {
                '& > .ant-collapse-header': {
                  padding: '0 !important',
                },
                '.ant-collapse-content > .ant-collapse-content-box': {
                  borderTop: preset.border,
                  padding: preset.spacing(3),
                },
              },
            }}
          >
            <Collapse.Panel
              key="filter_bar"
              collapsible="disabled"
              showArrow={false}
              header={
                <QueryInput
                  loading={isProjectDatasetsLoading || filterOptions.loading}
                  documentFilterOptions={filterOptions.document}
                  projectDatasets={projectDatasets}
                  defaultValue={defaultQueryInputValue}
                  onSearch={onQueryInputApply}
                  suffix={
                    <div
                      css={{
                        position: 'absolute',
                        zIndex: 2,
                        top: 7,
                        right: 10,
                        '.anticon': {
                          transition: preset.transition,
                          transform: isCollapseOpen ? 'rotate(180deg)' : 'none',
                        },
                      }}
                    >
                      <Badge
                        count={
                          moreFilters.length +
                          (verbatim ? 1 : 0) +
                          (formatEmptyObject(timeFilter) ? 1 : 0)
                        }
                        overflowCount={9}
                      >
                        <Button
                          type="text"
                          size="large"
                          onClick={() =>
                            setIsCollapseOpen((prevOpen) => !prevOpen)
                          }
                        >
                          Filters
                          <CaretDownOutlined css={{ color: colors.darkGrey }} />
                        </Button>
                      </Badge>
                    </div>
                  }
                  recentSearches={recentSearches}
                  sharedSchemaFields={sharedSchemaFields}
                  css={{
                    '.ant-select-auto-complete .ant-input-affix-wrapper': {
                      padding: '8px 144px 8px 16px !important',
                      '.ant-input-clear-icon': {
                        top: 22,
                        right: 120,
                      },
                    },
                  }}
                />
              }
            >
              {filterForm}
            </Collapse.Panel>
          </Collapse>
        </div>
      )}

      {/* Help message */}
      {helpMessage &&
        (isHelpAlertOpen ? (
          <Alert
            showIcon
            closable
            onClose={() => setIsHelpAlertOpen(false)}
            type="info"
            message={helpMessage}
            css={{
              marginTop: preset.spacing(2),
              alignItems: 'flex-start',
              '.ant-alert-icon': {
                marginTop: 5,
                marginRight: preset.spacing(2),
              },
            }}
          />
        ) : (
          <div css={{ textAlign: 'right', marginTop: preset.spacing(1) }}>
            <Button
              type="text"
              onClick={() => setIsHelpAlertOpen(true)}
              icon={<QuestionCircleOutlined />}
            >
              Help
            </Button>
          </div>
        ))}
    </>
  );
};

export default forwardRef(FilterBar);
