/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';

// Import Ant Design
import { Popover } from 'antd';

import { preset } from 'styles';

const ColorPicker = ({ color, onColorChange, className }) => {
  const [visible, setVisible] = useState(false);
  const [currentColor, setCurrentColor] = useState(color);

  useEffect(() => {
    setCurrentColor(color);
  }, [color]);

  return (
    <Popover
      content={<SketchPicker color={color} onChange={onColorChange} />}
      trigger="click"
      visible={visible}
      onVisibleChange={setVisible}
    >
      <div
        className={className}
        css={{
          width: preset.spacing(5),
          height: preset.spacing(3),
          padding: 4,
          cursor: 'pointer',
          border: preset.border,
        }}
      >
        <div
          css={{
            borderRadius: 2,
            background: currentColor,
            height: '100%',
          }}
        />
      </div>
    </Popover>
  );
};

export default ColorPicker;
