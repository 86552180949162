/** @jsxImportSource @emotion/react */
import isEqual from 'lodash/isEqual';
import { Space, Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import { colors } from 'styles';

const gradientColors = colors.chart.gradient;

const ColorPlateSelect = ({ color, onSelect }) => {
  const isGradientSelected = (gradientKey) => {
    return isEqual(gradientColors[gradientKey], color);
  };

  return (
    <Space>
      {Object.keys(gradientColors).map((gradientKey) => (
        <Button
          key={gradientKey}
          icon={
            isGradientSelected(gradientKey) && (
              <CheckOutlined css={{ color: '#fff' }} />
            )
          }
          onClick={() => onSelect(gradientColors[gradientKey])}
          css={{
            width: 120,
            background: `linear-gradient(
                            to right,
                            ${gradientColors[gradientKey].fromColor} 0%,
                            ${gradientColors[gradientKey].midColor} 50%,
                            ${gradientColors[gradientKey].toColor} 100%
                        ) !important`,
          }}
        >
          <span css={{ display: 'none !important' }}>{gradientKey}</span>
        </Button>
      ))}
    </Space>
  );
};

export default ColorPlateSelect;
