import React, { createContext, useState, useContext } from 'react';

import { FilterOptions, EnrichmentCorrectionExtraOptions } from 'types';

type ProviderProps = { children: React.ReactNode };

type ExploreContextType = {
  documentFilterOptions: FilterOptions;
  setDocumentFilterOptions: React.Dispatch<React.SetStateAction<FilterOptions>>;
  docCorrectionExtraOptions: EnrichmentCorrectionExtraOptions;
  setDocCorrectionExtraOptions: React.Dispatch<
    React.SetStateAction<EnrichmentCorrectionExtraOptions>
  >;
};

const initialState: ExploreContextType = {
  documentFilterOptions: {},
  setDocumentFilterOptions: () => { },
  docCorrectionExtraOptions: {},
  setDocCorrectionExtraOptions: () => { },
};

export const ExploreContext = createContext<ExploreContextType>(initialState);

export const useExplore = () => useContext(ExploreContext);

export const ExploreProvider: React.FC<ProviderProps> = ({ children }) => {
  const [documentFilterOptions, setDocumentFilterOptions] =
    useState<FilterOptions>(initialState.documentFilterOptions);
  const [docCorrectionExtraOptions, setDocCorrectionExtraOptions] =
    useState<EnrichmentCorrectionExtraOptions>(
      initialState.docCorrectionExtraOptions
    );

  return (
    <ExploreContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        documentFilterOptions,
        setDocumentFilterOptions,
        docCorrectionExtraOptions,
        setDocCorrectionExtraOptions,
      }}
    >
      {children}
    </ExploreContext.Provider>
  );
};
