/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import shortid from 'shortid';

import { Modal, message } from 'antd';

import { preset } from 'styles';
import { postApi, fetchApiErrorMessage } from '@modules/api';

import Charts from '../../Projects/ViewProject/Pages/Charts';

const mapStateToProps = (state) => {
  return {
    isChartSelectModalOpen: state.dashboard.isChartSelectModalOpen,
    dashboardCharts: state.dashboard.dashboardCharts,
    currentPageId: state.dashboard.currentPageId,
    dashboardPageIds: state.dashboard.pages.allIds,
  };
};

const ChartSelectModal = ({
  // redux state
  isChartSelectModalOpen,
  dashboardCharts,
  currentPageId,
  dashboardPageIds,
  // redux dispatch
  dispatch,
}) => {
  const { slug: projectSlug, dashboardSlug } = useParams();

  const newChartLayout = useMemo(() => {
    const currentPageVisIds = currentPageId
      ? dashboardCharts.byPageId[currentPageId] || []
      : [];
    const currentPageCharts = currentPageVisIds.map(
      (visId) => dashboardCharts.byId[visId]
    );

    const newChartY = currentPageCharts.reduce(
      (prev, vis) =>
        vis.layout.y + vis.layout.h > prev ? vis.layout.y + vis.layout.h : prev,
      0
    );

    return {
      i: shortid.generate(),
      x: 0,
      y: newChartY,
      w: 6,
      h: 5,
    };
  }, [currentPageId, dashboardCharts]);

  // Add project chart to dashboard
  const addChart = async (projectChartId) => {
    try {
      const { data = {} } = await postApi(
        `/projects/${projectSlug}/dashboards/${dashboardSlug}/pages/${currentPageId}`,
        {
          newDashboardVis: {
            visualisationId: projectChartId,
            layout: newChartLayout,
          },
        }
      );

      message.success('New Chart Added');

      const { id: newDashboardVisId, comments } = data;

      const updatedDashboardVis = {
        id: newDashboardVisId,
        layout: newChartLayout,
        page: { id: currentPageId },
        visualisation: { id: projectChartId },
        comments,
      };

      dispatch({
        type: 'ADD_DASHBOARD_CHART',
        chart: updatedDashboardVis,
      });

      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 0);
    } catch (error) {
      message.error(fetchApiErrorMessage(error, 'Unable to Add Chart'));
    }
  };

  const visualisationAlreadyInUse =
    dashboardCharts.byPageId && dashboardCharts.byPageId[currentPageId]
      ? dashboardCharts.byPageId[currentPageId].map(
          (chartId) => dashboardCharts.byId[chartId].visualisation.id
        )
      : [];

  return (
    <Modal
      title="Add Chart To Dashboard"
      visible={isChartSelectModalOpen}
      footer={null}
      onCancel={() =>
        dispatch({
          type: 'SET_DASHBOARD_MODAL_OPEN',
          key: 'isChartSelectModalOpen',
          open: false,
        })
      }
      width={preset.modalWidth.lg}
    >
      <Charts
        width={preset.modalWidth.lg}
        onSelect={(projectChartId) => addChart(projectChartId)}
        createChartFromDashboardInfo={{
          dashboard: dashboardSlug,
          page: dashboardPageIds.indexOf(currentPageId) + 1,
          pageId: currentPageId,
          layout: newChartLayout,
        }}
        fadeCharts={visualisationAlreadyInUse}
      />
    </Modal>
  );
};

export default connect(mapStateToProps)(ChartSelectModal);
