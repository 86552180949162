import formatDimensionLabel from './formatDimensionLabel';
import deepGet from '../deepGet';

const formatChartColorLabel = (label, option) => {
    const {
        chartType, defaultConfig,
        queryObj: { dimensions },
        schema,
    } = option;

    const defaultFormattedLabel = formatDimensionLabel(label, { labelConfig: defaultConfig.label });

    if (!Array.isArray(dimensions)) return defaultFormattedLabel;

    if (dimensions.length === 0) return defaultFormattedLabel;

    switch (chartType) {
        // One dimension charts
        case 'bar_basic':
        case 'pie_innerlabel':
        case 'pie_donut':
        case 'plotted_bubble':
        case 'treemap_rect':
        case 'word_cloud':
        case 'bubble': {
            const dateDimension = dimensions[0];
            return formatDimensionLabel(
                label, { timeInterval: deepGet(schema, [dateDimension.as, 'functionValue']) },
            );
        }

        // Two dimensions charts
        case 'bar_grouped':
        case 'bar_stackedColumn':
        case 'bar_stackedPercentage':
        case 'line_series':
        case 'area_series': {
            const { color } = defaultConfig;
            if (!color) return defaultFormattedLabel;
            return formatDimensionLabel(
                label, { timeInterval: deepGet(schema, [color, 'functionValue']) },
            );
        }

        default: {
            return defaultFormattedLabel;
        }
    }
};

export default formatChartColorLabel;
