/** @jsxImportSource @emotion/react */
import { useParams } from 'react-router-dom';
import queryString from 'query-string';
import copy from 'copy-to-clipboard';
import { useAuth } from '@otso/auth-wrapper';

import { Button, Popover } from 'antd';
import { ShareAltOutlined } from '@ant-design/icons';

import { trackEvent } from 'analytics';
import { convertToURLParams } from '@modules/queryStringConverter';
import { formatEmptyObject } from '@modules/items';

const FilterShareButton = ({
  id,
  disabled = false,
  datasetIds = [],
  getExportedFilters,
  filterSlug,
  className,
}) => {
  const { dashboardSlug } = useParams();

  const { user } = useAuth();

  const copyLinkToClipboard = () => {
    trackEvent('Copy Filters');

    const { filters, timeFilter } = getExportedFilters();

    const urlParamString = convertToURLParams({
      ...(filterSlug && filterSlug !== 'custom'
        ? {
            filterSlug,
          }
        : {
            filters,
            ...(formatEmptyObject(timeFilter)
              ? { timeFilter: formatEmptyObject(timeFilter) }
              : {}),
          }),
      ...(dashboardSlug ? {} : { datasetIds }),
    });

    copy(
      `${window.location.origin}${window.location.pathname}?${
        user?.currentOrg
          ? queryString.stringify({
              ...queryString.parse(urlParamString),
              org: user.currentOrg,
            })
          : urlParamString
      }`
    );
  };

  return (
    <Popover content="Link copied to clipboard" trigger="click">
      <Button
        id={id}
        icon={<ShareAltOutlined />}
        disabled={disabled}
        onClick={copyLinkToClipboard}
        className={className}
      >
        Share
      </Button>
    </Popover>
  );
};

export default FilterShareButton;
