/** @jsxImportSource @emotion/react */

// Import libraries
import { useMemo } from 'react';
import capitalize from 'lodash/capitalize';

// Import Ant Design components
import { Select, Input, Collapse, Form, Tag, InputNumber } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

// Import constants
import { TIME_INTERVALS } from 'constant';

// Import stylesheets
import { colors, preset } from 'styles';

// Import modules
import formatLabel from '@modules/formatLabel';

// Import components
import StyledCollapse from '../../StyledCollapse';

const { Panel } = Collapse;
const { Option, OptGroup } = Select;

const Dimensions = ({
  dimensions = [],
  metrics = [],
  onChange,
  onRemove,
  attributeOptions = {},
  showGeoLocationDimensions = false,
  // handle dimension/metric as field change
  onAsFieldChange,
}) => {
  const allTimeFieldNames = (attributeOptions.time || []).map(
    (timeField) => timeField.name
  );

  // Prepare order by options
  const orderByOptions = useMemo(
    () =>
      metrics.filter(
        (enteredField) => enteredField.attribute && enteredField.as
      ),
    [metrics]
  );

  // Handle dimension timeInterval change
  const onTimeIntervalChange = (newTimeInterval, index) => {
    if (newTimeInterval) {
      const newDateTruncateValue = TIME_INTERVALS.truncate.find(
        (item) => item.value === newTimeInterval
      );
      const newDateExtractValue = TIME_INTERVALS.extract.find(
        (item) => item.value === newTimeInterval
      );
      if (newDateTruncateValue) {
        onChange('date_trunc', 'sqlFunction', index);
        onChange(newTimeInterval, 'functionValue', index);
      } else if (newDateExtractValue) {
        onChange('extract', 'sqlFunction', index);
        onChange(newTimeInterval, 'functionValue', index);
      }
    } else {
      onChange(null, 'sqlFunction', index);
      onChange(null, 'functionValue', index);
    }
  };

  // Handle dimension attribute change
  const onDimensionAttributeChange = (
    oldDimension,
    newAttributeOption,
    index
  ) => {
    // Extract values from the new attribute option
    const { value: newAttribute, children: newAttributeDisplay } =
      newAttributeOption;

    // Update the 'attribute' of dimension
    onChange(newAttribute, 'attribute', index);

    // Update the 'as' of dimension
    let defaultAs =
      (newAttribute === 'otso_doc_publish_date'
        ? newAttributeDisplay[0]
        : newAttributeDisplay) || formatLabel(newAttribute);
    if (dimensions.find((dim) => dim.as === defaultAs)) {
      defaultAs = `${defaultAs} 2`;
    }
    onChange(defaultAs, 'as', index);

    // Set timeInterval and add default orderBy time asc if a time dimension is selected
    if (allTimeFieldNames.length > 0) {
      if (allTimeFieldNames.includes(newAttribute)) {
        onTimeIntervalChange('auto', index);
        onChange(undefined, 'orderBy', index);
        onChange(undefined, 'orderByDirection', index);
      } else if (
        !dimensions.find(
          (dims, dimsIndex) =>
            allTimeFieldNames.includes(dims.attribute) && dimsIndex !== index
        )
      ) {
        onTimeIntervalChange(null, index);
        onChange('Alphabetical', 'orderBy', index);
        onChange('asc', 'orderByDirection', index);
      } else {
        onChange('Alphabetical', 'orderBy', index);
        onChange('asc', 'orderByDirection', index);
      }
    }

    // Update groupBy and orderBy dimensions reference
    onAsFieldChange(
      oldDimension.as,
      defaultAs,
      allTimeFieldNames.length > 0 && allTimeFieldNames.includes(newAttribute)
    );
  };

  const onDimensionAsChange = (
    oldAs,
    newAs,
    index,
    isTimeDimension = false
  ) => {
    onChange(newAs, 'as', index);

    // Update groupBy and orderBy dimensions reference
    onAsFieldChange(oldAs, newAs, isTimeDimension);
  };

  const onDimensionAsBlur = (oldAs, newAs, index, isTimeDimension = false) => {
    if (newAs && newAs !== newAs.trim()) {
      onDimensionAsChange(oldAs, newAs.trim(), index, isTimeDimension);
    }
  };

  const onDimensionRemove = (dimension, index) => {
    onRemove(index);

    // Need also remove related groupBy when remove dimension
    if (dimension.as) {
      onAsFieldChange(dimension.as);
    }
  };

  return (
    <StyledCollapse>
      {dimensions.map((dimension, index) => (
        <Panel
          key={`dimension_${index + 1}`}
          header={dimension.as || `Dimension ${index + 1}`}
          extra={[
            <DeleteOutlined
              key="delete"
              onClick={(e) => {
                e.stopPropagation();
                onDimensionRemove(dimension, index);
              }}
            />,
          ]}
        >
          <Form.Item label="Attribute" required>
            <Select
              showSearch
              placeholder="Select Attribute"
              optionFilterProp="children"
              value={dimension.attribute}
              onChange={(newAttribute, newAttributeOption) =>
                onDimensionAttributeChange(dimension, newAttributeOption, index)
              }
            >
              {showGeoLocationDimensions
                ? (attributeOptions['Geo Location Dimensions'] || []).map(
                    (field) => (
                      <Option key={field.name} value={field.name}>
                        {field.displayName || formatLabel(field.name)}
                      </Option>
                    )
                  )
                : Object.keys(attributeOptions).map(
                    (optionGroupKey) =>
                      !optionGroupKey.toLowerCase().includes('metrics') &&
                      attributeOptions[optionGroupKey].length > 0 && (
                        <OptGroup
                          key={optionGroupKey}
                          label={capitalize(optionGroupKey)}
                        >
                          {attributeOptions[optionGroupKey].map((field) =>
                            field.name === 'otso_doc_publish_date' ? (
                              <Option key={field.name} value={field.name}>
                                {field.displayName || 'Date'}
                                <Tag
                                  color={colors.primary}
                                  css={{ marginLeft: preset.spacing(1) }}
                                >
                                  Default
                                </Tag>
                              </Option>
                            ) : (
                              <Option key={field.name} value={field.name}>
                                {field.displayName || formatLabel(field.name)}
                              </Option>
                            )
                          )}
                        </OptGroup>
                      )
                  )}
            </Select>
          </Form.Item>

          {dimension.attribute &&
            allTimeFieldNames.includes(dimension.attribute) && (
              <Form.Item label="Time Interval" required>
                <Select
                  showSearch
                  placeholder="Select Time Interval"
                  optionFilterProp="children"
                  value={dimension.functionValue}
                  onChange={(val) => onTimeIntervalChange(val, index)}
                >
                  <OptGroup label="Truncate">
                    {TIME_INTERVALS.truncate.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </OptGroup>
                  <OptGroup label="Extract">
                    {TIME_INTERVALS.extract.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </OptGroup>
                </Select>
              </Form.Item>
            )}

          {/* Dimension As */}
          <Form.Item label="As" required>
            <Input
              value={dimension.as}
              onChange={(e) =>
                onDimensionAsChange(
                  dimension.as,
                  e.target.value,
                  index,
                  dimension.attribute &&
                    allTimeFieldNames.includes(dimension.attribute)
                )
              }
              onBlur={(e) =>
                onDimensionAsBlur(
                  dimension.as,
                  e.target.value,
                  index,
                  dimension.attribute &&
                    allTimeFieldNames.includes(dimension.attribute)
                )
              }
            />
          </Form.Item>

          {/* Dimension Order By */}
          {!allTimeFieldNames.includes(dimension.attribute) && (
            <>
              <Form.Item label="Order By">
                <Select
                  showSearch
                  disabled={orderByOptions.length === 0}
                  placeholder="Select order by"
                  optionFilterProp="children"
                  value={dimension.orderBy}
                  onChange={(newAttribute) =>
                    onChange(newAttribute, 'orderBy', index)
                  }
                >
                  {orderByOptions.map((enteredField) => (
                    <Option
                      key={`entered_field_${enteredField.attribute}`}
                      value={enteredField.as}
                    >
                      {enteredField.as}
                    </Option>
                  ))}
                  <Option key="entered_field_alphabetical" value="Alphabetical">
                    Alphabetical
                  </Option>
                </Select>
              </Form.Item>

              <Form.Item label="Direction">
                <Select
                  disabled={orderByOptions.length === 0}
                  value={dimension.orderByDirection}
                  onChange={(val) => onChange(val, 'orderByDirection', index)}
                  placeholder="Select Direction"
                >
                  <Option value="asc">Ascending</Option>
                  <Option value="desc">Descending</Option>
                </Select>
              </Form.Item>

              {/* Dimension Limit */}
              <Form.Item label="Limit">
                <InputNumber
                  value={dimension.limit}
                  onChange={(value) => onChange(value, 'limit', index)}
                  min={1}
                  max={2000}
                />
              </Form.Item>
            </>
          )}
        </Panel>
      ))}
    </StyledCollapse>
  );
};

export default Dimensions;
