import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import numeral from 'numeral';

import { preset } from 'styles';

class MeltwaterUsageEstimate extends PureComponent {
  render() {
    const {
      onRecalculate,
      estimateLengthDays,
      shouldRecalculate,
      estimatedUsage,
      usageLoading,
      searchId,
      startDate,
      endDate,
    } = this.props;

    const recalculate = (
      <a href="#re-calculate" onClick={onRecalculate}>
        Re-calculate usage
      </a>
    );

    const usageDescription = () => {
      if (usageLoading) return 'Estimating usage...';
      if (!searchId) return 'Select a search to calculate the estimated usage.';
      return startDate && endDate ? (
        <div>
          {`Estimated usage is ${numeral(estimatedUsage).format(
            '0,0'
          )} documents between ${startDate} and ${endDate}.`}
          <br />
          {onRecalculate && shouldRecalculate ? recalculate : null}
        </div>
      ) : (
        <div>
          {`Estimated usage is ${numeral(estimatedUsage).format(
            '0,0'
          )} documents over ${estimateLengthDays} days.`}
          <br />
          {onRecalculate && shouldRecalculate ? recalculate : null}
        </div>
      );
    };

    return (
      <Alert
        message="Estimated Usage"
        description={usageDescription()}
        type="info"
        closable={false}
        style={{ marginBottom: preset.spacing(3) }}
      />
    );
  }
}

MeltwaterUsageEstimate.propTypes = {
  searchId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  usageLoading: PropTypes.bool,
  estimateLengthDays: PropTypes.number,
  shouldRecalculate: PropTypes.bool,
  estimatedUsage: PropTypes.number,
  onRecalculate: PropTypes.func,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

MeltwaterUsageEstimate.defaultProps = {
  searchId: null,
  usageLoading: false,
  estimateLengthDays: 0,
  shouldRecalculate: false,
  estimatedUsage: 0,
  onRecalculate: null,
  startDate: null,
  endDate: null,
};

export default MeltwaterUsageEstimate;
