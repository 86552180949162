/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';

import { Collapse } from 'antd';

import StyledCollapse from '../../StyledCollapse';
import AxisPanel from './AxisPanel';
import LabelPanel from './LabelPanel';
import ColorPanel from './ColorPanel';
import ColorBySelect from './ColorBySelect';
import RangeColorPanel from './RangeColorPanel';

const { Panel } = Collapse;
const ColorPanelBody = ColorPanel.Body;
const RangeColorPanelBody = RangeColorPanel.Body;

const BarBasicConfig = ({
    chart,
    canShowEmptyDate = false,
    dispatch,
}) => {
    const {
        defaultConfig = {},
        defaultColor = {},
    } = chart;

    const hasDimension = !!defaultConfig.x;

    const colorByOptions = useMemo(() => {
        if (hasDimension) {
            return [
                { key: defaultConfig.x, label: defaultConfig.x },
                { key: defaultConfig.y, label: defaultConfig.y },
                { key: 'one colour', label: 'One colour' },
            ];
        }

        return [
            { key: 'label', label: 'Label' },
            { key: 'value', label: 'Value' },
        ];
    }, [hasDimension, defaultConfig]);

    const colorByMetric = defaultConfig.colorBy === (hasDimension ? defaultConfig.y : 'value');

    const colorPanelConfig = defaultConfig.colorBy === 'one colour' ? {
        colors: { bar: defaultColor.bar },
        capitaliseLabel: true,
    } : { chart };

    return (
        <StyledCollapse>
            <AxisPanel
                defaultConfig={defaultConfig}
                dispatch={dispatch}
                options={{
                    canTranspose: true,
                    canShowEmptyDate,
                    canLogScale: true,
                    rangeKeys: ['y'],
                }}
            />

            <LabelPanel
                chart={chart}
                dispatch={dispatch}
                options={{ canDisplayLabel: true }}
            />

            <Panel
                key="chart_color"
                header="Chart Colour"
            >
                <ColorBySelect
                    options={colorByOptions}
                    value={defaultConfig.colorBy}
                    onChange={val => dispatch({
                        type: 'SET_CONFIG',
                        value: val,
                        key: 'colorBy',
                    })}
                />

                {colorByMetric ? (
                    <RangeColorPanelBody
                        defaultConfig={defaultConfig}
                        defaultColor={defaultColor}
                        dispatch={dispatch}
                    />
                ) : (
                    <ColorPanelBody
                        {...colorPanelConfig}
                        dispatch={dispatch}
                    />
                )}
            </Panel>
        </StyledCollapse>
    );
};

export default BarBasicConfig;
