/** @jsxImportSource @emotion/react */
import {
    useRef, useMemo, useState, useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
    Chart, Axis, Tooltip, Legend,
} from 'bizcharts';

import formatDimensionLabel from '@modules/chart/formatDimensionLabel';
import getXAxisLabelConfig from '@modules/chart/getXAxisLabelConfig';
import formatBarChartData from '@modules/chart/formatBarChartData';
import getYAxisScale from '@modules/chart/getYAxisScale';
import getDualBarSize from '@modules/chart/getDualBarSize';
import useChartRef from '@modules/hooks/useChartRef';
import deepGet from '@modules/deepGet';
import getChartElement from '@modules/chart/getChartElement';

import CoordConfig from '../components/CoordConfig';
import ChartComments from '../components/ChartComments';
import CustomGeom from './components/CustomGeom';

const DoubleAxes = ({
    width,
    height,
    config,
    color,
    queryObj,
    data,
    schema,
    drillDownDisabled = false,
    // chart download
    renderer,
    setChartDownloadInstance,
    // dashboard chart comments
    comments = [],
    commentsShowing = false,
    commentsEditing = false,
    // redux dispatch for triggering drilldown
    dispatch,
}) => {
    const {
        x, bar, line, left, right, metricDisplay,
        reverse = false, label: labelConfig, axis: axisConfig,
    } = config;

    const { dimensions } = queryObj;

    const [barSize, setBarSize] = useState();

    // Init chart instance
    const chart = useRef();

    // Re-init chart when width changes and pass chartRef to download modal
    useChartRef(chart, width, setChartDownloadInstance);

    // Get chart config
    const xAxisLabelConfig = useMemo(() => getXAxisLabelConfig(config), [config]);
    const legendDisplay = config.legend ? config.legend.display : true;
    const legendPosition = config.legend ? config.legend.config.position : 'top';
    const hasTimeDimension = !!dimensions.find(dimension => dimension.functionValue);
    const showEmptyDate = config.showEmptyDate && hasTimeDimension;
    const leftMetricKey = left || bar;
    const rightMetricKey = right || line;

    // Config how left/right geom displays
    const geomConfig = useMemo(() => ({
        left: {
            metricKey: leftMetricKey,
            display: deepGet(metricDisplay, ['left']) || 'bar',
            color: color[leftMetricKey] || color.bar,
        },
        right: {
            metricKey: rightMetricKey,
            display: deepGet(metricDisplay, ['right']) || 'line',
            color: color[rightMetricKey] || color.line,
        },
    }), [color, leftMetricKey, metricDisplay, rightMetricKey]);

    // Remove empty x and insert empty date when needed
    const formattedData = useMemo(() => formatBarChartData({
        data, x, queryObj, showEmptyDate, schema,
    }), [data, queryObj, showEmptyDate, x, schema]);

    const isDualBarChart = useMemo(() => (
        geomConfig.left.display === 'bar' && geomConfig.right.display === 'bar'
    ), [geomConfig.left.display, geomConfig.right.display]);

    // Config dual bar shape
    useEffect(() => {
        if (chart.current && isDualBarChart) {
            const calculatedBarSize = getDualBarSize(chart.current);
            setBarSize(calculatedBarSize);
        } else {
            setBarSize();
        }
    }, [isDualBarChart]);

    // Config chart scale
    const scale = useMemo(() => {
        return {
            [x]: {
                formatter: val => formatDimensionLabel(val, {
                    timeInterval: deepGet(schema, [x, 'functionValue']),
                    labelConfig,
                }),
                ...(
                    deepGet(geomConfig, ['left', 'display']) !== 'bar'
                    && deepGet(geomConfig, ['right', 'display']) !== 'bar'
                ) ? { range: [0, 1] } : {},
            },
            [leftMetricKey]: getYAxisScale({
                axisConfig, yAxisKey: 'left', data, y: leftMetricKey,
            }),
            [rightMetricKey]: getYAxisScale({
                axisConfig, yAxisKey: 'right', data, y: rightMetricKey,
            }),
        };
    }, [axisConfig, data, geomConfig, labelConfig, leftMetricKey, rightMetricKey, schema, x]);

    // Config legend items
    const legendItems = useMemo(() => ['left', 'right'].map(key => {
        const { metricKey, display, color: legendColor } = geomConfig[key];
        return {
            value: metricKey,
            marker: display === 'bar' ? {
                symbol: 'square',
                fill: legendColor,
                radius: 5,
            } : display === 'line' ? {
                symbol: 'hyphen',
                stroke: legendColor,
                radius: 5,
                lineWidth: 3,
            } : {
                symbol: 'triangle',
                fill: legendColor,
                radius: 6,
            },
        };
    }), [geomConfig]);

    // Custom double axe legend click
    const onLegendClick = ev => {
        if (chart.current) {
            const { item: { value }, checked } = ev;
            const geoms = chart.current.getAllGeoms();

            for (let i = 0; i < geoms.length; i += 1) {
                const geom = geoms[i];
                if (geom.getYScale().field === value) {
                    if (checked) {
                        geom.show();
                    } else {
                        geom.hide();
                    }
                }
            }
        }
    };

    // Collect bar/point click data for drilldown
    const onBarAndPointClick = ev => {
        const targetData = ev.data ? ev.data._origin : {};
        if (!drillDownDisabled) {
            dispatch({
                type: 'SET_DRILLDOWN',
                drilldown: {
                    x: ev.x,
                    y: ev.y,
                    chartElement: getChartElement(ev, chart.current),
                    targetData,
                    queryObj,
                },
            });
        } else if (commentsEditing) {
            dispatch({
                type: 'SET_DASHBOARD_CHART_COMMENT_POINT',
                point: {
                    dimension: targetData[x],
                    metric: targetData[leftMetricKey],
                },
            });
        }
    };

    return (
        <Chart
            height={height}
            data={formattedData}
            scale={scale}
            padding="auto"
            renderer={renderer}
            forceFit
            onIntervalClick={ev => onBarAndPointClick(ev)}
            onPointClick={ev => onBarAndPointClick(ev)}
            onGetG2Instance={g2Chart => { chart.current = g2Chart; }}
        >
            <CoordConfig option={{ reverse }} />
            <Axis
                name={x}
                label={xAxisLabelConfig}
            />
            <Axis
                name={leftMetricKey}
                position={reverse ? 'right' : 'left'}
            />
            <Axis
                name={rightMetricKey}
                grid={null}
                position={reverse ? 'left' : 'right'}
            />
            {legendDisplay && (
                <Legend
                    custom
                    allowAllCanceled
                    position={legendPosition}
                    items={legendItems}
                    onClick={ev => onLegendClick(ev)}
                />
            )}
            <Tooltip />

            {['left', 'right'].map(positionKey => (
                <CustomGeom
                    key={positionKey}
                    position={positionKey}
                    {...geomConfig[positionKey]}
                    x={x}
                    barSize={barSize}
                    isDualBarChart={isDualBarChart}
                    cursor={(drillDownDisabled && !commentsEditing) ? 'auto' : 'pointer'}
                />
            ))}

            {/* Dashboard chart comments */}
            <ChartComments
                visible={commentsShowing}
                comments={comments}
                data={formattedData}
                x={x}
                y={leftMetricKey}
            />
        </Chart>
    );
};

export default connect()(DoubleAxes);
