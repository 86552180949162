/** @jsxImportSource @emotion/react */
import {
  Divider,
  Space,
  Button,
  Dropdown,
  Menu,
  Popover,
  Typography,
  Rate,
  Tag,
  Row,
  Col,
} from 'antd';
import {
  EllipsisOutlined,
  LinkOutlined,
  ExportOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { trackEvent } from 'analytics';
import { preset, colors } from 'styles';
import { formatDateInTZ, useCheckPermission } from 'modules';

import DocSourceLogo from '@components/DocSourceLogo';

import CustomClassifierTags from './CustomClassifierTags';
import DocumentAttachment from './DocumentAttachment';

const DocumentCollapseHeader = ({
  document = {},
  dataset,
  keyFields,
  hasEnrichmentCorrection = false,
  actions = {},
}) => {
  const {
    name: datasetName,
    integrationMeta: { type: integrationType, audio: isAudioDataset },
  } = dataset;

  const { source, title, verbatim, author, isDialogue, link, primary, rating } =
    keyFields;

  const { copyDocumentLink, deleteDocument } = actions;

  const canDeleteDocument = useCheckPermission(['insight.viewDocuments']);

  const primaryFieldValue = primary
    ? document[primary]
    : author && document[author];

  const verbatimText = isDialogue
    ? 'Conversation between Agent and Customer'
    : document.otso_doc_body || document[verbatim];

  const onMenuClick = ({ key }) => {
    switch (key) {
      case 'copy_link':
        copyDocumentLink(document.id);
        break;
      case 'delete_document':
        deleteDocument();
        break;
      default:
        break;
    }
  };

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'start',
        paddingLeft: 12,
        paddingRight: 12,
      }}
    >
      <div
        css={{
          flex: 1,
          display: 'flex',
          alignItems: 'flex-start',
          marginRight: preset.spacing(isAudioDataset ? 5 : 3),
        }}
      >
        <DocSourceLogo
          source={isDialogue ? 'dialogue' : document[source] || integrationType}
          css={{
            marginRight: preset.spacing(2),
            fontSize: 16,
          }}
        />
        <div
          css={{
            flex: 1,
            'div.ant-typography': {
              marginBottom: preset.spacing(1),
              wordBreak: 'break-word',
            },
          }}
        >
          <Row justify="space-between">
            <Col>
              <Space
                split={<Divider type="vertical" />}
                css={{ marginBottom: preset.spacing(1) }}
              >
                {/* Document dataset name */}
                <Typography.Text type="secondary" strong>
                  {datasetName}
                </Typography.Text>

                {/* Document primary field */}
                {(primaryFieldValue ||
                  typeof primaryFieldValue === 'number') && (
                  <Typography.Text type="secondary">
                    {primaryFieldValue.toString()}
                  </Typography.Text>
                )}

                {/* Document publish date */}
                <Typography.Text type="secondary">
                  {formatDateInTZ(
                    document.otso_doc_publish_date_with_tz ||
                      document.otso_doc_publish_date,
                    'minute',
                    '/'
                  )}
                </Typography.Text>

                {/* Document score */}
                {document.otso_doc_score && (
                  <Typography.Text type="secondary">
                    Relevance Score: {document.otso_doc_score}
                  </Typography.Text>
                )}
              </Space>
            </Col>

            {document.attachments?.length > 0 && (
              <Col>
                <Space wrap>
                  <Typography.Text type="secondary">
                    Attachments:
                  </Typography.Text>
                  <DocumentAttachment
                    downloadable
                    attachment={document.attachments[0]}
                  />
                  {document.attachments.length > 1 && (
                    <Tag
                      style={{
                        marginLeft: -preset.spacing(1),
                        background: colors.white,
                        borderStyle: 'dashed',
                        borderRadius: '8px',
                      }}
                    >
                      +{document.attachments.length - 1}
                    </Tag>
                  )}
                </Space>
              </Col>
            )}
          </Row>

          {title && document[title] && (
            <div css={{ marginTop: -4, marginBottom: 4 }}>
              <Typography.Text strong>{document[title]}</Typography.Text>
            </div>
          )}
          {rating && typeof document[rating] === 'number' && (
            <div
              css={{
                marginTop: -4,
                marginBottom: 4,
                '.ant-rate': { fontSize: 18 },
              }}
            >
              <Rate value={document[rating]} allowHalf disabled />
            </div>
          )}
          {(document.otso_doc_highlight || verbatimText) && (
            <Typography.Paragraph
              ellipsis={{ rows: 4, expandable: true }}
              css={{ whiteSpace: 'pre-line' }}
            >
              {!document.otso_doc_highlight ? (
                verbatimText
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: document.otso_doc_highlight,
                  }}
                />
              )}
            </Typography.Paragraph>
          )}
          <CustomClassifierTags
            document={document}
            hasEnrichmentCorrection={hasEnrichmentCorrection}
            dataset={dataset}
          />
        </div>
      </div>

      <div role="presentation" onClick={(e) => e.stopPropagation()}>
        <Space>
          {link && document[link] && (
            <Button
              type="link"
              size="small"
              icon={<ExportOutlined />}
              href={document[link]}
              target="_blank"
              onClick={() =>
                trackEvent('Go To Original', {
                  link: document[link],
                  documentId: document.id,
                })
              }
            >
              Go to original
            </Button>
          )}
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu onClick={onMenuClick}>
                <Menu.Item key="copy_link" icon={<LinkOutlined />}>
                  <Popover content="Link copied to clipboard" trigger="click">
                    Copy link
                  </Popover>
                </Menu.Item>
                {canDeleteDocument && (
                  <Menu.Item key="delete_document" icon={<DeleteOutlined />}>
                    Delete document
                  </Menu.Item>
                )}
              </Menu>
            }
          >
            <Button size="small" icon={<EllipsisOutlined />} />
          </Dropdown>
        </Space>
      </div>
    </div>
  );
};

export default DocumentCollapseHeader;
