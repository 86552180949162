export const initialState = {
    query: {
        page: 1,
        q: undefined,
        createdBy: 'all',
    },
};

export const chartsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_QUERY':
            return {
                ...state,
                query: {
                    ...state.query,
                    ...action.query,
                },
            };

        default:
            return state;
    }
};
