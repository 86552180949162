/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/** @jsxImportSource @emotion/react */
import debounce from 'lodash/debounce';

const Bubble = ({
    transform,
    fontSize,
    label,
    color,
    radius,
    onMouseOver,
    onMouseOut,
    onClick,
}) => {
    return (
        <g
            transform={transform}
            onMouseOver={debounce(onMouseOver, 300)}
            onMouseOut={debounce(onMouseOut, 300)}
            onClick={onClick}
        >
            <circle
                r={radius}
                fill={color}
                stroke={color}
                strokeWidth="2"
            />
            <text
                dy={fontSize / 3}
                fill="#fff"
                textAnchor="middle"
                fontSize={`${fontSize}px`}
                fontWeight="bold"
            >
                {label}
            </text>
        </g>
    );
};

export default Bubble;
