/** @jsxImportSource @emotion/react */
import { Global } from '@emotion/react';
import { useLocation } from 'react-router-dom';
import queryString from 'qs';
import { useAuth } from '@otso/auth-wrapper';

import { PageHeader, Button } from 'antd';

import { preset } from 'styles';
import { formatDateInTZ } from '@modules/date/formatDate';
import { convertToURLParams } from '@modules/queryStringConverter';
import OrgLogo from '@components/Logo';
import Container from '@components/Container';
import RichTextWidget from '@components/Charts/RichTextWidget';

export const DashboardPrintHeader = () => {
  const location = useLocation();

  const { user } = useAuth();

  const {
    page: pageIndexStr,
    title: pdfPageTitle,
    filters,
    timeFilter,
    filterSlug,
    summary,
  } = queryString.parse(location.search);

  const dashboardUrlString = convertToURLParams({
    filters,
    timeFilter,
    filterSlug,
    ...(pageIndexStr ? { page: pageIndexStr } : {}),
    org: user?.currentOrg,
  });

  return (
    <>
      <Global
        styles={{
          header: { display: 'none !important' },
          '.sub-header': { display: 'none !important' },
        }}
      />
      <PageHeader
        ghost={false}
        title={
          <span css={{ display: 'flex', alignItems: 'center' }}>
            <OrgLogo
              orgSlug={user?.currentOrg}
              theme="dark"
              placement="header"
            />
            {pdfPageTitle}
          </span>
        }
        extra={[
          <Button
            key="dashboard_link"
            type="link"
            target="_blank"
            href={`${window.location.origin}${window.location.pathname}?${dashboardUrlString}`}
          >
            Go to dashboard
          </Button>,
        ]}
        css={{ marginBottom: preset.spacing(3) }}
      />

      {summary && (
        <Container>
          <div
            css={{
              width: '50%',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginBottom: preset.spacing(3),
            }}
          >
            <RichTextWidget data={[{ html: summary }]} />
          </div>
        </Container>
      )}
    </>
  );
};

export const DashboardPrintFooter = () => {
  return (
    <div
      css={{
        height: preset.spacing(5),
        display: 'flex',
        alignItems: 'center',
        fontSize: 10,
      }}
    >
      <Container>
        {`Created at: ${formatDateInTZ('now', 'day', '/')}`}
      </Container>
    </div>
  );
};
