import getDateBoundary from './getDateBoundary';
import formatDimensionLabel from './formatDimensionLabel';
import { formatDateInTZ } from '../date/formatDate';

const getDateAxisTickInterval = (timeInterval = '') => {
    switch (timeInterval) {
        case 'year':
            return 12 * 31 * 24 * 3600 * 1000;
        case 'month':
            return 31 * 24 * 3600 * 1000;
        case 'week':
            return 7 * 24 * 3600 * 1000;
        case 'day':
            return 24 * 3600 * 1000;
        case 'hour':
            return 3600 * 1000;
        case 'minute':
            return 60 * 1000;
        case 'second':
            return 1000;
        default:
            return 24 * 3600 * 1000;
    }
};

const getXAxisScale = (config = {}) => {
    const {
        timeInterval,
        labelConfig,
        showEmptyDate = false,
        data = [],
        x,
        queryObj,
        schema,
    } = config;

    const xScale = showEmptyDate ? {
        type: 'time',
        mask: 'YYYY-MM-DDTHH:mm:ss',
        tickInterval: getDateAxisTickInterval(timeInterval),
        ...getDateBoundary({
            data, x, queryObj, schema,
        }),
        formatter: val => formatDateInTZ(val, timeInterval),
    } : {
        formatter: val => formatDimensionLabel(val, { timeInterval, labelConfig }),
        range: [0, 1],
    };

    return xScale;
};

export default getXAxisScale;
