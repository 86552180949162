/** @jsxImportSource @emotion/react */
import { Tooltip, TooltipProps } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

type Props = {
  className?: string;
  text: string;
  tip: string;
  placement?: TooltipProps['placement'];
};

const CXToolTip: React.FC<Props> = ({
  className,
  text,
  tip,
  placement = 'rightTop',
}) => {
  return (
    <span className={className}>
      {text}
      &nbsp;
      {tip && (
        <Tooltip placement={placement} title={tip}>
          <QuestionCircleOutlined />
        </Tooltip>
      )}
    </span>
  );
};

export default CXToolTip;
