/** @jsxImportSource @emotion/react */

import { Collapse, Row, Col, Form, Select } from 'antd';

import { preset } from 'styles';
import deeepGet from '@modules/deepGet';
import StyledCollapse from '../../StyledCollapse';
import AxisPanel from './AxisPanel';
import LabelPanel from './LabelPanel';
import LegendPanel from './LegendPanel';
import ColorPanel from './ColorPanel';

const { Panel } = Collapse;
const FormItem = Form.Item;
const { Option } = Select;

const LEGACY_METRIC_KEY = {
  left: 'bar',
  right: 'line',
};

const DoubleAxesConfig = ({ chart, dispatch, canShowEmptyDate = false }) => {
  const { defaultConfig = {} } = chart;

  const metricDisplayConfig = deeepGet(defaultConfig, ['metricDisplay']) || {};

  return (
    <StyledCollapse>
      <AxisPanel
        defaultConfig={defaultConfig}
        dispatch={dispatch}
        options={{
          canShowEmptyDate,
          rangeKeys: ['left', 'right'],
          canChangePosition: false,
        }}
      />

      <Panel key="metrics_display" header="Metrics Display">
        <Row gutter={preset.spacing(3)}>
          {['left', 'right'].map((metricKey) => (
            <Col key={metricKey} span={12}>
              <FormItem
                label={`Display '${
                  defaultConfig[metricKey] ||
                  defaultConfig[LEGACY_METRIC_KEY[metricKey]]
                }' data as`}
              >
                <Select
                  placeholder={metricKey === 'left' ? 'Bar' : 'Line'}
                  value={metricDisplayConfig[metricKey]}
                  onChange={(value) =>
                    dispatch({
                      type: 'SET_CONFIG',
                      value,
                      key: 'metricDisplay',
                      childKey: metricKey,
                    })
                  }
                >
                  <Option value="bar">Bar</Option>
                  <Option value="line">Line</Option>
                  <Option value="area">Area</Option>
                </Select>
              </FormItem>
            </Col>
          ))}
        </Row>
      </Panel>

      <LabelPanel chart={chart} dispatch={dispatch} />
      <LegendPanel defaultConfig={defaultConfig} dispatch={dispatch} />
      <ColorPanel capitaliseLabel chart={chart} dispatch={dispatch} />
    </StyledCollapse>
  );
};

export default DoubleAxesConfig;
