/** @jsxImportSource @emotion/react */

// Import libraries
import { useState } from 'react';

// Import Ant Design components
import { Button, Alert, Tooltip } from 'antd';

import { preset } from 'styles';
import { putApi, useIsSuperAdminRoute, formatDateInTZ } from 'modules';

const DataStreamingSwitch = ({ dataset, fetchDataset }) => {
  const { id: datasetId, status, uploadType, integrationMeta } = dataset;

  // Initialization
  const [loading, setLoading] = useState(false);
  const [hookStatusErr, setHookStatusErr] = useState();

  const isGlobal = useIsSuperAdminRoute();

  const enableHook = async () => {
    try {
      setLoading(true);
      await putApi(
        isGlobal
          ? `superadmin/datasets/${datasetId}/enable-hook`
          : `datasets/${datasetId}/enable-hook`
      );
      setLoading(false);
      fetchDataset();
    } catch (err) {
      setLoading(false);
      setHookStatusErr(err.message);
    }
  };

  const disableHook = async () => {
    try {
      setLoading(true);
      await putApi(
        isGlobal
          ? `superadmin/datasets/${datasetId}/disable-hook`
          : `datasets/${datasetId}/disable-hook`
      );
      setLoading(false);
      fetchDataset();
    } catch (err) {
      setLoading(false);
      setHookStatusErr(err.message);
    }
  };

  // Only display this switch if there is dataset integration meta available
  // or upload type is integration
  if (!integrationMeta || uploadType !== 'integration') return null;

  // Detect if data streaming is enabled
  const { type, hookId, hookUpdatedAt, active } = integrationMeta || {};

  let dataStreamingEnabled = false;

  switch (type) {
    case 'meltwater': {
      if (hookId || active) {
        dataStreamingEnabled = true;
      }
      break;
    }
    case 'facebook':
    case 'reviewshake':
    case 'reddit': {
      if (active) dataStreamingEnabled = true;
      break;
    }
    default:
      break;
  }

  return (
    <div css={{ paddingTop: preset.spacing(3) }}>
      <b>Data Stream</b>
      <div>
        {dataStreamingEnabled ? (
          <Button
            style={{ margin: '10px 10px 0px 0px' }}
            loading={loading}
            onClick={disableHook}
            type="danger"
          >
            Pause Data Stream
          </Button>
        ) : (
          <Tooltip
            title={status !== 'active' ? 'Activate dataset first' : null}
          >
            <Button
              style={{ margin: '10px 10px 0px 0px' }}
              loading={loading}
              onClick={enableHook}
              type="primary"
              ghost
              disabled={status !== 'active'}
            >
              Resume Data Stream
            </Button>
          </Tooltip>
        )}
        {/* Meltwater hook active since */}
        {hookUpdatedAt
          ? `Hook ${
              dataStreamingEnabled ? 'enabled' : 'disabled'
            } since ${formatDateInTZ(
              integrationMeta.hookUpdatedAt,
              'minute',
              '/'
            )}`
          : null}
      </div>
      {hookStatusErr && (
        <Alert
          message="Error"
          description={hookStatusErr}
          type="error"
          showIcon
          closable
        />
      )}
    </div>
  );
};

export default DataStreamingSwitch;
