export const initialState = {
  datasetIds: [],
  moreFilters: [],
  timeFilter: null,
};

export const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_FILTERS':
      return {
        ...action.filters,
        datasetIds: state.datasetIds,
        timeFilter: action.timeFilter,
      };

    case 'SET_DEFAULT_FILTER':
      return {
        ...state,
        [action.key]: action.value,
      };

    case 'SET_TIME_FILTER':
      return {
        ...state,
        timeFilter: action.value,
      };

    case 'SET_MORE_FILTERS':
      return {
        ...state,
        moreFilters: action.moreFilters,
      };

    case 'SET_FILTERS':
      return {
        ...state,
        ...action.filters,
      };

    case 'CLEAR_FILTERS':
      return initialState;

    default:
      return state;
  }
};
