/** @jsxImportSource @emotion/react */
import { useState, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Card, Table, Space, Button, Dropdown, Menu, message, Tag } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

import useGetApi from '@modules/hooks/useGetApi';
import { formatDateInTZ } from '@modules/date/formatDate';
import { postApi, fetchApiErrorMessage } from '@modules/api';

const ChartsEditor = ({ projectSlug }) => {
  const { orgSlug } = useParams();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [legacyCharts, setLegacyCharts] = useState([]);
  const [isFinding, setIsFinding] = useState(false);
  const [isFixingAttributes, setIsFixingAttributes] = useState(false);

  const { data: charts, loading } = useGetApi(
    `superadmin/organisations/${orgSlug}/projects/${projectSlug}/visualisations`
  );

  const filteredCharts = useMemo(
    () =>
      charts.reduce((prev, chart) => {
        const { id, defaultTitle, chartType, dataUpdatedAt } = chart;
        if (chartType === 'rich_text') return prev;
        if (
          legacyCharts.length > 0 &&
          !legacyCharts.find((legacyChart) => id === legacyChart.id)
        ) {
          return prev;
        }
        return [
          ...prev,
          {
            id,
            title: defaultTitle,
            chartType,
            dataUpdatedAt,
          },
        ];
      }, []),
    [charts, legacyCharts]
  );

  const onCheckLegacyAttributes = async () => {
    setIsFinding(true);

    try {
      const { data } = await postApi(
        `superadmin/organisations/${orgSlug}/find-legacy-visualisations`,
        { chartIds: filteredCharts.map((chart) => chart.id) }
      );
      if (data.length === 0) {
        message.success('No legacy charts found');
      } else {
        message.warning(`${data.length} legacy charts found`);
      }
      setLegacyCharts(data.map((visId) => ({ id: visId, fixed: false })));
    } catch (error) {
      message.error(fetchApiErrorMessage(error));
    }
    setIsFinding(false);
  };

  const onCheckFailedQuery = async () => {
    setIsFinding(true);
    message.info('Not yet supported 🌚');
    // try {
    //     const { data } = await postApi(
    //         `superadmin/organisations/${organisationSlug}/find-legacy-visualisations`,
    //         { chartIds: filteredCharts.map(chart => chart.id) },
    //     );
    //     if (data.length === 0) {
    //         message.success('No legacy charts found');
    //     } else {
    //         message.warning(`${data.length} legacy charts found`);
    //     }
    //     setLegacyCharts(data.map(visId => ({ id: visId, fixed: false })));
    // } catch (error) {
    //     message.error(fetchApiErrorMessage(error));
    // }
    setIsFinding(false);
  };

  const onChartsMenuClick = ({ key }) => {
    switch (key) {
      case 'check_legacy_attributes':
        onCheckLegacyAttributes();
        break;
      case 'check_failed_query':
        onCheckFailedQuery();
        break;
      default:
        break;
    }
  };

  const selectedLegacyChartIds = useMemo(() => {
    const selectedLegacyCharts =
      selectedRowKeys.length > 0
        ? legacyCharts.filter(
            (legacyChart) =>
              !legacyChart.fixed && selectedRowKeys.includes(legacyChart.id)
          )
        : legacyCharts.filter((legacyChart) => !legacyChart.fixed);
    return selectedLegacyCharts.map((legacyChart) => legacyChart.id);
  }, [legacyCharts, selectedRowKeys]);

  const onFixLegacyCharts = async () => {
    const chartIds = selectedLegacyChartIds;

    setIsFixingAttributes(true);

    try {
      await postApi(
        `superadmin/organisations/${orgSlug}/fix-legacy-visualisations`,
        { chartIds }
      );
      message.success('Legacy charts fixed');
      setSelectedRowKeys([]);
      setLegacyCharts((prevLegacyCharts) =>
        prevLegacyCharts.map((legacyChart) => ({
          ...legacyChart,
          fixed: legacyChart.fixed || chartIds.includes(legacyChart.id),
        }))
      );
    } catch (error) {
      message.error(fetchApiErrorMessage(error));
    }
    setIsFixingAttributes(false);
  };

  return (
    <Card
      loading={loading}
      title="Charts"
      size="small"
      extra={
        <Space size="middle">
          {legacyCharts.length > 0 && (
            <Button
              size="small"
              type="primary"
              disabled={selectedLegacyChartIds.length === 0}
              loading={isFixingAttributes}
              onClick={onFixLegacyCharts}
            >
              {`Fix ${selectedLegacyChartIds.length} legacy charts`}
            </Button>
          )}
          {legacyCharts.length > 0 && (
            <Button
              size="small"
              onClick={() => {
                setLegacyCharts([]);
              }}
            >
              Clear Filter
            </Button>
          )}
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu onClick={onChartsMenuClick}>
                <Menu.Item key="check_failed_query">
                  Find charts with failed query
                </Menu.Item>
                <Menu.Item key="check_legacy_attributes">
                  Find charts with legacy attributes
                </Menu.Item>
              </Menu>
            }
          >
            <Button size="small" icon={<EllipsisOutlined />} />
          </Dropdown>
        </Space>
      }
    >
      <Table
        size="small"
        loading={loading || isFinding}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
            render: (id) => (
              <Link
                target="_blank"
                to={{
                  pathname: `/projects/${projectSlug}/charts/${id}`,
                  search: `?org=${orgSlug}`,
                }}
              >
                {id}
              </Link>
            ),
          },
          {
            title: 'Title',
            dataIndex: 'title',
            render: (title, row) => (
              <Space>
                {title}
                <Space>
                  {legacyCharts.find(
                    (legacyChart) => legacyChart.id === row.id
                  ) &&
                    (legacyCharts.find(
                      (legacyChart) => legacyChart.id === row.id
                    ).fixed ? (
                      <Tag color="success">Fixed</Tag>
                    ) : (
                      <Tag color="warning">Legacy</Tag>
                    ))}
                </Space>
              </Space>
            ),
          },
          { title: 'Chart Type', dataIndex: 'chartType' },
          {
            title: 'Data Updated',
            dataIndex: 'dataUpdatedAt',
            render: (dataUpdatedAt) =>
              formatDateInTZ(dataUpdatedAt, 'full', '/'),
          },
        ]}
        dataSource={filteredCharts}
        {...(legacyCharts.length > 0
          ? {
              rowSelection: {
                selectedRowKeys,
                onChange: (newKeys) => setSelectedRowKeys(newKeys),
              },
            }
          : {})}
        rowKey="id"
        pagination={{
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} charts`,
        }}
      />
    </Card>
  );
};

export default ChartsEditor;
