import shortid from 'shortid';

/**
 * Transforms given String (upper or lowercase) to slug
 * (all lower case, spaces replaces with hyphen) and appends random lowercase shortid
 * @param {String} text Upper or lowercase string to be transformed
 */
const generateUniqueSlug = (text) => {
  const formattedText = text
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');

  return `${formattedText}-${shortid.generate().toLowerCase()}`;
};

export default generateUniqueSlug;
