/** @jsxImportSource @emotion/react */
import { useState, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import capitalize from 'lodash/capitalize';
import { Formik } from 'formik';
import { Input, Select, Form, SubmitButton, TimePicker } from 'formik-antd';
import {
  Modal,
  message,
  Row,
  Col,
  Table,
  Button,
  Spin,
  Descriptions,
  Popconfirm,
  Tooltip,
} from 'antd';
import numeral from 'numeral';
import { useAuth, UserInfo } from '@otso/auth-wrapper';

import { trackEvent } from 'analytics';
import { preset } from 'styles';
import generateUniqueSlug from '@modules/slug';
import {
  getApi,
  postApi,
  deleteApi,
  putApi,
  fetchApiErrorMessage,
} from '@modules/api';
import deepGet from '@modules/deepGet';
import RichTextEditor from '@components/RichTextEditor';
import { formatDateInTZ } from 'modules';

const { Option } = Select;

const DAYS_IN_WEEK = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];
const MONTH_DAY_OPTIONS = Array(28)
  .fill()
  .map((x, i) => i + 1);

const mapStateToProps = (state) => {
  return {
    isScheduledEmailsModalOpen: state.dashboard.isScheduledEmailsModalOpen,
    dashboardName: state.dashboard.name,
    selectedSavedFilterSlug: state.dashboard.selectedSavedFilterSlug,
    savedDashboardFilters: state.dashboard.savedFilters,
    currentPageId: state.dashboard.currentPageId,
    dashboardPages: state.dashboard.pages,
  };
};

const ScheduledEmailsModal = ({
  // redux state
  isScheduledEmailsModalOpen,
  dashboardName,
  selectedSavedFilterSlug,
  savedDashboardFilters,
  dashboardPages,
  currentPageId,
  // redux dispatch
  dispatch,
}) => {
  const { slug: projectSlug, dashboardSlug } = useParams();

  const { user, currentOrg } = useAuth();

  const users = currentOrg?.users || [];

  const [reports, setReports] = useState([]);
  const [shouldFetchReports, setShouldFetchReports] = useState(true);
  const [isReportsLoading, setIsReportsLoading] = useState(false);
  const [selectedReport, setSelectedReport] = useState();
  const [isSelectedReportLoading, setIsSelectedReportLoading] = useState(false);
  const [isSavingReport, setIsSavingReport] = useState(false);
  const [isSendingTestEmail, setIsSendingTestEmail] = useState(false);

  const {
    name,
    sendTo = [],
    pageId,
    filterSlug,
    frequency = {},
    description,
  } = selectedReport || {};

  const editorRef = useRef();

  // Fetch reports
  useEffect(() => {
    const fetchReports = async () => {
      setIsReportsLoading(true);
      setShouldFetchReports(false);
      try {
        const { data } = await getApi(`projects/${projectSlug}/reports`, {
          dashboardSlug,
        });
        setReports(data);
      } catch (error) {
        message.error(fetchApiErrorMessage(error));
      }
      setIsReportsLoading(false);
    };

    if (isScheduledEmailsModalOpen && shouldFetchReports) {
      fetchReports();
    }
  }, [
    projectSlug,
    dashboardSlug,
    isScheduledEmailsModalOpen,
    shouldFetchReports,
  ]);

  // Edit report
  const editReport = async (reportSlug) => {
    setSelectedReport({});
    setIsSelectedReportLoading(true);
    try {
      const { data } = await getApi(
        `projects/${projectSlug}/reports/${reportSlug}`
      );

      setSelectedReport({
        ...data,
        pageId: deepGet(data, ['filters', 'pageId']),
        filterSlug: deepGet(data, ['filters', 'filterSlug']),
        frequency: {
          ...data.frequency,
          ...(typeof deepGet(data, ['frequency', 'hourOfDay']) === 'number'
            ? {
                hourOfDay: moment()
                  .set({ hour: data.frequency.hourOfDay })
                  .format(),
              }
            : {}),
        },
      });
    } catch (error) {
      message.error(fetchApiErrorMessage(error));
    }
    setIsSelectedReportLoading(false);
  };

  // Delete report
  const deleteReport = async (reportSlug) => {
    try {
      await deleteApi(`projects/${projectSlug}/reports/${reportSlug}`);
      message.success('Scheduled Email Deleted');
      setShouldFetchReports(true);
    } catch (error) {
      message.error(fetchApiErrorMessage(error));
    }
  };

  // Convert frequencyObj into a displayable string
  const displayFrequency = (frequencyObj = {}) => {
    const { interval, hourOfDay, weekDay, monthDay } = frequencyObj;
    const weekDayDisplay = weekDay ? capitalize(weekDay) : '';
    const hourOfDayDisplay = hourOfDay
      ? `${moment().set({ hour: hourOfDay }).format('h a')}`
      : '';
    switch (interval) {
      case 'daily':
        return `Daily${hourOfDayDisplay ? `: ${hourOfDayDisplay}` : ''}`;
      case 'weekly':
        return `Weekly${
          weekDayDisplay
            ? `: ${weekDayDisplay}${
                hourOfDayDisplay ? ` ${hourOfDayDisplay}` : ''
              }`
            : ''
        }`;
      case 'monthly':
        return `Monthly${
          monthDay
            ? `: ${numeral(monthDay).format('0o')} day, at ${
                hourOfDayDisplay ? ` ${hourOfDayDisplay}` : ''
              }`
            : ''
        }`;
      default:
        return '';
    }
  };

  // Handle New Email Init
  const onNewScheduledEmailClick = () => {
    trackEvent('Create Scheduled Email Begin');

    const currentMonthDay = parseInt(moment().format('D'), 10);
    const monthDay = currentMonthDay <= 28 ? currentMonthDay : 1;

    setSelectedReport({
      name: `${dashboardName} - ${dashboardPages.byId[currentPageId].title}`,
      sendTo: [],
      pageId: currentPageId,
      filterSlug:
        selectedSavedFilterSlug === 'custom'
          ? undefined
          : selectedSavedFilterSlug,
      frequency: {
        interval: 'once',
        hourOfDay: moment().format(),
        weekDay: moment().format('dddd').toLowerCase(),
        monthDay,
      },
    });
  };

  const emailFormValidate = (values) => {
    const errors = {};
    if (!values.name || !values.name.trim()) {
      errors.name = 'Subject is required';
    }
    if (values.sendTo.length === 0) {
      errors.sendTo = 'Recipients is required';
    }
    if (!values.pageId) {
      errors.pageId = 'Dashboard Page is required';
    }
    return errors;
  };

  const sendEmail = async (reportData, recipients = []) => {
    const pageIndexStr = dashboardPages.allIds.indexOf(reportData.pageId) + 1;
    const recipientsDisplay =
      recipients.length === 1
        ? recipients[0]
        : `${recipients[0]} and ${recipients.length - 1} others`;

    const pdfSummary = editorRef.current && editorRef.current.getHTML();

    try {
      await postApi(
        `projects/${projectSlug}/dashboards/${dashboardSlug}/page/${pageIndexStr}/email-pdf`,
        {
          width: 1440,
          title: reportData.name,
          ...(reportData.filterSlug
            ? {
                filterSlug: reportData.filterSlug,
              }
            : {}),
          sendTo: recipients,
          ...(pdfSummary ? { summary: pdfSummary } : {}),
        }
      );

      message.success(`Email has been sent to ${recipientsDisplay}`);
    } catch (error) {
      message.error(fetchApiErrorMessage(error, 'Unable to send test email'));
    }
  };

  // Create/Update report
  const saveReport = async (newReport) => {
    setIsSavingReport(true);

    if (newReport.frequency.interval === 'once') {
      const sendToEmails = newReport.sendTo.map((recipientId) => {
        const matchedUser = users.find(
          (singleUser) => singleUser.id === recipientId
        );
        return matchedUser?.email;
      });
      trackEvent('Send One Time Email', { sendTo: sendToEmails });
      try {
        await sendEmail(newReport, sendToEmails);
        setSelectedReport();
      } catch (error) {
        message.error(fetchApiErrorMessage(error, 'Unable to send email'));
      }
    } else {
      try {
        const formattedNewReport = {
          name: newReport.name,
          ...(selectedReport.slug
            ? {}
            : {
                slug: generateUniqueSlug(newReport.name),
                dashboardSlug,
              }),
          sendTo: newReport.sendTo,
          frequency: {
            ...newReport.frequency,
            hourOfDay: moment(newReport.frequency.hourOfDay).hour(),
          },
          filters: {
            pageId: newReport.pageId,
            filterSlug: newReport.filterSlug,
          },
        };

        const pdfSummary = editorRef.current && editorRef.current.getHTML();

        if (pdfSummary) {
          formattedNewReport.description = { html: pdfSummary };
        }

        if (selectedReport.slug) {
          trackEvent('Edit Scheduled Email', { name: formattedNewReport.name });
          await putApi(
            `projects/${projectSlug}/reports/${selectedReport.slug}`,
            formattedNewReport
          );
        } else {
          trackEvent('Create Scheduled Email Complete', {
            name: formattedNewReport.name,
          });
          await postApi(`projects/${projectSlug}/reports`, formattedNewReport);
        }

        message.success(
          selectedReport.slug
            ? 'Scheduled Email Updated'
            : 'New Scheduled Email Created'
        );
        setSelectedReport();
        setShouldFetchReports(true);
      } catch (error) {
        message.error(
          fetchApiErrorMessage(error, 'Unable to save scheduled email')
        );
      }
    }

    setIsSavingReport(false);
  };

  const sendTestEmail = async (currentReportData) => {
    setIsSendingTestEmail(true);
    await sendEmail(currentReportData, [user.email]);
    setIsSendingTestEmail(false);
  };

  // Filter out custom filter
  const selectableDashboardFilters = useMemo(
    () =>
      savedDashboardFilters.filter(
        (savedDashboardFilter) => savedDashboardFilter.slug !== 'custom'
      ),
    [savedDashboardFilters]
  );

  return (
    <Modal
      destroyOnClose
      title="Scheduled Emails"
      visible={isScheduledEmailsModalOpen}
      width={preset.modalWidth.lg}
      onCancel={() =>
        dispatch({
          type: 'SET_DASHBOARD_MODAL_OPEN',
          key: 'isScheduledEmailsModalOpen',
          open: false,
        })
      }
      footer={null}
    >
      {selectedReport ? (
        <Formik
          enableReinitialize
          initialValues={{
            name,
            sendTo,
            pageId,
            filterSlug,
            frequency,
          }}
          onSubmit={saveReport}
          validate={emailFormValidate}
        >
          {({ values }) => (
            <Spin spinning={isSelectedReportLoading}>
              {selectedReport.slug && (
                <Descriptions css={{ marginBottom: preset.spacing(1) }}>
                  <Descriptions.Item label="Created By">
                    <UserInfo id={selectedReport.creatorId} output="email" />
                  </Descriptions.Item>
                  <Descriptions.Item label="Created At">
                    {formatDateInTZ(selectedReport.createdAt, 'day', '/')}
                  </Descriptions.Item>
                  <Descriptions.Item label="Modified At">
                    {formatDateInTZ(selectedReport.updatedAt, 'day', '/')}
                  </Descriptions.Item>
                </Descriptions>
              )}
              <Form layout="vertical">
                <Row gutter={preset.spacing(3)}>
                  <Col span={12}>
                    <Form.Item name="name" label="Subject" required>
                      <Input name="name" suffix={<span />} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="sendTo" label="Recipients" required>
                      <Select
                        name="sendTo"
                        showSearch
                        mode="multiple"
                        placeholder="Add Recipients"
                        optionFilterProp="children"
                      >
                        {users.map((singleUser) => (
                          <Option
                            key={singleUser.id}
                            title={singleUser.email}
                            value={singleUser.id}
                          >
                            {singleUser.email}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="pageId" label="Dashboard Page" required>
                      <Select name="pageId" placeholder="Select Dashboard Page">
                        {dashboardPages.allIds.map((dashboardPageId) => (
                          <Option key={dashboardPageId} value={dashboardPageId}>
                            {dashboardPages.byId[dashboardPageId].title}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="filterSlug" label="Dashboard Filter">
                      <Select
                        allowClear
                        name="filterSlug"
                        placeholder="Select Dashboard Filter"
                      >
                        {selectableDashboardFilters.length > 0 ? (
                          selectableDashboardFilters.map((dashboardFilter) => (
                            <Option
                              key={dashboardFilter.slug}
                              value={dashboardFilter.slug}
                            >
                              {dashboardFilter.name}
                            </Option>
                          ))
                        ) : (
                          <Option disabled value="">
                            No Dashboard Filter Found
                          </Option>
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name="description" label="Summary">
                      {!isSelectedReportLoading && (
                        <RichTextEditor
                          ref={editorRef}
                          defaultValue={deepGet(description, ['html'])}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <div
                      css={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        marginBottom: preset.spacing(3),
                        '.ant-form-item': {
                          marginBottom: 0,
                          '.ant-select': {
                            minWidth: 120,
                          },
                        },
                      }}
                    >
                      <Form.Item
                        required
                        name="frequency.interval"
                        label="Frequency"
                      >
                        <Select
                          name="frequency.interval"
                          placeholder="Select Frequency"
                        >
                          <Option value="once">Once</Option>
                          <Option value="daily">Daily</Option>
                          <Option value="weekly">Weekly</Option>
                          <Option value="monthly">Monthly</Option>
                        </Select>
                      </Form.Item>
                      {values.frequency.interval === 'weekly' && (
                        <>
                          <span
                            css={{
                              marginLeft: 12,
                              marginRight: 12,
                              paddingBottom: 6,
                            }}
                          >
                            every
                          </span>
                          <Form.Item key="weekDay" name="frequency.weekDay">
                            <Select name="frequency.weekDay">
                              {DAYS_IN_WEEK.map((day) => (
                                <Option key={day} value={day}>
                                  {capitalize(day)}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </>
                      )}
                      {values.frequency.interval === 'monthly' && (
                        <>
                          <span
                            css={{
                              marginLeft: 12,
                              marginRight: 12,
                              paddingBottom: 6,
                            }}
                          >
                            on the
                          </span>
                          {values.frequency.interval === 'monthly' && (
                            <Form.Item key="monthDay" name="frequency.monthDay">
                              <Select name="frequency.monthDay">
                                {MONTH_DAY_OPTIONS.map((day) => (
                                  <Option key={day} value={day}>
                                    {numeral(day).format('0o')}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          )}
                          <span
                            css={{
                              marginLeft: 12,
                              marginRight: 12,
                              paddingBottom: 6,
                            }}
                          >
                            day
                          </span>
                        </>
                      )}
                      {values.frequency.interval !== 'once' && (
                        <>
                          <span
                            css={{
                              marginLeft: 12,
                              marginRight: 12,
                              paddingBottom: 6,
                            }}
                          >
                            at
                          </span>
                          <Form.Item name="frequency.hourOfDay">
                            <TimePicker
                              use12Hours
                              allowClear={false}
                              name="frequency.hourOfDay"
                              format="h a"
                            />
                          </Form.Item>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col span={24}>
                    <SubmitButton
                      type="primary"
                      loading={isSavingReport}
                      disabled={
                        !Array.isArray(values.sendTo) ||
                        values.sendTo.length === 0
                      }
                      css={{ marginRight: preset.spacing(2) }}
                    >
                      {values.frequency.interval === 'once'
                        ? 'Send Email'
                        : 'Save Scheduled Email'}
                    </SubmitButton>
                    <Button onClick={() => setSelectedReport()}>Cancel</Button>
                    <Tooltip title={`Send a test email to ${user.email}`}>
                      <Button
                        loading={isSendingTestEmail}
                        onClick={() => sendTestEmail(values)}
                        css={{ float: 'right' }}
                      >
                        Send Test Email
                      </Button>
                    </Tooltip>
                  </Col>
                </Row>
              </Form>
            </Spin>
          )}
        </Formik>
      ) : (
        <>
          <Button
            type="primary"
            onClick={onNewScheduledEmailClick}
            css={{ marginBottom: preset.spacing(2) }}
          >
            New Scheduled Email
          </Button>
          <Table
            size="small"
            bordered
            loading={isReportsLoading}
            columns={[
              { title: 'Subject', dataIndex: 'name' },
              {
                title: 'Frequency',
                dataIndex: 'frequency',
                render: (frequencyObj) => displayFrequency(frequencyObj),
              },
              {
                title: 'Action',
                key: 'action',
                width: 144,
                render: (text, row) => [
                  <Button
                    key="edit"
                    type="link"
                    size="small"
                    onClick={() => editReport(row.slug)}
                  >
                    Edit
                  </Button>,
                  <Popconfirm
                    key="delete"
                    title="Are you sure you want to delete this scheduled email?"
                    okText="Delete"
                    okType="danger"
                    onConfirm={() => deleteReport(row.slug)}
                  >
                    <Button type="link" size="small">
                      Delete
                    </Button>
                  </Popconfirm>,
                ],
              },
            ]}
            dataSource={reports}
            rowKey="slug"
            pagination={false}
          />
        </>
      )}
    </Modal>
  );
};

export default connect(mapStateToProps)(ScheduledEmailsModal);
