import numeral from 'numeral';

const formatMetricLabel = (label, format = 'number') => {
    if (typeof label === 'number') {
        if (label === Infinity || label === -Infinity) {
            return label.toString();
        }
        return numeral(label).format(
            format === 'percent'
                ? '0.[00]%'
                : (format === 'bigNumber' && label >= 1000)
                    ? '0a'
                    : '0,0.[00]',
        );
    }

    return label;
};

export default formatMetricLabel;
