import moment from 'moment-timezone';

import { formatDate, formatDateInTZ } from '../date/formatDate';

const momentShorthandMap = {
    second: 's',
    minute: 'm',
    hour: 'h',
    day: 'd',
    week: 'w',
    month: 'M',
    year: 'y',
};

const insertEmptyDate = (
    data = [],
    dateKey,
    option = {
        timeInterval: 'day',
        mask: undefined,
        startDate: null,
        endDate: null,
        valueKey: null,
        showZero: false,
    },
) => {
    if (data && data.length > 0) {
        const {
            timeInterval, mask, showZero, valueKey,
        } = option;

        // Default start/end date
        let startDate = option.startDate || data[0][dateKey];
        let endDate = option.endDate ? (
            option.endDate === 'now' ? formatDateInTZ('now', mask) : option.endDate
        ) : data[data.length - 1][dateKey];

        if (timeInterval === 'week') {
            startDate = formatDate(startDate, 'moment').startOf('isoWeek').format(mask);
            endDate = formatDate(endDate, 'moment').endOf('isoWeek').format(mask);
        } else {
            startDate = formatDate(startDate, 'moment').startOf(timeInterval).format(mask);
            endDate = formatDate(endDate, 'moment').endOf(timeInterval).format(mask);
        }

        // Create a dateMap to make the date find easier
        const dateMap = data.reduce((prev, currentRow) => {
            const formattedDateKey = formatDate(currentRow[dateKey], timeInterval);
            return {
                ...prev,
                [formattedDateKey]: prev[formattedDateKey] ? [
                    ...prev[formattedDateKey], currentRow,
                ] : [currentRow],
            };
        }, {});

        // Create a empty Obj for later on use
        const emptyObjSample = Object.keys(data[0]).reduce((prev, currentKey) => (
            (currentKey === dateKey || (valueKey && currentKey !== valueKey)) ? prev : {
                ...prev,
                [currentKey]: showZero ? 0 : null,
            }
        ), {});

        let newData = [];
        let currentDate = startDate;

        // Date loop
        while (moment(currentDate) <= moment(endDate)) {
            const formattedCurrentDate = formatDate(currentDate, timeInterval);
            if (dateMap[formattedCurrentDate]) {
                newData = [...newData, ...dateMap[formattedCurrentDate]];
            } else {
                const emptyObj = {
                    [dateKey]: currentDate,
                    ...emptyObjSample,
                };
                newData = [...newData, emptyObj];
            }
            currentDate = formatDate(currentDate, 'moment').add(1, momentShorthandMap[timeInterval]).format(mask);
        }

        return newData;
    }
    return data;
};

export default insertEmptyDate;
