/** @jsxImportSource @emotion/react */
import { Global } from '@emotion/react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import queryString from 'query-string';
import { useAuth } from '@otso/auth-wrapper';

import {
  PageHeader,
  Button,
  message,
  Dropdown,
  Menu,
  Modal,
  Badge,
  Tooltip,
  Popover,
} from 'antd';
import {
  EllipsisOutlined,
  DownOutlined,
  AreaChartOutlined,
  FontColorsOutlined,
  ExclamationCircleOutlined,
  ReloadOutlined,
  LinkOutlined,
} from '@ant-design/icons';

import { trackEvent } from 'analytics';
import { putApi, deleteApi, fetchApiErrorMessage } from '@modules/api';
import useCheckPermission from '@modules/hooks/useCheckPermission';
import { formatEmptyObject } from '@modules/items';

const mapStateToProps = (state) => {
  return {
    dashboardName: state.dashboard.name,
    isLayoutLocked: state.dashboard.isLayoutLocked,
    dashboardChartsById: state.dashboard.dashboardCharts.byId,
    isDashboardFilterShowing: state.dashboard.isDashboardFilterShowing,
    activeFiltersLength:
      state.dashboard.activeFilters.filters.length +
      (formatEmptyObject(state.dashboard.activeFilters.timeFilter) ? 1 : 0),
  };
};

const DashboardHeader = ({
  disabled = false,
  // redux state
  dashboardName,
  isLayoutLocked,
  dashboardChartsById,
  isDashboardFilterShowing = true,
  activeFiltersLength,
  // redux dispatch
  dispatch,
  isAutoRefreshEnabled = false,
  toggleAutoRefresh,
}) => {
  const history = useHistory();
  const { slug: projectSlug, dashboardSlug } = useParams();

  const [isLayoutSaving, setLayoutSaving] = useState(false);
  const [isInFullscreen, setIsInFullscreen] = useState(
    !!(
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    )
  );

  const canEditDashboard = useCheckPermission(['insight.manageDashboards']);

  const { user } = useAuth();

  // Save dashboard visualisations layout
  const saveDashboardVisualisationsLayout = async () => {
    setLayoutSaving(true);

    try {
      await putApi(
        `projects/${projectSlug}/dashboards/${dashboardSlug}/visualisations`,
        { data: Object.values(dashboardChartsById) }
      );

      message.success('Dashboard Layout Saved');
    } catch (error) {
      message.error(fetchApiErrorMessage(error, 'Unable to Save Layout'));
    }

    setLayoutSaving(false);
    dispatch({
      type: 'SET_IS_DASHBOARD_LAYOUT_LOCK',
      locked: true,
    });
  };

  // Delete Dashboard
  const deleteDashboard = () => {
    const deleteReq = async () => {
      try {
        await deleteApi(`projects/${projectSlug}/dashboards/${dashboardSlug}`);
        trackEvent('Delete Dashboard', { dashboardSlug });
        message.success('Dashboard Deleted');
        history.push(`/projects/${projectSlug}/dashboards`);
      } catch (error) {
        trackEvent('Delete Dashboard Error', {
          dashboardSlug,
          error: fetchApiErrorMessage(error),
        });
        message.error(
          fetchApiErrorMessage(error, 'Unable to Delete Dashboard')
        );
      }
    };

    Modal.confirm({
      title: `Delete Dashboard: ${dashboardName}`,
      icon: <ExclamationCircleOutlined />,
      content: 'Confirm that you want to delete this Dashboard.',
      okText: 'Delete',
      okType: 'danger',
      onOk: () => deleteReq(),
    });
  };

  // Handle add chart dropdown
  const onAddChartMenuClick = (actionKey) => {
    switch (actionKey) {
      case 'add_chart':
        dispatch({
          type: 'SET_DASHBOARD_MODAL_OPEN',
          key: 'isChartSelectModalOpen',
          open: true,
        });
        break;
      case 'add_rich_text':
        dispatch({
          type: 'SET_DASHBOARD_MODAL_OPEN',
          key: 'isRichTextModalOpen',
          open: true,
        });
        break;
      default:
        break;
    }
  };

  // Handle more dashboard action click
  const onDashboardActionClick = (actionKey) => {
    switch (actionKey) {
      case 'fullscreen':
        if (isInFullscreen) {
          document.exitFullscreen();
        } else {
          document.documentElement.requestFullscreen();
        }
        trackEvent(`Dashboard ${isInFullscreen ? 'Exit' : 'View'} Fullscreen`);
        setIsInFullscreen(!isInFullscreen);
        break;
      case 'auto_refresh':
        toggleAutoRefresh(!isAutoRefreshEnabled);
        break;
      case 'download_pdf':
        dispatch({ type: 'ON_DASHBOARD_DOWNLOAD_PDF_PREVIEW' });
        trackEvent('Dashboard Preview PDF');
        break;
      case 'scheduled_emails':
        dispatch({
          type: 'SET_DASHBOARD_MODAL_OPEN',
          key: 'isScheduledEmailsModalOpen',
          open: true,
        });
        break;
      case 'edit':
        dispatch({
          type: 'SET_DASHBOARD_MODAL_OPEN',
          key: 'isNameEditModalOpen',
          open: true,
        });
        break;
      case 'duplicate':
        dispatch({
          type: 'SET_DASHBOARD_MODAL_OPEN',
          key: 'isDuplicateDashboardModalOpen',
          open: true,
        });
        break;
      case 'delete':
        deleteDashboard();
        break;
      default:
        break;
    }
  };

  const copyDashboardLink = () => {
    copy(
      queryString.stringifyUrl({
        url: window.location.href,
        query: {
          ...queryString.parseUrl(window.location.href).query,
          org: user?.currentOrg,
        },
      })
    );
  };

  return (
    <>
      {/* Hide subHeader */}
      <Global
        styles={{
          '.sub-header': { height: 0, overflow: 'hidden' },
        }}
      />

      <PageHeader
        ghost={false}
        title={dashboardName}
        subTitle={
          !disabled && (
            <Popover content="Link copied to clipboard" trigger="click">
              <Tooltip title="Copy dashboard link">
                <Button
                  size="small"
                  type="link"
                  icon={<LinkOutlined />}
                  onClick={copyDashboardLink}
                />
              </Tooltip>
            </Popover>
          )
        }
        onBack={() => history.push(`/projects/${projectSlug}/dashboards`)}
        extra={
          isLayoutLocked
            ? [
                ...(canEditDashboard
                  ? [
                      <Dropdown.Button
                        key="add_chart"
                        trigger={['click']}
                        type="primary"
                        disabled={disabled}
                        icon={
                          <DownOutlined data-testId="add-chart-widget-dropdown" />
                        }
                        onClick={() =>
                          dispatch({
                            type: 'SET_DASHBOARD_MODAL_OPEN',
                            key: 'isChartSelectModalOpen',
                            open: true,
                          })
                        }
                        overlay={
                          <Menu onClick={({ key }) => onAddChartMenuClick(key)}>
                            <Menu.Item
                              key="add_chart"
                              icon={<AreaChartOutlined />}
                            >
                              Add Chart
                            </Menu.Item>
                            <Menu.Item
                              key="add_rich_text"
                              icon={<FontColorsOutlined />}
                            >
                              Add Rich Text Widget
                            </Menu.Item>
                          </Menu>
                        }
                        css={{ marginRight: 12 }}
                      >
                        Add Chart
                      </Dropdown.Button>,
                      <Button
                        key="edit_layoyt"
                        disabled={disabled}
                        onClick={() =>
                          dispatch({
                            type: 'SET_IS_DASHBOARD_LAYOUT_LOCK',
                            locked: false,
                          })
                        }
                      >
                        Edit Layout
                      </Button>,
                    ]
                  : []),
                <Badge
                  key="toggle_filter"
                  count={activeFiltersLength}
                  overflowCount={9}
                >
                  <Button
                    disabled={disabled}
                    onClick={() =>
                      dispatch({
                        type: 'TOGGLE_DASHBOARD_FILTER_BAR_SHOWING',
                      })
                    }
                  >
                    {`${
                      isDashboardFilterShowing ? 'Hide' : 'Show'
                    } Dashboard Filter`}
                  </Button>
                </Badge>,
                <Tooltip key="refresh_charts" title="Refresh charts">
                  <Button
                    disabled={disabled}
                    icon={<ReloadOutlined />}
                    onClick={() =>
                      dispatch({
                        type: 'REFRESH_DASHBOARD_CHARTS',
                      })
                    }
                  />
                </Tooltip>,
                <Dropdown
                  key="more_actions"
                  trigger={['click']}
                  overlay={
                    <Menu
                      onClick={({ key }) => onDashboardActionClick(key)}
                      css={{ minWidth: 200 }}
                    >
                      <Menu.Item key="fullscreen">
                        {isInFullscreen && 'Exit '}Fullscreen Mode
                      </Menu.Item>
                      <Menu.Item key="auto_refresh">
                        {isAutoRefreshEnabled && 'Disable '}Dashboard Auto
                        Refresh
                      </Menu.Item>
                      <Menu.Item key="download_pdf">Download PDF</Menu.Item>
                      {canEditDashboard && [
                        <Menu.Item key="scheduled_emails">
                          Scheduled Emails
                        </Menu.Item>,
                        <Menu.Item key="edit">Edit Dashboard Name</Menu.Item>,
                        <Menu.Item key="duplicate">
                          Duplicate Dashboard
                        </Menu.Item>,
                        <Menu.Item key="delete">Delete Dashboard</Menu.Item>,
                      ]}
                    </Menu>
                  }
                >
                  <Button
                    disabled={disabled}
                    icon={<EllipsisOutlined />}
                    data-testId="open-dashboard-menu-button"
                  />
                </Dropdown>,
              ]
            : [
                <Button
                  key="cancel_layout_edit"
                  onClick={() =>
                    dispatch({
                      type: 'SET_IS_DASHBOARD_LAYOUT_LOCK',
                      locked: true,
                      reset: true,
                    })
                  }
                >
                  Cancel
                </Button>,
                <Button
                  key="save_layout_edit"
                  type="primary"
                  loading={isLayoutSaving}
                  onClick={saveDashboardVisualisationsLayout}
                >
                  Save and Lock Layout
                </Button>,
              ]
        }
      />
    </>
  );
};

export default connect(mapStateToProps)(DashboardHeader);
