import isEqual from 'lodash/isEqual';

import getChartDefaultConfig from '../../modules/chart/getChartDefaultConfig';
import generateChartColor from '../../modules/chart/generateChartColor';
import generateChartGeneral from '../../modules/chart/generateChartGeneral';

export const defaultQueryObj = {
  metrics: [],
  dimensions: [],
  dataset: [],
  filters: [],
  timeFilter: null,
  groupBy: [],
};

export const initialState = {
  // chart setup
  defaultTitle: 'Untitled chart',
  description: '',
  chartType: undefined,
  queryObj: defaultQueryObj,
  // chart data
  data: [],
  // chart customise
  defaultConfig: {},
  defaultColor: {},
  // chart schema
  schema: {},
};

export const chartReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_CHART': {
      const {
        id,
        defaultTitle,
        description,
        chartType,
        queryObj,
        data = [],
        defaultConfig = {},
        defaultColor = {},
        schema = {},
      } = action.chart;

      if (queryObj.timeInterval) {
        delete queryObj.timeInterval;
      }

      // Chart data could be updated, so need re-calc chart config
      const updatedChartConfig = getChartDefaultConfig(
        chartType,
        data,
        queryObj,
        defaultConfig
      );

      const updatedChartColor = generateChartColor(
        updatedChartConfig.color,
        defaultColor
      );

      return {
        ...state,
        id,
        defaultTitle,
        description,
        chartType,
        queryObj,
        data,
        defaultConfig,
        defaultColor: updatedChartColor,
        schema,
      };
    }

    case 'SET_VALUE':
      return {
        ...state,
        [action.key]: action.value,
      };

    case 'ON_CHART_QUERY_APPLY': {
      const {
        data = [],
        schema = {},
        chartType: newChartType,
        queryObj: newQueryObj,
        error,
      } = action;

      const {
        chartType,
        queryObj: currentQueryObj,
        defaultColor,
        defaultConfig,
      } = state;

      if (error) {
        return {
          ...state,
          data: initialState.data,
          schema: initialState.schema,
          defaultConfig: initialState.defaultConfig,
          defaultColor: initialState.defaultColor,
        };
      }

      const chartConfig =
        data && data.length > 0
          ? getChartDefaultConfig(newChartType, data, newQueryObj)
          : { color: {}, general: {} };

      const chartColor = generateChartColor(chartConfig.color, defaultColor);

      let chartGeneral = chartConfig.general;

      // As long as there is no changes in metrics and dimensions, the previous general won't be reset
      if (
        chartType === newChartType &&
        isEqual(currentQueryObj.metrics, newQueryObj.metrics) &&
        isEqual(currentQueryObj.dimensions, newQueryObj.dimensions) &&
        isEqual(currentQueryObj.compare, newQueryObj.compare)
      ) {
        chartGeneral = generateChartGeneral(chartConfig.general, defaultConfig);
      }

      return {
        ...state,
        chartType: newChartType,
        queryObj: newQueryObj,
        data,
        schema,
        defaultConfig: chartGeneral,
        defaultColor: chartColor,
      };
    }

    case 'SET_COLOR':
      return {
        ...state,
        defaultColor: {
          ...state.defaultColor,
          [action.key]: action.value,
        },
      };

    case 'SET_CONFIG':
      return {
        ...state,
        defaultConfig: {
          ...state.defaultConfig,
          [action.key]: action.childKey
            ? {
                ...(state.defaultConfig[action.key] || {}),
                [action.childKey]: action.value,
              }
            : action.value,
        },
      };

    default:
      return state;
  }
};
