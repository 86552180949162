/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-plusplus */
/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  Chart,
  Legend,
  Axis,
  Tooltip,
  Geom,
  Util,
  Shape,
  Label,
} from 'bizcharts';

import { deepGet, useChartRef } from 'modules';
import {
  getAxisConfig,
  formatDimensionLabel,
  getYAxisScale,
  getXAxisLabelConfig,
  formatMetricLabel,
  getChartElement,
  formatBarChartData,
} from 'modules/chart';
import CoordConfig from '../components/CoordConfig';
import ChartComments from '../components/ChartComments';

const WaterfallChart = ({
  width,
  height,
  config,
  color,
  queryObj,
  data,
  schema,
  drillDownDisabled = false,
  // chart download
  renderer,
  setChartDownloadInstance,
  // dashboard chart comments
  comments = [],
  commentsShowing = false,
  commentsEditing = false,
  // redux dispatch for triggering drilldown
  dispatch,
}) => {
  const [isShapeRegistered, setIsShapeRegistered] = useState(false);

  const {
    x,
    y,
    reverse = false,
    log = false,
    label: labelConfig,
    axis: axisConfig,
  } = config;

  // Init chart instance
  const chart = useRef();

  // Re-init chart when width changes and pass chartRef to download modal
  useChartRef(chart, width, setChartDownloadInstance, isShapeRegistered);

  // Get chart config
  const labelDisplay = config.label ? config.label.display : true;
  const xAxisLabelConfig = useMemo(() => getXAxisLabelConfig(config), [config]);

  useEffect(() => {
    const getRectPath = (points) => {
      const path = [];

      for (let i = 0; i < points.length; i++) {
        const point = points[i];

        if (point) {
          const action = i === 0 ? 'M' : 'L';
          path.push([action, point.x, point.y]);
        }
      }

      const first = points[0];
      path.push(['L', first.x, first.y]);
      path.push(['z']);
      return path;
    };

    const getFillAttrs = (cfg) => {
      const defaultAttrs = Shape.interval;
      const attrs = Util.mix({}, cfg.style, defaultAttrs, {
        fill: cfg.color,
        stroke: cfg.color,
        fillOpacity: cfg.opacity,
      });
      return attrs;
    };

    Shape.registerShape('interval', 'waterfall', {
      draw(cfg, container) {
        const attrs = getFillAttrs(cfg);
        let rectPath = getRectPath(cfg.points);
        rectPath = this.parsePath(rectPath);
        const interval = container.addShape('path', {
          attrs: Util.mix(attrs, {
            path: rectPath,
          }),
        });

        if (cfg.nextPoints) {
          let linkPath = [
            ['M', cfg.points[2].x, cfg.points[2].y],
            ['L', cfg.nextPoints[0].x, cfg.nextPoints[0].y],
          ];

          if (cfg.nextPoints[0].y === 0) {
            linkPath[1] = ['L', cfg.nextPoints[1].x, cfg.nextPoints[1].y];
          }

          linkPath = this.parsePath(linkPath);
          container.addShape('path', {
            attrs: {
              path: linkPath,
              stroke: 'rgba(0, 0, 0, 0.45)',
              lineDash: [4, 2],
            },
          });
        }

        return interval;
      },
    });

    setIsShapeRegistered(true);
  }, []);

  // Transform data
  const transformedData = useMemo(() => {
    // Remove empty x
    const formattedData = formatBarChartData({
      data,
      x,
      queryObj,
      schema,
    });
    // Transform data into waterfall format
    return formattedData.reduce((prev, item, index) => {
      if (index === 0) {
        return [...prev, item];
      }
      const prevItem = prev[index - 1];
      return [
        ...prev,
        {
          ...item,
          [y]: Array.isArray(prevItem[y])
            ? [prevItem[y][1], item[y]]
            : [prevItem[y], item[y]],
        },
      ];
    }, []);
  }, [data, x, y, queryObj, schema]);

  // Config chart scale
  const scale = useMemo(() => {
    return {
      [x]: {
        formatter: (val) =>
          formatDimensionLabel(val, {
            timeInterval: deepGet(schema, [x, 'functionValue']),
            labelConfig,
          }),
      },
      [y]: getYAxisScale({
        log,
        axisConfig,
        data: transformedData,
        y,
      }),
    };
  }, [log, x, y, labelConfig, axisConfig, transformedData, schema]);

  const getTooltipValueColor = (val) => {
    if (Array.isArray(val)) {
      return {
        name: y,
        value: `${formatMetricLabel(val[1])} <span style="color: ${
          val[1] === val[0]
            ? 'inherit'
            : val[1] - val[0] > 0
            ? color.increase
            : color.decrease
        }">(${val[1] - val[0] > 0 ? '+' : ''}${formatMetricLabel(
          val[1] - val[0]
        )})</span>`,
      };
    }
    return {
      name: y,
      value: formatMetricLabel(val),
    };
  };

  // Collect bar click data for drilldown
  const onBarClick = (ev) => {
    const targetData = ev.data ? ev.data._origin : {};
    if (!drillDownDisabled) {
      dispatch({
        type: 'SET_DRILLDOWN',
        drilldown: {
          x: ev.x,
          y: ev.y,
          chartElement: getChartElement(ev, chart.current),
          targetData,
          queryObj,
        },
      });
    } else if (commentsEditing) {
      dispatch({
        type: 'SET_DASHBOARD_CHART_COMMENT_POINT',
        point: {
          dimension: targetData[x],
          metric: targetData[y],
        },
      });
    }
  };

  return (
    isShapeRegistered && (
      <Chart
        height={height}
        data={transformedData}
        scale={scale}
        padding="auto"
        renderer={renderer}
        forceFit
        onIntervalClick={(ev) => onBarClick(ev)}
        onGetG2Instance={(g2Chart) => {
          chart.current = g2Chart;
        }}
      >
        <CoordConfig option={{ reverse }} />
        <Legend
          custom
          clickable={false}
          items={[
            {
              value: 'Increase',
              marker: {
                symbol: 'square',
                fill: color.increase,
                radius: 5,
              },
            },
            {
              value: 'Decrease',
              marker: {
                symbol: 'square',
                fill: color.decrease,
                radius: 5,
              },
            },
          ]}
        />
        <Axis
          name={x}
          label={xAxisLabelConfig}
          {...getAxisConfig(config, 'x')}
        />
        <Axis
          name={y}
          position={reverse ? 'right' : 'left'}
          {...getAxisConfig(config, 'y')}
        />
        <Tooltip />
        <Geom
          type="interval"
          position={`${x}*${y}`}
          color={[
            `${x}*${y}`,
            (xValue, yValue) => {
              if (Array.isArray(yValue)) {
                return yValue[1] - yValue[0] >= 0
                  ? color.increase
                  : color.decrease;
              }
              return yValue >= 0 ? color.increase : color.decrease;
            },
          ]}
          tooltip={[
            `${x}*${y}`,
            (xValue, yValue) => getTooltipValueColor(yValue),
          ]}
          shape="waterfall"
          style={{
            cursor: drillDownDisabled && !commentsEditing ? 'auto' : 'pointer',
          }}
        >
          {labelDisplay && (
            <Label
              content={y}
              formatter={(val, item) => {
                const yValue = item.point[y];
                if (Array.isArray(yValue)) {
                  return formatMetricLabel(yValue[1]);
                }
                return formatMetricLabel(yValue);
              }}
            />
          )}
        </Geom>

        {/* Dashboard chart comments */}
        <ChartComments
          visible={commentsShowing}
          comments={comments}
          data={transformedData}
          x={x}
          y={y}
        />
      </Chart>
    )
  );
};

export default connect()(WaterfallChart);
