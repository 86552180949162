import queryString from 'qs';

/**
 * Converts query string to structured object of filters
 * @param {String} filterString
 */
export const queryStringToObject = urlParams => {
    const parsedParams = queryString.parse(
        urlParams[0] === '?'
            ? urlParams.slice(1)
            : urlParams,
        { arrayLimit: `${urlParams}`.length },
    );

    return parsedParams;
};

/**
 * Convert Object to URL parameters
 * (value can be nested, but topmost level must be a standard Object)
 * @param {Object} filters
 */
export const convertToURLParams = (object = {}) => {
    return queryString.stringify(object);
};
