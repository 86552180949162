/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { Input } from 'antd';

import { CHART_TYPES } from 'constant';
import ChartTypeModal from './ChartTypeModal';

const { byType } = CHART_TYPES;

const ChartTypeInput = ({ value, onChange }) => {
  const [isChartsModalOpen, setIsChartsModalOpen] = useState(false);

  return (
    <>
      <Input
        data-testId="new-chart-chartType-dropdown"
        readOnly
        value={byType[value] && byType[value].name}
        placeholder="Select Chart Type"
        onClick={() => setIsChartsModalOpen(true)}
        css={{ cursor: 'pointer' }}
      />

      <ChartTypeModal
        open={isChartsModalOpen}
        onCancel={() => setIsChartsModalOpen(false)}
        onSelect={onChange}
      />
    </>
  );
};

export default ChartTypeInput;
