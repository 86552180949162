import chroma from 'chroma-js';

const findMedian = (arr) => {
  arr.sort((a, b) => a - b);
  const middleIndex = Math.floor(arr.length / 2);

  if (arr.length % 2 === 0) {
    return (arr[middleIndex - 1] + arr[middleIndex]) / 2;
  }
  return arr[middleIndex];
};

const convertzRangeToMinMidMax = (data, colorBy, zRange) => {
  const { min, mid, max } = zRange;

  const intData = data
    .map((dataPoint) => dataPoint[colorBy])
    .sort((a, b) => {
      return a - b;
    });

  const intDataLen = intData.length;

  return {
    min: min ?? intData[0],
    mid: mid ?? findMedian(intData),
    max: max ?? intData[intDataLen - 1],
  };
};

const getColorByMetric = (chartConfig = {}) => {
  const { x, config, color, data, colorBy, callback = false } = chartConfig;
  const { fromColor, midColor, toColor } = color;

  const { min, mid, max } = convertzRangeToMinMidMax(
    data,
    colorBy,
    config.zRange || {}
  );

  const colorScale = chroma
    .scale([fromColor, midColor, toColor])
    .domain([min, mid, max], 10000);

  if (!x) {
    return (val) => colorScale(val).hex();
  }

  return [
    x,
    callback
      ? (val) => colorScale(val).hex()
      : data.map((row) => colorScale(row[colorBy]).hex()),
  ];
};

export default getColorByMetric;
