/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';
import { useAuth } from '@otso/auth-wrapper';

import {
  Form,
  Select,
  Row,
  Col,
  Switch,
  Input,
  InputNumber,
  Button,
  Typography,
} from 'antd';

import { preset } from 'styles';

const AlertForm = ({ alert, okText, onCancel, onSave, loading = false }) => {
  const { currentOrg } = useAuth();

  const [form] = Form.useForm();

  useEffect(() => {
    const {
      name,
      isActive,
      alertShares,
      config: {
        occurrence,
        hasFilter,
        operator,
        thresholdValue,
        filterTimeframe,
      },
    } = alert;

    const filterTimeframeValue = filterTimeframe
      ? `${filterTimeframe}`.substr(0, filterTimeframe.length - 1)
      : 1;
    const filterTimeframeUnit =
      (filterTimeframe &&
        `${filterTimeframe}`.substr(filterTimeframe.length - 1)) ||
      'd';

    form.setFieldsValue({
      name,
      isActive,
      alertShares: alertShares || [],
      occurrence,
      operator,
      thresholdValue,
      hasFilter,
      filterTimeframe: filterTimeframeValue,
      filterTimeframeUnit,
    });
  }, [alert, form]);

  const selectAfter = (
    <Form.Item name="filterTimeframeUnit" noStyle>
      <Select defaultValue="d" css={{ minWidth: '120px' }}>
        <Select.Option value="s">seconds</Select.Option>
        <Select.Option value="m">minutes</Select.Option>
        <Select.Option value="h">hours</Select.Option>
        <Select.Option value="d">days</Select.Option>
      </Select>
    </Form.Item>
  );

  return (
    <Form layout="vertical" form={form} onFinish={onSave}>
      <Row gutter={preset.spacing(5)}>
        <Col span={12}>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Alert must have a name' }]}
          >
            <Input autoFocus />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name="isActive"
            label="Is this alert active?"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        noStyle
        shouldUpdate={(prev, current) =>
          prev.thresholdValue !== current.thresholdValue ||
          prev.operator !== current.operator
        }
      >
        {({ getFieldValue }) => {
          return (
            <Row gutter={preset.spacing(3)}>
              <Col span={5}>
                <Form.Item
                  name="operator"
                  label="Match condition"
                  tooltip="The operator rule that gets applied to this alert"
                  rules={[
                    {
                      required: true,
                      message: 'Alert must have a match operator',
                    },
                  ]}
                >
                  <Select>
                    <Select.Option value=">">Is above</Select.Option>
                    <Select.Option value=">=">Is above or equals</Select.Option>
                    <Select.Option value="<">Is below</Select.Option>
                    <Select.Option value="<=">Is below or equals</Select.Option>
                    <Select.Option value="between">Is between</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              {getFieldValue('operator') === 'between' ? (
                <>
                  <Col span={3}>
                    <Form.Item
                      name="thresholdValueLower"
                      label="Lower Threshold"
                      rules={[
                        {
                          required: true,
                          message: 'Alert must have a lower threshold value',
                        },
                      ]}
                      css={{ marginBottom: 0 }}
                    >
                      <InputNumber min={1} css={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      name="thresholdValueUpper"
                      label="Upper Threshold"
                      rules={[
                        {
                          required: true,
                          message: 'Alert must have an upper threshold value',
                        },
                      ]}
                      css={{ marginBottom: 0 }}
                    >
                      <InputNumber min={1} css={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                </>
              ) : (
                <Col span={3}>
                  <Form.Item
                    name="thresholdValue"
                    label="Threshold"
                    rules={[
                      {
                        required: true,
                        message: 'Alert must have a threshold value',
                      },
                    ]}
                    css={{ marginBottom: 0 }}
                  >
                    <InputNumber min={1} css={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              )}
            </Row>
          );
        }}
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prev, current) => prev.hasFilter !== current.hasFilter}
      >
        {({ getFieldValue }) => {
          return (
            <>
              <Row gutter={preset.spacing(3)}>
                <Col span={1} css={{ alignSelf: 'center' }}>
                  <Form.Item name="hasFilter" label="" valuePropName="checked">
                    <Switch id="hasFilter-switch" />
                  </Form.Item>
                </Col>
                <Col css={{ alignSelf: 'start' }}>
                  <Typography.Text
                    css={{
                      display: 'inline-block',
                      marginLeft: preset.spacing(1),
                      marginTop: '4px',
                      paddingRight: 0,
                    }}
                  >
                    <label htmlFor="hasFilter-switch">
                      Filtered by a specific time frame?
                    </label>
                  </Typography.Text>
                </Col>

                {getFieldValue('hasFilter') === true && (
                  <>
                    <Col span={6} flex="0 1 auto" css={{ alignSelf: 'start' }}>
                      <Typography.Text
                        css={{ display: 'inline-block', marginTop: '4px' }}
                      >
                        For the last
                      </Typography.Text>
                    </Col>
                    <Col span={6} css={{ alignSelf: 'center' }}>
                      <Form.Item name="filterTimeframe">
                        <InputNumber
                          min={1}
                          css={{ width: '200px' }}
                          addonAfter={selectAfter}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>
            </>
          );
        }}
      </Form.Item>
      <Form.Item
        name="alertShares"
        label="Send notification emails to"
        rules={[
          {
            required: true,
            message: 'Alert must have a notification recipient',
          },
        ]}
      >
        <Select showSearch mode="multiple" optionFilterProp="children">
          {(currentOrg?.users || []).map((user) => (
            <Select.Option key={user.id} title={user.email} value={user.id}>
              {user.email}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        css={{
          textAlign: 'right',
          marginBottom: 0,
        }}
      >
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          style={{ marginLeft: preset.spacing(2) }}
        >
          {okText}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AlertForm;
