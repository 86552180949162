/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import sumBy from 'lodash/sumBy';

import { List, Typography } from 'antd';

import { preset } from 'styles';
import formatChartData from '@modules/chart/formatChartData';
import formatDimensionLabel from '@modules/chart/formatDimensionLabel';
import deepGet from '@modules/deepGet';

const { Title } = Typography;

const ListChart = ({ height, config, color, data, schema }) => {
  const { x, y, label: labelConfig } = config;

  // Remove empty x
  const formattedData = useMemo(() => formatChartData(data, [x]), [data, x]);

  // Calc sum
  const dataSum = useMemo(() => sumBy(data, y), [data, y]);

  return (
    <div
      css={{
        height,
        paddingRight: preset.spacing(3),
        paddingLeft: 4,
        overflowY: 'auto',
        '.ant-list-header h1': {
          color: color.text,
          marginBottom: 0,
        },
        '.ant-list-item-meta-title': {
          marginBottom: 0,
        },
      }}
    >
      <List
        size="small"
        header={<Title>{dataSum}</Title>}
        dataSource={formattedData}
        renderItem={(item) => (
          <List.Item key={item[x]}>
            <List.Item.Meta
              title={formatDimensionLabel(item[x], {
                timeInterval: deepGet(schema, [x, 'functionValue']),
                labelConfig,
              })}
            />
            <div>{item[y]}</div>
          </List.Item>
        )}
      />
    </div>
  );
};

export default ListChart;
