/** @jsxImportSource @emotion/react */

import { Collapse, Form, Switch } from 'antd';

import { colors, preset } from 'styles';
import formatChartColorLabel from '@modules/chart/formatChartColorLabel';
import ColorPicker from '@components/ColorPicker';

const { Panel } = Collapse;
const FormItem = Form.Item;

const ColorPanelBody = ({
  colors: customColors,
  chart,
  dispatch,
  capitaliseLabel = false,
}) => {
  const {
    chartType,
    queryObj,
    defaultConfig = {},
    defaultColor = {},
    schema,
  } = chart || {};

  const filteredColors = Object.keys(defaultColor).reduce(
    (prev, colorKey) =>
      [
        'custom',
        'fromColor',
        'midColor',
        'toColor',
        ...(chartType === 'bar_basic' ? ['bar'] : []),
        ...(chartType === 'gauge'
          ? ['negative', 'neutral', 'positive', 'default']
          : []),
      ].includes(colorKey)
        ? prev
        : { ...prev, [colorKey]: defaultColor[colorKey] },
    {}
  );

  const displayColors = customColors || filteredColors;

  const hasGradient = defaultConfig.gradient !== undefined;

  return (
    <div
      css={{
        display: 'flex',
        flexFlow: 'wrap',
      }}
    >
      {Object.keys(displayColors).map((colorKey) => (
        <div
          key={colorKey}
          css={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 12,
            marginRight: preset.spacing(5),
          }}
        >
          <span
            css={{
              textTransform: capitaliseLabel ? 'capitalize' : 'none',
              color: colors.typography.primary,
              marginRight: preset.spacing(1),
            }}
          >
            {customColors
              ? colorKey
              : formatChartColorLabel(colorKey, {
                  chartType,
                  defaultConfig,
                  queryObj,
                  schema,
                })}
            :
          </span>
          <ColorPicker
            color={displayColors[colorKey]}
            onColorChange={(color) =>
              dispatch({
                type: 'SET_COLOR',
                value: color.hex,
                key: colorKey,
              })
            }
          />
        </div>
      ))}

      {hasGradient && (
        <FormItem
          label="Gradient"
          css={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 12,
          }}
        >
          <Switch
            checked={!!defaultConfig.gradient}
            onChange={(checked) =>
              dispatch({
                type: 'SET_CONFIG',
                value: checked,
                key: 'gradient',
              })
            }
          />
        </FormItem>
      )}
    </div>
  );
};

const ColorPanel = (props) => {
  const { chart, dispatch, capitaliseLabel, ...panelProps } = props;

  return (
    <Panel {...panelProps} key="chart_color" header="Chart Colour">
      <ColorPanelBody {...props} />
    </Panel>
  );
};

ColorPanel.Body = ColorPanelBody;

export default ColorPanel;
