const formatChartData = (data = [], dimensionKeys = []) => {
    if (!Array.isArray(data)) return [];

    return Array.isArray(dimensionKeys) ? data.map(row => ({
        ...row,
        // Repalce null dimension value with 'No Data'
        ...dimensionKeys.reduce((prev, dimensionKey) => ({
            ...prev,
            ...(dimensionKey ? {
                [dimensionKey]: row[dimensionKey] === null ? 'No Data' : `${row[dimensionKey]}`,
            } : {}),
        }), {}),
    })) : data;
};

export default formatChartData;
