/** @jsxImportSource @emotion/react */
import { useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { Chart, Axis, Tooltip, Geom, Guide, Label } from 'bizcharts';
import meanBy from 'lodash/meanBy';
import round from 'lodash/round';

import { preset } from 'styles';
import { deepGet, useChartRef } from 'modules';
import {
  getAxisConfig,
  formatDimensionLabel,
  getYAxisScale,
  formatMetricLabel,
  getChartElement,
  getColorByMetric,
  formatChartData,
} from 'modules/chart';

const { Line } = Guide;

const PlottedBubble = ({
  width,
  height,
  config,
  color,
  queryObj,
  data,
  schema,
  drillDownDisabled = false,
  renderer,
  setChartDownloadInstance,
  // redux dispatch for triggering drilldown
  dispatch,
}) => {
  const {
    name,
    x,
    y,
    z,
    colorBy,
    baseline,
    minified = false,
    label: labelConfig,
    axis: axisConfig,
  } = config;

  // Init chart instance
  const chart = useRef();

  // Re-init chart when width changes and pass chartRef to download modal
  useChartRef(chart, width, setChartDownloadInstance);

  // Remove empty x
  const formattedData = useMemo(
    () => formatChartData(data, [name]),
    [data, name]
  );

  // Config chart scale
  const scale = useMemo(() => {
    return {
      [name]: {
        formatter: (val) =>
          formatDimensionLabel(val, {
            timeInterval: deepGet(schema, [name, 'functionValue']),
            labelConfig,
          }),
      },
      [x]: {
        ...getYAxisScale({
          axisConfig,
          yAxisKey: 'x',
          data,
          y: x,
        }),
        nice: false,
        alias: x,
      },
      [y]: {
        ...getYAxisScale({
          axisConfig,
          yAxisKey: 'y',
          data,
          y,
        }),
        nice: false,
        alias: y,
      },
      [z]: { formatter: (val) => formatMetricLabel(val) },
    };
  }, [name, x, axisConfig, data, y, z, schema, labelConfig]);

  // Get bubble color
  const getBubbleColor = (label) => {
    if (colorBy === name) {
      if (label) {
        return color[label];
      }
      return [name, (nameVal) => color[nameVal]];
    }

    const colorByMetric = getColorByMetric({
      x: name,
      config,
      color,
      data: formattedData,
      colorBy,
    });
    if (label) {
      const nameIndex = formattedData.findIndex((row) => row[name] === label);
      return nameIndex && colorByMetric[1][nameIndex];
    }
    return colorByMetric;
  };

  // Get data median for Guide
  const dataMedian = useMemo(
    () => ({
      x: round(meanBy(formattedData, x), 2),
      y: round(meanBy(formattedData, y), 2),
    }),
    [formattedData, x, y]
  );

  // Collect bubble click data for drilldown
  const onPointClick = (ev) => {
    if (!drillDownDisabled) {
      const targetData = ev.data ? ev.data._origin : {};

      dispatch({
        type: 'SET_DRILLDOWN',
        drilldown: {
          x: ev.x,
          y: ev.y,
          chartElement: getChartElement(ev, chart.current),
          targetData,
          queryObj,
        },
      });
    }
  };

  return (
    <Chart
      height={height}
      data={formattedData}
      scale={scale}
      padding="auto"
      renderer={renderer}
      forceFit
      onPointClick={(ev) => onPointClick(ev)}
      onGetG2Instance={(g2Chart) => {
        chart.current = g2Chart;
      }}
      plotBackground={{
        stroke: '#ccc',
        lineWidth: 1,
      }}
    >
      <Axis
        name={x}
        grid={{
          lineStyle: {
            stroke: '#d9d9d9',
            lineWidth: 1,
            lineDash: [2, 2],
          },
        }}
        title={{ offset: preset.spacing(5) }}
        {...getAxisConfig(config, 'x')}
      />
      <Axis name={y} title={{ offset: 60 }} {...getAxisConfig(config, 'y')} />
      <Tooltip title={name} />
      <Geom
        type="point"
        position={`${x}*${y}`}
        color={getBubbleColor()}
        style={[
          name,
          {
            lineWidth: 1,
            cursor: drillDownDisabled ? 'auto' : 'pointer',
            stroke: (nameVal) => getBubbleColor(nameVal),
          },
        ]}
        shape="circle"
        size={[z, minified ? [4, 10] : [15, 60]]}
        tooltip={`${x}*${y}*${z}`}
        opacity={0.3}
      >
        {!minified && (
          <Label
            content={name}
            offset={0}
            textStyle={(nameVal) => {
              return {
                fill: getBubbleColor(nameVal),
                textBaseline: 'middle',
              };
            }}
          />
        )}
      </Geom>
      <Guide>
        {Object.keys({ x, y }).map(
          (axisKey) =>
            baseline[axisKey].display && (
              <Line
                key={`baseline_${axisKey}`}
                start={
                  axisKey === 'x'
                    ? [dataMedian.x, 'min']
                    : ['min', dataMedian.y]
                }
                end={
                  axisKey === 'x'
                    ? [dataMedian.x, 'max']
                    : ['max', dataMedian.y]
                }
                text={{
                  content: `${dataMedian[axisKey]}`,
                  position: 'end',
                  autoRotate: axisKey === 'y',
                  style: {
                    fill: baseline[axisKey].color,
                    fontSize: '11.5',
                    textAlign: axisKey === 'x' ? 'start' : 'end',
                  },
                }}
                lineStyle={{
                  stroke: baseline[axisKey].color,
                }}
              />
            )
        )}
      </Guide>
    </Chart>
  );
};

export default connect()(PlottedBubble);
