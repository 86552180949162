/** @jsxImportSource @emotion/react */
import { useState, useMemo } from 'react';
import { Modal, Input, Row, Col, Card } from 'antd';

import { preset } from 'styles';
import { CHART_TYPES } from 'constant';

const { allTypes, byType } = CHART_TYPES;

const ChartTypeModal = ({ open = false, onCancel, onSelect }) => {
  const [searchStr, setSeachStr] = useState();

  const filteredVisTypes = useMemo(
    () =>
      allTypes.filter((visType) => {
        if (searchStr) {
          return byType[visType].name
            .toLowerCase()
            .includes(searchStr.toLowerCase());
        }
        return true;
      }),
    [searchStr]
  );

  return (
    <Modal
      title="Chart Types"
      visible={open}
      footer={null}
      width={preset.modalWidth.lg}
      onCancel={onCancel}
    >
      <Input.Search
        data-testId="chart-type-search-input"
        autoFocus
        allowClear
        placeholder="Press Enter to Search"
        onSearch={(val) => setSeachStr(val)}
        css={{
          maxWidth: 500,
          marginBottom: preset.spacing(3),
        }}
      />

      <Row gutter={preset.spacing(3)}>
        {filteredVisTypes.map((visType) => (
          <Col key={visType} span={8}>
            <Card
              data-testId="chart-img"
              hoverable
              bordered={false}
              cover={
                <img alt={visType} src={`/img/charts/${byType[visType].img}`} />
              }
              onClick={() => {
                onSelect(visType);
                onCancel();
              }}
              css={{
                height: 320,
                marginBottom: preset.spacing(3),
                '.ant-card-cover': {
                  height: 320 - preset.spacing(10),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  img: {
                    width: 'auto',
                    maxWidth: '100%',
                    maxHeight: '100%',
                  },
                },
                '.ant-card-body': {
                  height: preset.spacing(10),
                  padding: 0,
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                },
              }}
            >
              <Card.Meta
                title={byType[visType].name}
                description={byType[visType].hints}
              />
            </Card>
          </Col>
        ))}
      </Row>
    </Modal>
  );
};

export default ChartTypeModal;
