/** @jsxImportSource @emotion/react */
import { useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { Chart, Axis, Tooltip, Geom, Label } from 'bizcharts';

import { deepGet, useChartRef } from 'modules';
import {
  getAxisConfig,
  getYAxisScale,
  getXAxisLabelConfig,
  getColorByMetric,
  formatDimensionLabel,
  formatBarChartData,
  getChartElement,
} from 'modules/chart';
import CoordConfig from '../components/CoordConfig';
import ChartComments from '../components/ChartComments';

const BarChart = ({
  width,
  height,
  config,
  color,
  queryObj,
  data,
  schema,
  drillDownDisabled = false,
  // chart download
  renderer,
  setChartDownloadInstance,
  // dashboard chart comments
  comments = [],
  commentsShowing = false,
  commentsEditing = false,
  // redux dispatch for triggering drilldown
  dispatch,
}) => {
  const {
    x,
    y,
    reverse = false,
    transpose = false,
    colorBy,
    log = false,
    label: labelConfig,
    axis: axisConfig,
  } = config;

  const { dimensions } = queryObj;

  // Init chart instance
  const chart = useRef();

  // Re-init chart when width changes and pass chartRef to download modal
  useChartRef(chart, width, setChartDownloadInstance);

  // Get chart config
  const xAxisLabelConfig = useMemo(() => getXAxisLabelConfig(config), [config]);
  const barLabelDisplay = config.label ? config.label.display : false;
  const noDimension = !(Array.isArray(dimensions) && dimensions.length > 0);
  const hasTimeDimension = noDimension
    ? false
    : !!dimensions.find((dimension) => dimension.functionValue);
  const showEmptyDate = config.showEmptyDate && hasTimeDimension;

  // Config X and Y for two types of data
  const transformedX = noDimension ? 'label' : x;
  const transformedY = noDimension ? 'value' : y;

  // Transform data if no dimension
  const transformedData = useMemo(() => {
    if (noDimension) {
      return Object.keys(data[0]).map((key) => ({
        label: key,
        value: data[0][key],
      }));
    }

    // Remove empty x and insert empty date when needed
    const formattedData = formatBarChartData({
      data,
      x,
      queryObj,
      showEmptyDate,
      schema,
    });

    return formattedData;
  }, [noDimension, data, x, showEmptyDate, queryObj, schema]);

  // Config chart scale
  const scale = useMemo(() => {
    return {
      [transformedX]: {
        formatter: (val) =>
          formatDimensionLabel(val, {
            timeInterval: deepGet(schema, [transformedX, 'functionValue']),
            labelConfig,
          }),
      },
      [transformedY]: getYAxisScale({
        log,
        axisConfig,
        data: transformedData,
        y: transformedY,
      }),
    };
  }, [
    log,
    transformedX,
    transformedY,
    labelConfig,
    axisConfig,
    transformedData,
    schema,
  ]);

  // Config bar color based on colorBy
  const barColor = useMemo(() => {
    switch (colorBy) {
      case 'one colour':
        return color.bar;
      case transformedY:
        return getColorByMetric({
          x: transformedX,
          config,
          color,
          data: transformedData,
          colorBy,
        });
      default:
        return [transformedX, (xValue) => color[xValue]];
    }
  }, [colorBy, color, transformedY, transformedX, config, transformedData]);

  // Collect bar click data for drilldown
  const onBarClick = (ev) => {
    const targetData = ev.data ? ev.data._origin : {};
    if (!drillDownDisabled) {
      dispatch({
        type: 'SET_DRILLDOWN',
        drilldown: {
          x: ev.x,
          y: ev.y,
          chartElement: getChartElement(ev, chart.current),
          targetData,
          queryObj,
        },
      });
    } else if (commentsEditing) {
      dispatch({
        type: 'SET_DASHBOARD_CHART_COMMENT_POINT',
        point: {
          dimension: targetData[transformedX],
          metric: targetData[transformedY],
        },
      });
    }
  };

  return (
    <Chart
      height={height}
      data={transformedData}
      scale={scale}
      padding="auto"
      renderer={renderer}
      forceFit
      onIntervalClick={(ev) => onBarClick(ev)}
      onGetG2Instance={(g2Chart) => {
        chart.current = g2Chart;
      }}
    >
      <CoordConfig option={{ transpose, reverse }} />
      <Axis
        name={transformedX}
        label={xAxisLabelConfig}
        {...getAxisConfig(config, 'x')}
      />
      <Axis
        name={transformedY}
        position={reverse ? 'right' : 'left'}
        {...getAxisConfig(config, 'y')}
      />
      <Tooltip />
      <Geom
        type="interval"
        color={barColor}
        position={`${transformedX}*${transformedY}`}
        style={{
          cursor: drillDownDisabled && !commentsEditing ? 'auto' : 'pointer',
        }}
      >
        {barLabelDisplay && <Label content={transformedY} />}
      </Geom>

      {/* Dashboard chart comments */}
      <ChartComments
        visible={commentsShowing}
        comments={comments}
        data={transformedData}
        x={transformedX}
        y={transformedY}
      />
    </Chart>
  );
};

export default connect()(BarChart);
