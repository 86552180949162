/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// Import Ant Design
import { Result, Button } from 'antd';

const SuccessDisplay = ({ newProjectSlug = '' }) => {
  const history = useHistory();
  return (
    <Result
      status="success"
      title="Project Created"
      subTitle="Your project has been successfully created."
      extra={[
        <Button key="back" onClick={() => history.push('/projects')}>
          Back to Projects Page
        </Button>,
        <Button
          key="new project"
          type="primary"
          onClick={() =>
            history.push(
              newProjectSlug
                ? `/projects/${newProjectSlug}/dashboards`
                : '/projects'
            )
          }
        >
          View Project
        </Button>,
      ]}
    />
  );
};

SuccessDisplay.propTypes = {
  newProjectSlug: PropTypes.string,
};

export default SuccessDisplay;
