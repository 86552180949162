/** @jsxImportSource @emotion/react */

import { Collapse } from 'antd';

import RangeColorPanel from './RangeColorPanel';
import StyledCollapse from '../../StyledCollapse';
import ColorBySelect from './ColorBySelect';
import LegendPanel from './LegendPanel';

const { Panel } = Collapse;
const RangeColorPanelBody = RangeColorPanel.Body;

const ChoroplethMapConfig = ({
    chart,
    dispatch,
}) => {
    const {
        queryObj: { metrics = [] },
        defaultConfig = {},
        defaultColor = {},
    } = chart;

    const colorByOptions = metrics.map(metric => ({
        key: metric.as, label: metric.as,
    }));

    return (
        <StyledCollapse>
            <Panel
                key="chart_color"
                header="Map Colour"
            >
                <ColorBySelect
                    options={colorByOptions}
                    value={defaultConfig.colorBy}
                    onChange={val => dispatch({
                        type: 'SET_CONFIG',
                        value: val,
                        key: 'colorBy',
                    })}
                />

                <RangeColorPanelBody
                    defaultConfig={defaultConfig}
                    defaultColor={defaultColor}
                    dispatch={dispatch}
                />
            </Panel>

            <LegendPanel
                defaultConfig={defaultConfig}
                dispatch={dispatch}
            />
        </StyledCollapse>
    );
};

export default ChoroplethMapConfig;
