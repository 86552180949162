import { useEffect } from 'react';

const useChartRef = (
    chartRef, width, setChartDownloadInstance, isChartLoaded = true,
) => {
    // Re-fit chart into container after layout changes
    useEffect(() => {
        if (width && chartRef.current) {
            chartRef.current.forceFit();
        }
    }, [width, chartRef]);

    // Pass chart instance to download modal
    useEffect(() => {
        if (setChartDownloadInstance && chartRef.current && isChartLoaded) {
            setChartDownloadInstance(chartRef.current);
        }
    }, [setChartDownloadInstance, chartRef, isChartLoaded]);
};

export default useChartRef;
