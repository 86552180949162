/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import debounce from 'lodash/debounce';

import { Select, message, Button } from 'antd';

import { fetchApiErrorMessage } from '../../modules/api';

const { Option } = Select;

const MulitSelectWithSearch = ({
  label,
  value = [],
  onChange,
  onSearch,
  disabled = false,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = async (q) => {
    setData([]);
    setLoading(true);

    try {
      const result = await onSearch(q);
      setData(result);
    } catch (error) {
      message.error(fetchApiErrorMessage(error));
    }

    setLoading(false);
  };

  const handleChange = (newVal) => {
    setData([]);
    setLoading(false);
    onChange(newVal);
  };

  return (
    <Select
      mode="tags"
      disabled={disabled}
      placeholder={disabled ? '' : `Enter ${label} to search`}
      filterOption={false}
      tokenSeparators={[';']}
      notFoundContent={null}
      loading={loading}
      value={value}
      onChange={handleChange}
      onSearch={debounce(handleSearch, 800)}
      dropdownRender={(menu) =>
        data.length > 500 ? (
          <Button disabled block type="text">
            500+ Results Found
          </Button>
        ) : (
          menu
        )
      }
    >
      {data.map((str) => (
        <Option key={str}>{str}</Option>
      ))}
    </Select>
  );
};

export default MulitSelectWithSearch;
