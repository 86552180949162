/** @jsxImportSource @emotion/react */
import { preset } from 'styles';

type Props = {
  children: React.ReactNode;
  paddingY?: number;
  fullWidth?: boolean;
  className?: string;
};

const Container: React.FC<Props> = ({
  fullWidth = false,
  paddingY,
  children,
  className,
}) => (
  <div
    className={className}
    css={{
      width: '100%',
      maxWidth: fullWidth ? 'none' : preset.containerWidth,
      paddingLeft: preset.spacing(3),
      paddingRight: preset.spacing(3),
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingTop: paddingY ? preset.spacing(paddingY) : 0,
      paddingBottom: paddingY ? preset.spacing(paddingY) : 0,
      '> .ant-page-header': {
        paddingLeft: 0,
        paddingRight: 0,
      },
      [preset.breakpoints.lg]: {
        paddingLeft: preset.spacing(5),
        paddingRight: preset.spacing(5),
      },
    }}
  >
    {children}
  </div>
);

export default Container;
