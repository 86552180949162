/** @jsxImportSource @emotion/react */
import { useMemo, useState } from 'react';

import { Card, Form } from 'antd';

import { preset } from 'styles';

import Empty from '@components/Empty';
import ChartDataTable from './ChartDataTable';
import CustomiseForm from './CustomiseForm';

const DataAndCustomiseCard = ({ loading = false, chart, dispatch }) => {
  const {
    data,
    queryObj: { metrics = [], dimensions = [], compare },
    defaultConfig = {},
    schema,
  } = chart;

  const [activeTab, setActiveTab] = useState('data');

  const hasData = useMemo(() => {
    if (!(Array.isArray(data) && data.length > 0)) return false;

    if (
      compare &&
      data.length === 2 &&
      (!Array.isArray(data[0]) || data[0].length === 0)
    ) {
      return false;
    }

    return true;
  }, [compare, data]);

  return (
    <Card
      bordered={false}
      loading={loading}
      activeTabKey={activeTab}
      tabList={[
        { key: 'data', tab: 'Data' },
        { key: 'customise', tab: 'Customise' },
      ]}
      onTabChange={(tab) => setActiveTab(tab)}
      css={{
        flex: 1,
        minHeight: 0,
        display: 'flex',
        flexDirection: 'column',
        '.ant-card-body': {
          flex: 1,
          overflowY: 'auto',
        },
      }}
    >
      {hasData ? (
        activeTab === 'data' ? (
          <ChartDataTable
            loading={loading}
            data={data}
            metrics={metrics}
            dimensions={dimensions}
            compare={compare}
            schema={schema}
            config={{
              ...defaultConfig,
              rowsPerPage: 10,
            }}
          />
        ) : (
          <Form
            layout="vertical"
            css={{
              '.ant-collapse-item .ant-collapse-content .ant-collapse-content-box':
                {
                  paddingTop: preset.spacing(2),
                },
            }}
          >
            <CustomiseForm chart={chart} dispatch={dispatch} />
          </Form>
        )
      ) : (
        <Empty fullHeight />
      )}
    </Card>
  );
};

export default DataAndCustomiseCard;
