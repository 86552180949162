/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import { List } from 'antd';

import { ExploreCard, DocLabelTag } from 'components';
import { Dataset } from 'types';
import { getCustomClassifierFields } from 'modules';
import { preset } from 'styles';

type Props = { document: Record<string, any>; dataset: Dataset };

const CustomClassifiersInfo: React.FC<Props> = ({ document, dataset }) => {
  const {
    integrationMeta: { audio: isAudioDataset },
  } = dataset;

  const customClassifierData: {
    name: string;
    displayName: string;
    value: any;
  }[] = useMemo(() => {
    const {
      schema: { fields },
    } = dataset;
    const customClassifierFields = getCustomClassifierFields(fields);
    return customClassifierFields.map((field) => ({
      name: field.name,
      displayName: field.displayName || field.name,
      value: document[field.name],
    }));
  }, [dataset, document]);

  return (
    <ExploreCard
      title={
        isAudioDataset
          ? 'Document (Aggregate) Classifications'
          : 'Custom Classifiers'
      }
    >
      <List
        split={false}
        size="small"
        dataSource={customClassifierData}
        rowKey="name"
        renderItem={(item) => {
          return (
            <List.Item>
              <List.Item.Meta title={item.displayName} />
              <div
                css={{
                  maxWidth: `${200 / 3}%`,
                  marginLeft: preset.spacing(2),
                  '.ant-space': { justifyContent: 'flex-end' },
                }}
              >
                <DocLabelTag
                  name={item.name}
                  value={item.value}
                  displayName={item.displayName}
                  isTooltipVisible={false}
                />
              </div>
            </List.Item>
          );
        }}
        css={{
          '.ant-list-item': {
            display: 'flex',
            paddingLeft: 0,
            paddingRight: 0,
          },
        }}
      />
    </ExploreCard>
  );
};

export default CustomClassifiersInfo;
