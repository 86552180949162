// Import libraries
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';

// Import Ant Design components
import { MenuOutlined } from '@ant-design/icons';

const SortableItem = sortableElement((props) => <tr {...props} />);

const SortableContainer = sortableContainer((props) => <tbody {...props} />);

export const DraggableContainer = ({ onSortEnd, ...restProps }) => {
  return (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...restProps}
    />
  );
};

export const DraggableBodyRow = ({ index, ...restProps }) => {
  return <SortableItem key={index} index={index} {...restProps} />;
};

export const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
));
