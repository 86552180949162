export const primary = '#00958F';
export const brand = '#044F50';
export const secondary = '#FC9A92';
export const background = '#f0f2f5';
export const inputIcon = 'rgba(0,0,0,.25)';
export const typography = {
  primary: 'rgba(0, 0, 0, .85)',
  secondary: 'rgba(0, 0, 0, 0.45)',
  disable: 'rgba(0, 0, 0, 0.25)',
};

export const white = '#FFFFFF';
export const grey = '#FAFAFA';
export const darkGrey = '#8c8c8c';
export const lightGrey = '#bfbfbf';
export const green = '#52c41a';
export const red = '#f5222d';
export const gold = '#faad14';

export const sentiment = {
  negative: '#f5222d',
  neutral: '#8c8c8c',
  positive: '#52c41a',
  multiple: '#bfbfbf',
  mixed: '#bfbfbf',
};

export const emotion = {
  joy: '#fadb14',
  fear: '#722ed1',
  anger: '#f5222d',
  disgust: '#5b8c00',
  sadness: '#1890ff',
  anticipation: '#fa8c16',
  surprise: '#ff85c0',
  trust: '#061178',
};

export const chart = {
  default: '#1890FF',
  sm: [
    '#1890FF',
    '#2FC25B',
    '#FACC14',
    '#223273',
    '#8543E0',
    '#13C2C2',
    '#3436C7',
    '#F04864',
  ],
  md: [
    '#1890FF',
    '#41D9C7',
    '#2FC25B',
    '#FACC14',
    '#E6965C',
    '#223273',
    '#7564CC',
    '#8543E0',
    '#5C8EE6',
    '#13C2C2',
    '#5CA3E6',
    '#3436C7',
    '#B381E6',
    '#F04864',
    '#D598D9',
  ],
  lg: [
    '#1890FF',
    '#66B5FF',
    '#41D9C7',
    '#2FC25B',
    '#6EDB8F',
    '#9AE65C',
    '#FACC14',
    '#E6965C',
    '#57AD71',
    '#223273',
    '#738AE6',
    '#7564CC',
    '#8543E0',
    '#A877ED',
    '#5C8EE6',
    '#13C2C2',
    '#70E0E0',
    '#5CA3E6',
    '#3436C7',
    '#8082FF',
    '#DD81E6',
    '#F04864',
    '#FA7D92',
    '#D598D9',
  ],
  pieSm: [
    '#1890FF',
    '#13C2C2',
    '#2FC25B',
    '#FACC14',
    '#F04864',
    '#8543E0',
    '#3436C7',
    '#223273',
  ],
  pieLg: [
    '#1890FF',
    '#73C9E6',
    '#13C2C2',
    '#6CD9B3',
    '#2FC25B',
    '#9DD96C',
    '#FACC14',
    '#E6965C',
    '#F04864',
    '#D66BCA',
    '#8543E0',
    '#8E77ED',
    '#3436C7',
    '#737EE6',
    '#223273',
    '#7EA2E6',
  ],
  gradient: {
    default: {
      fromColor: '#91d5ff',
      midColor: '#1890ff',
      toColor: '#003a8c',
    },
    score: {
      fromColor: '#f5222d',
      midColor: '#faad14',
      toColor: '#52c41a',
    },
  },
};
