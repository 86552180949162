import moment from 'moment-timezone';

import { TIME_INTERVALS } from 'constant';
import { formatDate, formatExtractDate, getPrimarySchemaField } from 'modules';

export const getStartAndEndDate = (date, timeInterval) => {
  if (timeInterval === 'week') {
    if (moment(date).days() === 0) {
      return {
        from: formatDate(date, 'moment')
          .subtract(1, 'week')
          .add(1, 'd')
          .format(),
        to: formatDate(date),
      };
    }
    return {
      from: formatDate(date, 'moment')
        .startOf(timeInterval)
        .add(1, 'd')
        .format(),
      to: formatDate(date, 'moment').endOf(timeInterval).add(1, 'd').format(),
    };
  }

  if (TIME_INTERVALS.extract.find((item) => item.value === timeInterval)) {
    return {
      from: formatExtractDate(date, timeInterval),
      to: formatExtractDate(date, timeInterval),
    };
  }

  return {
    from: formatDate(date, 'moment').startOf(timeInterval).format(),
    to: formatDate(date, 'moment').endOf(timeInterval).format(),
  };
};

export const getDatsetSchemaField = (dataset) => {
  const schemaFields = {};
  if (dataset) {
    const currentSchemaFields = dataset.schema.fields;

    const primarySchemaField = getPrimarySchemaField(currentSchemaFields);
    if (primarySchemaField) {
      schemaFields.primaryFieldName = primarySchemaField.name;
    }

    [...currentSchemaFields].reverse().forEach((field) => {
      if (field.subtype === 'source') {
        schemaFields.sourceFieldName = field.name;
      } else if (field.subtype === 'title') {
        schemaFields.titleFieldName = field.name;
      } else if (field.subtype === 'verbatim') {
        schemaFields.verbatimFieldName = field.name;
      } else if (field.name === 'otso_doc_publish_date') {
        schemaFields.dateFieldName = field.name;
      } else if (field.subtype === 'author') {
        schemaFields.authorFieldName = field.name;
      }
    });
  }
  return schemaFields;
};

export const legacyEntityFields = {
  'Entity Primary Emotion': 'entity_primary_emotion',
  'Entity Text': 'entity_lemma', // entity text is not filterable on explore page
  'Entity Lemma': 'entity_lemma',
  'Entity Type': 'entity_type',
  'Entity Sentiment Score': 'entity_sentiment_score',
  'Entity Sentiment Label': 'entity_sentiment_label',
};
