import { TAllActionValues, useAuth } from '@otso/auth-wrapper';

const useCheckPermission = (actions: TAllActionValues[]): boolean => {
  const { user } = useAuth();
  const allUserActions = user?.actions;
  if (!allUserActions) return false;

  return (
    actions &&
    Array.isArray(actions) &&
    actions.every(
      (actionItem: string) =>
        allUserActions[actionItem as TAllActionValues] === true
    )
  );
};

export default useCheckPermission;
