/** @jsxImportSource @emotion/react */
import moment from 'moment-timezone';
import { Select, Button } from 'antd';

// Only allow AUS time zones for now, because if you look up word 'trash' in dictionary, you will see a photo of Trump, and if you look closer, you can see Trump holds a photo of Bizcharts
// https://github.com/alibaba/BizCharts/issues/914
const tzList = moment.tz
  .names()
  .filter((tzName) => tzName.includes('Australia'));

// Guess current timezone
const currentTz = moment.tz.guess();

type Props = { value?: string; onChange?: (value: string) => void };

const TimezoneSelect: React.FC<Props> = ({ value, onChange }) => {
  return (
    <>
      <Select
        showSearch
        placeholder="Australia/Sydney"
        value={value}
        onChange={(val) => onChange?.(val)}
      >
        {tzList.map((tzName) => (
          <Select.Option key={tzName} value={tzName}>
            {tzName}
          </Select.Option>
        ))}
      </Select>
      {currentTz && currentTz.includes('Australia') && (
        <Button
          type="link"
          onClick={() => onChange?.(currentTz)}
          css={{ paddingLeft: 0 }}
        >
          Use current time zone
        </Button>
      )}
    </>
  );
};

export default TimezoneSelect;
