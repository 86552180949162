/** @jsxImportSource @emotion/react */
import { useRef, useState } from 'react';
import { Button, Popover, Tooltip, Badge, Space } from 'antd';
import {
  FilterOutlined,
  BgColorsOutlined,
  StockOutlined,
  DatabaseOutlined,
  BlockOutlined,
} from '@ant-design/icons';

import { preset } from 'styles';
import FilterBar from '@components/FilterBar';
import ChartDataTable from '../../Chart/components/ChartDataTable';
import MetricsSelect from './MetricsSelect';
import ColorPlateSelect from './ColorPlateSelect';
import GeoDimensionsSelect from './GeoDimensionsSelect';

const POPOVER_MAX_WIDTH = window.innerWidth - 240;

const LocationButtonGroups = ({
  isQueryLoading,
  data,
  queryObj,
  color,
  autoZoom,
  onQueryObjUpdate,
  onDimensionUpdate,
  onColorUpdate,
}) => {
  const {
    dataset: datasetIds,
    metrics,
    dimensions,
    filters,
    timeFilter,
  } = queryObj;

  const [hasGeoDimensionSelected, setHasGeoDimensionSelected] = useState(false);

  const filterBtnRef = useRef();
  const metricsBtnRef = useRef();
  const dimensionsBtnRef = useRef();
  const colorBtnRef = useRef();

  const onFilterBarApply = (newFilters) => {
    onQueryObjUpdate({
      ...queryObj,
      dataset: newFilters.datasetIds,
      filters: newFilters.filters,
      timeFilter: newFilters.timeFilter,
    });
    if (filterBtnRef.current) {
      filterBtnRef.current.click();
    }
  };

  const onMetricsChange = (newMetrics) => {
    onQueryObjUpdate({
      ...queryObj,
      metrics: newMetrics,
    });
    if (metricsBtnRef.current) {
      metricsBtnRef.current.click();
    }
  };

  const onDimensionChange = (newDimensionField) => {
    onDimensionUpdate(newDimensionField);
    setHasGeoDimensionSelected(!!newDimensionField);
    if (dimensionsBtnRef.current) {
      dimensionsBtnRef.current.click();
    }
  };

  const onColorPlateSelect = (newColor) => {
    onColorUpdate(newColor);
    if (colorBtnRef.current) {
      colorBtnRef.current.click();
    }
  };

  return (
    <Space
      direction="vertical"
      size="middle"
      css={{
        button: {
          boxShadow: 'rgb(0 0 0 / 30%) 0px 1px 4px -1px',
        },
      }}
    >
      <Popover
        placement="rightTop"
        trigger="click"
        content={
          <FilterBar
            reInitWhenFiltersChanged
            initialDatasetIds={datasetIds}
            initialFilters={filters}
            initialTimeFilter={timeFilter}
            isSearchLoading={isQueryLoading}
            onApply={onFilterBarApply}
            css={{
              margin: `-${12}px -${preset.spacing(2)}px`,
              maxWidth: POPOVER_MAX_WIDTH,
            }}
          />
        }
      >
        <Tooltip title="Filters">
          <Badge count={filters.length}>
            <Button
              ref={filterBtnRef}
              type="primary"
              size="large"
              shape="circle"
              icon={<FilterOutlined />}
            />
          </Badge>
        </Tooltip>
      </Popover>

      <Popover
        placement="rightTop"
        trigger="click"
        content={
          <MetricsSelect
            datasetIds={datasetIds}
            metrics={metrics}
            onMetricsChange={onMetricsChange}
          />
        }
      >
        <Tooltip title="Metrics">
          <Button
            ref={metricsBtnRef}
            type="primary"
            size="large"
            shape="circle"
            icon={<StockOutlined />}
          />
        </Tooltip>
      </Popover>

      <Popover
        placement="rightTop"
        trigger="click"
        content={
          <GeoDimensionsSelect
            dimensions={dimensions}
            autoZoom={autoZoom}
            onDimensionChange={onDimensionChange}
          />
        }
      >
        <Tooltip title="Geo Dimensions">
          <Badge dot={hasGeoDimensionSelected}>
            <Button
              ref={dimensionsBtnRef}
              type="primary"
              size="large"
              shape="circle"
              icon={<BlockOutlined />}
            />
          </Badge>
        </Tooltip>
      </Popover>

      <Popover
        placement="rightTop"
        trigger="click"
        content={
          <ColorPlateSelect color={color} onSelect={onColorPlateSelect} />
        }
      >
        <Tooltip title="Colour">
          <Button
            ref={colorBtnRef}
            type="primary"
            size="large"
            shape="circle"
            icon={<BgColorsOutlined />}
          />
        </Tooltip>
      </Popover>

      <Popover
        placement="rightTop"
        trigger="click"
        content={
          <div css={{ width: POPOVER_MAX_WIDTH / 2 }}>
            <ChartDataTable
              loading={isQueryLoading}
              data={data}
              metrics={metrics}
              dimensions={dimensions}
              config={{ rowsPerPage: 10 }}
            />
          </div>
        }
      >
        <Tooltip title="Data">
          <Button
            type="primary"
            size="large"
            shape="circle"
            icon={<DatabaseOutlined />}
          />
        </Tooltip>
      </Popover>
    </Space>
  );
};

export default LocationButtonGroups;
