const Reddit = (props) => {
  const { document } = props;

  return (
    <blockquote className="reddit-card" data-card-created="1578454461">
      <a href={document.document_url}>{document.title}</a> from{' '}
      <a href={`http://www.reddit.com/r/${document.subreddit}`}>
        {`r/${document.subreddit}`}
      </a>
    </blockquote>
  );
};

export default Reddit;
