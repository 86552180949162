/** @jsxImportSource @emotion/react */
import { Geom } from 'bizcharts';

const CustomGeom = ({
    metricKey,
    display,
    color,
    x,
    position,
    isDualBarChart = false,
    barSize,
    cursor,
}) => {
    return (
        <>
            {display === 'area' && (
                <Geom
                    type="line"
                    color={color}
                    position={`${x}*${metricKey}`}
                    size={2}
                />
            )}
            <Geom
                type={display === 'bar' ? 'interval' : display}
                position={`${x}*${metricKey}`}
                color={color}
                {...isDualBarChart ? {
                    shape: position,
                    size: barSize,
                } : {}}
                style={{ cursor }}
            />
            {display !== 'bar' && (
                <Geom
                    type="point"
                    position={`${x}*${metricKey}`}
                    color={color}
                    size={3}
                    shape="circle"
                    style={{
                        cursor,
                        ...display === 'area' ? {} : {
                            stroke: '#fff',
                            lineWidth: 1,
                        },
                    }}
                />
            )}
        </>
    );
};

export default CustomGeom;
