/** @jsxImportSource @emotion/react */
import React from 'react';

import Empty from '@components/Empty';   

class ChartRenderError extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            errMsg: '',
        };
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            errMsg: error.message,
        };
    }

    render() {
        const { children, height } = this.props;
        const { hasError, errMsg } = this.state;

        if (hasError) {
            return (
                <div css={{ height }}>
                    <Empty
                        fullHeight
                        description={`Chart Error${errMsg ? `: ${errMsg}` : ''}`}
                    />
                </div>
            );
        }

        return children;
    }
}

export default ChartRenderError;
