/** @jsxImportSource @emotion/react */
import { preset } from 'styles';
import isNumber from '@modules/lib/isNumber';
import deepGet from '@modules/deepGet';

const ChoroplethMapLegend = ({ color, config }) => {
  if (!config.zRange || !deepGet(config, ['legend', 'display'])) return null;

  const { max, mid, min } = config.zRange;
  const displayMid = isNumber(mid) && mid !== max ? mid : null;
  const displayMin = isNumber(min) && min !== max ? min : 0;

  return (
    <div
      css={{
        position: 'absolute',
        bottom: 32,
        left: preset.spacing(2),
        height: '20%',
        backgroundColor: '#fff',
        padding: preset.spacing(1),
        paddingTop: 0,
        borderRadius: 2,
        boxShadow: 'rgb(0 0 0 / 30%) 0px 1px 4px -1px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        css={{
          height: 32,
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.9em',
        }}
      >
        <b>{config.colorBy}</b>
      </div>
      <div css={{ flex: 1, display: 'flex' }}>
        <div
          css={{
            width: preset.spacing(5),
            background: `linear-gradient(
                        to top,
                        ${color.fromColor} 0%,
                        ${color.midColor} 50%,
                        ${color.toColor} 100%
                    ) !important`,
          }}
        />

        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginLeft: preset.spacing(1),
            fontSize: '0.8em',
          }}
        >
          <span>{max}</span>
          <span>{displayMid}</span>
          <span>{displayMin}</span>
        </div>
      </div>
    </div>
  );
};

export default ChoroplethMapLegend;
