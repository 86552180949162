/** @jsxImportSource @emotion/react */
import { useMemo, useState } from 'react';

import { Collapse, Form, Row, Col, Switch, Input, Select, Button } from 'antd';
import { ArrowRightOutlined, MinusOutlined } from '@ant-design/icons';

import { preset } from 'styles';
import deepGet from '@modules/deepGet';
import { removeObjectKey } from '@modules/items';

const { Panel } = Collapse;
const FormItem = Form.Item;
const { Option, OptGroup } = Select;

const LabelPanel = ({ chart, dispatch, options = {}, ...props }) => {
  const {
    data,
    queryObj: { dimensions = [], compare },
    defaultConfig = {},
  } = chart;

  const { canDisplayLabel = false } = options;

  const [selectedLabel, setSelectedLabel] = useState({});
  const [newLabelAlias, setNewLabelAlias] = useState();

  const labelConfig = deepGet(defaultConfig, ['label']) || {};
  const nullDimensionDisplayConfig = deepGet(defaultConfig, [
    'label',
    'nullDimensionDisplay',
  ]);
  const labelAlias = deepGet(defaultConfig, ['label', 'alias']) || {};
  const formattedData =
    compare && Array.isArray(data[0]) && Array.isArray(data[1])
      ? data[0]
      : data;

  const nullDimensionDisplay = useMemo(() => {
    if (!nullDimensionDisplayConfig) {
      return dimensions.reduce(
        (prev, dimension) => ({
          ...prev,
          [dimension.as || dimension.attribute]: 'No Data',
        }),
        {}
      );
    }
    if (Array.isArray(nullDimensionDisplayConfig)) {
      return dimensions.reduce(
        (prev, dimension, index) => ({
          ...prev,
          [dimension.as || dimension.attribute]:
            nullDimensionDisplayConfig[index] || 'No Data',
        }),
        {}
      );
    }
    return nullDimensionDisplayConfig;
  }, [dimensions, nullDimensionDisplayConfig]);

  const dimensionLabelList = useMemo(() => {
    if (dimensions.length === 0) return {};
    return dimensions.reduce((prev, dimensionObj) => {
      // Date dimension label is not customisable
      if (dimensionObj.functionValue) {
        return prev;
      }
      const dimension = dimensionObj.as || dimensionObj.attribute;
      const dimensionLabels = [
        ...new Set(formattedData.map((row) => `${row[dimension]}`)),
      ];
      const filteredDimensionLabels = dimensionLabels.filter(
        (label) => label !== 'No Data' && label !== 'null'
      );
      return {
        ...prev,
        [dimension]: filteredDimensionLabels,
      };
    }, {});
  }, [formattedData, dimensions]);

  const onLabelAliasAdd = () => {
    dispatch({
      type: 'SET_CONFIG',
      value: {
        ...labelAlias,
        [selectedLabel.dimension]: {
          ...(labelAlias[selectedLabel.dimension] || {}),
          [selectedLabel.value]: newLabelAlias,
        },
      },
      key: 'label',
      childKey: 'alias',
    });
    setSelectedLabel({});
    setNewLabelAlias();
  };

  const onLabelAliasDelete = (dimensionKey, aliasKey) => {
    dispatch({
      type: 'SET_CONFIG',
      value: {
        ...labelAlias,
        [dimensionKey]: removeObjectKey(labelAlias[dimensionKey], aliasKey),
      },
      key: 'label',
      childKey: 'alias',
    });
  };

  const onLabelAliasUpdate = (newAlias, dimensionKey, aliasKey) => {
    dispatch({
      type: 'SET_CONFIG',
      value: {
        ...labelAlias,
        [dimensionKey]: {
          ...(labelAlias[dimensionKey] || {}),
          [aliasKey]: newAlias,
        },
      },
      key: 'label',
      childKey: 'alias',
    });
  };

  return (
    <Panel {...props} key="label" header="Label">
      <Row gutter={preset.spacing(3)}>
        {canDisplayLabel && (
          <Col span={12}>
            <FormItem label="Display data label">
              <Switch
                checked={!!labelConfig.display}
                onChange={(checked) =>
                  dispatch({
                    type: 'SET_CONFIG',
                    value: checked,
                    key: 'label',
                    childKey: 'display',
                  })
                }
              />
            </FormItem>
          </Col>
        )}
        {Array.isArray(dimensions) &&
          dimensions.map((dimension) => {
            const dimensionKey = dimension.as || dimension.attribute;
            return (
              <Col key={dimensionKey} span={12}>
                <FormItem
                  label={`Display null '${dimensionKey}' as`}
                  help="Press Enter to Apply"
                >
                  <Input
                    placeholder={
                      nullDimensionDisplay[dimensionKey] || 'No Data'
                    }
                    defaultValue={nullDimensionDisplay[dimensionKey]}
                    onPressEnter={(e) =>
                      dispatch({
                        type: 'SET_CONFIG',
                        value: {
                          ...nullDimensionDisplay,
                          [dimensionKey]: e.target.value,
                        },
                        key: 'label',
                        childKey: 'nullDimensionDisplay',
                      })
                    }
                  />
                </FormItem>
              </Col>
            );
          })}
      </Row>

      {Object.keys(dimensionLabelList).length > 0 && (
        <Row gutter={preset.spacing(3)}>
          <Col span={24}>
            <FormItem label="Label alias">
              <Row gutter={preset.spacing(3)}>
                <Col span={8}>
                  <Select
                    showSearch
                    placeholder="Select label"
                    optionFilterProp="children"
                    value={
                      selectedLabel.value &&
                      `${selectedLabel.dimension}_${selectedLabel.value}`
                    }
                    onChange={(val, option) =>
                      setSelectedLabel({
                        dimension: option.dimension,
                        value: option.label,
                      })
                    }
                  >
                    {Object.keys(dimensionLabelList).map((dimensionKey) => (
                      <OptGroup key={dimensionKey} label={dimensionKey}>
                        {dimensionLabelList[dimensionKey].map((label) => (
                          <Option
                            key={`${dimensionKey}_${label}`}
                            disabled={
                              !!deepGet(labelAlias, [dimensionKey, label])
                            }
                            dimension={dimensionKey}
                            label={label}
                            value={`${dimensionKey}_${label}`}
                          >
                            {label}
                          </Option>
                        ))}
                      </OptGroup>
                    ))}
                  </Select>
                </Col>
                <Col span={8}>
                  <Input
                    placeholder="Enter alias"
                    value={newLabelAlias}
                    onChange={(e) => setNewLabelAlias(e.target.value)}
                  />
                </Col>
                <Col span={8}>
                  <Button
                    type="primary"
                    onClick={onLabelAliasAdd}
                    disabled={!selectedLabel.value || !labelAlias}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            </FormItem>

            {Object.keys(labelAlias).map((dimensionKey) =>
              Object.keys(labelAlias[dimensionKey] || {}).map((aliasKey) => (
                <div
                  key={`${dimensionKey}_${aliasKey}`}
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: preset.spacing(2),
                  }}
                >
                  <span css={{ fontWeight: '500' }}>{aliasKey}</span>
                  <ArrowRightOutlined
                    css={{
                      marginLeft: preset.spacing(2),
                      marginRight: preset.spacing(2),
                    }}
                  />
                  <Input
                    placeholder={aliasKey}
                    defaultValue={labelAlias[dimensionKey][aliasKey]}
                    onPressEnter={(e) =>
                      onLabelAliasUpdate(e.target.value, dimensionKey, aliasKey)
                    }
                    css={{ width: `${100 / 3}%` }}
                  />
                  <Button
                    type="danger"
                    shape="circle"
                    size="small"
                    icon={<MinusOutlined />}
                    onClick={() => onLabelAliasDelete(dimensionKey, aliasKey)}
                    css={{ marginLeft: preset.spacing(3) }}
                  />
                </div>
              ))
            )}
          </Col>
        </Row>
      )}
    </Panel>
  );
};

export default LabelPanel;
