/** @jsxImportSource @emotion/react */

import { Collapse, Row, Col, Form, InputNumber, Input } from 'antd';

import { preset } from 'styles';

import isNumber from '@modules/lib/isNumber';
import ColorPicker from '@components/ColorPicker';

import StyledCollapse from '../../StyledCollapse';
import SingleNumberPanel from './SingleNumberPanel';
import ColorPanel from './ColorPanel';

const { Panel } = Collapse;
const FormItem = Form.Item;
const ColorPanelBody = ColorPanel.Body;

const GaugeConfig = ({ chart, dispatch }) => {
  const { defaultConfig = {}, defaultColor = {} } = chart;
  const { max, min, threshold = {}, label = {} } = defaultConfig;

  const rangeMin = isNumber(min) ? min : -Infinity;
  const rangeMax = isNumber(max) ? max : Infinity;

  return (
    <StyledCollapse>
      <SingleNumberPanel chart={chart} dispatch={dispatch} />

      <Panel key="gaugeLabel" header="Gauge Label">
        <Row gutter={preset.spacing(3)}>
          <Col span={12}>
            <FormItem label="Min label" help="Press Enter to Apply">
              <Input
                placeholder={`${isNumber(min) ? min : -1}`}
                defaultValue={label.min}
                onPressEnter={(e) =>
                  dispatch({
                    type: 'SET_CONFIG',
                    value: e.target.value,
                    key: 'label',
                    childKey: 'min',
                  })
                }
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Max label" help="Press Enter to Apply">
              <Input
                placeholder={`${isNumber(max) ? max : -1}`}
                defaultValue={label.max}
                onPressEnter={(e) =>
                  dispatch({
                    type: 'SET_CONFIG',
                    value: e.target.value,
                    key: 'label',
                    childKey: 'max',
                  })
                }
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Label offset">
              <InputNumber
                placeholder="20"
                value={label.offset}
                onChange={(value) =>
                  dispatch({
                    type: 'SET_CONFIG',
                    value,
                    key: 'label',
                    childKey: 'offset',
                  })
                }
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Label font size">
              <InputNumber
                placeholder="14"
                value={label.fontSize}
                onChange={(value) =>
                  dispatch({
                    type: 'SET_CONFIG',
                    value,
                    key: 'label',
                    childKey: 'fontSize',
                  })
                }
              />
            </FormItem>
          </Col>
        </Row>
      </Panel>

      <Panel key="gaugeRange" header="Gauge Range">
        <Row gutter={preset.spacing(3)}>
          <Col span={12}>
            <FormItem label="Min" required>
              <InputNumber
                max={rangeMax}
                value={min}
                onChange={(value) =>
                  dispatch({
                    type: 'SET_CONFIG',
                    value,
                    key: 'min',
                  })
                }
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Max" required>
              <InputNumber
                min={rangeMin}
                value={max}
                onChange={(value) =>
                  dispatch({
                    type: 'SET_CONFIG',
                    value,
                    key: 'max',
                  })
                }
              />
            </FormItem>
          </Col>
        </Row>
      </Panel>

      <Panel key="chartColor" header="Chart Colour">
        <ColorPanelBody capitaliseLabel chart={chart} dispatch={dispatch} />

        <Row gutter={preset.spacing(3)}>
          <Col span={8}>
            <FormItem label="Range 1 Colour" css={styles.rangeFormItem}>
              <ColorPicker
                color={defaultColor.negative}
                onColorChange={(color) =>
                  dispatch({
                    type: 'SET_COLOR',
                    value: color.hex,
                    key: 'negative',
                  })
                }
              />
              <InputNumber disabled value={min} />
              <span>~</span>
              <InputNumber
                min={rangeMin}
                max={
                  isNumber(threshold.positive) ? threshold.positive : rangeMax
                }
                value={threshold.negative}
                onChange={(value) =>
                  dispatch({
                    type: 'SET_CONFIG',
                    value,
                    key: 'threshold',
                    childKey: 'negative',
                  })
                }
              />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="Range 2 Colour" css={styles.rangeFormItem}>
              <ColorPicker
                color={defaultColor.neutral}
                onColorChange={(color) =>
                  dispatch({
                    type: 'SET_COLOR',
                    value: color.hex,
                    key: 'neutral',
                  })
                }
              />
              <InputNumber
                min={rangeMin}
                max={
                  isNumber(threshold.positive) ? threshold.positive : rangeMax
                }
                value={threshold.negative}
                onChange={(value) =>
                  dispatch({
                    type: 'SET_CONFIG',
                    value,
                    key: 'threshold',
                    childKey: 'negative',
                  })
                }
              />
              <span>~</span>
              <InputNumber
                min={
                  isNumber(threshold.negative) ? threshold.negative : rangeMin
                }
                max={rangeMax}
                value={threshold.positive}
                onChange={(value) =>
                  dispatch({
                    type: 'SET_CONFIG',
                    value,
                    key: 'threshold',
                    childKey: 'positive',
                  })
                }
              />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="Range 3 Colour" css={styles.rangeFormItem}>
              <ColorPicker
                color={defaultColor.positive}
                onColorChange={(color) =>
                  dispatch({
                    type: 'SET_COLOR',
                    value: color.hex,
                    key: 'positive',
                  })
                }
              />
              <InputNumber
                min={
                  isNumber(threshold.negative) ? threshold.negative : rangeMin
                }
                max={rangeMax}
                value={threshold.positive}
                onChange={(value) =>
                  dispatch({
                    type: 'SET_CONFIG',
                    value,
                    key: 'threshold',
                    childKey: 'positive',
                  })
                }
              />
              <span>~</span>
              <InputNumber disabled value={max} />
            </FormItem>
          </Col>
        </Row>
      </Panel>
    </StyledCollapse>
  );
};

const styles = {
  rangeFormItem: {
    '.ant-form-item-control-input-content': {
      display: 'flex',
      alignItems: 'center',
      '.ant-input-number': {
        marginLeft: 12,
        marginRight: 12,
      },
    },
  },
};

export default GaugeConfig;
