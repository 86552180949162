import React from 'react';
import { Space } from 'antd';
import { ExploreCard } from 'components';
import { preset } from 'styles';
import DocumentAttachment, { Attachment } from './DocumentAttachment';

type Props = {
  attachments: Attachment[];
};

const DocumentAttachmentInfo: React.FC<Props> = ({ attachments }) => {
  return (
    <ExploreCard
      title="Document Attachments"
      style={{ marginTop: preset.spacing(2) }}
    >
      <Space wrap>
        {attachments.map((attachment) => (
          <DocumentAttachment
            key={attachment.id}
            downloadable
            attachment={attachment}
          />
        ))}
      </Space>
    </ExploreCard>
  );
};

export default DocumentAttachmentInfo;
