import moment from 'moment-timezone';

import isValidDate from './isValidDate';

type TimeInterval =
  | 'year'
  | 'month'
  | 'week'
  | 'day'
  | 'hour'
  | 'minute'
  | 'second'
  | 'full'
  | 'moment';

type ExtractTimeInterval =
  | 'qoy'
  | 'moy'
  | 'woy'
  | 'doy'
  | 'dom'
  | 'dow'
  | 'hod';

type FormatLength = 'default' | 'short';

export const displayMoment = (
  momentObj: moment.Moment,
  timeInterval?: TimeInterval,
  delimiter = '-',
  length: FormatLength = 'default'
) => {
  switch (timeInterval) {
    case 'year':
      return momentObj.format('YYYY');
    case 'month':
      return momentObj.format(
        length === 'short' ? `MM${delimiter}YY` : `MM${delimiter}YYYY`
      );
    case 'week':
    case 'day':
      return momentObj.format(
        length === 'short'
          ? `DD${delimiter}MM${delimiter}YY`
          : `DD${delimiter}MM${delimiter}YYYY`
      );
    case 'hour':
      return momentObj.format(
        length === 'short'
          ? `DD${delimiter}MM hA`
          : `DD${delimiter}MM${delimiter}YYYY hA`
      );
    case 'minute':
      return momentObj.format(
        length === 'short' ? 'HH:mm`' : `DD${delimiter}MM${delimiter}YYYY HH:mm`
      );
    case 'second':
      return momentObj.format(
        length === 'short'
          ? 'mm:ss'
          : `DD${delimiter}MM${delimiter}YYYY HH:mm:ss`
      );
    case 'full':
      return momentObj.format(`DD${delimiter}MM${delimiter}YYYY HH:mm:ss`);
    case 'moment':
      return momentObj;
    default:
      return momentObj ? momentObj.format('YYYY-MM-DDTHH:mm:ss') : '';
  }
};

export const formatDate = (
  date: any,
  timeInterval?: TimeInterval,
  delimiter = '-',
  length: FormatLength = 'default'
) => {
  if (isValidDate(date) || date === 'now') {
    // parseZone will ignore the timezone offset in the date
    const momentDate =
      date === 'now' ? moment.parseZone() : moment.parseZone(date);
    return displayMoment(momentDate, timeInterval, delimiter, length);
  }
  return date;
};

export const formatDateInTZ = (
  date: any,
  timeInterval?: TimeInterval,
  delimiter = '-',
  length: FormatLength = 'default'
) => {
  if (isValidDate(date) || date === 'now') {
    const userTimeZone =
      sessionStorage.getItem('user_timezone') || 'Australia/Sydney';

    const momentDate =
      date === 'now'
        ? moment().tz(userTimeZone)
        : moment(date).tz(userTimeZone);
    return displayMoment(momentDate, timeInterval, delimiter, length);
  }
  return date;
};

export const formatExtractDate = (
  extractDate: number,
  timeInterval: ExtractTimeInterval
) => {
  const baseDate = moment.parseZone('2000-01-01');
  switch (timeInterval) {
    case 'qoy':
      return baseDate.add(extractDate - 1, 'Q').format();
    case 'moy':
      return baseDate.add(extractDate - 1, 'M').format();
    case 'woy':
      return baseDate.add(extractDate, 'w').format();
    case 'doy':
    case 'dom':
      return baseDate.add(extractDate - 1, 'd').format();
    case 'dow':
      return baseDate.startOf('week').add(extractDate, 'd').format();
    case 'hod':
      return baseDate.add(extractDate, 'h').format();
    default:
      return baseDate.format();
  }
};
