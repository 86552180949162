/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import { Collapse } from 'antd';

import { trackEvent } from 'analytics';
import { preset } from 'styles';
import { Dataset, Filter, TimeFilter, ExploreDocumentFields } from 'types';
import { getPrimarySchemaField } from 'modules';
import DocumentCollapseHeader from './DocumentCollapseHeader';
import DocumentExpanded from './DocumentExpanded';
import AudioDocCollapse from './AudioDocCollapse';

type Props = {
  document: Record<string, any>;
  dataset: Dataset;
  expandedDocumentId?: string;
  hasEnrichmentCorrection: boolean;
  appliedFilters?: { filters?: Filter[]; timeFilter?: TimeFilter };
  copyDocumentLink: (docId: string) => void;
  deleteDocument: () => void;
};

const DocumentCollapse: React.FC<Props> = ({
  document,
  dataset,
  expandedDocumentId,
  hasEnrichmentCorrection = false,
  appliedFilters,
  copyDocumentLink,
  deleteDocument,
}) => {
  const { schema: datasetSchema, integrationMeta } = dataset || {};

  const { audio: isAudioDataset } = integrationMeta || {};

  const isAudioDoc = isAudioDataset && !document.parent_id;

  const keyFields: ExploreDocumentFields = useMemo(() => {
    const filteredSecondaryFields = [
      'document_hit_sentence',
      'document_sentiment',
    ];

    const defaultDatasetSchemaFields = {
      secondary: [],
      isDialogue: false,
    } as unknown as ExploreDocumentFields;

    const primarySchemaField = getPrimarySchemaField(datasetSchema.fields);
    if (primarySchemaField) {
      defaultDatasetSchemaFields.primary = primarySchemaField.name;
    }

    [...datasetSchema.fields].reverse().forEach((field) => {
      if (field.name === 'source_country_code') {
        defaultDatasetSchemaFields.country = field.name;
      } else if (field.name === 'document_image_link') {
        defaultDatasetSchemaFields.image = field.name;
      } else if (field.name === 'parent_id') {
        defaultDatasetSchemaFields.hasDocumentThread = true;
      } else if (
        field.name === 'rating' ||
        (field.displayName && field.displayName.toLowerCase() === 'rating')
      ) {
        defaultDatasetSchemaFields.rating = field.name;
      } else if (field.subtype) {
        defaultDatasetSchemaFields[field.subtype] = field.name;
        if (field.subtype === 'verbatim' && field.isDialogue) {
          defaultDatasetSchemaFields.isDialogue = true;
        }
      } else if (
        field.category &&
        ['dimension', 'metric'].includes(field.category) &&
        !filteredSecondaryFields.includes(field.name) &&
        typeof field.type !== 'object'
      ) {
        defaultDatasetSchemaFields.secondary.push(field.name);
      }
    });

    return defaultDatasetSchemaFields;
  }, [datasetSchema.fields]);

  return (
    <div
      key={`explore_${document.id}`}
      id={`explore-card-${document.id}`}
      css={{ marginBottom: preset.spacing(3) }}
    >
      {isAudioDoc ? (
        <AudioDocCollapse
          document={document}
          dataset={dataset}
          appliedFilters={appliedFilters}
          hasEnrichmentCorrection={hasEnrichmentCorrection}
          expandedDocumentId={expandedDocumentId}
        />
      ) : (
        <Collapse
          defaultActiveKey={expandedDocumentId}
          onChange={(expanded) => {
            trackEvent('Toggle Document Collapse', {
              toggleType: expanded.length > 0 ? 'Show' : 'Hide',
              documentId: document.id,
            });
          }}
        >
          <Collapse.Panel
            key={document.id}
            showArrow={false}
            header={
              <DocumentCollapseHeader
                document={document}
                dataset={dataset}
                keyFields={keyFields}
                hasEnrichmentCorrection={hasEnrichmentCorrection}
                actions={{
                  copyDocumentLink,
                  deleteDocument,
                }}
              />
            }
          >
            <DocumentExpanded
              document={document}
              dataset={dataset}
              datasetSchemaFields={keyFields}
              appliedFilters={appliedFilters}
              renderChildDocumentCollapse={(
                childDoc: Record<string, any>,
                hasChildDocEnrichmentCorrection: boolean
              ) => (
                <DocumentCollapse
                  key={childDoc.id}
                  document={childDoc}
                  dataset={dataset}
                  hasEnrichmentCorrection={hasChildDocEnrichmentCorrection}
                  copyDocumentLink={copyDocumentLink}
                  deleteDocument={deleteDocument}
                />
              )}
            />
          </Collapse.Panel>
        </Collapse>
      )}
    </div>
  );
};

export default DocumentCollapse;
