import { AUS_GEO_JSON } from 'constant/geo';

const getGeoJsonConfigByDimension = (dimensionKey) => {
  switch (dimensionKey.toLowerCase()) {
    case 'lga':
      return AUS_GEO_JSON.queensland.lga;
    case 'suburb':
      return AUS_GEO_JSON.queensland.suburb;
    case 'council division':
      // TODO: work out the better council agnostic solution, since this is tied to LCC
      return AUS_GEO_JSON.queensland.lga.councils.lcc;
    default:
      return AUS_GEO_JSON.state;
  }
};

export default getGeoJsonConfigByDimension;
