/** @jsxImportSource @emotion/react */
import { PureComponent } from 'react';
import webvtt from 'node-webvtt';
import PropTypes from 'prop-types';

const parsevtt = vttinput => {
    const parsedVTT = webvtt.parse(vttinput);
    const { cues } = parsedVTT;
    return cues;
};

class Webvtt extends PureComponent {
    state = {
        cueDisplayLimit: this.props.cueDisplayLimit,
        cues: parsevtt(this.props.content),
    };

    render() {
        const { cues, cueDisplayLimit } = this.state;

        return (
            <div>
                {cues.slice(0, cueDisplayLimit).map((cue, index) => (
                    <div
                        key={`cue_${index + 1}`}
                        css={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            padding: '16px 0',
                            fontSize: 15,
                            borderBottom: 'solid 1px #d9d9d9',
                            '&:last-of-type': {
                                borderBottom: 'none',
                            },
                        }}
                    >
                        <span
                            css={{
                                width: 72,
                                marginRight: 24,
                                textTransform: 'capitalize',
                                color: '#bfbfbf',
                            }}
                        >
                            {cue.identifier}
                        </span>
                        <span>
                            {cue.text}
                        </span>
                    </div>
                ))}
                {cues && cues.length >= cueDisplayLimit
                    ? (
                        <div css={{ paddingTop: 8, textAlign: 'center' }}>
                            <a
                                href="#more"
                                onClick={e => {
                                    e.preventDefault();
                                    this.setState({ cueDisplayLimit: cueDisplayLimit + 4 });
                                }}
                            >
                                View More
                            </a>
                        </div>
                    ) : null}
            </div>
        );
    }
}

Webvtt.propTypes = {
    cueDisplayLimit: PropTypes.number,
    content: PropTypes.string,
};

Webvtt.defaultProps = {
    cueDisplayLimit: 4,
    content: null,
};

export default Webvtt;
