/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { auth } from 'firebase-export';
import Spinner from '../../components/Spinner';

const microSoftProvider = new auth.OAuthProvider('microsoft.com');

const Microsoft = () => {
  const history = useHistory();

  useEffect(() => {
    const linkToMicrosoftAccount = async () => {
      try {
        const { currentUser } = await auth();
        microSoftProvider.setCustomParameters({
          tenant: 'lgsherlock.onmicrosoft.com',
          login_hint: currentUser.email,
        });
        await currentUser.linkWithRedirect(microSoftProvider);
        history.push('/');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        history.push('/');
      }
    };

    linkToMicrosoftAccount();
  }, [history]);

  return <Spinner />;
};

export default Microsoft;
