/** @jsxImportSource @emotion/react */

// Import libraries
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useState, useEffect } from 'react';

// Import Ant Design components
import { message, Tag, Alert, DatePicker, Button, Space } from 'antd';

// Import modules and components
import { preset } from 'styles';
import { getApi, useIsSuperAdminRoute, fetchApiErrorMessage } from 'modules';
import MWUsageEstimate from '@components/MWUsageEstimate';

// Import RangePicker component
const { RangePicker } = DatePicker;

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i += 1) {
    result.push(i);
  }
  return result;
}

const MeltwaterExport = ({ dataset }) => {
  const { id: datasetId, integrationId } = dataset;

  const [countError, setCountError] = useState();
  const [exportError, setExportError] = useState();
  const [usageLoading, setUsageLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [estimatedUsage, setEstimatedUsage] = useState(0);
  const [dateRange, setDateRange] = useState([]);
  const [integrationMeta, setIntegrationMeta] = useState(
    dataset.integrationMeta
  );

  const { id: searchId, exported, type } = integrationMeta;

  const isGlobal = useIsSuperAdminRoute();

  useEffect(() => {
    setIntegrationMeta(dataset.integrationMeta);
  }, [dataset.integrationMeta]);

  const [startDate, endDate] = dateRange;

  useEffect(() => {
    const getSearchCount = async () => {
      try {
        setUsageLoading(true);
        const { data } = await getApi(
          isGlobal
            ? `superadmin/integrations/${integrationId}/meltwater-searches/searches/counts`
            : `integrations/${integrationId}/meltwater-searches/searches/counts`,
          {
            start_date: startDate.format(),
            end_date: endDate.format(),
            search_ids: searchId.join(','),
          }
        );

        setEstimatedUsage(data.count.total);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        message.error(fetchApiErrorMessage(err));
        setCountError(fetchApiErrorMessage(err));
      } finally {
        setUsageLoading(false);
      }
    };

    if (integrationId && searchId && startDate && endDate) getSearchCount();
  }, [endDate, integrationId, isGlobal, searchId, startDate]);

  const createNewExport = async () => {
    try {
      setExportLoading(true);
      const { data } = await getApi(
        isGlobal
          ? `superadmin/datasets/mw-back-fill/${datasetId}`
          : `datasets/mw-back-fill/${datasetId}`,
        { start_date: startDate.format(), end_date: endDate.format() }
      );
      setIntegrationMeta(data);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      message.error(fetchApiErrorMessage(err));
      setExportError(fetchApiErrorMessage(err));
    } finally {
      setExportLoading(false);
    }
  };

  if (!type || type.toLowerCase() !== 'meltwater') return null;

  const disabledDate = (current) => {
    // Can not select days after today
    return current && current > moment().endOf('day');
  };

  const disabledTime = (dates, dateType) => {
    if (dates) {
      // Assign the active date in the array based on the date type
      let activeDate;
      // eslint-disable-next-line prefer-destructuring
      if (dateType === 'start') activeDate = dates[0];
      // eslint-disable-next-line prefer-destructuring
      if (dateType === 'end') activeDate = dates[1];
      // Disable future times if the selected date is NOT before the current date
      // In other words, if the selected date is today
      if (activeDate && !activeDate.isBefore(moment(), 'day')) {
        return {
          disabledHours: () => range(0, 24).slice(moment().hour() + 1),
          disabledMinutes: () => range(0, 60).slice(moment().minute() + 1),
        };
      }
    }
    return {};
  };

  const errorAlert = (errorMessage) => {
    return (
      <Alert
        message="Error"
        description={errorMessage}
        type="error"
        showIcon
        closable
      />
    );
  };

  return (
    <div css={{ paddingTop: preset.spacing(3) }}>
      <Space>
        <b>Data Export</b>
        <Tag color={exported ? 'green' : 'red'}>
          {exported ? 'Export Finished' : 'Export Not Finished'}
        </Tag>
      </Space>
      <div css={{ marginBottom: preset.spacing(1) }}>
        {exported
          ? 'Select dates below to create a new export for this Dataset.'
          : 'An export is currently in progress.'}
      </div>
      {exported && (
        <div>
          <RangePicker
            disabledDate={disabledDate}
            disabledTime={disabledTime}
            onChange={setDateRange}
            onOk={setDateRange}
            format="DD/MM/YYYY HH:mm"
            showTime={{
              hideDisabledOptions: true,
              defaultValue: [moment('00:00:00', 'HH:mm'), moment()],
              format: 'HH:mm',
            }}
          />
          {startDate && endDate && !countError ? (
            <div css={{ marginTop: preset.spacing(3) }}>
              <MWUsageEstimate
                usageLoading={usageLoading}
                searchId={searchId}
                estimatedUsage={estimatedUsage}
                startDate={startDate.format('DD/MM/YYYY HH:mm')}
                endDate={endDate.format('DD/MM/YYYY HH:mm')}
              />
              <Button
                loading={exportLoading}
                onClick={createNewExport}
                type="primary"
                ghost
                disabled={usageLoading}
              >
                Create New Export
              </Button>
            </div>
          ) : null}
          {countError && errorAlert(countError)}
        </div>
      )}
      {exportError && errorAlert(exportError)}
    </div>
  );
};

MeltwaterExport.propTypes = {
  dataset: PropTypes.shape({
    datasetId: PropTypes.string,
    integrationId: PropTypes.string,
    integrationMeta: PropTypes.shape({
      id: PropTypes.number,
      exported: PropTypes.bool,
    }),
  }),
};

MeltwaterExport.defaultProps = {
  dataset: { integrationMeta: { exported: false } },
};

export default MeltwaterExport;
