/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import { Guide } from 'bizcharts';

const { DataMarker } = Guide;

const ChartComments = ({
    visible = false,
    comments = [],
    data = [],
    x,
    y,
}) => {
    const commentsWithPos = useMemo(() => comments.reduce((prev, commentObj) => {
        // If commentObj has metric then we know the y
        const { dimension, metric } = commentObj;
        if (metric) {
            return [
                ...prev,
                { ...commentObj, x: dimension, y: metric },
            ];
        }

        // If commentObj doesn't have metric, we have to get Y from data
        if (Array.isArray(data)) {
            const currentDimensionData = data.find(row => row[x] === dimension);
            if (currentDimensionData && currentDimensionData[y] !== undefined) {
                return [
                    ...prev,
                    { ...commentObj, x: dimension, y: currentDimensionData[y] },
                ];
            }
            return prev;
        }

        return prev;
    }, []), [comments, data, x, y]);

    return (visible && (
        <Guide>
            {commentsWithPos.map(commentObj => (
                <DataMarker
                    key={`${commentObj.x}-${commentObj.y}`}
                    position={[commentObj.x, commentObj.y]}
                    content={commentObj.comments.join('\n')}
                    style={{ text: { textAlign: 'left' } }}
                />
            ))}
        </Guide>
    ));
};

export default ChartComments;
