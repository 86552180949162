/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { preset } from 'styles';
import { getPresignedGetURL } from 'modules/objectStore';

const DEFAULT_LOGO_DARK_PATH = '/img/logo.png';
const DEFAULT_LOGO_WHITE_PATH = '/img/logo-white.png';

type Props = {
  orgSlug?: string;
  theme?: 'light' | 'dark';
  cantClick?: boolean;
  placement?: 'default' | 'header' | 'auth';
};

const Logo: React.FC<Props> = ({
  orgSlug,
  theme = 'light',
  cantClick = false,
  placement = 'default',
}) => {
  const localURL =
    theme === 'dark' ? DEFAULT_LOGO_DARK_PATH : DEFAULT_LOGO_WHITE_PATH;

  const [logo, setLogoURL] = useState(localURL);

  useEffect(() => {
    const fetchCustomLogo = async () => {
      try {
        const customLogo = await getPresignedGetURL('', 'logos/logo', orgSlug);
        if (customLogo) {
          setLogoURL(customLogo);
        }
      } catch (error) {
        setLogoURL(localURL);
      }
    };

    if (orgSlug) {
      fetchCustomLogo();
    }
  }, [orgSlug, localURL]);

  const onImgError = () => {
    setLogoURL(localURL);
  };

  return logo ? (
    <NavLink
      to="/"
      css={{
        cursor: 'pointer',
        pointerEvents: cantClick ? 'none' : 'auto',
        img: {
          maxWidth: '100%',
          maxHeight: '100%',
        },
        ...styles[placement],
      }}
    >
      <img alt="Logo" src={logo} onError={onImgError} />
    </NavLink>
  ) : null;
};

const styles = {
  default: {
    display: 'inline-block',
    height: 30,
  },
  header: {
    flexShrink: 0,
    display: 'flex',
    height: 30,
    marginRight: preset.spacing(2),
    [preset.breakpoints.lg]: {
      marginRight: preset.spacing(3),
    },
  },
  auth: {
    display: 'inline-block',
    width: 200,
    height: preset.spacing(10),
  },
};

export default Logo;
