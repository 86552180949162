export const spacing = (factor: number) => 8 * factor;

export const boxShadow = '0 1px 4px 0 rgb(0 21 41 / 12%)';
export const border = 'solid 1px #e8e8e8';
export const transition = 'all 0.15s';

export const headerHeight = spacing(6);
export const subHeaderHeight = spacing(8);
export const containerWidth = 1440;
export const modalWidth = {
  md: 800,
  lg: 1200,
};
export const centerFormWidth = 480;

export const breakpoints = {
  lg: '@media (min-width: 1440px)',
};
