/** @jsxImportSource @emotion/react */
import { Button, Space } from 'antd';

import { GEO_LOCATION_SCHEMA_FIELDS } from '@modules/filterBarHelpers';

const GeoDimensionsSelect = ({
    dimensions = [],
    onDimensionChange,
    autoZoom = false,
}) => {
    const isGeoDimensionSelected = name => {
        if (autoZoom) {
            const selectedDimension = dimensions.length > 0 && dimensions[0].attribute;
            return selectedDimension === name;
        }
        return false;
    };

    const onDimensionSelect = newVal => {
        if (isGeoDimensionSelected(newVal)) {
            onDimensionChange();
        } else {
            const newDimension = GEO_LOCATION_SCHEMA_FIELDS.find(field => field.name === newVal);
            onDimensionChange(newDimension);
        }
    };

    return (
        <Space>
            {GEO_LOCATION_SCHEMA_FIELDS.map(geoField => (
                <Button
                    key={geoField.name}
                    type={isGeoDimensionSelected(geoField.name) ? 'primary' : 'default'}
                    onClick={() => onDimensionSelect(geoField.name)}
                >
                    {geoField.displayName}
                </Button>
            ))}
        </Space>
    );
};

export default GeoDimensionsSelect;
