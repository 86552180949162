/** @jsxImportSource @emotion/react */
import { useHistory } from 'react-router-dom';
import { UserInfo } from '@otso/auth-wrapper';

import { trackEvent } from 'analytics';
import { useGetApi, useCheckPermission } from 'modules';
import CXTable from '@components/CXTable';

const ViewProjects = () => {
  const history = useHistory();

  const canCreateProject = useCheckPermission(['insight.createProject']);

  // Fetch Projects
  const { data: projects = [], loading: projectsLoading } = useGetApi(
    'projects',
    []
  );

  const onCreateProject = () => {
    if (canCreateProject) {
      trackEvent('Create Project Begin');
      history.push('/projects/create');
    }
  };

  return (
    <CXTable
      title="Projects"
      loading={projectsLoading}
      columns={[
        { title: 'Name', dataIndex: 'name', searchable: true },
        {
          title: 'Created By',
          dataIndex: 'creatorId',
          render: (creatorId) => <UserInfo id={creatorId} output="firstName" />,
        },
        { title: 'Created', dataIndex: 'createdAt', type: 'date' },
      ]}
      dataSource={projects}
      emptyText={!canCreateProject && 'No projects found'}
      singularUnit="project"
      pluralUnit="projects"
      rowKey="slug"
      onItemClick={(row) => history.push(`/projects/${row.slug}/dashboards`)}
      {...(canCreateProject ? { onCreateNew: onCreateProject } : {})}
      canSearch
    />
  );
};

export default ViewProjects;
