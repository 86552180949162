/** @jsxImportSource @emotion/react */

// Import libraries
import { useState, useEffect } from 'react';

// Import Ant Design components
import { Tag, Button, Select, Descriptions } from 'antd';

// Import contexts
import { preset } from 'styles';
import { getApi, deleteApi, putApi, useIsSuperAdminRoute } from 'modules';

// Import Option component
const { Option } = Select;

const DatasetIntegrationTable = ({ dataset, integrations, integration }) => {
  // Initialization
  const [loadingEnrichmentToggle, setLoadingEnrichmentToggle] = useState(false);
  const [mwSearch, setMWSearch] = useState();
  const [enrichmentId, setEnrichmentId] = useState(dataset.enrichmentId);

  const isGlobal = useIsSuperAdminRoute();

  const {
    integrationId,
    integrationMeta,
    id: datasetId,
    source,
    uploadType,
  } = dataset;
  const { id: mwSearchId, isGlobal: datasetIsGlobal } = integrationMeta || {};

  useEffect(() => {
    setEnrichmentId(dataset.enrichmentId);
  }, [dataset.enrichmentId]);

  useEffect(() => {
    const getMWSearch = async () => {
      const { data } = await getApi(
        isGlobal
          ? `superadmin/integrations/${integrationId}/meltwater-searches/${mwSearchId}`
          : `integrations/${integrationId}/meltwater-searches/${mwSearchId}`
      );
      setMWSearch(data.search.name);
    };
    if (integrationId && mwSearchId) {
      getMWSearch();
    }
  }, [integrationId, isGlobal, mwSearchId]);

  const disableEnrichment = async () => {
    setLoadingEnrichmentToggle(true);
    const { data } = await deleteApi(
      `datasets/${datasetId}/disable-enrichment`
    );
    setEnrichmentId(data.enrichmentId);
    setLoadingEnrichmentToggle(false);
  };

  const enableEnrichment = async (selectedEnrichmentId) => {
    setLoadingEnrichmentToggle(true);
    const { data } = await putApi(`datasets/${datasetId}/enable-enrichment`, {
      enrichmentId: selectedEnrichmentId,
    });
    setEnrichmentId(data.enrichmentId);
    setLoadingEnrichmentToggle(false);
  };

  const enrichment = integrations.find((integ) => integ.id === enrichmentId);

  const data = [
    { key: 'Upload Type', value: uploadType },
    {
      key: 'Enrichment Model',
      value: enrichment ? (
        <div>
          <div>{enrichment.name}</div>
          {!datasetIsGlobal && (
            <Button
              size="small"
              onClick={disableEnrichment}
              loading={loadingEnrichmentToggle}
              type="danger"
              css={{ marginTop: preset.spacing(1) }}
            >
              Disable Enrichment
            </Button>
          )}
        </div>
      ) : (
        <div>
          <Tag color="red">Not Enriching</Tag>
          {!datasetIsGlobal && (
            <div css={{ paddingTop: preset.spacing(1) }}>
              <Select
                size="small"
                placeholder="Select an Enrichment"
                onSelect={enableEnrichment}
                loading={loadingEnrichmentToggle}
              >
                {integrations.map((integrationOption) => {
                  return (
                    source === integrationOption.type && (
                      <Option
                        key={integrationOption.id}
                        value={integrationOption.id}
                      >
                        {integrationOption.name}
                      </Option>
                    )
                  );
                })}
              </Select>
            </div>
          )}
        </div>
      ),
    },
  ];

  // Prepare data to be displayed in details panel
  if (uploadType === 'integration') {
    const {
      type,
      redditDatasetSource,
      redditSearch,
      subreddits,
      fbPages,
      searchType,
      id,
    } = integrationMeta || {};

    // Prepare integration name
    let integrationName = integration ? integration.name : null;
    if (datasetIsGlobal) integrationName = 'Global Dataset';

    switch (type) {
      case 'meltwater': {
        data.push(
          ...[
            { key: 'Integration', value: integrationName },
            { key: 'Search ID', value: id || null },
            ...(datasetIsGlobal
              ? []
              : [{ key: 'Search Name', value: mwSearch || 'Loading...' }]),
            { key: 'Search Type', value: searchType || null },
          ]
        );
        break;
      }
      case 'reddit': {
        data.push(
          ...[
            { key: 'Integration', value: integrationName },
            { key: 'Reddit Dataset Source', value: redditDatasetSource },
            ...(redditDatasetSource === 'redditSearch'
              ? [{ key: 'Reddit Search', value: redditSearch }]
              : []),
            ...(redditDatasetSource === 'subreddits'
              ? [
                  {
                    key: 'Subreddits',
                    value: subreddits ? subreddits.join(', ') : null,
                  },
                ]
              : []),
          ]
        );
        break;
      }
      case 'facebook': {
        data.push(
          ...[
            { key: 'Integration', value: integrationName },
            {
              key: 'FB Pages',
              value: (
                <div>
                  {fbPages
                    ? fbPages.map((page) => <Tag key={page}>{page}</Tag>)
                    : null}
                </div>
              ),
            },
          ]
        );
        break;
      }
      default:
        break;
    }
  }

  return (
    <Descriptions bordered column={1} size="small">
      {data.map((item) => (
        <Descriptions.Item key={item.key} label={item.key}>
          {item.value}
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
};

export default DatasetIntegrationTable;
