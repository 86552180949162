/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { message } from 'antd';

import { preset } from 'styles';
import { ExploreCard, Empty } from 'components';
import {
  postApi,
  fetchApiErrorMessage,
  getExploreQuery,
  arrayToObject,
} from 'modules';
import {
  Dataset,
  Filter,
  TimeFilter,
  DocumentEnrichmentCorrection,
} from 'types';

type Props = {
  document: Record<string, any>;
  dataset: Dataset;
  appliedFilters?: { filters?: Filter[]; timeFilter?: TimeFilter };
  renderChildDocumentCollapse: (
    document: Record<string, any>,
    hasEnrichmentCorrection: boolean
  ) => React.ReactElement;
};

const DocumentThread: React.FC<Props> = ({
  document,
  dataset,
  appliedFilters,
  renderChildDocumentCollapse,
}) => {
  const [threadDocuments, setThreadDocuments] = useState<Record<string, any>[]>(
    []
  );
  const [threadEnrichmentCorrections, setThreadEnrichmentCorrections] =
    useState<{ [documentId: string]: DocumentEnrichmentCorrection }>({});
  const [loading, setLoading] = useState<boolean>(true);

  const { slug: projectSlug } = useParams<{ slug: string }>();

  const { id: datasetId } = dataset;

  useEffect(() => {
    const { filters = [], timeFilter } = appliedFilters || {};

    const query = getExploreQuery({
      datasetIds: [datasetId],
      filters: [
        ...filters,
        { attribute: 'parent_id', condition: '=', value: document.id },
      ],
      timeFilter,
    });

    const fetchEnrichmentCorrections = async (newDocIds: string[]) => {
      const { data } = await postApi(
        `projects/${projectSlug}/enrichment-correction`,
        { documents: newDocIds }
      );
      setThreadEnrichmentCorrections(
        data ? arrayToObject(data, 'documentId') : {}
      );
    };

    const fetchThreadDocuments = async () => {
      setLoading(true);
      try {
        const res = await postApi(`projects/${projectSlug}/query`, query);
        const { data }: { data: Record<string, any>[] } = res.data;

        if (data.length > 0) {
          await fetchEnrichmentCorrections(data.map((doc) => doc.otso_doc_id));
        }

        setThreadDocuments(data);
      } catch (error) {
        message.error(fetchApiErrorMessage(error));
      }
      setLoading(false);
    };

    if (document.id && datasetId) fetchThreadDocuments();
  }, [appliedFilters, datasetId, document.id, projectSlug]);

  return (
    <ExploreCard
      title="Document Thread"
      loading={loading}
      hidden={!loading && threadDocuments.length === 0}
      css={{
        '.ant-card-body': {
          paddingTop: preset.spacing(3),
          paddingBottom: preset.spacing(3),
          '> div:last-of-type': {
            marginBottom: 0,
          },
        },
      }}
    >
      {threadDocuments.length > 0 ? (
        threadDocuments.map((threadDocument) =>
          renderChildDocumentCollapse(
            threadDocument,
            !!threadEnrichmentCorrections[threadDocument.otso_doc_id]
          )
        )
      ) : (
        <div css={{ height: preset.spacing(2) }}>
          <Empty fullHeight />
        </div>
      )}
    </ExploreCard>
  );
};

export default DocumentThread;
