/** @jsxImportSource @emotion/react */
import capitalize from 'lodash/capitalize';

import {
  Collapse,
  Form,
  Row,
  Col,
  InputNumber,
  Switch,
  Input,
  Select,
} from 'antd';

import { preset } from 'styles';
import deepGet from '@modules/deepGet';
import isNumber from '@modules/lib/isNumber';

const { Panel } = Collapse;
const FormItem = Form.Item;

const AxisPanel = ({
  defaultConfig = {},
  dispatch,
  options = {},
  ...props
}) => {
  const axisLabelConfig = defaultConfig.label || {};

  const {
    canCustomiseLabel = true,
    canReverse = true,
    canTranspose = false,
    canShowEmptyDate = false,
    canLogScale = false,
    canChangePosition = true,
    rangeKeys = [],
  } = options;

  const onRangeAliasEnter = (value, rangeKey, childKey) => {
    dispatch({
      type: 'SET_CONFIG',
      value: {
        ...(deepGet(defaultConfig, ['axis', rangeKey]) || {}),
        [childKey]: value,
      },
      key: 'axis',
      childKey: rangeKey,
    });
  };

  return (
    <Panel {...props} key="dimension_axis" header="Axis">
      <Row gutter={preset.spacing(3)}>
        {canCustomiseLabel && [
          <Col key="Label font size" span={8}>
            <FormItem label="Label font size">
              <InputNumber
                value={axisLabelConfig.fontSize}
                onChange={(value) =>
                  dispatch({
                    type: 'SET_CONFIG',
                    value,
                    key: 'label',
                    childKey: 'fontSize',
                  })
                }
              />
            </FormItem>
          </Col>,
          <Col key="Label offset" span={8}>
            <FormItem label="Label offset">
              <InputNumber
                placeholder="auto"
                value={axisLabelConfig.offset}
                onChange={(value) =>
                  dispatch({
                    type: 'SET_CONFIG',
                    value,
                    key: 'label',
                    childKey: 'offset',
                  })
                }
              />
            </FormItem>
          </Col>,
          <Col key="Label rotate" span={8}>
            <FormItem label="Label rotate">
              <InputNumber
                placeholder="auto"
                value={axisLabelConfig.rotate}
                onChange={(value) =>
                  dispatch({
                    type: 'SET_CONFIG',
                    value,
                    key: 'label',
                    childKey: 'rotate',
                  })
                }
              />
            </FormItem>
          </Col>,
        ]}
        {canChangePosition &&
          ['x', 'y'].map((axisKey) => (
            <Col key={`${axisKey}_axis_pos`} span={8}>
              <FormItem label={`${axisKey.toUpperCase()} axis position`}>
                <Select
                  placeholder={axisKey === 'x' ? 'Bottom' : 'Left'}
                  value={deepGet(defaultConfig, ['axis', axisKey, 'position'])}
                  onChange={(val) =>
                    dispatch({
                      type: 'SET_CONFIG',
                      value: {
                        ...(deepGet(defaultConfig, ['axis', axisKey]) || {}),
                        position: val,
                      },
                      key: 'axis',
                      childKey: axisKey,
                    })
                  }
                  options={
                    axisKey === 'x'
                      ? [
                          { label: 'Top', value: 'top' },
                          { label: 'Bottom', value: 'bottom' },
                          { label: 'Hidden', value: 'hidden' },
                        ]
                      : [
                          { label: 'Left', value: 'left' },
                          { label: 'Right', value: 'right' },
                          { label: 'Hidden', value: 'hidden' },
                        ]
                  }
                />
              </FormItem>
            </Col>
          ))}
        {canReverse && (
          <Col span={8}>
            <FormItem label="Reverse axis order">
              <Switch
                checked={!!defaultConfig.reverse}
                onChange={(checked) =>
                  dispatch({
                    type: 'SET_CONFIG',
                    value: checked,
                    key: 'reverse',
                  })
                }
              />
            </FormItem>
          </Col>
        )}
        {canTranspose && (
          <Col span={8}>
            <FormItem label="Transpose">
              <Switch
                checked={!!defaultConfig.transpose}
                onChange={(checked) =>
                  dispatch({
                    type: 'SET_CONFIG',
                    value: checked,
                    key: 'transpose',
                  })
                }
              />
            </FormItem>
          </Col>
        )}
        {canShowEmptyDate && (
          <Col span={8}>
            <FormItem label="Show blank dates">
              <Switch
                checked={!!defaultConfig.showEmptyDate}
                onChange={(checked) =>
                  dispatch({
                    type: 'SET_CONFIG',
                    value: checked,
                    key: 'showEmptyDate',
                  })
                }
              />
            </FormItem>
          </Col>
        )}
        {canLogScale && (
          <Col span={8}>
            <FormItem label="Use log scale">
              <Switch
                checked={!!defaultConfig.log}
                onChange={(checked) =>
                  dispatch({
                    type: 'SET_CONFIG',
                    value: checked,
                    key: 'log',
                  })
                }
              />
            </FormItem>
          </Col>
        )}
        {rangeKeys.map((rangeKey) =>
          ['min', 'max'].map((rangeChildKey) => (
            <Col key={`${rangeKey}_${rangeChildKey}`} span={8}>
              <FormItem
                label={`${
                  defaultConfig[rangeKey]
                    ? `'${defaultConfig[rangeKey]}'`
                    : 'Metric'
                } ${capitalize(rangeChildKey)}`}
              >
                <Row gutter={12}>
                  <Col span={10}>
                    <InputNumber
                      placeholder="auto"
                      value={deepGet(defaultConfig, [
                        'axis',
                        rangeKey,
                        rangeChildKey,
                      ])}
                      onChange={(value) =>
                        onRangeAliasEnter(value, rangeKey, rangeChildKey)
                      }
                      css={{ width: '100%' }}
                    />
                  </Col>
                  <Col span={10}>
                    <Input
                      placeholder={`${capitalize(rangeChildKey)} alias`}
                      disabled={
                        !isNumber(
                          deepGet(defaultConfig, [
                            'axis',
                            rangeKey,
                            rangeChildKey,
                          ])
                        )
                      }
                      defaultValue={deepGet(defaultConfig, [
                        'axis',
                        rangeKey,
                        `${rangeChildKey}Alias`,
                      ])}
                      onPressEnter={(e) =>
                        onRangeAliasEnter(
                          e.target.value,
                          rangeKey,
                          `${rangeChildKey}Alias`
                        )
                      }
                      onBlur={(e) =>
                        onRangeAliasEnter(
                          e.target.value,
                          rangeKey,
                          `${rangeChildKey}Alias`
                        )
                      }
                    />
                  </Col>
                </Row>
              </FormItem>
            </Col>
          ))
        )}
      </Row>
    </Panel>
  );
};

export default AxisPanel;
