import formatChartData from './formatChartData';
import getDateBoundary from './getDateBoundary';
import fillSecondDimensionData from './fillSecondDimensionData';
import insertEmptyDate from './insertEmptyDate';
import { formatDate } from '../date/formatDate';
import deepGet from '../deepGet';

const fillColorDimensionData = (data = [], config = {}) => {
    const {
        x, y, colorDimension, orderBy = [],
    } = config;
    const colorDimensionOrderBy = orderBy.find(orderByObj => orderByObj.attribute === colorDimension);

    if (!colorDimensionOrderBy) return data;

    return fillSecondDimensionData(
        data,
        {
            firstDimension: x,
            secondDimension: colorDimension,
            metric: y,
            secondDimensionOrder: colorDimensionOrderBy.direction,
        },
    );
};

const formatBarChartData = (chartConfig = {}) => {
    const {
        data,
        x,
        y,
        colorDimension,
        queryObj,
        showEmptyDate,
        schema,
    } = chartConfig;

    const { orderBy } = queryObj;

    let formattedData = data;

    formattedData = formatChartData(formattedData, [x, colorDimension]);

    if (colorDimension) {
        formattedData = fillColorDimensionData(formattedData, {
            x, y, colorDimension, orderBy,
        });
    }

    if (showEmptyDate) {
        const { min, max } = getDateBoundary({
            data: formattedData,
            x,
            queryObj,
            schema,
        });

        formattedData = insertEmptyDate(formattedData, x, {
            timeInterval: deepGet(schema, [x, 'functionValue']),
            startDate: min && formatDate(min),
            endDate: max && formatDate(max),
            valueKey: y,
        });
    }

    return formattedData;
};

export default formatBarChartData;
