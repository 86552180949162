import { useState, useReducer, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '@otso/auth-wrapper';

import { message, PageHeader, Space, Button } from 'antd';

import { trackEvent } from 'analytics';
import { preset } from 'styles';

import Container from '@components/Container';
import useGetApi from '@modules/hooks/useGetApi';
import { postApi, fetchApiErrorMessage } from '@modules/api';

import ProjectInfoForm from './components/ProjectInfoForm';
import ProjectDatasetForm from './components/ProjectDatasetForm';
import SuccessDisplay from './components/SuccessDisplay';
import { createProjectReducer, initialState } from './reducer';

const CreateProject = () => {
  const history = useHistory();

  const [newProjectSlug, setNewProjectSlug] = useState();
  const [isCreatingProject, setIsCreatingProject] = useState(false);

  const [newProject, dispatch] = useReducer(createProjectReducer, initialState);

  const { name, collaborators = [], datasets = [], filters = {} } = newProject;

  const { currentOrg } = useAuth();

  // Get Datasets
  const { data: allDatasets = [] } = useGetApi('datasets');

  // Sort users by email
  const sortedUsers = useMemo(
    () =>
      (currentOrg?.users || []).sort((a, b) => a.email.localeCompare(b.email)),
    [currentOrg?.users]
  );

  const isProjectInfoFormValid = useMemo(() => name.trim().length > 0, [name]);

  const isProjectDatasetFormValid = useMemo(
    () => datasets.length > 0,
    [datasets]
  );

  const createProjectConfirm = () => {
    const projectData = {
      name,
      collaborators,
      datasets,
      filters,
    };

    // Re-assign datasetIds
    if (projectData.filters.datasetIds) {
      projectData.filters.datasetIds = datasets;
    }

    // Set empty filters to null
    if (filters) {
      datasets.forEach((dataset) => {
        if (filters[dataset] === undefined) {
          filters[dataset] = null;
        }
      });
    }

    const createProject = async () => {
      setIsCreatingProject(true);

      try {
        const res = await postApi('projects', projectData);
        trackEvent('Create Project Complete', {
          projectName: projectData.name,
          projectSlug: res.data.slug,
        });
        setNewProjectSlug(res.data.slug);
      } catch (error) {
        trackEvent('Create Project Error', {
          error: fetchApiErrorMessage(error),
          projectName: projectData.name,
        });
        message.error(fetchApiErrorMessage(error));
      }

      setIsCreatingProject(false);
    };

    // Notes: Create project - this used to be in a modal for user to confirm
    createProject();
  };

  return (
    <div
      css={{
        '.ant-card': {
          marginBottom: preset.spacing(3),
        },
      }}
    >
      <PageHeader ghost={false} title="Create Project" />
      <Container paddingY={3}>
        {newProjectSlug ? (
          <SuccessDisplay newProjectSlug={newProjectSlug} />
        ) : (
          <>
            <ProjectInfoForm
              users={sortedUsers}
              formValues={{
                name,
                collaborators,
              }}
              valid={isProjectInfoFormValid}
              onFormValueChange={(newVal, key) =>
                dispatch({
                  type: 'SET_PROJECT_FORM',
                  value: newVal,
                  key,
                })
              }
            />
            <ProjectDatasetForm
              allDatasets={allDatasets}
              formValues={{
                datasets,
                filters,
              }}
              valid={isProjectDatasetFormValid}
              onFormValueChange={(newVal, key) =>
                dispatch({
                  type: 'SET_PROJECT_FORM',
                  value: newVal,
                  key,
                })
              }
            />
            <Space
              size="middle"
              style={{
                marginTop: preset.spacing(3),
                marginBottom: preset.spacing(3),
              }}
            >
              <Button
                type="primary"
                disabled={!isProjectInfoFormValid || !isProjectDatasetFormValid}
                loading={isCreatingProject}
                onClick={createProjectConfirm}
              >
                Create Project
              </Button>
              <Button onClick={() => history.goBack()}>Cancel</Button>
            </Space>
          </>
        )}
      </Container>
    </div>
  );
};

export default CreateProject;
