import * as topojson from 'topojson-client';
import cloneDeep from 'lodash/cloneDeep';

const filterGeoJson = (rawTopoJson, config = {}) => {
    const { idPropertyName, data = [], dimensions = [] } = config;

    const defaultGeoJson = { type: 'FeatureCollection', features: [] };

    if (data.length > 0 && dimensions.length > 0) {
        const geoDimensionKey = dimensions[0].as;

        let dimensionValueList = [...new Set(data.map(row => row[geoDimensionKey]))];

        if (dimensionValueList.length === 0) {
            return defaultGeoJson;
        }

        const formattedGeometries = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const geometry of rawTopoJson.objects.data.geometries) {
            if (dimensionValueList.length === 0) {
                break;
            }
            if (dimensionValueList.includes(`${geometry.properties[idPropertyName]}`)) {
                dimensionValueList = dimensionValueList.filter(
                    dimensionValue => `${dimensionValue}` !== `${geometry.properties[idPropertyName]}`,
                );
                formattedGeometries.push(geometry);
            }
        }

        // rawTopoJson should be immutable
        const formattedTopoJson = cloneDeep(rawTopoJson);

        formattedTopoJson.objects.data.geometries = formattedGeometries;

        return topojson.feature(formattedTopoJson, formattedTopoJson.objects.data);
    }

    return defaultGeoJson;
};

export default filterGeoJson;
