/** @jsxImportSource @emotion/react */

// Import libraries
import { find } from 'lodash';
import urlJoin from 'url-join';
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';

// Import Ant Design components
import {
  PageHeader,
  Card,
  Form,
  Select,
  Input,
  Space,
  Button,
  message,
} from 'antd';

// Import modules
import { getApi, postApi, fetchApiErrorMessage } from 'modules';

// Import components
import Container from '@components/Container';

// Import stylesheets
import styles from './styles';

// Import additional Ant Design components
const { Option } = Select;
const { TextArea } = Input;

const DSData = () => {
  // Initialisation
  const history = useHistory();
  const [form] = Form.useForm();
  const [loadingDatasets, setLoadingDatasets] = useState(false);
  const [datasets, setDatasets] = useState([]);
  const [selectedDataset, setSelectedDataset] = useState({});
  const [creatingDataset, setCreatingDataset] = useState(false);

  useEffect(() => {
    const fetchOtsoDataDatasets = async () => {
      try {
        setLoadingDatasets(true);

        // Retrieve otso Data datasets
        const { data } = await getApi(
          'datasets',
          {},
          process.env.REACT_APP_DATA_API_URL
        );
        setDatasets(data);
      } catch (error) {
        message.error('Unable to fetch datasets, please try again later');
      } finally {
        setLoadingDatasets(false);
      }
    };

    fetchOtsoDataDatasets();

    return () => {
      setLoadingDatasets(false);
      setDatasets([]);
    };
  }, []);

  // On dataset selected
  const onDatasetSelected = (datasetSlug) => {
    const dataset = find(datasets, { id: datasetSlug });
    if (dataset) {
      setSelectedDataset(dataset);
      form.setFieldsValue({
        name: dataset.name,
        description: dataset.description,
      });
    }
  };

  // On form submitted
  const onFinish = async (values) => {
    try {
      setCreatingDataset(true);

      // Create a new dataset
      const { datasetSlug, name, description } = values || {};
      const { data } = await postApi('datasets', {
        name,
        description,
        uploadType: 'data',
        integrationMeta: { datasetSlug },
      });

      // Extract dataset id from the returned response
      const { datasetId } = data || {};

      // Redirect user to the dataset page
      history.push(urlJoin('/admin/datasets', datasetId));
    } catch (error) {
      message.error(
        fetchApiErrorMessage(
          error,
          'Unable to create dataset, please try again later'
        )
      );
    } finally {
      setCreatingDataset(false);
    }
  };

  return (
    <>
      {/* Page header */}
      <PageHeader ghost={false} title="Create Dataset" />

      {/* Page container */}
      <Container paddingY={3}>
        <Card>
          <div style={styles.cardBody}>
            <Form form={form} onFinish={onFinish} layout="vertical">
              {/* Dataset selection */}
              <Form.Item
                name="datasetSlug"
                label="otso Data Dataset"
                rules={[{ required: true, message: 'Please select a dataset' }]}
              >
                {/* otso Data dataset selection */}
                <Select
                  showSearch
                  placeholder="Select a dataset"
                  loading={loadingDatasets}
                  disabled={creatingDataset}
                  onChange={onDatasetSelected}
                >
                  {datasets.map((dataset) => (
                    <Option key={dataset.id}>{dataset.name}</Option>
                  ))}
                </Select>
              </Form.Item>

              {/* Dataset details */}
              {selectedDataset.id && (
                <>
                  {/* Dataset name */}
                  <Form.Item
                    name="name"
                    label="Dataset Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter a dataset name',
                      },
                    ]}
                  >
                    <Input disabled={creatingDataset} />
                  </Form.Item>

                  {/* Dataset description */}
                  <Form.Item name="description" label="Dataset Description">
                    <TextArea rows={3} disabled={creatingDataset} />
                  </Form.Item>
                </>
              )}

              {/* Action panel */}
              <Form.Item>
                <Space size="middle" style={styles.actionPanel}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={creatingDataset}
                  >
                    Create Dataset
                  </Button>

                  <Button
                    onClick={() => history.push('/admin/datasets')}
                    disabled={creatingDataset}
                  >
                    Cancel
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </div>
        </Card>
      </Container>
    </>
  );
};

export default DSData;
