/** @jsxImportSource @emotion/react */
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

const StyledCollapse = ({ activeKey, onChange, children, className }) => {
  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      {...(onChange
        ? {
            activeKey,
            onChange,
          }
        : {})}
      className={className}
      css={{
        backgroundColor: '#fff',
        '.ant-collapse-item': {
          borderRadius: 4,
          border: 0,
          overflow: 'hidden',
          marginBottom: 12,
          '.ant-collapse-header': {
            backgroundColor: '#f7f7f7',
          },
          '.ant-collapse-content-box': {
            paddingBottom: 4,
          },
        },
      }}
    >
      {children}
    </Collapse>
  );
};

export default StyledCollapse;
