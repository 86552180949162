/** @jsxImportSource @emotion/react */
import { useState, useEffect, forwardRef, useRef } from 'react';
import { Spin } from 'antd';
import { useAuth } from '@otso/auth-wrapper';

type Ref = HTMLAudioElement;
type Props = { src?: string; inView: boolean };

export const AudioPlayer = forwardRef<Ref, Props>(({ src, inView }, ref) => {
  const [downloadURL, setDownloadURL] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const cachedDownloadURLRef = useRef<string>('');

  const { currentOrgStorageRef } = useAuth();

  useEffect(() => {
    let didCancel = false;

    const getAudioFileURL = async () => {
      if (currentOrgStorageRef && src) {
        if (inView && !downloadURL) {
          if (cachedDownloadURLRef.current) {
            setDownloadURL(cachedDownloadURLRef.current);
          } else {
            setIsLoading(true);
            try {
              const audioFileRef = currentOrgStorageRef.child(src);
              const url = await audioFileRef.getDownloadURL();
              if (!didCancel) {
                setDownloadURL(url);
                setIsLoading(false);
                cachedDownloadURLRef.current = url;
              }
            } catch {
              if (!didCancel) {
                setIsLoading(false);
              }
            }
          }
        } else if (!inView && downloadURL) {
          const audioPlayerElements = window.document.querySelectorAll(
            'audio[src]:not([src=""])'
          );
          if (audioPlayerElements.length > 60) {
            setDownloadURL('');
          }
        }
      }
    };

    getAudioFileURL();

    return () => {
      didCancel = true;
    };
  }, [currentOrgStorageRef, src, inView, downloadURL]);

  return (
    <Spin spinning={isLoading}>
      <div
        role="presentation"
        onClick={(e) => e.stopPropagation()}
        css={{ display: 'flex' }}
      >
        <audio
          ref={ref}
          controls
          src={downloadURL}
          controlsList="nodownload"
          css={{ width: '100%' }}
        >
          Your browser does not support the
          <code>audio</code> element.
        </audio>
      </div>
    </Spin>
  );
});
