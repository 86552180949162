/** @jsxImportSource @emotion/react */
import { Collapse, Form, Row, Col, Select, Input } from 'antd';

import { preset } from 'styles';

const { Panel } = Collapse;
const { Option } = Select;
const FormItem = Form.Item;

const SingleNumberPanel = ({ chart, dispatch, ...props }) => {
  const {
    defaultConfig = {},
    queryObj: { compare },
  } = chart;

  const hasCompareValue = !!(compare && typeof compare === 'object');
  const compareConfig = defaultConfig.compare || {};

  return (
    <Panel {...props} key="single_number" header="Number">
      <Row gutter={preset.spacing(3)}>
        <Col span={12}>
          <FormItem label="Number Format">
            <Select
              placeholder="Number"
              value={defaultConfig.format}
              onChange={(value) =>
                dispatch({
                  type: 'SET_CONFIG',
                  value,
                  key: 'format',
                })
              }
            >
              <Option value="number">Number</Option>
              <Option value="percent">Percent</Option>
            </Select>
          </FormItem>
        </Col>
        {hasCompareValue && [
          <Col key="display_comparison_value" span={12}>
            <FormItem label="Display comparison value as">
              <Select
                placeholder="Percentage change"
                value={compareConfig.display}
                onChange={(value) =>
                  dispatch({
                    type: 'SET_CONFIG',
                    value,
                    key: 'compare',
                    childKey: 'display',
                  })
                }
              >
                <Option value="percentage_change">Percentage change</Option>
                <Option value="absolute_change">Absolute change</Option>
                <Option value="value">Value</Option>
              </Select>
            </FormItem>
          </Col>,
          <Col key="comparison_desc" span={12}>
            <FormItem
              label="Comparison description"
              help="Press Enter to Apply"
            >
              <Input
                defaultValue={compareConfig.desc}
                onPressEnter={(e) =>
                  dispatch({
                    type: 'SET_CONFIG',
                    value: e.target.value,
                    key: 'compare',
                    childKey: 'desc',
                  })
                }
              />
            </FormItem>
          </Col>,
        ]}
      </Row>
    </Panel>
  );
};

export default SingleNumberPanel;
