/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { Space, Tooltip, message } from 'antd';
import { CheckCircleTwoTone, SyncOutlined } from '@ant-design/icons';

import { trackEvent } from 'analytics';
import { colors } from 'styles';
import { putApi, useCheckPermission, getCustomClassifierFields } from 'modules';
import { DocLabelTag } from 'components';
import { useExplore } from 'providers';
import { Dataset, SchemaField } from 'types';
import { InsightActions } from '@otso/auth-wrapper';

type Props = {
  document: Record<string, any>;
  dataset: Dataset;
  hasEnrichmentCorrection?: boolean;
  className?: string;
};

const CustomClassifierTags: React.FC<Props> = ({
  document,
  hasEnrichmentCorrection = false,
  dataset,
  className,
}) => {
  const { slug: projectSlug } = useParams<{ slug: string }>();

  const [hasCorrection, setHasCorrection] = useState<boolean>(
    hasEnrichmentCorrection
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(hasEnrichmentCorrection);
  const [corrections, setCorrections] = useState<Record<string, any>>(document);
  const [cachedCorrections, setCachedCorrections] =
    useState<Record<string, any>>(document);

  const canAddCorrectionLabel = useCheckPermission([
    InsightActions.viewDocuments,
  ]);

  const {
    documentFilterOptions,
    docCorrectionExtraOptions,
    setDocCorrectionExtraOptions,
  } = useExplore();

  const { otso_doc_id: documentId, datasetId } = document;

  useEffect(() => {
    if (hasEnrichmentCorrection !== hasCorrection) {
      setHasCorrection(hasEnrichmentCorrection);
      setDisabled(hasEnrichmentCorrection);
    }
  }, [hasCorrection, hasEnrichmentCorrection]);

  const customClassifierFields: SchemaField[] = useMemo(() => {
    const {
      schema: { fields },
    } = dataset;
    return getCustomClassifierFields(fields);
  }, [dataset]);

  const onCorrectionLabelChange = (newVal: any, key: string) => {
    trackEvent('Change Enrichment Correction Label', {
      labelKey: key,
      newValue: newVal,
    });
    setCorrections((prevCorrections) => ({
      ...prevCorrections,
      [key]: newVal,
    }));
    setDisabled(false);
  };

  const onCorrectionLabelAdd = (newLabel: string, key: string) => {
    setDocCorrectionExtraOptions((prevExtraOptions) => ({
      ...prevExtraOptions,
      [key]: Array.isArray(prevExtraOptions[key])
        ? [...prevExtraOptions[key], newLabel]
        : [newLabel],
    }));
  };

  const applyCorrections = async () => {
    setLoading(true);

    const body = customClassifierFields.reduce(
      (prev, field) => ({
        corrections: {
          ...prev.corrections,
          [field.name]: corrections[field.name],
        },
        current: {
          ...prev.current,
          [field.name]: document[field.name],
        },
      }),
      {
        corrections: {},
        current: {},
      }
    );

    await putApi(
      `projects/${projectSlug}/enrichment-correction/${datasetId}/${documentId}`,
      body
    );
    setLoading(false);
    setDisabled(true);
    setCachedCorrections(corrections);

    message.success('Document labels verified');

    // TODO: enrichment correct analytics event
    trackEvent('Apply Enrichment Correction');
  };

  const isLabelChanged = useMemo(() => {
    return !isEqual(corrections, cachedCorrections);
  }, [corrections, cachedCorrections]);

  return customClassifierFields.length > 0 ? (
    <div className={className}>
      <Space size="middle" align="center">
        <Space align="center" wrap>
          {customClassifierFields.map((field) => (
            <DocLabelTag
              key={field.name}
              editable={canAddCorrectionLabel}
              value={corrections[field.name]}
              onChange={(val) => onCorrectionLabelChange(val, field.name)}
              name={field.name}
              displayName={field.displayName}
              options={documentFilterOptions[field.name] || []}
              extraOptions={docCorrectionExtraOptions[field.name] || []}
              {...(canAddCorrectionLabel && field.enableExtraOptions
                ? {
                    onAddOption: (newOption) =>
                      onCorrectionLabelAdd(newOption, field.name),
                  }
                : {})}
            />
          ))}
        </Space>

        <div css={{ display: 'flex', '.anticon': { fontSize: 18 } }}>
          {loading ? (
            <SyncOutlined spin />
          ) : disabled ? (
            <Tooltip title="Document labels are verified">
              <CheckCircleTwoTone
                twoToneColor={colors.green}
                css={{ cursor: 'auto' }}
              />
            </Tooltip>
          ) : (
            <Tooltip
              title={
                canAddCorrectionLabel
                  ? `Click to ${
                      isLabelChanged
                        ? 'verify label changes'
                        : 'verify document labels'
                    }`
                  : 'Document labels are not verified'
              }
            >
              <CheckCircleTwoTone
                {...(canAddCorrectionLabel
                  ? {
                      twoToneColor: isLabelChanged
                        ? colors.gold
                        : colors.lightGrey,
                      onClick: (e) => {
                        e.stopPropagation();
                        applyCorrections();
                      },
                      css: { cursor: 'pointer' },
                    }
                  : {
                      twoToneColor: colors.lightGrey,
                    })}
              />
            </Tooltip>
          )}
        </div>
      </Space>
    </div>
  ) : (
    <div />
  );
};

export default CustomClassifierTags;
