/** @jsxImportSource @emotion/react */
import { useState, useMemo, useEffect } from 'react';
import moment from 'moment-timezone';
import { Dropdown, Input, Menu, DatePicker } from 'antd';
import {
  CalendarOutlined,
  CloseCircleFilled,
  DownOutlined,
} from '@ant-design/icons';

import { colors } from 'styles';
import getPresetDateRanges from '@modules/date/getPresetDateRanges';
import { displayMoment } from '@modules/date/formatDate';
import deepGet from '@modules/deepGet';

const { RangePicker } = DatePicker;

const { allValues: ALL_PRESET_RANGE_VALUES, byValue: BY_PRESET_RANGE_VALUE } =
  getPresetDateRanges();

const DateRangePicker = ({
  disabled = false,
  placeholder,
  value,
  onChange,
}) => {
  const [customRangeValue, setCustomRangeValue] = useState([]);
  const [isCustomRangePickerOpen, setIsCustomRangePickerOpen] = useState(false);

  const fromValue = deepGet(value, ['from']) || null;
  const toValue = deepGet(value, ['to']) || null;
  const relativeValue = deepGet(value, ['relative']) || null;

  const showCustomPicker = !!(fromValue && toValue) || isCustomRangePickerOpen;

  useEffect(() => {
    if ((fromValue || toValue) && !relativeValue) {
      setCustomRangeValue([
        fromValue && moment(fromValue),
        toValue && moment(toValue),
      ]);
    } else {
      setCustomRangeValue([]);
    }
  }, [fromValue, toValue, relativeValue]);

  const onMenuItemClick = ({ key }) => {
    if (key === 'custom') {
      onChange(null);
      setIsCustomRangePickerOpen(true);
    } else {
      onChange({ relative: key });
    }
  };

  const onClear = () => {
    onChange(null);
    setCustomRangeValue([]);
    setIsCustomRangePickerOpen(false);
  };

  const onCustomRangePickerChange = (dates) => {
    if (Array.isArray(dates)) {
      setCustomRangeValue(dates);
      onChange({ from: dates[0], to: dates[1] });
      setIsCustomRangePickerOpen(false);
    } else {
      onClear();
    }
  };

  const dateDisplay = useMemo(() => {
    // No date filter
    if (!fromValue && !toValue && !relativeValue) return {};
    // Preset ranges
    if (relativeValue) {
      const { label, from, to, displayTimeInterval } =
        getPresetDateRanges().byValue[relativeValue];
      return {
        input: label,
        range: `${displayMoment(
          from,
          displayTimeInterval,
          '/'
        )} - ${displayMoment(to, displayTimeInterval, '/')}`,
      };
    }
    // Custom
    if (customRangeValue.every((val) => !!val)) {
      return {
        input: customRangeValue
          .map((val) => displayMoment(val, 'full', '/'))
          .join(' - '),
      };
    }
    if (!customRangeValue[0] && customRangeValue[1]) {
      return {
        input: `Before ${displayMoment(customRangeValue[1], 'full', '/')}`,
      };
    }
    if (customRangeValue[0] && !customRangeValue[1]) {
      return {
        input: `After ${displayMoment(customRangeValue[0], 'full', '/')}`,
      };
    }
    return {};
  }, [fromValue, toValue, customRangeValue, relativeValue]);

  const selectedMenuKeys = useMemo(() => {
    if (relativeValue) {
      return [relativeValue];
    }
    if (!fromValue && !toValue) return [];
    return ['custom'];
  }, [fromValue, toValue, relativeValue]);

  return (
    <div
      css={{
        position: 'relative',
        zIndex: isCustomRangePickerOpen ? 2 : 1,
        cursor: 'pointer',
        input: {
          cursor: 'pointer',
        },
      }}
    >
      <Dropdown
        trigger={['click']}
        disabled={disabled}
        overlay={
          <Menu selectedKeys={selectedMenuKeys} onClick={onMenuItemClick}>
            {ALL_PRESET_RANGE_VALUES.map((presetRangeValue) =>
              typeof presetRangeValue === 'string' ? (
                <Menu.Item key={presetRangeValue}>
                  {BY_PRESET_RANGE_VALUE[presetRangeValue].label}
                </Menu.Item>
              ) : (
                <Menu.SubMenu
                  key={presetRangeValue.title}
                  title={presetRangeValue.title}
                >
                  {presetRangeValue.values.map((val) => (
                    <Menu.Item key={val}>
                      {BY_PRESET_RANGE_VALUE[val].label}
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              )
            )}
          </Menu>
        }
      >
        <label>
          <Input
            readOnly
            disabled={disabled}
            prefix={<CalendarOutlined />}
            suffix={
              dateDisplay.input ? (
                <CloseCircleFilled
                  key="clear"
                  onClick={() => onClear()}
                  style={{ color: colors.inputIcon, fontSize: 12 }}
                />
              ) : (
                <DownOutlined style={{ color: colors.inputIcon }} />
              )
            }
            {...(placeholder ? { placeholder } : {})}
            value={dateDisplay.input}
            css={{
              '.ant-input-suffix i': {
                marginLeft: 6,
              },
            }}
          />
        </label>
      </Dropdown>

      <div
        css={{
          position: 'absolute',
          zIndex: showCustomPicker ? 1 : -1,
          pointerEvents: showCustomPicker ? 'auto' : 'none',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: 'hidden',
        }}
      >
        <RangePicker
          showTime={{
            defaultValue: [
              moment('00:00:00', 'HH:mm:ss'),
              moment('23:59:59', 'HH:mm:ss'),
            ],
          }}
          format="DD/MM/YYYY HH:mm:ss"
          open={isCustomRangePickerOpen}
          onOpenChange={(newOpen) => setIsCustomRangePickerOpen(newOpen)}
          value={customRangeValue}
          onChange={onCustomRangePickerChange}
          css={{ width: '100%' }}
        />
      </div>

      {dateDisplay.range && (
        <div
          css={{
            lineHeight: 'initial',
            color: colors.typography.secondary,
          }}
        >
          {dateDisplay.range}
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
