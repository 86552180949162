/** @jsxImportSource @emotion/react */
import {
  BarChart,
  BarGroup,
  BarPecentage,
  Gauge,
  SingleDataHighlight,
  LineChart,
  LineSeries,
  AreaChart,
  AreaSeries,
  PieChart,
  ListChart,
  HeatMap,
  BubbleChart,
  TreeMap,
  WordCloud,
  DoubleAxes,
  PlottedBubble,
  RadarChart,
  TableChart,
  RichTextWidget,
  ChoroplethMap,
  WaterfallChart,
} from '@components/Charts';

const ChartTypeConfig = ({ chartType, ...props }) => {
  switch (chartType) {
    case 'number':
      return <SingleDataHighlight {...props} />;
    case 'gauge':
      return <Gauge {...props} />;
    case 'bar_basic':
      return <BarChart {...props} />;
    case 'bar_grouped':
      return <BarGroup {...props} />;
    case 'bar_stackedColumn':
      return <BarGroup stack {...props} />;
    case 'bar_stackedPercentage':
      return <BarPecentage {...props} />;
    case 'line_basic':
      return <LineChart {...props} />;
    case 'line_series':
      return <LineSeries {...props} />;
    case 'area_basic':
      return <AreaChart {...props} />;
    case 'area_series':
      return <AreaSeries {...props} />;
    case 'pie_donut':
      return <PieChart dount {...props} />;
    case 'pie_innerlabel':
      return <PieChart {...props} />;
    case 'list_count':
      return <ListChart {...props} />;
    case 'heatmap':
      return <HeatMap {...props} />;
    case 'bubble':
      return <BubbleChart {...props} />;
    case 'treemap_rect':
      return <TreeMap {...props} />;
    case 'word_cloud':
      return <WordCloud {...props} />;
    case 'double_axes':
      return <DoubleAxes {...props} />;
    case 'plotted_bubble':
      return <PlottedBubble {...props} />;
    case 'radar':
      return <RadarChart {...props} />;
    case 'table':
      return <TableChart {...props} />;
    case 'rich_text':
      return <RichTextWidget {...props} />;
    case 'choropleth_map':
      return <ChoroplethMap {...props} />;
    case 'waterfall':
      return <WaterfallChart {...props} />;
    default:
      return null;
  }
};

export default ChartTypeConfig;
