/** @jsxImportSource @emotion/react */
import { preset } from 'styles';
import ChartDataTable from '@views/Chart/components/ChartDataTable'; 

const TableChart = ({ height, data, queryObj, config, schema }) => {
  const { metrics, dimensions, compare } = queryObj;

  return (
    <div
      css={{
        height,
        paddingTop: preset.spacing(2),
        paddingRight: preset.spacing(3),
        paddingLeft: 4,
        overflowY: 'auto',
      }}
    >
      <ChartDataTable
        data={data}
        metrics={metrics}
        dimensions={dimensions}
        compare={compare}
        schema={schema}
        config={config}
      />
    </div>
  );
};

export default TableChart;
