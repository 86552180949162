/** @jsxImportSource @emotion/react */
import { useState, useMemo } from 'react';
import { List, Button, Typography } from 'antd';
import isoConv from 'iso-language-converter';
import { orderBy, uniqBy } from 'lodash';

import { ExploreCard, CXToolTip } from 'components';
import { isURL, convertCountryCode, formatDateInTZ } from 'modules';
import { Dataset } from 'types';
import { preset } from 'styles';

type Props = {
  document: Record<string, any>;
  dataset: Dataset;
  rowDisplayLimit?: number;
};

type KeyDocInfoItem = {
  attribute: string | React.ReactNode;
  value: string;
  order?: number;
};

const TIMESTAMP_FIELD_NAMES = [
  'otso_doc_ingested_at',
  'created_date_time',
  'received_date_time',
  'sent_date_time',
  'Publish Date',
  'End Time',
];

const KeyDocumentInfo: React.FC<Props> = ({
  document,
  dataset,
  rowDisplayLimit = 6,
}) => {
  const [rowDisplayNo, setRowDisplayNo] = useState<number>(rowDisplayLimit);

  const {
    integrationMeta: { audio: isAudioDataset },
  } = dataset;

  // Format key document info data
  const formattedKeyDocumentInfoData: KeyDocInfoItem[] = useMemo(() => {
    const {
      name: datasetName,
      schema: { fields: schemaFields },
    } = dataset;

    const keyDocumentInfoData: KeyDocInfoItem[] = schemaFields.reduce(
      (prev: KeyDocInfoItem[], field) => {
        const { name, subtype, displayName = '', order } = field;
        let attribute: string | React.ReactNode = displayName || name;
        let value = document[name];
        if ((typeof value === 'string' && value) || typeof value === 'number') {
          if (
            [
              'otso_doc_body',
              'document_body',
              'otso_doc_publish_date',
              'document_publish_date',
            ].includes(name) ||
            (name.startsWith('otso_doc_enriched_') &&
              !name.startsWith('otso_doc_enriched_location'))
          ) {
            return prev;
          }
          // Legacy subtype/field name support
          if (subtype === 'language') {
            value = isoConv(value) || value;
          } else if (
            name === 'source_country_code' &&
            typeof value === 'string'
          ) {
            value = convertCountryCode(value);
          } else if (name === 'source_reach') {
            attribute = (
              <CXToolTip
                text="Source Reach"
                placement="rightTop"
                tip="The number of potential readers or viewers the source has."
              />
            );
          } else if (name === 'source_ave') {
            attribute = (
              <CXToolTip
                text="Source AVE"
                placement="rightTop"
                tip="Advertising Value Equivalent (AVE) assesses the monetary value of received coverage.'"
              />
            );
          } else if (
            TIMESTAMP_FIELD_NAMES.includes(name) ||
            TIMESTAMP_FIELD_NAMES.includes(displayName)
          ) {
            value = formatDateInTZ(value, 'second', '/');
          }

          return [...prev, { attribute, value, order }];
        }
        return prev;
      },
      [{ attribute: 'Dataset', value: datasetName, order: 0 }]
    );

    return uniqBy(orderBy(keyDocumentInfoData, 'order'), 'attribute');
  }, [dataset, document]);

  return (
    <ExploreCard
      title={isAudioDataset ? 'Document Metadata' : 'Key Document Info'}
    >
      <List
        split={false}
        size="small"
        dataSource={formattedKeyDocumentInfoData.slice(0, rowDisplayNo)}
        rowKey="attribute"
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta title={item.attribute} />
            <div
              css={{
                maxWidth: `${200 / 3}%`,
                textAlign: 'right',
                marginLeft: preset.spacing(2),
              }}
            >
              {isURL(item.value.toString()) ? (
                <div css={{ display: 'flex' }}>
                  <Typography.Link
                    ellipsis
                    target="_blank"
                    href={item.value.toString()}
                  >
                    {item.value.toString()}
                  </Typography.Link>
                </div>
              ) : (
                item.value.toString()
              )}
            </div>
          </List.Item>
        )}
        css={{
          '.ant-list-item': {
            display: 'flex',
            padding: '4px 0',
          },
        }}
      />
      {formattedKeyDocumentInfoData.length > rowDisplayNo && (
        <Button
          block
          type="link"
          onClick={() => setRowDisplayNo((prevNo) => prevNo + 6)}
        >
          Show More
        </Button>
      )}
    </ExploreCard>
  );
};

export default KeyDocumentInfo;
