import insertEmptyDate from './insertEmptyDate';
import deepGet from '../deepGet';
import { formatDate } from '../date/formatDate';
import formatChartData from './formatChartData';

const formatCompareChartData = (chartConfig = {}) => {
    const { data, queryObj, schema } = chartConfig;
    const { dimensions, metrics, compare } = queryObj;

    if (!compare) return { data };
    if (!Array.isArray(data)) return { data: [] };

    const chartData = data[0];
    const compareData = data[1];

    if (!Array.isArray(chartData) || !Array.isArray(compareData)) return { data: [] };

    const sampleRow = chartData[0];

    const y = metrics[0].as;

    if (dimensions.length === 0 && Object.keys(sampleRow).length === 1) {
        // No dimension charts compare
        return {
            data: [{
                ...sampleRow,
                compare: compareData.length > 0 ? Object.values(compareData[0])[0] : null,
            }],
            compareY: 'compare',
            y,
        };
    }

    // One dimension charts compare
    const x = dimensions[0].as;
    const timeInterval = deepGet(schema, [x, 'functionValue']);
    const calculatedFilters = deepGet(schema, ['calculatedFilters']) || [];

    const chartCalculatedFilters = calculatedFilters[0];
    const compareCalculatedFilters = calculatedFilters[1];

    if (timeInterval && !Array.isArray(chartCalculatedFilters)) return { data: chartData };

    let startDate;
    let endDate;
    let compareStartDate;
    let compareEndDate;

    chartCalculatedFilters.forEach(filter => {
        if (filter.isTime && filter.value) {
            if (filter.condition === '>=' || filter.condition === '>') {
                startDate = filter.value;
            } else if (filter.condition === '<=' || filter.condition === '<') {
                endDate = filter.value;
            }
        }
    });

    if (!startDate || !endDate) return { data: chartData };

    if (!Array.isArray(compareCalculatedFilters)) {
        compareStartDate = startDate;
        compareEndDate = endDate;
    } else {
        compareCalculatedFilters.forEach(filter => {
            if (filter.isTime && filter.value) {
                if (filter.condition === '>=' || filter.condition === '>') {
                    compareStartDate = filter.value;
                } else if (filter.condition === '<=' || filter.condition === '<') {
                    compareEndDate = filter.value;
                }
            }
        });
    }

    if (timeInterval) {
        const filledChartData = insertEmptyDate(chartData, x, {
            timeInterval,
            startDate,
            endDate,
            valueKey: y,
            showZero: true,
        });

        const filledCompareChartData = insertEmptyDate(compareData, x, {
            timeInterval,
            startDate: compareStartDate,
            endDate: compareEndDate,
            valueKey: y,
            showZero: true,
        });

        const newY = `${
            formatDate(startDate, timeInterval, '/', 'short')
        } - ${
            formatDate(endDate, timeInterval, '/', 'short')
        }`;
        let compareY = `${
            formatDate(compareStartDate, timeInterval, '/', 'short')
        } - ${
            formatDate(compareEndDate, timeInterval, '/', 'short')
        }`;

        compareY = (compareY === newY) ? `${compareY} Compare` : compareY;

        const combinedChartData = filledChartData.map((row, index) => {
            const compareRow = filledCompareChartData[index];
            return {
                [x]: row[x],
                [newY]: row[y],
                compareX: compareRow ? compareRow[x] : row[x],
                [compareY]: compareRow ? compareRow[y] : 0,
            };
        });

        return {
            data: formatChartData(combinedChartData, [x]),
            x,
            compareX: 'compareX',
            y: newY,
            compareY,
            compareFrom: compareStartDate,
            compareTo: compareEndDate,
        };
    }

    const chartXValues = chartData.map(row => row[x]);
    const compareXValues = compareData.map(row => row[x]);

    const combinedXValues = [...new Set([...chartXValues, ...compareXValues])];

    const combinedChartData = combinedXValues.map(xValue => {
        const matchedChartRow = chartData.find(row => row[x] === xValue);
        const matchedCompareRow = compareData.find(row => row[x] === xValue);

        return {
            [x]: xValue,
            [y]: matchedChartRow ? matchedChartRow[y] : 0,
            compareX: xValue,
            'Compare To': matchedCompareRow ? matchedCompareRow[y] : 0,
        };
    });

    return {
        data: formatChartData(combinedChartData, [x]),
        x,
        compareX: x,
        y,
        compareY: 'Compare To',
        compareFrom: compareStartDate,
        compareTo: compareEndDate,
    };
};

export default formatCompareChartData;
