/** @jsxImportSource @emotion/react */
import { useReducer, useMemo } from 'react';

import { Form, Select, Button } from 'antd';

import formatLabel from '@modules/formatLabel';
import {
  getCategorisedSchemaFields,
  getFilterInputGroupConfig,
  ENTITIES_SCHEMA_FIELDS,
  getFormatRequiredAttributes,
} from '@modules/filterBarHelpers';

import { initialState, newFilterReducer } from './reducer';
import FilterValueField from '../FilterValueField';

const { Option, OptGroup } = Select;

const NewFilterForm = ({
  filterOptions = {},
  sharedSchemaFields = [],
  datasetIds = [],
  onNewFilterApply,
  hideFilterApply = false,
}) => {
  const [newFilter, dispatch] = useReducer(newFilterReducer, initialState);

  const { attribute, condition, value } = newFilter;

  // Group and format schema fields for attribute select dropdown
  const categorisedSchemaFields = useMemo(
    () =>
      getCategorisedSchemaFields(sharedSchemaFields, {
        includingTime: false,
      }),
    [sharedSchemaFields]
  );

  // Get list of field names that need filterOption value formatted like 'channel', 'source', 'language'
  const formatRequiredAttributes = useMemo(
    () => getFormatRequiredAttributes(categorisedSchemaFields),
    [categorisedSchemaFields]
  );

  const valueFormat = (val) => {
    if (!attribute || formatRequiredAttributes.length === 0) {
      return null;
    }

    const formatAttributeObj = formatRequiredAttributes.find(
      (obj) => obj.name === attribute
    );
    return formatAttributeObj ? formatAttributeObj.format(val) : null;
  };

  // Get formItemType, conditionOptions, searchKey etc for each filter.
  const {
    conditionOptions = [],
    formItemType,
    searchKey,
    displayName,
  } = useMemo(() => {
    const selectedField = sharedSchemaFields.find(
      (field) => field.name === attribute
    );
    return getFilterInputGroupConfig(selectedField);
  }, [attribute, sharedSchemaFields]);

  // Get value select options for fields like 'channel', 'source', 'language'
  const valueOptions = useMemo(() => {
    const isEntityField = ENTITIES_SCHEMA_FIELDS.find(
      (entitySchemaField) => entitySchemaField.name === attribute
    );
    return isEntityField
      ? filterOptions.entity[attribute]
      : filterOptions.document[attribute];
  }, [attribute, filterOptions.document, filterOptions.entity]);

  const onApply = () => {
    onNewFilterApply(newFilter);
    dispatch({ type: 'RESET' });
  };

  return (
    <Form
      layout="vertical"
      css={{
        '.ant-form-item': {
          marginBottom: 12,
          minWidth: 304,
          maxWidth: 344,
          '&:last-of-type': {
            marginBottom: 0,
          },
        },
      }}
    >
      <Form.Item label="Attribute">
        <Select
          showSearch
          placeholder="Select filter attribute"
          optionFilterProp="children"
          loading={filterOptions.loading}
          value={attribute}
          onChange={(val) =>
            dispatch({
              type: 'SET_VALUE',
              key: 'attribute',
              value: val,
            })
          }
        >
          {Object.keys(categorisedSchemaFields).map((optionGroupKey) => (
            <OptGroup key={optionGroupKey} label={formatLabel(optionGroupKey)}>
              {categorisedSchemaFields[optionGroupKey].map((field) => (
                <Option key={field.name} value={field.name}>
                  {field.displayName || formatLabel(field.name)}
                </Option>
              ))}
            </OptGroup>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Condition">
        <Select
          showSearch
          placeholder="Select filter condition"
          optionFilterProp="children"
          loading={filterOptions.loading}
          value={condition}
          onChange={(val) =>
            dispatch({
              type: 'SET_VALUES',
              values: {
                value: val === 'between' ? {} : undefined,
                condition: val,
              },
            })
          }
        >
          {conditionOptions.map((conditionOption) => (
            <Option key={conditionOption.value} value={conditionOption.value}>
              {conditionOption.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Value">
        <FilterValueField
          attribute={attribute}
          condition={condition}
          value={value}
          onChange={(val) =>
            dispatch({
              type: 'SET_VALUE',
              key: 'value',
              value: val,
            })
          }
          config={{
            formItemType,
            valueOptions,
            datasetIds,
            searchKey,
            valueFormat: (val) => valueFormat(val),
            attributeDisplay: displayName,
          }}
        />
      </Form.Item>
      {!hideFilterApply && (
        <Form.Item>
          <Button block type="primary" onClick={onApply}>
            Apply
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};

export default NewFilterForm;
