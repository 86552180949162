/** @jsxImportSource @emotion/react */
import { Responsive, WidthProvider } from 'react-grid-layout';

import getGridLayout from '@modules/getGridLayout';

import Container from '@components/Container';
import NoAPIChartCard from '@components/NoAPIChartCard';
import Empty from '@components/Empty';

const ResponsiveGridLayout = WidthProvider(Responsive);

const DashboardCharts = ({
  isPrintingDashboard = false,
  dashboardCharts,
  currentPageId,
  pages,
  isLayoutLocked = true,
  canDrilldownData = false,
}) => {
  const getChartsByPageId = (pageId) => {
    return dashboardCharts.filter(({ page: { id } }) => id === pageId);
  };

  const { gridRowHeight, gridWidth, gridMargin, getGridUnitHeight } =
    getGridLayout();

  return pages.map((page) => {
    const isCurrentPage = currentPageId === page.id;
    const currentPageCharts = getChartsByPageId(page.id);

    return currentPageCharts.length > 0 ? (
      <Container
        key={`page_${page.id}`}
        fullWidth
        css={{ display: isCurrentPage ? 'block' : 'none' }}
      >
        <ResponsiveGridLayout
          key={page.id}
          cols={{ lg: 12 }}
          breakpoints={{ lg: 1200 }}
          rowHeight={gridRowHeight}
          width={gridWidth}
          margin={[gridMargin, gridMargin]}
          containerPadding={[0, 0]}
          draggableHandle=".draggable"
        >
          {currentPageCharts.map((dashboardChart) => {
            const { chartType, data } = dashboardChart.visualisation;
            const isRichTextWidget = chartType === 'rich_text';
            return (
              <div
                key={dashboardChart.id}
                data-grid={dashboardChart.layout}
                className={isLayoutLocked ? 'disabled' : 'draggable'}
              >
                <NoAPIChartCard
                  id={dashboardChart.visualisation.id}
                  dashboardChartId={dashboardChart.id}
                  width={dashboardChart.layout.w}
                  chart={dashboardChart.visualisation}
                  height={getGridUnitHeight(dashboardChart.layout.h)}
                  // filters={loadedPage.activeFilters.filters}
                  // timeFilter={loadedPage.activeFilters.timeFilter || null}
                  // refreshAt={loadedPage.chartsRefreshAt}
                  comments={dashboardChart.comments}
                  drillDownDisabled={!canDrilldownData}
                  actions={[]}
                  {...(isRichTextWidget &&
                  Array.isArray(data) &&
                  data.length > 0 &&
                  data[0].html
                    ? {
                        key: data[0].html,
                      }
                    : {})}
                />
              </div>
            );
          })}
        </ResponsiveGridLayout>
      </Container>
    ) : (
      <Empty
        key={`emptyPage_${page.id}`}
        description={<span>This dashboard has no charts currently</span>}
        css={{
          display: currentPageId === page.id ? 'flex' : 'none',
        }}
      />
    );
  });
};

export default DashboardCharts;
