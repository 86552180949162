/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Modal, Input, message } from 'antd';

import { postApi, fetchApiErrorMessage } from '@modules/api';

const NewSavedFilterNameModal = ({
    visible = false,
    onCancel,
    getExportedFilters,
    // redux dispatch
    dispatch,
}) => {
    const { slug: projectSlug, dashboardSlug } = useParams();

    const [filterName, setFilterName] = useState();

    const onNewFilterNameConfirm = async () => {
        try {
            const { data: newFilter } = await postApi(
                `projects/${projectSlug}/dashboards/${dashboardSlug}/filters`,
                {
                    filter: {
                        filterConfig: getExportedFilters(),
                        name: filterName,
                    },
                },
            );

            setFilterName();
            onCancel();
            dispatch({
                type: 'ADD_DASHBOARD_SAVED_FILTER',
                newFilter,
            });
            message.success('Dashboard Filter Saved');
        } catch (error) {
            message.error(fetchApiErrorMessage(error, 'Unable to Save New Dashboard Filter'));
        }
    };

    return (
        <Modal
            title="Save New Filter"
            visible={visible}
            okText="Save"
            onOk={onNewFilterNameConfirm}
            onCancel={onCancel}
            destroyOnClose
        >
            <Input
                autoFocus
                placeholder="New Filter Name"
                value={filterName}
                onChange={e => setFilterName(e.target.value)}
            />
        </Modal>
    );
};

export default connect()(NewSavedFilterNameModal);
