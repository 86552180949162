/** @jsxImportSource @emotion/react */
import React, { useRef, useCallback, useEffect, useState } from 'react';
import { Collapse, Space, Divider, Typography } from 'antd';
import { useInView } from 'react-intersection-observer';

import { Dataset, Filter, TimeFilter } from 'types';
import { trackEvent } from 'analytics';
import { colors, preset } from 'styles';
import { formatDateInTZ } from 'modules';
import { AudioPlayer } from 'components';
import CustomClassifierTags from './CustomClassifierTags';
import AudioDocExpanded from './AudioDocExpanded';

type Props = {
  document: Record<string, any>;
  dataset: Dataset;
  appliedFilters?: { filters?: Filter[]; timeFilter?: TimeFilter };
  hasEnrichmentCorrection: boolean;
  expandedDocumentId?: string;
};

const AudioDocCollapse: React.FC<Props> = ({
  document,
  dataset,
  expandedDocumentId,
  hasEnrichmentCorrection,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  appliedFilters,
}) => {
  const [isInDebounceView, setIsInDebounceView] = useState<boolean>(false);

  const hasUtterances = Array.isArray(document.utterances);

  const audioSummary = document.otso_doc_body;

  const audioPlayerRef = useRef<HTMLAudioElement>(null);

  const [audioPlayerContainerRef, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsInDebounceView(inView);
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, [inView]);

  useEffect(() => {
    audioPlayerRef.current?.addEventListener('play', (e) => {
      const audioPlayerElements: NodeListOf<HTMLAudioElement> =
        window.document.querySelectorAll('audio[src]:not([src=""])');
      audioPlayerElements.forEach((audioEle) => {
        if (audioEle !== e.target && !audioEle.paused) {
          audioEle?.pause();
        }
      });
    });
  }, []);

  const getAudioPlayer = useCallback((): HTMLAudioElement | null => {
    return audioPlayerRef.current;
  }, []);

  return (
    <Collapse
      defaultActiveKey={expandedDocumentId}
      onChange={(expanded) => {
        trackEvent('Toggle Document Collapse', {
          toggleType: expanded.length > 0 ? 'Show' : 'Hide',
          documentId: document.id,
        });
      }}
      css={{
        '& > .ant-collapse-item > .ant-collapse-header': {
          position: 'sticky',
          zIndex: 2,
          top: 0,
          backgroundColor: colors.grey,
        },
      }}
    >
      <Collapse.Panel
        key={document.id}
        showArrow={false}
        header={
          <div css={{ paddingLeft: 12, paddingRight: 12 }}>
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: preset.spacing(2),
              }}
            >
              <Space split={<Divider type="vertical" />}>
                <Typography.Text type="secondary" strong>
                  {dataset.name}
                </Typography.Text>
                <Typography.Text type="secondary">
                  {formatDateInTZ(
                    document.otso_doc_publish_date,
                    'minute',
                    '/'
                  )}
                </Typography.Text>
              </Space>

              <CustomClassifierTags
                document={document}
                hasEnrichmentCorrection={hasEnrichmentCorrection}
                dataset={dataset}
                css={{
                  flex: 1,
                  marginLeft: preset.spacing(5),
                  textAlign: 'right',
                  '.ant-space': { justifyContent: 'flex-end' },
                }}
              />
            </div>

            {hasUtterances && audioSummary && (
              <div
                css={{ '.ant-typography': { marginBottom: preset.spacing(2) } }}
              >
                <Typography.Paragraph ellipsis={{ rows: 4, expandable: true }}>
                  {audioSummary}
                </Typography.Paragraph>
              </div>
            )}

            <div
              ref={audioPlayerContainerRef}
              css={{ marginBottom: preset.spacing(1) }}
            >
              <AudioPlayer
                ref={audioPlayerRef}
                inView={isInDebounceView}
                src={document.source_file}
              />
            </div>
          </div>
        }
      >
        <AudioDocExpanded
          document={document}
          dataset={dataset}
          getAudioPlayer={getAudioPlayer}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

export default AudioDocCollapse;
