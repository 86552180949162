/* eslint-disable camelcase */
/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Select, Tooltip, Button } from 'antd';

import { trackEvent } from 'analytics';
import { colors, preset } from 'styles';
import ExploreCard from '@components/ExploreCard';
import Webvtt from '@components/WebVTT';

const { Paragraph } = Typography;

const DocumentBody = ({ verbatim, link, isDialogue, entities }) => {
  const [displayType, setDisplayType] = useState('none');

  useEffect(() => {
    if (entities && entities.length > 0) setDisplayType('sentence');
  }, [entities]);

  const entityHighlights = [];
  const sentenceHighlights = [];

  const filteredEntities =
    entities && entities.length > 0
      ? entities.filter(
          (entity) =>
            entity.start_char &&
            entity.end_char &&
            entity.embedding_sentence &&
            entity.entity_lemma
        )
      : [];

  const orderedEntities = filteredEntities.sort((a, b) => {
    return a.start_char - b.start_char;
  });

  const uniqueEmbeddingSentences = [];

  entityHighlights.push({
    text: verbatim.slice(
      0,
      orderedEntities[0] ? orderedEntities[0].start_char : verbatim.length
    ),
  });
  orderedEntities.forEach((entity, index) => {
    const { embedding_sentence } = entity;

    if (embedding_sentence) {
      // Get unique embedding sentences with the full sentence start and end char
      if (
        !uniqueEmbeddingSentences.find(
          (sentence) => sentence.embedding_sentence === embedding_sentence
        )
      ) {
        const io = verbatim.indexOf(embedding_sentence);
        uniqueEmbeddingSentences.push({
          ...entity,
          start_char: io,
          end_char: io + embedding_sentence.length,
        });
      }
    }

    entityHighlights.push({
      text: verbatim.slice(entity.start_char, entity.end_char),
      entityType: entity.entity_type,
      entitySentiment: entity.entity_sentiment_label,
      isEntity: true,
      start_char: entity.start_char,
      end_char: entity.end_char,
    });

    const nextEntity = orderedEntities[index + 1];

    entityHighlights.push({
      text: verbatim.slice(
        entity.end_char,
        nextEntity ? nextEntity.start_char : verbatim.length
      ),
    });
  });

  sentenceHighlights.push({
    text: verbatim.slice(
      0,
      uniqueEmbeddingSentences[0]
        ? uniqueEmbeddingSentences[0].start_char
        : verbatim.length
    ),
  });
  uniqueEmbeddingSentences.forEach((sentence, index) => {
    sentenceHighlights.push({
      text: verbatim.slice(sentence.start_char, sentence.end_char),
      sentenceSentiment: sentence.entity_sentiment_label,
      isSentence: true,
    });

    const nextSentence = uniqueEmbeddingSentences[index + 1];

    sentenceHighlights.push({
      text: verbatim.slice(
        sentence.end_char,
        nextSentence ? nextSentence.start_char : verbatim.length
      ),
    });
  });

  const regularContent = () => {
    const entityHighlight = (entity) => {
      return (
        <Tooltip
          key={`${entity.text}${entity.start_char}`}
          title={entity.entityType || 'keyword'}
        >
          <span
            css={{
              // rrggbbaa
              backgroundColor:
                `${colors.sentiment[entity.entitySentiment]}99` || 'grey',
              fontWeight: 'bold',
              borderRadius: 5,
              padding: '0px 5px',
            }}
          >
            {entity.text}
          </span>
        </Tooltip>
      );
    };

    const sentenceHighlight = (sentence) => {
      return (
        <span
          key={sentence.text}
          css={{
            // rrggbbaa
            backgroundColor:
              `${colors.sentiment[sentence.sentenceSentiment]}99` || 'grey',
            fontWeight: 'bold',
            borderRadius: 5,
            padding: '0px 5px',
          }}
        >
          {sentence.text}
        </span>
      );
    };

    return (
      <Paragraph
        ellipsis={{
          rows: 4,
          expandable: true,
        }}
        css={{ whiteSpace: 'pre-line' }}
      >
        {displayType === 'entities'
          ? entityHighlights.map((highlight) => {
              if (highlight.isEntity) {
                return entityHighlight(highlight);
              }
              return highlight.text;
            })
          : displayType === 'sentence'
          ? sentenceHighlights.map((highlight) => {
              if (highlight.isSentence) {
                return sentenceHighlight(highlight);
              }
              return highlight.text;
            })
          : verbatim}
      </Paragraph>
    );
  };

  return (
    <ExploreCard
      title="Document Body"
      extra={
        entities &&
        entities.length > 0 && (
          <Select
            value={displayType}
            onChange={(val) => setDisplayType(val)}
            dropdownMatchSelectWidth={false}
          >
            <Select.Option value="entities">Entities</Select.Option>
            <Select.Option value="sentence">Sentence</Select.Option>
            <Select.Option value="none">None</Select.Option>
          </Select>
        )
      }
    >
      <div>
        {link ? (
          <Button
            type="link"
            href={link}
            target="_blank"
            onClick={() => trackEvent('Go To Original', { link })}
            css={{ marginLeft: -preset.spacing(2) }}
          >
            Go to original
          </Button>
        ) : null}
        {isDialogue ? <Webvtt content={verbatim} /> : regularContent()}
      </div>
    </ExploreCard>
  );
};

DocumentBody.propTypes = {
  verbatim: PropTypes.string,
  link: PropTypes.string,
  isDialogue: PropTypes.bool,
  entities: PropTypes.array,
};

DocumentBody.defaultProps = {
  verbatim: null,
  link: null,
  isDialogue: false,
  entities: [],
};

export default DocumentBody;
