/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import shortid from 'shortid';

import { Table } from 'antd';

import formatDimensionLabel from '@modules/chart/formatDimensionLabel';
import formatMetricLabel from '@modules/chart/formatMetricLabel';
import formatChartData from '@modules/chart/formatChartData';
import formatCompareChartData from '@modules/chart/formatCompareChartData';
import isNumber from '@modules/lib/isNumber';
import deepGet from '@modules/deepGet';

const ChartDataTable = ({
    loading = false,
    data = [],
    metrics = [],
    dimensions = [],
    compare,
    config = {},
    schema,
}) => {
    const labelConfig = config.label;
    const rowsPerPage = isNumber(config.rowsPerPage) ? config.rowsPerPage : 10;

    const compareChartConfig = useMemo(() => {
        return compare ? formatCompareChartData({
            data,
            queryObj: { dimensions, metrics, compare },
            schema,
        }) : {};
    }, [compare, dimensions, metrics, data, schema]);
    const isCompareChart = !!(compare
        && compareChartConfig.y
        && compareChartConfig.compareY);

    const formattedData = useMemo(
        () => formatChartData(data, dimensions.map(dimension => dimension.as)),
        [data, dimensions],
    );

    const columns = useMemo(() => {
        const dimensionCols = dimensions.map((dimension, index) => ({
            title: dimension.as,
            dataIndex: dimension.as,
            render: val => formatDimensionLabel(
                val,
                {
                    timeInterval: deepGet(schema, [dimension.as, 'functionValue']),
                    labelConfig,
                    dimensionIndex: index,
                    dimensionKey: dimension.as,
                },
            ),
        }));

        const metricCols = metrics.map(metric => ({
            title: metric.as,
            dataIndex: metric.as,
            render: val => formatMetricLabel(val),
        }));

        if (!isCompareChart) return [...dimensionCols, ...metricCols];

        if (dimensions.length === 0) {
            return [
                ...dimensionCols,
                ...metricCols,
                {
                    title: 'Compare To',
                    dataIndex: compareChartConfig.compareY || 'compare',
                    render: val => formatMetricLabel(val),
                },
            ];
        }

        if (!compareChartConfig.y || !compareChartConfig.compareY) {
            return [...dimensionCols, ...metricCols];
        }

        return [
            ...dimensionCols,
            {
                title: compareChartConfig.y,
                dataIndex: compareChartConfig.y,
                render: val => formatMetricLabel(val),
            },
            {
                title: 'Compare To',
                dataIndex: compareChartConfig.compareX || 'compareX',
                render: val => formatDimensionLabel(
                    val,
                    {
                        timeInterval: deepGet(schema, [compareChartConfig.x, 'functionValue']),
                        labelConfig,
                    },
                ),
            },
            {
                title: compareChartConfig.compareY,
                dataIndex: compareChartConfig.compareY,
                render: val => formatMetricLabel(val),
            },
        ];
    }, [dimensions, metrics, labelConfig, isCompareChart, schema, compareChartConfig]);

    return (
        <Table
            loading={loading}
            size="small"
            dataSource={compare ? compareChartConfig.data : formattedData}
            columns={columns}
            rowKey={() => shortid.generate()}
            pagination={{
                pageSize: rowsPerPage,
                size: 'small',
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
        />
    );
};

export default ChartDataTable;
