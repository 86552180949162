import { useState, useEffect, useMemo } from 'react';
import { message } from 'antd';

import { getApi, fetchApiErrorMessage } from '../api';

const useGetApi = <T>(url: string, initData: T = [] as any, params?: any) => {
  const [data, setData] = useState<T>(initData);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  const stringifiedParams = useMemo(
    () => JSON.stringify(params || {}),
    [params]
  );

  useEffect(() => {
    // This is used for aborting state transition if the component is unmounted
    let didCancel = false;

    const fetchData = async () => {
      setLoading(true);
      try {
        const queryParams = JSON.parse(stringifiedParams);

        const res = await getApi(url, queryParams);

        if (!didCancel) {
          setData(res.data);
        }
      } catch (err) {
        setError(err);
        message.error(fetchApiErrorMessage(err));
      }
      if (!didCancel) {
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      didCancel = true;
    };
  }, [url, stringifiedParams]);

  return {
    data,
    setData,
    loading,
    error,
  };
};

export default useGetApi;
