/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Card } from 'antd';
import { CheckCircleFilled, CheckCircleOutlined } from '@ant-design/icons';

import { colors } from 'styles';

const ValidationFormContainer = ({
  children,
  title = '',
  valid = false,
  className,
}) => {
  return (
    <Card
      title={title}
      extra={
        valid ? (
          <CheckCircleFilled css={{ fontSize: 22, color: colors.primary }} />
        ) : (
          <CheckCircleOutlined css={{ fontSize: 22 }} />
        )
      }
      className={className}
    >
      {children}
    </Card>
  );
};

ValidationFormContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  valid: PropTypes.bool,
  className: PropTypes.string,
};

export default ValidationFormContainer;
