/** @jsxImportSource @emotion/react */

import { Collapse, Row, Col, Form, Switch } from 'antd';

import { preset } from 'styles';
import ColorPicker from '@components/ColorPicker';
import ColorPanel from './ColorPanel';
import RangeColorPanel from './RangeColorPanel';
import LabelPanel from './LabelPanel';
import AxisPanel from './AxisPanel';
import StyledCollapse from '../../StyledCollapse';
import ColorBySelect from './ColorBySelect';

const { Panel } = Collapse;
const FormItem = Form.Item;
const ColorPanelBody = ColorPanel.Body;
const RangeColorPanelBody = RangeColorPanel.Body;

const PlottedBubbleConfig = ({ chart, dispatch }) => {
  const { defaultConfig = {}, defaultColor = {} } = chart;

  const baselineConfig = defaultConfig.baseline || {};

  const colorByOptions = ['name', 'x', 'y', 'z'].map((configKey) => ({
    key: defaultConfig[configKey],
    label: defaultConfig[configKey],
  }));

  const colorByMetric = [
    defaultConfig.x,
    defaultConfig.y,
    defaultConfig.z,
  ].includes(defaultConfig.colorBy);

  return (
    <StyledCollapse>
      <AxisPanel
        defaultConfig={defaultConfig}
        dispatch={dispatch}
        options={{
          canCustomiseLabel: false,
          canReverse: false,
          rangeKeys: ['x', 'y'],
        }}
      />

      <LabelPanel chart={chart} dispatch={dispatch} />

      <Panel key="bubble" header="Bubble">
        <Row gutter={preset.spacing(3)}>
          <Col span={12}>
            <FormItem label="Minified">
              <Switch
                checked={defaultConfig.minified}
                onChange={(checked) =>
                  dispatch({
                    type: 'SET_CONFIG',
                    value: checked,
                    key: 'minified',
                  })
                }
              />
            </FormItem>
          </Col>
        </Row>
      </Panel>

      <Panel key="baseline" header="Baseline">
        <Row gutter={preset.spacing(3)}>
          {Object.keys(baselineConfig).map((baselineKey) => (
            <Col key={baselineKey} span={12}>
              <FormItem
                label={`${baselineKey.toUpperCase()} baseline`}
                css={{
                  '.ant-form-item-control-input-content': {
                    display: 'flex',
                    alignItems: 'center',
                    '.ant-switch': {
                      marginRight: 12,
                    },
                  },
                }}
              >
                <Switch
                  checked={baselineConfig[baselineKey].display}
                  onChange={(checked) =>
                    dispatch({
                      type: 'SET_CONFIG',
                      value: {
                        ...baselineConfig[baselineKey],
                        display: checked,
                      },
                      key: 'baseline',
                      childKey: baselineKey,
                    })
                  }
                />
                {baselineConfig[baselineKey].display && (
                  <ColorPicker
                    color={baselineConfig[baselineKey].color}
                    onColorChange={(color) =>
                      dispatch({
                        type: 'SET_CONFIG',
                        value: {
                          ...baselineConfig[baselineKey],
                          color: color.hex,
                        },
                        key: 'baseline',
                        childKey: baselineKey,
                      })
                    }
                  />
                )}
              </FormItem>
            </Col>
          ))}
        </Row>
      </Panel>

      <Panel key="chart_color" header="Chart Colour">
        <ColorBySelect
          options={colorByOptions}
          value={defaultConfig.colorBy}
          onChange={(val) =>
            dispatch({
              type: 'SET_CONFIG',
              value: val,
              key: 'colorBy',
            })
          }
        />

        {colorByMetric ? (
          <RangeColorPanelBody
            defaultConfig={defaultConfig}
            defaultColor={defaultColor}
            dispatch={dispatch}
          />
        ) : (
          <ColorPanelBody chart={chart} dispatch={dispatch} />
        )}
      </Panel>
    </StyledCollapse>
  );
};

export default PlottedBubbleConfig;
