import { CHART_TYPES } from 'constant';
import { defaultQueryObj } from '../../reducer';

const { byType } = CHART_TYPES;

export const initialState = {
  chartType: undefined,
  queryObj: defaultQueryObj,
  isCompareModalOpen: false,
  editingCompareQueryObj: { compareType: 'previous_period' },
};

export const chartSetupFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_CHART_SETUP':
      return {
        ...state,
        ...action.chart,
      };

    case 'SET_CHART_TYPE': {
      const newChartType = action.value;

      const { maxMetrics, maxDimensions, noDimension } = byType[newChartType];

      const { metrics = [], dimensions = [] } = state.queryObj;

      let newMetrics = metrics;
      let newDimensions = dimensions;

      if (metrics.length === 0) {
        newMetrics = [{}];
      } else if (metrics.length > maxMetrics) {
        newMetrics = metrics.slice(metrics.length - maxMetrics);
      }

      if (dimensions.length === 0 && maxDimensions > 0 && !noDimension) {
        newDimensions = [{}];
      } else if (dimensions.length > maxDimensions) {
        newDimensions = dimensions.slice(dimensions.length - maxDimensions);
      }

      return {
        ...state,
        chartType: newChartType,
        queryObj: {
          ...state.queryObj,
          metrics: newMetrics,
          dimensions: newDimensions,
        },
      };
    }

    case 'SET_QUERYOBJ_VALUE':
      return {
        ...state,
        queryObj: {
          ...state.queryObj,
          [action.key]: action.value,
        },
      };

    case 'SET_QUERYOBJ_ARRAY_VALUE':
      return {
        ...state,
        queryObj: {
          ...state.queryObj,
          [action.key]: state.queryObj[action.key].map((item, index) =>
            index === action.index
              ? {
                  ...item,
                  [action.itemKey]: action.value,
                }
              : item
          ),
        },
      };

    case 'ADD_QUERYOBJ_ARRAY_VALUE':
      return {
        ...state,
        queryObj: {
          ...state.queryObj,
          [action.key]: [...state.queryObj[action.key], action.value],
        },
      };

    case 'REMOVE_QUERYOBJ_ARRAY_VALUE':
      return {
        ...state,
        queryObj: {
          ...state.queryObj,
          [action.key]: [
            ...state.queryObj[action.key].slice(0, action.index),
            ...state.queryObj[action.key].slice(action.index + 1),
          ],
        },
      };

    case 'ON_AS_FIELD_CHANGE': {
      const {
        oldAs,
        newAs,
        isTimeDimension = false,
        orderByOnly = false,
      } = action;

      const { dimensions = [], groupBy = [] } = state.queryObj;

      let newDimensions = dimensions;
      let newGroupBy = groupBy;
      // let newOrderBy = orderBy;

      if (newAs) {
        if (!orderByOnly) {
          // New Group By Config
          const isGroupByExisted = !!groupBy.find(
            (groupByItem) => groupByItem === newAs
          );

          if (isGroupByExisted) {
            if (oldAs) {
              newGroupBy = groupBy.filter(
                (groupByItem) => groupByItem !== oldAs
              );
            }
          } else if (oldAs) {
            newGroupBy = groupBy.map((groupByItem) =>
              groupByItem === oldAs ? newAs : groupByItem
            );
          } else {
            newGroupBy = [...groupBy, newAs];
          }
        }

        // New Order By Config
        const isOrderByExisted = !!dimensions
          .map((dimension) => dimension.orderBy)
          .find((attribute) => attribute === newAs);
        // .find((orderByObj) => orderByObj.attribute === newAs);

        if (isOrderByExisted) {
          if (oldAs) {
            newDimensions = dimensions.map((dimension) => ({
              ...dimension,
              ...(dimension.orderBy === oldAs
                ? { orderBy: undefined, orderByDirection: undefined }
                : {}),
            }));
            // newOrderBy = orderBy.filter(
            //   (orderByObj) => orderByObj.attribute !== oldAs
            // );
          }
        } else if (oldAs) {
          newDimensions = dimensions.map((dimension) => ({
            ...dimension,
            ...(dimension.orderBy === oldAs ? { orderBy: newAs } : {}),
          }));
          // newOrderBy = orderBy.map((orderByObj) =>
          //   orderByObj.attribute === oldAs
          //     ? {
          //         ...orderByObj,
          //         attribute: newAs,
          //       }
          //     : orderByObj
          // );
        } else if (isTimeDimension) {
          // newDimensions = dimensions.map((dimension) => ({
          //   ...dimension,
          //   ...(dimension.as === newAs
          //     ? { orderBy: newAs, orderByDirection: 'asc' }
          //     : {}),
          // }));
          // newOrderBy = [...orderBy, { attribute: newAs, direction: 'asc' }];
        }
      } else if (oldAs) {
        if (!orderByOnly) {
          newGroupBy = groupBy.filter((groupByItem) => groupByItem !== oldAs);
        }
        newDimensions = dimensions.map((dimension) => ({
          ...dimension,
          ...(dimension.orderBy === oldAs
            ? { orderBy: undefined, orderByDirection: undefined }
            : {}),
        }));
        // newOrderBy = orderBy.filter(
        //   (orderByObj) => orderByObj.attribute !== oldAs
        // );
      }

      return {
        ...state,
        queryObj: {
          ...state.queryObj,
          dimensions: newDimensions,
          groupBy: newGroupBy,
          // orderBy: newOrderBy,
        },
      };
    }

    // Compare actions
    case 'ON_COMPARE_MODAL_OPEN':
      return {
        ...state,
        isCompareModalOpen: true,
        editingCompareQueryObj:
          state.queryObj.compare || initialState.editingCompareQueryObj,
      };

    case 'ON_COMPARE_MODAL_CANCEL':
      return {
        ...state,
        isCompareModalOpen: false,
        editingCompareQueryObj: initialState.editingCompareQueryObj,
      };

    case 'ON_COMPARE_SAVE':
      return {
        ...state,
        isCompareModalOpen: false,
        editingCompareQueryObj: initialState.editingCompareQueryObj,
        queryObj: {
          ...state.queryObj,
          compare: state.editingCompareQueryObj,
        },
      };

    case 'ON_COMPARE_REMOVE':
      return {
        ...state,
        isCompareModalOpen: false,
        editingCompareQueryObj: initialState.editingCompareQueryObj,
        queryObj: {
          ...state.queryObj,
          compare: null,
        },
      };

    case 'SET_COMPARE_QUERYOBJ_VALUE':
      return {
        ...state,
        editingCompareQueryObj:
          action.key === 'compareType'
            ? {
                compareType: action.value,
              }
            : {
                ...state.editingCompareQueryObj,
                [action.key]: action.value,
              },
      };

    case 'SET_COMPARE_QUERYOBJ_ARRAY_VALUE': {
      const { editingCompareQueryObj, queryObj } = state;
      const compareQueryObjArrayValue =
        editingCompareQueryObj[action.key] || queryObj[action.key];

      return {
        ...state,
        editingCompareQueryObj: {
          ...editingCompareQueryObj,
          [action.key]: compareQueryObjArrayValue.map((item, index) =>
            index === action.index
              ? {
                  ...item,
                  [action.itemKey]: action.value,
                }
              : item
          ),
        },
      };
    }

    case 'ADD_COMPARE_QUERYOBJ_ARRAY_VALUE': {
      const { editingCompareQueryObj, queryObj } = state;
      const compareQueryObjArrayValue =
        editingCompareQueryObj[action.key] || queryObj[action.key];

      return {
        ...state,
        editingCompareQueryObj: {
          ...editingCompareQueryObj,
          [action.key]: [...compareQueryObjArrayValue, action.value],
        },
      };
    }

    case 'REMOVE_COMPARE_QUERYOBJ_ARRAY_VALUE': {
      const { editingCompareQueryObj, queryObj } = state;
      const compareQueryObjArrayValue =
        editingCompareQueryObj[action.key] || queryObj[action.key];

      return {
        ...state,
        editingCompareQueryObj: {
          ...editingCompareQueryObj,
          [action.key]: [
            ...compareQueryObjArrayValue.slice(0, action.index),
            ...compareQueryObjArrayValue.slice(action.index + 1),
          ],
        },
      };
    }

    default:
      return state;
  }
};
