/** @jsxImportSource @emotion/react */
import { useContext, useMemo } from 'react';
import { Radio } from 'antd';

import { ProjectContext } from '@providers/ProjectProvider';
import { getLocationPageMetrics } from '@modules/filterBarHelpers';

const MetricsSelect = ({
    datasetIds = [],
    metrics = [],
    onMetricsChange,
}) => {
    const {
        project: { projectDatasetsById },
    } = useContext(ProjectContext);

    const metricList = useMemo(() => {
        const selectedDatasets = datasetIds.reduce((prev, datasetId) => (
            projectDatasetsById[datasetId] ? [...prev, projectDatasetsById[datasetId]] : []
        ), []);
        return getLocationPageMetrics(selectedDatasets);
    }, [datasetIds, projectDatasetsById]);

    const onMetricSelect = newVal => {
        const newMetric = metricList.find(metric => metric.as === newVal);
        if (newMetric) {
            onMetricsChange([newMetric]);
        }
    };

    const selectedMetric = metrics.length > 0 && metrics[0].as;

    return (
        <Radio.Group
            buttonStyle="solid"
            value={selectedMetric}
            onChange={e => onMetricSelect(e.target.value)}
        >
            {metricList.map(metric => (
                <Radio.Button key={metric.as} value={metric.as}>
                    {metric.as}
                </Radio.Button>
            ))}
        </Radio.Group>
    );
};

export default MetricsSelect;
