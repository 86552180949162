/** @jsxImportSource @emotion/react */

import { Row, Col, InputNumber, Select, Input } from 'antd';

import { preset } from 'styles';
import { postApi } from '@modules/api';
import formatLabel from '@modules/formatLabel';
import deepGet from '@modules/deepGet';

import MulitSelectWithSearch from '../../MulitSelectWithSearch';

const { Option } = Select;

const FilterValueField = ({
  loading = false,
  attribute,
  condition,
  value,
  onChange,
  config = {},
}) => {
  const {
    formItemType,
    valueOptions = [],
    datasetIds = [],
    searchKey,
    valueFormat,
    attributeDisplay,
    selectProps = {},
  } = config;

  const onEntityLemmaSearch = async (q) => {
    if (searchKey) {
      const res = await postApi('datasets/entity-value', {
        requestedFields: [searchKey],
        datasets: datasetIds,
        filters: { [searchKey]: { [`${searchKey}Query`]: q } },
      });
      return deepGet(res, ['data', searchKey]) || [];
    }
    return [];
  };

  const isValueFieldDisabled = ['is null', 'is not null'].includes(condition);

  if (condition === 'between') {
    return (
      <Row gutter={preset.spacing(2)}>
        <Col span={12}>
          <InputNumber
            placeholder="From"
            value={value.from}
            onChange={(newVal) =>
              onChange({
                ...value,
                from: newVal,
              })
            }
          />
        </Col>
        <Col span={12}>
          <InputNumber
            placeholder="To"
            value={value.to}
            onChange={(newVal) =>
              onChange({
                ...value,
                to: newVal,
              })
            }
          />
        </Col>
      </Row>
    );
  }

  switch (formItemType) {
    case 'multiSelect':
      return (
        <Select
          showSearch
          mode="multiple"
          placeholder={
            isValueFieldDisabled
              ? ''
              : `Select ${attributeDisplay || formatLabel(attribute)}`
          }
          optionFilterProp="children"
          disabled={isValueFieldDisabled}
          loading={loading}
          value={value}
          onChange={(newVal) => onChange(newVal)}
          {...selectProps}
        >
          {valueOptions
            .filter(
              (valueOption) => valueOption || typeof valueOption === 'number'
            )
            .map((valueOption) => (
              <Option key={valueOption} value={valueOption}>
                {valueFormat && valueFormat(valueOption)
                  ? valueFormat(valueOption)
                  : valueOption}
              </Option>
            ))}
        </Select>
      );
    case 'multiSelectWithSearch':
      return (
        <MulitSelectWithSearch
          label={attributeDisplay || formatLabel(attribute)}
          value={value}
          onChange={(newVal) => onChange(newVal)}
          disabled={isValueFieldDisabled}
          onSearch={onEntityLemmaSearch}
        />
      );
    case 'inputNumber':
      return (
        <InputNumber
          value={value}
          disabled={isValueFieldDisabled}
          onChange={(newVal) => onChange(newVal)}
        />
      );
    default:
      return (
        <Input
          allowClear
          value={value}
          disabled={isValueFieldDisabled}
          onChange={(e) => onChange(e.target.value)}
        />
      );
  }
};

export default FilterValueField;
