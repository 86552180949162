/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { useLocation, matchPath, Link } from 'react-router-dom';
import queryString from 'query-string';
import { useAuth, logout } from '@otso/auth-wrapper';

// Import Ant Design
import { Avatar, Dropdown, Menu } from 'antd';

// Import store and constants
import { showContactForm, getUserInitials, localStore } from 'modules';
import { GUIDED_TOURS } from 'components';
import { colors } from 'styles';

type UserMenuKey = 'superadmin' | 'account' | 'contact' | 'helpTour' | 'logout';

export const UserDropdownMenu: React.FC = () => {
  const location = useLocation();

  const { user, authHost } = useAuth();

  const currentPageTour = GUIDED_TOURS.find((tour) => {
    const { hideTour } = queryString.parse(location.search);
    const match = matchPath(location.pathname, { path: tour.pagePath });
    return hideTour ? false : match?.isExact;
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isTourShowing, setIsTourShowing] = useState<boolean>(
    currentPageTour && process.env.REACT_APP_SHOW_TOUR === 'true'
      ? localStore.getItem(currentPageTour.localStoreItemName) !== 'completed'
      : false
  );

  const onMenuClick = (key: UserMenuKey) => {
    switch (key) {
      case 'logout':
        logout();
        break;
      case 'contact':
        showContactForm();
        break;
      case 'helpTour': {
        setIsTourShowing(true);
        break;
      }
      default:
        break;
    }
  };

  return (
    <Dropdown
      overlay={
        <Menu
          onClick={({ key }) => onMenuClick(key as UserMenuKey)}
          css={{ minWidth: 160 }}
        >
          <Menu.ItemGroup title="Account">
            {user?.isSuper && (
              <Menu.Item key="superadmin">
                <Link to="/superadmin/datasets">Super Admin</Link>
              </Menu.Item>
            )}
            <Menu.Item key="account">
              <a
                href={`${authHost}/account`}
                target="_blank"
                rel="noopener noreferrer"
              >
                My Account
              </a>
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup title="Help">
            {process.env.REACT_APP_SHOW_LIVE_CHAT === 'true' && (
              <Menu.Item key="contact">Ask For Help</Menu.Item>
            )}
            <Menu.Item key="help">
              <a
                href="https://help.otso.ai"
                target="_blank"
                rel="noopener noreferrer"
              >
                Documentation
              </a>
            </Menu.Item>
            {currentPageTour && (
              <Menu.Item key="helpTour">View Page Tour</Menu.Item>
            )}
          </Menu.ItemGroup>
          <Menu.Divider />
          <Menu.Item danger key="logout">
            Sign Out
          </Menu.Item>
        </Menu>
      }
    >
      <span>
        <Avatar css={{ cursor: 'pointer', backgroundColor: colors.primary }}>
          {getUserInitials(user)}
        </Avatar>
      </span>
    </Dropdown>
  );
};
