/** @jsxImportSource @emotion/react */
import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';
import capitalize from 'lodash/capitalize';
import moment from 'moment-timezone';

import { formatDateInTZ } from '@modules/date/formatDate';
import { Card, Input, Button, Table, Badge, Tooltip, Popconfirm } from 'antd';
import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import { preset } from 'styles';
import Container from '../Container';

const CXTable = ({
  title,
  size,
  loading = false,
  columns = [],
  dataSource = [],
  rowKey,
  scroll,
  singularUnit,
  pluralUnit,
  canSearch = false,
  onCreateNew,
  onItemClick,
  expandedRowRender,
  pagination,
  emptyText,
  className,
}) => {
  const [searchText, setSearchText] = useState();

  const configuredColumns = useMemo(
    () =>
      columns.map((column, index) => {
        const {
          title: colTitle,
          dataIndex,
          key,
          type: colType = 'text',
          onClick,
          render,
          filterable = false,
        } = column;

        // Config title, data index and key
        const antColumn = {
          title: colTitle,
          dataIndex,
          key: dataIndex || key || camelCase(colTitle),
          ...(dataIndex
            ? {
                dataIndex,
                sorter: (currentItem, nextItem) => {
                  if (typeof currentItem[dataIndex] === 'number') {
                    return currentItem[dataIndex] - nextItem[dataIndex];
                  }
                  if (!currentItem[dataIndex]) return -1;
                  if (!nextItem[dataIndex]) return 1;
                  if (typeof currentItem[dataIndex] === 'string') {
                    if (colType === 'date') {
                      return moment(currentItem[dataIndex]).isSameOrAfter(
                        moment(nextItem[dataIndex])
                      );
                    }
                    return currentItem[dataIndex].toLowerCase() <
                      nextItem[dataIndex].toLowerCase()
                      ? -1
                      : 1;
                  }
                  return 0;
                },
                ...(filterable && dataSource.length > 0
                  ? {
                      filters: [
                        ...new Set(
                          dataSource.map((row) => `${row[dataIndex]}`)
                        ),
                      ].map((text) => ({
                        text,
                        value: text,
                      })),
                      onFilter: (value, row) => `${row[dataIndex]}` === value,
                    }
                  : {}),
              }
            : {}),
        };

        if (render) {
          return { ...antColumn, render };
        }

        // By default, first column will be selected as onItemClick trigger
        if (index === 0 && onItemClick) {
          antColumn.render = (text, row) => (
            <Button
              type="link"
              onClick={() => onItemClick(row)}
              css={{ paddingLeft: 0 }}
            >
              {text}
            </Button>
          );
        }

        // If this column is type status
        if (colType === 'status') {
          antColumn.render = (text) => (
            <Badge status={text} text={startCase(text.toLowerCase())} />
          );
        }

        if (colType === 'date') {
          antColumn.render = (date) => (
            <Tooltip title={formatDateInTZ(date, 'second', '/')}>
              <span>{formatDateInTZ(date, 'day', '/')}</span>
            </Tooltip>
          );
        }

        if (colType === 'deleteButton') {
          antColumn.render = (text, row) => (
            <Popconfirm
              title="Are you sure?"
              okText="Delete"
              okType="danger"
              cancelText="Cancel"
              onConfirm={() => onClick(row)}
            >
              <Button shape="circle" icon={<DeleteOutlined />} />
            </Popconfirm>
          );
        }

        if (colType === 'boolean') {
          antColumn.render = (text) =>
            text ? <CheckCircleOutlined /> : <CloseCircleOutlined />;
        }

        return antColumn;
      }),
    [columns, dataSource, onItemClick]
  );

  const queryableRowkeys = useMemo(
    () =>
      columns.reduce((prev, col) => {
        if (col.searchable) return [...prev, col.dataIndex];
        return prev;
      }, []),
    [columns]
  );

  const filteredData = useMemo(() => {
    if (!searchText) return dataSource;
    return dataSource.filter((row) => {
      return !!queryableRowkeys.find((queryableRowkey) =>
        `${row[queryableRowkey]}`
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
    });
  }, [searchText, dataSource, queryableRowkeys]);

  return (
    <Container>
      <Card
        title={
          <h1>
            {title}
            <span>
              {!loading &&
                `${dataSource.length} ${
                  dataSource.length === 1 ? singularUnit : pluralUnit
                }`}
            </span>
          </h1>
        }
        bordered={false}
        loading={loading}
        className={className}
        css={{
          background: 'transparent',
          '.ant-card-head': {
            borderBottom: 'none',
            padding: 0,
            background: 'transparent',
            '.ant-card-head-title': {
              h1: {
                marginBottom: 0,
                span: {
                  paddingLeft: preset.spacing(2),
                  fontSize: 14,
                  color: '#595959',
                  fontWeight: 400,
                },
              },
            },
          },
          '> .ant-card-body': {
            padding: 0,
          },
        }}
      >
        {/* Component Header */}
        {(canSearch || onCreateNew) && (
          <div
            css={{
              marginTop: 12,
              marginBottom: preset.spacing(3),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {canSearch && (
              <Input.Search
                data-testId="project-and-dashboard-search"
                allowClear
                placeholder="Search"
                onSearch={(val) => setSearchText(val)}
                css={{
                  flex: 1,
                  marginRight: preset.spacing(5),
                  maxWidth: 500,
                }}
              />
            )}
            {/* Create a new item */}
            {onCreateNew && (
              <Button type="primary" onClick={onCreateNew}>
                {`Create ${capitalize(singularUnit)}`}
              </Button>
            )}
          </div>
        )}

        {/* Component Body */}
        {dataSource && dataSource.length > 0 ? (
          <Table
            loading={loading}
            columns={configuredColumns}
            dataSource={filteredData}
            rowKey={rowKey || ((row) => JSON.stringify(row))}
            expandedRowRender={expandedRowRender}
            size={size}
            scroll={scroll ? { x: scroll } : undefined}
            pagination={{ position: pagination || 'bottom' }}
          />
        ) : (
          <div
            css={{
              marginTop: 150,
              marginBottom: 150,
              textAlign: 'center',
            }}
          >
            {!dataSource || dataSource.length === 0
              ? emptyText || `Create your first ${singularUnit} here`
              : 'No result matches'}
          </div>
        )}
      </Card>
    </Container>
  );
};

CXTable.propTypes = {
  // Page title
  title: PropTypes.string,
  // Column name
  columns: PropTypes.arrayOf(PropTypes.object),
  // Data source
  dataSource: PropTypes.arrayOf(PropTypes.object),
  // Function to handle clicking on a item in the first column
  onItemClick: PropTypes.func,
  // Function to handle adding a new item
  onCreateNew: PropTypes.func,
  // Single unit label
  singularUnit: PropTypes.string,
  // Plural unit label
  pluralUnit: PropTypes.string,
  // Column acts as a unique row key
  rowKey: PropTypes.string,
  // Whether searching is allowed
  canSearch: PropTypes.bool,
};

export default CXTable;
