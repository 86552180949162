/** @jsxImportSource @emotion/react */
import { useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { Chart, Axis, Tooltip, Geom } from 'bizcharts';
import { View } from '@antv/data-set';

import {
  getAxisConfig,
  getXAxisLabelConfig,
  getChartElement,
  formatDimensionLabel,
  formatBarChartData,
} from 'modules/chart';
import { deepGet, useChartRef } from 'modules';
import CoordConfig from '../components/CoordConfig';
import LegendConfig from '../components/LegendConfig';

const BarPecentage = ({
  width,
  height,
  config,
  color,
  queryObj,
  data,
  schema,
  drillDownDisabled = false,
  renderer,
  setChartDownloadInstance,
  // redux dispatch for triggering drilldown
  dispatch,
}) => {
  const {
    x,
    y,
    groups: metricGroups,
    color: colorDimension,
    transpose = false,
    reverse = false,
    label: labelConfig,
  } = config;

  const { dimensions } = queryObj;

  // Init chart instance
  const chart = useRef();

  // Re-init chart when width changes and pass chartRef to download modal
  useChartRef(chart, width, setChartDownloadInstance);

  // Get chart config
  const xAxisLabelConfig = useMemo(() => getXAxisLabelConfig(config), [config]);
  const hasTimeDimension = !!dimensions.find(
    (dimension) => dimension.functionValue
  );
  const showEmptyDate = config.showEmptyDate && hasTimeDimension;

  // Remove empty x and insert empty date when needed
  const formattedData = useMemo(
    () =>
      formatBarChartData({
        data,
        x,
        queryObj,
        showEmptyDate,
        y,
        colorDimension,
        schema,
      }),
    [data, queryObj, showEmptyDate, x, y, colorDimension, schema]
  );

  // Config X and Y for two types of data
  const metricKey = 'metric';
  const metricValueKey = 'value';

  // Add percentages
  const transformedData = useMemo(() => {
    const dv = new View();

    if (colorDimension) {
      dv.source(formattedData).transform({
        type: 'percent',
        field: y,
        dimension: colorDimension,
        groupBy: [x],
        as: 'percent',
      });

      return dv;
    }

    dv.source(formattedData).transform({
      type: 'fold',
      fields: metricGroups,
      key: metricKey,
      value: metricValueKey,
    });

    const ds = new View();

    ds.source(dv.rows).transform({
      type: 'percent',
      field: metricValueKey,
      dimension: metricKey,
      groupBy: [x],
      as: 'percent',
    });

    return ds;
  }, [colorDimension, formattedData, metricGroups, x, y]);

  // Config chart scale
  const scale = useMemo(() => {
    return {
      [x]: {
        formatter: (val) =>
          formatDimensionLabel(val, {
            timeInterval: deepGet(schema, [x, 'functionValue']),
            labelConfig,
          }),
      },
      percent: {
        min: 0,
        max: 1,
        nice: false,
        formatter: (val) => `${(val * 100).toFixed(2)}%`,
      },
      [colorDimension]: {
        formatter: (val) =>
          formatDimensionLabel(val, {
            timeInterval: deepGet(schema, [colorDimension, 'functionValue']),
            labelConfig,
            dimensionIndex: 1,
            dimensionKey: colorDimension,
          }),
      },
    };
  }, [x, colorDimension, schema, labelConfig]);

  // Get series area color
  const seriesColor = useMemo(
    () => [colorDimension || 'metric', (xKey) => color[xKey]],
    [color, colorDimension]
  );

  // Collect bar click data for drilldown
  const onBarClick = (ev) => {
    if (!drillDownDisabled) {
      const targetData = ev.data ? ev.data._origin : {};

      dispatch({
        type: 'SET_DRILLDOWN',
        drilldown: {
          x: ev.x,
          y: ev.y,
          chartElement: getChartElement(ev, chart.current),
          targetData,
          queryObj,
        },
      });
    }
  };

  return (
    <Chart
      height={height}
      data={transformedData}
      scale={scale}
      padding="auto"
      renderer={renderer}
      forceFit
      onIntervalClick={(ev) => onBarClick(ev)}
      onGetG2Instance={(g2Chart) => {
        chart.current = g2Chart;
      }}
    >
      <CoordConfig option={{ transpose, reverse }} />
      <Axis name={x} label={xAxisLabelConfig} {...getAxisConfig(config, 'x')} />
      <Axis
        name="percent"
        position={reverse ? 'right' : 'left'}
        {...getAxisConfig(config, 'y')}
      />
      <LegendConfig config={config} />
      <Tooltip />
      <Geom
        type="intervalStack"
        position={`${x}*percent`}
        color={seriesColor}
        style={{ cursor: drillDownDisabled ? 'auto' : 'pointer' }}
      />
    </Chart>
  );
};

export default connect()(BarPecentage);
