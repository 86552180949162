import React, { useRef, useState } from 'react';
import { Tag } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import { truncate } from 'lodash';
import { saveAs } from 'file-saver';
import { useAuth } from '@otso/auth-wrapper';
import { colors } from 'styles';

export type Attachment = {
  id: string;
  path: string;
  sizeBytes: number;
  contentType: string;
  fileName: string;
};

type Props = {
  attachment: Attachment;
  downloadable?: boolean;
};

const DocumentAttachment: React.FC<Props> = ({
  attachment,
  downloadable = false,
}) => {
  const { path, fileName } = attachment;
  const { currentOrgStorageRef } = useAuth();
  const cachedDownloadURLRef = useRef<string>('');
  const [downloadURL, setDownloadURL] = useState<string>('');

  const fileNameString = fileName.substring(0, fileName.lastIndexOf('.'));
  const fileExt = fileName.substring(fileName.lastIndexOf('.') + 1);
  const shortenFileName = `${truncate(fileNameString, {
    length: 20,
  })}.${fileExt}`;

  const downloadAttachmentFile = async (
    e: React.MouseEvent<HTMLSpanElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (!downloadable) return;
    if (!downloadURL) {
      if (currentOrgStorageRef) {
        const rawFileRef = currentOrgStorageRef.child(path);
        const url = await rawFileRef.getDownloadURL();
        cachedDownloadURLRef.current = url;
        setDownloadURL(url);
        saveAs(url, fileName);
      }
    } else {
      saveAs(downloadURL, fileName);
    }
  };

  return (
    <Tag
      icon={<PaperClipOutlined />}
      title={fileName}
      onClick={downloadAttachmentFile}
      style={{
        cursor: 'pointer',
        background: colors.white,
        borderRadius: '8px',
      }}
    >
      {shortenFileName}
    </Tag>
  );
};

export default DocumentAttachment;
