/** @jsxImportSource @emotion/react */

const Youtube = props => {
    const { link, embedMaxWidth } = props;
    const url = new URL(link);

    const id = url.searchParams.get('v');

    return (
        <iframe
            title={link}
            width={embedMaxWidth}
            height={embedMaxWidth * 0.5625}
            src={`https://www.youtube.com/embed/${id}`}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
        />
    );
};

export default Youtube;
