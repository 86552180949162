/** @jsxImportSource @emotion/react */

// Import libraries
import { orderBy, find } from 'lodash';
import { arrayMoveImmutable } from 'array-move';
import { useMemo, useState, useEffect, useRef } from 'react';

// Import Ant Design components
import {
  Modal,
  Table,
  Input,
  Switch,
  Tooltip,
  Space,
  message,
  Alert,
} from 'antd';
import { QuestionCircleOutlined, WarningFilled } from '@ant-design/icons';

// Import stylesheets
import { colors, preset } from 'styles';

// Import modules
import {
  getFilterableFields,
  isDefaultMultiSelectField,
} from '@modules/filterBarHelpers';
import { postApi, putApi, fetchApiErrorMessage } from '@modules/api';
import deepGet from '@modules/deepGet';
import formatLabel from '@modules/formatLabel';

// Import components
import {
  DragHandle,
  DraggableBodyRow,
  DraggableContainer,
} from '@components/Sortable';

const SchemaCustomModal = ({
  dataset,
  isGlobal = false,
  editable = true,
  open = false,
  onClose,
  fetchDataset,
}) => {
  // Initialisation
  const [fieldsData, setFieldsData] = useState([]);
  const [changedFields, setChangedFields] = useState([]);
  const [fieldsOrder, setFieldsOrder] = useState({});
  const [categoryFieldsProps, setCategoryFieldsProps] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const dataSourceRef = useRef();

  useEffect(() => {
    dataSourceRef.current = fieldsData.map((field) => {
      const updatedField = find(changedFields, { name: field.name });
      if (updatedField) return { ...field, ...updatedField };
      return field;
    });
  }, [fieldsData, changedFields]);

  useEffect(() => {
    setFieldsOrder(
      fieldsData.reduce(
        (prev, current, idx) => ({ ...prev, [current.name]: idx }),
        {}
      )
    );

    return () => setFieldsOrder({});
  }, [fieldsData]);

  useEffect(() => {
    if (dataset.schema) {
      const { fields = [] } = dataset.schema || {};

      const processedFields = getFilterableFields(fields).map((field) => {
        const { type } = field;
        // Config category field
        if (isDefaultMultiSelectField(field)) {
          return { ...field, isCategory: true, isCategoryDisabled: true };
        }
        if ('isCategory' in field) {
          return { ...field, isCategoryDisabled: false };
        }
        if (['string', 'numeric', 'int', 'float', '_string'].includes(type)) {
          return { ...field, isCategory: false };
        }
        return { ...field, isCategory: false, isCategoryDisabled: true };
      });

      setFieldsData(orderBy(processedFields, 'order'));
    }

    return () => setFieldsData([]);
  }, [dataset.schema]);

  // Actions on cancelled or comepleted
  const onCancel = () => {
    setChangedFields([]);
    setFieldsOrder({});
    setCategoryFieldsProps({});
    setFieldsData(orderBy(fieldsData, 'order'));
    onClose();
  };

  // On field changed
  const onFieldChange = (val, key, field) => {
    let newChangedFields = [];
    const isFieldChanged = changedFields.find(
      (changedField) => changedField.name === field.name
    );
    if (isFieldChanged) {
      newChangedFields = changedFields.map((changedField) =>
        changedField.name === field.name
          ? {
              ...changedField,
              [key]: val,
            }
          : changedField
      );
    } else {
      newChangedFields = [
        ...changedFields,
        {
          ...field,
          [key]: val,
        },
      ];
    }
    setChangedFields(newChangedFields);
  };

  // On isCategory toggled or untoggled
  const onIsCategoryChange = async (checked, field) => {
    if (!checked) {
      setCategoryFieldsProps((prevProps) => ({
        ...prevProps,
        [field.name]: {},
      }));
      onFieldChange(checked, 'isCategory', field);
    } else {
      setCategoryFieldsProps((prevProps) => ({
        ...prevProps,
        [field.name]: { loading: true },
      }));
      try {
        const { data } = await postApi('datasets/document-value', {
          datasets: [dataset.id],
          filter: {},
          requestedFields: [field.name],
        });
        setCategoryFieldsProps((prevProps) => ({
          ...prevProps,
          [field.name]: {
            loading: false,
            length: data[field.name].length,
          },
        }));
        onFieldChange(checked, 'isCategory', field);
      } catch (error) {
        setCategoryFieldsProps((prevProps) => ({
          ...prevProps,
          [field.name]: { loading: false },
        }));
        message.error(fetchApiErrorMessage(error));
      }
    }
  };

  // On data saved
  const onSave = async () => {
    try {
      // Set saving status
      setIsSaving(true);

      // Prepare payload
      const payload = {
        fields: changedFields,
        order: fieldsOrder,
      };

      // Send a request to update schema fields
      await putApi(
        isGlobal
          ? `superadmin/datasets/${dataset.id}/schema-fields`
          : `datasets/${dataset.id}/schema-fields`,
        payload
      );

      // Re-fetch dataset information
      fetchDataset();

      message.success('Dataset Schema Saved');
      onCancel();
    } catch (error) {
      // Show error message
      message.error(fetchApiErrorMessage(error));
    } finally {
      setIsSaving(false);
    }
  };

  // Check for duplicated display name
  const hasDuplicatedDisplayName = useMemo(() => {
    if (fieldsData.length === 0) return false;
    const displayNames = fieldsData.map((field) => {
      const currentChangedField = changedFields.find(
        (changedField) => changedField.name === field.name
      );
      if (currentChangedField && currentChangedField.displayName) {
        return currentChangedField.displayName;
      }
      return formatLabel(field.name);
    });
    return new Set(displayNames).size !== displayNames.length;
  }, [fieldsData, changedFields]);

  // Prepare dataset schema table columns
  const columns = [
    {
      title: 'Sort',
      key: 'reorder',
      width: '5%',
      render: () => <DragHandle />,
    },
    { title: 'Field name', dataIndex: 'name' },
    {
      title: (
        <Tooltip
          visible={hasDuplicatedDisplayName}
          title="The same display name is used multiple times"
        >
          <Space>
            Display Name
            {hasDuplicatedDisplayName && (
              <WarningFilled style={{ color: colors.gold, fontSize: 16 }} />
            )}
          </Space>
        </Tooltip>
      ),
      dataIndex: 'displayName',
      render: (displayName, row) => (
        <Input
          size="middle"
          disabled={!editable}
          {...(displayName
            ? { defaultValue: displayName }
            : { placeholder: formatLabel(row.name) })}
          onChange={(e) => onFieldChange(e.target.value, 'displayName', row)}
        />
      ),
    },
    { title: 'Type', dataIndex: 'type' },
    {
      title: (
        <Tooltip title="Category type field allows user to filter values from a drop down list">
          <Space>
            Is Category type
            <QuestionCircleOutlined />
          </Space>
        </Tooltip>
      ),
      dataIndex: 'isCategory',
      render: (isCategory, row) =>
        row.isCategoryDisabled || !editable ? (
          isCategory ? (
            'Yes'
          ) : (
            'No'
          )
        ) : (
          <Space>
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              loading={!!deepGet(categoryFieldsProps, [row.name, 'loading'])}
              defaultChecked={isCategory}
              onChange={(checked) => onIsCategoryChange(checked, row)}
            />
            {deepGet(categoryFieldsProps, [row.name, 'length']) > 500 && (
              <Tooltip
                title={`There will be ${deepGet(categoryFieldsProps, [
                  row.name,
                  'length',
                ])} distinct values in the drop down list, we suggest not to use 
                                  category type for ${
                                    row.name
                                  } to avoid possible lagging in a large list.`}
              >
                <WarningFilled style={{ color: colors.gold, fontSize: 16 }} />
              </Tooltip>
            )}
          </Space>
        ),
    },
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const temp = dataSourceRef.current || [];
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable([...temp], oldIndex, newIndex).filter(
        (el) => !!el
      );
      setFieldsData([...newData]);
    }
  };

  const TableWrapper = useMemo(() => {
    const memoized = (props) => (
      <DraggableContainer onSortEnd={onSortEnd} {...props} />
    );
    return memoized;
  }, []);

  const TableRow = useMemo(() => {
    const memoized = (props) => {
      // Extract values from props
      const { className, style, ...restProps } = props;
      const index = dataSourceRef.current.findIndex(
        (x) => x.name === props['data-row-key']
      );

      return <DraggableBodyRow index={index} {...restProps} />;
    };
    return memoized;
  }, []);

  return (
    <Modal
      destroyOnClose
      title={editable ? 'Edit Dataset Schema Fields' : 'Dataset Schema Fields'}
      visible={open}
      width={preset.modalWidth.lg}
      onCancel={onCancel}
      okText="Save"
      onOk={onSave}
      confirmLoading={isSaving}
      okButtonProps={{
        disabled: hasDuplicatedDisplayName,
      }}
      {...(editable ? { maskClosable: false } : { footer: null })}
    >
      {/* Alert user when the dataset is a global dataset */}
      {!editable && (
        <Alert
          message="Note: This is a global dataset managed and controlled by a central system. If you want to make any other changes, please contact support for help."
          type="info"
          showIcon
          style={{ marginBottom: preset.spacing(3) }}
        />
      )}

      {/* Dataset schema table */}
      <Table
        size="small"
        columns={columns}
        dataSource={fieldsData}
        pagination={false}
        rowKey="name"
        components={{
          body: {
            wrapper: TableWrapper,
            row: TableRow,
          },
        }}
      />
    </Modal>
  );
};

export default SchemaCustomModal;
