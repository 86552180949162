/** @jsxImportSource @emotion/react */
import { useCallback, useContext, useEffect, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import { message } from 'antd';
import isEqual from 'lodash/isEqual';

import { preset } from 'styles';
import { useCheckPermission } from 'modules';
import { postApi, fetchApiErrorMessage } from '../../modules/api';
import { ProjectContext } from '../../providers/ProjectProvider';
import { initialState, locationReducer } from './reducer';
import { defaultQueryObj } from '../Chart/reducer';
import {
  getLocationPageMetrics,
  GEO_LOCATION_SCHEMA_FIELDS,
  getLocationDimensionByZoom,
} from '../../modules/filterBarHelpers';
import getGeoJsonConfigByDimension from '../../modules/geo/getGeoJsonConfigByDimension';
import { queryStringToObject } from '../../modules/queryStringConverter';

import Container from '../../components/Container';
import LocationButtonGroups from './components/LocationButtonGroups';
import ChoroplethMap from '../../components/Charts/ChoroplethMap';

const ProjectLocation = () => {
  const {
    project: { slug: projectSlug, datasets: projectDatasets = [] },
  } = useContext(ProjectContext);

  const [state, dispatch] = useReducer(locationReducer, initialState);

  const {
    isQueryLoading = false,
    data,
    currentDimensionKey,
    queryObj,
    config,
    color,
    autoZoom,
  } = state;

  const location = useLocation();
  const canDrilldownData = useCheckPermission(['insight.viewDocuments']);

  const onQueryApply = useCallback(
    async (newQueryObj, options = {}) => {
      const { metrics } = newQueryObj;
      const { newDimensionKey, currentAutoZoom, tempAutoZoom } = options;
      if (metrics.length > 0) {
        dispatch({ type: 'LOCATION_QUERY_REQUESTED' });

        try {
          const sortedGeoFields = [...GEO_LOCATION_SCHEMA_FIELDS].sort(
            (fieldA, fieldB) => {
              if (fieldA.name === newDimensionKey) {
                return -1;
              }
              if (fieldB.name === newDimensionKey) {
                return 1;
              }
              return 0;
            }
          );

          sortedGeoFields.reduce((prev, geoField) => {
            return prev.then(async () => {
              const newQueryObjWithDimension = {
                ...newQueryObj,
                dimensions: [
                  {
                    attribute: geoField.name,
                    as: geoField.displayName,
                    limit: 2000,
                  },
                ],
                groupBy: [geoField.displayName],
              };

              const res = await postApi(`projects/${projectSlug}/query`, {
                ...newQueryObjWithDimension,
                queryType: 'union',
                withCount: false,
              });
              const { data: queryData } = res.data;

              dispatch({
                type: 'LOCATION_QUERY_COMPLETE',
                dimensionKey: geoField.name,
                data: queryData,
                newQueryObj: newQueryObjWithDimension,
                newDimensionKey,
                currentAutoZoom,
                tempAutoZoom,
              });
            });
          }, Promise.resolve());
        } catch (error) {
          message.error(fetchApiErrorMessage(error));
          dispatch({ type: 'LOCATION_QUERY_ERROR', queryObj: newQueryObj });
        }
      }
    },
    [projectSlug]
  );

  useEffect(() => {
    const initLocation = async () => {
      // Find project datasets containing the "otso_doc_enriched_location" field
      const datasetsWithGeoLocation = projectDatasets.filter((dataset) =>
        dataset.schema.fields.find(
          (field) => field.name === 'otso_doc_enriched_location'
        )
      );

      // If there is at least one dataset containing the "otso_doc_enriched_location" field
      if (datasetsWithGeoLocation.length > 0) {
        // Extract dataset ids and filters from query string
        const {
          datasetIds: datasetIdsFromUrl = [],
          filters: filtersFromUrl = [],
        } = queryStringToObject(location.search) || {};

        // If there is at least a dataset id in the query parameters
        if (datasetIdsFromUrl.length > 0) {
          const defaultDatasets = datasetIdsFromUrl.reduce(
            (prev, datasetId) => {
              const matchedDataset = datasetsWithGeoLocation.find(
                (geoDataset) => geoDataset.id === datasetId
              );
              if (matchedDataset) {
                return [...prev, matchedDataset];
              }
              return prev;
            },
            []
          );
          const initialisedQueryObj = {
            ...defaultQueryObj,
            metrics: getLocationPageMetrics(defaultDatasets).slice(0, 1),
            dataset: datasetIdsFromUrl,
            filters: filtersFromUrl,
          };
          onQueryApply(initialisedQueryObj);
        } else {
          // No dataset id found in the query parameters, use the first dataset with the
          // "otso_doc_enriched_location" field
          const [defaultDataset] = datasetsWithGeoLocation;

          // Prepare a query object
          const initialisedQueryObj = {
            ...defaultQueryObj,
            metrics: getLocationPageMetrics([defaultDataset]).slice(0, 1),
            dataset: [defaultDataset.id],
          };

          // Apply the query search
          onQueryApply(initialisedQueryObj);
        }
      }
    };

    initLocation();
  }, [projectDatasets, onQueryApply, location.search]);

  const onZoomChange = useCallback((newZoom) => {
    const newDimensionField = getLocationDimensionByZoom(newZoom);
    dispatch({ type: 'SET_DIMENSION_KEY', dimensionField: newDimensionField });
  }, []);

  const onDbClick = async (feature) => {
    const geoFieldIndex = GEO_LOCATION_SCHEMA_FIELDS.findIndex(
      (field) => field.name === currentDimensionKey
    );

    if (geoFieldIndex < GEO_LOCATION_SCHEMA_FIELDS.length - 1) {
      const currentGeoField = GEO_LOCATION_SCHEMA_FIELDS[geoFieldIndex];
      const { idPropertyName } = getGeoJsonConfigByDimension(
        currentGeoField.displayName
      );
      const dimensionFilterValue = feature.getProperty(idPropertyName);

      const newDimensionField = GEO_LOCATION_SCHEMA_FIELDS[geoFieldIndex + 1];

      const newFilter = {
        attribute: currentDimensionKey,
        condition: '=',
        value: [dimensionFilterValue],
      };

      const isNewFilterExisted = queryObj.filters.find((filter) =>
        isEqual(filter, newFilter)
      );

      const newFilters = isNewFilterExisted
        ? queryObj.filters
        : [...queryObj.filters, newFilter];

      await onQueryApply(
        {
          ...queryObj,
          filters: newFilters,
        },
        {
          newDimensionKey: newDimensionField.name,
          currentAutoZoom: autoZoom,
          tempAutoZoom: true,
        }
      );
    }
  };

  return (
    <div css={{ paddingTop: 12, paddingBottom: 12 }}>
      <Container fullWidth>
        <div css={{ position: 'relative' }}>
          <ChoroplethMap
            loading={isQueryLoading}
            autoZoom={autoZoom}
            onZoomChange={!autoZoom && onZoomChange}
            onDbClick={onDbClick}
            height={`calc(100vh - ${
              preset.headerHeight + preset.subHeaderHeight + 12 * 2
            }px)`}
            data={data[currentDimensionKey]}
            queryObj={queryObj}
            config={{
              ...config,
              legend: { display: true },
            }}
            color={color}
            drillDownDisabled={!canDrilldownData}
          />

          <div
            css={{
              position: 'absolute',
              top: preset.spacing(2),
              left: preset.spacing(2),
            }}
          >
            <LocationButtonGroups
              {...state}
              data={data[currentDimensionKey]}
              onQueryObjUpdate={onQueryApply}
              onDimensionUpdate={(newDimensionField) =>
                dispatch({
                  type: 'SET_DIMENSION_KEY',
                  dimensionField: newDimensionField,
                  autoZoom: true,
                })
              }
              onColorUpdate={(newColor) =>
                dispatch({
                  type: 'SET_LOCATION_VALUE',
                  key: 'color',
                  value: newColor,
                })
              }
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ProjectLocation;
