/** @jsxImportSource @emotion/react */
import { Collapse, Form, Row, Col, Switch, Select } from 'antd';

import { preset } from 'styles';

const { Panel } = Collapse;
const FormItem = Form.Item;
const { Option } = Select;

const LegendPanel = ({ defaultConfig = {}, dispatch, ...props }) => {
  const legendConfig = defaultConfig.legend || {};

  return (
    <Panel {...props} key="legend" header="Legend">
      <Row gutter={preset.spacing(3)}>
        <Col span={12}>
          <FormItem label="Display Legend">
            <Switch
              checked={legendConfig.display}
              onChange={(checked) =>
                dispatch({
                  type: 'SET_CONFIG',
                  value: checked,
                  key: 'legend',
                  childKey: 'display',
                })
              }
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="Position">
            <Select
              value={legendConfig.config.position}
              onChange={(value) =>
                dispatch({
                  type: 'SET_CONFIG',
                  value: {
                    ...legendConfig.config,
                    position: value,
                  },
                  key: 'legend',
                  childKey: 'config',
                })
              }
            >
              <Option value="top">Top</Option>
              <Option value="right">Right</Option>
              <Option value="bottom">Bottom</Option>
              <Option value="left">Left</Option>
            </Select>
          </FormItem>
        </Col>
      </Row>
    </Panel>
  );
};

export default LegendPanel;
