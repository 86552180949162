/** @jsxImportSource @emotion/react */
import { useRef, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import shortid from 'shortid';

import { Modal, message } from 'antd';

import { preset } from 'styles';
import { postApi, putApi, fetchApiErrorMessage } from '@modules/api';
import deepGet from '@modules/deepGet';
import RichTextEditor from '@components/RichTextEditor';

const mapStateToProps = (state) => {
  return {
    isRichTextModalOpen: state.dashboard.isRichTextModalOpen,
    dashboardCharts: state.dashboard.dashboardCharts,
    selectedWidgetChartId: state.dashboard.selectedWidgetChartId,
    currentPageId: state.dashboard.currentPageId,
  };
};

const RichTextModal = ({
  // redux state
  isRichTextModalOpen,
  dashboardCharts,
  selectedWidgetChartId,
  currentPageId,
  // redux dispatch
  dispatch,
}) => {
  const { slug: projectSlug, dashboardSlug } = useParams();

  const [isSaving, setIsSaving] = useState(false);

  const editorRef = useRef();

  const selectedWidgetChart = deepGet(dashboardCharts, [
    'byId',
    selectedWidgetChartId,
  ]);

  const newChartLayout = useMemo(() => {
    const currentPageVisIds = currentPageId
      ? dashboardCharts.byPageId[currentPageId] || []
      : [];
    const currentPageCharts = currentPageVisIds.map(
      (visId) => dashboardCharts.byId[visId]
    );

    const newChartY = currentPageCharts.reduce(
      (prev, vis) =>
        vis.layout.y + vis.layout.h > prev ? vis.layout.y + vis.layout.h : prev,
      0
    );

    return {
      i: shortid.generate(),
      x: 0,
      y: newChartY,
      w: 6,
      h: 4,
    };
  }, [currentPageId, dashboardCharts]);

  const onClose = () => {
    dispatch({ type: 'RESET_SELECTED_RICH_TEXT_WIDGET' });
    setIsSaving(false);
  };

  const onSave = async () => {
    if (editorRef.current) {
      const richTextHTML = editorRef.current.getHTML();
      const widgetChart = {
        defaultTitle: 'Untitled rich text widget',
        chartType: 'rich_text',
        defaultConfig: {},
        defaultColor: {},
        data: [{ html: richTextHTML }],
        queryObj: {},
      };

      setIsSaving(true);

      if (selectedWidgetChart) {
        // Update current widget
        try {
          await putApi(
            `projects/${projectSlug}/visualisations/${selectedWidgetChart.visualisation.id}`,
            { visualisation: widgetChart }
          );

          dispatch({
            type: 'SET_DASHBOARD_CHART',
            chart: {
              ...selectedWidgetChart,
              visualisation: {
                ...selectedWidgetChart.visualisation,
                data: widgetChart.data,
              },
            },
          });

          message.success('Rich Text Widget Saved');
        } catch (error) {
          message.error(fetchApiErrorMessage(error));
        }
      } else {
        // Create new widget
        try {
          const res = await postApi(`projects/${projectSlug}/visualisations`, {
            visualisation: widgetChart,
          });

          const newChartId = res.data.id;

          const {
            data: { id: newDashboardVisId },
          } = await postApi(
            `projects/${projectSlug}/dashboards/${dashboardSlug}/pages/${currentPageId}`,
            {
              newDashboardVis: {
                visualisationId: newChartId,
                layout: newChartLayout,
              },
            }
          );

          const updatedDashboardVis = {
            id: newDashboardVisId,
            layout: newChartLayout,
            page: { id: currentPageId },
            visualisation: { id: newChartId, ...widgetChart },
          };

          dispatch({
            type: 'ADD_DASHBOARD_CHART',
            chart: updatedDashboardVis,
          });

          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 0);

          message.success('Rich Text Widget Created');
        } catch (error) {
          message.error(fetchApiErrorMessage(error));
        }
      }
      setIsSaving(false);
      onClose();
    }
  };

  const selectedWidgetChartHTML = useMemo(() => {
    if (!selectedWidgetChart) return null;
    const chartData =
      deepGet(selectedWidgetChart, ['visualisation', 'data']) || [];
    return chartData.length > 0 ? chartData[0].html : null;
  }, [selectedWidgetChart]);

  return (
    <Modal
      destroyOnClose
      title="Rich Text Editor"
      visible={isRichTextModalOpen}
      width={preset.modalWidth.md}
      onCancel={onClose}
      onOk={onSave}
      okText="Save"
      confirmLoading={isSaving}
    >
      <RichTextEditor ref={editorRef} defaultValue={selectedWidgetChartHTML} />
    </Modal>
  );
};

export default connect(mapStateToProps)(RichTextModal);
