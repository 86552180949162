// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import intersectionBy from 'lodash/intersectionBy';
import uniqBy from 'lodash/uniqBy';
import flatten from 'lodash/flatten';
import isEqual from 'lodash/isEqual';
import capitalize from 'lodash/capitalize';
import set from 'lodash/set';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import isoConv from 'iso-language-converter';
import moment from 'moment-timezone';
import lucene, { AST, Node, NodeRangedTerm, NodeTerm, Operator } from 'lucene';

import {
  SchemaField,
  Dataset,
  CategorisedSchemaFields,
  SchemaFieldInputConfig,
  DashboardFilter,
  Filter,
  TimeFilter,
  LuceneNode,
  LuceneNodeNested,
  LuceneNodeNestedRight,
} from 'types';
import formatLabel from './formatLabel';
import { initialState as filterBarInitialState } from '../components/FilterBar/reducer';
import { formatEmptyObject } from './items';
import getPresetDateRanges from './date/getPresetDateRanges';

const { byValue } = getPresetDateRanges();

const SOURCE_NAME_MAP: Record<string, string> = {
  social: 'Social',
  social_message_boards: 'Social message boards',
  facebook: 'Facebook',
  twitter: 'Twitter',
  social_blogs: 'Social blogs',
  social_comments: 'Social comments',
  social_reviews: 'Social reviews',
  instagram: 'Instagram',
  youtube: 'Youtube',
};

const CHANNEL_NAME_MAP: Record<string, string> = {
  social: 'Social',
  news: 'News',
  print: 'Print',
  online: 'Online',
  broadcast: 'Broadcast',
};

export const PRIMARY_SUBTYPES = [
  'time',
  'verbatim',
  'channel',
  'source',
  'language',
];

export const ENTITIES_SCHEMA_FIELDS: SchemaField[] = [
  { name: 'entity_type', type: 'string' },
  { name: 'entity_lemma', type: 'string_search', searchKey: 'lemma' },
  { name: 'entity_sentiment_label', type: 'string' },
  { name: 'embedding_sentence', type: 'string' },
  { name: 'entity_sentiment_score', type: 'numeric' },
];

export const GEO_LOCATION_SCHEMA_FIELDS: SchemaField[] = [
  // { name: 'geo_location.country.unique_name', displayName: 'Country' },
  {
    name: 'otso_doc_enriched_location_state',
    type: 'string',
    displayName: 'State',
  },
  {
    name: 'otso_doc_enriched_location_lga',
    type: 'string',
    displayName: 'LGA',
  },
  {
    name: 'otso_doc_enriched_location_suburb',
    type: 'string',
    displayName: 'Suburb',
  },
  {
    name: 'otso_doc_enriched_location_council_division',
    type: 'string',
    displayName: 'Council Division',
  },
];

export const HARD_CODED_ENRICHED_DIMENSION_NAMES = [
  'entity_type',
  'entity_lemma',
  'entity_sentiment_label',
  'embedding_sentence',
];

export const MULTI_SELECT_SUBTYPES = ['channel', 'source', 'language'];

const NULL_OPTIONS = [
  { label: 'Is Null', value: 'is null' },
  { label: 'Is Not Null', value: 'is not null' },
];

export const FILTERABLE_TYPES = [
  'string',
  'int',
  'float',
  'integer',
  'long',
  'numeric',
  'array',
  'timestamp',
  '_string',
];

export const NON_FILTERABLE_FIELD_NAMES: string[] = [];

const getSchemaFieldType = (type: SchemaField['type']): string => {
  if (typeof type === 'string') return type;
  return type.type === 'array' && type.items === 'string' ? 'array' : 'record';
};

export const getPrimarySchemaField = (fields: SchemaField[]) => {
  return fields.find((field) => {
    return (
      field.order === 0 &&
      ![
        'otso_doc_body',
        'document_body',
        'otso_doc_publish_date',
        'document_publish_date',
      ].includes(field.name)
    );
  });
};

export const checkIsCustomClassifierField = (field: SchemaField): boolean => {
  return (
    !!field.name?.startsWith('otso_doc_enriched_') &&
    field.name !== 'otso_doc_enriched_entities' &&
    !field.name?.startsWith('otso_doc_enriched_location') &&
    field.name !== 'otso_doc_enriched_sentiment_score' &&
    field.name !== 'otso_doc_enriched_summary'
  );
};

export const getFilterableFields = (
  fields: SchemaField[] = []
): SchemaField[] => {
  return fields.filter(
    (field) =>
      !field.hidden &&
      FILTERABLE_TYPES.includes(getSchemaFieldType(field.type)) &&
      !NON_FILTERABLE_FIELD_NAMES.includes(field.name)
  );
};

export const getSharedSchemaFields = (
  datasets: Dataset[] = [],
  option?: { getAllFields?: boolean; getSharedFields?: boolean }
) => {
  const { getAllFields = false, getSharedFields = false } = option || {};

  if (datasets.length === 0) {
    return [];
  }

  let hasGeoLocationField = false;

  const schemaFieldsList = datasets.map((dataset) => {
    hasGeoLocationField = !!dataset.schema.fields.find(
      (field) => field.name === 'geo_location'
    );
    return getAllFields
      ? dataset.schema.fields
      : getFilterableFields(dataset.schema.fields);
  });

  let defaultSharedSchemaFields = getSharedFields
    ? intersectionBy(...schemaFieldsList, 'name')
    : uniqBy(flatten(schemaFieldsList), 'name');

  if (hasGeoLocationField) {
    defaultSharedSchemaFields = [
      ...defaultSharedSchemaFields,
      ...GEO_LOCATION_SCHEMA_FIELDS,
    ];
  }

  return [...defaultSharedSchemaFields];
};

export const getCustomClassifierFields = (
  sharedSchemaFields: SchemaField[] = []
) => {
  const customClassifierFields = sharedSchemaFields.filter((field) => {
    // console.log(field);
    return checkIsCustomClassifierField(field);
  });

  const getCustomClassifierFieldSortScore = (field: SchemaField) => {
    const { name } = field;
    if (name.includes('sentiment')) return 2;
    if (name.includes('emotion')) return 1;
    return 0;
  };
  return customClassifierFields.sort((a, b) => {
    return (
      getCustomClassifierFieldSortScore(b) -
      getCustomClassifierFieldSortScore(a)
    );
  });
};

export const getMainVerbatimFieldName = (
  sharedSchemaFields: SchemaField[] = []
): string | null => {
  const verbatimField: SchemaField | null = sharedSchemaFields.reduce(
    (prev: SchemaField | null, field) => {
      // Use otso_doc_body as main verbatim if found
      if (field.name === 'otso_doc_body') return field;
      if (prev && prev.name === 'otso_doc_body') return prev;
      // Otherwise use other verbatim field name
      return field;
    },
    null
  );

  return verbatimField && verbatimField.name;
};

export const getFormatRequiredAttributes = (
  categorisedSchemaFields: CategorisedSchemaFields = {}
): { name: string; format: (val: any) => any }[] => {
  const { language, source, channel } = categorisedSchemaFields;

  const attributes = [];

  if (Array.isArray(language) && language.length > 0) {
    attributes.push({
      name: language[0].name,
      format: (val: any) => isoConv(val) || val,
    });
  }

  if (Array.isArray(source) && source.length > 0) {
    attributes.push({
      name: source[0].name,
      format: (val: any) =>
        typeof val === 'string' ? SOURCE_NAME_MAP[val] || val : val,
    });
  }

  if (Array.isArray(channel) && channel.length > 0) {
    attributes.push({
      name: channel[0].name,
      format: (val: any) =>
        typeof val === 'string' ? CHANNEL_NAME_MAP[val] || val : val,
    });
  }

  return attributes;
};

export const getCategorisedSchemaFields = (
  fields: SchemaField[] = [],
  options?: { includingTime?: boolean }
): CategorisedSchemaFields => {
  const { includingTime = true } = options || {};

  if (fields.length === 0) {
    return {};
  }
  const primarySubtypes = PRIMARY_SUBTYPES.filter(
    (subtype) => includingTime || subtype !== 'time'
  );
  const primarySubtypeGroups: { [subtype: string]: SchemaField[] } =
    primarySubtypes.reduce(
      (prev, subtype) => ({
        ...prev,
        [subtype]: [],
      }),
      {}
    );

  const geoLocationFieldGroups: { 'Geo Location Dimensions'?: SchemaField[] } =
    fields.find((field) =>
      GEO_LOCATION_SCHEMA_FIELDS.find(
        (geoField) => geoField.name === field.name
      )
    )
      ? { 'Geo Location Dimensions': [] }
      : {};

  const otherGroups: {
    metrics: SchemaField[];
    dimensions: SchemaField[];
  } = {
    metrics: [],
    dimensions: [],
  };

  const initGroups: CategorisedSchemaFields = {
    ...primarySubtypeGroups,
    ...geoLocationFieldGroups,
    ...otherGroups,
  };

  const categorisedFields: CategorisedSchemaFields = fields.reduce(
    (prev, field) => {
      const { subtype, name, displayName, category, type } = field;

      if (name === 'otso_doc_body') {
        return {
          ...prev,
          dimensions: [
            ...(prev.dimensions || []),
            {
              name,
              type,
              category: 'dimension',
              displayName: displayName || 'Analyzed text',
            },
          ],
        };
      }

      if (name === 'otso_doc_publish_date') {
        if (includingTime) {
          return {
            ...prev,
            time: [
              ...(prev.time || []),
              {
                name,
                type,
                category: 'dimension',
                displayName: displayName || 'Date',
              },
            ],
          };
        }
        return prev;
      }

      if (subtype && primarySubtypes.includes(subtype)) {
        return {
          ...prev,
          [subtype]: [...prev[subtype], field],
        };
      }

      if (
        GEO_LOCATION_SCHEMA_FIELDS.find((geoField) => geoField.name === name)
      ) {
        return {
          ...prev,
          'Geo Location Dimensions': [
            ...(prev['Geo Location Dimensions'] || []),
            field,
          ],
        };
      }

      if (type === 'timestamp' && category === 'dimension') {
        return includingTime
          ? {
              ...prev,
              time: [...(prev.time || []), field],
            }
          : prev;
      }

      if (category === 'metric') {
        return {
          ...prev,
          metrics: [...(prev.metrics || []), field],
        };
      }

      if (category === 'dimension') {
        return {
          ...prev,
          dimensions: [...(prev.dimensions || []), field],
        };
      }

      return prev;
    },
    initGroups
  );

  const filteredCategorisedFields: CategorisedSchemaFields = Object.keys(
    categorisedFields
  ).reduce(
    (prev: CategorisedSchemaFields, fieldKey) =>
      categorisedFields[fieldKey].length > 0
        ? {
            ...prev,
            [fieldKey]: categorisedFields[fieldKey],
          }
        : prev,
    {}
  );

  return filteredCategorisedFields;
};

export const isDefaultMultiSelectField = (field: SchemaField): boolean => {
  const { name, subtype } = field;
  return !!(
    (subtype && MULTI_SELECT_SUBTYPES.includes(subtype)) ||
    HARD_CODED_ENRICHED_DIMENSION_NAMES.includes(name) ||
    checkIsCustomClassifierField(field) ||
    name === 'search_name' ||
    name === 'Dataset Name' ||
    GEO_LOCATION_SCHEMA_FIELDS.find((geoField) => geoField.name === name)
  );
};

export const getFilterInputGroupConfig = (
  field: SchemaField
): SchemaFieldInputConfig => {
  const defaultConfig: SchemaFieldInputConfig = {
    formItemType: 'input',
    defaultCondition: '=',
    conditionOptions: [
      { label: 'Equals', value: '=' },
      { label: 'Not Equals', value: '!=' },
      ...NULL_OPTIONS,
    ],
  };

  if (!field) {
    return defaultConfig;
  }

  const { type, name, displayName, searchKey, isCategory = false } = field;
  const formattedType = getSchemaFieldType(type);

  const fieldDisplayName = displayName || formatLabel(name);

  const isMultiSelectField = isDefaultMultiSelectField(field) || isCategory;

  switch (formattedType) {
    case 'string': {
      if (isMultiSelectField) {
        return {
          formItemType: 'multiSelect',
          defaultCondition: '=',
          conditionOptions: [
            { label: 'One Of', value: '=' },
            { label: 'None Of', value: '!=' },
            ...NULL_OPTIONS,
          ],
          displayName: fieldDisplayName,
        };
      }
      return {
        formItemType: 'input',
        defaultCondition: 'like',
        conditionOptions: [
          { label: 'Equals', value: '=' },
          { label: 'Not Equals', value: '!=' },
          { label: 'Like', value: 'like' },
          { label: 'Not Like', value: 'not like' },
          { label: 'Contains', value: 'contains' },
          { label: 'Does not contain', value: 'not contains' },
          ...NULL_OPTIONS,
        ],
        displayName: fieldDisplayName,
      };
    }

    case 'string_search':
      return {
        formItemType: 'multiSelectWithSearch',
        defaultCondition: '=',
        conditionOptions: [
          { label: 'One Of', value: '=' },
          { label: 'None Of', value: '!=' },
          ...NULL_OPTIONS,
        ],
        searchKey,
        displayName: fieldDisplayName,
      };

    case 'numeric':
    case 'int':
    case 'float': {
      if (isMultiSelectField) {
        return {
          formItemType: 'multiSelect',
          defaultCondition: '=',
          conditionOptions: [
            { label: 'One Of', value: '=' },
            { label: 'None Of', value: '!=' },
            ...NULL_OPTIONS,
          ],
          displayName: fieldDisplayName,
        };
      }
      return {
        formItemType: 'inputNumber',
        defaultCondition: '=',
        conditionOptions: [
          { label: '=', value: '=' },
          { label: '!=', value: '!=' },
          { label: '>', value: '>' },
          { label: '<', value: '<' },
          { label: '>=', value: '>=' },
          { label: '<=', value: '<=' },
          { label: 'Between', value: 'between' },
          ...NULL_OPTIONS,
        ],
        displayName: fieldDisplayName,
      };
    }

    case '_string':
    case 'array': {
      return {
        formItemType: 'multiSelect',
        defaultCondition: '&&',
        conditionOptions: [
          { label: 'Contains One Of', value: '&&' },
          { label: 'Contains All', value: '@>' },
          ...NULL_OPTIONS,
        ],
        displayName: fieldDisplayName,
      };
    }

    default:
      return {
        ...defaultConfig,
        displayName: fieldDisplayName,
      };
  }
};

export const transformLegacyDashboardFilters = (
  dashboardFilters: DashboardFilter['filterConfig'] | Filter[]
): DashboardFilter['filterConfig'] => {
  if (Array.isArray(dashboardFilters)) {
    return {
      filters: dashboardFilters,
      timeFilter: null,
    };
  }
  if (dashboardFilters.filters || dashboardFilters.timeFilter) {
    return {
      filters: Array.isArray(dashboardFilters.filters)
        ? dashboardFilters.filters
        : [],
      timeFilter: formatEmptyObject(dashboardFilters.timeFilter) || null,
    };
  }
  return { filters: [], timeFilter: null };
};

export const getFilterDisplay = (
  filter: Filter,
  sharedSchemaFields: SchemaField[] = []
) => {
  const { attribute, condition, value } = filter;

  const schemaField = sharedSchemaFields.find(
    (field) => field.name === attribute
  );

  const displayName = schemaField && schemaField.displayName;

  if (!attribute || !condition) {
    return '';
  }

  const displayPrefix = `${displayName || formatLabel(attribute)} ${condition}`;

  if (['is null', 'is not null'].includes(condition)) {
    return displayPrefix;
  }

  if (value === undefined) {
    return '';
  }

  if (
    condition === 'between' &&
    typeof value === 'object' &&
    !Array.isArray(value) &&
    value.from !== undefined &&
    value.to !== undefined
  ) {
    return `${displayPrefix} between ${value.from} and ${value.to}`;
  }

  if (Array.isArray(value) && value.length > 0) {
    const conditionMap: Record<string, string> = {
      '=': 'one of',
      '!=': 'none of',
      '&&': 'contains one of',
      '@>': 'contains all',
    };
    return `${displayName || formatLabel(attribute)} ${
      conditionMap[condition]
    } ${value.join(', ')}`;
  }

  if (typeof value === 'string' || typeof value === 'number') {
    return `${displayPrefix} ${value}`;
  }

  return '';
};

export const exportFilterBarFilters = (
  filters: { verbatim?: string; moreFilters: Filter[]; timeFilter: TimeFilter },
  fields?: { mainVerbatimFieldName?: string }
) => {
  const { verbatim, moreFilters = [], timeFilter } = filters;
  const { mainVerbatimFieldName } = fields || {};

  const defaultFilters = [
    ...(mainVerbatimFieldName
      ? [
          {
            attribute: mainVerbatimFieldName,
            condition: 'query_string',
            value: verbatim,
          },
        ]
      : []),
    ...(moreFilters || []),
  ];

  const formattedFilters = defaultFilters.reduce((prev: Filter[], filter) => {
    // Only keep filters that have condition and value
    if (
      (['is null', 'is not null'].includes(filter.condition) &&
        filter.value === undefined) ||
      (!['is null', 'is not null'].includes(filter.condition) &&
        ((Array.isArray(filter.value) && filter.value.length > 0) ||
          (typeof filter.value === 'string' && filter.value) ||
          typeof filter.value === 'number' ||
          filter.condition === 'between'))
    ) {
      return [...prev, filter];
    }

    return prev;
  }, []);

  const formattedTimeFilter = timeFilter
    ? {
        relative: timeFilter.relative || null,
        from: timeFilter.from ? moment(timeFilter.from).format() : null,
        to: timeFilter.to ? moment(timeFilter.to).format() : null,
      }
    : null;

  return {
    filters: formattedFilters,
    timeFilter: formatEmptyObject(formattedTimeFilter),
  };
};

export const importFilterBarFilters = (
  filters = [],
  sharedSchemaFields = [],
  noVerbatim = false
) => {
  const mainVerbatimFieldName = getMainVerbatimFieldName(sharedSchemaFields);

  return filters.reduce((prev, filter) => {
    const { attribute, condition, value } = filter;

    // Add verbatim
    if (
      !noVerbatim &&
      attribute === mainVerbatimFieldName &&
      (condition === 'like' || condition === 'query_string') // Notes: add "query_string" condition to allow mapping the "text" / verbatim field back to the filter UI
    ) {
      return {
        ...prev,
        verbatim: value,
      };
    }

    // Check existing filter
    if (prev.moreFilters.find((moreFilter) => isEqual(moreFilter, filter))) {
      return prev;
    }

    return {
      ...prev,
      moreFilters: [...prev.moreFilters, filter],
    };
  }, filterBarInitialState);
};

export const getLocationPageMetrics = (datasets: Dataset[] = []) => {
  const sharedSchemaFields = getSharedSchemaFields(datasets);
  const categorisedSchemaFields = getCategorisedSchemaFields(
    sharedSchemaFields,
    {}
  );

  const locationPageMetricFields = [
    ...(categorisedSchemaFields['Enriched Metrics'] || []),
    ...(categorisedSchemaFields.metrics || []),
  ];

  const defaultMetric = {
    sqlFunction: 'count',
    attribute: '*',
    as: 'Count Documents',
  };

  return locationPageMetricFields.reduce(
    (prev, metricField) => {
      return [
        ...prev,
        {
          sqlFunction: 'avg',
          attribute: metricField.name,
          as: capitalize(
            `avg ${metricField.displayName || formatLabel(metricField.name)}`
          ),
        },
      ];
    },
    [defaultMetric]
  );
};

export const getLocationDimensionByZoom = (zoom: number) => {
  let geoLocationDimensionField;
  if (zoom < 6) {
    geoLocationDimensionField = GEO_LOCATION_SCHEMA_FIELDS.find(
      (field) => field.displayName === 'State'
    );
  } else if (zoom < 10) {
    geoLocationDimensionField = GEO_LOCATION_SCHEMA_FIELDS.find(
      (field) => field.displayName === 'LGA'
    );
  } else {
    geoLocationDimensionField = GEO_LOCATION_SCHEMA_FIELDS.find(
      (field) => field.displayName === 'Suburb'
    );
  }
  return geoLocationDimensionField;
};

const deepFindTermNodeEndLocation = (luceneAST: Node | AST): number | null => {
  if ('right' in luceneAST) {
    if ('term' in luceneAST.right) {
      const { termLocation } = luceneAST.right;
      return termLocation.end.offset;
    }
    return deepFindTermNodeEndLocation(luceneAST.right);
  }
  return null;
};

export const getLuceneNodeRange = (
  node: LuceneNode
): { start: number; end: number } | null => {
  // Term
  if ('term' in node) {
    const { fieldLocation, termLocation } = node;
    if (fieldLocation) {
      return {
        start: fieldLocation.start.offset,
        end: termLocation.end.offset,
      };
    }
    return { start: termLocation.start.offset, end: termLocation.end.offset };
  }
  // Ranged Term
  if ('term_max' in node) {
    const { fieldLocation, term_max, term_min } = node;
    if (fieldLocation) {
      const rangedTermEndOffset =
        fieldLocation.end.offset +
        2 +
        term_min.length +
        4 +
        term_max.length +
        1;
      return { start: fieldLocation.start.offset, end: rangedTermEndOffset };
    }
    return null;
  }
  // Nested Term
  if ('left' in node && node.parenthesized) {
    const { fieldLocation, left: leftNode } = node;
    if (fieldLocation) {
      // Left and Right
      if (node.right) {
        const endLocation = deepFindTermNodeEndLocation(node);
        if (typeof endLocation === 'number') {
          return { start: fieldLocation.start.offset, end: endLocation + 1 };
        }
        return null;
      }
      // Left Only
      const leftNodeRange = getLuceneNodeRange(leftNode);
      if (leftNodeRange) {
        return {
          start: fieldLocation.start.offset,
          end: leftNodeRange.end + 1,
        };
      }
    }
    return null;
  }
  return null;
};

export const deepFindLuceneNode = (
  luceneAST: LuceneNode | AST,
  targetPos: number
): LuceneNode | null => {
  if ('left' in luceneAST) {
    // Nested Term
    if ('field' in luceneAST) {
      const nodeRange = getLuceneNodeRange(luceneAST);
      if (
        nodeRange &&
        targetPos >= nodeRange.start &&
        targetPos <= nodeRange.end
      ) {
        return luceneAST;
      }
    }
    // Left Only Term
    const leftNode = luceneAST.left;
    if ('field' in leftNode) {
      const nodeRange = getLuceneNodeRange(leftNode);
      if (
        nodeRange &&
        targetPos >= nodeRange.start &&
        targetPos <= nodeRange.end
      ) {
        return leftNode;
      }
    }
    // Left and Right
    if ('right' in luceneAST && !('field' in luceneAST)) {
      return deepFindLuceneNode(luceneAST.right, targetPos);
    }

    return null;
  }
  const nodeRange = getLuceneNodeRange(luceneAST);
  if (nodeRange && targetPos >= nodeRange.start && targetPos <= nodeRange.end) {
    return luceneAST;
  }
  return null;
};

export const deepFindAllNestedTermStrings = (
  nestedNode: LuceneNodeNested | AST,
  result: string[] = []
): string[] => {
  if ('left' in nestedNode && 'term' in nestedNode.left) {
    result.push(nestedNode.left.term);
  }
  if ('right' in nestedNode && nestedNode.right) {
    if ('term' in nestedNode.right) {
      result.push(nestedNode.right.term);
    } else if ('left' in nestedNode.right) {
      return deepFindAllNestedTermStrings(nestedNode.right, result);
    }
  }
  return result;
};

const findPresetDateTerm = (node: Node): NodeRangedTerm | null => {
  if ('term' in node) {
    const { term, field, fieldLocation } = node;
    const fromDate = byValue[term]?.from;
    const toDate = byValue[term]?.to;
    if (fromDate && toDate) {
      const fromDateString = fromDate.format('YYYY-MM-DD');
      const toDateString = toDate.format('YYYY-MM-DD');
      return {
        field,
        fieldLocation,
        inclusive: 'both',
        term_max: toDateString,
        term_min: fromDateString,
      };
    }
    return null;
  }
  return null;
};

export const formatFinalLuceneInput = (input: string): string => {
  const luceneAST = lucene.parse(input);

  // Replace preset date with the actual date range
  const deepReplacePresetDateLuceneTerms = (
    currentLuceneAST: Node | AST,
    setterPath: string[] = []
  ) => {
    if ('left' in currentLuceneAST) {
      if ('field' in currentLuceneAST.left) {
        const presetDateTerm = findPresetDateTerm(currentLuceneAST.left);
        if (presetDateTerm) {
          set(luceneAST, [...setterPath, 'left'], presetDateTerm);
        }
      }
      if ('right' in currentLuceneAST) {
        deepReplacePresetDateLuceneTerms(currentLuceneAST.right, [
          ...setterPath,
          'right',
        ]);
      }
    } else {
      const presetDateTerm = findPresetDateTerm(currentLuceneAST);
      if (presetDateTerm) {
        set(luceneAST, setterPath, presetDateTerm);
      }
    }
  };

  deepReplacePresetDateLuceneTerms(luceneAST);

  const formattedInput = lucene.toString(luceneAST);

  return formattedInput;
};

export const LUCENE_DEFAULT_TERM_NODE: NodeTerm = {
  field: '<implicit>',
  fieldLocation: null,
  term: '',
  quoted: false,
  termLocation: {
    start: { offset: 0, line: 0, column: 0 },
    end: { offset: 0, line: 0, column: 0 },
  },
  boost: null,
  prefix: null,
  regex: false,
  similarity: null,
};

const findDeepestNestedNodePath = (
  node: LuceneNodeNested | LuceneNodeNestedRight,
  path: string[] = []
): string[] => {
  if ('right' in node && node.right) {
    return findDeepestNestedNodePath(node.right, [...path, 'right']);
  }
  if ('term' in node) {
    return path;
  }
  return path;
};

export const addTermToNestedNode = (
  currentNode: LuceneNodeNested,
  newTermString: string
): LuceneNodeNested => {
  const clonedNode = cloneDeep(currentNode);
  const deepestNodePath = findDeepestNestedNodePath(clonedNode);
  const deepestNode: NodeTerm = get(clonedNode, deepestNodePath);
  if (deepestNodePath.length === 0) {
    const newOperator: Operator = 'OR';
    const newTerm: NodeTerm = {
      ...LUCENE_DEFAULT_TERM_NODE,
      term: newTermString,
      quoted: true,
    };
    set(clonedNode, ['operator'], newOperator);
    set(clonedNode, ['right'], newTerm);
  } else {
    const newNode: { left: NodeTerm; operator: Operator; right: NodeTerm } = {
      left: deepestNode,
      operator: 'OR',
      right: { ...LUCENE_DEFAULT_TERM_NODE, term: newTermString, quoted: true },
    };
    set(clonedNode, deepestNodePath, newNode);
  }
  return clonedNode;
};

const findNestedNodeTermPath = (
  currentNode: LuceneNodeNested | LuceneNodeNestedRight,
  targetString: string,
  path: string[] = []
): string[] => {
  if ('left' in currentNode && 'term' in currentNode.left) {
    if (currentNode.left.term === targetString) {
      return [...path, 'left'];
    }
  }
  if ('right' in currentNode && currentNode.right) {
    if ('term' in currentNode.right) {
      if (currentNode.right.term === targetString) {
        return [...path, 'right'];
      }
    }
    if ('left' in currentNode.right) {
      return findNestedNodeTermPath(currentNode.right, targetString, [
        ...path,
        'right',
      ]);
    }
  }
  return path;
};

export const removeTermFromNestedNode = (
  currentNode: LuceneNodeNested,
  removedTermString: string
): AST => {
  const clonedNode = cloneDeep(currentNode);
  const termNodePath = findNestedNodeTermPath(clonedNode, removedTermString);
  if (!('right' in clonedNode)) {
    return {
      left: { ...LUCENE_DEFAULT_TERM_NODE, term: clonedNode.field },
    };
  }
  if (termNodePath.length === 1 && termNodePath[0] === 'left') {
    set(clonedNode, ['operator'], null);
  }
  try {
    set(clonedNode, [...termNodePath, 'term'], null);
    const luceneString = lucene.toString(clonedNode);
    const finalLuceneAST = lucene.parse(
      lucene.toString(lucene.parse(luceneString))
    );
    return finalLuceneAST;
  } catch {
    // Fallback to default node object if the parse/toString went wrong
    return {
      left: { ...LUCENE_DEFAULT_TERM_NODE, term: clonedNode.field },
    };
  }
};
