import moment from 'moment-timezone';

type AllValues = (string | { title: string; values: string[] })[];
type ByValue = {
  [key: string]: {
    label: string;
    from?: moment.Moment;
    to?: moment.Moment;
    displayTimeInterval?: string;
  };
};

const getPresetDateRanges = (): { allValues: AllValues; byValue: ByValue } => ({
  allValues: [
    'today',
    'yesterday',
    {
      title: 'This week',
      values: [
        'this_week_sun_today',
        'this_week_mon_today',
        'this_full_week_from_sun',
        'this_full_week_from_mon',
      ],
    },
    {
      title: 'Last week',
      values: ['last_week_sun_sat', 'last_week_mon_sun'],
    },
    'last_7_days',
    'last_30_days',
    {
      title: 'This month',
      values: ['this_calendar_month', 'this_calendar_full_month'],
    },
    'last_calendar_month',
    {
      title: 'Last X months',
      values: ['last_3_months', 'last_6_months', 'last_12_months'],
    },
    {
      title: 'Calendar Quarters',
      values: [
        'this_calendar_quarter',
        'last_calendar_quarter',
        'cq1',
        'cq2',
        'cq3',
        'cq4',
      ],
    },
    {
      title: 'Financial Quarters',
      values: ['fq1', 'fq2', 'fq3', 'fq4'],
    },
    {
      title: 'This year',
      values: ['this_year', 'this_full_year'],
    },
    'last_calendar_year',
    {
      title: 'This Financial Year',
      values: ['this_fin_year', 'this_full_fin_year'],
    },
    'last_fin_year',
    'custom',
  ],
  byValue: {
    today: {
      label: 'Today',
      from: moment().startOf('day'),
      to: moment().endOf('day'),
      displayTimeInterval: 'full',
    },
    yesterday: {
      label: 'Yesterday',
      from: moment().subtract(1, 'days').startOf('day'),
      to: moment().subtract(1, 'days').endOf('day'),
      displayTimeInterval: 'full',
    },
    this_week_sun_today: {
      label: 'This week (Sun - Today)',
      from: moment().startOf('week'),
      to: moment().endOf('day'),
      displayTimeInterval: 'day',
    },
    this_week_mon_today: {
      label: 'This week (Mon - Today)',
      from: moment().startOf('isoWeek'),
      to: moment().endOf('day'),
      displayTimeInterval: 'day',
    },
    this_full_week_from_sun: {
      label: 'This week (Sun - Sat)',
      from: moment().startOf('week'),
      to: moment().endOf('week'),
      displayTimeInterval: 'day',
    },
    this_full_week_from_mon: {
      label: 'This week (Mon - Sun)',
      from: moment().startOf('isoWeek'),
      to: moment().endOf('isoWeek'),
      displayTimeInterval: 'day',
    },
    last_week_sun_sat: {
      label: 'This week (Sun - Sat)',
      from: moment().subtract(1, 'weeks').startOf('week'),
      to: moment().subtract(1, 'weeks').endOf('week'),
      displayTimeInterval: 'day',
    },
    last_week_mon_sun: {
      label: 'This week (Mon - Sun)',
      from: moment().subtract(1, 'weeks').startOf('isoWeek'),
      to: moment().subtract(1, 'weeks').endOf('isoWeek'),
      displayTimeInterval: 'day',
    },
    last_7_days: {
      label: 'Last 7 days',
      from: moment().subtract(6, 'days').startOf('day'),
      to: moment().endOf('day'),
      displayTimeInterval: 'day',
    },
    last_30_days: {
      label: 'Last 30 days',
      from: moment().subtract(29, 'days').startOf('day'),
      to: moment().endOf('day'),
      displayTimeInterval: 'day',
    },
    last_30_days_offset: {
      label: 'Last 30 days backset 30 days',
      from: moment().subtract(59, 'days').startOf('day'),
      to: moment().subtract(30, 'days').endOf('day'),
      displayTimeInterval: 'day',
    },
    this_calendar_month: {
      label: 'This month (1st - Today)',
      from: moment().startOf('month'),
      to: moment().endOf('day'),
      displayTimeInterval: 'day',
    },
    this_calendar_full_month: {
      label: 'This month (1st - End)',
      from: moment().startOf('month'),
      to: moment().endOf('month'),
      displayTimeInterval: 'day',
    },
    last_calendar_month: {
      label: 'Last month',
      from: moment().subtract(1, 'months').startOf('month'),
      to: moment().subtract(1, 'months').endOf('month'),
      displayTimeInterval: 'day',
    },
    last_3_months: {
      label: 'Last 3 months',
      from: moment().subtract(3, 'months').startOf('day'),
      to: moment().endOf('day'),
      displayTimeInterval: 'day',
    },
    last_6_months: {
      label: 'Last 6 months',
      from: moment().subtract(6, 'months').startOf('day'),
      to: moment().endOf('day'),
      displayTimeInterval: 'day',
    },
    last_12_months: {
      label: 'Last 12 months',
      from: moment().subtract(12, 'months').startOf('day'),
      to: moment().endOf('day'),
      displayTimeInterval: 'day',
    },
    last_calendar_year: {
      label: 'Last year',
      from: moment().subtract(1, 'years').startOf('year'),
      to: moment().subtract(1, 'years').endOf('year'),
      displayTimeInterval: 'day',
    },
    cq1: {
      label: 'Q1 - Jan, Feb, Mar',
      from: moment().quarter(1).startOf('quarter'),
      to: moment().quarter(1).endOf('quarter'),
      displayTimeInterval: 'day',
    },
    cq2: {
      label: 'Q2 - Apr, May, Jun',
      from: moment().quarter(2).startOf('quarter'),
      to: moment().quarter(2).endOf('quarter'),
      displayTimeInterval: 'day',
    },
    cq3: {
      label: 'Q3 - Jul, Aug, Sep',
      from: moment().quarter(3).startOf('quarter'),
      to: moment().quarter(3).endOf('quarter'),
      displayTimeInterval: 'day',
    },
    cq4: {
      label: 'Q4 - Oct, Nov, Dec',
      from: moment().quarter(4).startOf('quarter'),
      to: moment().quarter(4).endOf('quarter'),
      displayTimeInterval: 'day',
    },
    this_calendar_quarter: {
      label: 'This Calendar Quarter',
      from: moment().startOf('quarter'),
      to: moment().endOf('quarter'),
      displayTimeInterval: 'day',
    },
    last_calendar_quarter: {
      label: 'Last Calendar Quarter',
      from: moment().subtract(1, 'quarters').startOf('quarter'),
      to: moment().subtract(1, 'quarters').endOf('quarter'),
      displayTimeInterval: 'day',
    },
    fq1: {
      label: 'FQ1 - Jul, Aug, Sep',
      from:
        moment().month() >= 6
          ? moment().month('Jul').startOf('month')
          : moment().subtract(1, 'year').month('Jul').startOf('month'),
      to:
        moment().month() >= 6
          ? moment().month('Sep').endOf('month')
          : moment().subtract(1, 'year').month('Sep').endOf('month'),
      displayTimeInterval: 'day',
    },
    fq2: {
      label: 'FQ2 - Oct, Nov, Dec',
      from:
        moment().month() >= 6
          ? moment().month('Oct').startOf('month')
          : moment().subtract(1, 'year').month('Oct').startOf('month'),
      to:
        moment().month() >= 6
          ? moment().month('Dec').endOf('month')
          : moment().subtract(1, 'year').month('Dec').endOf('month'),
      displayTimeInterval: 'day',
    },
    fq3: {
      label: 'FQ3 - Jan, Feb, Mar',
      from:
        moment().month() >= 6
          ? moment().add(1, 'year').month('Jan').startOf('month')
          : moment().month('Jan').startOf('month'),
      to:
        moment().month() >= 6
          ? moment().add(1, 'year').month('Mar').endOf('month')
          : moment().month('Mar').endOf('month'),
      displayTimeInterval: 'day',
    },
    fq4: {
      label: 'FQ4 - Apr, May, Jun',
      from:
        moment().month() >= 6
          ? moment().add(1, 'year').month('Apr').startOf('month')
          : moment().month('Apr').startOf('month'),
      to:
        moment().month() >= 6
          ? moment().add(1, 'year').month('Jun').endOf('month')
          : moment().month('Jun').endOf('month'),
      displayTimeInterval: 'day',
    },
    this_year: {
      label: 'This year (Jan - Today)',
      from: moment().startOf('year'),
      to: moment().endOf('day'),
      displayTimeInterval: 'day',
    },
    this_full_year: {
      label: 'This year (Jan - Dec)',
      from: moment().startOf('year'),
      to: moment().endOf('year'),
      displayTimeInterval: 'day',
    },
    this_fin_year: {
      label: 'This Financial Year (Jul - Today)',
      from:
        moment().month() >= 6
          ? moment().month('Jul').startOf('month')
          : moment().subtract(1, 'year').month('Jul').startOf('month'),
      to: moment().endOf('day'),
      displayTimeInterval: 'day',
    },
    this_full_fin_year: {
      label: 'This Financial Year (Jul - Jun)',
      from:
        moment().month() >= 6
          ? moment().month('Jul').startOf('month')
          : moment().subtract(1, 'year').month('Jul').startOf('month'),
      to:
        moment().month() >= 6
          ? moment().add(1, 'year').month('Jun').endOf('month')
          : moment().month('Jun').endOf('month'),
      displayTimeInterval: 'day',
    },
    last_fin_year: {
      label: 'Last Financial Year (Jul - Jun)',
      from:
        moment().month() >= 6
          ? moment().subtract(1, 'year').month('Jul').startOf('month')
          : moment().subtract(2, 'year').month('Jul').startOf('month'),
      to:
        moment().month() >= 6
          ? moment().month('Jun').endOf('month')
          : moment().subtract(1, 'year').month('Jun').endOf('month'),
      displayTimeInterval: 'day',
    },
    custom: {
      label: 'Custom',
    },
  },
});

export default getPresetDateRanges;
