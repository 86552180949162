/**
 * Remove an item from a list
 * @param {array} list
 * @param {*} item
 */
export const removeItem = (list: any[] = [], item: any): any[] => {
  const index = list.indexOf(item);
  if (index > -1) {
    return [...list.slice(0, index), ...list.slice(index + 1)];
  }
  return list;
};

/**
 * Add an item to a list
 * @param {array} list
 * @param {*} item
 */
export const addItem = (list: any[] = [], item: any) => {
  return [...list, item];
};

/**
 * Convert an array of objects to an id keyed object
 * @param {array} array
 * @param {string} key
 */
export const arrayToObject = (array = [], key = 'id') =>
  array.reduce((obj, item) => {
    return { ...obj, [item[key]]: item };
  }, {});

export const objectToArray = (obj = {}) => {
  if (!obj) return [];
  return Object.values(obj);
};

/**
 * Extract array of keys (id) from an array of objects
 * @param {array} array
 * @param {string} key
 */
export const extractIds = (array = [], key = 'id') =>
  array.map((item) => item[key]);

/**
 * Remove a key from an object
 * @param {object} obj
 * @param {*} key
 */
export const removeObjectKey = (obj: Record<string, any> = {}, key: string) => {
  const { [key]: omit, ...res } = obj;
  return res;
};

/**
 * Remove empty ('' || null || undefined) values from object
 * @param {object} obj
 */
export const removeEmptyObjectValues = (obj: Record<string, any> = {}) =>
  Object.keys(obj).reduce(
    (prev, key) =>
      obj[key] || typeof obj[key] === 'number'
        ? { ...prev, [key]: obj[key] }
        : prev,
    {}
  );

/**
 * Remove null values from object
 * @param {object} obj
 */
export const deepRemoveNullObjectValues = (
  obj: Record<string, any> = {}
): Record<string, any> =>
  Object.keys(obj).reduce((prev, key) => {
    if (obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      return { ...prev, [key]: deepRemoveNullObjectValues(obj[key]) };
    }
    if (obj[key] === null) {
      return prev;
    }
    return { ...prev, [key]: obj[key] };
  }, {});

export const filterObjectKeys = (obj = {}, array = []) => {
  const res = {};
  array.forEach((item) => {
    res[item] = obj[item] || [];
  });
  return res;
};

/**
 * Remove properties from object
 * @param {object} obj object keys are to be removed from
 * @param {string[]} array keys to be removed from object
 */
export const omit = (obj = {}, array = []) => {
  const res = { ...obj };
  array.forEach((key) => {
    delete res[key];
  });
  return res;
};

export const containsAnyString = (str: string, items: string[]): boolean => {
  return items.some((item) => str.indexOf(item) > -1);
};

export const formatEmptyObject = (obj: any = {}) => {
  if (!obj) return null;
  const formattedObj = removeEmptyObjectValues(obj);
  if (Object.keys(formattedObj).length === 0) return null;
  return formattedObj;
};
