/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

// Import Ant Design
import { Form, Input, Select, Row, Col } from 'antd';

import { preset } from 'styles';
import ValidationFormContainer from '@components/ValidationFormContainer';

const FormItem = Form.Item;
const { Option } = Select;

const ProjectInfoForm = ({
  formValues = {
    name: '',
    collaborators: [],
  },
  users = [],
  onFormValueChange = () => null,
  valid = false,
}) => {
  const { name, collaborators } = formValues;

  return (
    <ValidationFormContainer title="Project Information" valid={valid}>
      <Form layout="vertical">
        <Row gutter={preset.spacing(5)}>
          <Col span={12}>
            <FormItem label="Project Name" required>
              <Input
                autoFocus
                value={name}
                onChange={(e) => onFormValueChange(e.target.value, 'name')}
              />
            </FormItem>
            <FormItem label="Collaborators">
              <Select
                showSearch
                mode="multiple"
                placeholder="Add Collaborators"
                optionFilterProp="children"
                value={collaborators}
                onChange={(value) => onFormValueChange(value, 'collaborators')}
              >
                {users.map((user) => (
                  <Option key={user.id} title={user.email} value={user.id}>
                    {user.email}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </ValidationFormContainer>
  );
};

ProjectInfoForm.propTypes = {
  formValues: PropTypes.shape({
    name: PropTypes.string,
    collaborators: PropTypes.arrayOf(PropTypes.string),
  }),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
    })
  ),
  onFormValueChange: PropTypes.func,
  valid: PropTypes.bool,
};

export default ProjectInfoForm;
