type AxisPosition = 'top' | 'bottom' | 'left' | 'right' | 'hidden' | undefined;

const getAxisConfig = (config: any, type: 'x' | 'y') => {
  const axisPosition: AxisPosition = config?.axis?.[type]?.position;
  return {
    ...(axisPosition
      ? axisPosition === 'hidden'
        ? { label: null, line: null, tickLine: null }
        : { position: axisPosition }
      : {}),
    ...(type === 'y'
      ? { grid: { hideFirstLine: false, hideLastLine: false } }
      : {}),
  };
};

export default getAxisConfig;
