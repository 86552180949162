/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';

import { Select, Input, Collapse, Form } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import formatLabel from '@modules/formatLabel';

import StyledCollapse from '../../StyledCollapse';
import MetricFilterCollapse from './MetricFilterCollapse';

const { Panel } = Collapse;
const { Option, OptGroup } = Select;

const Metrics = ({
  value: metrics = [],
  attributeOptions = {},
  onChange,
  onRemove,
  // handle metric as field change
  onAsFieldChange,
  // for metric filter config
  sharedSchemaFields,
  filterOptions = {},
  formatRequiredAttributes = [],
  datasetIds = [],
}) => {
  const onMetricAttributeChange = (
    currentMetric,
    newAttributeOption,
    index
  ) => {
    const { value: newAttribute } = newAttributeOption;
    let { children: newAttributeDisplay } = newAttributeOption;
    if (currentMetric.sqlFunction === 'count' && newAttribute !== '*') {
      newAttributeDisplay = `Unique ${newAttributeDisplay}`;
    }

    onChange(newAttribute, 'attribute', index);

    const defaultAs = currentMetric.sqlFunction
      ? `${formatLabel(currentMetric.sqlFunction)} ${newAttributeDisplay}`
      : newAttributeDisplay;

    onChange(defaultAs, 'as', index);

    onAsFieldChange(currentMetric.as, defaultAs);
  };

  const onMetricAsChange = (oldAs, newAs, index) => {
    onChange(newAs, 'as', index);

    onAsFieldChange(oldAs, newAs);
  };

  const onMetricAsBlur = (oldAs, newAs, index) => {
    if (newAs && newAs !== newAs.trim()) {
      onMetricAsChange(oldAs, newAs.trim(), index);
    }
  };

  const onMetricRemove = (metric, index) => {
    onRemove(index);

    // Need also remove related orderBy when remove metric
    if (metric.as) {
      onAsFieldChange(metric.as);
    }
  };

  // Exclude time from filter attribute options
  const filterAttributeOptions = useMemo(() => {
    const { time, ...otherOptions } = attributeOptions;
    return otherOptions;
  }, [attributeOptions]);

  return (
    <StyledCollapse>
      {metrics.map((metric, index) => (
        <Panel
          key={`metric_${index + 1}`}
          header={metric.as || `Metric ${index + 1}`}
          extra={
            metrics.length > 1 && (
              <DeleteOutlined
                key="delete"
                onClick={(e) => {
                  e.stopPropagation();
                  onMetricRemove(metric, index);
                }}
              />
            )
          }
        >
          <Form.Item label="Function">
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              onChange={(val) => onChange(val, 'sqlFunction', index)}
              value={metric.sqlFunction}
              placeholder="Select Function"
            >
              <Option value="count">Count</Option>
              <Option value="sum">Sum</Option>
              <Option value="avg">Average</Option>
              <Option value="min">Minimum</Option>
              <Option value="max">Maximum</Option>
              <Option value="stddev_pop">
                Standard Deviation (Population)
              </Option>
              <Option value="var_pop">Variance (Population)</Option>
              <Option value="impact">Impact</Option>
              <Option value="nps">NPS</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Attribute" required>
            <Select
              showSearch
              placeholder="Select Attribute"
              optionFilterProp="children"
              value={metric.attribute}
              onChange={(newAttribute, newAttributeOption) =>
                onMetricAttributeChange(metric, newAttributeOption, index)
              }
            >
              <OptGroup label="Documents">
                <Option value="*">Documents</Option>
              </OptGroup>
              {Object.keys(attributeOptions).map((optionGroupKey) => (
                <OptGroup
                  key={optionGroupKey}
                  label={formatLabel(optionGroupKey)}
                >
                  {attributeOptions[optionGroupKey].map((field) => (
                    <Option key={field.name} value={field.name}>
                      {field.displayName || formatLabel(field.name)}
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="As" required>
            <Input
              value={metric.as}
              onChange={(e) =>
                onMetricAsChange(metric.as, e.target.value, index)
              }
              onBlur={(e) => onMetricAsBlur(metric.as, e.target.value, index)}
            />
          </Form.Item>

          <MetricFilterCollapse
            filter={metric.filter || {}}
            onFilterChange={(value) => onChange(value, 'filter', index)}
            // for filter config
            sharedSchemaFields={sharedSchemaFields}
            attributeOptions={filterAttributeOptions}
            filterOptions={filterOptions}
            formatRequiredAttributes={formatRequiredAttributes}
            datasetIds={datasetIds}
          />
        </Panel>
      ))}
    </StyledCollapse>
  );
};

export default Metrics;
