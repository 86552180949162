/** @jsxImportSource @emotion/react */
import { useState, useContext, useEffect } from 'react';

import {
  Form,
  Input,
  Modal,
  message,
  List,
  Card,
  Select,
  Collapse,
} from 'antd';
import { useHistory } from 'react-router-dom';

import { trackEvent } from 'analytics';
import { colors, preset } from 'styles';
import useGetApi from '@modules/hooks/useGetApi';
import useCheckPermission from '@modules/hooks/useCheckPermission';
import { postApi, deleteApi, fetchApiErrorMessage } from '@modules/api';
import { ProjectContext } from '@providers/ProjectProvider';

import CXTable from '@components/CXTable';
import CXToolTip from '@components/CXToolTip';

const { Panel } = Collapse;

const Dashboards = () => {
  const history = useHistory();
  const {
    project: { slug: projectSlug, datasets },
  } = useContext(ProjectContext);

  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [newDashboardName, setNewDashboardName] = useState();
  const [isCreatingDashboard, setIsCreatingDashboard] = useState(false);
  const [selectedDefaultType, setSelectedDefaultType] = useState();
  const [selectedDatasets, setSelectedDatasets] = useState([]);
  const [allDatasetIds, setAllDatasetIds] = useState([]);

  const canCreateDashboard = useCheckPermission(['insight.createDashboards']);
  const canDeleteDashboard = useCheckPermission(['insight.manageDashboards']);

  useEffect(() => {
    const datasetIds = datasets.map((dataset) => dataset.id);
    setSelectedDatasets(datasetIds);
    setAllDatasetIds(datasetIds);
  }, [datasets]);

  useEffect(() => {
    const datasetIds = datasets.map((dataset) => dataset.id);
    setSelectedDatasets(datasetIds);
    setAllDatasetIds(datasetIds);
  }, [datasets]);

  // Fetch project dashboards
  const {
    data: dashboards = [],
    loading,
    setData: setDashboards,
  } = useGetApi(`projects/${projectSlug}/dashboards`);

  const dashboardNameValidate = () => {
    if (newDashboardName && newDashboardName.trim()) {
      if (dashboards.find((dashboard) => dashboard.name === newDashboardName)) {
        message.error('Dashboard name must be unique');
        return false;
      }
      return true;
    }
    message.error('Dashboard name cannot be empty');
    return false;
  };

  const createDashboard = async () => {
    if (dashboardNameValidate()) {
      setIsCreatingDashboard(true);

      try {
        const { data: newDashboard } = await postApi(
          `projects/${projectSlug}/dashboards`,
          {
            name: newDashboardName,
            defaultType: selectedDefaultType,
            datasets: selectedDatasets,
          }
        );

        setDashboards((prevDashboards) => [...prevDashboards, newDashboard]);

        setCreateModalOpen(false);
        message.success('Dashboard Created');
        history.push(`dashboards/${newDashboard.slug}`);
      } catch (error) {
        message.error(fetchApiErrorMessage(error));
      }

      setIsCreatingDashboard(false);
    }
  };

  const deleteDashboard = async (dashboard) => {
    const { slug } = dashboard;
    try {
      await deleteApi(`projects/${projectSlug}/dashboards/${slug}`);

      trackEvent('Delete Dashboard', { dashboardSlug: slug });

      setDashboards((prevDashboards) =>
        prevDashboards.filter((prevDashboard) => prevDashboard.slug !== slug)
      );

      message.success('Dashboard Deleted');
    } catch (error) {
      trackEvent('Delete Dashboard Error', { dashboardSlug: slug });
      message.error(fetchApiErrorMessage(error, 'Unable to Delete Dashboard'));
    }
  };

  const defaultDashboardsOptions = [
    {
      type: 'operational',
      title: 'Operational',
      description: 'Best for viewing data sourced from Meltwater',
      defaultName: 'Operational',
    },
    {
      type: 'reviews',
      title: 'Reviews',
      description: 'Best for viewing reviews sourced from Review Shake',
      defaultName: 'Reviews',
    },
    {
      type: 'blank',
      title: 'Blank',
      description: 'A blank dashboard to start from.',
      defaultName: 'My Blank Dashboard',
    },
  ];

  const emptyContent = (
    <div>
      <div css={{ marginBottom: preset.spacing(3) }}>
        Looks like this Project has no Dashboards yet, try selecting one of
        these pre-built ones or start with a blank one.
      </div>
      <List
        grid={{
          gutter: preset.spacing(2),
          column: defaultDashboardsOptions.length,
        }}
        dataSource={defaultDashboardsOptions}
        renderItem={(item) => (
          <List.Item>
            <Card
              hoverable
              onClick={() => {
                setSelectedDefaultType(item.type);
                setNewDashboardName(item.defaultName);
                setCreateModalOpen(true);
              }}
            >
              <Card.Meta title={item.title} description={item.description} />
            </Card>
          </List.Item>
        )}
      />
    </div>
  );

  return (
    <div>
      <CXTable
        loading={loading}
        title="Dashboards"
        columns={[
          { title: 'Name', dataIndex: 'name', searchable: true },
          { title: 'Modified', dataIndex: 'updatedAt', type: 'date' },
          ...(canDeleteDashboard
            ? [
                {
                  title: 'Delete',
                  type: 'deleteButton',
                  onClick: deleteDashboard,
                },
              ]
            : []),
        ]}
        dataSource={dashboards}
        singularUnit="dashboard"
        pluralUnit="dashboards"
        rowKey="slug"
        onItemClick={(dashboard) =>
          history.push(`/projects/${projectSlug}/dashboards/${dashboard.slug}`)
        }
        onCreateNew={canCreateDashboard ? () => setCreateModalOpen(true) : null}
        projectSlug={projectSlug}
        canSearch
        emptyText={emptyContent}
      />
      <Modal
        data-testId="create-dashboard-modal"
        title="Create Dashboard"
        visible={isCreateModalOpen}
        onCancel={() => setCreateModalOpen(false)}
        okText="Create"
        onOk={createDashboard}
        okButtonProps={{
          loading: isCreatingDashboard,
        }}
      >
        <Form.Item labelCol={{ span: 24 }} label="Dashboard name" required>
          <Input
            data-testId="dashboard-name-input"
            placeholder="Enter Dashboard Name"
            value={newDashboardName}
            onChange={(e) => setNewDashboardName(e.target.value)}
            css={{ marginBottom: preset.spacing(2) }}
          />
        </Form.Item>

        <Collapse
          accordion
          onChange={(type) => {
            const sameType = selectedDefaultType === type || !type;
            setSelectedDefaultType(sameType ? null : type);
            setNewDashboardName(
              sameType
                ? null
                : defaultDashboardsOptions.find(
                    (option) => option.type === type
                  ).defaultName
            );
          }}
          activeKey={selectedDefaultType}
          bordered={false}
          css={{
            backgroundColor: 'transparent',
            marginLeft: -12,
            marginRight: -12,
          }}
        >
          {defaultDashboardsOptions.map((dashboardOption) => (
            <Panel
              style={{ border: 0 }}
              showArrow={false}
              key={dashboardOption.type}
              header={
                <Card
                  hoverable
                  {...(selectedDefaultType === dashboardOption.type && {
                    css: { borderColor: `${colors.primary} !important` },
                  })}
                >
                  <Card.Meta
                    title={dashboardOption.title}
                    description={dashboardOption.description}
                  />
                </Card>
              }
            >
              {selectedDefaultType && selectedDefaultType !== 'blank' && (
                <Form.Item
                  labelCol={{ span: 24 }}
                  label={
                    <CXToolTip
                      text="Included datasets"
                      tip="The datasets used to generate the charts for this dashboard. You will still be able to add charts with different dataset or edits the charts made."
                    />
                  }
                  required
                >
                  <Select
                    css={{ width: '100%' }}
                    name="datasets"
                    showSearch
                    mode="multiple"
                    optionFilterProp="children"
                    onChange={(ids) =>
                      setSelectedDatasets(
                        !ids || ids.length === 0 ? allDatasetIds : ids
                      )
                    }
                    value={selectedDatasets}
                  >
                    {datasets.map((dataset) => (
                      <Select.Option key={dataset.id} value={dataset.id}>
                        {dataset.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Panel>
          ))}
        </Collapse>
      </Modal>
    </div>
  );
};

export default Dashboards;
