/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';

import { TIME_INTERVALS } from 'constant';
import StyledCollapse from '../StyledCollapse';
import GaugeConfig from './components/GaugeConfig';
import BarBasicConfig from './components/BarBasicConfig';
import ColorPanel from './components/ColorPanel';
import RangeColorPanel from './components/RangeColorPanel';
import AxisPanel from './components/AxisPanel';
import LabelPanel from './components/LabelPanel';
import LegendPanel from './components/LegendPanel';
import GeoMapConfig from './components/GeoMapConfig';
import PlottedBubbleConfig from './components/PlottedBubbleConfig';
import TablePanel from './components/TablePanel';
import SingleNumberPanel from './components/SingleNumberPanel';
import DoubleAxesConfig from './components/DoubleAxesConfig';
import ChoroplethMapConfig from './components/ChoroplethMapConfig';

const CustomiseForm = ({ chart, dispatch }) => {
  const {
    chartType,
    queryObj: { dimensions = [], metrics = [], orderBy = [], compare },
    defaultConfig = {},
    defaultColor = {},
  } = chart;

  const metricLength = metrics.length;

  let timeInterval = null;

  if (Array.isArray(dimensions) && dimensions.length > 0) {
    timeInterval = dimensions[0].functionValue;
  }

  // Only enable showEmptyDate for charts that have timeInterval and orderBy time acs
  const canShowEmptyDate = useMemo(() => {
    if (
      timeInterval &&
      !compare &&
      TIME_INTERVALS.truncate.find((obj) => obj.value === timeInterval)
    ) {
      const hasDescTimeOrderBy = orderBy.find(
        (orderByObj) =>
          orderByObj.attribute === defaultConfig.x &&
          orderByObj.direction === 'desc'
      );

      return !hasDescTimeOrderBy;
    }

    return false;
  }, [timeInterval, orderBy, defaultConfig.x, compare]);

  switch (chartType) {
    case 'number':
      return (
        <StyledCollapse>
          <SingleNumberPanel chart={chart} dispatch={dispatch} />
          <ColorPanel capitaliseLabel chart={chart} dispatch={dispatch} />
        </StyledCollapse>
      );

    case 'gauge':
      return <GaugeConfig chart={chart} dispatch={dispatch} />;

    case 'bar_basic':
      return (
        <BarBasicConfig
          chart={chart}
          canShowEmptyDate={canShowEmptyDate}
          dispatch={dispatch}
        />
      );

    case 'line_basic':
    case 'area_basic':
      return (
        <StyledCollapse>
          <AxisPanel
            defaultConfig={defaultConfig}
            dispatch={dispatch}
            options={{
              canShowEmptyDate,
              canLogScale: true,
              rangeKeys: ['y'],
            }}
          />
          <LabelPanel chart={chart} dispatch={dispatch} />
          <ColorPanel capitaliseLabel chart={chart} dispatch={dispatch} />
        </StyledCollapse>
      );

    case 'pie_innerlabel':
    case 'pie_donut':
      return (
        <StyledCollapse>
          <LabelPanel
            chart={chart}
            dispatch={dispatch}
            options={{ canDisplayLabel: true }}
          />
          <LegendPanel defaultConfig={defaultConfig} dispatch={dispatch} />
          <ColorPanel chart={chart} dispatch={dispatch} />
        </StyledCollapse>
      );

    case 'waterfall':
      return (
        <StyledCollapse>
          <AxisPanel
            defaultConfig={defaultConfig}
            dispatch={dispatch}
            options={{
              canLogScale: true,
              rangeKeys: ['y'],
            }}
          />
          <LabelPanel
            chart={chart}
            dispatch={dispatch}
            options={{ canDisplayLabel: true }}
          />
          <ColorPanel capitaliseLabel chart={chart} dispatch={dispatch} />
        </StyledCollapse>
      );

    case 'list_count':
    case 'list_ordered':
      return (
        <StyledCollapse>
          <LabelPanel chart={chart} dispatch={dispatch} />
          <ColorPanel capitaliseLabel chart={chart} dispatch={dispatch} />
        </StyledCollapse>
      );

    case 'heatmap':
      return (
        <StyledCollapse>
          <LabelPanel chart={chart} dispatch={dispatch} />
          <AxisPanel
            defaultConfig={defaultConfig}
            dispatch={dispatch}
            options={{ canReverse: false }}
          />
          <RangeColorPanel
            defaultConfig={defaultConfig}
            defaultColor={defaultColor}
            dispatch={dispatch}
          />
        </StyledCollapse>
      );

    case 'geo_bubble':
      return <GeoMapConfig chart={chart} dispatch={dispatch} />;

    case 'treemap_rect':
    case 'word_cloud':
    case 'bubble':
      return (
        <StyledCollapse>
          <LabelPanel chart={chart} dispatch={dispatch} />
          {metricLength > 1 ? (
            <RangeColorPanel
              defaultConfig={defaultConfig}
              defaultColor={defaultColor}
              dispatch={dispatch}
            />
          ) : (
            <ColorPanel chart={chart} dispatch={dispatch} />
          )}
        </StyledCollapse>
      );

    case 'double_axes':
      return (
        <DoubleAxesConfig
          chart={chart}
          dispatch={dispatch}
          canShowEmptyDate={canShowEmptyDate}
        />
      );

    case 'plotted_bubble':
      return <PlottedBubbleConfig chart={chart} dispatch={dispatch} />;

    case 'radar':
      return (
        <StyledCollapse>
          <LabelPanel chart={chart} dispatch={dispatch} />
          <LegendPanel defaultConfig={defaultConfig} dispatch={dispatch} />
          <ColorPanel chart={chart} dispatch={dispatch} />
        </StyledCollapse>
      );

    case 'bar_grouped':
    case 'bar_stackedColumn':
    case 'bar_stackedPercentage':
    case 'line_series':
    case 'area_series':
      return (
        <StyledCollapse>
          <AxisPanel
            defaultConfig={defaultConfig}
            dispatch={dispatch}
            options={{
              canTranspose: chartType.includes('bar'),
              canShowEmptyDate,
              ...(chartType === 'bar_stackedPercentage'
                ? {}
                : {
                    canLogScale: true,
                    rangeKeys: ['y'],
                  }),
            }}
          />
          <LabelPanel chart={chart} dispatch={dispatch} />
          <LegendPanel defaultConfig={defaultConfig} dispatch={dispatch} />
          <ColorPanel chart={chart} dispatch={dispatch} />
        </StyledCollapse>
      );

    case 'table':
      return (
        <StyledCollapse>
          <LabelPanel chart={chart} dispatch={dispatch} />
          <TablePanel defaultConfig={defaultConfig} dispatch={dispatch} />
        </StyledCollapse>
      );

    case 'choropleth_map':
      return <ChoroplethMapConfig chart={chart} dispatch={dispatch} />;

    default:
      return null;
  }
};

export default CustomiseForm;
