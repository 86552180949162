import { useState, useEffect, useContext } from 'react';

import { message } from 'antd';

import {
  MULTI_SELECT_SUBTYPES,
  GEO_LOCATION_SCHEMA_FIELDS,
  checkIsCustomClassifierField,
} from '../filterBarHelpers';
import { postApi, fetchApiErrorMessage } from '../api';
import { ExploreContext } from '../../providers/ExploreProvider';
import { ProjectContext } from '../../providers/ProjectProvider';

const removeEmptyOptions = (data = []) =>
  data.filter((option) => option || typeof option === 'number');

const useFilterOptions = (
  sharedSchemaFields,
  datasets,
  isDatasetsEnriched = false
) => {
  const [documentFilterOptionsLoading, setDocumentFilterOptionsLoading] =
    useState(false);
  const [entityFilterOptionsLoading, setEntityFilterOptionsLoading] =
    useState(false);
  const [filterOptions, setFilterOptions] = useState({
    document: {},
    entity: {},
  });

  const { setDocumentFilterOptions } = useContext(ExploreContext);

  const {
    project: { slug: projectSlug },
  } = useContext(ProjectContext);

  // Get filterOptions for fields like 'channel', 'source', 'language', 'entities'
  useEffect(() => {
    // Get document fields that needs get select options from API fetch
    const documentFieldsNeedFetch = sharedSchemaFields.filter((field) => {
      return (
        MULTI_SELECT_SUBTYPES.includes(field.subtype) ||
        field.name === 'search_name' ||
        field.isCategory ||
        GEO_LOCATION_SCHEMA_FIELDS.find(
          (geoField) => geoField.name === field.name
        )
      );
    });

    // Get custom document classifier fields
    const customDocumentClassifierFields = sharedSchemaFields.filter((field) =>
      checkIsCustomClassifierField(field)
    );

    // Get entity field that needs get select options from API fetch
    const entityFieldsNeedFetch = isDatasetsEnriched
      ? [
          { name: 'entity_type', key: 'type' },
          {
            name: 'entity_sentiment_label',
            key: 'sentiment_label',
          },
        ]
      : [];

    // Get document field names
    const documentFieldNames = [
      ...new Set(
        [...documentFieldsNeedFetch, ...customDocumentClassifierFields].map(
          (field) => field.name
        )
      ),
    ];

    const fetchDocumentFieldOptions = async () => {
      if (documentFieldNames.length > 0) {
        setDocumentFilterOptionsLoading(true);

        try {
          const res = await postApi('datasets/document-value', {
            datasets: datasets.map((dataset) => dataset.id),
            filter: {},
            requestedFields: documentFieldNames,
            projectSlug,
          });
          const { data = {} } = res;

          const initDocumentFieldOptions = {
            'Dataset Name': datasets.map((dataset) => dataset.name),
          };

          const documentFieldOptions = data
            ? Object.keys(data).reduce(
                (prev, dataKey) => ({
                  ...prev,
                  [dataKey]: removeEmptyOptions(data[dataKey]),
                }),
                initDocumentFieldOptions
              )
            : initDocumentFieldOptions;

          setFilterOptions((prevFilterOptions) => ({
            ...prevFilterOptions,
            document: documentFieldOptions,
          }));

          // Send custom classifiers options to Explore Context so ExploreCard can access it
          if (setDocumentFilterOptions) {
            setDocumentFilterOptions(documentFieldOptions);
          }

          setDocumentFilterOptionsLoading(false);
        } catch (error) {
          setDocumentFilterOptionsLoading(false);
          message.error(fetchApiErrorMessage(error));
        }
      }
    };

    const fetchEntityFieldOptions = async () => {
      const requestedFields = entityFieldsNeedFetch.map(
        (entityField) => entityField.key
      );
      if (requestedFields && requestedFields.length > 0) {
        setEntityFilterOptionsLoading(true);

        try {
          const res = await postApi('datasets/entity-value', {
            datasets: datasets.map((dataset) => dataset.id),
            requestedFields,
            projectSlug,
          });
          const { data = {} } = res;

          const entityFieldOptions = data
            ? entityFieldsNeedFetch.reduce((prev, entityField) => {
                const { name, key } = entityField;
                return { ...prev, [name]: removeEmptyOptions(data[key]) };
              }, {})
            : {};

          setFilterOptions((prevFilterOptions) => ({
            ...prevFilterOptions,
            entity: entityFieldOptions,
          }));

          setEntityFilterOptionsLoading(false);
        } catch (error) {
          setEntityFilterOptionsLoading(false);
          message.error(fetchApiErrorMessage(error));
        }
      }
    };

    if (datasets.length > 0) {
      // Fetch document filter options
      fetchDocumentFieldOptions();

      // Fetch entity filter options
      fetchEntityFieldOptions();
    }
  }, [
    sharedSchemaFields,
    datasets,
    isDatasetsEnriched,
    setDocumentFilterOptions,
    projectSlug,
  ]);

  return {
    ...filterOptions,
    loading: documentFilterOptionsLoading || entityFilterOptionsLoading,
  };
};

export default useFilterOptions;
