/** @jsxImportSource @emotion/react */
import { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import shortid from 'shortid';
import moment from 'moment-timezone';
import { useAuth } from '@otso/auth-wrapper';

import {
  Modal,
  Row,
  Col,
  Form,
  Comment,
  List,
  Avatar,
  Popconfirm,
  Input,
  message,
  Button,
} from 'antd';

import { colors, preset } from 'styles';
import { formatDateInTZ } from '@modules/date/formatDate';
import formatDimensionLabel from '@modules/chart/formatDimensionLabel';
import deepGet from '@modules/deepGet';
import { putApi, getApi, fetchApiErrorMessage } from '@modules/api';

import ChartDisplay from '@components/ChartDisplay';

const { TextArea } = Input;

const mapStateToProps = (state) => {
  return {
    commentsEditingChart: state.dashboard.commentsEditingChart,
    newChartCommentPoint: state.dashboard.newChartCommentPoint,
  };
};

const ChartCommentsModal = ({
  // redux state
  commentsEditingChart,
  newChartCommentPoint,
  // redux dispatch
  dispatch,
}) => {
  const { id, dashboardChartId, comments, ...chartProps } =
    commentsEditingChart;

  const chartDimensions = deepGet(commentsEditingChart, [
    'queryObj',
    'dimensions',
  ]);
  const chartFirstDimension = Array.isArray(chartDimensions)
    ? chartDimensions[0]
    : null;
  const chartSchema = deepGet(commentsEditingChart, ['schema']);
  const chartLabelConfig = deepGet(commentsEditingChart, [
    'defaultConfig',
    'label',
  ]);

  const chartTimeInterval = chartFirstDimension
    ? deepGet(chartSchema, [chartFirstDimension.as, 'functionValue'])
    : null;

  const { slug: projectSlug, dashboardSlug } = useParams();

  // Get current user info
  const { user } = useAuth();

  const [edittingCommentKey, setEdittingCommentKey] = useState(null);
  const [newCommentText, setNewCommentText] = useState();
  const [isCommentUpdated, setIsCommentUpdated] = useState(false);

  // Sort comments by date and add key
  const sortedComments = useMemo(
    () =>
      Array.isArray(comments)
        ? orderBy(
            comments.map((commentObj) => ({
              ...commentObj,
              key: shortid.generate(),
            })),
            'date',
            'desc'
          )
        : [],
    [comments]
  );

  const updateDashboardChartComments = async () => {
    const res = await getApi(
      `projects/${projectSlug}/dashboards/${dashboardSlug}/visualisations/${dashboardChartId}/comments`
    );

    dispatch({
      type: 'SET_DASHBOARD_CHART_COMMENTS',
      id: dashboardChartId,
      comments: res.data,
    });
  };

  const onModalClose = () => {
    // Trigger dashboard chart comments refetch
    if (isCommentUpdated) {
      updateDashboardChartComments();
    }

    dispatch({ type: 'RESET_DASHBOARD_COMMENTS_EDITING_CHART' });
    setEdittingCommentKey(null);
    setNewCommentText();
    setIsCommentUpdated(false);
  };

  // Handle comment action click
  const onCommentActionClick = async (key, commentKey) => {
    if (key === 'edit') {
      setEdittingCommentKey(
        edittingCommentKey
          ? edittingCommentKey === commentKey
            ? null
            : commentKey
          : commentKey
      );
    } else if (key === 'delete') {
      const newComments = sortedComments.filter(
        (commentObj) => commentObj.key !== commentKey
      );

      await updateCommentsApi(newComments, 'Deleted');

      dispatch({
        type: 'UPDATE_DASHBOARD_EDITING_COMMENTS',
        comments: newComments,
      });
    }
  };

  // Add new comment
  const onCommentAdd = async () => {
    if (
      newCommentText &&
      newChartCommentPoint &&
      newChartCommentPoint.dimension
    ) {
      const newComments = [
        {
          comment: newCommentText,
          date: moment().valueOf(),
          user: user?.email,
          ...newChartCommentPoint,
        },
        ...sortedComments,
      ];

      await updateCommentsApi(newComments, 'Added');

      dispatch({
        type: 'UPDATE_DASHBOARD_EDITING_COMMENTS',
        comments: newComments,
      });
    } else {
      message.error('Annotation Point and Annotation Text cannot be empty');
    }
  };

  // Save comment edit
  const onCommentEditSave = async (e, commentKey) => {
    if (!e.shiftKey) {
      e.preventDefault();

      const newComments = sortedComments.map((commentObj) =>
        commentObj.key === commentKey
          ? { ...commentObj, comment: e.target.value }
          : commentObj
      );

      await updateCommentsApi(newComments, 'Saved');

      dispatch({
        type: 'UPDATE_DASHBOARD_EDITING_COMMENTS',
        comments: newComments,
      });
      setEdittingCommentKey(null);
    }
  };

  // Update comments request
  const updateCommentsApi = async (newComments, method) => {
    try {
      await putApi(
        `projects/${projectSlug}/dashboards/${dashboardSlug}/visualisations/${dashboardChartId}/comments`,
        { data: newComments }
      );

      setIsCommentUpdated(true);

      message.success(`Chart Annotation ${method}!`);
    } catch (error) {
      message.error(fetchApiErrorMessage(error));
    }
  };

  // Format chart annotation point data display
  const annotationPointDisplay = useMemo(() => {
    if (newChartCommentPoint && newChartCommentPoint.dimension) {
      return {
        x: formatDimensionLabel(newChartCommentPoint.dimension, {
          timeInterval: chartTimeInterval,
          labelConfig: chartLabelConfig,
        }),
        y: newChartCommentPoint.metric,
      };
    }

    return {};
  }, [newChartCommentPoint, chartLabelConfig, chartTimeInterval]);

  return (
    <Modal
      title="Chart Annotation"
      visible={!!id}
      width={preset.modalWidth.lg}
      destroyOnClose
      onCancel={() => onModalClose()}
      footer={null}
    >
      <Row gutter={preset.spacing(3)} type="flex">
        <Col span={18}>
          <ChartDisplay
            height={500}
            noHeader
            drillDownDisabled
            chart={chartProps}
            comments={comments}
            commentsEditing
          />
        </Col>
        <Col
          span={6}
          css={{
            maxHeight: 500,
            overflowY: 'auto',
            '.ant-comment-avatar': {
              cursor: 'auto',
            },
          }}
        >
          <Form layout="vertical">
            <Form.Item
              label="Annotation Point"
              required
              extra="Click on the chart to select"
            >
              <Input
                addonBefore="X"
                value={annotationPointDisplay.x}
                css={{ marginBottom: 12 }}
              />
              <Input addonBefore="Y" value={annotationPointDisplay.y} />
            </Form.Item>
            <Form.Item label="Annotation Text" required>
              <TextArea
                rows={3}
                onBlur={(e) => setNewCommentText(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={onCommentAdd}>
                Add Annotation
              </Button>
            </Form.Item>
          </Form>

          <List
            dataSource={sortedComments}
            header={`${sortedComments.length} annotation${
              sortedComments.length > 1 ? 's' : ''
            }`}
            itemLayout="horizontal"
            renderItem={(commentObj) => (
              <Comment
                author={commentObj.user}
                avatar={
                  <Avatar css={{ backgroundColor: colors.primary }}>
                    {commentObj.user.charAt().toUpperCase()}
                  </Avatar>
                }
                content={
                  <p css={{ marginBottom: 0 }}>
                    <span css={{ color: colors.typography.primary }}>
                      {formatDimensionLabel(commentObj.dimension, {
                        timeInterval: chartTimeInterval,
                        labelConfig: chartLabelConfig,
                      })}
                    </span>
                    <span
                      css={{ marginLeft: 2, marginRight: preset.spacing(1) }}
                    >
                      :
                    </span>
                    {edittingCommentKey === commentObj.key ? (
                      <TextArea
                        rows={3}
                        defaultValue={commentObj.comment}
                        onPressEnter={(e) =>
                          onCommentEditSave(e, commentObj.key)
                        }
                        css={{ marginTop: 12 }}
                      />
                    ) : (
                      commentObj.comment
                    )}
                  </p>
                }
                datetime={formatDateInTZ(commentObj.date, 'day', '/')}
                actions={[
                  <span
                    key="edit"
                    role="presentation"
                    onClick={() => onCommentActionClick('edit', commentObj.key)}
                  >
                    {edittingCommentKey === commentObj.key ? 'Cancel' : 'Edit'}
                  </span>,
                  <Popconfirm
                    key="delete"
                    title="Delete this annotation?"
                    onConfirm={() =>
                      onCommentActionClick('delete', commentObj.key)
                    }
                    okText="Delete"
                    okType="danger"
                  >
                    <span>Delete</span>
                  </Popconfirm>,
                ]}
              />
            )}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default connect(mapStateToProps)(ChartCommentsModal);
