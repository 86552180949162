import { getApi, customApi } from './api';

export const deleteFile = async (fileName, project, bucketName = undefined) => {
    if (sessionStorage.getItem(project)) { sessionStorage.removeItem(project); }
    return getApi('objects/deleteFile', ({ file: fileName, project, bucketName }));
};

/**
 * Check if a file exists, return true for existing otherwise false.
 * @param {*} filename Name of the file to be checked
 * @param {*} project Name of project associated (optional)
 * @param {*} bucketName Name of the bucket
 */
export const checkFileExists = async (filename, project, bucketName = undefined) => {
    const res = await getApi('objects/checkFileExists', ({ filename, project, bucketName }));
    return res && res.data.exists;
};

/**
 * Retrieve a presigned url for HTTP PUT requests.
 * @param {*} project Name of project associated (optional)
 * @param {*} name Name of the file to be uploaded
 * @param {*} bucketName Name of the bucket
 */
export const getPresignedPutURL = async (project, filename = 'logo', bucketName) => {
    const { data } = await getApi('objects/getUploadUrl', ({ filename, project, bucketName }));
    return data;
};

/**
 * Retrieve a presigned url for HTTP GET requests.
 * @param {*} project Name of project associated (optional)
 * @param {*} name Name of the file to be uploaded
 * @param {*} bucketName Name of the bucket
 */
export const getPresignedGetURL = async (project, filename = 'logo', bucketName = undefined) => {
    // Check if the file exists in the specified bucket
    const exists = await checkFileExists(filename, project, bucketName);

    // Generate a presigned GET url only when the file exists
    if (exists) {
        return getApi('objects/getDownloadUrl', ({ filename, project, bucketName }))
            .then(res => res.data)
            .catch(err => {
                throw err;
            });
    }
    // Return null if file not found
    return null;
};

/**
 * Upload an object using the presigned url.
 * @param {*} url Presigned url
 * @param {*} file File object
 * @param {*} progressCB Upload progress callback
 */
export const uploadPresigned = (url, file, progressCB = null) => {
    if (url && typeof url === 'string') {
        return new Promise((resolve, reject) => {
            customApi(url, 'PUT', null, {}, file, progressCB)
                .then(res => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }
    // Return null if a invalid url is detected
    return null;
};

export const downloadPresigned = url => {
    if (url) {
        return new Promise((resolve, reject) => {
            customApi(url, 'GET', null, {}, {})
                .then(res => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    return null;
};
