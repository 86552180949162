/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import request from 'superagent';
import jsonp from 'superagent-jsonp';
import { LoadingOutlined } from '@ant-design/icons';

import { trackEvent } from 'analytics';

const Twitter = ({ embedMaxWidth, link }) => {
  const [html, setHtml] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const url = new URL(link);
    url.protocol = 'https';
    const safeLink = url.href;

    request
      .get('https://publish.twitter.com/oembed')
      .use(jsonp({ timeout: 3000 }))
      .query({
        url: safeLink,
        omit_script: true,
        maxwidth: embedMaxWidth < 220 ? 220 : embedMaxWidth,
      })
      .then((res) => {
        setHtml(res.body.html);
        setLoading(false);
        if (window.twttr) {
          window.twttr.widgets.load();
        }
      })
      .catch(() => {
        setHtml(`<a href='${safeLink}' target="_blank">${safeLink}</a>`);
        setLoading(false);
      });
  }, [embedMaxWidth, link]);

  const createMarkup = (markupHtml) => {
    return { __html: markupHtml };
  };

  return (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <div
          role="presentation"
          // eslint-disable-next-line
          dangerouslySetInnerHTML={createMarkup(html)}
          onClick={() =>
            trackEvent('Explore Twitter Post Click', { postLink: link })
          }
        />
      )}
    </div>
  );
};
export default Twitter;
