import { formatDate } from '../date/formatDate';
import deepGet from '../deepGet';

const getDateBoundary = (chartConfig = {}) => {
    const {
        data = [], x, queryObj = {}, schema,
    } = chartConfig;
    const { dimensions = [], orderBy = [] } = queryObj;

    const isAsc = orderBy.find(orderByObj => (
        orderByObj.attribute === x && orderByObj.direction === 'desc'
    )) === undefined;

    // Add { type: time } will force chart order by dateime asc, therefore there is no need getting date boundary for desc chart
    if (!isAsc) return {};

    if (dimensions.length === 0) return {};

    let startDate;
    let endDate;
    let boundary = {};

    if (Array.isArray(deepGet(schema, ['calculatedFilters']))) {
        schema.calculatedFilters.forEach(filter => {
            if (filter.isTime && filter.value) {
                if (filter.condition === '>=' || filter.condition === '>') {
                    startDate = filter.value;
                } else if (filter.condition === '<=' || filter.condition === '<') {
                    endDate = filter.value;
                }
            }
        });
    }

    if (startDate) {
        const firstDate = formatDate(data[0][x]);
        const startDateWithoutTz = formatDate(startDate);
        if (new Date(startDateWithoutTz) < new Date(firstDate)) {
            boundary = { ...boundary, min: new Date(startDateWithoutTz).getTime() };
        }
    }
    if (endDate) {
        const lastDate = formatDate(data[data.length - 1][x]);
        const endDateWithoutTz = formatDate(endDate);
        if (new Date(endDateWithoutTz) > new Date(lastDate)) {
            boundary = { ...boundary, max: new Date(endDateWithoutTz).getTime() };
        }
    } else {
        boundary = { ...boundary, max: new Date().getTime() };
    }

    return boundary;
};

export default getDateBoundary;
