import { defaultQueryObj } from '../Chart/reducer';

import getChartDefaultConfig from '../../modules/chart/getChartDefaultConfig';
import { GEO_LOCATION_SCHEMA_FIELDS } from '../../modules/filterBarHelpers';

const defaultData = GEO_LOCATION_SCHEMA_FIELDS.reduce((prev, locationField) => ({
    ...prev,
    [locationField.name]: [],
}), []);

export const initialState = {
    isQueryLoading: false,
    currentDimensionKey: GEO_LOCATION_SCHEMA_FIELDS[0].name,
    data: defaultData,
    queryObj: defaultQueryObj,
    config: {},
    color: {},
    autoZoom: false,
};

export const locationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOCATION_QUERY_REQUESTED':
            return {
                ...state,
                isQueryLoading: true,
            };

        case 'LOCATION_QUERY_COMPLETE': {
            const {
                dimensionKey, data, newQueryObj, newDimensionKey, currentAutoZoom, tempAutoZoom,
            } = action;
            const { currentDimensionKey, autoZoom } = state;

            const selectedDimensionKey = newDimensionKey || currentDimensionKey;

            if (dimensionKey === selectedDimensionKey) {
                const chartConfig = (data && data.length > 0)
                    ? getChartDefaultConfig('choropleth_map', data, newQueryObj)
                    : { color: {}, general: {} };
                return {
                    ...state,
                    isQueryLoading: false,
                    queryObj: newQueryObj,
                    data: {
                        ...state.data,
                        [dimensionKey]: data,
                    },
                    config: chartConfig.general,
                    color: chartConfig.color,
                    currentDimensionKey: selectedDimensionKey,
                    autoZoom: tempAutoZoom || autoZoom,
                };
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    [dimensionKey]: data,
                },
                autoZoom: tempAutoZoom ? currentAutoZoom : autoZoom,
            };
        }

        case 'LOCATION_QUERY_ERROR':
            return {
                ...state,
                isQueryLoading: false,
                data: initialState.data,
            };

        case 'SET_DIMENSION_KEY': {
            const { dimensionField, autoZoom } = action;
            const { queryObj, data, currentDimensionKey } = state;

            if (!dimensionField) {
                return {
                    ...state,
                    autoZoom: false,
                };
            }

            if (dimensionField.name === currentDimensionKey) {
                if (autoZoom) {
                    return {
                        ...state,
                        autoZoom: true,
                    };
                }
                return state;
            }

            const newQueryObj = {
                ...queryObj,
                dimensions: [{
                    attribute: dimensionField.name,
                    as: dimensionField.displayName,
                }],
                groupBy: [dimensionField.displayName],
            };

            const currentDimensionData = data[dimensionField.name];

            const chartConfig = (currentDimensionData && currentDimensionData.length > 0)
                ? getChartDefaultConfig('choropleth_map', currentDimensionData, newQueryObj)
                : { color: {}, general: {} };

            return {
                ...state,
                currentDimensionKey: dimensionField.name,
                queryObj: newQueryObj,
                config: chartConfig.general,
                color: chartConfig.color,
                autoZoom: autoZoom || state.autoZoom,
            };
        }

        case 'SET_LOCATION_VALUE':
            return {
                ...state,
                [action.key]: action.value,
            };

        default:
            return state;
    }
};
