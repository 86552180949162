// Import presets
import { preset } from 'styles';

// Prepare a stylesheet
const styles = {
  helpAlert: {
    marginTop: preset.spacing(2),
    alignItems: 'flex-start',
    '.ant-alert-icon': {
      marginTop: 5,
      marginRight: preset.spacing(2),
    },
  },
  helpAlertTrigger: { textAlign: 'right', marginTop: preset.spacing(1) },
  helpMessage: { 'div.ant-typography': { marginBottom: preset.spacing(1) } },
  chartCard: {
    height: '100%',
    '.ant-card-body': {
      height: '100%',
      paddingTop: preset.spacing(2),
      paddingBottom: preset.spacing(2),
    },
    marginTop: preset.spacing(2),
    marginBottom: preset.spacing(2),
  },
};

export default styles;
