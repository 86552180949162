/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Import Ant Design
import { Modal, Button, Input, List, message } from 'antd';

import { deleteApi, fetchApiErrorMessage } from '@modules/api';

const DangerZone = ({ project }) => {
  const history = useHistory();

  const { slug: projectSlug } = project;

  const [deleteConfirmText, setDeleteConfirmText] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);

  // Delete Project
  const deleteProject = async () => {
    try {
      setLoading(true);
      await deleteApi(`projects/${projectSlug}`);
      message.success('Project Deleted!');
      setLoading(false);
      history.push('/projects');
    } catch (error) {
      setLoading(false);
      message.error(fetchApiErrorMessage(error));
    }
  };

  return (
    <>
      <List>
        <List.Item
          actions={[
            <Button
              onClick={() => setShowDeleteModal(true)}
              type="danger"
              loading={loading}
            >
              Delete project
            </Button>,
          ]}
        >
          <List.Item.Meta
            title="Delete project"
            description="This action cannot be undone. This will permanently delete the project, visualisation, and dashboards, and remove all collaborator associations."
          />
        </List.Item>
      </List>

      <Modal
        visible={showDeleteModal}
        title="Are you sure?"
        onCancel={() => {
          setShowDeleteModal(false);
          setDeleteConfirmText('');
        }}
        footer={[
          <Button
            key="danger-delete-button"
            type="danger"
            onClick={deleteProject}
            disabled={deleteConfirmText !== project.name}
            loading={loading}
          >
            I understand, delete project
          </Button>,
        ]}
        closable
        destroyOnClose
      >
        <p>
          {
            'This action cannot be undone. This will permanently delete the project,\
                    visualisation, and dashboards, and remove all collaborator associations.'
          }
        </p>
        <p>Please type in the name of the project to confirm.</p>
        <Input
          autoFocus
          data-testId="danger-zone-input"
          value={deleteConfirmText}
          onChange={(evt) => setDeleteConfirmText(evt.target.value)}
        />
      </Modal>
    </>
  );
};

export default DangerZone;
