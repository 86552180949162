import mixpanel from 'mixpanel-browser';

type AnalyticUserPropertyKey =
  | 'email'
  | 'picture'
  | 'name'
  | 'firebaseId'
  | 'signInProviders';

type AnalyticEventSuperPropertyKey = 'currentOrg' | 'role';

const enableAnalytics = false;

// Initialise MixPanel SDK
export const initMixpanel = () => {
  if (enableAnalytics) {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN);
  }
};

// User property mappings
const userPropertyMappings: Record<AnalyticUserPropertyKey, string> = {
  // Reserved props
  email: '$email',
  picture: '$avatar',
  name: '$name',
  // Custom props
  firebaseId: 'Firebase ID',
  signInProviders: 'Sign In Providers',
};

const eventSuperPropertyMappings: Record<
  AnalyticEventSuperPropertyKey,
  string
> = {
  // Custom props
  currentOrg: 'Current Organization',
  role: 'Role',
};

// Map user attributes to MixPanel user profile
const mapProperty = (data: any, mappingData: any) =>
  Object.entries(data).reduce((prev, current) => {
    const [key, value] = current;
    const attributeName = mappingData[key] || key;
    return { ...prev, [attributeName]: value };
  }, {});

export const identifyUser = (userId: string) => {
  if (enableAnalytics) {
    mixpanel.identify(userId);
  }
};

export const trackEvent = (eventName: string, data?: Record<string, any>) => {
  if (enableAnalytics) {
    mixpanel.track(eventName, data);
  }
};

export const trackPage = (pageTitle: string, pathname: string) => {
  trackEvent(`View Page - ${pageTitle}`, {
    pathname,
  });
};

export const setUserProperty = (
  data: { [key in AnalyticUserPropertyKey]?: any }
) => {
  if (enableAnalytics) {
    mixpanel.people.set(mapProperty(data, userPropertyMappings));
  }
};

export const setEventSuperProperty = (
  data: { [key in AnalyticEventSuperPropertyKey]?: any }
) => {
  if (enableAnalytics) {
    mixpanel.register(mapProperty(data, eventSuperPropertyMappings));
  }
};

export const resetAnalytics = () => {
  mixpanel.reset();
};
