import request from 'superagent';
import axios, { AxiosRequestConfig } from 'axios';

import { auth } from 'firebase-export';
import deepGet from './deepGet';

const URL = process.env.REACT_APP_INSIGHT_API_URL;

const checkURLPath = (path: string): string => {
  if (path.charAt(0) === '/') return path.slice(1);
  return path;
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

const getCurrentUser = async () => {
  const { currentUser } = await auth();
  return currentUser || Promise.reject({ message: 'Unauthorized' });
};

export const getApi = async (
  path: string,
  paramters?: AxiosRequestConfig['params'],
  url: string = URL
) => {
  const currentUser = await getCurrentUser();
  const token = await currentUser.getIdToken();

  const req = axios.get(`${url}/${checkURLPath(path)}`, {
    params: paramters,
    headers: {
      Accept: 'application/json',
      Authorization: `JWT ${token} `,
    },
  });

  return req;
};

export const postApi = async (path: string, data?: any, url: string = URL) => {
  const currentUser = await getCurrentUser();
  const token = await currentUser.getIdToken();

  const req = axios.post(`${url}/${checkURLPath(path)}`, data, {
    headers: {
      Accept: 'application/json',
      Authorization: `JWT ${token} `,
    },
  });

  return req;
};

export const putApi = async (path: string, data?: any, url: string = URL) => {
  const currentUser = await getCurrentUser();
  const token = await currentUser.getIdToken();

  const req = axios.put(`${url}/${checkURLPath(path)}`, data, {
    headers: {
      Accept: 'application/json',
      Authorization: `JWT ${token} `,
    },
  });

  return req;
};

export const deleteApi = async (
  path: string,
  data?: any,
  url: string = URL
) => {
  const currentUser = await getCurrentUser();
  const token = await currentUser.getIdToken();

  const req = axios.delete(`${url}/${checkURLPath(path)}`, {
    data,
    headers: {
      Accept: 'application/json',
      Authorization: `JWT ${token} `,
    },
  });

  return req;
};

export const getPDFApi = async (path: string, data?: any) => {
  const currentUser = await getCurrentUser();
  const token = await currentUser.getIdToken();

  const req = axios.post(`${URL}/${checkURLPath(path)}`, data, {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/pdf',
      Authorization: `JWT ${token} `,
    },
  });

  return req;
};

export const customApi = (
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  responseType: string,
  headers = {},
  body = {},
  progressCB?: (e: request.ProgressEvent) => void
) => {
  return new Promise((resolve, reject) => {
    if (url && method) {
      switch (method.toLowerCase()) {
        case 'get': {
          request
            .get(url)
            .responseType(responseType)
            .set(headers)
            .then((res) => {
              return resolve(res);
            })
            .catch(() => {
              reject('CustomApi GET | error found: ');
            });
          break;
        }
        case 'delete': {
          request
            .delete(url)
            .set(headers)
            .then((res) => {
              return resolve(res);
            })
            .catch(() => {
              reject('CustomApi DELETE | error found: ');
            });
          break;
        }
        case 'post': {
          request
            .post(url)
            .set(headers)
            .send(body)
            .then((res) => {
              return resolve(res);
            })
            .catch(() => {
              reject('CustomApi POST | error found: ');
            });
          break;
        }

        case 'put': {
          request
            .put(url)
            .set(headers)
            .send(body)
            .on('progress', (event) => {
              if (progressCB) progressCB(event);
            })
            .then((res) => {
              return resolve(res);
            })
            .catch(() => {
              reject('CustomApi PUT | error found: ');
            });
          break;
        }
        default:
          break;
      }
    }
    reject('no url or method specified');
  });
};

/**
 * Optionally retrieve nested error message from response
 * @param {Error} err api error
 * @param {string} [fallback=undefined] optional custom fallback error to be returned
 * @returns {string} returns error message or generic error 'Internal Server Error
 */
export const fetchApiErrorMessage = (err: any, fallback = undefined) =>
  deepGet(err, ['message']) ||
  deepGet(err, ['data', 'message']) ||
  fallback ||
  'Internal Server Error';

export const fetchApiErrorLogId = (err: any) => err?.data?.logId || null;
