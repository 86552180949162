/** @jsxImportSource @emotion/react */
import { useParams, Redirect } from 'react-router-dom';

import Microsoft from './Microsoft';

const LinkSSO = () => {
    const { provider } = useParams();

    switch (provider) {
        case 'microsoft':
            return <Microsoft />;

        default:
            return <Redirect to="/" />;
    }
};

export default LinkSSO;
