const fillSecondDimensionData = (data = [], config = {}) => {
    const {
        firstDimension,
        secondDimension,
        metric,
        secondDimensionOrder,
    } = config;

    const secondDimensionValues = [...new Set(data.map(row => `${row[secondDimension]}`))];
    const sortedSecondDimensionValues = secondDimensionValues.filter(val => !!val).sort(
        (a, b) => (secondDimensionOrder === 'asc' ? a.localeCompare(b) : b.localeCompare(a)),
    );

    const dataByFirstDimensionValue = data.reduce((prev, row) => ({
        ...prev,
        [row[firstDimension]]: prev[row[firstDimension]] ? [...prev[row[firstDimension]], row] : [row],
    }), {});

    return Object.keys(dataByFirstDimensionValue).reduce((prev, firstDimensionValue) => {
        if (dataByFirstDimensionValue[firstDimensionValue].length === sortedSecondDimensionValues.length) {
            return [...prev, ...dataByFirstDimensionValue[firstDimensionValue]];
        }

        const filledRows = sortedSecondDimensionValues.map(secondDimensionVal => {
            const existingColorRow = dataByFirstDimensionValue[firstDimensionValue].find(
                row => row[secondDimension] === secondDimensionVal,
            );
            return {
                ...existingColorRow,
                [firstDimension]: firstDimensionValue,
                [secondDimension]: secondDimensionVal,
                [metric]: existingColorRow ? existingColorRow[metric] : 0,
            };
        });

        return [...prev, ...filledRows];
    }, []);
};

export default fillSecondDimensionData;
