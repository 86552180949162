/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';

import { Select } from 'antd';

import formatLabel from '@modules/formatLabel';

const { OptGroup, Option } = Select;

const GroupBy = ({
  value,
  onChange,
  dimensions = [],
  attributeOptions = {},
}) => {
  const enteredDimensions = useMemo(
    () => dimensions.filter((dimension) => dimension.attribute && dimension.as),
    [dimensions]
  );

  const enteredDimensionAs = useMemo(
    () => enteredDimensions.map((dimension) => dimension.as),
    [enteredDimensions]
  );

  return (
    <Select
      showSearch
      mode="multiple"
      optionFilterProp="children"
      placeholder="Select Group By"
      value={value}
      onChange={onChange}
    >
      {enteredDimensions.length > 0 && (
        <OptGroup label="Entered Dimensions">
          {enteredDimensions.map((dimension) => (
            <Option
              key={`entered_dimensions_${dimension.as}`}
              value={dimension.as}
            >
              {dimension.as}
            </Option>
          ))}
        </OptGroup>
      )}
      {Object.keys(attributeOptions).map((optionGroupKey) => (
        <OptGroup key={optionGroupKey} label={formatLabel(optionGroupKey)}>
          {attributeOptions[optionGroupKey].map(
            (field) =>
              !enteredDimensionAs.includes(field.name) && (
                <Option
                  key={`${optionGroupKey}_${field.name}`}
                  value={field.name}
                >
                  {field.displayName || formatLabel(field.name)}
                </Option>
              )
          )}
        </OptGroup>
      ))}
    </Select>
  );
};

export default GroupBy;
