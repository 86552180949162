// Import stylesheets
import { preset } from 'styles';

// Prepare stylesheet
const styles = {
  cardBody: {
    width: preset.centerFormWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  actionPanel: {
    marginBottom: preset.spacing(3),
  },
};

export default styles;
