/** @jsxImportSource @emotion/react */
import { useReducer, useState, useEffect, useContext, useRef } from 'react';
import queryString from 'query-string';
import { useLocation, useParams, useHistory } from 'react-router-dom';

import { Row, Col, Card, message, Button } from 'antd';

import { trackEvent } from 'analytics';
import { preset } from 'styles';
import Container from '../../components/Container';
import SetupForm from './components/SetupForm';
import ChartDisplay from '../../components/ChartDisplay';
import DataAndCustomiseCard from './components/DataAndCustomiseCard';

import { postApi, putApi, fetchApiErrorMessage } from '../../modules/api';
import { chartReducer, initialState } from './reducer';
import { ProjectContext } from '../../providers/ProjectProvider';

const maxCardHeight = `calc(100vh - ${
  preset.headerHeight + preset.subHeaderHeight + 12 + preset.spacing(3)
}px)`;

const Chart = () => {
  const {
    project: { slug: projectSlug },
  } = useContext(ProjectContext);
  const location = useLocation();
  const history = useHistory();

  const { chartId } = useParams();
  const { dashboard: dashboardSlug, page: dashboardPageIndex } =
    queryString.parse(location.search);

  const [chart, dispatch] = useReducer(chartReducer, initialState);

  const chartContainer = useRef();

  const { id, defaultTitle, chartType, queryObj, defaultConfig, defaultColor } =
    chart;

  const [chartQueryLoading, setChartQueryLoading] = useState(false);
  const [chartQueryError, setChartQueryError] = useState();
  const [chartLoading, setChartLoading] = useState(false);
  const [isSavingChart, setIsSavingChart] = useState(false);

  // Fetch Chart if chartId found in URL
  useEffect(() => {
    const fetchChart = async () => {
      setChartLoading(true);

      try {
        const { data } = await postApi(
          `projects/${projectSlug}/visualisations/${chartId}`
        );

        if (data.error) {
          message.error(data.error);
          setChartQueryError(data.error);
        }

        dispatch({
          type: 'INIT_CHART',
          chart: data,
        });
      } catch (error) {
        message.error(fetchApiErrorMessage(error));
      }

      setChartLoading(false);
    };

    if (chartId) {
      fetchChart();
    }
  }, [projectSlug, chartId]);

  // Create Chart
  const createChart = async () => {
    setIsSavingChart(true);

    try {
      const res = await postApi(`projects/${projectSlug}/visualisations`, {
        visualisation: chart,
      });

      const newChartId = res.data.id;

      trackEvent('Create Chart Complete', {
        chartType: chart.chartType,
        chartTitle: chart.defaultTitle,
        chartId: newChartId,
      });

      if (dashboardSlug && location.state) {
        const { layout, pageId } = location.state;

        await postApi(
          `projects/${projectSlug}/dashboards/${dashboardSlug}/pages/${pageId}`,
          {
            newDashboardVis: {
              visualisationId: newChartId,
              layout,
            },
          }
        );

        message.success('Chart Created');
        setIsSavingChart(false);

        history.push(
          `/projects/${projectSlug}/dashboards/${dashboardSlug}?page=${dashboardPageIndex}`
        );
      } else {
        message.success('Project Chart Created');
        setIsSavingChart(false);

        history.push(`/projects/${projectSlug}/charts`);
      }
    } catch (error) {
      setIsSavingChart(false);
      trackEvent('Create Chart Error', {
        chartType: chart.chartType,
        chartTitle: chart.defaultTitle,
        error: fetchApiErrorMessage(error),
      });
      message.error(fetchApiErrorMessage(error));
    }
  };

  // Save Chart Edit
  const saveChart = async () => {
    setIsSavingChart(true);

    try {
      await putApi(`projects/${projectSlug}/visualisations/${chartId}`, {
        visualisation: chart,
      });

      trackEvent('Save Chart Edit', {
        chartType: chart.chartType,
        chartTitle: chart.defaultTitle,
        chartId,
      });

      message.success('Chart Saved');
      setIsSavingChart(false);

      if (dashboardSlug) {
        history.push(
          `/projects/${projectSlug}/dashboards/${dashboardSlug}?page=${dashboardPageIndex}`
        );
      }
    } catch (error) {
      setIsSavingChart(false);
      trackEvent('Save Chart Edit Error', {
        chartType: chart.chartType,
        chartTitle: chart.defaultTitle,
        chartId,
        error: fetchApiErrorMessage(error),
      });
      message.error(fetchApiErrorMessage(error));
    }
  };

  const runChartQuery = async (newChartType, formattedQueryObj = {}) => {
    setChartQueryLoading(true);

    const query = {
      ...formattedQueryObj,
      queryType: 'union',
      withCount: false,
    };

    try {
      const res = await postApi(`projects/${projectSlug}/query`, query);

      setChartQueryError();

      dispatch({
        type: 'ON_CHART_QUERY_APPLY',
        data: res.data.data,
        schema: res.data.schema,
        queryObj: formattedQueryObj,
        chartType: newChartType,
      });
    } catch (error) {
      setChartQueryError(error);
      message.error(fetchApiErrorMessage(error));

      dispatch({
        type: 'ON_CHART_QUERY_APPLY',
        error,
        queryObj: formattedQueryObj,
        chartType: newChartType,
      });
    }

    setChartQueryLoading(false);
  };

  const chartHeight = chartContainer.current
    ? chartContainer.current.clientHeight
    : '100%';

  return (
    <Container paddingY={2}>
      <Row gutter={preset.spacing(3)}>
        <Col xl={9} xxl={8}>
          <Card
            bordered={false}
            loading={chartLoading}
            title="Setup"
            css={{
              height: maxCardHeight,
              display: 'flex',
              flexDirection: 'column',
              '.ant-card-body': {
                flex: 1,
                overflowY: 'auto',
              },
            }}
          >
            <SetupForm
              chart={{
                id,
                defaultTitle,
                chartType,
                queryObj,
              }}
              chartReducerDispatch={dispatch}
              chartQueryLoading={chartQueryLoading}
              runChartQuery={runChartQuery}
            />
          </Card>
        </Col>
        <Col xl={15} xxl={16}>
          <div
            css={{
              height: maxCardHeight,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Card
              bordered={false}
              css={{
                flex: 1.5,
                marginBottom: preset.spacing(3),
                '.ant-card-body': {
                  height: '100%',
                  paddingTop: preset.spacing(2),
                  paddingBottom: preset.spacing(2),
                },
              }}
            >
              <div ref={chartContainer} css={{ height: '100%' }}>
                <ChartDisplay
                  loading={chartQueryLoading || chartLoading}
                  error={chartQueryError}
                  height={chartHeight}
                  chart={{
                    ...chart,
                    color: defaultColor,
                    config: defaultConfig,
                    title: defaultTitle,
                  }}
                  onTitleChange={(value) =>
                    dispatch({
                      type: 'SET_VALUE',
                      key: 'defaultTitle',
                      value,
                    })
                  }
                  onDescriptionChange={(value) =>
                    dispatch({
                      type: 'SET_VALUE',
                      key: 'description',
                      value,
                    })
                  }
                />
              </div>

              {!chartQueryLoading && !chartLoading && chart.data.length > 0 && (
                <div
                  css={{
                    position: 'absolute',
                    top: preset.spacing(2),
                    right: preset.spacing(3),
                  }}
                >
                  <Button
                    type="primary"
                    loading={isSavingChart}
                    onClick={chartId ? saveChart : createChart}
                  >
                    {`${chartId ? 'Save' : 'Create'} Chart`}
                  </Button>
                </div>
              )}
            </Card>

            <DataAndCustomiseCard
              loading={chartQueryLoading || chartLoading}
              chart={chart}
              dispatch={dispatch}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Chart;
