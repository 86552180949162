export const initialState = {};

export const newFilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_VALUE':
            return {
                ...state,
                [action.key]: action.value,
            };

        case 'SET_VALUES':
            return {
                ...state,
                ...action.values,
            };

        case 'RESET':
            return initialState;

        default:
            return state;
    }
};
