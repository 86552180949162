import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  RouteProps,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';
import {
  AuthActions,
  InsightActions,
  TAllActionValues,
  useAuth,
} from '@otso/auth-wrapper';

import { DatasetsProvider, ProjectProvider } from 'providers';
import {
  // Project
  ProjectDashboards,
  Dashboard,
  ProjectExplore,
  ProjectInsightStudio,
  ProjectLocation,
  ProjectChart,
  ProjectAlerts,
  ProjectSettings,
  // Admin
  ViewProjects,
  CreateProject,
  Datasets,
  CreateDataset,
  EditDataset,

  // Super Admin
  OrganisationGodMode,
  // Others
  LinkSSO,
} from 'views';

type PageRouteProps = CustomRouteProps & { title?: string };

const PageRoute: React.FC<PageRouteProps> = ({
  title,
  action,
  children,
  ...restProps
}) => {
  useEffect(() => {
    const pageTitle = title ? `${title} | otso Insight` : 'otso.ai | Insight';
    document.title = pageTitle;
    return () => {
      document.title = 'otso.ai | Insight';
    };
  }, [title, restProps.path]);
  const { user } = useAuth();

  return (
    <Route {...restProps}>
      {action ? (
        user?.actions?.[action] ? (
          children
        ) : (
          <Redirect to={{ pathname: '/' }} />
        )
      ) : (
        children
      )}
    </Route>
  );
};

interface CustomRouteProps extends RouteProps {
  action?: TAllActionValues;
}

const SuperAdminRoute: React.FC<RouteProps> = ({ children, ...restProps }) => {
  const { user } = useAuth();
  return (
    <Route {...restProps}>
      {user?.isSuper ? children : <Redirect to={{ pathname: '/' }} />}
    </Route>
  );
};

const DatasetRoutes: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <DatasetsProvider>
      <Switch>
        <PageRoute exact path={path} title="Datasets">
          <Datasets />
        </PageRoute>
        <PageRoute exact path={`${path}/create`} title="Create Dataset">
          <CreateDataset />
        </PageRoute>
        <PageRoute path={`${path}/:id`} title="Edit Dataset">
          <EditDataset />
        </PageRoute>
      </Switch>
    </DatasetsProvider>
  );
};

const SuperAdminRoutes: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/datasets`}>
        <DatasetRoutes />
      </Route>
      <Route exact path={`${path}/organisations/:orgSlug/god-mode`}>
        <OrganisationGodMode />
      </Route>
    </Switch>
  );
};

const AdminRoutes: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/datasets`}>
        <DatasetRoutes />
      </Route>
    </Switch>
  );
};

const ProjectRoutes: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <ProjectProvider>
      <Switch>
        <PageRoute exact path={`${path}/dashboards`} title="Project Dashboards">
          <ProjectDashboards />
        </PageRoute>
        <PageRoute path={`${path}/dashboards/:dashboardSlug`} title="Dashboard">
          <Dashboard />
        </PageRoute>
        <PageRoute
          path={`${path}/explore`}
          title="Project Explore"
          action={InsightActions.viewDocuments}
        >
          <ProjectExplore />
        </PageRoute>
        <PageRoute
          path={`${path}/insight-studio`}
          title="Project Insight Studio"
        >
          <ProjectInsightStudio />
        </PageRoute>
        <PageRoute
          path={`${path}/location`}
          title="Project Location"
          action={InsightActions.viewLocationPage}
        >
          <ProjectLocation />
        </PageRoute>
        <PageRoute path={`${path}/charts/create`} title="Create Chart">
          <ProjectChart />
        </PageRoute>
        <PageRoute path={`${path}/charts/:chartId`} title="Edit Chart">
          <ProjectChart />
        </PageRoute>
        <PageRoute exact path={`${path}/alerts`} title="Project Alerts">
          <ProjectAlerts />
        </PageRoute>
        <PageRoute exact path={`${path}/alerts/:alertId`} title="Edit Alert">
          <ProjectAlerts />
        </PageRoute>
        <PageRoute
          exact
          path={`${path}/alerts/:alertId/:alertAction`}
          title="Acknowledge Alert"
        >
          <ProjectAlerts />
        </PageRoute>
        <PageRoute path={`${path}/settings`} title="Project Settings">
          <ProjectSettings />
        </PageRoute>
        <PageRoute exact path={path} title="Project Dashboards">
          <ProjectDashboards />
        </PageRoute>
      </Switch>
    </ProjectProvider>
  );
};

export const Routes: React.FC = () => {
  return (
    <Switch>
      <SuperAdminRoute path="/superadmin">
        <SuperAdminRoutes />
      </SuperAdminRoute>
      <PageRoute title="Link SSO" path="/link-sso/:provider">
        <LinkSSO />
      </PageRoute>
      <PageRoute exact path="/projects" title="Projects">
        <ViewProjects />
      </PageRoute>
      <PageRoute path="/admin" action={AuthActions.manageOrganisationUsers}>
        <AdminRoutes />
      </PageRoute>
      <PageRoute path="/projects/create" title="Create Project">
        <CreateProject />
      </PageRoute>
      <Route path="/projects/:slug">
        <ProjectRoutes />
      </Route>
      <PageRoute path="/" title="Projects">
        <ViewProjects />
      </PageRoute>
    </Switch>
  );
};
