/** @jsxImportSource @emotion/react */
import { Link, useParams } from 'react-router-dom';

// Import Ant Design
import { Card, Collapse, Checkbox, Space, Button } from 'antd';
import { LinkOutlined } from '@ant-design/icons';

import Container from '@components/Container';
import useGetApi from '@modules/hooks/useGetApi';

import ChartsEditor from './components/ChartsEditor';

const { Panel } = Collapse;

const OrganisationGodMode = () => {
  const { orgSlug } = useParams();

  const { data: projects, loading: isProjectsLoading } = useGetApi(
    `superadmin/organisations/${orgSlug}/projects`
  );

  return (
    <Container paddingY={2}>
      <Card title="Projects" loading={isProjectsLoading} bordered={false}>
        <Collapse accordion>
          {projects.map((project) => (
            <Panel
              key={project.slug}
              header={
                <Space>
                  {project.name}
                  <Button
                    type="link"
                    size="small"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Link
                      target="_blank"
                      to={{
                        pathname: `/projects/${project.slug}/dashboards`,
                        search: `?org=${orgSlug}`,
                      }}
                    >
                      <LinkOutlined />
                    </Link>
                  </Button>
                </Space>
              }
              extra={
                <div role="presentation" onClick={(e) => e.stopPropagation()}>
                  <Checkbox />
                </div>
              }
            >
              <ChartsEditor projectSlug={project.slug} />
            </Panel>
          ))}
        </Collapse>
      </Card>
    </Container>
  );
};

export default OrganisationGodMode;
