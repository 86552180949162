/** @jsxImportSource @emotion/react */
import { useReducer, useContext, useMemo, useEffect, useState } from 'react';
import moment from 'moment-timezone';

import { Form, Button } from 'antd';
import { PlusOutlined, FormOutlined } from '@ant-design/icons';

import { preset } from 'styles';
import { CHART_TYPES } from 'constant';

import { ProjectContext } from '@providers/ProjectProvider';
import {
  getSharedSchemaFields,
  getCategorisedSchemaFields,
  getFormatRequiredAttributes,
} from '@modules/filterBarHelpers';
import useFilterOptions from '@modules/hooks/useFilterOptions';
import { formatEmptyObject } from '@modules/items';

// Setup Form Components
import { chartSetupFormReducer, initialState } from './reducer';
import Datasets from './components/Datasets';
import ChartTypeInput from './components/ChartTypeInput';
import Metrics from './components/Metrics';
import Dimensions from './components/Dimensions';
import Filters from './components/Filters';
import GroupBy from './components/GroupBy';
import CompareModal from './components/CompareModal';

const { byType } = CHART_TYPES;

const SetupForm = ({
  chart,
  chartReducerDispatch,
  chartQueryLoading = false,
  runChartQuery,
}) => {
  const { id: chartId } = chart;

  const [isChartInitialised, setIsChartInitialised] = useState(false);
  const [setupChart, dispatch] = useReducer(
    chartSetupFormReducer,
    initialState
  );

  const {
    chartType,
    queryObj: {
      dataset: selectedDatasetIds = [],
      metrics = [],
      dimensions = [],
      filters = [],
      timeFilter,
      groupBy = [],
      compare,
    },
    isCompareModalOpen,
    editingCompareQueryObj,
  } = setupChart;

  // Get selected chart max number of entered metrics and dimensions
  const {
    maxMetrics = 1,
    maxDimensions = 1,
    hints,
    comparable = false,
  } = byType[chartType] || {};

  const {
    project: { name: projectName, datasets: projectDatasets = [] },
    loading: isProjectDatasetsLoading,
  } = useContext(ProjectContext);

  // Init setup form state for chart edit
  useEffect(() => {
    if (chartId && projectDatasets.length > 0 && !isChartInitialised) {
      setIsChartInitialised(true);
      dispatch({
        type: 'INIT_CHART_SETUP',
        chart: {
          chartType: chart.chartType,
          queryObj: chart.queryObj,
        },
      });
    }
  }, [
    chartId,
    projectDatasets.length,
    isChartInitialised,
    chart.chartType,
    chart.queryObj,
  ]);

  const selectedProjectDatasets = useMemo(() => {
    if (projectDatasets.length > 0) {
      return selectedDatasetIds.reduce((prev, datasetId) => {
        const dataset = projectDatasets.find(
          (projectDataset) => projectDataset.id === datasetId
        );
        return dataset ? [...prev, dataset] : prev;
      }, []);
    }

    return [];
  }, [selectedDatasetIds, projectDatasets]);

  // Set chart description
  useEffect(() => {
    if (selectedProjectDatasets.length > 0 && !chartId) {
      const description =
        selectedProjectDatasets.length > 1
          ? projectName
          : selectedProjectDatasets[0].name;

      chartReducerDispatch({
        type: 'SET_VALUE',
        key: 'description',
        value: description,
      });
    }
  }, [selectedProjectDatasets, chartReducerDispatch, projectName, chartId]);

  // Get common schema fields for multi datasets
  const sharedSchemaFields = useMemo(() => {
    return [
      ...getSharedSchemaFields(selectedProjectDatasets),
      ...(selectedProjectDatasets.length > 1
        ? [
            {
              name: 'Dataset Name',
              type: 'string',
              category: 'dimension',
            },
          ]
        : []),
    ];
  }, [selectedProjectDatasets]);

  // Group and format schema fields for attribute select dropdown
  const categorisedSchemaFields = useMemo(
    () => getCategorisedSchemaFields(sharedSchemaFields, {}),
    [sharedSchemaFields]
  );

  // Get filterOptions for fields like 'channel', 'source', 'language', 'entities'
  const filterOptions = useFilterOptions(
    sharedSchemaFields,
    selectedProjectDatasets
  );

  // Get list of field names that need filterOption value formatted like 'channel', 'source', 'language'
  const formatRequiredAttributes = useMemo(
    () => getFormatRequiredAttributes(categorisedSchemaFields),
    [categorisedSchemaFields]
  );

  const isApplyDisabled = useMemo(() => {
    return selectedDatasetIds.length === 0 || !chartType;
  }, [selectedDatasetIds.length, chartType]);

  // Format Setup form data
  const onSetupFormSubmit = () => {
    const formatTimeFilter = (rawTimeFilter) => {
      if (!rawTimeFilter) return null;
      const formattedTimeFilter = {
        relative: rawTimeFilter.relative || null,
        from: rawTimeFilter.from ? moment(rawTimeFilter.from).format() : null,
        to: rawTimeFilter.to ? moment(rawTimeFilter.to).format() : null,
      };
      return formatEmptyObject(formattedTimeFilter);
    };

    const formattedQueryObj = {
      ...setupChart.queryObj,
      timeFilter: formatTimeFilter(timeFilter),
      ...(compare && compare.filters
        ? {
            compare: {
              ...compare,
              timeFilter: formatTimeFilter(compare.timeFilter),
            },
          }
        : {}),
    };

    runChartQuery(chartType, formattedQueryObj);
  };

  // Get compare value for display (if value does not exist in compare, use the one from queryObj)
  const compareDisplayValue = useMemo(() => {
    const { compare: compareVal, ...queryObjVal } = setupChart.queryObj;

    return { ...queryObjVal, ...(editingCompareQueryObj || {}) };
  }, [setupChart.queryObj, editingCompareQueryObj]);

  return (
    <Form
      layout="vertical"
      onFinish={onSetupFormSubmit}
      css={{
        '.ant-form-item .ant-collapse .ant-form-item': {
          marginBottom: preset.spacing(1),
        },
      }}
    >
      <Form.Item label="Datasets" required>
        <Datasets
          value={selectedDatasetIds}
          onChange={(value) =>
            dispatch({
              type: 'SET_QUERYOBJ_VALUE',
              key: 'dataset',
              value,
            })
          }
          loading={isProjectDatasetsLoading}
          projectDatasets={projectDatasets}
        />
      </Form.Item>

      <Form.Item label="Chart Type" required help={hints}>
        <ChartTypeInput
          value={chartType}
          onChange={(value) =>
            dispatch({
              type: 'SET_CHART_TYPE',
              value,
            })
          }
        />
      </Form.Item>

      <Form.Item label="Metrics" required>
        <Metrics
          value={metrics}
          onChange={(value, itemKey, index) =>
            dispatch({
              type: 'SET_QUERYOBJ_ARRAY_VALUE',
              key: 'metrics',
              index,
              itemKey,
              value,
            })
          }
          onRemove={(index) =>
            dispatch({
              type: 'REMOVE_QUERYOBJ_ARRAY_VALUE',
              key: 'metrics',
              index,
            })
          }
          attributeOptions={categorisedSchemaFields}
          // handle metric as field change
          onAsFieldChange={(oldAs, newAs) =>
            dispatch({
              type: 'ON_AS_FIELD_CHANGE',
              oldAs,
              newAs,
              orderByOnly: true,
            })
          }
          // for metric filter config
          sharedSchemaFields={sharedSchemaFields}
          filterOptions={filterOptions}
          formatRequiredAttributes={formatRequiredAttributes}
          datasetIds={selectedDatasetIds}
        />
        <Button
          block
          icon={<PlusOutlined />}
          type="dashed"
          disabled={metrics.length >= maxMetrics}
          onClick={() =>
            dispatch({
              type: 'ADD_QUERYOBJ_ARRAY_VALUE',
              key: 'metrics',
              value: {},
            })
          }
        >
          Add Metric
        </Button>
      </Form.Item>

      <Form.Item label="Dimensions">
        <Dimensions
          dimensions={dimensions}
          metrics={metrics}
          onChange={(value, itemKey, index) =>
            dispatch({
              type: 'SET_QUERYOBJ_ARRAY_VALUE',
              key: 'dimensions',
              index,
              itemKey,
              value,
            })
          }
          onRemove={(index) =>
            dispatch({
              type: 'REMOVE_QUERYOBJ_ARRAY_VALUE',
              key: 'dimensions',
              index,
            })
          }
          attributeOptions={categorisedSchemaFields}
          showGeoLocationDimensions={chartType === 'choropleth_map'}
          // handle dimension/metric as field change
          onAsFieldChange={(oldAs, newAs, isTimeDimension) =>
            dispatch({
              type: 'ON_AS_FIELD_CHANGE',
              oldAs,
              newAs,
              isTimeDimension,
            })
          }
        />
        <Button
          block
          icon={<PlusOutlined />}
          type="dashed"
          disabled={dimensions.length >= maxDimensions}
          onClick={() =>
            dispatch({
              type: 'ADD_QUERYOBJ_ARRAY_VALUE',
              key: 'dimensions',
              value: {
                orderBy: 'Alphabetical',
                orderByDirection: 'asc',
                limit: 2000,
              },
            })
          }
        >
          Add Dimension
        </Button>
      </Form.Item>

      <Form.Item label="Filters">
        <Filters
          value={filters}
          onChange={(value, itemKey, index) =>
            dispatch({
              type: 'SET_QUERYOBJ_ARRAY_VALUE',
              key: 'filters',
              index,
              itemKey,
              value,
            })
          }
          onRemove={(index) =>
            dispatch({
              type: 'REMOVE_QUERYOBJ_ARRAY_VALUE',
              key: 'filters',
              index,
            })
          }
          timeFilter={timeFilter}
          onTimeFilterChange={(value) =>
            dispatch({
              type: 'SET_QUERYOBJ_VALUE',
              key: 'timeFilter',
              value,
            })
          }
          sharedSchemaFields={sharedSchemaFields}
          attributeOptions={categorisedSchemaFields}
          filterOptions={filterOptions}
          formatRequiredAttributes={formatRequiredAttributes}
          datasetIds={selectedDatasetIds}
        />
        <Button
          block
          icon={<PlusOutlined />}
          type="dashed"
          onClick={() =>
            dispatch({
              type: 'ADD_QUERYOBJ_ARRAY_VALUE',
              key: 'filters',
              value: {},
            })
          }
        >
          Add Filter
        </Button>
      </Form.Item>

      <Form.Item label="Group By" hidden>
        <GroupBy
          value={groupBy}
          onChange={(value) =>
            dispatch({
              type: 'SET_QUERYOBJ_VALUE',
              key: 'groupBy',
              value,
            })
          }
          dimensions={dimensions}
          attributeOptions={categorisedSchemaFields}
        />
      </Form.Item>

      {comparable && (
        <Form.Item
          label="Compare To"
          help={
            compare && typeof compare === 'object'
              ? compare.compareType === 'previous_period'
                ? 'Compare to previous equivalent period'
                : compare.compareType === 'previous_year'
                ? 'Compare to equivalent period in the previous year'
                : 'Custom compare'
              : ''
          }
          css={{
            '.ant-form-item-explain': { marginBottom: preset.spacing(1) },
          }}
        >
          {compare && typeof compare === 'object' ? (
            <Button
              block
              icon={<FormOutlined />}
              onClick={() => dispatch({ type: 'ON_COMPARE_MODAL_OPEN' })}
            >
              Edit Compare To
            </Button>
          ) : (
            <Button
              block
              icon={<PlusOutlined />}
              type="dashed"
              onClick={() => dispatch({ type: 'ON_COMPARE_MODAL_OPEN' })}
            >
              Add Compare To
            </Button>
          )}

          <CompareModal
            // modal open
            open={isCompareModalOpen}
            // compare value
            value={compare}
            displayValue={compareDisplayValue}
            dispatch={dispatch}
            // other values for compare queryObj fields
            projectDatasets={projectDatasets}
            categorisedSchemaFields={categorisedSchemaFields}
            sharedSchemaFields={sharedSchemaFields}
            filterOptions={filterOptions}
            formatRequiredAttributes={formatRequiredAttributes}
          />
        </Form.Item>
      )}

      <Form.Item css={{ marginBottom: 0 }}>
        <Button
          block
          type="primary"
          htmlType="submit"
          disabled={isApplyDisabled}
          loading={chartQueryLoading}
        >
          Apply
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SetupForm;
