/** @jsxImportSource @emotion/react */
const Tooltip = ({ config = {} }) => {
  const { x, y, data = [], color } = config;

  const showTooltip = data.length > 0;

  return (
    <div
      css={{
        position: 'absolute',
        zIndex: 8,
        transition:
          'visibility 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s, left 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s, top 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s',
        boxShadow: 'rgb(174, 174, 174) 0px 0px 10px',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderRadius: 3,
        color: 'rgb(87, 87, 87)',
        fontSize: 12,
        lineHeight: '20px',
        padding: '10px 10px 6px',
        whiteSpace: 'nowrap',
        pointerEvents: 'none',
        top: y,
        left: x,
        display: showTooltip ? 'block' : 'none',
        visibility: showTooltip ? 'visible' : 'hidden',
      }}
    >
      <ul
        css={{
          listStyle: 'none',
          margin: 0,
          padding: 0,
        }}
      >
        {data.map((row) => (
          <li
            key={row.name}
            css={{
              height: 20,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 4,
            }}
          >
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                marginRight: 30,
              }}
            >
              <div
                css={{
                  width: 5,
                  height: 5,
                  backgroundColor: color,
                  borderRadius: '50%',
                  marginRight: 8,
                }}
              />
              <div>{row.name}</div>
            </div>
            <div>{row.value}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Tooltip;
