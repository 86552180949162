/** @jsxImportSource @emotion/react */
import React from 'react';
import { colors } from 'styles';
import { fetchApiErrorMessage } from 'modules';

type Props = {
  error?: any;
  description?: string;
  fullHeight?: boolean;
  className?: string;
};

const Empty: React.FC<Props> = ({
  error,
  description = 'No Data',
  fullHeight = false,
  className,
}) => {
  return (
    <div
      className={className}
      css={{
        color: colors.lightGrey,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        ...(fullHeight
          ? {
              height: '100%',
            }
          : {
              marginTop: '10%',
              marginBottom: '10%',
            }),
      }}
    >
      {error
        ? typeof error === 'string'
          ? error
          : `Error: ${fetchApiErrorMessage(error)}`
        : description}
    </div>
  );
};

export default Empty;
