/** @jsxImportSource @emotion/react */

// Import libraries
import { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useAuth, UserInfo } from '@otso/auth-wrapper';

// Import Ant Design components
import {
  Card,
  Alert,
  Row,
  Col,
  Tooltip,
  Button,
  Badge,
  Table,
  PageHeader,
} from 'antd';
import { SyncOutlined, CheckCircleTwoTone } from '@ant-design/icons';

// Import context
import { preset } from 'styles';
import {
  useIsSuperAdminRoute,
  getApi,
  useGetApi,
  deepGet,
  formatDateInTZ,
} from 'modules';
import { Container } from 'components';
import MeltwaterExport from './components/MeltwaterExport';
import DatasetUsage from './components/DatasetUsageContainer';
import DataStreamingSwitch from './components/DataStreamingSwitch';
import DatasetBasicDetails from './components/DatasetBasicDetails';
import DatasetIntegrationTable from './components/DatasetIntegrationTable';
import SchemaCustomModal from './components/SchemaCustomModal';

/** EditDataset class allows users to view or update a dataset details. */
const EditDataset = (props) => {
  const [datasetId] = useState(useParams().id);

  const history = useHistory();

  // Initialization
  const [integrations, setIntegrations] = useState([]);
  const [dataset, setDataset] = useState({});
  const [datasetIntegration, setDatasetIntegration] = useState({});
  const [datasetFetchedAt, setDatasetFetchedAt] = useState(
    new Date().getTime()
  );
  const [isSchemaCustomModalOpen, setIsSchemaCustomModalOpen] = useState(false);

  const { currentOrg } = useAuth();

  const isGlobal = useIsSuperAdminRoute();

  const { data: datasetProjects } = useGetApi(
    `datasets/${datasetId}/projects`,
    []
  );

  useEffect(() => {
    // Get integration data as an admin user or get global integration
    // data as a super admin user
    const getIntegration = async () => {
      const { data } = await getApi(
        isGlobal ? 'superadmin/integrations' : 'integrations'
      );
      setIntegrations(data);
    };

    getIntegration();
  }, [isGlobal]);

  useEffect(() => {
    // Get dataset data as an admin user or get global dataset data
    // as a super admin user
    const getDataset = async () => {
      const { data } = await getApi(
        isGlobal ? `superadmin/datasets/${datasetId}` : `datasets/${datasetId}`
      );
      setDataset(data);
    };

    getDataset();
  }, [datasetId, datasetFetchedAt, isGlobal]);

  useEffect(() => {
    setDatasetIntegration(
      integrations.find(
        (integration) => integration.id === dataset.integrationId
      ) || {}
    );
  }, [dataset.integrationId, integrations]);

  // Obtain datset data
  const { err } = props;
  const { uploadType, integrationMeta, integrationId } = dataset;
  const {
    failed: numOfFailures,
    isGlobal: datasetIsGlobal,
    type: integrationType,
    exported,
  } = integrationMeta || {};

  // Fetch dataset data
  const fetchDataset = async () => {
    setDatasetFetchedAt(new Date().getTime());
  };

  // Construct ingestion failure message for manual upload
  const statusMessage =
    numOfFailures === undefined
      ? 'Data ingestion may take some time, please come back later or contact support if you think there has been an error.'
      : numOfFailures > 0
      ? `Failed to ingest ${numOfFailures} document(s), possibly due to a wrong data type selected in the schema or data error in the uploaded file`
      : 'Successfully ingested all documents';

  return (
    <div>
      <PageHeader
        ghost={false}
        title={dataset.name}
        onBack={() => history.push('/admin/datasets')}
        extra={[
          ...(uploadType === 'manual'
            ? [
                <Link
                  key="uploadDataLink"
                  to={
                    isGlobal
                      ? `/superadmin/datasets/create?type=manual&id=${datasetId}`
                      : `/admin/datasets/create?type=manual&id=${datasetId}`
                  }
                >
                  <Tooltip
                    placement="rightTop"
                    title={
                      numOfFailures === undefined
                        ? 'Data ingestion in progress, please come back later'
                        : 'Upload more data to this dataset'
                    }
                  >
                    <Button
                      type="primary"
                      disabled={numOfFailures === undefined}
                    >
                      Upload data
                    </Button>
                  </Tooltip>
                </Link>,
              ]
            : []),
          ...(uploadType === 'integration'
            ? [
                <Link
                  key="editDatasetLink"
                  to={
                    exported
                      ? isGlobal
                        ? `/superadmin/datasets/create?type=integration&id=${datasetId}`
                        : `/admin/datasets/create?type=integration&id=${datasetId}`
                      : isGlobal
                      ? `/superadmin/datasets/${datasetId}`
                      : `/admin/datasets/${datasetId}`
                  }
                >
                  {integrationType === 'reviewshake' && (
                    <Tooltip
                      placement="rightTop"
                      title={
                        !exported
                          ? 'Data scraping in progress, please come back later'
                          : 'Add Datashake profiles'
                      }
                    >
                      <Button
                        key="addProfiles"
                        type="primary"
                        disabled={!exported}
                      >
                        Add Profiles
                      </Button>
                    </Tooltip>
                  )}

                  {integrationType === 'reddit' && (
                    <Tooltip
                      placement="rightTop"
                      title={
                        !exported
                          ? 'Data ingestion in progress, please come back later'
                          : 'Edit Reddit Configurations'
                      }
                    >
                      <Button
                        key="editReddit"
                        type="primary"
                        disabled={!exported}
                      >
                        Edit Reddit
                      </Button>
                    </Tooltip>
                  )}
                </Link>,
              ]
            : []),
        ]}
      />
      <Container paddingY={3}>
        {err ? (
          <Alert message="Error" description={err} type="error" showIcon />
        ) : null}

        <Row gutter={preset.spacing(2)}>
          <Col span={9}>
            <Row gutter={[0, preset.spacing(2)]}>
              <Col span={24}>
                <Card
                  id="editDatasetInformation"
                  title={
                    uploadType === 'manual' ? (
                      <Tooltip placement="right" title={statusMessage}>
                        Dataset Information&nbsp;&nbsp;
                        {numOfFailures === undefined ? (
                          <SyncOutlined spin />
                        ) : numOfFailures > 0 ? (
                          <Badge
                            count={numOfFailures}
                            style={{ backgroundColor: 'orange' }}
                          />
                        ) : (
                          <CheckCircleTwoTone twoToneColor="#52c41a" />
                        )}
                      </Tooltip>
                    ) : (
                      'Dataset Information'
                    )
                  }
                  extra={
                    <Tooltip
                      title={formatDateInTZ(dataset.createdAt, 'second', '/')}
                    >
                      <span>
                        {`Created: ${formatDateInTZ(
                          dataset.createdAt,
                          'day',
                          '/'
                        )}`}
                      </span>
                    </Tooltip>
                  }
                >
                  <DatasetBasicDetails
                    dataset={dataset}
                    fetchDataset={fetchDataset}
                  />
                </Card>
              </Col>

              <Col span={24}>
                <Card
                  id="datasetSourceInformation"
                  title="Dataset Source Information"
                  extra={
                    <Button
                      type="link"
                      size="small"
                      disabled={
                        dataset.uploadType !== 'data' &&
                        !Array.isArray(deepGet(dataset, ['schema', 'fields']))
                      }
                      onClick={() => setIsSchemaCustomModalOpen(true)}
                    >
                      {`${datasetIsGlobal ? 'View' : 'Edit'} Schema`}
                    </Button>
                  }
                >
                  <DatasetIntegrationTable
                    dataset={dataset}
                    integrations={integrations}
                    integration={datasetIntegration}
                  />
                  {uploadType === 'integration' && integrationId && (
                    <div>
                      {datasetIntegration.type === 'meltwater' && (
                        <MeltwaterExport dataset={dataset} />
                      )}
                      <DataStreamingSwitch
                        dataset={dataset}
                        fetchDataset={fetchDataset}
                      />
                    </div>
                  )}
                  {datasetIsGlobal && (
                    <Alert
                      message="Note: This is a global dataset managed and controlled by a central system. Please feel free to update dataset name, description or archive this dataset. If you want to make any other changes, please contact support for help."
                      type="info"
                      showIcon
                      css={{ marginTop: preset.spacing(3) }}
                    />
                  )}
                </Card>
              </Col>

              {currentOrg && (
                <Col span={24}>
                  <Card id="datasetProjects" title="Projects">
                    {datasetProjects && (
                      <Table
                        size="small"
                        dataSource={datasetProjects}
                        columns={[
                          {
                            title: 'Name',
                            dataIndex: 'name',
                            render: (name, row) => (
                              <Link to={`/projects/${row.slug}/dashboards`}>
                                {name}
                              </Link>
                            ),
                          },
                          {
                            title: 'Owner',
                            dataIndex: 'creatorId',
                            render: (creatorId) => (
                              <UserInfo id={creatorId} output="firstName" />
                            ),
                          },
                          {
                            title: 'Created',
                            dataIndex: 'createdAt',
                            render: (createdAt) =>
                              formatDateInTZ(createdAt, 'day', '/'),
                          },
                        ]}
                        rowKey="slug"
                        pagination={false}
                      />
                    )}
                  </Card>
                </Col>
              )}
            </Row>
          </Col>

          <Col span={15}>
            <span id="datasetUsage">
              <DatasetUsage datasetId={datasetId} />
            </span>
          </Col>
        </Row>
      </Container>
      {/* </Card> */}

      <SchemaCustomModal
        dataset={dataset}
        isGlobal={isGlobal}
        editable={!datasetIsGlobal}
        open={isSchemaCustomModalOpen}
        onClose={() => setIsSchemaCustomModalOpen(false)}
        fetchDataset={fetchDataset}
      />
    </div>
  );
};

export default EditDataset;
