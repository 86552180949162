/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import { connect } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { useLocation } from 'react-router-dom';
import queryString from 'qs';

import getChartDefaultConfig from '@modules/chart/getChartDefaultConfig';
import generateChartColor from '@modules/chart/generateChartColor';
import isNumber from '@modules/lib/isNumber';
import deepGet from '@modules/deepGet';

import { preset } from 'styles';
import Spinner from '../Spinner';
import Empty from '../Empty';
import ChartHeader from './components/ChartHeader';
import ChartTypeConfig from './components/ChartTypeConfig';
import ChartRenderError from './components/ChartRenderError';


const mapStateToProps = (state, ownProps) => {
  return {
    commentsShowing: state.dashboard.commentsShowingChartId
      ? state.dashboard.commentsShowingChartId === ownProps.dashboardChartId
      : ownProps.commentsEditing,
  };
};

const ChartDisplay = ({
  dashboardChartId,
  loading = false,
  error,
  width,
  height,
  noHeader = false,
  chart = {},
  comments = [],
  commentsEditing = false,
  onTitleChange,
  onDescriptionChange,
  actions = [],
  drillDownDisabled = false,
  // redux state
  commentsShowing = false,
}) => {
  const {
    chartType,
    queryObj,
    data = [],
    config = {},
    color = {},
    schema,
    renderer = 'canvas',
    setChartDownloadInstance,
  } = chart;

  const formattedQueryObj = useMemo(() => {
    const { dimensions } = queryObj || {};
    if (!Array.isArray(dimensions)) return queryObj;
    return {
      ...(queryObj || {}),
      dimensions: dimensions.map((dimension) =>
        dimension.functionValue
          ? {
              ...dimension,
              functionValue:
                deepGet(schema, [dimension.as, 'functionValue']) ||
                dimension.functionValue,
            }
          : dimension
      ),
    };
  }, [queryObj, schema]);

  const location = useLocation();

  const { print: isPrintingChart = false } = queryString.parse(location.search);

  // Only render chart when in view
  const [chartRef, inView] = useInView({ threshold: 0.25, triggerOnce: true });

  // Check if has data to render this chart
  const hasData = useMemo(() => {
    if (!queryObj) return false;

    if (!(Array.isArray(data) && data.length > 0)) return false;

    if (
      queryObj.compare &&
      data.length === 2 &&
      (!Array.isArray(data[0]) || data[0].length === 0)
    ) {
      return false;
    }

    switch (chartType) {
      case 'number':
      case 'gauge':
        return queryObj.compare
          ? isNumber(Object.values(data[0][0])[0])
          : isNumber(Object.values(data[0])[0]);
      case 'heatmap':
      case 'bubble':
      case 'pie_innerlabel':
      case 'pie_donut':
      case 'word_cloud':
        return Object.keys(data[0]).length > 1;
      default:
        return true;
    }
  }, [chartType, data, queryObj]);

  // Color object will be outdated if chart data got updated on server
  // Therefore need re-generate chart color from chart data
  const updatedChartColor = useMemo(() => {
    const { color: colorConfigFromData } = getChartDefaultConfig(
      chartType,
      data,
      formattedQueryObj,
      config
    );
    return generateChartColor(colorConfigFromData, color);
  }, [chartType, data, color, config, formattedQueryObj]);

  // Config chart body height
  const chartBodyHeight = useMemo(() => {
    const fullHeight = noHeader || chartType === 'rich_text';
    if (height === '100%') {
      return `calc(${height} - ${fullHeight ? 0 : preset.spacing(5)}px)`;
    }
    return height - (fullHeight ? 0 : preset.spacing(5));
  }, [height, noHeader, chartType]);

  // Format comments array
  const formattedChartComments = useMemo(
    () => (Array.isArray(comments) ? comments : []),
    [comments]
  );

  // Group comments with same dimension for displaying
  const groupedChartComments = useMemo(
    () =>
      formattedChartComments.reduce((prev, commentObj) => {
        const { dimension, metric, comment } = commentObj;
        const existingCommentPoint = prev.find((prevCommentObj) => {
          if (metric) {
            return (
              prevCommentObj.dimension === dimension &&
              prevCommentObj.metric === metric
            );
          }
          return prevCommentObj.dimension === dimension;
        });

        if (existingCommentPoint) {
          existingCommentPoint.comments.push(comment);
          return prev;
        }

        return [
          ...prev,
          {
            dimension,
            ...(metric ? { metric } : {}),
            comments: [comment],
          },
        ];
      }, []),
    [formattedChartComments]
  );

  return (
    <div
      css={{
        height,
        canvas: {
          userSelect: 'none',
        },
      }}
    >
      {loading ? (
        <Spinner fullHeight />
      ) : (
        <div ref={chartRef} css={{ height }}>
          {!noHeader && (
            <ChartHeader
              dashboardChartId={dashboardChartId}
              chartType={chartType}
              chart={chart}
              onTitleChange={onTitleChange}
              onDescriptionChange={onDescriptionChange}
              comments={formattedChartComments}
              commentsShowing={commentsShowing}
              actions={actions}
            />
          )}
          {(inView || isPrintingChart) &&
            (hasData ? (
              <ChartRenderError height={chartBodyHeight}>
                <ChartTypeConfig
                  chartType={chartType}
                  width={width}
                  height={chartBodyHeight}
                  queryObj={formattedQueryObj}
                  data={data}
                  config={config}
                  color={updatedChartColor}
                  schema={schema}
                  drillDownDisabled={drillDownDisabled}
                  // for chart download
                  renderer={renderer}
                  setChartDownloadInstance={setChartDownloadInstance}
                  // chart comments
                  comments={groupedChartComments}
                  commentsShowing={commentsShowing}
                  commentsEditing={commentsEditing}
                />
              </ChartRenderError>
            ) : (
              <div css={{ height: chartBodyHeight }}>
                <Empty fullHeight error={error} />
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(ChartDisplay);
