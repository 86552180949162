/** @jsxImportSource @emotion/react */
import {
    useMemo, useRef, useEffect, useCallback, useState,
} from 'react';
import { connect } from 'react-redux';
import * as d3 from 'd3';
import truncate from 'lodash/truncate';
import debounce from 'lodash/debounce';

import formatDimensionLabel from '@modules/chart/formatDimensionLabel';
import formatMetricLabel from '@modules/chart/formatMetricLabel';
import formatChartData from '@modules/chart/formatChartData';
import getColorByMetric from '@modules/chart/getColorByMetric';
import deepGet from '@modules/deepGet';

import Bubble from './components/Bubble';
import Tooltip from './components/Tooltip';
import Empty from '../../Empty';

const BubbleChart = ({
    height,
    config,
    color,
    queryObj,
    data,
    schema,
    drillDownDisabled = false,
    setChartDownloadInstance,
    // redux dispatch for triggering drilldown
    dispatch,
}) => {
    const {
        x, y, z, label: labelConfig,
    } = config;

    const [transformedData, setTransformedData] = useState([]);
    const [tooltipConfig, setTooltipConfig] = useState({});

    const chartContainer = useRef();
    const chartRef = useRef();

    const width = chartContainer.current ? chartContainer.current.clientWidth : 0;

    // Remove empty x
    const formattedData = useMemo(() => formatChartData(data, [x]), [data, x]);

    // Get bubble radius
    const radiusScale = useCallback(value => {
        // Get yValue range for radius scale
        const valueRange = [
            0.95 * d3.min(data, item => item[y]),
            1.05 * d3.max(data, item => item[y]),
        ];

        const fx = d3
            .scaleSqrt()
            .range([1, (height / 4)])
            .domain(valueRange);

        return fx(value);
    }, [data, height, y]);

    // Debounce set transformed data to prevent insane re-rendering
    const debounceSetTransformedData = useMemo(
        () => debounce(newData => setTransformedData(newData), 300),
        [],
    );

    // Clear lodash debounce when unmount
    useEffect(() => {
        return () => {
            debounceSetTransformedData.cancel();
        };
    }, [debounceSetTransformedData]);

    // Transform to d3 bubble data
    useEffect(() => {
        let didCancel = false;

        d3
            .forceSimulation()
            .nodes(formattedData)
            .velocityDecay(0.5)
            .force('x', d3.forceX().strength(0.05))
            .force('y', d3.forceY().strength(0.05))
            .force(
                'collide',
                d3.forceCollide(d => {
                    return radiusScale(d[y]) + 2;
                }),
            )
            .on('tick', () => {
                if (!didCancel) {
                    debounceSetTransformedData([...formattedData]);
                }
            });

        return () => {
            didCancel = true;
        };
    }, [formattedData, y, radiusScale, debounceSetTransformedData]);

    // Pass chart instance to download modal
    useEffect(() => {
        if (setChartDownloadInstance && width > 0 && transformedData.length > 0 && chartRef.current) {
            setChartDownloadInstance(chartRef.current);
        }
    }, [setChartDownloadInstance, transformedData.length, width]);

    // Get colorMap when multiple metrics
    const colorByMetricMap = useMemo(() => {
        if (!z) return [];

        return getColorByMetric({
            x, config, color, data, colorBy: z,
        })[1];
    }, [config, color, data, x, z]);

    // Get Bubble config
    const getBubblePropsConfig = (row, index) => {
        const bubbleX = (width / 2) + row.x;
        const bubbleY = (height / 2) + row.y;
        const bubbleRadius = radiusScale(row[y]);
        const fontSize = bubbleRadius / 3;
        const bubbleDiameter = bubbleRadius * 2;

        const bubbleColor = z ? colorByMetricMap[index] : color[row[x]];

        // Fit label into bubble
        let label = row[x];
        const labelWidth = 0.562 * fontSize * `${label}`.length;
        const maxTextLen = Math.floor((bubbleDiameter / 0.562) / fontSize);
        if (bubbleDiameter < 15) {
            label = '';
        } else if (labelWidth > bubbleDiameter) {
            label = truncate(label, { length: maxTextLen, separator: /,? +/ });
        }

        const tooltipData = [
            {
                name: x,
                value: formatDimensionLabel(row[x], {
                    timeInterval: deepGet(schema, [x, 'functionValue']),
                    labelConfig,
                }),
            },
            { name: y, value: formatMetricLabel(row[y]) },
            ...z ? [{ name: z, value: formatMetricLabel(row[z]) }] : [],
        ];

        return {
            transform: `translate(${bubbleX}, ${bubbleY})`,
            color: bubbleColor,
            radius: bubbleRadius,
            fontSize,
            label,
            // tooltip trigger
            onMouseOver: () => setTooltipConfig({
                x: bubbleX, y: bubbleY, data: tooltipData, color: bubbleColor,
            }),
            onMouseOut: () => setTooltipConfig({}),
            // drilldown trigger
            onClick: () => {
                if (!drillDownDisabled) {
                    setTooltipConfig({});
                    dispatch({
                        type: 'SET_DRILLDOWN',
                        drilldown: {
                            x: bubbleX,
                            y: bubbleY,
                            chartElement: chartRef.current,
                            targetData: data[index],
                            queryObj,
                        },
                    });
                }
            },
        };
    };

    return (
        <div
            ref={chartContainer}
            css={{
                position: 'relative',
                height,
                g: {
                    cursor: drillDownDisabled ? 'auto' : 'pointer',
                    circle: {
                        transition: 'all 0.3s',
                    },
                },
            }}
        >
            {(width > 0 && transformedData.length > 0) ? (
                <svg
                    ref={chartRef}
                    width={width}
                    height={height}
                >
                    {transformedData.map((row, index) => (
                        <Bubble
                            key={row[x]}
                            {...getBubblePropsConfig(row, index)}
                        />
                    ))}
                </svg>
            ) : (
                <Empty
                    fullHeight
                    description="Bubble loading..."
                />
            )}

            <Tooltip config={tooltipConfig} />
        </div>
    );
};

export default connect()(BubbleChart);
