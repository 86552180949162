/** @jsxImportSource @emotion/react */
import { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { message, BackTop } from 'antd';

import { preset } from 'styles';
import Container from '@components/Container';
import Spinner from '@components/Spinner';
import ChartDownloadModal from '@components/ChartDownloadModal';
import { DASHBOARD_REFRESH } from 'constant';
import { ProjectContext } from '../../providers/ProjectProvider';
import { getApi, fetchApiErrorMessage } from '../../modules/api';
import useCheckPermission from '../../modules/hooks/useCheckPermission';

import DashboardHeader from './components/DashboardHeader';
import {
  DashboardPrintHeader,
  DashboardPrintFooter,
} from './components/DashboardPrintComponents';
import DashboardFilter from './components/DashboardFilter';
import DashboardCharts from './components/DashboardCharts';
import DashboardPageTabs from './components/DashboardPageTabs';
import ChartSelectModal from './components/ChartSelectModal';
import ScheduledEmailsModal from './components/ScheduledEmailsModal';
import ChartCommentsModal from './components/ChartCommentsModal';
import DashboardNameModal from './components/DashboardNameModal';
import DashboardPDFModal from './components/DashboardPDFModal';
import RichTextModal from './components/RichTextModal';

const ViewDashboard = ({
  // redux dispatch
  dispatch,
}) => {
  const { dashboardSlug } = useParams();
  const location = useLocation();

  const [isDashboardLoading, setIsDashboardLoading] = useState(true);
  const [isAutoRefresh, setIsAutoRefresh] = useState(false);

  const canAccessScheduledEmails = useCheckPermission([
    'insight.manageDashboards',
  ]);
  const canDrilldownData = useCheckPermission(['insight.viewDocuments']);

  // Get Project Datasets from Provider
  const {
    project: { slug: projectSlug },
    loading: isProjectDatasetsLoading,
  } = useContext(ProjectContext);

  // Fetch and Init Dashboard
  useEffect(() => {
    // This is used for aborting state transition if the component is unmounted
    let didCancel = false;

    const fetchDashboard = async () => {
      setIsDashboardLoading(true);

      try {
        const res = await getApi(
          `projects/${projectSlug}/dashboards/${dashboardSlug}`
        );
        if (!didCancel) {
          dispatch({
            type: 'INIT_DASHBOARD',
            dashboard: res.data,
          });
        }
      } catch (error) {
        message.error(fetchApiErrorMessage(error));
      }

      setIsDashboardLoading(false);
    };

    fetchDashboard();

    return () => {
      didCancel = true;
      dispatch({ type: 'RESET_DASHBOARD' });
    };
  }, [projectSlug, dashboardSlug, dispatch]);

  const isDashboardInitialised =
    !isDashboardLoading && !isProjectDatasetsLoading;

  const isPrintingDashboard = !!queryString.parse(location.search).print;

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      if (isAutoRefresh)
        dispatch({
          type: 'REFRESH_DASHBOARD_CHARTS',
        });
    }, DASHBOARD_REFRESH);
    return () => {
      clearInterval(refreshInterval);
    };
  });

  return (
    <div>
      {isPrintingDashboard ? (
        <DashboardPrintHeader />
      ) : (
        <DashboardHeader
          disabled={!isDashboardInitialised}
          isAutoRefreshEnabled={isAutoRefresh}
          toggleAutoRefresh={setIsAutoRefresh}
        />
      )}

      <Container css={isPrintingDashboard ? { css: { display: 'none' } } : {}}>
        {isDashboardInitialised && <DashboardFilter />}
      </Container>

      <div
        id="dashboard-charts-container"
        css={{
          paddingBottom: isPrintingDashboard
            ? preset.spacing(3)
            : 32 + preset.spacing(3),
        }}
      >
        {isDashboardInitialised ? (
          <>
            <DashboardCharts
              isPrintingDashboard={isPrintingDashboard}
              canDrilldownData={canDrilldownData}
            />

            <DashboardPageTabs
              css={{ display: isPrintingDashboard ? 'none' : 'flex' }}
            />

            {isPrintingDashboard ? (
              <DashboardPrintFooter />
            ) : (
              [
                <ChartSelectModal key="ChartSelectModal" />,
                canAccessScheduledEmails && (
                  <ScheduledEmailsModal key="ScheduledEmailsModal" />
                ),
                <DashboardNameModal key="DashboardNameModal" />,
                <ChartCommentsModal key="ChartCommentsModal" />,
                <DashboardPDFModal key="DashboardPDFModal" />,
                <RichTextModal key="RichTextModal" />,
                <ChartDownloadModal key="ChartDownloadModal" />,
                <BackTop key="backtop" css={{ right: 4, bottom: 60 }} />,
              ]
            )}
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

export default connect()(ViewDashboard);
