/** @jsxImportSource @emotion/react */

// Import libraries
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

// Import Ant Design components
import { Result, Button } from 'antd';

// Import components
import DSData from './DSData';

/**
 * CreateDataset component manages dataset creation by
 * integration and manual upload.
 */
const CreateDataset = () => {
  // Initialisation
  const history = useHistory();
  const location = useLocation();

  // Extract data from query parameters
  const queryParams = queryString.parse(location.search);
  const { id: datasetId } = queryParams || {};

  if (datasetId) {
    return (
      <Result
        status="warning"
        title="Operation not allowed"
        subTitle="Please use otso Data to upload data to the dataset."
        extra={
          <Button
            type="primary"
            onClick={() => history.push(`/admin/datasets/${datasetId}`)}
          >
            Back to Dataset
          </Button>
        }
      />
    );
  }

  return <DSData />;
};

export default CreateDataset;
