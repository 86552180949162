/* eslint-disable import/no-extraneous-dependencies */
/** @jsxImportSource @emotion/react */
import { useReducer, useEffect, useState, useMemo } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation, useParams, Link } from 'react-router-dom';
import {
  Input,
  Tag,
  Button,
  Modal,
  Pagination,
  Table,
  Space,
  message,
  Empty,
} from 'antd';
import { CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons';

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

import { trackEvent } from 'analytics';
import { preset } from 'styles';
import Container from '@components/Container';
import Spinner from '@components/Spinner';
import { deleteApi, putApi, fetchApiErrorMessage } from '@modules/api';
import { removeEmptyObjectValues } from '@modules/items';
import useGetApi from '@modules/hooks/useGetApi';
import useCheckPermission from '@modules/hooks/useCheckPermission';
import { primary } from 'styles/colors';

import { alertsReducer, initialState } from './reducer';
import AlertModal from './components/AlertModal';
import Charts from '../Charts';

const PAGE_SIZE = 10;

TimeAgo.addDefaultLocale(en);

const Alerts = () => {
  const history = useHistory();
  const location = useLocation();
  const { alertId, slug: projectSlug, alertAction } = useParams();

  const canCreateOrEditAlert = useCheckPermission(['insight.manageAlerts']);

  const [reducer, dispatch] = useReducer(alertsReducer, initialState);
  const [isQueryInit, setIsQueryInit] = useState(false);
  const [isChartsModalOpen, setIsChartsModalOpen] = useState(false);
  const timeAgo = new TimeAgo('en-US');

  const {
    query: { q, page },
    alert,
  } = reducer;

  // Set default states from url
  useEffect(() => {
    if (location && location.search && !isQueryInit) {
      const urlParams = queryString.parse(location.search);

      dispatch({
        type: 'SET_QUERY',
        query: {
          ...(urlParams.page ? { page: parseInt(urlParams.page, 10) } : {}),
          ...(urlParams.q ? { q: urlParams.q } : {}),
        },
      });
    }

    setIsQueryInit(true);
  }, [location, isQueryInit]);

  // Get all project alerts
  const {
    data: allProjectAlerts,
    loading: allProjectAlertsLoading,
    setData: setAllProjectAlerts,
  } = useGetApi(`projects/${projectSlug}/alerts`);

  // Open edit alert modal if alertId found in url
  useEffect(() => {
    if (alertId && allProjectAlerts.length > 0 && alertAction !== 'ack') {
      const selectedAlert = allProjectAlerts.find(
        (projectAlert) => projectAlert.id === parseInt(alertId, 10)
      );

      if (selectedAlert) {
        dispatch({
          type: 'SET_ALERT_VALUE',
          key: 'id',
          value: parseInt(alertId, 10),
        });
      }
    }
  }, [alertId, alertAction, allProjectAlerts]);

  // Open create alert modal if visualisationId found in location state
  useEffect(() => {
    if (location.state && location.state.visualisationId) {
      dispatch({
        type: 'SET_ALERT_VALUE',
        key: 'visualisationId',
        value: location.state.visualisationId,
      });
    }
  }, [location.state]);

  // Handle alerts query change
  const onQueryChange = (newQuery = {}) => {
    dispatch({
      type: 'SET_QUERY',
      query: newQuery,
    });

    history.push({
      search: queryString.stringify(removeEmptyObjectValues(newQuery)),
    });
  };

  // Open new alert modal
  const onAlertCreateModalOpen = () => {
    trackEvent('Create Alert Begin');
    setIsChartsModalOpen(true);
  };

  // Add new alert to state when creating new alert
  const onAlertCreate = (newAlert) => {
    setAllProjectAlerts([newAlert, ...allProjectAlerts]);
  };

  // Change the current alert state when editing alert
  const onAlertEdit = (newAlert) => {
    setAllProjectAlerts(
      allProjectAlerts.map((projectAlert) =>
        projectAlert.id === newAlert.id
          ? {
              ...projectAlert,
              ...newAlert,
            }
          : projectAlert
      )
    );
  };

  // Remove alert from state when deleting alert
  const onAlertDelete = (deletedAlertId) => {
    setAllProjectAlerts(
      allProjectAlerts.filter(
        (projectAlert) => projectAlert.id !== deletedAlertId
      )
    );
  };

  // Get filter alerts
  const filteredAlerts = useMemo(
    () =>
      isQueryInit
        ? allProjectAlerts.reduce((prev, projectAlert) => {
            // string query filter
            if (
              q &&
              !`${projectAlert.name}`.toLowerCase().includes(q.toLowerCase())
            ) {
              return prev;
            }

            return [...prev, projectAlert];
          }, [])
        : [],
    [isQueryInit, allProjectAlerts, q]
  );

  // Get alerts on current page
  const currentPageAlerts = useMemo(() => {
    const chartsLength = filteredAlerts.length;
    const offset = (page - 1) * PAGE_SIZE; // current page number multiplied by the number of alerts per page
    const endLimit =
      offset + PAGE_SIZE <= chartsLength ? offset + PAGE_SIZE : chartsLength;
    return filteredAlerts.slice(offset, endLimit);
  }, [filteredAlerts, page]);

  const ackAlert = async (id) => {
    try {
      await putApi(`projects/${projectSlug}/alerts/${id}/ack`);
      message.success('Alert acknowledged!');
    } catch (error) {
      message.error(fetchApiErrorMessage(error));
    }

    Modal.destroyAll();
    history.push(`/projects/${projectSlug}/alerts`);
  };

  const onDelete = (id) => {
    const deleteAlert = async () => {
      try {
        await deleteApi(`projects/${projectSlug}/alerts/${id}`);

        onAlertDelete(id);
        message.success('Alert Deleted!');
      } catch (error) {
        message.error(fetchApiErrorMessage(error));
      }
    };
    Modal.confirm({
      title: 'Are you sure you want to delete this alert?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: deleteAlert,
    });
  };

  const alertColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      onFilter: (value, record) => record.isActive === value,
      render: (text, record) => (
        <>
          {record.isActive ? (
            record.status?.actions?.webhook_1?.ack?.state === 'ackable' ? (
              <Tag color="warning">Alerted</Tag>
            ) : (
              <Tag color="green">Active</Tag>
            )
          ) : (
            <Tag>Inactive</Tag>
          )}{' '}
          <Link to={`/projects/${projectSlug}/alerts/${record.id}`}>
            {text}
          </Link>{' '}
          {record.status?.actions?.webhook_1?.ack?.state === 'acked' && (
            <Tag color="warning">Acknowledged</Tag>
          )}
        </>
      ),
    },
    {
      title: 'Chart',
      dataIndex: 'visualisationId',
      render: (_, record) => (
        <Link to={`/projects/${projectSlug}/charts/${record.visualisationId}`}>
          {record.visualisationName}
        </Link>
      ),
    },
    {
      title: 'Condition last met',
      dataIndex: ['status', 'last_met_condition'],
      render: (text) => <>{text ? timeAgo.format(new Date(text)) : 'N/A'}</>,
    },
    {
      title: 'Last check',
      dataIndex: ['status', 'last_checked'],
      render: (text) => <>{text ? timeAgo.format(new Date(text)) : 'N/A'}</>,
    },
    {
      title: 'Updated at',
      dataIndex: 'updatedAt',
      render: (text) => <>{text ? timeAgo.format(new Date(text)) : 'N/A'}</>,
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (_, record) => {
        return (
          <>
            <Space size="small">
              {record.status?.actions?.webhook_1?.ack?.state === 'ackable' && (
                <CheckCircleOutlined
                  key="ack"
                  style={{ fontSize: 16, color: primary }}
                  onClick={() => {
                    history.push(
                      `/projects/${projectSlug}/alerts/${record.id}/ack`
                    );
                  }}
                />
              )}
              {canCreateOrEditAlert && (
                <DeleteOutlined
                  key="delete"
                  style={{ fontSize: 16 }}
                  onClick={() => onDelete(record.id)}
                />
              )}
            </Space>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (
      alertId &&
      location.pathname.indexOf('/ack') >= 0 &&
      allProjectAlerts.length > 0
    ) {
      const selectedAlert = allProjectAlerts.find(
        (projectAlert) => `${projectAlert.id}` === `${alertId}`
      );
      if (selectedAlert) {
        // TODO: check the acked state of the selected alert
        if (selectedAlert.status?.actions?.webhook_1?.ack?.state !== 'acked') {
          Modal.confirm({
            title: `Are you sure you want to acknowledge the alert "${selectedAlert.name}"?`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => {
              ackAlert(alertId);
            },
            onCancel: () => {
              history.push(`/projects/${projectSlug}/alerts`);
              dispatch({ type: 'RESET_ALERT' });
              Modal.destroyAll();
            },
          });
        } else {
          message.error('Error: Alert already acknowledged');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertId, location.pathname, allProjectAlerts]);

  return (
    <Container>
      {currentPageAlerts.length > 0 && (
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: preset.spacing(3),
            marginTop: 12,
          }}
        >
          <Input.Search
            key={q} // user query as key to force refresh of input on query change
            allowClear
            placeholder="Press Enter to Search"
            defaultValue={q}
            onSearch={(val) =>
              onQueryChange({
                q: val,
                page: 1,
              })
            }
            css={{
              flex: 1,
              maxWidth: 500,
              marginRight: preset.spacing(3),
            }}
          />
          {canCreateOrEditAlert && (
            <Button type="primary" onClick={onAlertCreateModalOpen}>
              Create Alert
            </Button>
          )}
        </div>
      )}

      {allProjectAlertsLoading || !isQueryInit ? (
        <Spinner />
      ) : currentPageAlerts.length > 0 ? (
        <Table
          columns={alertColumns}
          dataSource={currentPageAlerts}
          rowKey={(record) => `row-${record.id}`}
          pagination={false}
        />
      ) : (
        <Empty
          css={{ marginTop: preset.spacing(10) }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No alerts found"
        >
          <Button type="primary" onClick={onAlertCreateModalOpen}>
            Create a new alert
          </Button>
        </Empty>
      )}

      {filteredAlerts.length > 0 && (
        <Pagination
          hideOnSinglePage
          total={filteredAlerts.length}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} alerts`
          }
          pageSize={PAGE_SIZE}
          current={page}
          onChange={(newPage) =>
            onQueryChange({
              q,
              page: newPage,
            })
          }
          css={{
            textAlign: 'right',
            marginTop: preset.spacing(5),
            marginBottom: 60,
          }}
        />
      )}

      {/* Alert create/edit modal */}
      <AlertModal
        alert={alert}
        onCancel={() => {
          history.push(`/projects/${projectSlug}/alerts`);
          dispatch({ type: 'RESET_ALERT' });
        }}
        dispatch={dispatch}
        actions={{
          onAlertCreate,
          onAlertEdit,
        }}
      />

      {/* Chart select modal */}
      <Modal
        title="Choose Chart to Create Alert"
        visible={isChartsModalOpen}
        footer={null}
        onCancel={() => setIsChartsModalOpen(false)}
        width={preset.modalWidth.lg}
      >
        <Charts
          width={preset.modalWidth.lg}
          hideCreateChartBtn
          extraFilter={{
            chartType: [
              'number',
              'gauge',
              'bar_basic',
              'line_basic',
              'area_basic',
            ],
          }}
          onSelect={(chartId) => {
            setIsChartsModalOpen(false);
            dispatch({
              type: 'SET_ALERT_VALUE',
              key: 'visualisationId',
              value: chartId,
            });
          }}
        />
      </Modal>
    </Container>
  );
};

export default Alerts;
