import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';

import formatMetricLabel from './formatMetricLabel';
import deepGet from '../deepGet';
import isNumber from '../lib/isNumber';

const getYAxisScale = (config = {}) => {
    const {
        log = false, axisConfig = {}, yAxisKey = 'y', data = [], y,
    } = config;

    const {
        min, max, minAlias, maxAlias,
    } = deepGet(axisConfig, [yAxisKey]) || {};

    const yMin = deepGet(minBy(data, y), [y]);
    const yMax = deepGet(maxBy(data, y), [y]);

    const rangeMin = (isNumber(min) && isNumber(yMax) && (min <= yMax)) ? min : null;
    const rangeMax = (isNumber(max) && isNumber(yMin) && (max >= yMin)) ? max : null;

    return {
        formatter: val => {
            if ((rangeMin !== null && minAlias)
            || (rangeMax !== null && maxAlias)) {
                if (val === rangeMin) {
                    return minAlias;
                }
                if (val === rangeMax) {
                    return maxAlias;
                }
                return '';
            }
            return formatMetricLabel(val);
        },
        ...log ? { type: 'log', base: 10 } : {},
        ...(rangeMin !== null) ? { min: rangeMin } : {},
        ...(rangeMax !== null) ? { max: rangeMax } : {},
    };
};

export default getYAxisScale;
