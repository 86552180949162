import { useState, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isEqual, difference } from 'lodash';
import PropTypes from 'prop-types';
import {
  Form,
  Select,
  Row,
  Col,
  Space,
  Card,
  message,
  Spin,
  Collapse,
  Alert,
} from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { preset } from 'styles';
import ValidationFormContainer from '@components/ValidationFormContainer';
import FilterBar from '@components/FilterBar';
import { ProjectContext } from '@providers/ProjectProvider';
import { getApi, postApi, fetchApiErrorMessage } from 'modules';
import getExploreQuery from '@modules/getExploreQuery';

const FormItem = Form.Item;
const { Option } = Select;

export const isFiltersEmpty = (filters) => {
  const filtersToCompare = { ...filters };
  delete filtersToCompare.datasetIds;

  const emptyFilters = {
    filters: [],
    timeFilter: null,
  };

  return isEqual(filtersToCompare, emptyFilters);
};

const ProjectDatasetForm = ({
  allDatasets,
  formValues = {
    datasets: [],
    filters: {},
  },
  onFormValueChange = () => null,
  valid = false,
  withContainer = true,
  style = {},
}) => {
  const [form] = Form.useForm();
  const { datasets, filters, initialFilters } = formValues;
  const [selectedDatasets, setSelectedDatasets] = useState([]);
  const [selectedDatasetsById, setSelectedDatasetById] = useState({});
  const [isDocumentsLoading, setIsDocumentsLoading] = useState({});
  const [isDatasetsLoading, setIsDatasetsLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState({});
  const [totalDocuments, setTotalDocuments] = useState({});
  const location = useLocation();
  const isOnSettingsPage = location.pathname.includes('/settings');
  const [isDatasetInitialised, setDatasetInitialised] = useState(false);

  // Notes: load initial datasets details if they are being passed down from parent component
  useEffect(() => {
    async function loadData() {
      const datasetResponses = [];
      const updatedSelectedDatasetsById = {};
      for (let i = 0; i < datasets.length; i++) {
        const { data } = await getApi(`datasets/${datasets[i]}`);
        datasetResponses.push(data);
      }
      datasetResponses.forEach((d) => {
        updatedSelectedDatasetsById[d.id] = d;
      });
      setSelectedDatasets(Object.values(updatedSelectedDatasetsById));
      setSelectedDatasetById(updatedSelectedDatasetsById);
    }

    if (datasets.length > 0 && !isDatasetInitialised) {
      loadData();
      setDatasetInitialised(true);
    }
  }, [datasets, isDatasetInitialised]);

  const onChangeDatasets = async (values) => {
    async function getDatasetDetails(datasetId) {
      setIsDatasetsLoading(true);
      const { data } = await getApi(`datasets/${datasetId}`);
      const updatedSelectedDatasetsById = {
        ...selectedDatasetsById,
      };
      updatedSelectedDatasetsById[datasetId] = data;
      setSelectedDatasets(Object.values(updatedSelectedDatasetsById));
      setSelectedDatasetById(updatedSelectedDatasetsById);
      setIsDatasetsLoading(false);

      const updatedFilters = { ...filters };
      updatedFilters[datasetId] = null;
      onFormValueChange(updatedFilters, 'filters', datasetId);
    }

    function removeDataset(datasetId) {
      const updatedSelectedDatasetsById = {
        ...selectedDatasetsById,
      };
      delete updatedSelectedDatasetsById[datasetId];
      setSelectedDatasets(Object.values(updatedSelectedDatasetsById));
      setSelectedDatasetById(updatedSelectedDatasetsById);

      const updatedFilters = { ...filters };
      delete updatedFilters[datasetId];
      onFormValueChange(updatedFilters, 'filters');
    }

    const datasetIds = Object.keys(selectedDatasetsById);
    const currentDatasetCount = selectedDatasets.length;

    if (values.length < currentDatasetCount) {
      // Removing dataset
      const datasetToBeRemoved = difference(datasetIds, values);
      removeDataset(datasetToBeRemoved[0]);
    } else if (values.length > currentDatasetCount) {
      // Adding dataset
      const datasetToBeAdded = difference(values, datasetIds);
      await getDatasetDetails(datasetToBeAdded[0]);
    } else if (values.length === 0) {
      // Reset filters if nothing is selected
      setSelectedDatasets([]);
      setSelectedDatasetById({});
      onFormValueChange({}, 'filters');
    }

    onFormValueChange(values, 'datasets');
  };

  const onClearFilters = (datasetId) => {
    const updatedHasSearch = {
      ...hasSearched,
    };
    updatedHasSearch[datasetId] = false;
    setHasSearched(updatedHasSearch);

    const updatedFilters = {
      ...filters,
    };
    updatedFilters[datasetId] = null;
    onFormValueChange(updatedFilters, 'filters', datasetId);
  };

  const onFilterChange = (selectedFilters, datasetId) => {
    const updatedFilters = {
      ...filters,
    };
    updatedFilters[datasetId] = selectedFilters;
    onFormValueChange(updatedFilters, 'filters', datasetId);
  };

  const onApplyFilters = async ({ filters: f, timeFilter }, datasetId) => {
    const query = getExploreQuery({
      datasetIds: [datasetId],
      filters: f,
      timeFilter,
      limit: 15,
    });

    const fetchDocuments = async () => {
      try {
        const updatedDocumentsLoading = {
          ...isDocumentsLoading,
        };
        updatedDocumentsLoading[datasetId] = true;
        setIsDocumentsLoading(updatedDocumentsLoading);

        const updatedHasSearch = {
          ...hasSearched,
        };
        updatedHasSearch[datasetId] = true;
        setHasSearched(updatedHasSearch);

        const res = await postApi(`projects/~/query`, query);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { data, total } = res.data;

        // TODO: render a sample list of documents from "data" in a popup so users can see what they are getting back
        if (typeof total === 'number') {
          const updatedTotalDocuments = {
            ...totalDocuments,
          };
          updatedTotalDocuments[datasetId] = total;
          setTotalDocuments(updatedTotalDocuments);
        }
      } catch (error) {
        message.error(fetchApiErrorMessage(error));
      } finally {
        const updatedDocumentsLoading = {
          ...isDocumentsLoading,
        };
        updatedDocumentsLoading[datasetId] = false;
        setIsDocumentsLoading(updatedDocumentsLoading);
      }
    };
    await fetchDocuments();
  };

  const datasetForm = useMemo(() => {
    return (
      <DatasetForm
        datasets={datasets}
        allDatasets={allDatasets}
        onChangeDatasets={onChangeDatasets}
        onApplyFilters={onApplyFilters}
        onClearFilters={onClearFilters}
        onFilterChange={onFilterChange}
        isDatasetsLoading={isDatasetsLoading}
        isDocumentsLoading={isDocumentsLoading}
        selectedDatasets={selectedDatasets}
        hasSearched={hasSearched}
        totalDocuments={totalDocuments}
        isOnSettingsPage={isOnSettingsPage}
        initialFilters={initialFilters}
        style={style}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    datasets,
    allDatasets,
    hasSearched,
    isDatasetsLoading,
    isDocumentsLoading,
    selectedDatasets,
    totalDocuments,
    onChangeDatasets,
  ]);

  return withContainer ? (
    <ValidationFormContainer title="Datasets & Filters" valid={valid}>
      <Form form={form} layout="vertical">
        {datasetForm}
      </Form>
    </ValidationFormContainer>
  ) : (
    datasetForm
  );
};

const DatasetForm = ({
  datasets,
  allDatasets,
  initialFilters,
  onChangeDatasets,
  onApplyFilters,
  onClearFilters,
  onFilterChange,
  isDatasetsLoading,
  isDocumentsLoading,
  selectedDatasets,
  hasSearched,
  totalDocuments,
  style,
  isOnSettingsPage = false,
}) => {
  const initialDatasetIds = initialFilters ? Object.keys(initialFilters) : [];

  return (
    <Col style={{ ...style }}>
      <Row span={24} gutter={preset.spacing(5)}>
        <Col span={12}>
          <FormItem label="Datasets" required data-testId="dataset-dropdown">
            <Select
              showSearch
              mode="multiple"
              placeholder="Add Datasets"
              optionFilterProp="children"
              value={datasets}
              onChange={(value) => onChangeDatasets(value)}
              disabled={isDatasetsLoading}
            >
              {allDatasets.map((datasetObj) => (
                <Option
                  key={datasetObj.id}
                  title={datasetObj.name}
                  value={datasetObj.id}
                  disabled={
                    isOnSettingsPage
                      ? initialDatasetIds.includes(datasetObj.id)
                      : false
                  }
                >
                  {datasetObj.name}
                </Option>
              ))}
            </Select>
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Spin spinning={isDatasetsLoading}>
            {selectedDatasets.length > 0 && (
              <Collapse expandIconPosition="right">
                {selectedDatasets.map((dataset) => {
                  const projectDatasetsById = {};
                  projectDatasetsById[dataset.id] = dataset;
                  let datasetFilters;
                  if (initialFilters) {
                    datasetFilters = initialFilters[dataset.id];
                  }
                  return (
                    <Collapse.Panel
                      header={
                        <Space>
                          <FilterOutlined />
                          {dataset.name}
                        </Space>
                      }
                      key={dataset.id}
                    >
                      <ProjectContext.Provider
                        value={{
                          project: {
                            slug: 'N/A',
                            datasets: [dataset],
                            projectDatasetsById,
                          },
                        }}
                      >
                        <>
                          <FilterBar
                            initialDatasetIds={[dataset.id]}
                            initialFilters={
                              datasetFilters ? datasetFilters.filters : []
                            }
                            initialTimeFilter={
                              datasetFilters ? datasetFilters.timeFilter : null
                            }
                            searchButtonText="Test"
                            addMoreFiltersButtonText="Add More Filters"
                            allowShareFilters={false}
                            onApply={(f) => {
                              onApplyFilters(f, dataset.id);
                            }}
                            onClear={() => onClearFilters(dataset.id)}
                            onFilterChange={(f) => {
                              onFilterChange(f, dataset.id);
                            }}
                            filterCardProps={{
                              size: 'small',
                            }}
                            isSearchLoading={isDocumentsLoading[dataset.id]}
                          />
                          {hasSearched[dataset.id] &&
                            !isDocumentsLoading[dataset.id] && (
                              <>
                                {totalDocuments[dataset.id] > 0 ? (
                                  <Card size="small">
                                    {`Total results: ${
                                      totalDocuments[dataset.id]
                                    }`}
                                  </Card>
                                ) : (
                                  <>
                                    <Alert
                                      message="No filtered results returned for this dataset"
                                      type="warning"
                                      showIcon
                                    />
                                  </>
                                )}
                              </>
                            )}
                        </>
                      </ProjectContext.Provider>
                    </Collapse.Panel>
                  );
                })}
              </Collapse>
            )}
          </Spin>
        </Col>
      </Row>
    </Col>
  );
};

ProjectDatasetForm.propTypes = {
  formValues: PropTypes.shape({
    datasets: PropTypes.arrayOf(PropTypes.string),
    filters: PropTypes.object,
  }),
  onFormValueChange: PropTypes.func,
  valid: PropTypes.bool,
  withContainer: PropTypes.bool,
  style: PropTypes.object,
};

export default ProjectDatasetForm;
