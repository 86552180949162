import { PureComponent } from 'react';
import { Chart, Geom, Axis, Tooltip, Legend } from 'bizcharts';

import getDualBarSize from '@modules/chart/getDualBarSize';

class UsageChart extends PureComponent {
  state = {
    chart: null,
    barSize: undefined,
  };

  initialChart = (g2Chart) => {
    this.setState({ chart: g2Chart });

    const barSize = getDualBarSize(g2Chart);
    this.setState({ barSize });
  };

  render() {
    const { data } = this.props;
    const { chart, barSize } = this.state;

    const scale = {
      ingestedAt: {
        alias: 'ingestedAt',
        type: 'timeCat',
      },
      enriched: {
        alias: 'Enriched Documents Processed',
        min: 0,
      },
      unenriched: {
        alias: 'Unenriched Documents Processed',
        min: 0,
      },
    };

    const items = [
      {
        value: 'Enriched Documents Processed',
        alias: 'enriched',
        marker: {
          symbol: 'square',
          fill: '#3182bd',
          radius: 5,
        },
      },
      {
        value: 'Unenriched Documents Processed',
        alias: 'unenriched',
        marker: {
          symbol: 'square',
          fill: '#ffae6b',
          radius: 5,
        },
      },
    ];

    return (
      <Chart
        height={window.innerHeight * 0.6}
        padding="auto"
        scale={scale}
        forceFit
        data={data}
        onGetG2Instance={(g2Chart) => this.initialChart(g2Chart)}
        placeholder="No usage data"
      >
        <Legend
          custom
          allowAllCanceled
          items={items}
          onClick={(ev) => {
            const { item, checked } = ev;
            const value = item.alias;
            const geoms = chart.getAllGeoms();

            for (let i = 0; i < geoms.length; i += 1) {
              const geom = geoms[i];

              if (geom.getYScale().field === value) {
                if (checked) {
                  geom.show();
                } else {
                  geom.hide();
                }
              }
            }
          }}
        />
        <Axis name="enriched" />
        <Axis
          name="unenriched"
          grid={null}
          label={{ textStyle: { fill: '#fdae6b' } }}
        />
        <Tooltip />
        <Geom
          type="interval"
          position="ingestedAt*enriched"
          color="#3182bd"
          shape="left"
          size={barSize}
        />
        <Geom
          type="interval"
          position="ingestedAt*unenriched"
          color="#fdae6b"
          shape="right"
          size={barSize}
        />
      </Chart>
    );
  }
}

export default UsageChart;
