/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '@otso/auth-wrapper';
import { Modal, message, Spin } from 'antd';

import { trackEvent } from 'analytics';
import { preset } from 'styles';
import ChartCard from '@components/ChartCard';

import { getApi, postApi, putApi, fetchApiErrorMessage } from '@modules/api';
import AlertForm from './AlertForm';

const AlertModal = ({ onCancel, alert, dispatch, actions = {} }) => {
  const { id, visualisationId } = alert;

  const { onAlertCreate, onAlertEdit } = actions;

  const { slug: projectSlug } = useParams();

  const [alertLoading, setAlertLoading] = useState(false);
  const [isSavingAlert, setIsSavingAlert] = useState(false);
  const { user } = useAuth();

  const orgSlug = user?.currentOrg;

  const isEditing = !!id;

  // Fetch editing alert
  useEffect(() => {
    if (id) {
      const fetchAlert = async () => {
        setAlertLoading(true);

        try {
          const res = await getApi(`projects/${projectSlug}/alerts/${id}`);

          dispatch({
            type: 'SET_ALERT',
            alert: res.data,
          });
        } catch (error) {
          message.error(fetchApiErrorMessage(error));
        }

        setAlertLoading(false);
      };

      fetchAlert();
    }
  }, [projectSlug, id, dispatch]);

  const onAlertModalClose = () => {
    onCancel();
  };

  // Create/Edit Alert
  const onAlertSave = (formValues = {}) => {
    const {
      name,
      isActive,
      thresholdValue,
      thresholdValueLower,
      thresholdValueUpper,
      filterTimeframe,
      filterTimeframeUnit,
      operator,
      occurrence,
      hasFilter,
      alertShares,
    } = formValues;

    const newAlert = {
      name,
      config: {
        thresholdValue,
        thresholdValueLower,
        thresholdValueUpper,
        operator,
        occurrence,
        hasFilter,
      },
      alertShares,
      visualisationId,
      isActive: !!isActive,
      orgSlug,
    };

    newAlert.config.filterTimeframe = hasFilter
      ? `${filterTimeframe}${filterTimeframeUnit}`
      : null;

    const createAlert = async () => {
      setIsSavingAlert(true);

      try {
        const res = await postApi(`projects/${projectSlug}/alerts`, newAlert);

        onAlertCreate(res.data);
        message.success('New Alert is Created');
        onAlertModalClose();
      } catch (error) {
        message.error(fetchApiErrorMessage(error));
      }

      setIsSavingAlert(false);
    };

    const saveAlert = async () => {
      setIsSavingAlert(true);

      try {
        const res = await putApi(
          `projects/${projectSlug}/alerts/${id}`,
          newAlert
        );

        onAlertEdit(res.data);
        message.success('Alert Saved');
        onAlertModalClose();
      } catch (error) {
        message.error(fetchApiErrorMessage(error));
      }

      setIsSavingAlert(false);
    };

    if (isEditing) {
      trackEvent('Edit Alert', { alertId: id });
      saveAlert();
    } else {
      trackEvent('Create Alert Complete', { alertName: newAlert.name });
      createAlert();
    }
  };

  return (
    <Modal
      title={`${isEditing ? 'Edit' : 'Create'} Alert`}
      visible={!!(id || visualisationId)}
      width={preset.modalWidth.lg}
      onCancel={onAlertModalClose}
      footer={null}
    >
      <ChartCard
        id={visualisationId}
        height={280}
        bordered
        drillDownDisabled
        css={{
          marginBottom: preset.spacing(5),
        }}
      />

      <Spin spinning={alertLoading}>
        <AlertForm
          alert={alert}
          onCancel={onAlertModalClose}
          okText={isEditing ? 'Save' : 'Create Alert'}
          onSave={onAlertSave}
          loading={isSavingAlert}
        />
      </Spin>
    </Modal>
  );
};

export default AlertModal;
