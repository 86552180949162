/** @jsxImportSource @emotion/react */
import { Card } from 'antd';

import { preset } from 'styles';

import ChartDisplay from './ChartDisplay';

const NoAPIChartCard = ({
  dashboardChartId,
  width,
  height,
  filters,
  timeFilter,
  refreshAt,
  comments = [],
  onClick,
  actions = [],
  bordered = false,
  hoverable = false,
  drillDownDisabled = false,
  className,
  chart,
}) => {
  return (
    <Card
      data-testId="chart-card"
      bordered={bordered}
      hoverable={hoverable}
      onClick={() => onClick && onClick()}
      css={{
        height: '100%',
        '.ant-card-body': {
          height: '100%',
          paddingTop: preset.spacing(2),
          paddingBottom: preset.spacing(2),
        },
      }}
      className={className}
    >
      <ChartDisplay
        dashboardChartId={dashboardChartId}
        // loading={loading}
        // error={error}
        width={width}
        height={height && height - preset.spacing(2) * 2}
        chart={chart}
        comments={comments}
        actions={actions}
        drillDownDisabled={drillDownDisabled}
      />
    </Card>
  );
};

export default NoAPIChartCard;
