/** @jsxImportSource @emotion/react */
import { useRef, useMemo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Chart, Axis, Tooltip, Geom } from 'bizcharts';
import chroma from 'chroma-js';

import {
  getAxisConfig,
  getXAxisLabelConfig,
  getYAxisScale,
  formatChartData,
  getXAxisScale,
  getChartElement,
} from 'modules/chart';
import { deepGet, useChartRef } from 'modules';
import CoordConfig from '../components/CoordConfig';
import ChartComments from '../components/ChartComments';

const AreaChart = ({
  width,
  height,
  config,
  color,
  queryObj,
  data,
  schema,
  drillDownDisabled = false,
  // chart download
  renderer,
  setChartDownloadInstance,
  // dashboard chart comments
  comments = [],
  commentsShowing = false,
  commentsEditing = false,
  // redux dispatch for triggering drilldown
  dispatch,
}) => {
  const {
    x,
    y,
    reverse = false,
    gradient = false,
    log = false,
    label: labelConfig,
    axis: axisConfig,
  } = config;

  const { dimensions } = queryObj;

  // area gradient color cannnot be updated without another render (see https://github.com/alibaba/BizCharts/issues/999)
  const [, forceRender] = useState();

  // Init chart instance
  const chart = useRef();

  useEffect(() => {
    if (chart.current) {
      forceRender(new Date());
    }
  }, [color.line, gradient]);

  // Re-init chart when width changes and pass chartRef to download modal
  useChartRef(chart, width, setChartDownloadInstance);

  // Get chart config
  const xAxisLabelConfig = useMemo(() => getXAxisLabelConfig(config), [config]);
  const hasTimeDimension = !!dimensions.find(
    (dimension) => dimension.functionValue
  );
  const showEmptyDate = config.showEmptyDate && hasTimeDimension;

  // Remove empty x
  const formattedData = useMemo(() => formatChartData(data, [x]), [data, x]);

  // Config chart scale
  const scale = useMemo(() => {
    return {
      [x]: getXAxisScale({
        timeInterval: deepGet(schema, [x, 'functionValue']),
        showEmptyDate,
        data,
        x,
        queryObj,
        schema,
        labelConfig,
      }),
      [y]: getYAxisScale({
        log,
        axisConfig,
        data,
        y,
      }),
    };
  }, [
    x,
    schema,
    showEmptyDate,
    data,
    queryObj,
    labelConfig,
    y,
    log,
    axisConfig,
  ]);

  // Get area gradient color
  const areaColor = useMemo(() => {
    const chromaColor = chroma(color.line);
    return gradient
      ? `l(90) 0:${chromaColor.css()} 1:${chromaColor.alpha(0.1).css()}`
      : color.line;
  }, [gradient, color.line]);

  // Collect point click data for drilldown
  const onPointClick = (ev) => {
    const targetData = ev.data ? ev.data._origin : {};
    if (!drillDownDisabled) {
      dispatch({
        type: 'SET_DRILLDOWN',
        drilldown: {
          x: ev.x,
          y: ev.y,
          chartElement: getChartElement(ev, chart.current),
          targetData,
          queryObj,
        },
      });
    } else if (commentsEditing) {
      dispatch({
        type: 'SET_DASHBOARD_CHART_COMMENT_POINT',
        point: {
          dimension: targetData[x],
          metric: targetData[y],
        },
      });
    }
  };

  return (
    <Chart
      height={height}
      data={formattedData}
      scale={scale}
      padding="auto"
      renderer={renderer}
      forceFit
      onPointClick={(ev) => onPointClick(ev)}
      onGetG2Instance={(g2Chart) => {
        chart.current = g2Chart;
      }}
    >
      <CoordConfig option={{ reverse }} />
      <Axis name={x} label={xAxisLabelConfig} {...getAxisConfig(config, 'x')} />
      <Axis
        name={y}
        position={reverse ? 'right' : 'left'}
        {...getAxisConfig(config, 'y')}
      />
      <Tooltip crosshairs={{ type: y }} />
      <Geom type="line" color={color.line} position={`${x}*${y}`} size={2} />
      <Geom
        type="area"
        color={areaColor}
        position={`${x}*${y}`}
        tooltip={false}
      />
      <Geom
        type="point"
        position={`${x}*${y}`}
        size={6}
        shape="circle"
        opacity={0}
        style={{
          cursor: drillDownDisabled && !commentsEditing ? 'auto' : 'pointer',
        }}
      />

      {/* Dashboard chart comments */}
      <ChartComments
        visible={commentsShowing}
        comments={comments}
        data={formattedData}
        x={x}
        y={y}
      />
    </Chart>
  );
};

export default connect()(AreaChart);
