/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';

import { Form, Collapse, Select } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import {
  getFilterInputGroupConfig,
  getFilterDisplay,
  ENTITIES_SCHEMA_FIELDS,
} from '@modules/filterBarHelpers';
import formatLabel from '@modules/formatLabel';
import FilterValueField from '@components/FilterBar/components/FilterValueField';
import DateRangePicker from '@components/DateRangePicker';

import StyledCollapse from '../../StyledCollapse';

const { Panel } = Collapse;
const { Option, OptGroup } = Select;

const Filters = ({
  value: filters = [],
  onChange,
  onRemove,
  timeFilter,
  onTimeFilterChange,
  // for filter config
  sharedSchemaFields,
  attributeOptions = {},
  filterOptions = {},
  formatRequiredAttributes = [],
  datasetIds = [],
}) => {
  // Get value select options for fields like 'channel', 'source', 'language'
  const getValueOptions = (filterAttrbute) => {
    return ENTITIES_SCHEMA_FIELDS.find(
      (entitySchemaField) => entitySchemaField.name === filterAttrbute
    )
      ? filterOptions.entity[filterAttrbute]
      : filterOptions.document[filterAttrbute];
  };

  // format filterOptions like 'channel', 'source', 'language'
  const valueFormat = (val, filterAttrbute) => {
    if (!filterAttrbute || formatRequiredAttributes.length === 0) {
      return null;
    }

    const formatAttributeObj = formatRequiredAttributes.find(
      (obj) => obj.name === filterAttrbute
    );
    return formatAttributeObj ? formatAttributeObj.format(val) : null;
  };

  // Get formItemType, conditionOptions, searchKey etc for each filter.
  const getFilterFormItemConfig = (filterAttrbute) => {
    return getFilterInputGroupConfig(
      sharedSchemaFields.find((field) => field.name === filterAttrbute)
    );
  };

  // Exclude time from filter attribute options
  const filterAttributeOptions = useMemo(() => {
    const { time: timeFields, ...otherOptions } = attributeOptions;
    const nonPrimaryTimeFields = (timeFields || []).filter(
      (timeField) => timeField.name !== 'otso_doc_publish_date'
    );
    return {
      ...(otherOptions || {}),
      dimensions: otherOptions
        ? [...(otherOptions.dimensions || []), ...nonPrimaryTimeFields]
        : [],
    };
  }, [attributeOptions]);

  return (
    <div
      css={{
        '.ant-form-item': {
          '.ant-calendar-picker, .ant-input-number': {
            width: '100%',
            minWidth: '0px !important',
          },
        },
      }}
    >
      {/* Time Filter */}
      <Form.Item label="Date Filter">
        <DateRangePicker
          placeholder="Select Date Filter"
          value={timeFilter}
          onChange={onTimeFilterChange}
        />
      </Form.Item>

      {/* Other Filters */}
      <StyledCollapse>
        {filters.map((filter, index) => (
          <Panel
            key={`filter_${index + 1}`}
            header={
              <span
                css={{
                  flex: 1,
                  marginRight: 12,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {getFilterDisplay(filter, sharedSchemaFields) ||
                  `Filter ${index + 1}`}
              </span>
            }
            extra={[
              <DeleteOutlined
                key="delete"
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove(index);
                }}
              />,
            ]}
            css={{
              '.ant-collapse-header': {
                display: 'flex',
                alignItems: 'center',
              },
            }}
          >
            <Form.Item label="Attribute" required>
              <Select
                showSearch
                dropdownMatchSelectWidth={false}
                placeholder="Attribute"
                optionFilterProp="children"
                value={filter.attribute}
                onChange={(val) => onChange(val, 'attribute', index)}
              >
                {Object.keys(filterAttributeOptions).map((optionGroupKey) => (
                  <OptGroup
                    key={optionGroupKey}
                    label={formatLabel(optionGroupKey)}
                  >
                    {filterAttributeOptions[optionGroupKey].map((field) => (
                      <Option key={field.name} value={field.name}>
                        {field.displayName || formatLabel(field.name)}
                      </Option>
                    ))}
                  </OptGroup>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Condition" required>
              <Select
                showSearch
                placeholder="Condition"
                optionFilterProp="children"
                value={filter.condition}
                onChange={(val) => {
                  onChange(val === 'between' ? {} : undefined, 'value', index);
                  onChange(val, 'condition', index);
                }}
              >
                {getFilterFormItemConfig(filter.attribute).conditionOptions.map(
                  (conditionOption) => (
                    <Option
                      key={conditionOption.value}
                      value={conditionOption.value}
                    >
                      {conditionOption.label}
                    </Option>
                  )
                )}
              </Select>
            </Form.Item>

            <Form.Item label="Value" required>
              <FilterValueField
                attribute={filter.attribute}
                condition={filter.condition}
                value={filter.value}
                onChange={(val) => onChange(val, 'value', index)}
                config={{
                  formItemType: getFilterFormItemConfig(filter.attribute)
                    .formItemType,
                  valueOptions: getValueOptions(filter.attribute),
                  datasetIds,
                  searchKey: getFilterFormItemConfig(filter.attribute)
                    .searchKey,
                  valueFormat: (val) => valueFormat(val, filter.attribute),
                  attributeDisplay: getFilterFormItemConfig(filter.attribute)
                    .displayName,
                }}
              />
            </Form.Item>
          </Panel>
        ))}
      </StyledCollapse>
    </div>
  );
};

export default Filters;
