import isEqual from 'lodash/isEqual';

import deepGet from '../../modules/deepGet';

const initialState = {
    name: '',
    // dashboard charts
    dashboardCharts: {
        byId: {},
        byPageId: {},
    },
    // for layout reset
    cachedDashboardCharts: {
        byId: {},
        byPageId: {},
    },
    // charts layout
    isLayoutLocked: true,
    // dashboard pages
    pages: { allIds: [], byId: {} },
    currentPageId: undefined,
    loadedPages: [],
    // dashboard filters
    activeFilters: {
        filters: [],
        timeFilter: null,
    },
    isDashboardFilterShowing: false,
    savedFilters: [{
        name: 'Custom',
        slug: 'custom',
        filterConfig: {
            filters: [],
            timeFilter: null,
        },
        dashboardDefault: false,
    }],
    selectedSavedFilterSlug: 'custom',
    exportedFilters: null,
    // dashboard comments
    commentsEditingChart: {},
    commentsShowingChartId: null,
    newChartCommentPoint: null,
    // dashboard PDF
    pdfPageTitle: '',
    pdfSummary: null,
    // rich text widget
    selectedWidgetChartId: null,
    // dashboard modals
    isChartSelectModalOpen: false,
    isScheduledEmailsModalOpen: false,
    isNameEditModalOpen: false,
    isPDFModalOpen: false,
    isRichTextModalOpen: false,
    isDuplicateDashboardModalOpen: false,
};

export default function dashboard(state = initialState, action) {
    switch (action.type) {
        case 'INIT_DASHBOARD': {
            const {
                dashboardVisualisations = [],
                pages = [],
                name,
                filters = [],
            } = action.dashboard;

            const defaultFilter = filters.find(filter => filter.dashboardDefault);

            return {
                ...state,
                name,
                // init dashboardCharts state
                dashboardCharts: dashboardVisualisations.reduce((prev, vis) => ({
                    byId: {
                        ...prev.byId,
                        [vis.id]: vis,
                    },
                    byPageId: {
                        ...prev.byPageId,
                        [vis.page.id]: prev.byPageId[vis.page.id] ? [
                            ...prev.byPageId[vis.page.id],
                            vis.id,
                        ] : [vis.id],
                    },
                }), initialState.dashboardCharts),
                // init pages state
                pages: pages.reduce((prev, page) => ({
                    allIds: [...prev.allIds, page.id],
                    byId: {
                        ...prev.byId,
                        [page.id]: page,
                    },
                }), initialState.pages),
                // init dashboard saved filters
                savedFilters: [
                    ...initialState.savedFilters,
                    ...filters,
                ],
                selectedSavedFilterSlug: defaultFilter ? defaultFilter.slug : initialState.selectedSavedFilterSlug,
            };
        }

        // dashboard name
        case 'SET_DASHBOARD_NAME':
            return {
                ...state,
                name: action.name,
                isNameEditModalOpen: false,
            };

        // charts
        case 'ADD_DASHBOARD_CHART': {
            const { chart } = action;
            const { id: chartId } = chart;
            const { dashboardCharts, currentPageId } = state;
            const currentPageVisIds = currentPageId ? (dashboardCharts.byPageId[currentPageId] || []) : [];

            return {
                ...state,
                dashboardCharts: {
                    byId: {
                        ...dashboardCharts.byId,
                        [chartId]: chart,
                    },
                    byPageId: {
                        ...dashboardCharts.byPageId,
                        [currentPageId]: [...currentPageVisIds, chartId],
                    },
                },
                isChartSelectModalOpen: false,
            };
        }

        case 'REMOVE_DASHBOARD_CHART': {
            const { [action.visId]: foo, ...restById } = state.dashboardCharts.byId;

            return {
                ...state,
                dashboardCharts: {
                    byId: restById,
                    byPageId: {
                        ...state.dashboardCharts.byPageId,
                        [state.currentPageId]: state.dashboardCharts.byPageId[state.currentPageId].filter(
                            dashboardVisId => dashboardVisId !== action.visId,
                        ),
                    },
                },
            };
        }

        case 'SET_DASHBOARD_CHART':
            return {
                ...state,
                dashboardCharts: {
                    ...state.dashboardCharts,
                    byId: {
                        ...state.dashboardCharts.byId,
                        [action.chart.id]: {
                            ...state.dashboardCharts.byId[action.chart.id],
                            ...action.chart,
                        },
                    },
                },
            };

        case 'SET_DASHBOARD_CHART_COMMENTS':
            return {
                ...state,
                dashboardCharts: {
                    ...state.dashboardCharts,
                    byId: {
                        ...state.dashboardCharts.byId,
                        [action.id]: {
                            ...state.dashboardCharts.byId[action.id],
                            comments: action.comments,
                        },
                    },
                },
            };

        // layout
        case 'SET_IS_DASHBOARD_LAYOUT_LOCK':
            return {
                ...state,
                isLayoutLocked: action.locked,
                ...action.locked ? (action.reset ? {
                    dashboardCharts: state.cachedDashboardCharts,
                    loadedPages: state.loadedPages.map(loadedPage => {
                        if (loadedPage.id === state.currentPageId) {
                            const { chartsRefreshAt, ...restPageObj } = loadedPage;
                            return {
                                ...restPageObj,
                                layoutResetAt: Date.now(),
                            };
                        }
                        return loadedPage;
                    }),
                } : {
                    cachedDashboardCharts: initialState.cachedDashboardCharts,
                }) : {
                    cachedDashboardCharts: state.dashboardCharts,
                    isDashboardFilterShowing: false,
                },
            };

        case 'SET_DASHBOARD_CHARTS_LAYOUT': {
            const updatedLayouts = itemLayouts => {
                const updatedCharts = {};
                itemLayouts.forEach(itemLayout => {
                    updatedCharts[itemLayout.i] = {
                        ...state.dashboardCharts.byId[itemLayout.i],
                        layout: {
                            ...state.dashboardCharts.byId[itemLayout.i].layout,
                            ...itemLayout,
                        },
                    };
                });
                return updatedCharts;
            };

            return {
                ...state,
                dashboardCharts: {
                    ...state.dashboardCharts,
                    byId: {
                        ...state.dashboardCharts.byId,
                        ...updatedLayouts(action.layouts),
                    },
                },
            };
        }

        // pages
        case 'SET_DASHBOARD_CURRENT_PAGE_ID': {
            const {
                loadedPages,
                pages: { allIds: allPageIds = [] },
                activeFilters,
            } = state;
            const loadedPageIds = loadedPages.map(page => page.id);

            const newLoadedPages = allPageIds.reduce((prev, pageId) => {
                const currentPage = loadedPages.find(page => page.id === pageId) || {};
                if (pageId === action.pageId) {
                    const { chartsRefreshAt, ...resetPageObj } = currentPage;
                    const hasFiltersChanged = !isEqual(activeFilters, currentPage.activeFilters);
                    return [
                        ...prev,
                        {
                            ...(hasFiltersChanged ? resetPageObj : currentPage),
                            id: pageId,
                            activeFilters,
                        },
                    ];
                }
                if (loadedPageIds.includes(pageId)) {
                    return [
                        ...prev,
                        currentPage,
                    ];
                }
                return prev;
            }, []);

            return {
                ...state,
                currentPageId: action.pageId,
                loadedPages: newLoadedPages,
            };
        }

        case 'ADD_DASHBOARD_PAGE':
            return {
                ...state,
                pages: {
                    allIds: [...state.pages.allIds, action.pageId],
                    byId: {
                        ...state.pages.byId,
                        [action.pageId]: {
                            id: action.pageId,
                            title: action.title,
                        },
                    },
                },
            };

        case 'DELETE_DASHBOARD_PAGE': {
            const { [action.pageId]: foo, ...restById } = state.pages.byId;
            const { [action.pageId]: removedVisIds = [], ...restByPageId } = state.dashboardCharts.byPageId;

            return {
                ...state,
                dashboardCharts: {
                    byId: Object.keys(state.dashboardCharts.byId).reduce((prev, visId) => (
                        removedVisIds.map(removedVisId => removedVisId.toString()).includes(visId)
                            ? prev
                            : { ...prev, [visId]: state.dashboardCharts.byId[visId] }
                    ), {}),
                    byPageId: restByPageId,
                },
                pages: {
                    allIds: state.pages.allIds.filter(pageId => pageId !== action.pageId),
                    byId: restById,
                },
                currentPageId: initialState.currentPageId,
                loadedPages: state.loadedPages.filter(loadedPage => loadedPage.id !== action.pageId),
            };
        }

        case 'SET_DASHBOARD_PAGE_TITLE':
            return {
                ...state,
                pages: {
                    ...state.pages,
                    byId: {
                        ...state.pages.byId,
                        [action.pageId]: {
                            ...state.pages.byId[action.pageId],
                            title: action.title,
                        },
                    },
                },
            };

        // filters
        case 'SET_DASHBOARD_ACTIVE_FILTERS':
            return {
                ...state,
                activeFilters: {
                    filters: action.filters,
                    timeFilter: action.timeFilter,
                },
                loadedPages: state.loadedPages.map(loadedPage => {
                    if (loadedPage.id === state.currentPageId) {
                        const { chartsRefreshAt, ...restPageObj } = loadedPage;
                        return {
                            ...restPageObj,
                            activeFilters: {
                                filters: action.filters,
                                timeFilter: action.timeFilter,
                            },
                        };
                    }
                    return loadedPage;
                }),
                ...action.slug ? { selectedSavedFilterSlug: action.slug } : {},
            };

        case 'TOGGLE_DASHBOARD_FILTER_BAR_SHOWING':
            return {
                ...state,
                isDashboardFilterShowing: !state.isDashboardFilterShowing,
            };

        case 'SET_SELECTED_SAVED_FILTER_SLUG':
            return {
                ...state,
                selectedSavedFilterSlug: action.slug,
            };

        case 'ADD_DASHBOARD_SAVED_FILTER':
            return {
                ...state,
                savedFilters: [
                    ...state.savedFilters,
                    action.newFilter,
                ],
                selectedSavedFilterSlug: action.newFilter.slug,
            };

        case 'SET_DASHBOARD_SAVED_FILTER':
            return {
                ...state,
                savedFilters: state.savedFilters.map(filter => (
                    filter.slug === action.filter.slug ? action.filter : filter
                )),
            };

        case 'SET_DASHBOARD_DEFAULT_FILTER':
            return {
                ...state,
                savedFilters: state.savedFilters.map(filter => (
                    filter.slug === action.slug ? {
                        ...filter,
                        dashboardDefault: true,
                    } : {
                        ...filter,
                        dashboardDefault: false,
                    }
                )),
            };

        case 'DELETE_DASHBOARD_SAVED_FILTER':
            return {
                ...state,
                savedFilters: state.savedFilters.filter(filter => filter.slug !== action.slug),
                selectedSavedFilterSlug: initialState.selectedSavedFilterSlug,
            };

        case 'LOAD_DASHBOARD_CUSTOM_FILTER':
            return {
                ...state,
                savedFilters: state.savedFilters.map(filter => (
                    filter.slug === initialState.selectedSavedFilterSlug ? {
                        ...filter,
                        filterConfig: {
                            filters: action.filters,
                            timeFilter: action.timeFilter || null,
                        },
                    } : filter
                )),
                selectedSavedFilterSlug: initialState.selectedSavedFilterSlug,
                activeFilters: {
                    filters: action.filters,
                    timeFilter: action.timeFilter || null,
                },
                loadedPages: state.loadedPages.map(loadedPage => {
                    if (loadedPage.id === state.currentPageId) {
                        const { chartsRefreshAt, ...restPageObj } = loadedPage;
                        return {
                            ...restPageObj,
                            activeFilters: {
                                filters: action.filters,
                                timeFilter: action.timeFilter || null,
                            },
                        };
                    }
                    return loadedPage;
                }),
            };

        case 'SET_DASHBOARD_EXPORTED_FILTERS':
            return {
                ...state,
                exportedFilters: action.filters,
            };

        // chart comments
        case 'TOGGLE_DASHBOARD_CHART_COMMENTS':
            return {
                ...state,
                commentsShowingChartId: state.commentsShowingChartId ? null : action.dashboardChartId,
            };

        case 'SET_DASHBOARD_COMMENTS_EDITING_CHART':
            return {
                ...state,
                commentsEditingChart: action.chart,
                commentsShowingChartId: null,
            };

        case 'RESET_DASHBOARD_COMMENTS_EDITING_CHART':
            return {
                ...state,
                commentsEditingChart: initialState.commentsEditingChart,
                newChartCommentPoint: initialState.newChartCommentPoint,
            };

        case 'UPDATE_DASHBOARD_EDITING_COMMENTS':
            return {
                ...state,
                commentsEditingChart: {
                    ...state.commentsEditingChart,
                    comments: action.comments,
                },
            };

        case 'SET_DASHBOARD_CHART_COMMENT_POINT':
            return {
                ...state,
                newChartCommentPoint: action.point,
            };

        // pdf download
        case 'ON_DASHBOARD_DOWNLOAD_PDF_PREVIEW': {
            const { name, currentPageId, pages } = state;
            const currentPageTitle = deepGet(pages, ['byId', currentPageId, 'title']) || '';
            return {
                ...state,
                isPDFModalOpen: true,
                pdfPageTitle: currentPageTitle ? `${name} - ${currentPageTitle}` : name,
            };
        }

        case 'ON_DASHBOARD_DOWNLOAD_PDF_CANCEL':
            return {
                ...state,
                isPDFModalOpen: false,
                exportedFilters: initialState.exportedFilters,
                pdfSummary: initialState.pdfSummary,
            };

        case 'ON_DASHBOARD_DOWNLOAD_PDF_FORM_CHANGE':
            return {
                ...state,
                [action.key]: action.value,
            };

        // rich text widget
        case 'SET_SELECTED_RICH_TEXT_WIDGET':
            return {
                ...state,
                selectedWidgetChartId: action.chartId,
                isRichTextModalOpen: true,
            };

        case 'RESET_SELECTED_RICH_TEXT_WIDGET':
            return {
                ...state,
                selectedWidgetChartId: initialState.selectedWidgetChartId,
                isRichTextModalOpen: false,
            };

        // dashboard modal
        case 'SET_DASHBOARD_MODAL_OPEN':
            return {
                ...state,
                [action.key]: action.open,
            };

        // force charts refresh
        case 'REFRESH_DASHBOARD_CHARTS':
            return {
                ...state,
                loadedPages: state.loadedPages.map(loadedPage => (
                    loadedPage.id === state.currentPageId ? {
                        ...loadedPage,
                        chartsRefreshAt: Date.now(),
                    } : loadedPage
                )),
            };

        // Reset
        case 'RESET_DASHBOARD':
            return initialState;

        default:
            return state;
    }
}
