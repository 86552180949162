import { useState, useEffect, createContext } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import queryString from 'query-string';

// Import modules
import { useIsSuperAdminRoute, getApi } from 'modules';

// Create a dataset context
export const DatasetsContext = createContext();

export const DatasetsProvider = (props) => {
  const location = useLocation();

  const [datasets, setDatasets] = useState([]);
  const [loadingDatasets, setLoadingDatasets] = useState(true);
  const [datasetsUsage, setDatasetsUsage] = useState({});
  const [usageLoading, setUsageLoading] = useState(true);
  const [month, setMonth] = useState(
    queryString.parse(location.search).month ||
      moment().startOf('month').format('YYYY-MM-DD')
  );

  const isGlobal = useIsSuperAdminRoute();

  useEffect(() => {
    // Get organisation and global datasets
    const getDatasets = async () => {
      setLoadingDatasets(true);

      // Fetch organisation and global datasets,
      // if org value is undefined, fetch global datasets only
      const { data } = await getApi(
        isGlobal ? 'superadmin/datasets' : 'datasets'
      );
      setDatasets(data);

      setLoadingDatasets(false);
    };

    getDatasets();

    return () => setDatasets([]);
  }, [isGlobal]);

  return (
    <DatasetsContext.Provider
      value={{
        datasets,
        loadingDatasets,
        datasetsUsage,
        setDatasetsUsage,
        usageLoading,
        setUsageLoading,
        setMonth,
        month,
      }}
    >
      {props.children}
    </DatasetsContext.Provider>
  );
};
