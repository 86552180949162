import { isHeadlessChrome } from './isHeadlessChrome';

export const initZendeskScript = () => {
  if (
    process.env.NODE_ENV === 'production' &&
    !isHeadlessChrome() &&
    process.env.REACT_APP_SHOW_LIVE_CHAT === 'true' &&
    !document.getElementById('ze-snippet')
  ) {
    window.zESettings = { analytics: true };

    const script = document.createElement('script');

    script.src =
      'https://static.zdassets.com/ekr/snippet.js?key=27967009-6e7c-4a01-bef2-7b1280d7598a';
    script.id = 'ze-snippet';
    script.async = true;
    script.onload = () => {
      if (window.zE) {
        window.zE('webWidget', 'hide');
        window.zE('webWidget:on', 'close', () => {
          window.zE('webWidget', 'hide');
        });
      }
    };

    document.body.appendChild(script);
  }
};

export const showContactForm = () => {
  if (process.env.NODE_ENV === 'production' && window.zE) {
    window.zE('webWidget', 'show');
    window.zE('webWidget', 'open');
  }
};
