// See https://www.npmjs.com/package/search-query-parser
import { parse } from 'search-query-parser';

const isValuePresent = (values = [], rawColumnValue) => {
    const columnValue = rawColumnValue ? rawColumnValue.toString() : '';

    // validators
    const numericCheck = (inputVal, columnVal) => inputVal === columnVal;
    const stringSearch = (inputVal, columnVal) => columnVal.match(new RegExp((inputVal.toString()).replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi')) || false;

    // map validators to variable type
    const getValidatorForType = (val, colVal) => {
        switch (true) {
            // eslint-disable-next-line no-restricted-globals
            case typeof val === 'number' || !isNaN(val): // requires use of NaN to detect invalid strings
                return numericCheck(val, colVal);
            case typeof val === 'string':
                return stringSearch(val, colVal);
            default:
                return false;
        }
    };

    return Array.isArray(values)
        ? values.find(value => getValidatorForType(value, columnValue)) || false
        : getValidatorForType(values, columnValue);
};

/**
 * Filters array of object with github-like search string
 * @param {Array} dataSource array of object to be filtered by
 * @param {String} queryString search string to process and filter datasource by
 * @param {Array} properties object containing the relation between queryable value and its corresponding property within dataSource elements
 */
const advancedSearch = (dataSource = [], queryString = '', properties = {}) => {
    if (!queryString || queryString.trim() === '') return dataSource;
    const propertyKeywords = Object.keys(properties).reduce((obj, key) => {
        return { ...obj, [key.toLowerCase()]: properties[key] };
    }, {});

    /**
     * specify columns as keywords to search for
     */
    const options = { keywords: Object.keys(propertyKeywords), ranges: [] };
    const searchValue = parse(queryString.toLowerCase(), options);
    // search single column by default if no properties have been filtered
    if (typeof searchValue === 'string') {
        const escapedQuery = queryString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const reg = new RegExp(escapedQuery, 'gi');
        return Object.keys(propertyKeywords).length // if no properties are searchable, return no rows
            ? dataSource.filter(data => (`${data[propertyKeywords[Object.keys(propertyKeywords)[0]]]}`.match(reg)))
            : [];
    }

    /**
     * filter by specific properties
     */
    // get specified columns
    const specifiedColumns = Object.keys(propertyKeywords).reduce((obj, key) => {
        // if lowercase column was specified in search query
        if (searchValue[key] !== undefined) {
            return { ...obj, [key]: propertyKeywords[key] };
        }
        return obj;
    }, {});

    // search each result for specified column values
    return dataSource.filter(data => {
        let shouldReturnEntry = true;
        // for each specified column in search string
        Object.keys(specifiedColumns).forEach(lowerCaseKey => {
            if (data[specifiedColumns[lowerCaseKey]] === null
                || data[specifiedColumns[lowerCaseKey]] === undefined
                || data[specifiedColumns[lowerCaseKey]] === '') {
                shouldReturnEntry = false;
            }

            if (shouldReturnEntry && specifiedColumns[lowerCaseKey]) {
                shouldReturnEntry = isValuePresent(searchValue[lowerCaseKey], data[specifiedColumns[lowerCaseKey]]);
            }
        });

        return shouldReturnEntry;
    });
};

export default advancedSearch;
