/** @jsxImportSource @emotion/react */
import React, { Fragment, useEffect, useState } from 'react';
import { Space, Divider, Typography, Card, Button } from 'antd';
import {
  SoundOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment-timezone';

import { colors, preset } from 'styles';
import { Dataset } from 'types';
import CustomClassifierTags from './CustomClassifierTags';

type Props = {
  childDoc: Record<string, any>;
  dataset: Dataset;
  hasEnrichmentCorrection: boolean;
  hideCustomClassifier?: boolean;
  getAudioPlayer: () => HTMLAudioElement | null;
  status: 'playing' | 'paused' | 'idle';
};

const AudioChildDoc: React.FC<Props> = ({
  childDoc,
  dataset,
  hasEnrichmentCorrection,
  hideCustomClassifier,
  getAudioPlayer,
  status,
}) => {
  const [currentAudioTime, setCurrentAudioTime] = useState<number>(0);

  useEffect(() => {
    const audioPlayer = getAudioPlayer();
    let fetchCurrentTimeInterval: NodeJS.Timeout | null = null;

    if (audioPlayer && status === 'playing') {
      fetchCurrentTimeInterval = setInterval(() => {
        setCurrentAudioTime(audioPlayer.currentTime);
      }, 100);
    } else {
      if (fetchCurrentTimeInterval) clearInterval(fetchCurrentTimeInterval);
      if (status === 'idle') {
        setCurrentAudioTime(0);
      }
    }

    return () => {
      if (fetchCurrentTimeInterval) clearInterval(fetchCurrentTimeInterval);
    };
  }, [getAudioPlayer, status]);

  const onAudioPlay = (startTime?: number) => {
    const audioPlayer = getAudioPlayer();
    if (audioPlayer) {
      if (startTime) {
        audioPlayer.currentTime = startTime;
      }
      audioPlayer.play();
    }
  };

  const onAudioPause = () => {
    const audioPlayer = getAudioPlayer();
    if (audioPlayer) {
      audioPlayer.pause();
    }
  };

  const docColor =
    Number(childDoc.speaker_tag) % 2 === 0 ? colors.secondary : colors.primary;

  return (
    <Card size="small">
      <div
        css={{
          position: 'absolute',
          zIndex: 1,
          top: 0,
          left: 0,
          bottom: 0,
          width: 3,
          backgroundColor: docColor,
          transition: preset.transition,
          opacity: status === 'idle' ? 0 : 1,
        }}
      />

      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingRight: preset.spacing(1),
        }}
      >
        <Space split={<Divider type="vertical" />}>
          <Button
            type="text"
            onClick={() =>
              status === 'playing'
                ? onAudioPause()
                : status === 'paused'
                ? onAudioPlay()
                : onAudioPlay(childDoc.start_time)
            }
            css={{
              paddingLeft: preset.spacing(1),
              paddingRight: preset.spacing(1),
              '.anticon, .ant-typography': {
                color: docColor,
              },
            }}
          >
            {status === 'playing' ? (
              <PauseCircleOutlined />
            ) : status === 'paused' ? (
              <PlayCircleOutlined />
            ) : (
              <SoundOutlined />
            )}
            <Typography.Text strong>
              {`Speaker ${childDoc.speaker_tag}`}
            </Typography.Text>
          </Button>
          <Typography.Text type="secondary">
            {moment()
              .startOf('day')
              .seconds(childDoc.start_time)
              .format('mm:ss')}
          </Typography.Text>
        </Space>

        {!hideCustomClassifier && (
          <CustomClassifierTags
            document={childDoc}
            dataset={dataset}
            hasEnrichmentCorrection={hasEnrichmentCorrection}
          />
        )}
      </div>

      {childDoc.words.length > 0 && (
        <div css={{ padding: `4px ${preset.spacing(1)}px` }}>
          {childDoc.words.map((audioWord: any, index: number) => (
            <Fragment key={`${childDoc.id}_${index + 1}`}>
              <Typography.Text
                mark={
                  !!(
                    currentAudioTime &&
                    currentAudioTime > audioWord.startTime &&
                    currentAudioTime < audioWord.endTime
                  )
                }
                onClick={() => onAudioPlay(audioWord.startTime)}
                css={{
                  cursor: 'pointer',
                  transition: preset.transition,
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                {audioWord.word}
              </Typography.Text>
              <Typography.Text> </Typography.Text>
            </Fragment>
          ))}
        </div>
      )}
    </Card>
  );
};

export default AudioChildDoc;
