import { User } from '@otso/auth-wrapper';

const getUserInitials = (user: User | null): string => {
  const displayName = user?.displayName || '';
  if ((user?.firstName && user?.lastName) || displayName) {
    const firstName = user?.firstName || displayName.split(' ')[0];
    const lastName = user?.lastName || displayName.split(' ')[1];
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  }
  return '';
};

export default getUserInitials;
