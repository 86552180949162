const storageKeys = {
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  tourStatus: 'tourStatus',
};

const setItem = (key: string, stringfiedData: string) => {
  return localStorage.setItem(`ccx_${key}`, stringfiedData);
};

const getItem = (key: string) => {
  return localStorage.getItem(`ccx_${key}`);
};

const deleteItem = (key: string) => {
  localStorage.removeItem(`ccx_${key}`);
};

const clearAll = () => {
  deleteItem(storageKeys.accessToken);
  deleteItem(storageKeys.refreshToken);
};

export default { setItem, getItem, deleteItem, storageKeys, clearAll };
