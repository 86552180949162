import { PureComponent } from 'react';
import { Progress } from 'antd';
import numeral from 'numeral';

class UsageBar extends PureComponent {
  render() {
    const { current, cap, color, type, loading } = this.props;

    let percent = current && cap ? (current / cap) * 100 : 0;

    const percentFormatted = numeral(percent).format('0,0.0');
    const capFormatted = numeral(cap).format('0,0');
    const currentFormatted = numeral(current).format('0,0');

    let status = percent < 80 ? 'success' : 'exception';
    let strokeColor = !color ? '#949494' : null;
    let format = () => `${percentFormatted}% (${currentFormatted})`;

    const showInfo = type !== 'absolute' || false;

    if (loading) {
      status = 'active';
      percent = 100;
      strokeColor = '#d5d2d2';
      format = () => '0% (0)';
    }

    return (
      <div>
        <div style={{ float: 'left' }}>
          {type === 'absolute' ? format() : null}
        </div>
        <div style={{ float: 'right' }}>
          {type === 'absolute' ? capFormatted : null}
        </div>
        <Progress
          percent={percent}
          status={color ? status : null}
          format={format}
          strokeColor={strokeColor}
          showInfo={showInfo}
        />
      </div>
    );
  }
}

export default UsageBar;
