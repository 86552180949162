/** @jsxImportSource @emotion/react */
import { Typography } from 'antd';

import { preset } from 'styles';
import ChartTooltip from './ChartTooltip';

const ChartHeader = ({ chartType, chart = {}, onTitleChange }) => {
  const { defaultTitle: title, description } = chart;

  const isRichTextWidget = chartType === 'rich_text';

  return (
    <div
      css={{
        height: preset.spacing(5),
        display: 'flex',
        alignItems: 'flex-start',
        ...(isRichTextWidget
          ? {
              position: 'absolute',
              top: preset.spacing(2),
              right: preset.spacing(3),
            }
          : {}),
      }}
    >
      <div
        css={{
          flex: onTitleChange ? 0.8 : 1,
          minWidth: 0,
          display: 'flex',
          visibility: isRichTextWidget ? 'hidden' : 'visible',
        }}
      >
        <ChartTooltip disabled chart={chart}>
          <div
            css={{
              maxWidth: '100%',
              display: 'inline-flex',
              flexDirection: 'column',
            }}
          >
            <Typography.Text
              strong
              ellipsis
              editable={onTitleChange ? { onChange: onTitleChange } : false}
              css={{ lineHeight: description ? `${preset.spacing(2)}px` : 1.5 }}
            >
              {title}
            </Typography.Text>
            <Typography.Text
              type="secondary"
              ellipsis
              editable={false}
              css={{ fontSize: 13 }}
            >
              {description}
            </Typography.Text>
          </div>
        </ChartTooltip>
      </div>
    </div>
  );
};

export default ChartHeader;
