import { Shape } from 'bizcharts';

const getDualBarSize = chart => {
    const { geoms } = chart._attrs;
    if (geoms && geoms.length > 0) {
        const barSize = geoms[0].getSize() / 2;
        const barOffset = barSize / 2;
        const customShapes = [
            { shape: 'right', offsetX: +barOffset },
            { shape: 'left', offsetX: -barOffset },
            { shape: 'bottom', offsetY: +barOffset },
            { shape: 'top', offsetY: -barOffset },
        ];
        customShapes.forEach(item => {
            Shape.registerShape('interval', item.shape, {
                drawShape(cfg, group) {
                    const points = this.parsePoints(cfg.points);
                    const newPoints = points.map(obj => ({
                        ...obj,
                        y: obj.y + (item.offsetY ? item.offsetY : 0),
                        x: obj.x + (item.offsetX ? item.offsetX : 0),
                    }));
                    return group.addShape('polygon', {
                        attrs: {
                            points: [
                                [newPoints[0].x, newPoints[0].y],
                                [newPoints[1].x, newPoints[1].y],
                                [newPoints[2].x, newPoints[2].y],
                                [newPoints[3].x, newPoints[3].y],
                            ],
                            fill: cfg.color,
                        },
                    });
                },
            });
        });
        return barSize;
    }

    return 0;
};

export default getDualBarSize;
