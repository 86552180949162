/** @jsxImportSource @emotion/react */
import { Card, CardProps } from 'antd';

type Props = CardProps & { className?: string };

const ExploreCard: React.FC<Props> = ({ className, ...cardProps }) => {
  return (
    <Card
      size="small"
      {...cardProps}
      className={className}
      css={{ wordBreak: 'break-word' }}
    />
  );
};

export default ExploreCard;
