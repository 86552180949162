/** @jsxImportSource @emotion/react */

// Import libraries
import shortid from 'shortid';
import DOMPurify from 'dompurify';
import { orderBy, pick } from 'lodash';
import { useState, useContext, useMemo } from 'react';

// Import Ant Design components
import {
  Tabs,
  Typography,
  Card,
  Space,
  Button,
  Select,
  Row,
  Col,
  Form,
  Alert,
  Spin,
  message,
} from 'antd';
import {
  StopOutlined,
  ExperimentOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

// Import stylesheets
import { preset } from 'styles';

// Import modules
import { postApi } from 'modules';
import {
  getSharedSchemaFields,
  getCategorisedSchemaFields,
} from '../../modules/filterBarHelpers';
import getChartDefaultConfig from '../../modules/chart/getChartDefaultConfig';

// Import context
import { ProjectContext } from '../../providers/ProjectProvider';

// Import components
import ChartDisplay from '../../components/ChartDisplay';
import DriverAnalysisConfigs from './components/DriverAnalysisConfigs';
import Container from '../../components/Container';
import DateRangePicker from '../../components/DateRangePicker';
import FloatLabelContainer from '../../components/FloatLabelContainer';

// Import view stylesheet
import styles from './style';

// Import additional Ant Design components
const { TabPane } = Tabs;
const { Option } = Select;
const { Text } = Typography;

// Prepare a list of supported analysis types
const analysisTypes = [
  { tab: 'Entity Driver Analysis', key: 'entity-driver-analysis' },
  { tab: 'Driver Analysis', key: 'driver-analysis' },
  { tab: 'Other Analysis Tools', key: 'other' },
];

const InsightStudio = () => {
  // Initialisation
  const [loadingAnalysis, setLoadingAnalysis] = useState(false);
  const [analysisType, setAnalysisType] = useState('driver-analysis');
  const [selectedDatasetIds, setSelectedDatasetIds] = useState([]);
  const [timeFilter, setTimeFilter] = useState(null);
  const [isHelpAlertOpen, setIsHelpAlertOpen] = useState(false);
  const [analysisSummary, setAnalysisSummary] = useState({});
  const [analysisResults, setAnalysisResults] = useState([]);
  const [configsForm] = Form.useForm();

  const {
    project: {
      slug: projectSlug,
      datasets: projectDatasets = [],
      projectDatasetsById,
    },
    loading: isProjectDatasetsLoading,
  } = useContext(ProjectContext);

  // Prepare a list of selected datasets (matched by dataset ids)
  const selectedDatasets = useMemo(() => {
    if (Object.keys(projectDatasetsById).length > 0) {
      if (selectedDatasetIds.length === 0) return projectDatasets;
      return selectedDatasetIds.map(
        (datasetId) => projectDatasetsById[datasetId]
      );
    }
    return [];
  }, [projectDatasetsById, selectedDatasetIds, projectDatasets]);

  // Prepare a list of instances of shared fields among datasets
  const sharedSchemaFields = useMemo(
    () => getSharedSchemaFields(selectedDatasets),
    [selectedDatasets]
  );

  // Group and format shared dataset fields by metrics, dimensions,
  // enriched metrics and enriched dimensions
  const categorisedSchemaFields = useMemo(
    () =>
      getCategorisedSchemaFields(sharedSchemaFields, {
        includingTime: false,
      }),
    [sharedSchemaFields]
  );

  // Prepare a list of key metric field options
  const keyMetricFieldOptions = useMemo(() => {
    const metricFields = pick(categorisedSchemaFields, [
      'Enriched Metrics',
      'metrics',
    ]);
    return Object.entries(metricFields).reduce(
      (prev, current) => [
        ...prev,
        { groupName: current[0], options: current[1] },
      ],
      []
    );
  }, [categorisedSchemaFields]);

  // Prepare a list of key dimension field options
  const keyDimensionFieldOptions = useMemo(() => {
    const dimensionFields = pick(categorisedSchemaFields, [
      'Enriched Dimensions',
      'dimensions',
    ]);
    return Object.entries(dimensionFields).reduce(
      (prev, current) => [
        ...prev,
        {
          groupName: current[0],
          options:
            current[0] === 'dimensions'
              ? orderBy(current[1], ['order'], ['asc']).slice(0, 5)
              : current[1].filter((option) => option.isCategory),
        },
      ],
      []
    );
  }, [categorisedSchemaFields]);

  const onFormSubmit = async (values) => {
    try {
      setLoadingAnalysis(true);
      // Prepare a request payload
      const payload = {
        dataset: selectedDatasetIds,
        timeFilter,
      };

      // Add analysis type specific configurations
      if (analysisType === 'driver-analysis') {
        const { metric, dimension } = values;
        payload.configs = {
          metric: { name: metric.value, displayName: metric.label },
          dimension: { name: dimension.value, displayName: dimension.label },
        };
      }

      // Send a request to retrieve analsis results
      const { data: tasks } = await postApi(
        `projects/${projectSlug}/analysis/${analysisType}`,
        payload
      );

      // Format and save the chart data
      const aggsSummary = {};
      const formattedChartData = tasks.map((task) => {
        // Extract values from task
        const { name, summary = [], data: charts } = task || {};

        // Prepare analysis summary
        if (name && summary.length > 0) aggsSummary[name] = summary;

        // Prepare chart data
        return charts.map((chart) => {
          let { color: defaultColor, general: defaultConfig } =
            getChartDefaultConfig(chart.chartType, chart.data, chart.queryObj);
          defaultColor = { ...defaultColor, ...(chart.defaultColor || {}) };
          defaultConfig = {
            ...defaultConfig,
            ...(chart.defaultConfig || {}),
          };
          return {
            ...chart,
            defaultColor,
            defaultConfig,
            color: defaultColor,
            config: defaultConfig,
          };
        });
      });

      setAnalysisSummary(aggsSummary);
      setAnalysisResults(formattedChartData);
    } catch (error) {
      message.error('Unable to retrieve analysis, please try again later');
    } finally {
      setLoadingAnalysis(false);
    }
  };

  // Prepare a help message
  const helpMessage = (
    <Typography css={styles.helpMessage}>
      <Typography.Paragraph>Hello Quods!</Typography.Paragraph>
      <Typography.Paragraph>Thanks Quods!</Typography.Paragraph>
      <Typography.Paragraph>Goodbye Quods!</Typography.Paragraph>
    </Typography>
  );

  return (
    <Container paddingY={2}>
      <Tabs
        activeKey={analysisType}
        onChange={(activeKey) => {
          setAnalysisType(activeKey);
          setAnalysisSummary({});
          setAnalysisResults([]);
        }}
        type="card"
        tabBarStyle={{ marginBottom: 0 }}
      >
        {analysisTypes.map(({ tab, key }) => (
          <TabPane tab={tab} key={key} disabled={loadingAnalysis}>
            <Card bordered={false}>
              <Form form={configsForm} onFinish={onFormSubmit}>
                <Row gutter={[preset.spacing(2), preset.spacing(2)]}>
                  {/* Dataset filter */}
                  <Col span={6}>
                    <FloatLabelContainer
                      label="Datasets"
                      floating={selectedDatasetIds.length > 0}
                    >
                      <Select
                        showSearch
                        showArrow
                        allowClear
                        maxTagCount={1}
                        maxTagTextLength={
                          selectedDatasetIds.length > 1 ? 24 : 30
                        }
                        mode="multiple"
                        optionFilterProp="children"
                        loading={isProjectDatasetsLoading}
                        value={selectedDatasetIds}
                        onChange={(values) => setSelectedDatasetIds(values)}
                        dropdownMatchSelectWidth={false}
                        style={{ width: '100%' }}
                      >
                        {projectDatasets.map((dataset) => (
                          <Option key={dataset.id} value={dataset.id}>
                            {dataset.name}
                          </Option>
                        ))}
                      </Select>
                    </FloatLabelContainer>
                  </Col>

                  {/* Time filter */}
                  <Col span={6}>
                    <FloatLabelContainer
                      label="Date"
                      floating={
                        timeFilter
                          ? !!(
                              timeFilter.from ||
                              timeFilter.to ||
                              timeFilter.relative
                            )
                          : false
                      }
                      defaultStyle={{ left: 32 }}
                    >
                      <DateRangePicker
                        value={timeFilter}
                        onChange={(value) => setTimeFilter(value)}
                      />
                    </FloatLabelContainer>
                  </Col>

                  {/* Driver analsis configurations */}
                  {analysisType === 'driver-analysis' && (
                    <DriverAnalysisConfigs
                      keyMetricFieldOptions={keyMetricFieldOptions}
                      keyDimensionFieldOptions={keyDimensionFieldOptions}
                      loading={isProjectDatasetsLoading}
                    />
                  )}
                </Row>

                <Row justify="space-between" style={{ marginTop: '20px' }}>
                  <Col />
                  <Col>
                    <Form.Item>
                      <Space>
                        <Button
                          icon={<StopOutlined />}
                          disabled={loadingAnalysis}
                          onClick={() => {
                            setSelectedDatasetIds([]);
                            setTimeFilter(null);
                            configsForm.resetFields();
                          }}
                        >
                          Clear Elements
                        </Button>
                        <Button
                          type="primary"
                          icon={<ExperimentOutlined />}
                          htmlType="submit"
                          loading={loadingAnalysis}
                        >
                          Analyse
                        </Button>
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </TabPane>
        ))}
      </Tabs>

      {/* Help alert */}
      {isHelpAlertOpen ? (
        <Alert
          showIcon
          closable
          onClose={() => setIsHelpAlertOpen(false)}
          type="info"
          message={helpMessage}
          css={styles.helpAlert}
        />
      ) : (
        <div css={styles.helpAlertTrigger}>
          <Button
            type="text"
            onClick={() => setIsHelpAlertOpen(true)}
            icon={<QuestionCircleOutlined />}
          >
            Help
          </Button>
        </div>
      )}

      {/* Analysis results */}
      <Spin spinning={loadingAnalysis}>
        <Row gutter={[preset.spacing(3), preset.spacing(3)]}>
          {/* Analysis summary */}
          {Object.keys(analysisSummary).length > 0 && (
            <Col key="summary" span={24}>
              <Card bordered={false} hoverable={false} css={styles.chartCard}>
                {Object.entries(analysisSummary).map(
                  ([name, summaryPoints]) => (
                    <div key={name}>
                      <Text strong>{name}</Text>
                      <ul>
                        {summaryPoints.map((summaryPoint) => (
                          <li key={shortid.generate()}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(summaryPoint),
                              }}
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  )
                )}
              </Card>
            </Col>
          )}

          {/* Analysis charts */}
          {analysisResults.map((chartGroups) =>
            chartGroups.map((chart) => (
              <Col key={chart.chartId} span={chart.chartColSpan}>
                <Card bordered={false} hoverable={false} css={styles.chartCard}>
                  <ChartDisplay height={500} chart={chart} />
                </Card>
              </Col>
            ))
          )}
        </Row>
      </Spin>
    </Container>
  );
};

export default InsightStudio;
