/** @jsxImportSource @emotion/react */
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { Responsive, WidthProvider } from 'react-grid-layout';

import { message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import getGridLayout from '@modules/getGridLayout';
import useCheckPermission from '@modules/hooks/useCheckPermission';
import { deleteApi, fetchApiErrorMessage } from '@modules/api';

import Container from '@components/Container';
import ChartCard from '@components/ChartCard';
import Empty from '@components/Empty';

const ResponsiveGridLayout = WidthProvider(Responsive);

const mapStateToProps = (state) => {
  return {
    dashboardCharts: state.dashboard.dashboardCharts,
    currentPageId: state.dashboard.currentPageId,
    dashboardPageIds: state.dashboard.pages.allIds,
    loadedPages: state.dashboard.loadedPages,
    isLayoutLocked: state.dashboard.isLayoutLocked,
  };
};

const DashboardCharts = ({
  isPrintingDashboard = false,
  // redux state
  dashboardCharts,
  currentPageId,
  dashboardPageIds,
  loadedPages,
  isLayoutLocked = true,
  canDrilldownData = false,
  // redux dispatch
  dispatch,
}) => {
  const { slug: projectSlug, dashboardSlug } = useParams();
  const history = useHistory();

  const getChartsByPageId = (pageId) => {
    const currentPageVisIds = pageId
      ? dashboardCharts.byPageId[pageId] || []
      : [];
    return currentPageVisIds.map((visId) => dashboardCharts.byId[visId]);
  };

  // Remove chart
  const onRemoveChart = (dashboardVis) => {
    const {
      id: dashboardVisId,
      visualisation: { chartType },
    } = dashboardVis;
    const isRichTextWidget = chartType === 'rich_text';
    const remove = async () => {
      try {
        await deleteApi(
          `projects/${projectSlug}/dashboards/${dashboardSlug}/visualisations/${dashboardVisId}`
        );

        dispatch({ type: 'REMOVE_DASHBOARD_CHART', visId: dashboardVisId });
        message.success(`${isRichTextWidget ? 'Widget' : 'Chart'} Removed`);
      } catch (error) {
        message.error(
          fetchApiErrorMessage(
            error,
            `Unable to Remove ${isRichTextWidget ? 'Widget' : 'Chart'}`
          )
        );
      }
    };

    Modal.confirm({
      title: `Remove this ${
        isRichTextWidget ? 'widget' : 'chart'
      } from dashboard?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Remove',
      okType: 'danger',
      onOk: () => remove(),
    });
  };

  // Go to project chart edit page
  const onEditChart = (dashboardVis) => {
    const {
      id: dashboardVisId,
      visualisation: { id, chartType },
    } = dashboardVis;

    if (chartType === 'rich_text') {
      dispatch({
        type: 'SET_SELECTED_RICH_TEXT_WIDGET',
        chartId: dashboardVisId,
      });
    } else {
      const currentPageIndexStr = dashboardPageIds.indexOf(currentPageId) + 1;
      const urlQueryString = queryString.stringify({
        dashboard: dashboardSlug,
        page: currentPageIndexStr,
      });

      history.push(`/projects/${projectSlug}/charts/${id}?${urlQueryString}`);
    }
  };

  // Create Alert from a chart
  const createAlert = (chart) => {
    history.push(`/projects/${projectSlug}/alerts`, {
      visualisationId: chart.visualisation.id,
    });
  };

  // Handle layout change
  const onLayoutChange = (newLayouts) => {
    dispatch({ type: 'SET_DASHBOARD_CHARTS_LAYOUT', layouts: newLayouts });
  };

  const { gridRowHeight, gridWidth, gridMargin, getGridUnitHeight } =
    getGridLayout();

  const canCreateAndEditChart = useCheckPermission([
    'insight.manageDashboards',
  ]);

  return loadedPages.map((loadedPage) => {
    const isCurrentPage = currentPageId === loadedPage.id;
    const currentPageCharts = getChartsByPageId(loadedPage.id);

    return currentPageCharts.length > 0 ? (
      <Container
        key={`page_${loadedPage.id}`}
        fullWidth
        css={{ display: isCurrentPage ? 'block' : 'none' }}
      >
        <ResponsiveGridLayout
          key={loadedPage.layoutResetAt}
          cols={{ lg: 12 }}
          breakpoints={{ lg: 1200 }}
          rowHeight={gridRowHeight}
          width={gridWidth}
          margin={[gridMargin, gridMargin]}
          containerPadding={[0, 0]}
          draggableHandle=".draggable"
          onResizeStop={(layout, oldItemLayout, newItemLayout) =>
            onLayoutChange([newItemLayout])
          }
          onLayoutChange={(newLayouts) => onLayoutChange(newLayouts)}
        >
          {currentPageCharts.map((dashboardChart) => {
            const { chartType, data } = dashboardChart.visualisation;
            const isRichTextWidget = chartType === 'rich_text';
            return (
              <div
                key={dashboardChart.id}
                data-grid={dashboardChart.layout}
                className={isLayoutLocked ? 'disabled' : 'draggable'}
              >
                <ChartCard
                  id={dashboardChart.visualisation.id}
                  dashboardChartId={dashboardChart.id}
                  width={dashboardChart.layout.w}
                  height={getGridUnitHeight(dashboardChart.layout.h)}
                  filters={loadedPage.activeFilters.filters}
                  timeFilter={loadedPage.activeFilters.timeFilter || null}
                  refreshAt={loadedPage.chartsRefreshAt}
                  comments={dashboardChart.comments}
                  drillDownDisabled={!canDrilldownData}
                  actions={
                    isLayoutLocked && !isPrintingDashboard
                      ? [
                          ...(canCreateAndEditChart
                            ? [
                                {
                                  name: `Edit ${
                                    isRichTextWidget ? 'Widget' : 'Chart'
                                  }`,
                                  onClick: () => onEditChart(dashboardChart),
                                },
                                {
                                  name: `Remove ${
                                    isRichTextWidget ? 'Widget' : 'Chart'
                                  }`,
                                  onClick: () => onRemoveChart(dashboardChart),
                                },
                              ]
                            : []),
                          ...(isRichTextWidget
                            ? []
                            : [
                                { name: 'Download As' },
                                ...(['number', 'gauge'].includes(chartType)
                                  ? [
                                      {
                                        name: 'Create Alert',
                                        onClick: () =>
                                          createAlert(dashboardChart),
                                      },
                                    ]
                                  : []),
                                { name: 'Annotation' },
                              ]),
                        ]
                      : []
                  }
                  {...(isRichTextWidget &&
                  Array.isArray(data) &&
                  data.length > 0 &&
                  data[0].html
                    ? {
                        key: data[0].html,
                      }
                    : {})}
                />
              </div>
            );
          })}
        </ResponsiveGridLayout>
      </Container>
    ) : (
      <Empty
        key={`emptyPage_${loadedPage.id}`}
        description={
          canCreateAndEditChart ? (
            <span>
              <a
                href="#add-chart"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch({
                    type: 'SET_DASHBOARD_MODAL_OPEN',
                    key: 'isChartSelectModalOpen',
                    open: true,
                  });
                }}
              >
                Add a new chart
              </a>
              {' to build your dashboard'}
            </span>
          ) : (
            <span>This dashboard has no charts currently</span>
          )
        }
        css={{
          display: currentPageId === loadedPage.id ? 'flex' : 'none',
        }}
      />
    );
  });
};

export default connect(mapStateToProps)(DashboardCharts);
