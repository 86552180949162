/** @jsxImportSource @emotion/react */
import {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import ReactQuill, { Quill } from 'react-quill';

import deepGet from '../../modules/deepGet';

import 'react-quill/dist/quill.snow.css';

// Prepend https to url if not found
const Link = Quill.import('formats/link');
Link.sanitize = (url) => {
  if (/^\S+(\.)\S+[^.]$/.test(url) && !/^https?:\/\//.test(url)) {
    return `https://${url}`;
  }
  return url;
};

const RichTextEditor = forwardRef((props, ref) => {
  const { defaultValue } = props;

  const editorRef = useRef();

  const [isEditorMounted, setIsEditorMounted] = useState(false);

  useEffect(() => {
    setIsEditorMounted(true);
  }, []);

  // Fix stupid Quill default placeholder
  useEffect(() => {
    if (isEditorMounted && editorRef.current) {
      const tooltipRoot = deepGet(editorRef.current, [
        'editor',
        'theme',
        'tooltip',
        'root',
      ]);
      if (tooltipRoot) {
        const input = tooltipRoot.querySelector('input[data-link]');
        input.dataset.link = 'e.g. https://www.google.com/';
      }
    }
  }, [isEditorMounted]);

  // Expose Quill ref to parent
  useImperativeHandle(ref, () => ({
    getHTML: () => {
      if (editorRef.current) {
        const htmlContents = editorRef.current.getEditorContents();
        if (htmlContents === '<p><br></p>') {
          return null;
        }
        return editorRef.current.getEditorContents();
      }
      return null;
    },
  }));

  return (
    <div>
      <div id="richTextEditorContainer" css={{ position: 'relative' }}>
        <ReactQuill
          ref={editorRef}
          theme="snow"
          bounds="#richTextEditorContainer"
          defaultValue={defaultValue}
          modules={{
            toolbar: [
              [{ header: [1, 2, 3, false] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ color: [] }, { background: [] }],
              [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
              ],
              [{ direction: 'rtl' }, { align: [] }],
              ['link', 'image'],
              ['clean'],
            ],
          }}
          formats={[
            'header',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'color',
            'background',
            'list',
            'bullet',
            'indent',
            'link',
            'image',
            'direction',
            'align',
            'clean',
          ]}
        />
      </div>
    </div>
  );
});

export default RichTextEditor;
