import { preset } from 'styles';

const GRID_COLS = 12;
const GRID_ROW_HEIGHT = 90;
const GRID_MARGIN = preset.spacing(3);

const getGridLayout = () => {
  const gridWidth = window.innerWidth - preset.spacing(5) * 2;

  return {
    gridCols: GRID_COLS,
    gridRowHeight: GRID_ROW_HEIGHT,
    gridWidth,
    gridMargin: GRID_MARGIN,
    getGridUnitHeight: (layoutH) =>
      (GRID_ROW_HEIGHT + GRID_MARGIN) * layoutH - GRID_MARGIN,
  };
};

export default getGridLayout;
