import { Formik } from 'formik';
import { sortBy, isEqual, find } from 'lodash';
import { useMemo, useContext, useState, useEffect } from 'react';
import { useAuth } from '@otso/auth-wrapper';

import { Card, Divider, message } from 'antd';
import { Input, Select, Form, SubmitButton } from 'formik-antd';

// Import components
import { preset } from 'styles';
import Container from '@components/Container';

// Import modules
import useGetApi from '@modules/hooks/useGetApi';
import { putApi, fetchApiErrorMessage } from '@modules/api';

// Import constants and providers
import { ProjectContext } from '@providers/ProjectProvider';

import DangerZone from '../components/DangerZone';
import DuplicateProject from '../components/DuplicateProject';
import ProjectDatasetForm from '../../CreateProject/components/ProjectDatasetForm';

const Setting = () => {
  const [isSavingProject, setIsSavingProject] = useState(false);
  const [hasProjectDatasetFilters, setHasProjectDatasetFilters] =
    useState(false);
  const [rawFilters, setRawFilters] = useState({});

  const { currentOrg } = useAuth();

  const { project, loading, setProject, setProjectDatasets } =
    useContext(ProjectContext);

  const {
    slug: projectSlug,
    name,
    collaborators = [],
    datasets = [],
    filters,
  } = project;

  const [currentDatasets, setCurrentDatasets] = useState(datasets);
  const [currentDatasetIds, setCurrentDatasetIds] = useState([]);
  const [initialFilters, setInitialFilters] = useState({});

  // Notes: if filters is passed from project, set our state variables to render filter UI
  useEffect(() => {
    if (filters && filters.excluded === undefined) {
      const rawFiltersObject = Object.values(filters).map((f) => f.rawFilters);
      const updatedRawFilters = { ...rawFilters };
      if (rawFiltersObject[0] !== undefined) {
        Object.keys(filters).forEach((k, index) => {
          updatedRawFilters[k] = rawFiltersObject[index] || {};
        });
      }
      setRawFilters(updatedRawFilters);
      setInitialFilters(updatedRawFilters);
      setHasProjectDatasetFilters(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    setCurrentDatasets(datasets);
  }, [datasets]);

  useEffect(() => {
    setCurrentDatasetIds(currentDatasets.map((dataset) => dataset.id));
  }, [currentDatasets]);

  // Get all datasets
  const { data: allDatasets = [] } = useGetApi('datasets');

  // Sort users by email
  const sortedUsers = useMemo(
    () =>
      (currentOrg?.users || []).sort((a, b) => a.email.localeCompare(b.email)),
    [currentOrg?.users]
  );

  const validate = (values) => {
    const errors = {};
    if (!values.projectName || !values.projectName.trim()) {
      errors.projectName = 'Project Name is required';
    }
  };

  // Update Project Setting
  const onSubmit = async (values) => {
    // Extract values from form data
    const {
      name: newProjectName,
      collaborators: newCollaborators,
      datasets: newDatasets,
      filters: newFilters,
    } = values;

    // Set loading status
    setIsSavingProject(true);

    try {
      // Update project name
      if (newProjectName !== name) {
        await putApi(`projects/${projectSlug}`, { name: newProjectName });
      }
      if (newFilters) {
        // Notes: Proceed to update project dataset filter only if they have changed
        if (!isEqual(newFilters, initialFilters)) {
          await putApi(`projects/${projectSlug}/filters`, {
            filters: newFilters,
            datasets: newDatasets,
          });
        }
      }

      // Update project collaborators if changes detected
      if (!isEqual(sortBy(collaborators), sortBy(newCollaborators))) {
        await putApi(`projects/${projectSlug}/collaborators`, {
          collaborators: newCollaborators,
        });
      }

      // Update project datasets if changes detected
      if (!isEqual(sortBy(datasets), sortBy(newDatasets))) {
        await putApi(`projects/${projectSlug}/datasets`, {
          datasets: newDatasets,
        });
      }

      // Update project data
      setProject({
        ...project,
        name: newProjectName,
        collaborators: newCollaborators,
      });
      setProjectDatasets(
        newDatasets.map((datasetId) => find(allDatasets, { id: datasetId }))
      );

      // Show success message
      message.success('Project Settings Saved');
    } catch (error) {
      message.error(fetchApiErrorMessage(error));
    }

    // Cancel loading status
    setIsSavingProject(false);
  };

  return (
    <Container paddingY={2}>
      <Card bordered={false} loading={loading}>
        <Formik
          enableReinitialize
          initialValues={{
            name,
            collaborators,
            datasets: currentDatasetIds,
            filters: rawFilters,
          }}
          onSubmit={onSubmit}
          validate={validate}
        >
          {(props) => (
            <Form layout="vertical">
              <Form.Item name="name" label="Project Name">
                <Input name="name" placeholder="Project Name" />
              </Form.Item>
              <Form.Item name="collaborators" label="Project Collaborators">
                <Select
                  name="collaborators"
                  showSearch
                  mode="multiple"
                  placeholder="Add Collaborators"
                  optionFilterProp="children"
                >
                  {sortedUsers.map((user) => (
                    <Select.Option
                      key={user.id}
                      title={user.email}
                      value={user.id}
                    >
                      {user.email}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {hasProjectDatasetFilters ? (
                <ProjectDatasetForm
                  formValues={{
                    datasets: currentDatasetIds,
                    filters: rawFilters,
                    initialFilters,
                  }}
                  hideDatasetSelector
                  valid
                  allDatasets={allDatasets}
                  withContainer={false}
                  onFormValueChange={(newVal, key, datasetId) => {
                    if (key === 'datasets') return; // Notes: Here we ignore changes triggered from datasets

                    const currentFilters = props.values.filters || rawFilters;
                    const updatedFilters = {
                      ...currentFilters,
                    };

                    // Notes: if datasetId is being passed, we update the filters for that one
                    if (datasetId) {
                      updatedFilters[datasetId] = newVal[datasetId] || {};
                    }

                    // Notes: check if a new dataset is being added or removed
                    if (!isEqual(Object.keys(newVal), currentDatasetIds)) {
                      const newDatasetIds = Object.keys(newVal);
                      const newDatasets = allDatasets.filter((d) =>
                        newDatasetIds.includes(d.id)
                      );
                      setCurrentDatasets(newDatasets);
                      setRawFilters(newVal);
                    }
                    props.setFieldValue('filters', updatedFilters);
                  }}
                  style={{ marginBottom: preset.spacing(4) }}
                />
              ) : (
                <Form.Item name="datasets" label="Datasets">
                  <Select
                    name="datasets"
                    showSearch
                    mode="multiple"
                    optionFilterProp="children"
                  >
                    {allDatasets.map((datasetObj) => (
                      <Select.Option
                        key={datasetObj.id}
                        title={datasetObj.name}
                        value={datasetObj.id}
                        disabled={currentDatasetIds.includes(datasetObj.id)}
                      >
                        {datasetObj.name}
                      </Select.Option>
                    ))}
                    ))
                  </Select>
                </Form.Item>
              )}

              <SubmitButton
                type="primary"
                disabled={isSavingProject}
                loading={isSavingProject}
              >
                Save
              </SubmitButton>
            </Form>
          )}
        </Formik>
        <Divider css={{ marginTop: preset.spacing(5) }} />
        <DuplicateProject project={project} />
        <Divider css={{ marginTop: preset.spacing(5) }} />
        <DangerZone project={project} />
      </Card>
    </Container>
  );
};

export default Setting;
