/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, message, Space, Divider, Button, Typography } from 'antd';
import orderBy from 'lodash/orderBy';

import { preset } from 'styles';
import { AudioWord, Dataset, DocumentEnrichmentCorrection } from 'types';
import {
  postApi,
  fetchApiErrorMessage,
  getExploreQuery,
  arrayToObject,
} from 'modules';
import { Spinner } from 'components';
import KeyDocumentInfo from './KeyDocumentInfo';
import CustomClassifiersInfo from './CustomClassifiersInfo';
import AudioChildDoc from './AudioChildDoc';

type Props = {
  document: Record<string, any>;
  dataset: Dataset;
  getAudioPlayer: () => HTMLAudioElement | null;
};

const CHILD_DOC_LIMIT = 10;

const formatUtterancesWithWordsData = (
  utterance: any,
  index: number,
  documentId: string
) => {
  const wordsData: AudioWord[] = Array.isArray(utterance.words)
    ? utterance.words
    : typeof utterance.words === 'string'
    ? JSON.parse(utterance.words)
    : [];

  const formattedWords = wordsData.map((wordItem) => ({
    ...wordItem,
    startTime: wordItem.startTime,
    endTime: wordItem.endTime,
  }));

  return {
    ...utterance,
    id: `${documentId}_${index}`,
    words: formattedWords,
  };
};

const AudioDocExpanded: React.FC<Props> = ({
  document,
  dataset,
  getAudioPlayer,
}) => {
  const [childDocuments, setChildDocuments] = useState<Record<string, any>[]>(
    []
  );
  const [childEnrichmentCorrections, setChildEnrichmentCorrections] = useState<{
    [documentId: string]: DocumentEnrichmentCorrection;
  }>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPlayingDocId, setCurrentPlayingDocId] = useState<string>('');
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [childDocLimit, setChildDocLimit] = useState<number>(CHILD_DOC_LIMIT);

  const { slug: projectSlug } = useParams<{ slug: string }>();

  const { id: datasetId } = dataset;

  useEffect(() => {
    const hasUtterances = Array.isArray(document.utterances);

    const query = getExploreQuery({
      datasetIds: [datasetId],
      filters: [{ attribute: 'parent_id', condition: '=', value: document.id }],
    });

    const fetchEnrichmentCorrections = async (newDocIds: string[]) => {
      const { data } = await postApi(
        `projects/${projectSlug}/enrichment-correction`,
        { documents: newDocIds }
      );
      setChildEnrichmentCorrections(
        data ? arrayToObject(data, 'documentId') : {}
      );
    };

    const fetchChildDocuments = async () => {
      setIsLoading(true);
      try {
        let childDocs: Record<string, any>[] = [];

        if (hasUtterances) {
          childDocs = orderBy(document.utterances, 'start_time', 'asc').map(
            (utterance, index) =>
              formatUtterancesWithWordsData(utterance, index, document.id)
          );
        } else {
          const res = await postApi(`projects/${projectSlug}/query`, query);
          const { data }: { data: Record<string, any>[] } = res.data;

          if (data.length > 0) {
            await fetchEnrichmentCorrections(
              data.map((doc) => doc.otso_doc_id)
            );
          }

          childDocs = orderBy(data, 'start_time', 'asc').map(
            (utterance, index) =>
              formatUtterancesWithWordsData(utterance, index, document.id)
          );
        }

        const audioPlayer = getAudioPlayer();
        if (audioPlayer) {
          if (!audioPlayer.paused) {
            setIsPlaying(true);
          }
          audioPlayer.onplay = () => {
            setIsPlaying(true);
          };
          audioPlayer.onpause = () => {
            setIsPlaying(false);
          };
          audioPlayer.ontimeupdate = () => {
            const currentPlayingDocs = childDocs.find((doc) => {
              return doc.words.find(
                (word: any) =>
                  audioPlayer.currentTime > word.startTime &&
                  audioPlayer.currentTime < word.endTime
              );
            });
            setCurrentPlayingDocId(currentPlayingDocs?.id || '');
          };
        }

        setChildDocuments(childDocs);
      } catch (error) {
        message.error(fetchApiErrorMessage(error));
      }
      setIsLoading(false);
    };

    if (document.id && datasetId) fetchChildDocuments();
  }, [
    datasetId,
    document.id,
    document.utterances,
    projectSlug,
    getAudioPlayer,
  ]);

  const currentPlayingDocIndex: number = childDocuments.findIndex(
    (doc) => doc.id === currentPlayingDocId
  );
  const hasMoreDoc: boolean = childDocLimit < childDocuments.length;

  const isPlayingDocCloseToLimit: boolean =
    isPlaying &&
    hasMoreDoc &&
    childDocLimit - currentPlayingDocIndex - 1 < CHILD_DOC_LIMIT / 5;

  useEffect(() => {
    if (isPlayingDocCloseToLimit) {
      setChildDocLimit((prevLimit) => prevLimit + CHILD_DOC_LIMIT);
    }
  }, [isPlayingDocCloseToLimit]);

  return (
    <Row gutter={[preset.spacing(2), preset.spacing(2)]}>
      <Col span={24}>
        <Row gutter={[preset.spacing(2), preset.spacing(2)]}>
          <Col span={12}>
            <KeyDocumentInfo document={document} dataset={dataset} />
          </Col>
          <Col span={12}>
            <CustomClassifiersInfo document={document} dataset={dataset} />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Space direction="vertical" size="middle" css={{ width: '100%' }}>
          {isLoading ? (
            <Spinner />
          ) : (
            childDocuments
              .slice(0, childDocLimit)
              .map((childDoc) => (
                <AudioChildDoc
                  key={childDoc.id}
                  childDoc={childDoc}
                  dataset={dataset}
                  hasEnrichmentCorrection={
                    !!childEnrichmentCorrections[childDoc.otso_doc_id]
                  }
                  hideCustomClassifier={Array.isArray(document.utterances)}
                  getAudioPlayer={getAudioPlayer}
                  status={
                    currentPlayingDocId === childDoc.id
                      ? isPlaying
                        ? 'playing'
                        : 'paused'
                      : 'idle'
                  }
                />
              ))
          )}
          {!isLoading && hasMoreDoc && (
            <Divider>
              <Button
                type="primary"
                onClick={() =>
                  setChildDocLimit((prevLimit) => prevLimit + CHILD_DOC_LIMIT)
                }
              >
                {`Load ${childDocuments.length - childDocLimit} more`}
              </Button>
            </Divider>
          )}
          {!isLoading && (
            <Typography.Paragraph type="secondary" css={{ textAlign: 'right' }}>
              {childDocuments.slice(0, childDocLimit).length} of{' '}
              {childDocuments.length} results
            </Typography.Paragraph>
          )}
        </Space>
      </Col>
    </Row>
  );
};

export default AudioDocExpanded;
