/** @jsxImportSource @emotion/react */

import formatMetricLabel from '@modules/chart/formatMetricLabel';
import ComparisonCard from '../components/ComparisonCard';

const SingleDataHighlight = ({
    height,
    config,
    color,
    data,
    queryObj,
    schema,
}) => {
    const primaryValue = Array.isArray(data[0])
        ? Object.values(data[0][0])[0]
        : Object.values(data[0])[0];

    const primaryValueDisplay = formatMetricLabel(primaryValue, config.format);

    const hasCompareValue = !!(queryObj.compare && typeof queryObj.compare === 'object');

    return (
        <div
            css={{
                height,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
            }}
        >
            <svg
                width="100%"
                height={height * (hasCompareValue ? (1 / 2) : 1)}
                viewBox={`0 0 ${`${primaryValueDisplay}`.length * 12} 24`}
            >
                <text
                    x="50%"
                    y="50%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    fill={color.text}
                >
                    {primaryValueDisplay}
                </text>
            </svg>

            {hasCompareValue && (
                <ComparisonCard
                    config={config}
                    data={data}
                    schema={schema}
                    css={{
                        transform: `scale(${height / 250}, ${height / 250}) translate(0, -20%)`,
                    }}
                />
            )}
        </div>
    );
};

export default SingleDataHighlight;
