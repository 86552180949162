// Dataset
export const DATASET_DATA_TYPES = [
  { name: 'String', value: 'string', category: 'dimension' },
  { name: 'List', value: '_string', category: 'dimension' },
  { name: 'Boolean', value: 'boolean', category: 'dimension' },
  { name: 'Numeric (Metric)', value: 'numeric', category: 'metric' },
  { name: 'Numeric (Dimension)', value: 'numeric', category: 'dimension' },
  { name: 'Timestamp/Date', value: 'timestamp', category: 'dimension' },
];

export const DATASET_FILTER_TYPES = [
  { name: 'None', value: '' },
  { name: 'Language', value: 'language' },
  { name: 'Verbatim', value: 'verbatim' },
  { name: 'Title', value: 'title' },
  { name: 'ID', value: 'id' },
  { name: 'Channel', value: 'channel' },
  { name: 'Image', value: 'image' },
  { name: 'Link', value: 'link' },
  { name: 'Source', value: 'source' },
  { name: 'Author', value: 'author' },
  { name: 'Date Time', value: 'time' },
  { name: 'Coordinates', value: 'location_coord' },
  { name: 'Suburb', value: 'location_suburb' },
  { name: 'City', value: 'location_city' },
  { name: 'Region', value: 'location_region' },
  { name: 'Location - Other', value: 'location_other' },
];

export const DATASET_RESERVED_FIELDS = [
  'id',
  'ingested_at',
  'language',
  'otso_doc_body',
  'otso_doc_entities',
  'otso_doc_publish_date',
  'entities',
  'emotion',
  'sentiment',
  'usage',
  'sentiment_label',
  'sentiment_score',
  'primary_emotion',
  'other_emotions',
];

// Reviews
// https://help.datashake.com/article/208-guideline-url-formats
export const SUPPORTED_REVIEW_SITES = {
  'www.airbnb.com': 'Airbnb',
  'www.airbnb.com.au': 'Airbnb',
  'www.aliexpress.com': 'AliExpress',
  'www.amazon.com': 'Amazon',
  'www.amazon.com.au': 'Amazon',
  'apps.apple.com': 'Apple Appstore',
  'www.booking.com': 'Booking.com',
  'feedback.ebay.com': 'Ebay',
  'feedback.ebay.com.au': 'Ebay',
  'www.expedia.com': 'Expedia',
  'www.expedia.com.au': 'Expedia',
  'www.facebook.com': 'Facebook',
  'play.google.com': 'Google Playstore',
  'www.indeed.com': 'Indeed',
  'au.indeed.com': 'Indeed',
  'www.tripadvisor.com': 'TripAdvisor',
  'www.tripadvisor.com.au': 'TripAdvisor',
  'www.zomato.com': 'Zomato',
  'www.trustpilot.com': 'Trustpilot',
  'www.productreview.com.au': 'ProductReview',
  'www.google.com': 'Google',
  'www.glassdoor.com.au': 'Glassdoor',
};

export const REVIEW_QUERY_TERMS = [
  { name: 'Boat Ramp', value: 'Boat Ramp' },
  { name: 'Library', value: 'Library' },
  { name: 'Sports Club', value: 'Sports Club' },
  { name: 'Pool', value: 'Pool' },
  { name: 'Sport Field', value: 'Sport Field' },
  { name: 'Playground', value: 'Playground' },
  { name: 'Rubbish Dump', value: 'Rubbish Dump' },
  { name: 'Tourist Attraction', value: 'Tourist Attraction' },
  { name: 'Park', value: 'Park' },
  { name: 'Bike Path', value: 'Bike Path' },
];

// User Roles
export const USER_ROLES = [
  { id: 1, name: 'Admin' },
  { id: 2, name: 'Analyst' },
  { id: 3, name: 'User' },
];

export const USER_ROLES_BY_ID = {
  1: 'Admin',
  2: 'Analyst',
  3: 'User',
};

// Time Intervals
export const TIME_INTERVALS = {
  truncate: [
    { label: 'Auto', value: 'auto' },
    { label: 'Year', value: 'year' },
    { label: 'Month', value: 'month' },
    { label: 'Week', value: 'week' },
    { label: 'Day', value: 'day' },
    { label: 'Hour', value: 'hour' },
    { label: 'Minute', value: 'minute' },
    { label: 'Second', value: 'second' },
  ],
  extract: [
    { label: 'Quarter of Year', value: 'qoy' },
    { label: 'Month of Year', value: 'moy' },
    { label: 'Week of Year', value: 'woy' },
    { label: 'Day of Year', value: 'doy' },
    { label: 'Day of Month', value: 'dom' },
    { label: 'Day of Week', value: 'dow' },
    { label: 'Hour of Day', value: 'hod' },
  ],
};

// Chart Types
export const CHART_TYPES = {
  allTypes: [
    'number',
    'gauge',
    'bar_basic',
    'line_basic',
    'area_basic',
    'pie_innerlabel',
    'pie_donut',
    'waterfall',
    'list_count',
    'heatmap',
    // 'list_ordered',
    // 'geo_bubble',
    'bubble',
    'treemap_rect',
    'word_cloud',
    // 'bullet',
    'double_axes',
    'plotted_bubble',
    'radar',
    'choropleth_map',
    'bar_grouped',
    'bar_stackedColumn',
    'bar_stackedPercentage',
    'line_series',
    'area_series',
    'table',
  ],
  byType: {
    number: {
      name: 'Single Data Highlight',
      maxMetrics: 1,
      maxDimensions: 0,
      comparable: true,
      hints: '1 Metric, No Dimension',
      img: 'number.png',
    },
    gauge: {
      name: 'Gauge Chart',
      maxMetrics: 1,
      maxDimensions: 0,
      comparable: true,
      hints: '1 Metric, No Dimension',
      img: 'gauge.png',
    },
    bar_basic: {
      name: 'Bar Chart',
      maxMetrics: Infinity,
      maxDimensions: 1,
      noDimension: true,
      hints: '1 Metric, 1 Dimension OR Multiple Metrics, No Dimension',
      img: 'bar_basic.png',
    },
    line_basic: {
      name: 'Line Chart',
      maxMetrics: 1,
      maxDimensions: 1,
      hints: '1 Metric, 1 Dimension',
      img: 'line_basic.png',
    },
    area_basic: {
      name: 'Area Chart',
      maxMetrics: 1,
      maxDimensions: 1,
      hints: '1 Metric, 1 Dimension',
      img: 'area_basic.png',
    },
    double_axes: {
      name: 'Double Axes Chart',
      maxMetrics: 2,
      maxDimensions: 1,
      hints: '2 Metrics, 1 Dimension',
      img: 'double_axes.png',
    },
    pie_innerlabel: {
      name: 'Pie Chart',
      maxMetrics: Infinity,
      maxDimensions: 1,
      noDimension: true,
      hints: '1 Metric, 1 Dimension OR Multiple Metrics, No Dimension',
      img: 'pie_innerlabel.png',
    },
    pie_donut: {
      name: 'Donut Chart',
      maxMetrics: Infinity,
      maxDimensions: 1,
      noDimension: true,
      hints: '1 Metric, 1 Dimension OR Multiple Metrics, No Dimension',
      img: 'pie_donut.png',
    },
    waterfall: {
      name: 'Waterfall Chart',
      maxMetrics: 1,
      maxDimensions: 1,
      hints: '1 Metric, 1 Dimension',
      img: 'waterfall.png',
    },
    list_count: {
      name: 'Breakdown List',
      maxMetrics: 1,
      maxDimensions: 1,
      hints: '1 Metric, 1 Dimension',
      img: 'list_count.png',
    },
    list_ordered: {
      name: 'Ordered List',
      maxMetrics: 1,
      maxDimensions: 1,
      hints: '1 Metric, 1 Dimension',
      img: 'list_ordered.png',
    },
    bubble: {
      name: 'Bubble Chart',
      maxMetrics: 2,
      maxDimensions: 1,
      hints: '1 Metric, 1 Dimension OR 2 Metrics, 1 Dimension',
      img: 'bubble.png',
    },
    geo_bubble: {
      name: 'Geo Map',
      maxMetrics: 1,
      maxDimensions: 2,
      hints: '1 Metric, 2 Dimensions including 1 Coordinate Dimension',
      img: 'geo_bubble.png',
    },
    choropleth_map: {
      name: 'Choropleth Map',
      maxMetrics: Infinity,
      maxDimensions: 1,
      hints: 'Multiple Metrics, 1 Location Dimension',
      img: 'choropleth_map.png',
    },
    heatmap: {
      name: 'Heat Map',
      maxMetrics: 2,
      maxDimensions: 2,
      hints: '1 Metrics, 2 Dimensions OR 2 Metrics, 2 Dimensions',
      img: 'heatmap_rect.png',
    },
    bullet: {
      name: 'Bullet Chart',
      maxMetrics: Infinity,
      maxDimensions: 1,
      hints: '1 Metric, 1 Dimension OR Multiple Metrics, No Dimension',
      img: 'bullet.png',
    },
    treemap_rect: {
      name: 'Rectangle Tree Map',
      maxMetrics: 2,
      maxDimensions: 1,
      hints: '1 Metric, 1 Dimension OR 2 Metrics, 1 Dimension',
      img: 'treemap_rect.png',
    },
    word_cloud: {
      name: 'Word Cloud',
      maxMetrics: 2,
      maxDimensions: 1,
      hints: '1 Metric, 1 Dimension OR 2 Metrics, 1 Dimension',
      img: 'word_cloud.png',
    },
    plotted_bubble: {
      name: 'Plotted Bubble Chart',
      maxMetrics: 3,
      maxDimensions: 1,
      hints: '3 Metrics, 1 Dimension',
      img: 'plotted_bubble.png',
    },
    radar: {
      name: 'Radar Chart',
      maxMetrics: Infinity,
      maxDimensions: 1,
      hints: 'Multiple Metrics, 1 Dimension',
      img: 'radar.png',
    },
    bar_grouped: {
      name: 'Group Bar Chart',
      maxMetrics: Infinity,
      maxDimensions: 2,
      comparable: true,
      hints: 'Multi Metrics, 1 Dimension OR 1 Metric, 2 Dimensions',
      img: 'bar_grouped.png',
    },
    bar_stackedColumn: {
      name: 'Stacked Bar Chart',
      maxMetrics: Infinity,
      maxDimensions: 2,
      comparable: true,
      hints: 'Multi Metrics, 1 Dimension OR 1 Metric, 2 Dimensions',
      img: 'bar_stackedColumn.png',
    },
    bar_stackedPercentage: {
      name: 'Percentage Bar Chart',
      maxMetrics: Infinity,
      maxDimensions: 2,
      hints: 'Multi Metrics, 1 Dimension OR 1 Metric, 2 Dimensions',
      img: 'bar_stackedPercentage.png',
    },
    line_series: {
      name: 'Series Line Chart',
      maxMetrics: Infinity,
      maxDimensions: 2,
      comparable: true,
      hints: 'Multi Metrics, 1 Dimension OR 1 Metric, 2 Dimensions',
      img: 'line_series.png',
    },
    area_series: {
      name: 'Series Area Chart',
      maxMetrics: Infinity,
      maxDimensions: 2,
      comparable: true,
      hints: 'Multi Metrics, 1 Dimension OR 1 Metric, 2 Dimensions',
      img: 'area_series.png',
    },
    table: {
      name: 'Table',
      maxMetrics: Infinity,
      maxDimensions: Infinity,
      noDimension: true,
      hints: '',
      img: 'table.png',
    },
  },
};

export const DASHBOARD_REFRESH = 5 * 60 * 1000;
