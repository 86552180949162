const initialState = {};

export default function chartDownload(state = initialState, action) {
    switch (action.type) {
        case 'SET_DOWNLOAD_CHART':
            return action.chart;

        case 'RESET_DOWNLOAD_CHART':
            return initialState;

        default:
            return state;
    }
}
