const getComparisonValue = (primaryVal, compareToVal, config) => {
    switch (config) {
        case 'percentage_change':
            return ((primaryVal - compareToVal) / Math.abs(compareToVal)) * 100;
        case 'absolute_change':
            return primaryVal - compareToVal;
        default:
            return compareToVal;
    }
};

export default getComparisonValue;
