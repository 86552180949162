/** @jsxImportSource @emotion/react */
import { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';

import { Modal, Row, Col, Form, Select, Button, InputNumber } from 'antd';

import { trackEvent } from 'analytics';
import { colors, preset } from 'styles';
import downloadSVGAs from '@modules/chart/downloadSVGAs';
import ChartDisplay from '../ChartDisplay';

const { Option } = Select;

const mapStateToProps = (state) => {
  return {
    chartDownload: state.chartDownload,
  };
};

const DEFAULT_CHART_WIDTH =
  (preset.modalWidth.lg - preset.spacing(3) * 2 + preset.spacing(5)) * 0.75 -
  preset.spacing(5);
const DEFAULT_CHART_HEIGHT = 500;

const ChartDownloadModal = ({
  // redux state
  chartDownload,
  // redux dispatch
  dispatch,
}) => {
  const { id, chartType, defaultTitle, data = [] } = chartDownload;

  const imageDownloadDisabled = [
    'table',
    'number',
    'list_count',
    'choropleth_map',
  ].includes(chartType);

  const defaultDownloadAs = imageDownloadDisabled ? 'csv' : 'png';

  const [downloadAs, setDownloadAs] = useState(defaultDownloadAs);
  const [chartDownloadInstance, setChartDownloadInstance] = useState();
  const [imgW, setImgW] = useState(DEFAULT_CHART_WIDTH);
  const [imgH, setImgH] = useState(DEFAULT_CHART_HEIGHT);

  useEffect(() => {
    if (id) {
      setDownloadAs(defaultDownloadAs);
    }
  }, [defaultDownloadAs, id]);

  const onModalClose = () => {
    dispatch({ type: 'RESET_DOWNLOAD_CHART' });
    setDownloadAs(defaultDownloadAs);
    setChartDownloadInstance();
  };

  const onDownload = () => {
    trackEvent(`Chart ${downloadAs === 'csv' ? 'CSV' : 'Image'} Download`, {
      chartId: id,
      chartTitle: defaultTitle,
    });

    if (chartDownloadInstance && downloadAs !== 'csv') {
      if (chartType === 'bubble') {
        downloadSVGAs(chartDownloadInstance, {
          width: imgW,
          height: imgH,
          downloadAs,
          name: defaultTitle,
        });
      } else {
        chartDownloadInstance.downloadImage(defaultTitle);
      }
    }
  };

  const chartDownloadConfig = useMemo(() => {
    switch (downloadAs) {
      case 'svg':
        return { renderer: 'svg', setChartDownloadInstance };
      case 'csv':
        return { chartType: 'table' };
      default:
        return { renderer: 'canvas', setChartDownloadInstance };
    }
  }, [downloadAs]);

  return (
    <Modal
      title="Download Chart"
      visible={!!id}
      width={preset.modalWidth.lg}
      destroyOnClose
      onCancel={() => onModalClose()}
      footer={null}
    >
      <Row gutter={preset.spacing(5)}>
        <Col span={18}>
          <div
            css={{
              overflow: 'auto',
              maxWidth: DEFAULT_CHART_WIDTH,
              maxHeight: DEFAULT_CHART_HEIGHT,
            }}
          >
            <div
              css={{
                width: imgW,
                height: imgH,
                overflow: 'hidden',
                backgroundColor:
                  downloadAs === 'csv' ? '#fff' : colors.background,
              }}
            >
              <ChartDisplay
                key={downloadAs}
                height={imgH}
                noHeader
                chart={{
                  ...chartDownload,
                  ...chartDownloadConfig,
                }}
                drillDownDisabled
              />
            </div>
          </div>
        </Col>
        <Col span={6}>
          <Form
            layout="vertical"
            css={{
              '.ant-input-number': {
                width: '100%',
              },
            }}
          >
            <Form.Item label="Download as" required>
              <Select
                value={downloadAs}
                onChange={(val) => {
                  setDownloadAs(val);
                  setImgW(DEFAULT_CHART_WIDTH);
                  setImgH(DEFAULT_CHART_HEIGHT);
                }}
              >
                {!imageDownloadDisabled && [
                  <Option key="png" value="png">
                    PNG image (.png)
                  </Option>,
                  <Option key="svg" value="svg">
                    SVG image (.svg)
                  </Option>,
                ]}
                <Option value="csv">Comma-separated values (.csv)</Option>
              </Select>
            </Form.Item>
            {downloadAs === 'png' && (
              <>
                <Form.Item label="Image width (px)" required>
                  <InputNumber value={imgW} onChange={(val) => setImgW(val)} />
                </Form.Item>
                <Form.Item label="Image height (px)" required>
                  <InputNumber value={imgH} onChange={(val) => setImgH(val)} />
                </Form.Item>
              </>
            )}
            <Form.Item>
              <Button block type="primary" onClick={onDownload}>
                {downloadAs === 'csv' ? (
                  <CSVLink filename={`${defaultTitle}.csv`} data={data}>
                    Download
                  </CSVLink>
                ) : (
                  'Download'
                )}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default connect(mapStateToProps)(ChartDownloadModal);
