const initialState = {};

export default function drilldown(state = initialState, action) {
    switch (action.type) {
        case 'SET_DRILLDOWN':
            return action.drilldown;

        case 'RESET_DRILLDOWN':
            return initialState;

        default:
            return state;
    }
}
