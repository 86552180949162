/** @jsxImportSource @emotion/react */
import { Row, Col, Typography, Divider } from 'antd';
import { NavMenu, NavMenuRoute } from '@otso/auth-wrapper';

import { colors, preset } from 'styles';
import { Container } from 'components';

type Props = {
  title?: string | React.ReactNode;
  routes: NavMenuRoute[];
};

export const SubHeader: React.FC<Props> = ({ title, routes }) => {
  return (
    <div
      className="sub-header"
      css={{
        '.ant-menu': {
          background: 'transparent',
          lineHeight: `${preset.spacing(4)}px`,
        },
      }}
    >
      <Container>
        <Row
          gutter={preset.spacing(2)}
          wrap={false}
          align="middle"
          css={{ height: preset.subHeaderHeight }}
        >
          <Col>
            {typeof title === 'string' ? (
              <Typography.Text strong css={{ fontSize: '1rem' }}>
                {title}
              </Typography.Text>
            ) : (
              title
            )}
          </Col>
          <Col>
            <Divider type="vertical" css={{ borderColor: colors.lightGrey }} />
          </Col>
          <Col flex="auto">
            <NavMenu routes={routes} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
