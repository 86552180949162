/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { Button, Input, message, Modal, Tooltip } from 'antd';
import {
  PlusOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import { colors, preset } from 'styles';
import { postApi, deleteApi, putApi, fetchApiErrorMessage } from '@modules/api';
import useCheckPermission from '@modules/hooks/useCheckPermission';

const mapStateToProps = (state) => {
  return {
    dashboardPages: state.dashboard.pages,
    currentPageId: state.dashboard.currentPageId,
  };
};

const DashboardPageTabs = ({
  className,
  // redux state
  dashboardPages,
  currentPageId,
  // redux dipatch
  dispatch,
}) => {
  const { allIds: dashboardPageIds, byId: dashboardPageById } = dashboardPages;

  const { slug: projectSlug, dashboardSlug } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [editingPageId, setEditingPageId] = useState();
  const [isAddPageLoading, setAddPageLoading] = useState(false);

  const canEditDashboard = useCheckPermission(['insight.manageDashboards']);

  // Set currentPageId from URL
  useEffect(() => {
    let pageId;
    const { page: pageIndexStr, pageId: pageIdFromUrl } = queryString.parse(
      location.search
    );

    if (dashboardPageIds.length > 0) {
      // Check if has pageIndex from url
      if (pageIndexStr) {
        const pageIndexFromUrl = Number(pageIndexStr);
        // Check if pageIndex in range
        if (dashboardPageIds[pageIndexFromUrl - 1]) {
          pageId = dashboardPageIds[pageIndexFromUrl - 1];
        } else {
          history.push({
            search: queryString.stringify({ page: 1 }),
          });
        }
      } else {
        [pageId] = dashboardPageIds;
      }

      if (
        pageIdFromUrl &&
        dashboardPageIds.find(
          (dashboardPageId) => dashboardPageId === Number(pageIdFromUrl)
        )
      ) {
        pageId = Number(pageIdFromUrl);
      }
    }

    dispatch({ type: 'SET_DASHBOARD_CURRENT_PAGE_ID', pageId });
  }, [location.search, dashboardPageIds, history, dispatch]);

  // Add Page
  const addPage = async () => {
    const newPageIndex = dashboardPageIds.length + 1;

    setAddPageLoading(true);

    try {
      const res = await postApi(
        `projects/${projectSlug}/dashboards/${dashboardSlug}/pages`,
        { title: `Page ${newPageIndex}` }
      );

      const { id, title } = res.data;
      dispatch({ type: 'ADD_DASHBOARD_PAGE', pageId: id, title });
      history.push({
        search: queryString.stringify({ page: newPageIndex }),
      });

      message.success('New Page Added');
    } catch (error) {
      message.error(fetchApiErrorMessage(error, 'Unable to Add Page'));
    }

    setAddPageLoading(false);
  };

  // Delete Page
  const deletePage = (pageId) => {
    const deleteReq = async () => {
      try {
        await deleteApi(
          `projects/${projectSlug}/dashboards/${dashboardSlug}/pages/${pageId}`
        );

        // Switch to the previous page
        const deletedPageIndex = dashboardPageIds.indexOf(pageId);
        const newCurrentPageIndex =
          deletedPageIndex === 0 ? 0 : deletedPageIndex - 1;
        history.push({
          search: queryString.stringify({ page: newCurrentPageIndex + 1 }),
        });

        dispatch({ type: 'DELETE_DASHBOARD_PAGE', pageId });
        message.success('Page Deleted');
      } catch (error) {
        message.error(fetchApiErrorMessage(error, 'Unable to Delete Chart'));
      }
    };

    Modal.confirm({
      title: 'Delete this Page?',
      icon: <ExclamationCircleOutlined />,
      content: 'Charts in this page will also be removed from the dashboard',
      okText: 'Delete',
      okType: 'danger',
      onOk: () => deleteReq(),
    });
  };

  // Save page name change
  const savePageTitle = async (newTitle, targetPageId) => {
    const newPages = dashboardPageIds.map((pageId) =>
      pageId === targetPageId
        ? { id: pageId, title: newTitle }
        : dashboardPageById[pageId]
    );

    try {
      await putApi(
        `projects/${projectSlug}/dashboards/${dashboardSlug}/pages`,
        { pages: newPages }
      );

      dispatch({
        type: 'SET_DASHBOARD_PAGE_TITLE',
        pageId: targetPageId,
        title: newTitle,
      });
      message.success('Page Title Saved');
    } catch (error) {
      message.error(fetchApiErrorMessage(error, 'Unable to Save Page Title'));
    }

    setEditingPageId();
  };

  const onFinishEditing = (newVal, currentVal, pageId) => {
    if (newVal !== currentVal) {
      const pageTitle = newVal && newVal.trim() ? newVal : '(untitled)';
      savePageTitle(pageTitle, pageId);
    } else {
      setEditingPageId();
    }
  };

  return (
    <div
      className={className}
      css={{
        position: 'fixed',
        zIndex: 999,
        bottom: 0,
        left: 0,
        right: 0,
        height: 32,
        backgroundColor: '#000',
        paddingRight: preset.spacing(5),
        display: 'flex',
        button: {
          color: '#fff',
          borderRadius: 0,
          borderColor: 'transparent !important',
          '&.ant-btn-default': {
            backgroundColor: '#000',
          },
        },
      }}
    >
      {canEditDashboard && (
        <Tooltip placement="topLeft" title="New Page">
          <Button
            icon={<PlusOutlined />}
            loading={isAddPageLoading}
            onClick={() => addPage()}
            css={{
              width: preset.spacing(5),
              background: 'transparent !important',
              fontWeight: 'bolder',
              fontSize: 18,
            }}
          />
        </Tooltip>
      )}
      <div
        css={{
          flex: 1,
          display: 'flex',
          overflowX: 'auto',
        }}
      >
        {dashboardPageIds.map((pageId, index) => (
          <div
            key={pageId}
            css={{
              position: 'relative',
              display: 'flex',
            }}
          >
            {editingPageId === pageId ? (
              <Input
                autoFocus
                placeholder="Please Enter Page Name"
                defaultValue={dashboardPageById[pageId].title}
                onFocus={(e) => e.target.select()}
                onPressEnter={(e) =>
                  onFinishEditing(
                    e.target.value,
                    dashboardPageById[pageId].title,
                    pageId
                  )
                }
                onBlur={(e) =>
                  onFinishEditing(
                    e.target.value,
                    dashboardPageById[pageId].title,
                    pageId
                  )
                }
                css={{
                  backgroundColor: colors.primary,
                  color: colors.white,
                  borderColor: colors.primary,
                  borderRadius: 0,
                  fontStyle: 'italic',
                }}
              />
            ) : (
              <>
                <Tooltip
                  mouseEnterDelay={0.75}
                  title={
                    canEditDashboard &&
                    pageId === currentPageId &&
                    'Double Click to Edit'
                  }
                >
                  <Button
                    type={pageId === currentPageId ? 'primary' : 'default'}
                    onClick={() =>
                      history.push({
                        search: queryString.stringify({ page: index + 1 }),
                      })
                    }
                    onDoubleClick={
                      canEditDashboard && pageId === currentPageId
                        ? () => setEditingPageId(pageId)
                        : null
                    }
                    css={{
                      paddingLeft: preset.spacing(3),
                      paddingRight:
                        pageId === currentPageId && dashboardPageIds.length > 1
                          ? preset.spacing(5)
                          : preset.spacing(3),
                    }}
                  >
                    {dashboardPageById[pageId].title}
                  </Button>
                </Tooltip>
                {pageId === currentPageId &&
                  dashboardPageIds.length > 1 &&
                  canEditDashboard && (
                    <Tooltip title="Delete Page">
                      <Button
                        type="primary"
                        icon={<CloseOutlined />}
                        onClick={() => deletePage(pageId)}
                        css={{
                          position: 'absolute',
                          right: 0,
                          top: 0,
                          bottom: 0,
                          fontSize: 12,
                        }}
                      />
                    </Tooltip>
                  )}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(DashboardPageTabs);
