import Facebook from '@components/SocialEmbeds/Facebook';
import Twitter from '@components/SocialEmbeds/Twitter';
import Instagram from '@components/SocialEmbeds/Instagram';
import Youtube from '@components/SocialEmbeds/Youtube';
import Reddit from '@components/SocialEmbeds/Reddit';

const SocialPost = (props) => {
  const { link, row: document, embedMaxWidth } = props;

  if (link) {
    const source = new URL(link);
    const { hostname } = source;

    if (hostname.includes('facebook')) {
      return <Facebook link={link} embedMaxWidth={embedMaxWidth} />;
    }

    if (hostname.includes('twitter')) {
      return <Twitter link={link} embedMaxWidth={embedMaxWidth} />;
    }

    if (hostname.includes('instagram')) {
      return <Instagram link={link} embedMaxWidth={embedMaxWidth} />;
    }

    if (hostname.includes('youtube')) {
      return <Youtube link={link} embedMaxWidth={embedMaxWidth} />;
    }

    if (hostname.includes('reddit')) {
      return <Reddit document={document} />;
    }
  }
  return null;
};

export default SocialPost;
