/** @jsxImportSource @emotion/react */
import { useMemo, useState } from 'react';
import {
  Tag,
  Space,
  Typography,
  Select,
  Tooltip,
  Divider,
  Input,
  Button,
} from 'antd';

import { colors, preset } from 'styles';
import { formatLabel, deepGet } from 'modules';
import { CustomTagProps } from 'rc-select/es/BaseSelect';

type DocLabelTagBaseProps = {
  name: string;
  displayName?: string;
  value: any;
  onChange?: (value: any) => void;
  isTooltipVisible?: boolean;
  isEditing: boolean;
  onEditFinish: () => void;
  options?: (string | number)[];
  extraOptions?: string[];
  onAddOption?: (extraLabel: string) => void;
};

const getTagColor = (name: string, value: any) => {
  const colorKey = name.includes('sentiment')
    ? 'sentiment'
    : name.includes('emotion')
    ? 'emotion'
    : null;
  return colorKey && deepGet(colors, [colorKey, value]);
};

const SelectableTag: React.FC<DocLabelTagBaseProps> = ({
  name,
  displayName,
  value,
  onChange,
  onEditFinish,
  options = [],
  extraOptions = [],
  onAddOption,
}) => {
  const [extraLabel, setExtraLabel] = useState<string>('');

  const onNewLabelAdd = () => {
    if (onAddOption && extraLabel) {
      onAddOption(extraLabel);
    }
    setExtraLabel('');
  };

  const labelOptions = useMemo(() => {
    return [...options, ...extraOptions].map((optionStr) => ({
      label: optionStr,
      value: optionStr,
    }));
  }, [options, extraOptions]);

  const menuWithAddOption = (menu: React.ReactElement) => {
    return (
      <div>
        {menu}
        <Divider css={{ margin: '4px 0' }} />
        <div css={{ padding: preset.spacing(1) }}>
          <Space>
            <Input
              size="small"
              value={extraLabel}
              onChange={(e) => setExtraLabel(e.target.value)}
              css={{ maxWidth: 120 }}
            />
            <Button type="link" size="small" onClick={onNewLabelAdd}>
              Add
            </Button>
          </Space>
        </div>
      </div>
    );
  };

  const renderTag = ({
    label: tagLabel,
    value: tagValue,
    closable,
    onClose,
  }: CustomTagProps) => {
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color={getTagColor(name, tagValue)}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
      >
        {tagLabel}
      </Tag>
    );
  };

  return (
    <Select
      autoFocus
      defaultOpen
      showSearch
      showArrow
      optionFilterProp="children"
      size="small"
      placeholder={`No ${displayName || formatLabel(name)}`}
      dropdownMatchSelectWidth={false}
      value={value}
      onChange={onChange}
      onDropdownVisibleChange={(open) => {
        if (!open) {
          onEditFinish();
        }
      }}
      dropdownRender={(menu) => (onAddOption ? menuWithAddOption(menu) : menu)}
      {...(Array.isArray(value)
        ? {
            mode: 'multiple',
            tagRender: renderTag,
            css: {
              minWidth: value.length === 0 ? 120 : 'auto',
            },
          }
        : {})}
    >
      {labelOptions.map((labelOption) => (
        <Select.Option value={labelOption.value} key={labelOption.value}>
          {formatLabel(labelOption.label)}
        </Select.Option>
      ))}
    </Select>
  );
};

const SingleTag: React.FC<DocLabelTagBaseProps> = (props) => {
  const {
    value,
    name,
    displayName,
    isEditing = false,
    isTooltipVisible = true,
  } = props;

  if (isEditing) {
    return <SelectableTag {...props} />;
  }

  if (!value) {
    return (
      <Tag css={{ margin: 0 }}>
        <Typography.Text type="secondary">
          {`No ${displayName || formatLabel(name)}`}
        </Typography.Text>
      </Tag>
    );
  }

  const tagColor = getTagColor(name, value);

  return (
    <Tooltip
      title={
        isTooltipVisible
          ? `${displayName || formatLabel(name)}: ${formatLabel(value)}`
          : ''
      }
    >
      <Tag {...(tagColor ? { color: tagColor } : {})} css={{ margin: 0 }}>
        {formatLabel(value)}
      </Tag>
    </Tooltip>
  );
};

const GroupTag: React.FC<DocLabelTagBaseProps> = (props) => {
  const {
    value = [],
    name,
    displayName,
    isEditing = false,
    isTooltipVisible = true,
  } = props;

  return isEditing ? (
    <SelectableTag {...props} />
  ) : value.length > 0 ? (
    <Tooltip
      title={
        isTooltipVisible
          ? `${displayName || formatLabel(name)}: ${value
              .map((val: any) => formatLabel(val))
              .join(', ')}`
          : ''
      }
    >
      <Space size={0} wrap>
        {value.map((val: any) => (
          <SingleTag
            key={val}
            {...props}
            value={val}
            isTooltipVisible={false}
          />
        ))}
      </Space>
    </Tooltip>
  ) : (
    <SingleTag {...props} value={null} />
  );
};

type DocLabelTagProps = Omit<
  DocLabelTagBaseProps,
  'isEditing' | 'onEditFinish'
> & {
  editable?: boolean;
  className?: string;
};

const DocLabelTag: React.FC<DocLabelTagProps> = ({
  value,
  editable = false,
  className,
  ...props
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const onDocLabelTagClick = () => {
    if (editable && !isEditing) {
      setIsEditing(true);
    }
  };

  const childProps = {
    ...props,
    value,
    isEditing,
    onEditFinish: () => setIsEditing(false),
  };

  if (!childProps.value) childProps.value = [];
  if (!Array.isArray(childProps.value)) childProps.value = [childProps.value];

  return (
    <div
      role="presentation"
      onClick={(e) => {
        e.stopPropagation();
        onDocLabelTagClick();
      }}
      className={className}
      css={{
        textAlign: 'left',
        '.ant-tag': {
          cursor: editable ? 'pointer' : 'auto',
        },
      }}
    >
      <GroupTag {...childProps} />
      {/* {Array.isArray(value) ? (
        <GroupTag {...childProps} />
      ) : (
        <SingleTag {...childProps} />
      )} */}
    </div>
  );
};

export default DocLabelTag;
