export const initialState = {
    query: {
        page: 1,
        q: undefined,
    },
    alert: {
        config: {},
        alertShares: [],
    },
};

export const alertsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_QUERY':
            return {
                ...state,
                query: {
                    ...state.query,
                    ...action.query,
                },
            };

        case 'SET_ALERT':
            return {
                ...state,
                alert: {
                    ...state.alert,
                    ...action.alert,
                },
            };

        case 'SET_ALERT_VALUE':
            return {
                ...state,
                alert: {
                    ...state.alert,
                    [action.key]: action.childKey ? {
                        ...state.alert[action.key],
                        [action.childKey]: action.value,
                    } : action.value,
                },
            };

        case 'RESET_ALERT':
            return {
                ...state,
                alert: initialState.alert,
            };

        default:
            return state;
    }
};
