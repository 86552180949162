/** @jsxImportSource @emotion/react */

// Import libraries
import { useState } from 'react';

// Import Ant Design components
import {
  Card,
  Select,
  Form,
  Switch,
  Row,
  Col,
  Spin,
  Statistic,
  Divider,
} from 'antd';
import { preset } from 'styles';
import { useIsSuperAdminRoute } from 'modules';

import insertEmptyDate from '@modules/chart/insertEmptyDate';

import UsageChart from './UsageChart';


// Import Option and Item components
const { Option } = Select;
const { Item: FormItem } = Form;

const DatasetUsageContainer = (props) => {
  // Initialization
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [datasetId] = useState(props.datasetId);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [datasetUsage, setDatasetUsage] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(true);
  const [dateTruncValue, setDateTruncValue] = useState('day');
  const [showEmptyDate, setShowEmptyDate] = useState(true);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isGlobal = useIsSuperAdminRoute();

  const DateTruncSelector = (
    <Form layout="inline">
      <FormItem label="Show Empty Date">
        <Switch
          checked={showEmptyDate}
          onChange={(checked) => setShowEmptyDate(checked)}
        />
      </FormItem>
      <FormItem>
        <Select
          style={{ width: 120 }}
          onChange={(value) => setDateTruncValue(value)}
          value={dateTruncValue}
        >
          <Option value="day">Day</Option>
          <Option value="week">Week</Option>
          <Option value="month">Month</Option>
          <Option value="year">Year</Option>
        </Select>
      </FormItem>
    </Form>
  );

  const chartData = showEmptyDate
    ? insertEmptyDate(datasetUsage.count, 'ingestedAt', {
        timeInterval: dateTruncValue,
        mask: 'YYYY-MM-DD',
        endDate: 'now',
        showZero: true,
      })
    : datasetUsage.count;

  return (
    <Card title="Dataset Usage" extra={DateTruncSelector}>
      <Spin spinning={loading || !datasetUsage}>
        <UsageChart data={chartData} />
        <Divider />
        <Row gutter={preset.spacing(3)}>
          <Col span={12}>
            <Statistic
              title="Total Enriched Dataset Usage"
              value={
                datasetUsage &&
                datasetUsage.total &&
                datasetUsage.total.enriched
              }
            />
          </Col>
          <Col span={12}>
            <Statistic
              title="Total Unenriched Dataset Usage"
              value={
                datasetUsage &&
                datasetUsage.total &&
                datasetUsage.total.unenriched
              }
            />
          </Col>
        </Row>
      </Spin>
    </Card>
  );
};

export default DatasetUsageContainer;
