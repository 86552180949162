import moment from 'moment-timezone';

import { TIME_INTERVALS } from 'constant';
import { formatDate } from '../date/formatDate';
import deepGet from '../deepGet';

const getMonthOfYear = (month) => {
  const monthStr = month < 10 ? `0${month}` : month;
  return moment(`2000-${monthStr}-01`).format('MMM');
};

const getDayOfWeek = (day) => {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  return days[day];
};

const formatDimensionLabel = (value, config = {}) => {
  const {
    timeInterval,
    labelConfig = {},
    dimensionIndex = 0,
    dimensionKey,
  } = config;

  if (!timeInterval) {
    if (value === 'No Data' && deepGet(labelConfig, ['nullDimensionDisplay'])) {
      const { nullDimensionDisplay } = labelConfig;

      if (Array.isArray(nullDimensionDisplay)) {
        return nullDimensionDisplay[dimensionIndex] || 'No Data';
      }
      if (dimensionKey) {
        return nullDimensionDisplay[dimensionKey] || 'No Data';
      }
      if (Object.values(nullDimensionDisplay)[0]) {
        return Object.values(nullDimensionDisplay)[0];
      }
      return value;
    }

    if (deepGet(labelConfig, ['alias'])) {
      const { alias } = labelConfig;
      if (dimensionKey) {
        return deepGet(alias, [dimensionKey, value]) || value;
      }
      if (Object.values(alias)[0]) {
        return deepGet(Object.values(alias)[0], [value]) || value;
      }
      return value;
    }

    return value;
  }

  if (TIME_INTERVALS.truncate.find((obj) => obj.value === timeInterval)) {
    return formatDate(value, timeInterval);
  }

  if (TIME_INTERVALS.extract.find((obj) => obj.value === timeInterval)) {
    switch (timeInterval) {
      case 'qoy':
        return `Q${value}`;
      case 'moy':
        return getMonthOfYear(value);
      case 'woy':
        return `Week ${value}`;
      case 'doy':
      case 'dom':
        return `Day ${value}`;
      case 'dow':
        return getDayOfWeek(value);
      default:
        return `${value}`;
    }
  }

  return value;
};

export default formatDimensionLabel;
