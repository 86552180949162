/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';

import { Modal, Form, Button, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { preset } from 'styles';

// Compare Setup Form Components
import Datasets from './Datasets';
import Metrics from './Metrics';
import Filters from './Filters';

const CompareModal = ({
  // modal open
  open = false,
  // compare value
  value: compare,
  displayValue: compareDisplay,
  dispatch,
  // other values for compare queryObj fields
  projectDatasets = [],
  categorisedSchemaFields,
  sharedSchemaFields,
  filterOptions,
  formatRequiredAttributes,
}) => {
  const {
    compareType,
    dataset: selectedDatasetIds = [],
    metrics = [],
    dimensions = [],
    filters = [],
    timeFilter,
  } = compareDisplay;

  const nonDimensionCompare =
    !Array.isArray(dimensions) || dimensions.length === 0;

  useEffect(() => {
    // Clear compare value when compare is not available
    return () => {
      dispatch({
        type: 'SET_QUERYOBJ_VALUE',
        key: 'compare',
        value: null,
      });
    };
  }, [dispatch]);

  return (
    <Modal
      title="Compare To"
      visible={open}
      okText={compare ? 'Save' : 'Add'}
      onCancel={() => dispatch({ type: 'ON_COMPARE_MODAL_CANCEL' })}
      onOk={() => dispatch({ type: 'ON_COMPARE_SAVE' })}
    >
      <Form
        layout="vertical"
        css={{
          '.ant-form-item .ant-collapse .ant-form-item': {
            marginBottom: preset.spacing(1),
          },
        }}
      >
        <Form.Item
          required
          label="Compare Type"
          help={
            compareType === 'previous_period' || compareType === 'previous_year'
              ? 'Only works when having a valid date filter applied'
              : ''
          }
        >
          <Select
            value={compareType || 'custom'}
            onChange={(value) =>
              dispatch({
                type: 'SET_COMPARE_QUERYOBJ_VALUE',
                key: 'compareType',
                value,
              })
            }
          >
            <Select.Option value="previous_period">
              Compare to previous period
            </Select.Option>
            <Select.Option value="previous_year">
              Compare to previous year
            </Select.Option>
            <Select.Option value="custom">Custom</Select.Option>
          </Select>
        </Form.Item>

        {(!compareType || compareType === 'custom') && (
          <>
            {nonDimensionCompare && (
              <Form.Item label="Datasets" required>
                <Datasets
                  value={selectedDatasetIds}
                  projectDatasets={projectDatasets}
                  onChange={(value) =>
                    dispatch({
                      type: 'SET_COMPARE_QUERYOBJ_VALUE',
                      key: 'dataset',
                      value,
                    })
                  }
                />
              </Form.Item>
            )}

            {nonDimensionCompare && (
              <Form.Item label="Metrics" required>
                <Metrics
                  value={metrics}
                  onChange={(value, itemKey, index) =>
                    dispatch({
                      type: 'SET_COMPARE_QUERYOBJ_ARRAY_VALUE',
                      key: 'metrics',
                      index,
                      itemKey,
                      value,
                    })
                  }
                  attributeOptions={categorisedSchemaFields}
                  // handle metric as field change
                  // No need for compare metrics field since there won't be any Order By fields
                  onAsFieldChange={() => null}
                  // for metric filter config
                  sharedSchemaFields={sharedSchemaFields}
                  filterOptions={filterOptions}
                  formatRequiredAttributes={formatRequiredAttributes}
                  datasetIds={selectedDatasetIds}
                />
                <Button
                  block
                  icon={<PlusOutlined />}
                  type="dashed"
                  disabled={metrics.length >= 1}
                  onClick={() =>
                    dispatch({
                      type: 'ADD_COMPARE_QUERYOBJ_ARRAY_VALUE',
                      key: 'metrics',
                      value: {},
                    })
                  }
                >
                  Add Metric
                </Button>
              </Form.Item>
            )}

            <Form.Item
              label={nonDimensionCompare ? 'Filters' : ''}
              css={{ marginBottom: 0 }}
            >
              <Filters
                value={filters}
                onChange={(value, itemKey, index) =>
                  dispatch({
                    type: 'SET_COMPARE_QUERYOBJ_ARRAY_VALUE',
                    key: 'filters',
                    index,
                    itemKey,
                    value,
                  })
                }
                onRemove={(index) =>
                  dispatch({
                    type: 'REMOVE_COMPARE_QUERYOBJ_ARRAY_VALUE',
                    key: 'filters',
                    index,
                  })
                }
                timeFilter={timeFilter}
                onTimeFilterChange={(value) =>
                  dispatch({
                    type: 'SET_COMPARE_QUERYOBJ_VALUE',
                    key: 'timeFilter',
                    value,
                  })
                }
                sharedSchemaFields={sharedSchemaFields}
                attributeOptions={categorisedSchemaFields}
                filterOptions={filterOptions}
                formatRequiredAttributes={formatRequiredAttributes}
                datasetIds={selectedDatasetIds}
              />
              <Button
                block
                icon={<PlusOutlined />}
                type="dashed"
                onClick={() =>
                  dispatch({
                    type: 'ADD_COMPARE_QUERYOBJ_ARRAY_VALUE',
                    key: 'filters',
                    value: {},
                  })
                }
              >
                Add Filter
              </Button>
            </Form.Item>
          </>
        )}

        {!!compare && (
          <Button
            type="danger"
            onClick={() => dispatch({ type: 'ON_COMPARE_REMOVE' })}
            css={{
              position: 'absolute',
              bottom: 10,
              left: 16,
            }}
          >
            Remove
          </Button>
        )}
      </Form>
    </Modal>
  );
};

export default CompareModal;
