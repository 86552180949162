/** @jsxImportSource @emotion/react */
import { preset } from 'styles';

const TitleSubTitle = ({ title, subtitle }) => {
  return (
    <div>
      <h1
        css={{
          marginBottom: 0,
          span: {
            paddingLeft: preset.spacing(2),
            fontSize: 14,
            color: '#595959',
            fontWeight: 400,
          },
        }}
      >
        {title}
        <span>{subtitle}</span>
      </h1>
    </div>
  );
};

export default TitleSubTitle;
