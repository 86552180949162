/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  Modal,
  Form,
  Radio,
  Input,
  message,
  Alert,
  Skeleton,
  Checkbox,
} from 'antd';

import { preset } from 'styles';
import { postApi, fetchApiErrorMessage } from '@modules/api';

const DOC_FLAG_CATEGORIES = [
  'Irrelevant',
  'Contains Sensitive Information',
  'Adult-Oriented Content',
  'Other',
];

const DocumentFlagModal = ({ flaggedDocument, onCancel }) => {
  const { slug: projectSlug } = useParams();

  const [isDeletingDocument, setIsDeletingDocument] = useState(false);
  const [isCountingDuplicates, setIsCountingDuplicates] = useState(false);
  const [duplicatedDocsLength, setDuplicatedDocsLength] = useState(0);

  const [form] = Form.useForm();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const countDuplicatedDocuments = async (document) => {
      setIsCountingDuplicates(true);
      try {
        // const { data } = await getApi(
        //   `datasets/${document.datasetId}/duplicated-documents/count`,
        //   { documentId: document.otso_doc_id }
        // );
        const data = [];
        setDuplicatedDocsLength(data.length);
        form.setFieldsValue({ shouldDeleteDuplicates: data.length > 0 });
      } catch (error) {
        message.error(fetchApiErrorMessage(error));
      }
      setIsCountingDuplicates(false);
    };

    if (flaggedDocument) {
      countDuplicatedDocuments(flaggedDocument);
    }
  }, [flaggedDocument, form]);

  const onModalClose = () => {
    form.resetFields();
    setIsDeletingDocument(false);
    setIsCountingDuplicates(false);
    setDuplicatedDocsLength(0);
    onCancel();
  };

  const onCategoryChange = (e) => {
    const newVal = e.target.value;
    if (newVal !== 'other') {
      form.setFieldsValue({
        comment: null,
      });
    }
  };

  const onHide = () => {
    form.validateFields().then(async (values) => {
      setIsDeletingDocument(true);
      try {
        await postApi(`projects/${projectSlug}/document-flag`, {
          category: values.category,
          comment: values.comment,
          shouldDeleteDuplicates: !!values.shouldDeleteDuplicates,
          documentId: flaggedDocument.otso_doc_id,
          datasetId: flaggedDocument.datasetId,
        });

        onModalClose();

        const documentCard = document.getElementById(
          `explore-card-${flaggedDocument.id}`
        );
        documentCard.style.display = 'none';

        message.success('Document has been deleted');
      } catch (error) {
        message.error(fetchApiErrorMessage(error));
      }
      setIsDeletingDocument(false);
    });
  };

  return (
    <Modal
      title="Delete Document"
      visible={!!flaggedDocument}
      onCancel={onModalClose}
      onOk={onHide}
      okText="Delete"
      okButtonProps={{
        type: 'danger',
        loading: isDeletingDocument,
      }}
    >
      <Skeleton active loading={isCountingDuplicates}>
        <Form form={form} layout="vertical">
          <Form.Item
            name="category"
            label="Reason"
            rules={[{ required: true, message: 'Please input the reason.' }]}
          >
            <Radio.Group onChange={onCategoryChange}>
              {DOC_FLAG_CATEGORIES.map((flagCategory) => (
                <Radio
                  key={flagCategory}
                  value={flagCategory}
                  css={{
                    display: 'block',
                    paddingTop: preset.spacing(1),
                    paddingBottom: preset.spacing(1),
                  }}
                >
                  {flagCategory}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prev, current) => prev.category !== current.category}
          >
            {({ getFieldValue }) =>
              getFieldValue('category') === 'Other' && (
                <Form.Item name="comment" label="Comments">
                  <Input />
                </Form.Item>
              )
            }
          </Form.Item>
          {duplicatedDocsLength > 0 && (
            <Form.Item name="shouldDeleteDuplicates" valuePropName="checked">
              <Checkbox>
                {`This document has ${duplicatedDocsLength} number of duplicate documents. Delete all duplicate documents as well.`}
              </Checkbox>
            </Form.Item>
          )}
        </Form>

        <Alert
          showIcon
          type="info"
          message={
            <span>
              This will remove the document from this dataset — and affect all
              projects, dashboards and charts that would ordinarily show this.
              <br />
              <br />
              This delete function may be reversible if used in error. Please
              contact the support team as early as possible if you need to
              recover a deleted document.
              <br />
              <br />
              This delete function does not trigger a secure deletion from our
              server. If you are attempting to remove sensitive information
              using this system, please contact the support team so that they
              can expedite a secure delete, and confirm that the data has been
              removed from our servers after secure deletion.
            </span>
          }
          css={{ marginTop: preset.spacing(3) }}
        />
      </Skeleton>
    </Modal>
  );
};

export default DocumentFlagModal;
