/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import truncate from 'lodash/truncate';

import { formatDate } from '@modules/date/formatDate';
import { convertToURLParams } from '@modules/queryStringConverter';
import { formatDateInTZ } from 'modules';
import { getDatsetSchemaField } from './helper';
import DocSourceLogo from '../DocSourceLogo';

const DrilldownCard = ({
  projectSlug,
  row = {},
  exploreFilters = { filters: [], datasetIds: [] },
  datasets = [],
  onTrack,
}) => {
  const {
    sourceFieldName,
    titleFieldName,
    dateFieldName,
    verbatimFieldName,
    authorFieldName,
    primaryFieldName,
  } = useMemo(() => {
    const currentDataset = datasets.find(
      (dataset) => dataset.id === row.datasetId
    );
    return getDatsetSchemaField(currentDataset);
  }, [row.datasetId, datasets]);

  const urlParams = useMemo(
    () =>
      convertToURLParams({
        ...exploreFilters,
        docId: row.id,
      }),
    [exploreFilters, row.id]
  );

  const primaryFieldValue = primaryFieldName
    ? row[primaryFieldName]
    : titleFieldName
    ? row[titleFieldName]
    : authorFieldName && row[authorFieldName];

  const verbatimText =
    row.otso_doc_highlight || row.otso_doc_body || row[verbatimFieldName];

  return (
    <div role="presentation" onClick={() => onTrack()}>
      <a
        href={`/projects/${projectSlug}/explore?${urlParams}`}
        target="_blank"
        rel="noopener noreferrer"
        css={styles.card}
      >
        {row[sourceFieldName] && (
          <DocSourceLogo
            source={row[sourceFieldName]}
            css={styles.cardAvatar}
          />
        )}
        <div css={styles.cardMain}>
          <div css={styles.cardHeader}>
            {(primaryFieldValue || typeof primaryFieldValue === 'number') && (
              <div css={styles.postTitle}>
                {truncate(primaryFieldValue.toString(), {
                  length: 50,
                  separator: /,? +/,
                })}
              </div>
            )}
            <div css={styles.postTime}>
              {formatDateInTZ(row[dateFieldName], 'day', '/')}
            </div>
          </div>
          <div css={styles.postText}>
            {truncate(verbatimText, {
              length: 100,
              separator: /,? +/,
            })}
          </div>
        </div>
      </a>
    </div>
  );
};

const styles = {
  card: {
    padding: '6px 8px',
    display: 'flex',
    alignItems: 'flex-start',
    fontSize: 13,
    transition: 'all 0.3s',
    cursor: 'pointer',

    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 4px',
    },
  },
  cardAvatar: {
    height: 18,
    marginRight: 10,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
  },
  cardMain: {
    flex: 1,
    minWidth: 0,
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: 1.8,
    fontSize: 12,
  },
  postTitle: {
    color: '#262626',
    fontWeight: 600,
    marginRight: 12,
    maxWidth: 120,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  postTime: {
    color: '#8c8c8c',
  },
  postText: {
    color: '#262626',
    lineHeight: '1.4',
    wordBreak: 'break-all',
  },
};

export default DrilldownCard;
