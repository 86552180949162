/** @jsxImportSource @emotion/react */
import { useState, useMemo, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import download from 'downloadjs';

import {
  Modal,
  message,
  Row,
  Col,
  Form,
  Button,
  Input,
  Select,
  Spin,
} from 'antd';

import { trackEvent } from 'analytics';
import { colors, preset } from 'styles';
import { getPDFApi, fetchApiErrorMessage } from '@modules/api';
import { formatEmptyObject } from '@modules/items';
import RichTextEditor from '@components/RichTextEditor';

const { Option } = Select;

const mapStateToProps = (state) => {
  return {
    isPDFModalOpen: state.dashboard.isPDFModalOpen,
    pdfPageTitle: state.dashboard.pdfPageTitle,
    pdfSummary: state.dashboard.pdfSummary,
    exportedFilters: state.dashboard.exportedFilters,
    selectedSavedFilterSlug: state.dashboard.selectedSavedFilterSlug,
  };
};

const DashboardPDFModal = ({
  // redux state
  isPDFModalOpen,
  pdfPageTitle,
  pdfSummary,
  exportedFilters,
  selectedSavedFilterSlug,
  // redux dispatch
  dispatch,
}) => {
  const { slug: projectSlug, dashboardSlug } = useParams();
  const location = useLocation();

  const editorRef = useRef();

  const [isPDFPreviewLoading, setIsPDFPreviewLoading] = useState(false);
  const [pdfBuffer, setPDFBuffer] = useState();
  const [pdfWidth, setPDFWidth] = useState(1440);

  const { page: pageIndexStr = 1 } = queryString.parse(location.search);

  const chartsGridContainer = document.querySelector(
    '#dashboard-charts-container .react-grid-layout'
  );
  const chartsGridContainerHeight = chartsGridContainer
    ? chartsGridContainer.clientHeight
    : 0;

  useEffect(() => {
    const fetchPDF = async () => {
      try {
        setIsPDFPreviewLoading(true);

        const { data: pdfBufferData } = await getPDFApi(
          `projects/${projectSlug}/dashboards/${dashboardSlug}/page/${pageIndexStr}/pdf-download`,
          {
            width: pdfWidth,
            title: pdfPageTitle,
            summary: pdfSummary,
            ...(selectedSavedFilterSlug && selectedSavedFilterSlug !== 'custom'
              ? {
                  filterSlug: selectedSavedFilterSlug,
                }
              : {
                  filters: Array.isArray(exportedFilters.filters)
                    ? exportedFilters.filters
                    : [],
                  timeFilter:
                    exportedFilters.timeFilter &&
                    formatEmptyObject(exportedFilters.timeFilter)
                      ? formatEmptyObject(exportedFilters.timeFilter)
                      : null,
                }),
          }
        );

        setPDFBuffer(pdfBufferData);
        setIsPDFPreviewLoading(false);
      } catch (error) {
        setIsPDFPreviewLoading(false);
        message.error(fetchApiErrorMessage(error));
      }
    };

    if (isPDFModalOpen && chartsGridContainerHeight > 0 && exportedFilters) {
      fetchPDF();
    }
  }, [
    dashboardSlug,
    isPDFModalOpen,
    pageIndexStr,
    pdfPageTitle,
    pdfSummary,
    pdfWidth,
    projectSlug,
    chartsGridContainerHeight,
    exportedFilters,
    selectedSavedFilterSlug,
  ]);

  const pdfIframeSrc = useMemo(() => {
    if (pdfBuffer) {
      const pdfURL = URL.createObjectURL(
        new window.Blob([pdfBuffer], { type: 'application/pdf' })
      );
      return pdfURL;
    }
    return null;
  }, [pdfBuffer]);

  const onPDFSummaryApply = () => {
    if (editorRef.current) {
      const richTextHTML = editorRef.current.getHTML();
      dispatch({
        type: 'ON_DASHBOARD_DOWNLOAD_PDF_FORM_CHANGE',
        key: 'pdfSummary',
        value: richTextHTML,
      });
    }
  };

  const downloadPdf = () => {
    trackEvent('Dashboard Download PDF', { dashboardSlug, pdfPageTitle });
    download(pdfBuffer, `${pdfPageTitle}.pdf`, 'application/pdf');
  };

  const onClose = () => {
    setPDFBuffer();
    dispatch({ type: 'ON_DASHBOARD_DOWNLOAD_PDF_CANCEL' });
  };

  return (
    <Modal
      destroyOnClose
      title="Dashboard PDF"
      visible={isPDFModalOpen}
      width={preset.modalWidth.lg}
      onCancel={onClose}
      footer={null}
    >
      <Row gutter={preset.spacing(3)} type="flex">
        <Col span={15}>
          <Spin
            spinning={isPDFPreviewLoading}
            tip="Generating PDF... This may take a while."
          >
            <div
              css={{
                height: 600,
                overflow: 'hidden',
                border: preset.border,
                backgroundColor: colors.background,
                iframe: {
                  border: 'none',
                },
              }}
            >
              {pdfIframeSrc && (
                <iframe
                  title="Dashboard PDF Preview"
                  width="100%"
                  height="100%"
                  src={pdfIframeSrc}
                />
              )}
            </div>
          </Spin>
        </Col>

        <Col span={9}>
          <Form
            layout="vertical"
            css={{
              maxHeight: 600,
              overflowY: 'auto',
            }}
          >
            <Form.Item
              required
              label="PDF Header Title"
              extra="Press Enter to Apply"
            >
              <Input
                defaultValue={pdfPageTitle}
                onPressEnter={(e) =>
                  dispatch({
                    type: 'ON_DASHBOARD_DOWNLOAD_PDF_FORM_CHANGE',
                    key: 'pdfPageTitle',
                    value: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item
              required
              label="PDF Page Width (px)"
              css={{ display: 'none' }}
            >
              <Select value={pdfWidth} onChange={(val) => setPDFWidth(val)}>
                <Option value={1440}>Default - 1440</Option>
                <Option value={1920}>Large - 1920</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Summary">
              <RichTextEditor ref={editorRef} />
              <Button
                disabled={
                  chartsGridContainerHeight === 0 || isPDFPreviewLoading
                }
                onClick={onPDFSummaryApply}
                css={{
                  marginTop: 12,
                  marginBottom: 12,
                }}
              >
                Apply and Preview
              </Button>
            </Form.Item>
            <div
              css={{
                position: 'sticky',
                bottom: 0,
                backgroundColor: '#fff',
                paddingTop: preset.spacing(3),
              }}
            >
              <Button
                block
                type="primary"
                disabled={
                  chartsGridContainerHeight === 0 || isPDFPreviewLoading
                }
                onClick={downloadPdf}
              >
                Download
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default connect(mapStateToProps)(DashboardPDFModal);
