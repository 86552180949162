/** @jsxImportSource @emotion/react */
import { useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Typography, Dropdown, Menu, Tooltip, Badge } from 'antd';
import {
  MessageOutlined,
  MessageFilled,
  EllipsisOutlined,
} from '@ant-design/icons';

import { trackEvent } from 'analytics';
import { colors, preset } from 'styles';
import ChartTooltip from './ChartTooltip';

const ChartHeader = ({
  dashboardChartId,
  chartType,
  chart = {},
  onTitleChange,
  onDescriptionChange,
  actions = [],
  comments = [],
  commentsShowing = false,
  // redux dispatch for triggering drilldown
  dispatch,
}) => {
  const { title, description } = chart;

  const [editableDescription, setEditableDescription] = useState();

  useEffect(() => {
    setEditableDescription(description);
  }, [description]);

  const onActionsMenuClick = (actionName) => {
    if (actionName === 'Download As') {
      dispatch({
        type: 'SET_DOWNLOAD_CHART',
        chart,
      });
    } else if (actionName === 'Annotation') {
      trackEvent('Chart Annotation', {
        chartTitle: title,
        chartId: chart.id,
      });
      dispatch({
        type: 'SET_DASHBOARD_COMMENTS_EDITING_CHART',
        chart: {
          ...chart,
          dashboardChartId,
          comments,
        },
      });
    } else {
      const clickedAction = actions.find(
        (action) => action.name === actionName
      );
      if (clickedAction) {
        clickedAction.onClick();
        trackEvent(actionName, { chartTitle: title, chartId: chart.id });
      }
    }
  };

  const onShowCommentsClick = (e) => {
    if (e) {
      e.stopPropagation();
    }
    dispatch({
      type: 'TOGGLE_DASHBOARD_CHART_COMMENTS',
      dashboardChartId,
    });
  };

  const canShowComments = useMemo(() => {
    if (
      dashboardChartId &&
      [
        'bar_basic',
        'bar_grouped',
        'bar_stackedColumn',
        'double_axes',
        'line_basic',
        'line_series',
        'area_basic',
        'area_series',
      ].includes(chartType)
    ) {
      return true;
    }
    return false;
  }, [chartType, dashboardChartId]);

  const isRichTextWidget = chartType === 'rich_text';

  return (
    <div
      css={{
        height: preset.spacing(5),
        display: 'flex',
        alignItems: 'flex-start',
        ...(isRichTextWidget
          ? {
              position: 'absolute',
              top: preset.spacing(2),
              right: preset.spacing(3),
            }
          : {}),
      }}
    >
      <div
        css={{
          flex: onTitleChange ? 0.8 : 1,
          minWidth: 0,
          display: 'flex',
          visibility: isRichTextWidget ? 'hidden' : 'visible',
        }}
      >
        <ChartTooltip
          disabled={!!onDescriptionChange}
          chart={chart}
          onDescriptionEdit={(newDesc) => setEditableDescription(newDesc)}
        >
          <div
            css={{
              maxWidth: '100%',
              display: 'inline-flex',
              flexDirection: 'column',
            }}
          >
            <Typography.Text
              strong
              ellipsis
              editable={onTitleChange ? { onChange: onTitleChange } : false}
              css={{ lineHeight: description ? `${preset.spacing(2)}px` : 1.5 }}
            >
              {title}
            </Typography.Text>
            <Typography.Text
              type="secondary"
              ellipsis
              editable={
                onDescriptionChange ? { onChange: onDescriptionChange } : false
              }
              css={{ fontSize: 13 }}
            >
              {onDescriptionChange ? description || '' : editableDescription}
            </Typography.Text>
          </div>
        </ChartTooltip>
      </div>
      {canShowComments && actions.length > 0 && (
        <Tooltip
          title={`${commentsShowing ? 'Hide' : 'Show'} Chart Annotations`}
        >
          <Badge
            count={comments.length}
            overflowCount={10}
            onClick={() => onShowCommentsClick()}
            style={{ cursor: 'pointer' }}
          >
            {commentsShowing ? (
              <MessageFilled
                onClick={(e) => onShowCommentsClick(e)}
                css={{ color: colors.primary }}
              />
            ) : (
              <MessageOutlined onClick={(e) => onShowCommentsClick(e)} />
            )}
          </Badge>
        </Tooltip>
      )}
      {actions.length > 0 && (
        <Dropdown
          trigger={['click']}
          placement="bottomRight"
          overlay={
            <Menu onClick={({ key }) => onActionsMenuClick(key)}>
              {actions.map((action) =>
                action.name === 'Annotation' && !canShowComments ? null : (
                  <Menu.Item key={action.name}>{action.name}</Menu.Item>
                )
              )}
            </Menu>
          }
        >
          <EllipsisOutlined
            css={{ marginLeft: preset.spacing(2) }}
            data-testId="chart-ellipsis-menu"
          />
        </Dropdown>
      )}
    </div>
  );
};

export default connect()(ChartHeader);
