import mergeWith from 'lodash/mergeWith';
import cloneDeep from 'lodash/cloneDeep';

import { deepRemoveNullObjectValues } from '../items';

const generateChartGeneral = (generalData, overrideGeneral) => {
    // Clone generalData since mergeWith will mutate object
    const clonedGeneral = cloneDeep(generalData);
    // Override if key exists
    const mergedGeneral = mergeWith(clonedGeneral, overrideGeneral, obj => {
        if (obj === undefined) {
            return null;
        }
        return undefined;
    });
    // Remove keys with null value
    return deepRemoveNullObjectValues(mergedGeneral);
};

export default generateChartGeneral;
