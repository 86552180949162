/** @jsxImportSource @emotion/react */

import { Collapse, Row, Col, Form, Select } from 'antd';

import { preset } from 'styles';
import mapData from 'constant/geo/worldMapLowFidelity.json';
import StyledCollapse from '../../StyledCollapse';
import ColorPanel from './ColorPanel';

const { Panel } = Collapse;
const FormItem = Form.Item;
const { Option } = Select;

const GeoMapConfig = ({ chart, dispatch }) => {
  const { defaultConfig = {} } = chart;

  return (
    <StyledCollapse>
      <Panel key="geo" header="Geo">
        <Row gutter={preset.spacing(3)}>
          <Col span={12}>
            <FormItem label="Region" required>
              <Select
                showSearch
                optionFilterProp="children"
                value={defaultConfig.area}
                onChange={(value) =>
                  dispatch({
                    type: 'SET_CONFIG',
                    value,
                    key: 'area',
                  })
                }
              >
                <Option value="global">Global</Option>
                {mapData.features.map((country, index) => (
                  <Option
                    key={`${country.properties.name}${index + 1}`}
                    value={country.properties.name}
                  >
                    {country.properties.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
      </Panel>

      <ColorPanel capitaliseLabel chart={chart} dispatch={dispatch} />
    </StyledCollapse>
  );
};

export default GeoMapConfig;
