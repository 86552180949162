/** @jsxImportSource @emotion/react */
import { Select } from 'antd';

const { Option } = Select;

const Datasets = ({
  value,
  onChange,
  loading = false,
  projectDatasets = [],
}) => {
  return (
    <Select
      data-testId="new-chart-dataset-dropdown"
      showSearch
      mode="multiple"
      optionFilterProp="children"
      placeholder="Select Datasets"
      value={value}
      onChange={onChange}
      loading={loading}
    >
      {projectDatasets.map((datasetObj) => (
        <Option
          key={datasetObj.id}
          title={datasetObj.name}
          value={datasetObj.id}
        >
          {datasetObj.name}
        </Option>
      ))}
    </Select>
  );
};

export default Datasets;
