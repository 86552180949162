/** @jsxImportSource @emotion/react */

import SocialPost from './SocialPost';

const embedMaxWidth = Math.floor((window.innerWidth - 94) / 4 - 20);

const DocumentMedia = ({
    type,
    document,
    url,
}) => {
    switch (type) {
        case 'embed':
            return (
                <div css={{ maxWidth: embedMaxWidth, overflowX: 'auto' }}>
                    <SocialPost
                        link={url}
                        row={document}
                        embedMaxWidth={embedMaxWidth}
                    />
                </div>
            );
        case 'image':
            return (
                <a href={url} target="_blank" rel="noopener noreferrer">
                    <img src={url} alt={url} />
                </a>
            );
        default:
            return null;
    }
};

export default DocumentMedia;
