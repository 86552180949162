import { Filter, TimeFilter } from 'types';

type ExploreQueryConfig = {
  order?: 'asc' | 'desc';
  datasetIds?: string[];
  offset?: number;
  filters?: Filter[];
  timeFilter?: TimeFilter;
  limit?: number;
};

const getExploreQuery = (config: ExploreQueryConfig = {}) => {
  const {
    order = 'newest',
    datasetIds = [],
    offset,
    filters = [],
    timeFilter,
    limit = 2000,
  } = config;

  const query = {
    // datasets
    dataset: datasetIds,
    offset: offset || 0,
    includeDatasetId: true,
    // queryObj
    metrics: [],
    dimensions: [],
    filters,
    groupBy: [],
    orderBy: order,
    timeFilter,
    limit,
    // Others
    queryType: 'aggregate',
    distinct: true,
    withCount: !offset,
  };

  return query;
};

export default getExploreQuery;
