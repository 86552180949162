/** @jsxImportSource @emotion/react */
import { Spin } from 'antd';

import { preset } from 'styles';

type Props = { fullHeight?: boolean };

const Spinner: React.FC<Props> = ({ fullHeight = false }) => {
  return (
    <div
      css={
        fullHeight
          ? {
              height: '80%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }
          : {
              textAlign: 'center',
              paddingTop: preset.spacing(5),
              paddingBottom: preset.spacing(5),
            }
      }
    >
      <Spin size="large" />
    </div>
  );
};

export default Spinner;
