/** @jsxImportSource @emotion/react */
import {
    useRef, useMemo, useEffect, useState,
} from 'react';
import { connect } from 'react-redux';
import {
    Chart, Tooltip, Geom, Coord, Shape,
} from 'bizcharts';
import { View } from '@antv/data-set';

import formatDimensionLabel from '@modules/chart/formatDimensionLabel';
import formatMetricLabel from '@modules/chart/formatMetricLabel';
import formatChartData from '@modules/chart/formatChartData';
import getColorByMetric from '@modules/chart/getColorByMetric';
import useChartRef from '@modules/hooks/useChartRef';
import getChartElement from '@modules/chart/getChartElement';
import deepGet from '@modules/deepGet';

const WordCloud = ({
    width,
    height,
    config,
    color,
    queryObj,
    data,
    schema,
    drillDownDisabled = false,
    renderer,
    setChartDownloadInstance,
    // redux dispatch for triggering drilldown
    dispatch,
}) => {
    const {
        x, y, z, label: labelConfig,
    } = config;

    const [isShapeRegistered, setIsShapeRegistered] = useState(false);

    // Init chart instance
    const chartContainer = useRef();
    const chart = useRef();

    // Force word cloud re-render when color range changes (https://github.com/alibaba/BizCharts/issues/665)
    const [, forceRender] = useState();

    useEffect(() => {
        if (chart.current && z) {
            forceRender(new Date());
        }
    }, [color.line, config, z, color]);

    // Register Shape for wordCloud
    useEffect(() => {
        const getTextAttrs = cfg => ({
            ...cfg.style,
            fillOpacity: cfg.opacity,
            fontSize: cfg.origin._origin.size,
            rotate: cfg.origin._origin.rotate,
            text: cfg.origin._origin.text,
            textAlign: 'center',
            fontFamily: cfg.origin._origin.font,
            fill: cfg.color,
            textBaseline: 'Alphabetic',
        });

        Shape.registerShape('point', 'cloud', {
            drawShape(cfg, container) {
                const attrs = getTextAttrs(cfg);
                return container.addShape('text', {
                    attrs: { ...attrs, x: cfg.x, y: cfg.y },
                });
            },
        });

        setIsShapeRegistered(true);
    }, []);

    // Remove empty x
    const formattedData = useMemo(() => formatChartData(data, [x]), [data, x]);

    // Transform to cloud data
    const transformedData = useMemo(() => {
        if (!chartContainer.current || !isShapeRegistered) return null;

        const chartWidth = chartContainer.current.clientWidth;

        const dv = new View().source(formattedData);
        const range = dv.range(y);
        const min = range[0];
        const max = range[1];

        dv.transform({
            type: 'tag-cloud',
            fields: [x, y],
            size: [chartWidth, height],
            font: 'Verdana',
            padding: 0,
            timeInterval: 5000,
            rotate: () => {
                let random = Math.floor((Math.random() * 4) % 4);
                if (random === 2) {
                    random = 0;
                }
                return random * 90;
            },
            fontSize: d => {
                if (d.value) {
                    const baseFontSize = 80 * (chartWidth > 1200 ? 1 : (chartWidth / 1200));
                    return (((d.value - min) / (max - min)) * (baseFontSize - 24)) + 24;
                }
                return 0;
            },
        });

        return dv;
    }, [formattedData, height, isShapeRegistered, x, y]);

    // Re-init chart when width changes and pass chartRef to download modal
    useChartRef(chart, width, setChartDownloadInstance, !!transformedData);

    // Config chart scale
    const scale = useMemo(() => ({
        x: { nice: false },
        y: { nice: false },
        [y]: { formatter: val => formatMetricLabel(val) },
        ...z ? { [z]: { formatter: val => formatMetricLabel(val) } } : {},
    }), [y, z]);

    // Config cloud color for different types of data
    const cloudColor = useMemo(() => {
        if (z) {
            return getColorByMetric({
                x: 'text', config, color, data: formattedData, colorBy: z,
            });
        }
        return ['text', text => color[text]];
    }, [color, config, formattedData, z]);

    // Get polygon tooltip
    const cloudTooltip = useMemo(() => (z ? `${x}*${y}*${z}` : [
        `${x}*${y}`,
        (xValue, yValue) => ({
            name: formatDimensionLabel(xValue, {
                timeInterval: deepGet(schema, [x, 'functionValue']),
                labelConfig,
            }),
            value: formatMetricLabel(yValue),
        }),
    ]), [schema, x, y, z, labelConfig]);

    // Collect click ploygon data for drilldown
    const onCloudClick = ev => {
        if (!drillDownDisabled) {
            const targetData = ev.data ? ev.data._origin : {};

            dispatch({
                type: 'SET_DRILLDOWN',
                drilldown: {
                    x: ev.x,
                    y: ev.y,
                    chartElement: getChartElement(ev, chart.current),
                    targetData,
                    queryObj,
                },
            });
        }
    };

    return (
        <div ref={chartContainer}>
            {transformedData && (
                <Chart
                    height={height}
                    data={transformedData}
                    scale={scale}
                    padding="auto"
                    renderer={renderer}
                    forceFit
                    onPointClick={ev => onCloudClick(ev)}
                    onGetG2Instance={g2Chart => { chart.current = g2Chart; }}
                >
                    <Coord reflect="y" />
                    <Tooltip showTitle={false} />
                    <Geom
                        type="point"
                        position="x*y"
                        color={cloudColor}
                        shape="cloud"
                        tooltip={cloudTooltip}
                        style={{ cursor: drillDownDisabled ? 'auto' : 'pointer' }}
                    />
                </Chart>
            )}
        </div>
    );
};

export default connect()(WordCloud);
