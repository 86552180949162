/** @jsxImportSource @emotion/react */
import { Tooltip } from 'antd';
import {
    PhoneOutlined,
    AppleFilled,
    FacebookFilled,
    TwitterCircleFilled,
    InstagramOutlined,
    YoutubeFilled,
    RedditCircleFilled,
    SolutionOutlined,
    FormOutlined,
    MessageOutlined,
    ExceptionOutlined,
} from '@ant-design/icons';

import { ReactComponent as FacebookMessagerIcon } from '../../assets/icons/facebook-messager.svg';
import { ReactComponent as GoogleIcon } from '../../assets/icons/google.svg';

const DocSourceLogo = ({
    source,
    className,
}) => {
    if (!source) return null;

    let logoIcon = null;

    const lowerCaseSource = source.toLowerCase();

    const includes = sauce => {
        return lowerCaseSource.includes(sauce);
    };

    switch (true) {
        case includes('dialogue'):
            logoIcon = <PhoneOutlined />;
            break;
        case includes('apple'):
            logoIcon = <AppleFilled css={{ color: '#999999' }} />;
            break;
        case includes('google'):
            logoIcon = (
                <span className="anticon">
                    <GoogleIcon width={16} height={16} />
                </span>
            );
            break;
        case includes('facebook messenger'):
            logoIcon = (
                <span className="anticon">
                    <FacebookMessagerIcon width={16} height={16} />
                </span>
            );
            break;
        case includes('facebook'):
            logoIcon = <FacebookFilled css={{ color: '#3B5998' }} />;
            break;
        case includes('twitter'):
            logoIcon = <TwitterCircleFilled css={{ color: '#00aced' }} />;
            break;
        case includes('instagram'):
            logoIcon = <InstagramOutlined css={{ color: '#cd486b' }} />;
            break;
        case includes('youtube'):
            logoIcon = <YoutubeFilled css={{ color: '#ff0000' }} />;
            break;
        case includes('reddit'):
            logoIcon = <RedditCircleFilled css={{ color: '#ff4500' }} />;
            break;
        case includes('blog'):
            logoIcon = <SolutionOutlined />;
            break;
        case includes('forum'):
            logoIcon = <FormOutlined />;
            break;
        case includes('comment'):
            logoIcon = <MessageOutlined />;
            break;
        case includes('review'):
            logoIcon = <ExceptionOutlined />;
            break;
        default:
            break;
    }

    return logoIcon && (
        <Tooltip title={source}>
            <div className={className}>
                {logoIcon}
            </div>
        </Tooltip>
    );
};

export default DocSourceLogo;
