import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';

import { colors } from 'styles';
import formatChartData from './formatChartData';
import deepGet from '../deepGet';

const DEFAULT_Z_RANGE = {
  min: 0,
  mid: null,
  max: 1,
};

const DEFAULT_Z_COLOR = colors.chart.gradient.default;

const DEFAULT_AXIS_LABEL_CONFIG = {
  fontSize: 12,
};

const DEFAULT_AXIS_CONFIG = {
  x: { position: 'bottom' },
  y: { position: 'left' },
};

const getColorMap = (data = [], config = {}) => {
  const { x, isPie = false } = config;

  const smChartColorPlate = isPie ? colors.chart.pieSm : colors.chart.sm;
  const mdChartColorPlate = isPie ? colors.chart.pieSm : colors.chart.md;
  const lgChartColorPlate = isPie ? colors.chart.pieLg : colors.chart.lg;

  let colorList = [];

  if (data.length <= smChartColorPlate.length) {
    colorList = smChartColorPlate;
  } else if (data.length <= mdChartColorPlate.length) {
    colorList = mdChartColorPlate;
  } else if (data.length <= lgChartColorPlate.length) {
    colorList = lgChartColorPlate;
  } else {
    colorList = data.map(
      (row, index) => lgChartColorPlate[index % lgChartColorPlate.length]
    );
  }

  return data.reduce(
    (prev, row, index) => ({
      ...prev,
      [x ? row[x] : row]: colorList[index],
    }),
    {}
  );
};

const getChartDefaultConfig = (chartType, data, queryObj, defaultConfig) => {
  // Handle response when data is invalid or empty
  if (!Array.isArray(data) || data.length === 0) {
    return {
      general: {},
      color: {},
    };
  }

  const { metrics = [], dimensions = [], compare } = queryObj;
  const {
    x: defaultX,
    y: defaultY,
    z: defaultZ,
    color: defaultColorDimension,
  } = defaultConfig || {};

  if (compare && (!Array.isArray(data[0]) || data[0].length === 0)) {
    return {
      general: {},
      color: {},
    };
  }

  // Get sample row
  const rowKeys = [...dimensions, ...metrics].map(
    (obj) => obj.as || obj.attribute
  );

  // Basic config
  const x = defaultX || rowKeys[0];
  const y = defaultY || rowKeys[1];
  const z = defaultZ || rowKeys[2];
  const metricKeys = rowKeys.filter((rowKey) => rowKey !== x);

  // Dimension config
  const noDimension = dimensions.length === 0;
  const multiDimensions = dimensions.length > 1;
  const colorDimension = multiDimensions
    ? defaultColorDimension ||
      queryObj.dimensions[1].as ||
      queryObj.dimensions[1].attribute
    : null;
  const nullDimensionDisplay = dimensions.reduce(
    (prev, dimension) => ({
      ...prev,
      [dimension.as || dimension.attribute]: 'No Data',
    }),
    {}
  );

  // Format chart data, e.g. Replace the null key with 'No Data'
  let formattedData = formatChartData(data, [x, colorDimension]);

  // Get compare type
  const compareType = deepGet(queryObj, ['compare', 'compareType']);
  const compareDesc =
    compareType === 'previous_period'
      ? 'Compared to previous period'
      : compareType === 'previous_year'
      ? 'Compared to previous year'
      : '';

  switch (chartType) {
    case 'number': {
      return {
        general: {
          format: 'number',
          compare: {
            display: 'percentage_change',
            desc: compareDesc,
          },
        },
        color: {
          text: '#333',
        },
      };
    }

    case 'gauge': {
      return {
        general: {
          min: -1,
          max: 1,
          threshold: {
            negative: -0.4,
            positive: 0.4,
          },
          label: {
            offset: 20,
            fontSize: 14,
            min: 'Negative',
            max: 'Positive',
          },
          format: 'number',
          compare: {
            display: 'percentage_change',
            desc: compareDesc,
          },
        },
        color: {
          text: '#333',
          pointer: colors.primary,
          negative: colors.sentiment.negative,
          neutral: colors.sentiment.neutral,
          positive: colors.sentiment.positive,
        },
      };
    }

    case 'bar_basic': {
      const colorMap = noDimension
        ? getColorMap(rowKeys)
        : getColorMap(formattedData, { x });

      return {
        general: {
          ...(noDimension ? {} : { x, y }),
          label: {
            ...DEFAULT_AXIS_LABEL_CONFIG,
            display: false,
            ...(noDimension
              ? {}
              : {
                  nullDimensionDisplay,
                  alias: {},
                }),
          },
          zRange: DEFAULT_Z_RANGE,
          colorBy: noDimension ? 'label' : x,
          transpose: false,
          reverse: false,
          showEmptyDate: false,
          log: false,
          axis: DEFAULT_AXIS_CONFIG,
        },
        color: {
          ...colorMap,
          ...DEFAULT_Z_COLOR,
          ...(noDimension ? {} : { bar: colors.chart.default }),
        },
      };
    }

    case 'line_basic': {
      return {
        general: {
          x,
          y,
          label: {
            ...DEFAULT_AXIS_LABEL_CONFIG,
            nullDimensionDisplay,
            alias: {},
          },
          reverse: false,
          showEmptyDate: false,
          log: false,
          axis: DEFAULT_AXIS_CONFIG,
        },
        color: {
          line: colors.chart.default,
          point: colors.chart.default,
        },
      };
    }

    case 'area_basic': {
      return {
        general: {
          x,
          y,
          label: {
            ...DEFAULT_AXIS_LABEL_CONFIG,
            nullDimensionDisplay,
            alias: {},
          },
          reverse: false,
          showEmptyDate: false,
          gradient: false,
          log: false,
          axis: DEFAULT_AXIS_CONFIG,
        },
        color: {
          line: colors.chart.default,
        },
      };
    }

    case 'pie_innerlabel':
    case 'pie_donut': {
      const colorMap = noDimension
        ? getColorMap(rowKeys, { isPie: true })
        : getColorMap(formattedData, { x, isPie: true });

      return {
        general: {
          ...(noDimension ? {} : { x, y }),
          label: {
            display: true,
            ...(noDimension
              ? {}
              : {
                  nullDimensionDisplay,
                  alias: {},
                }),
          },
          legend: {
            display: false,
            config: {
              position: 'bottom',
            },
          },
          zRange: DEFAULT_Z_RANGE,
          colorBy: noDimension ? 'label' : x,
        },
        color: {
          ...colorMap,
          ...DEFAULT_Z_COLOR,
        },
      };
    }

    case 'waterfall': {
      return {
        general: {
          x,
          y,
          label: {
            ...DEFAULT_AXIS_LABEL_CONFIG,
            display: true,
            nullDimensionDisplay,
            alias: {},
          },
          transpose: false,
          reverse: false,
          showEmptyDate: false,
          log: false,
          axis: DEFAULT_AXIS_CONFIG,
        },
        color: {
          increase: colors.green,
          decrease: colors.red,
        },
      };
    }

    case 'list_count':
    case 'list_ordered': {
      return {
        general: {
          x,
          y,
          label: {
            nullDimensionDisplay,
            alias: {},
          },
        },
        color: { text: '#333' },
      };
    }

    case 'heatmap': {
      const multiMetrics = metrics.length > 1;
      const { colorMetric: defaultColorMetric } = defaultConfig || {};
      const colorMetric = multiMetrics
        ? defaultColorMetric || rowKeys[3]
        : null;

      return {
        general: {
          x,
          y,
          z,
          ...(multiMetrics ? { colorMetric } : {}),
          label: {
            ...DEFAULT_AXIS_LABEL_CONFIG,
            nullDimensionDisplay,
            alias: {},
          },
          axis: DEFAULT_AXIS_CONFIG,
          zRange: DEFAULT_Z_RANGE,
        },
        color: DEFAULT_Z_COLOR,
      };
    }

    case 'double_axes': {
      const { bar, line, left, right } = defaultConfig || {};

      const leftMetricKey = left || bar || rowKeys[1];
      const rightMetricKey = right || line || rowKeys[2];

      return {
        general: {
          x,
          left: leftMetricKey,
          right: rightMetricKey,
          label: {
            ...DEFAULT_AXIS_LABEL_CONFIG,
            nullDimensionDisplay,
            alias: {},
          },
          legend: {
            display: true,
            config: {
              position: 'bottom',
            },
          },
          reverse: false,
          showEmptyDate: false,
          axis: { left: {}, right: {} },
          metricDisplay: {
            left: 'bar',
            right: 'line',
          },
        },
        color: {
          [leftMetricKey]: colors.chart.default,
          [rightMetricKey]: colors.chart.sm[1],
        },
      };
    }

    case 'treemap_rect':
    case 'word_cloud':
    case 'bubble': {
      const multiMetrics = metrics.length > 1;
      const colorMap = getColorMap(formattedData, { x });

      return {
        general: {
          x,
          y,
          ...(multiMetrics
            ? {
                z,
                zRange: DEFAULT_Z_RANGE,
              }
            : {}),
          label: {
            nullDimensionDisplay,
            alias: {},
          },
        },
        color: multiMetrics ? DEFAULT_Z_COLOR : colorMap,
      };
    }

    case 'plotted_bubble': {
      const {
        name,
        x: defaultBubbleX,
        y: defaultBubbleY,
        z: defaultBubbleZ,
      } = defaultConfig || {};
      const bubbleName = name || rowKeys[0];
      const bubbleX = defaultBubbleX || rowKeys[1];
      const bubbleY = defaultBubbleY || rowKeys[2];
      const bubbleZ = defaultBubbleZ || rowKeys[3];

      formattedData = formatChartData(data, [bubbleName]);

      const colorMap = getColorMap(formattedData, { x: bubbleName });

      return {
        general: {
          name: bubbleName,
          x: bubbleX,
          y: bubbleY,
          z: bubbleZ,
          colorBy: bubbleName,
          zRange: DEFAULT_Z_RANGE,
          baseline: {
            x: {
              display: true,
              color: '#8c8c8c',
            },
            y: {
              display: true,
              color: '#8c8c8c',
            },
          },
          legend: {
            display: false,
            config: {
              position: 'bottom',
            },
          },
          minified: false,
          label: {
            nullDimensionDisplay,
            alias: {},
          },
          axis: DEFAULT_AXIS_CONFIG,
        },
        color: {
          ...colorMap,
          ...DEFAULT_Z_COLOR,
        },
      };
    }

    case 'radar': {
      const colorMap = getColorMap(metricKeys);

      return {
        general: {
          x,
          groups: metricKeys,
          legend: {
            display: true,
            config: {
              position: 'bottom',
            },
          },
          label: {
            nullDimensionDisplay,
            alias: {},
          },
        },
        color: colorMap,
      };
    }

    case 'geo_bubble': {
      return {
        general: {
          area: 'global',
        },
        color: {
          bubble: '#FF2F29',
        },
      };
    }

    case 'choropleth_map': {
      const defaultColorBy = metricKeys[0];

      const maxColorMetricValue =
        Math.round(maxBy(data, defaultColorBy)[defaultColorBy] * 100) / 100;
      const minColorMetricValue =
        Math.round(minBy(data, defaultColorBy)[defaultColorBy] * 100) / 100;
      const midColorMetricValue =
        (maxColorMetricValue + minColorMetricValue) / 2;

      let zRange = {
        min: minColorMetricValue,
        mid: midColorMetricValue,
        max: maxColorMetricValue,
      };
      let zColor = colors.chart.gradient.default;

      const colorMetricAttr = metrics[0].attribute;

      switch (colorMetricAttr) {
        case 'otso_doc_enriched_sentiment_score':
        case 'entity_sentiment_score':
          zRange = { min: -1, mid: 0, max: 1 };
          zColor = colors.chart.gradient.score;
          break;
        case 'rating':
          zColor = colors.chart.gradient.score;
          break;
        default:
          break;
      }
      return {
        general: {
          x,
          groups: metricKeys,
          zRange,
          colorBy: metricKeys[0],
          legend: {
            display: false,
            config: {
              position: 'bottom',
            },
          },
        },
        color: zColor,
      };
    }

    case 'bar_grouped':
    case 'bar_stackedColumn':
    case 'bar_stackedPercentage':
    case 'line_series':
    case 'area_series': {
      let colorMap = {};

      if (multiDimensions) {
        const colorDimensionValues = [
          ...new Set(formattedData.map((row) => `${row[colorDimension]}`)),
        ];
        colorMap = getColorMap(colorDimensionValues);
      } else {
        colorMap = getColorMap(
          compare ? [...metricKeys, 'Compare To'] : metricKeys
        );
      }

      return {
        general: {
          x,
          ...(multiDimensions
            ? {
                y: queryObj.metrics[0].as || queryObj.metrics[0].attribute,
                color: colorDimension,
              }
            : { groups: metricKeys }),
          label: {
            ...DEFAULT_AXIS_LABEL_CONFIG,
            nullDimensionDisplay,
            alias: {},
          },
          legend: {
            display: true,
            config: {
              position: 'bottom',
            },
          },
          transpose: chartType === 'bar_stackedPercentage',
          reverse: false,
          showEmptyDate: false,
          log: false,
          axis: DEFAULT_AXIS_CONFIG,
        },
        color: colorMap,
      };
    }

    case 'table': {
      return {
        general: {
          grid: false,
          rowsPerPage: 10,
          label: {
            nullDimensionDisplay,
            alias: {},
          },
        },
        color: {},
      };
    }

    default: {
      return {
        general: {},
        color: {},
      };
    }
  }
};

export default getChartDefaultConfig;
