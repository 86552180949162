import * as d3 from 'd3';
import download from 'downloadjs';
import { saveAs } from 'file-saver';

const downloadSVGAs = (svgElement, option = {}) => {
    const {
        width, height, downloadAs, name,
    } = option;

    const html = d3.select(svgElement)
        .attr('xmlns', 'http://www.w3.org/2000/svg')
        .node().parentNode.innerHTML;

    if (downloadAs === 'svg') {
        saveAs(new window.Blob([html], { type: 'application/svg+xml' }), `${name}.svg`);
    } else {
        const imgSrc = `data:image/svg+xml;base64,\n${window.btoa(html)}`;
        const canvasShadow = document.createElement('canvas');
        const context = canvasShadow.getContext('2d');
        canvasShadow.width = width;
        canvasShadow.height = height;

        const imageShadow = new window.Image();

        imageShadow.onload = () => {
            context.clearRect(0, 0, width, height);
            context.drawImage(imageShadow, 0, 0, width, height);
            canvasShadow.toBlob(blob => {
                download(blob, `${name}.${downloadAs}`, `image/${downloadAs}`);
            });
        };
        imageShadow.src = imgSrc;
    }
};

export default downloadSVGAs;
