/** @jsxImportSource @emotion/react */
import { useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { Chart, Axis, Tooltip, Geom, Label } from 'bizcharts';

import { deepGet, useChartRef } from 'modules';
import {
  getAxisConfig,
  formatDimensionLabel,
  getXAxisLabelConfig,
  formatMetricLabel,
  getChartElement,
  formatChartData,
  fillSecondDimensionData,
  getColorByMetric,
} from 'modules/chart';

const HeatMap = ({
  width,
  height,
  config,
  color,
  queryObj,
  data,
  schema,
  drillDownDisabled = false,
  // chart download
  renderer,
  setChartDownloadInstance,
  // redux dispatch for triggering drilldown
  dispatch,
}) => {
  const { x, y, z, colorMetric, label: labelConfig } = config;

  // Init chart instance
  const chart = useRef();

  // Re-init chart when width changes and pass chartRef to download modal
  useChartRef(chart, width, setChartDownloadInstance);

  // Get chart config
  const xAxisLabelConfig = useMemo(() => getXAxisLabelConfig(config), [config]);

  // Replace empty x and fill empty row with 0
  const formattedData = useMemo(() => {
    const filledData = fillSecondDimensionData(formatChartData(data, [x, y]), {
      firstDimension: x,
      secondDimension: y,
      metric: z,
      secondDimensionOrder: 'asc',
    });
    return filledData;
  }, [data, x, y, z]);

  // Config chart scale
  const scale = useMemo(
    () => ({
      [x]: {
        formatter: (val) =>
          formatDimensionLabel(val, {
            timeInterval: deepGet(schema, [x, 'functionValue']),
            labelConfig,
            dimensionIndex: 0,
            dimensionKey: x,
          }),
      },
      [y]: {
        formatter: (val) =>
          formatDimensionLabel(val, {
            timeInterval: deepGet(schema, [y, 'functionValue']),
            labelConfig,
            dimensionIndex: 1,
            dimensionKey: y,
          }),
      },
      [z]: { formatter: (val) => formatMetricLabel(val) },
      ...(colorMetric
        ? {
            [colorMetric]: { formatter: (val) => formatMetricLabel(val) },
          }
        : {}),
    }),
    [schema, x, y, z, colorMetric, labelConfig]
  );

  // Config ploygon color for different types of data
  const polygonColor = useMemo(() => {
    const colorKey = colorMetric || z;
    return getColorByMetric({
      x: colorKey,
      config,
      color,
      data: formattedData,
      colorBy: colorKey,
      callback: true,
    });
  }, [color, config, formattedData, z, colorMetric]);

  // Collect click ploygon data for drilldown
  const onPolygonClick = (ev) => {
    if (!drillDownDisabled) {
      const targetData = ev.data ? ev.data._origin : {};

      dispatch({
        type: 'SET_DRILLDOWN',
        drilldown: {
          x: ev.x,
          y: ev.y,
          chartElement: getChartElement(ev, chart.current),
          targetData,
          queryObj,
        },
      });
    }
  };

  return (
    <Chart
      height={height}
      data={formattedData}
      scale={scale}
      padding="auto"
      renderer={renderer}
      forceFit
      onPolygonClick={(ev) => onPolygonClick(ev)}
      onGetG2Instance={(g2Chart) => {
        chart.current = g2Chart;
      }}
    >
      <Axis
        name={x}
        label={xAxisLabelConfig}
        grid={{
          align: 'center',
          lineStyle: {
            lineWidth: 1,
            lineDash: null,
            stroke: '#f0f0f0',
          },
          showFirstLine: true,
        }}
        {...getAxisConfig(config, 'x')}
      />
      <Axis
        name={y}
        {...getAxisConfig(config, 'y')}
        grid={{
          align: 'center',
          lineStyle: {
            lineWidth: 1,
            lineDash: null,
            stroke: '#f0f0f0',
          },
          hideFirstLine: false,
          hideLastLine: false,
        }}
      />
      <Tooltip />
      <Geom
        type="polygon"
        position={`${x}*${y}`}
        color={polygonColor}
        tooltip={
          colorMetric ? `${x}*${y}*${z}*${colorMetric}` : `${x}*${y}*${z}`
        }
        style={{
          stroke: '#fff',
          lineWidth: 1,
          cursor: drillDownDisabled ? 'auto' : 'pointer',
        }}
      >
        <Label
          content={z}
          offset={-2}
          textStyle={{
            fill: '#fff',
            fontWeight: 'bold',
            shadowBlur: 2,
            shadowColor: 'rgba(0, 0, 0, .45)',
          }}
        />
      </Geom>
    </Chart>
  );
};

export default connect()(HeatMap);
