/** @jsxImportSource @emotion/react */
import { Collapse, Form, Row, Col, InputNumber } from 'antd';

import { preset } from 'styles';

const { Panel } = Collapse;
const FormItem = Form.Item;

const TablePanel = ({ defaultConfig = {}, dispatch, ...props }) => {
  return (
    <Panel {...props} key="table" header="Table">
      <Row gutter={preset.spacing(3)}>
        <Col span={12}>
          <FormItem label="Rows Per Page">
            <InputNumber
              placeholder="10"
              min={1}
              precision={0}
              value={defaultConfig.rowsPerPage}
              onChange={(value) =>
                dispatch({
                  type: 'SET_CONFIG',
                  value,
                  key: 'rowsPerPage',
                })
              }
            />
          </FormItem>
        </Col>
      </Row>
    </Panel>
  );
};

export default TablePanel;
