/** @jsxImportSource @emotion/react */
import { Statistic, Tooltip } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

import { colors } from 'styles';
import deepGet from '@modules/deepGet';
import formatMetricLabel from '@modules/chart/formatMetricLabel';
import getComparisonValue from '@modules/chart/getComparisonValue';
import { formatDate } from '@modules/date/formatDate';
import { formatDateInTZ } from 'modules';

const ComparisonCard = ({ config, data, schema, className }) => {
  const primaryValue = Array.isArray(data[0])
    ? Object.values(data[0][0])[0]
    : Object.values(data[0])[0];
  const comparisonValue =
    (Array.isArray(data[1])
      ? Object.values(data[1][0])[0]
      : Object.values(data[0])[1]) || 0;

  const displayConfig =
    deepGet(config, ['compare', 'display']) || 'percentage_change';
  const comparisonDesc = deepGet(config, ['compare', 'desc']);
  const transformedComparisonValue = getComparisonValue(
    primaryValue,
    comparisonValue,
    displayConfig
  );

  const calculatedFilters = Array.isArray(
    deepGet(schema, ['calculatedFilters'])
  )
    ? schema.calculatedFilters
    : [];
  const compareCalculatedFilters = Array.isArray(calculatedFilters[1])
    ? calculatedFilters[1]
    : [];
  const calculatedPeriod = compareCalculatedFilters.reduce((prev, filter) => {
    if (!filter.condition || !filter.isTime || !filter.value) return prev;
    if (filter.condition === '>=' || filter.condition === '>') {
      return formatDateInTZ(filter.value, 'day', '/');
    }
    if (filter.condition === '<=' || filter.condition === '<') {
      return `${prev} - ${formatDateInTZ(filter.value, 'day', '/')}`;
    }
    return prev;
  }, '');

  return (
    <Tooltip
      placement="bottom"
      title={calculatedPeriod && `Compared to ${calculatedPeriod}`}
      overlayStyle={{ maxWidth: 320 }}
    >
      <div className={className} css={{ textAlign: 'center' }}>
        <Statistic
          value={
            displayConfig === 'value'
              ? formatMetricLabel(transformedComparisonValue, config.format)
              : formatMetricLabel(Math.abs(transformedComparisonValue))
          }
          suffix={displayConfig === 'percentage_change' ? '%' : ''}
          prefix={
            displayConfig === 'value' ||
            transformedComparisonValue ===
              0 ? null : transformedComparisonValue > 0 ? (
              <ArrowUpOutlined />
            ) : (
              <ArrowDownOutlined />
            )
          }
          valueStyle={{
            color:
              displayConfig === 'value' || transformedComparisonValue === 0
                ? colors.typography.primary
                : transformedComparisonValue > 0
                ? colors.green
                : colors.red,
          }}
        />
        {comparisonDesc && (
          <div
            css={{
              color: colors.typography.secondary,
              fontWeight: '500',
            }}
          >
            {comparisonDesc}
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default ComparisonCard;
