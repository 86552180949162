import ReactDOM from 'react-dom';
import 'firebase/auth';
import 'firebase/storage';
import { Provider } from 'react-redux';
import { Global } from '@emotion/react';
import { AuthWrapper, initAuth } from '@otso/auth-wrapper';

import { globalStyle } from 'styles';
import store from 'redux/store';
import { AppLayout } from 'components';

import { Routes } from 'routes';

import { EmbedDashboard } from 'views';

// Import antd styles
import 'antd/dist/antd.less';
// Import react-grid-layout styles
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

initAuth({
  product: 'insight',
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  },
  authAPIHost: process.env.REACT_APP_AUTH_API_URL,
  authHost: process.env.REACT_APP_AUTH_CLIENT_URL,
});

const App = () => {
  return (
    <Provider store={store}>
      <Global styles={globalStyle} />
      <AuthWrapper
        logo="/img/logo.png"
        layout={false}
        customRoutes={[
          { path: '/:slug/embed/:embedId', component: EmbedDashboard },
        ]}
      >
        <AppLayout>
          <Routes />
        </AppLayout>
      </AuthWrapper>
    </Provider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
