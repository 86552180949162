/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Modal, Button, Input, List, message } from 'antd';

import { putApi, fetchApiErrorMessage } from '@modules/api';

const DuplicateProject = ({ project }) => {
  const history = useHistory();

  const { slug: projectSlug, name } = project;

  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [newProjectName, setNewProjectName] = useState(`${name} - Copy`);
  const [loading, setLoading] = useState(false);

  const duplicateProject = async (projectName) => {
    try {
      setLoading(true);
      const { data: newProject } = await putApi(
        `projects/${projectSlug}/duplicate`,
        { name: projectName }
      );
      message.success('Project Duplicated!');
      history.push(`/projects/${newProject.slug}/dashboards`);
    } catch (error) {
      setLoading(false);
      message.error(fetchApiErrorMessage(error));
    }
  };

  return (
    <>
      <List>
        <List.Item
          actions={[
            <Button
              onClick={() => setShowDuplicateModal(true)}
              loading={loading}
            >
              Duplicate Project
            </Button>,
          ]}
        >
          <List.Item.Meta
            title="Duplicate Project"
            description="Duplicate project including datasets, charts and dashboards"
          />
        </List.Item>
      </List>

      <Modal
        visible={showDuplicateModal}
        title="Duplicate Project"
        onCancel={() => {
          setShowDuplicateModal(false);
        }}
        footer={[
          <Button
            key="duplicate"
            onClick={() => duplicateProject(newProjectName)}
            disabled={!newProjectName || newProjectName.trim() === ''}
            loading={loading}
          >
            Duplicate
          </Button>,
        ]}
        closable
        destroyOnClose
      >
        <Input
          autoFocus
          value={newProjectName}
          onChange={(evt) => setNewProjectName(evt.target.value)}
        />
      </Modal>
    </>
  );
};

export default DuplicateProject;
