import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createTracker } from 'redux-segment';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './rootReducer';

const tracker = createTracker();
const middleware = [thunk, tracker];

const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(...middleware),
    ),
);

export default store;
