/** @jsxImportSource @emotion/react */
import { Coord } from 'bizcharts';

const CoordConfig = ({ option }) => {
    return (
        (option.transpose && option.reverse) ? (
            <Coord transpose scale={[-1, -1]} />
        ) : (
            option.transpose ? (
                <Coord transpose scale={[1, -1]} />
            ) : (
                option.reverse ? (
                    <Coord scale={[-1, 1]} />
                ) : null
            )
        )
    );
};

export default CoordConfig;
