/** @jsxImportSource @emotion/react */

// Import libraries
import { Formik } from 'formik';
import { useState, useEffect } from 'react';
import { Input, Form, SubmitButton } from 'formik-antd';

// Import Ant Design components
import { Button, Tooltip, message } from 'antd';

// Import modules
import { useIsSuperAdminRoute, putApi } from 'modules';

const DatasetBasicDetails = ({ dataset, fetchDataset }) => {
  // Initialization
  const [saving, setSaving] = useState(false);
  const [status, setStatus] = useState();

  const isGlobal = useIsSuperAdminRoute();

  useEffect(() => {
    setStatus(dataset.status);
  }, [dataset.status]);

  const updateDatasetStatus = async () => {
    setSaving(true);
    const newStatus = status === 'active' ? 'archived' : 'active';
    try {
      await putApi(
        isGlobal
          ? `superadmin/datasets/${dataset.id}`
          : `datasets/${dataset.id}`,
        { status: newStatus }
      );
      message.success(`Dataset ${newStatus}`);
      setStatus(newStatus);
      await fetchDataset();
    } catch (error) {
      message.error(
        `Unable to ${status === 'active' ? 'archive' : 'activate'} dataset`
      );
    }
    setSaving(false);
  };

  const onSubmit = async (values) => {
    setSaving(true);
    try {
      await putApi(
        isGlobal
          ? `superadmin/datasets/${dataset.id}`
          : `datasets/${dataset.id}`,
        values
      );
      message.success('Dataset updated');
    } catch (error) {
      message.error('Unable to save dataset');
    }
    setSaving(false);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name || !values.name.trim()) {
      errors.name = 'Dataset Name is required';
    }

    return errors;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: dataset.name,
        description: dataset.description,
      }}
      onSubmit={onSubmit}
      validate={validate}
    >
      <Form>
        <Form.Item name="name">
          <Input name="name" addonBefore="Name" placeholder="Dataset Name" />
        </Form.Item>
        <Form.Item name="description">
          <Input
            name="description"
            addonBefore="Description"
            placeholder="Dataset Description"
          />
        </Form.Item>
        <div>
          <SubmitButton type="primary" disabled={saving} loading={saving}>
            Save
          </SubmitButton>
          <Tooltip
            title={
              status === 'active'
                ? 'Archiving a dataset will hide it from the list of datasets when making new projects. All existing data will remain and it will still be able to ingest new data.'
                : 'Active this dataset so that it can be selected when creating new projects.'
            }
          >
            <Button
              css={{ float: 'right' }}
              type={status === 'active' ? 'danger' : null}
              loading={saving}
              onClick={updateDatasetStatus}
            >
              {status === 'active' ? 'Archive Dataset' : 'Activate Dataset'}
            </Button>
          </Tooltip>
        </div>
      </Form>
    </Formik>
  );
};

export default DatasetBasicDetails;
