/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Card } from 'antd';

import { preset } from 'styles';
import { postApi } from '@modules/api';
import deepGet from '@modules/deepGet';
import { formatEmptyObject } from '@modules/items';

import ChartDisplay from '../ChartDisplay';

const ChartCard = ({
  id,
  dashboardChartId,
  width,
  height,
  filters,
  timeFilter,
  refreshAt,
  comments = [],
  onClick,
  actions = [],
  bordered = false,
  hoverable = false,
  drillDownDisabled = false,
  className,
}) => {
  const { slug: projectSlug } = useParams();

  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [chart, setChart] = useState({});

  // Fetch chart
  useEffect(() => {
    let didCancel = false;

    const chartFetchUrl = `projects/${projectSlug}/visualisations/${id}`;

    const fetchChart = async () => {
      setLoading(true);

      try {
        const { data: chartData } = await postApi(chartFetchUrl, {
          filters,
          ...(formatEmptyObject(timeFilter)
            ? { timeFilter: formatEmptyObject(timeFilter) }
            : {}),
          refresh: !!refreshAt,
        });
        const formattedChartData = {
          ...chartData,
          color: chartData.defaultColor,
          config: chartData.defaultConfig,
          title: chartData.defaultTitle,
          queryObj: {
            ...(chartData.queryObj || {}),
            filters: [
              ...(deepGet(chartData, ['queryObj', 'filters']) || []),
              ...(filters || []),
            ],
            timeFilter,
          },
        };

        if (!didCancel) {
          setError();
          setChart(formattedChartData);
        }
      } catch (err) {
        if (!didCancel) {
          setError(err);
        }
      }

      if (!didCancel) {
        setLoading(false);
      }
    };

    if (id) {
      fetchChart();
    }

    return () => {
      didCancel = true;
    };
  }, [projectSlug, id, filters, timeFilter, refreshAt]);

  return (
    <Card
      data-testId="chart-card"
      bordered={bordered}
      hoverable={hoverable}
      onClick={() => onClick && onClick()}
      css={{
        height: '100%',
        '.ant-card-body': {
          height: '100%',
          paddingTop: preset.spacing(2),
          paddingBottom: preset.spacing(2),
        },
      }}
      className={className}
    >
      <ChartDisplay
        dashboardChartId={dashboardChartId}
        loading={loading}
        error={error}
        width={width}
        height={height && height - preset.spacing(2) * 2}
        chart={chart}
        comments={comments}
        actions={actions}
        drillDownDisabled={drillDownDisabled}
      />
    </Card>
  );
};

export default ChartCard;
