/** @jsxImportSource @emotion/react */
import { useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { Chart, Axis, Tooltip, Geom } from 'bizcharts';
import { View } from '@antv/data-set';

import {
  getAxisConfig,
  getXAxisLabelConfig,
  getYAxisScale,
  formatChartData,
  getXAxisScale,
  getChartElement,
  formatCompareChartData,
  getCompareChartDrilldownData,
  formatDimensionLabel,
  formatMetricLabel,
} from 'modules/chart';
import { deepGet, useChartRef } from 'modules';
import CoordConfig from '../components/CoordConfig';
import LegendConfig from '../components/LegendConfig';
import ChartComments from '../components/ChartComments';

const AreaSeries = ({
  width,
  height,
  config,
  color,
  queryObj,
  data,
  schema,
  drillDownDisabled = false,
  // chart download
  renderer,
  setChartDownloadInstance,
  // dashboard chart comments
  comments = [],
  commentsShowing = false,
  commentsEditing = false,
  // redux dispatch for triggering drilldown
  dispatch,
}) => {
  const {
    x,
    y,
    groups: metricGroups,
    color: colorDimension,
    reverse = false,
    log = false,
    label: labelConfig,
    axis: axisConfig,
  } = config;

  const { dimensions, compare, metrics } = queryObj;

  // Init chart instance
  const chart = useRef();

  // Re-init chart when width changes and pass chartRef to download modal
  useChartRef(chart, width, setChartDownloadInstance);

  // Get chart config
  const xAxisLabelConfig = useMemo(() => getXAxisLabelConfig(config), [config]);
  const hasTimeDimension = !!dimensions.find(
    (dimension) => dimension.functionValue
  );
  const showEmptyDate = config.showEmptyDate && hasTimeDimension && !!compare;
  const compareChartConfig = useMemo(() => {
    return compare
      ? formatCompareChartData({
          data,
          queryObj: { dimensions, metrics, compare },
          schema,
        })
      : { data: [] };
  }, [data, dimensions, metrics, compare, schema]);
  const isCompareChart = !!(
    compare &&
    compareChartConfig.y &&
    compareChartConfig.compareY &&
    compareChartConfig.compareX
  );

  // Remove empty x
  const formattedData = useMemo(() => {
    return compare
      ? compareChartConfig.data
      : formatChartData(data, [x, colorDimension]);
  }, [data, x, colorDimension, compare, compareChartConfig]);

  // Config X and Y for two types of data
  const metricKey = 'metric';
  const metricValueKey = 'value';
  const transformedY = colorDimension ? y : metricValueKey;

  // Transform data if data has multiple metrics and one dimension
  const transformedData = useMemo(() => {
    if (colorDimension) {
      return formattedData;
    }

    const transformConfig = isCompareChart
      ? {
          type: 'fold',
          fields: [compareChartConfig.y, compareChartConfig.compareY],
          key: metricKey,
          value: metricValueKey,
        }
      : {
          type: 'fold',
          fields: metricGroups,
          key: metricKey,
          value: metricValueKey,
        };

    const dv = new View();
    dv.source(formattedData).transform(transformConfig);

    return dv;
  }, [
    colorDimension,
    formattedData,
    metricGroups,
    isCompareChart,
    compareChartConfig,
  ]);

  // Config chart scale
  const scale = useMemo(() => {
    return {
      [x]: getXAxisScale({
        timeInterval: deepGet(schema, [x, 'functionValue']),
        showEmptyDate,
        data,
        x,
        queryObj,
        schema,
        labelConfig,
      }),
      [transformedY]: getYAxisScale({
        log,
        axisConfig,
        y: transformedY,
        data: colorDimension ? data : transformedData.rows,
      }),
      [colorDimension]: {
        formatter: (val) =>
          formatDimensionLabel(val, {
            timeInterval: deepGet(schema, [colorDimension, 'functionValue']),
            labelConfig,
            dimensionIndex: 1,
            dimensionKey: colorDimension,
          }),
      },
    };
  }, [
    x,
    schema,
    showEmptyDate,
    data,
    queryObj,
    labelConfig,
    transformedY,
    log,
    axisConfig,
    colorDimension,
    transformedData.rows,
  ]);

  // Get series area color
  const seriesColor = useMemo(
    () => [
      colorDimension || 'metric',
      (xKey) => {
        if (isCompareChart) {
          if (xKey === compareChartConfig.y) {
            return color[metricGroups[0]];
          }
          if (xKey === compareChartConfig.compareY) {
            return color['Compare To'];
          }
        }
        return color[xKey];
      },
    ],
    [color, colorDimension, isCompareChart, compareChartConfig, metricGroups]
  );

  // Get point tooltip
  const pointTooltip = useMemo(
    () => [
      colorDimension
        ? `${x}*${colorDimension}*${transformedY}`
        : `${x}*${metricKey}*${transformedY}`,
      (xValue, yLabel, yValue) => ({
        title: formatDimensionLabel(xValue, {
          timeInterval: deepGet(schema, [x, 'functionValue']),
          labelConfig,
        }),
        name: colorDimension
          ? formatDimensionLabel(yLabel, {
              timeInterval: deepGet(schema, [colorDimension, 'functionValue']),
              labelConfig,
            })
          : yLabel,
        value: formatMetricLabel(yValue),
      }),
    ],
    [colorDimension, schema, transformedY, x, labelConfig]
  );

  // Collect point click data for drilldown
  const onPointClick = (ev) => {
    const targetData = ev.data ? ev.data._origin : {};

    const compareDrilldownData = isCompareChart
      ? getCompareChartDrilldownData(
          targetData,
          { queryObj, metricKey, x },
          compareChartConfig
        )
      : {};

    if (!drillDownDisabled) {
      dispatch({
        type: 'SET_DRILLDOWN',
        drilldown: {
          x: ev.x,
          y: ev.y,
          chartElement: getChartElement(ev, chart.current),
          targetData: isCompareChart
            ? compareDrilldownData.targetData
            : targetData,
          queryObj: isCompareChart ? compareDrilldownData.queryObj : queryObj,
        },
      });
    } else if (commentsEditing) {
      dispatch({
        type: 'SET_DASHBOARD_CHART_COMMENT_POINT',
        point: {
          dimension: targetData[x],
          metric: targetData[transformedY],
        },
      });
    }
  };

  return (
    <Chart
      height={height}
      data={transformedData}
      scale={scale}
      padding="auto"
      renderer={renderer}
      forceFit
      onPointClick={(ev) => onPointClick(ev)}
      onGetG2Instance={(g2Chart) => {
        chart.current = g2Chart;
      }}
    >
      <CoordConfig option={{ reverse }} />
      <Axis name={x} label={xAxisLabelConfig} {...getAxisConfig(config, 'x')} />
      <Axis
        name={transformedY}
        position={reverse ? 'right' : 'left'}
        {...getAxisConfig(config, 'y')}
      />
      <LegendConfig config={config} />
      <Tooltip crosshairs={{ type: transformedY }} />
      <Geom
        type="line"
        color={seriesColor}
        position={`${x}*${transformedY}`}
        size={2}
      />
      <Geom
        type="area"
        color={seriesColor}
        position={`${x}*${transformedY}`}
        tooltip={false}
      />
      <Geom
        type="point"
        position={`${x}*${transformedY}`}
        size={6}
        shape="circle"
        opacity={0}
        tooltip={pointTooltip}
        style={{
          cursor: drillDownDisabled && !commentsEditing ? 'auto' : 'pointer',
        }}
      />

      {/* Dashboard chart comments */}
      <ChartComments
        visible={commentsShowing}
        comments={comments}
        data={colorDimension ? transformedData : transformedData.rows}
        x={x}
        y={transformedY}
      />
    </Chart>
  );
};

export default connect()(AreaSeries);
