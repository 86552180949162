import deepGet from '../deepGet';

const getChartElement = (ev, g2Chart) => {
    const { toElement } = ev;
    const canvasDom = deepGet(g2Chart, ['_attrs', 'canvas', '_cfg', 'el']);
    const containerDom = g2Chart.get('container');

    return toElement || canvasDom || containerDom;
};

export default getChartElement;
