/** @jsxImportSource @emotion/react */
import { useMemo, useState } from 'react';

import { Form, Select, Collapse } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import { preset } from 'styles';
import {
  getFilterInputGroupConfig,
  ENTITIES_SCHEMA_FIELDS,
  getFilterDisplay,
} from '@modules/filterBarHelpers';
import formatLabel from '@modules/formatLabel';
import FilterValueField from '@components/FilterBar/components/FilterValueField';

import StyledCollapse from '../../StyledCollapse';


const { Option, OptGroup } = Select;
const { Panel } = Collapse;

const MetricFilterCollapse = ({
  filter = {},
  onFilterChange,
  // for filter config
  sharedSchemaFields,
  attributeOptions = {},
  filterOptions = {},
  formatRequiredAttributes = [],
  datasetIds = [],
}) => {
  const [activeKey, setActiveKey] = useState([]);

  // Get value select options for fields like 'channel', 'source', 'language'
  const valueOptions = useMemo(() => {
    if (filter.attribute) {
      return ENTITIES_SCHEMA_FIELDS.find(
        (entitySchemaField) => entitySchemaField.name === filter.attribute
      )
        ? filterOptions.entity[filter.attribute]
        : filterOptions.document[filter.attribute];
    }
    return [];
  }, [filter.attribute, filterOptions]);

  // Get formItemType, conditionOptions, searchKey etc for each filter.
  const filterConfig = useMemo(() => {
    if (
      Array.isArray(sharedSchemaFields) &&
      sharedSchemaFields.length > 0 &&
      filter.attribute
    ) {
      return getFilterInputGroupConfig(
        sharedSchemaFields.find((field) => field.name === filter.attribute)
      );
    }
    return { conditionOptions: [] };
  }, [filter.attribute, sharedSchemaFields]);

  // Exclude time from filter attribute options
  const filterAttributeOptions = useMemo(() => {
    const { time, ...otherOptions } = attributeOptions;
    return otherOptions;
  }, [attributeOptions]);

  // format filterOptions like 'channel', 'source', 'language'
  const valueFormat = useMemo(() => {
    if (!filter.attribute || formatRequiredAttributes.length === 0) {
      return null;
    }

    const formatAttributeObj = formatRequiredAttributes.find(
      (obj) => obj.name === filter.attribute
    );
    return formatAttributeObj ? formatAttributeObj.format : null;
  }, [filter.attribute, formatRequiredAttributes]);

  return (
    <StyledCollapse
      activeKey={activeKey}
      onChange={(val) => setActiveKey(val)}
      css={{ marginTop: preset.spacing(3) }}
    >
      <Panel
        key="metric_filter"
        header={
          <span
            css={{
              flex: 1,
              marginRight: 12,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {getFilterDisplay(filter, sharedSchemaFields) ||
              'Metric Filter (Optional)'}
          </span>
        }
        extra={[
          <CloseCircleOutlined
            key="delete"
            onClick={(e) => {
              e.stopPropagation();
              onFilterChange();
              setActiveKey([]);
            }}
          />,
        ]}
        css={{
          '.ant-collapse-header': {
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        <Form.Item label="Attribute" required>
          <Select
            showSearch
            dropdownMatchSelectWidth={false}
            placeholder="Attribute"
            optionFilterProp="children"
            value={filter.attribute}
            onChange={(val) =>
              onFilterChange({
                ...(filter || {}),
                attribute: val,
              })
            }
          >
            {Object.keys(filterAttributeOptions).map((optionGroupKey) => (
              <OptGroup
                key={optionGroupKey}
                label={formatLabel(optionGroupKey)}
              >
                {attributeOptions[optionGroupKey].map((field) => (
                  <Option key={field.name} value={field.name}>
                    {field.displayName || formatLabel(field.name)}
                  </Option>
                ))}
              </OptGroup>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Condition" required>
          <Select
            showSearch
            placeholder="Condition"
            optionFilterProp="children"
            value={filter.condition}
            onChange={(val) =>
              onFilterChange({
                ...(filter || {}),
                condition: val,
                value: val === 'between' ? {} : undefined,
              })
            }
          >
            {filterConfig.conditionOptions.map((conditionOption) => (
              <Option key={conditionOption.value} value={conditionOption.value}>
                {conditionOption.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Value" required>
          <FilterValueField
            attribute={filter.attribute}
            condition={filter.condition}
            value={filter.value}
            onChange={(val) =>
              onFilterChange({
                ...(filter || {}),
                value: val,
              })
            }
            config={{
              formItemType: filterConfig.formItemType,
              valueOptions,
              datasetIds,
              searchKey: filterConfig.searchKey,
              valueFormat,
              attributeDisplay: filterConfig.displayName,
            }}
          />
        </Form.Item>
      </Panel>
    </StyledCollapse>
  );
};

export default MetricFilterCollapse;
