/** @jsxImportSource @emotion/react */
import { Tag, Tooltip } from 'antd';

import { colors, preset } from 'styles';
import { getFilterDisplay } from '@modules/filterBarHelpers';

const FilterTags = ({ filters = [], sharedSchemaFields = [], onTagRemove }) => {
  return (
    filters.length > 0 && (
      <div
        css={{
          marginBottom: -preset.spacing(1),
        }}
      >
        {filters.map(
          (filter, index) =>
            !filter.hidden && (
              <Tooltip
                key={`filter_${index + 1}`}
                title={getFilterDisplay(filter, sharedSchemaFields)}
              >
                <Tag
                  color={colors.primary}
                  closable
                  onClose={() => onTagRemove(index)}
                  css={{
                    display: 'inline-flex',
                    maxWidth: '50%',
                    alignItems: 'center',
                    lineHeight: '30px',
                    fontSize: 14,
                    paddingLeft: preset.spacing(1),
                    paddingRight: preset.spacing(1),
                    borderRadius: preset.spacing(2),
                    marginBottom: preset.spacing(1),
                    marginRight: preset.spacing(2),
                    i: {
                      '&.anticon.anticon-close': {
                        fontSize: 16,
                        marginLeft: 6,
                      },
                    },
                  }}
                >
                  <span
                    css={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {getFilterDisplay(filter, sharedSchemaFields)}
                  </span>
                </Tag>
              </Tooltip>
            )
        )}
      </div>
    )
  );
};

export default FilterTags;
