import deepGet from '../deepGet';

const getCompareChartDrilldownData = (targetData, chartConfig = {}, compareChartConfig = {}) => {
    const { queryObj, metricKey, x } = chartConfig;
    const {
        compareY, compareX, compareFrom, compareTo,
    } = compareChartConfig;

    let compareTargetData = targetData;
    let compareQueryObj = queryObj;

    if (targetData[metricKey] === compareY) {
        compareTargetData = {
            ...targetData,
            [x]: targetData[compareX],
        };

        const compareType = deepGet(queryObj, ['compare', 'compareType']);

        if (compareType === 'previous_period' || compareType === 'previous_year') {
            compareQueryObj = {
                ...(compareQueryObj || {}),
                ...((compareFrom && compareTo) ? {
                    timeFilter: { from: compareFrom, to: compareTo },
                } : {}),
            };
        } else {
            const compareObj = deepGet(queryObj, ['compare']) || {};
            if (Object.keys(compareObj).filter(key => key !== 'compareType').length > 0) {
                const compareTimeFilter = deepGet(queryObj, ['compare', 'timeFilter']) || null;
                const compareFilters = deepGet(queryObj, ['compare', 'filters']) || [];
                compareQueryObj = {
                    ...(compareQueryObj || {}),
                    timeFilter: compareTimeFilter,
                    filters: compareFilters,
                };
            }
        }
    }

    return { targetData: compareTargetData, queryObj: compareQueryObj };
};

export default getCompareChartDrilldownData;
