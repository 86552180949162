/** @jsxImportSource @emotion/react */
import { useState, useReducer, useEffect, useMemo } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import {
  Input,
  Select,
  Pagination,
  Modal,
  message,
  Button,
  Row,
  Col,
  Alert,
} from 'antd';

import { trackEvent } from 'analytics';
import { preset } from 'styles';
import Container from '@components/Container';
import Spinner from '@components/Spinner';
import Empty from '@components/Empty';
import ChartCard from '@components/ChartCard';
import ChartDownloadModal from '@components/ChartDownloadModal';
import useGetApi from '@modules/hooks/useGetApi';
import useCheckPermission from '@modules/hooks/useCheckPermission';
import { removeEmptyObjectValues } from '@modules/items';
import { putApi, deleteApi, fetchApiErrorMessage } from '@modules/api';

import { initialState, chartsReducer } from './reducer';

const { Option } = Select;

const PAGE_SIZE = 6;

const Charts = ({
  width = null,
  hideCreateChartBtn = false,
  createChartFromDashboardInfo = {},
  extraFilter = null,
  onSelect = () => {},
  fadeCharts = [],
}) => {
  const { slug: projectSlug } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [reducer, dispatch] = useReducer(chartsReducer, initialState);
  const [isQueryInit, setIsQueryInit] = useState(false);

  const {
    query: { page, q, createdBy },
  } = reducer;

  // Set default states from url
  useEffect(() => {
    if (location && location.search && !isQueryInit) {
      const urlParams = queryString.parse(location.search);

      dispatch({
        type: 'SET_QUERY',
        query: {
          ...(urlParams.page && !width
            ? { page: parseInt(urlParams.page, 10) }
            : {}),
          ...(urlParams.q ? { q: urlParams.q } : {}),
          ...(urlParams.createdBy ? { createdBy: urlParams.createdBy } : {}),
        },
      });
    }

    setIsQueryInit(true);
  }, [location, isQueryInit, width]);

  // Get all project charts
  const {
    data: allProjectCharts,
    loading: allProjectChartsLoading,
    setData: setAllProjectCharts,
  } = useGetApi(`projects/${projectSlug}/visualisations`);

  // Scroll to page top when page changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  // Handle charts query change
  const onQueryChange = (newQuery = {}) => {
    dispatch({
      type: 'SET_QUERY',
      query: newQuery,
    });

    // Only change url if in Charts page
    if (!width) {
      history.push({
        search: queryString.stringify(removeEmptyObjectValues(newQuery)),
      });
    }
  };

  // Create Chart
  const createChart = () => {
    const createChartPageUrl = `/projects/${projectSlug}/charts/create`;

    if (
      createChartFromDashboardInfo &&
      createChartFromDashboardInfo.dashboard
    ) {
      trackEvent('Create Chart Begin', { fromDashboard: true });

      const { layout, pageId, ...dashboardUrlInfo } =
        createChartFromDashboardInfo;
      const urlQueryString = queryString.stringify(dashboardUrlInfo);

      history.push(`${createChartPageUrl}?${urlQueryString}`, {
        layout,
        pageId,
      });
    } else {
      trackEvent('Create Chart Begin');
      history.push(createChartPageUrl);
    }
  };

  // Create Alert from a chart
  const createAlert = (chartId) => {
    history.push(`/projects/${projectSlug}/alerts`, {
      visualisationId: chartId,
    });
  };

  // Edit Chart
  const editChart = (chartId) => {
    history.push(`${location.pathname}/${chartId}`);
  };

  // Delete Chart
  const deleteChart = (chartId) => {
    const deleteProjectChart = async () => {
      try {
        await deleteApi(`projects/${projectSlug}/visualisations/${chartId}`);
        // Remove chart from state
        setAllProjectCharts(
          allProjectCharts.filter((projectChart) => projectChart.id !== chartId)
        );

        message.success('Project Chart Deleted');
      } catch (error) {
        message.error(fetchApiErrorMessage(error));
      }
    };

    Modal.confirm({
      title: 'Are you sure you want to delete this chart?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: deleteProjectChart,
    });
  };

  // Duplicate Chart
  const duplicateChart = async (chartId) => {
    try {
      const { data: newChart } = await putApi(
        `projects/${projectSlug}/visualisations/${chartId}/duplicate`
      );
      message.success('Project Chart Duplicated');
      history.push(`${location.pathname}/${newChart.id}`);
    } catch (error) {
      message.error(fetchApiErrorMessage(error));
    }
  };

  // Check if can create alert from chart
  const canCreateAlertFromChart = (chartId) => {
    const chart = allProjectCharts.find(
      (projectChart) => projectChart.id === chartId
    );

    return !!chart && ['number', 'gauge'].includes(chart.chartType);
  };

  // Get filter charts
  const filteredChartIds = useMemo(
    () =>
      isQueryInit
        ? allProjectCharts.reduce((prev, chart) => {
            // Remove rich text widget
            if (chart.chartType === 'rich_text') {
              return prev;
            }
            // Check extraFilter (charts in Alerts page)
            if (
              extraFilter &&
              !extraFilter.chartType.includes(chart.chartType)
            ) {
              return prev;
            }

            // createdBy filter
            if (createdBy === 'custom' && chart.createdBy === null) {
              return prev;
            }
            if (createdBy === 'default' && chart.createdBy !== null) {
              return prev;
            }

            // string query filter
            if (
              q &&
              !`${chart.defaultTitle}`
                .toLowerCase()
                .includes(q.toLowerCase()) &&
              !`${chart.description}`.toLowerCase().includes(q.toLowerCase())
            ) {
              return prev;
            }

            return [...prev, chart.id];
          }, [])
        : [],
    [isQueryInit, allProjectCharts, extraFilter, createdBy, q]
  );

  // Get charts on current page
  const currentPageChartIds = useMemo(() => {
    const chartsLength = filteredChartIds.length;
    const offset = (page - 1) * PAGE_SIZE; // current page number multiplied by the number of charts per page
    const endLimit = offset + 6 <= chartsLength ? offset + 6 : chartsLength;
    return filteredChartIds.slice(offset, endLimit);
  }, [filteredChartIds, page]);

  const canEditChart = useCheckPermission(['insight.manageProjects']);
  const canDrilldownData = useCheckPermission(['insight.viewDocuments']);

  return (
    <Container
      css={
        width
          ? {
              paddingLeft: 0,
              paddingRight: 0,
            }
          : {}
      }
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: preset.spacing(3),
          ...(width
            ? {}
            : {
                marginTop: 12,
              }),
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            marginRight: preset.spacing(5),
          }}
        >
          <Input.Search
            key={q} // user query as key to force refresh of input on query change
            allowClear
            placeholder="Press Enter to Search"
            defaultValue={q}
            onSearch={(val) =>
              onQueryChange({
                q: val,
                page: 1,
                createdBy,
              })
            }
            css={{
              flex: 1,
              maxWidth: 500,
              marginRight: preset.spacing(3),
            }}
          />
          <Select
            value={createdBy}
            onChange={(val) =>
              onQueryChange({
                q,
                page: 1,
                createdBy: val,
              })
            }
            dropdownMatchSelectWidth={false}
          >
            <Option value="all">Show All Charts</Option>
            <Option value="default">Show Default Charts Only</Option>
            <Option value="custom">Show Custom Charts Only</Option>
          </Select>
        </div>
        {!hideCreateChartBtn && canEditChart && (
          <Button type="primary" onClick={createChart}>
            Create Chart
          </Button>
        )}
      </div>

      {allProjectChartsLoading || !isQueryInit ? (
        <Spinner />
      ) : currentPageChartIds.length > 0 ? (
        <Row gutter={[preset.spacing(3), preset.spacing(3)]}>
          {currentPageChartIds.map((chartId) => {
            return (
              <Col key={chartId} span={12}>
                <div css={{ position: 'relative' }}>
                  {fadeCharts && fadeCharts.includes(chartId) && (
                    <div
                      css={{
                        position: 'absolute',
                        zIndex: 1,
                        maxWidth: 350,
                        top: '40%',
                        left: '20%',
                      }}
                    >
                      <Alert
                        showIcon
                        message={
                          <div>
                            <p>
                              This chart is already in use on the current
                              dashboard. It can be added multiple times, but all
                              duplicates of a chart will show the same data as
                              each other.
                            </p>
                            <p>
                              You can also
                              <a
                                href="#create-chart"
                                onClick={(e) => {
                                  e.preventDefault();
                                  createChart();
                                }}
                              >
                                {' click here '}
                              </a>
                              to create a new Chart.
                            </p>
                          </div>
                        }
                      />
                    </div>
                  )}
                  <ChartCard
                    css={{
                      opacity:
                        fadeCharts && fadeCharts.includes(chartId)
                          ? 0.4
                          : undefined,
                    }}
                    id={chartId}
                    height={500}
                    drillDownDisabled={!canDrilldownData}
                    {...(width
                      ? {
                          drillDownDisabled: true,
                          bordered: true,
                          hoverable: true,
                          onClick: () => onSelect(chartId),
                        }
                      : {
                          actions: [
                            ...(canEditChart
                              ? [
                                  {
                                    name: 'Edit Chart',
                                    onClick: () => editChart(chartId),
                                  },
                                  {
                                    name: 'Duplicate Chart',
                                    onClick: () => duplicateChart(chartId),
                                  },
                                  {
                                    name: 'Delete Chart',
                                    onClick: () => deleteChart(chartId),
                                  },
                                  ...(canCreateAlertFromChart(chartId)
                                    ? [
                                        {
                                          name: 'Create Alert',
                                          onClick: () => createAlert(chartId),
                                        },
                                      ]
                                    : []),
                                ]
                              : []),
                            { name: 'Download As' },
                          ],
                        })}
                  />
                </div>
              </Col>
            );
          })}
        </Row>
      ) : (
        <Empty description="No Charts Found" />
      )}

      {filteredChartIds.length > 0 && (
        <Pagination
          total={filteredChartIds.length}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} charts`
          }
          pageSize={PAGE_SIZE}
          current={page}
          onChange={(newPage) =>
            onQueryChange({
              q,
              page: newPage,
              createdBy,
            })
          }
          css={{
            textAlign: 'right',
            ...(width
              ? {
                  marginTop: preset.spacing(3),
                }
              : {
                  marginTop: preset.spacing(5),
                  marginBottom: 60,
                }),
          }}
        />
      )}

      <ChartDownloadModal />
    </Container>
  );
};

export default Charts;
