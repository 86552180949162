/** @jsxImportSource @emotion/react */
import { useEffect, useState, useContext } from 'react';
import moment from 'moment-timezone';

import { Popover, Typography, message } from 'antd';

import { preset } from 'styles';
import { putApi, fetchApiErrorMessage } from '@modules/api';
import { ProjectContext } from '@providers/ProjectProvider';

const ChartTooltip = ({
  disabled = false,
  children,
  chart = {},
  onDescriptionEdit,
}) => {
  const { title, description, dataUpdatedAt, id } = chart;

  const { project } = useContext(ProjectContext);

  const { slug: projectSlug } = project || {};

  const [timeSinceDataUpdate, setTimeSinceDataUpate] = useState(
    moment(dataUpdatedAt).fromNow()
  );
  const [chartDescription, setChartDescription] = useState();
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setChartDescription(description);
  }, [description]);

  useEffect(() => {
    const calcTimeSince = () => {
      return moment(dataUpdatedAt).fromNow();
    };

    const timeoutId = setTimeout(() => {
      setTimeSinceDataUpate(calcTimeSince());
    }, 55000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [dataUpdatedAt, timeSinceDataUpdate]);

  const onChartDescriptionEdit = async (text) => {
    if (text !== chartDescription) {
      try {
        await putApi(`projects/${projectSlug}/visualisations/${id}`, {
          visualisation: { description: text },
        });
        setChartDescription(text);
        onDescriptionEdit(text);
        setEditing(false);
      } catch (err) {
        message.error(fetchApiErrorMessage(err));
      }
    } else {
      setEditing(false);
    }
  };

  return disabled ? (
    children
  ) : (
    <Popover
      title={title}
      content={
        <div>
          <Typography.Text
            type="secondary"
            editable={{
              editing,
              onStart: () => setEditing(true),
              onChange: onChartDescriptionEdit,
            }}
          >
            {chartDescription}
          </Typography.Text>
          <div css={{ marginTop: preset.spacing(1) }}>
            {`Data updated ${timeSinceDataUpdate}.`}
          </div>
        </div>
      }
      overlayStyle={{ maxWidth: 300 }}
      mouseEnterDelay={0.3}
      mouseLeaveDelay={editing ? 0.5 : 0.1}
      placement="topLeft"
    >
      {children}
    </Popover>
  );
};

export default ChartTooltip;
