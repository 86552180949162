/** @jsxImportSource @emotion/react */

// Import Ant Design components
import { Select, Col, Form } from 'antd';

// Import modules
import formatLabel from '@modules/formatLabel';

// Import components
import FloatLabelContainer from '@components/FloatLabelContainer';

// Import additional Ant Design components
const { Option, OptGroup } = Select;

const DriverAnalysisConfigs = (props) => {
  // Extract values from props
  const { keyMetricFieldOptions, keyDimensionFieldOptions, loading } = props;

  return (
    <>
      {/* Key metric field */}
      <Col span={6}>
        <Form.Item dependencies={['metric']} noStyle>
          {({ getFieldValue }) => (
            <FloatLabelContainer
              label="Key Metric"
              floating={getFieldValue('metric')}
            >
              <Form.Item
                name="metric"
                rules={[{ required: true }]}
                style={{ marginBottom: 0 }}
              >
                <Select
                  showSearch
                  showArrow
                  allowClear
                  labelInValue
                  optionFilterProp="children"
                  loading={loading}
                  dropdownMatchSelectWidth={false}
                  style={{ width: '100%' }}
                >
                  {keyMetricFieldOptions.map(({ groupName, options }) => (
                    <OptGroup key={groupName} label={formatLabel(groupName)}>
                      {options.map((option) => (
                        <Option key={option.name} value={option.name}>
                          {option.displayName || formatLabel(option.name)}
                        </Option>
                      ))}
                    </OptGroup>
                  ))}
                </Select>
              </Form.Item>
            </FloatLabelContainer>
          )}
        </Form.Item>
      </Col>

      {/* Key dimension field */}
      <Col span={6}>
        <Form.Item dependencies={['dimension']} noStyle>
          {({ getFieldValue }) => (
            <FloatLabelContainer
              label="Key Dimension"
              floating={getFieldValue('dimension')}
            >
              <Form.Item
                name="dimension"
                rules={[{ required: true }]}
                style={{ marginBottom: 0 }}
              >
                <Select
                  showSearch
                  showArrow
                  allowClear
                  labelInValue
                  optionFilterProp="children"
                  loading={loading}
                  dropdownMatchSelectWidth={false}
                  style={{ width: '100%' }}
                >
                  {keyDimensionFieldOptions.map(({ groupName, options }) => (
                    <OptGroup key={groupName} label={formatLabel(groupName)}>
                      {options.map((option) => (
                        <Option key={option.name} value={option.name}>
                          {option.displayName || formatLabel(option.name)}
                        </Option>
                      ))}
                    </OptGroup>
                  ))}
                </Select>
              </Form.Item>
            </FloatLabelContainer>
          )}
        </Form.Item>
      </Col>
    </>
  );
};

export default DriverAnalysisConfigs;
