/** @jsxImportSource @emotion/react */
import { createContext, useMemo } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import queryString from 'query-string';
import { useAuth } from '@otso/auth-wrapper';

import { Space, Popover, Button, Tooltip, Typography } from 'antd';
import { LinkOutlined } from '@ant-design/icons';

import { useGetApi, arrayToObject, useCheckPermission } from 'modules';
import { SubHeader } from 'components';
import Drilldown from '../components/Drilldown';

export const ProjectContext = createContext({});

export const ProjectProvider = ({ children }) => {
  const { slug: projectSlug } = useParams();
  const { url } = useRouteMatch();

  const { user } = useAuth();

  const {
    data: project = {},
    setData: setProject,
    loading: projectLoading,
  } = useGetApi(`projects/${projectSlug}`, { slug: projectSlug });

  const {
    data: projectDatasets = [],
    setData: setProjectDatasets,
    loading: projectDatasetsLoading,
  } = useGetApi(`projects/${projectSlug}/datasets`, []);

  const copyProjectLink = () => {
    copy(
      queryString.stringifyUrl({
        url: window.location.href,
        query: {
          ...queryString.parseUrl(window.location.href).query,
          org: user?.currentOrg,
        },
      })
    );
  };

  // Get projectDatasetsById
  const projectDatasetsById = useMemo(
    () => arrayToObject(projectDatasets),
    [projectDatasets]
  );

  const canAccessSettings = useCheckPermission(['insight.manageProjects']);
  const canAccessExplorePage = useCheckPermission(['insight.viewDocuments']);
  const canAccessLocationPage = useCheckPermission([
    'insight.viewLocationPage',
  ]);
  const canManageAlerts = useCheckPermission(['insight.manageAlerts']);

  const projectMenuRoutes = useMemo(() => {
    return [
      { title: 'Dashboards', path: `${url}/dashboards` },
      ...(canAccessExplorePage
        ? [{ title: 'Explore', path: `${url}/explore` }]
        : []),
      ...(process.env.REACT_APP_SHOW_INSIGHT_STUDIO === 'true'
        ? [{ title: 'Insight Studio', path: `${url}/insight-studio` }]
        : []),
      ...(process.env.REACT_APP_SHOW_LOCATION_PAGE === 'true' &&
      canAccessLocationPage
        ? [{ title: 'Location', path: `${url}/location` }]
        : []),
      ...(process.env.REACT_APP_SHOW_ALERTS === 'true' && canManageAlerts
        ? [{ title: 'Alerts', path: `${url}/alerts` }]
        : []),
      ...(canAccessSettings
        ? [{ title: 'Settings', path: `${url}/settings` }]
        : []),
    ];
  }, [
    url,
    canAccessSettings,
    canAccessExplorePage,
    canManageAlerts,
    canAccessLocationPage,
  ]);

  return (
    <div>
      <SubHeader
        title={
          <Space>
            <Typography.Text strong css={{ fontSize: '1rem' }}>
              {project.name || 'Project'}
            </Typography.Text>
            <Popover content="Link copied to clipboard" trigger="click">
              <Tooltip title="Copy project link">
                <Button
                  size="small"
                  type="link"
                  icon={<LinkOutlined />}
                  onClick={copyProjectLink}
                />
              </Tooltip>
            </Popover>
          </Space>
        }
        routes={projectMenuRoutes}
      />
      <ProjectContext.Provider
        value={{
          project: {
            ...project,
            datasets: projectDatasets,
            projectDatasetsById,
          },
          setProject,
          setProjectDatasets,
          loading: projectLoading || projectDatasetsLoading,
        }}
      >
        {children}
        <Drilldown projectDatasets={projectDatasets} />
      </ProjectContext.Provider>
    </div>
  );
};
