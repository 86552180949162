/** @jsxImportSource @emotion/react */
import { colors, preset } from 'styles';
import deepGet from '@modules/deepGet';

const RichTextWidget = ({ height, data }) => {
  const html = deepGet(data[0], ['html']);

  return (
    <div
      css={{
        ...(height
          ? {
              height,
              overflowY: 'auto',
              paddingRight: preset.spacing(3),
            }
          : {}),
      }}
    >
      <div
        dangerouslySetInnerHTML={{ __html: html }}
        css={{
          'p, ol, ul, pre, h1, h2, h3, h4, h5, h6': {
            marginTop: 0,
            marginBottom: 0,
          },
          blockquote: {
            borderLeft: '4px solid #ccc',
            marginTop: preset.spacing(1),
            marginBottom: preset.spacing(1),
            paddingLeft: preset.spacing(2),
          },
          img: {
            maxWidth: '100%',
          },
          a: {
            textDecoration: 'underline',
            color: colors.primary,
          },
        }}
      />
    </div>
  );
};

export default RichTextWidget;
