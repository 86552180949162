/** @jsxImportSource @emotion/react */
import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import { preset } from 'styles';
import getDocumentMediaURL from '@modules/getDocumentMediaURL';

import KeyDocumentInfo from './KeyDocumentInfo';
import DocumentBody from './DocumentBody';
import DocumentMedia from './DocumentMedia';
import DialogueDocumentRatings from './DialogueDocumentRatings';
import DocumentThread from './DocumentThread';
import CustomClassifiersInfo from './CustomClassifiersInfo';
import DocumentAttachmentInfo from './DocumentAttachmentInfo';

const DocumentExpanded = ({
  document,
  dataset,
  datasetSchemaFields,
  appliedFilters,
  renderChildDocumentCollapse,
}) => {
  const {
    agent_other_emotions: agentOtherEmotions,
    agent_primary_emotion: agentPrimaryEmotion,
    agent_sentiment_label: agentSentimentLabel,
    customer_other_emotions: customerOtherEmotions,
    customer_primary_emotion: customerPrimaryEmotion,
    customer_sentiment_label: customerSentimentLabel,
  } = document;

  const { isDialogue, verbatim } = datasetSchemaFields;

  const [entityData, setEntityData] = useState([]);

  // fetch document entities for document body highlight
  useEffect(() => {
    if (document.otso_doc_entities) setEntityData(document.otso_doc_entities);
  }, [document]);

  // Get document media
  const docMedia = useMemo(
    () => getDocumentMediaURL(document, datasetSchemaFields),
    [document, datasetSchemaFields]
  );

  return (
    <Row gutter={[preset.spacing(2), preset.spacing(2)]}>
      <Col span={docMedia ? 18 : 24}>
        <Row gutter={[preset.spacing(2), preset.spacing(2)]}>
          <Col span={12}>
            <KeyDocumentInfo document={document} dataset={dataset} />
          </Col>
          <Col span={12}>
            {isDialogue ? (
              <DialogueDocumentRatings
                agentOtherEmotions={agentOtherEmotions}
                agentPrimaryEmotion={agentPrimaryEmotion}
                agentSentimentLabel={agentSentimentLabel}
                customerOtherEmotions={customerOtherEmotions}
                customerPrimaryEmotion={customerPrimaryEmotion}
                customerSentimentLabel={customerSentimentLabel}
              />
            ) : (
              <CustomClassifiersInfo document={document} dataset={dataset} />
            )}
            {document.attachments?.length > 0 && (
              <DocumentAttachmentInfo attachments={document.attachments} />
            )}
          </Col>
          {(document.otso_doc_body || document[verbatim] || '') && (
            <Col span={24}>
              <DocumentBody
                isDialogue={isDialogue}
                link={
                  datasetSchemaFields.link && document[datasetSchemaFields.link]
                }
                verbatim={document.otso_doc_body || document[verbatim] || ''}
                entities={entityData}
              />
            </Col>
          )}
        </Row>
      </Col>
      {docMedia && (
        <Col span={6}>
          <DocumentMedia
            document={document}
            type={docMedia.type}
            url={docMedia.url}
          />
        </Col>
      )}

      {datasetSchemaFields.hasDocumentThread && (
        <Col span={24}>
          <DocumentThread
            document={document}
            dataset={dataset}
            appliedFilters={appliedFilters}
            renderChildDocumentCollapse={renderChildDocumentCollapse}
          />
        </Col>
      )}
    </Row>
  );
};

DocumentExpanded.propTypes = {
  document: PropTypes.shape({}),
  dataset: PropTypes.shape({
    name: PropTypes.string,
    source: PropTypes.string,
  }),
  datasetSchemaFields: PropTypes.shape({}),
};

DocumentExpanded.defaultProps = {
  document: {},
  datasetSchemaFields: {},
  dataset: {},
};

export default DocumentExpanded;
