const deepGet = (obj: Record<string, any>, properties: string[]): any => {
  // If we have reached an undefined/null property
  // then stop executing and return undefined.
  if (obj === undefined || obj === null) {
    return undefined;
  }

  // If the path array has no more elements, we've reached
  // the intended property and return its value.
  if (properties.length === 0) {
    return obj;
  }

  // Prepare our found property and path array for recursion
  const foundSoFar = obj[properties[0]];
  const remainingProperties = properties.slice(1);

  return deepGet(foundSoFar, remainingProperties);
};

export default deepGet;
