/** @jsxImportSource @emotion/react */
import { Form, Select, Row, Col } from 'antd';

import { preset } from 'styles';

const { Option } = Select;

const ColorBySelect = ({ options = [], value, onChange }) => {
  return (
    <Row gutter={preset.spacing(3)}>
      <Col span={12}>
        <Form.Item
          required
          label="Colour By"
          css={{
            display: 'flex',
            marginTop: preset.spacing(1),
            '.ant-form-item-control-wrapper': {
              flex: 1,
              'label:after': {
                content: '":"',
              },
            },
          }}
        >
          <Select value={value} onChange={(val) => onChange(val)}>
            {options.map((colorByOption) => (
              <Option key={colorByOption.key} value={colorByOption.key}>
                {colorByOption.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default ColorBySelect;
