/** @jsxImportSource @emotion/react */
import { useState, useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import numeral from 'numeral';
import { trackEvent } from 'analytics';

import { Button, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { postApi, fetchApiErrorMessage } from '@modules/api';
import getChartFloatBoxPos from '@modules/chart/getChartFloatBoxPos';
import { convertToURLParams } from '@modules/queryStringConverter';
import getExploreQuery from '@modules/getExploreQuery';

import { getStartAndEndDate, legacyEntityFields } from './helper';
import Spinner from '../Spinner';
import DrilldownCard from './DrilldownCard';

const mapStateToProps = (state) => {
  return {
    drilldown: state.drilldown,
    // dashboard filters
    dashboardName: state.dashboard.name,
  };
};

const Drilldown = ({
  projectDatasets,
  // redux dispatch
  dispatch,
  // redux states
  dashboardName,
  drilldown,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [totalDocuments, setTotalDocuments] = useState();
  const [exploreFilters, setExploreFilters] = useState({
    filters: [],
    datasetIds: [],
    timeFilter: null,
  });

  const { slug: projectSlug } = useParams();
  const location = useLocation();
  const { x, y, chartElement, queryObj, targetData } = drilldown;

  // Init Drilldown
  useEffect(() => {
    resetStates();
    if (targetData && projectDatasets.length > 0) {
      const { dimensions, metrics, filters, timeFilter } = queryObj;

      const targetDataKeys = Object.keys(targetData);

      const chartFilters = [...filters];
      let chartTimeFilter = timeFilter || null;

      setLoading(true);
      setIsOpen(true);

      // dimensions config
      dimensions.forEach((dimension) => {
        if (dimension.as && targetDataKeys.includes(dimension.as)) {
          // Date dimension filter
          if (dimension.functionValue && targetData[dimension.as]) {
            const { from, to } = getStartAndEndDate(
              targetData[dimension.as],
              dimension.functionValue
            );
            if (
              dimension.subtype === 'time' ||
              dimension.attribute === 'otso_doc_publish_date'
            ) {
              chartTimeFilter = { from, to };
            } else {
              const fromFilter = {
                attribute: dimension.attribute,
                condition: '>=',
                value: from,
              };
              const toFilter = {
                attribute: dimension.attribute,
                condition: '<=',
                value: to,
              };
              chartFilters.push(fromFilter);
              chartFilters.push(toFilter);
            }
          } else {
            // Other filter
            const filter = {
              attribute: dimension.attribute || dimension.subtype,
              condition: '=',
              value: targetData[dimension.as],
            };

            if (dimension.attribute === 'City Coordinate') {
              filter.attribute = 'City Name';
              filter.value = targetData[dimension.as].name;
            } else if (dimension.attribute === 'Region Coordinate') {
              filter.attribute = 'Region Name';
              filter.value = targetData[dimension.as].name;
            }

            // if filter value is null, convert filter into 'is null' filter
            if (filter.value === null || filter.value === 'No Data') {
              filter.condition = 'is null';
              filter.value = undefined;
            }

            if (
              dimension.attribute &&
              ['dataset name', 'dataset'].includes(
                dimension.attribute.toLowerCase()
              )
            ) {
              filter.attribute = 'Dataset Name';
              chartFilters.push(filter);
            } else if (
              Array.isArray(filter.value) ||
              typeof filter.value !== 'object'
            ) {
              chartFilters.push(filter);
            }
          }
        }
      });

      // metrics config
      metrics.forEach((metric) => {
        if (
          metric.as &&
          (targetDataKeys.includes(metric.as) ||
            targetData.name === metric.as ||
            targetData.metric === metric.as ||
            (targetData.label === metric.as && dimensions.length === 0)) &&
          metric.filter
        ) {
          chartFilters.push(metric.filter);
        }
      });

      const datasetIds = queryObj.dataset;

      const cleanedFilters = chartFilters.filter(
        (filter) => filter.value !== null
      );

      const newQuery = {
        ...getExploreQuery({
          datasetIds,
          filters: cleanedFilters,
          timeFilter: chartTimeFilter,
          limit: 10,
        }),
        offset: 0,
      };

      // query api
      const fetchDocuments = async () => {
        try {
          const res = await postApi(`projects/${projectSlug}/query`, newQuery);
          const { data, total } = res.data;

          setDocuments(data);

          if (typeof total === 'number') {
            setTotalDocuments(total);
          }

          setLoading(false);
        } catch (error) {
          setLoading(false);
          message.error(fetchApiErrorMessage(error));
        }
      };

      fetchDocuments();

      // Get explore filters
      const transformedQueryFilters = cleanedFilters.map((filter) => ({
        ...filter,
        attribute: legacyEntityFields[filter.attribute] || filter.attribute,
      }));

      setExploreFilters({
        filters: transformedQueryFilters,
        datasetIds,
        timeFilter: chartTimeFilter,
        source: 'drilldown',
      });
    }
  }, [targetData, queryObj, projectDatasets, projectSlug]);

  const resetStates = () => {
    setDocuments([]);
    setTotalDocuments();
    setExploreFilters({ filters: [], datasetIds: [], timeFilter: null });
  };

  const closeDrilldown = () => {
    resetStates();
    dispatch({ type: 'RESET_DRILLDOWN' });
    setIsOpen(false);
  };

  const urlParams = useMemo(
    () => convertToURLParams(exploreFilters),
    [exploreFilters]
  );

  useEffect(() => {
    closeDrilldown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  return (
    isOpen && (
      <div
        css={{
          ...styles.container,
          ...getChartFloatBoxPos(
            // Drilldown position data
            {
              x,
              y,
              w: 240,
              h: 320,
            },
            // Chart element
            chartElement
          ),
        }}
      >
        <div css={styles.header}>
          <span>
            {`${numeral(documents.length).format('0,0')} of ${
              numeral(totalDocuments).format('0,0') || 0
            } results`}
          </span>
          <Button
            shape="circle"
            icon={<CloseOutlined />}
            onClick={closeDrilldown}
          />
        </div>
        <div css={styles.body}>
          {loading || projectDatasets.length === 0 ? (
            <Spinner fullHeight />
          ) : documents.length > 0 ? (
            documents.map((row, index) => (
              <DrilldownCard
                key={`${row.id}_${index + 1}`}
                projectSlug={projectSlug}
                row={row}
                exploreFilters={exploreFilters}
                datasets={projectDatasets}
                onTrack={() => {
                  // Track click-through
                  trackEvent('Drilldown View Single', {
                    docId: row.id,
                    projectSlug,
                  });
                }}
              />
            ))
          ) : (
            <div css={styles.errorBox}>No data found</div>
          )}
        </div>
        <div css={styles.footer}>
          {dashboardName ? (
            <Button
              size="small"
              onClick={() => {
                const { filters, timeFilter } = exploreFilters;
                closeDrilldown();
                dispatch({
                  type: 'LOAD_DASHBOARD_CUSTOM_FILTER',
                  filters,
                  timeFilter,
                });
              }}
            >
              Apply Filter
            </Button>
          ) : (
            <span />
          )}
          <Button
            type="primary"
            size="small"
            href={`/projects/${projectSlug}/explore?${urlParams}`}
            target="_blank"
            onClick={() => {
              // Track click-through
              trackEvent('Drilldown View All', { projectSlug });
            }}
          >
            View All
          </Button>
        </div>
      </div>
    )
  );
};

const styles = {
  container: {
    backgroundColor: '#fff',
    boxShadow:
      'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
    borderRadius: 2,
    backgroundClip: 'padding-box',
  },
  header: {
    backgroundColor: '#fff',
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    borderBottom: 'solid 1px #e8e8e8',
    padding: '0px 8px',

    '.ant-btn': {
      border: 'none',
      marginRight: -8,
    },
  },
  body: {
    height: 'calc(100% - 72px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  footer: {
    height: 36,
    backgroundColor: '#fff',
    borderTop: 'solid 1px #e8e8e8',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 8px',

    '.ant-btn': {
      fontSize: 13,
    },
  },
  errorBox: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default connect(mapStateToProps)(Drilldown);
