/** @jsxImportSource @emotion/react */
import { useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import {
    Chart, Axis, Tooltip, Geom, Coord,
} from 'bizcharts';
import { View } from '@antv/data-set';

import formatDimensionLabel from '@modules/chart/formatDimensionLabel';
import formatMetricLabel from '@modules/chart/formatMetricLabel';
import formatChartData from '@modules/chart/formatChartData';
import useChartRef from '@modules/hooks/useChartRef';
import getChartElement from '@modules/chart/getChartElement';
import deepGet from '@modules/deepGet';

import LegendConfig from '../components/LegendConfig';


const RadarChart = ({
    width,
    height,
    config,
    color,
    queryObj,
    data,
    schema,
    drillDownDisabled = false,
    renderer,
    setChartDownloadInstance,
    // redux dispatch for triggering drilldown
    dispatch,
}) => {
    const {
        x, groups: metricGroups, label: labelConfig,
    } = config;

    // Init chart instance
    const chart = useRef();

    // Re-init chart when width changes and pass chartRef to download modal
    useChartRef(chart, width, setChartDownloadInstance);

    // Remove empty x and insert empty date when needed
    const formattedData = useMemo(() => formatChartData(data, [x]), [data, x]);

    // Config X and Y for data transform
    const metricKey = 'metric';
    const metricValueKey = 'value';
    const transformedY = metricValueKey;

    // Transform data
    const transformedData = useMemo(() => {
        const dv = new View();
        dv.source(formattedData).transform({
            type: 'fold',
            fields: metricGroups,
            key: metricKey,
            value: metricValueKey,
        });

        return dv;
    }, [formattedData, metricGroups]);

    // Config chart scale
    const scale = useMemo(() => {
        return {
            [x]: {
                formatter: val => formatDimensionLabel(val, {
                    timeInterval: deepGet(schema, [x, 'functionValue']),
                    labelConfig,
                }),
            },
            [transformedY]: { formatter: val => formatMetricLabel(val) },
        };
    }, [schema, x, transformedY, labelConfig]);

    // Get radar color
    const radarColor = useMemo(() => ['metric', xKey => color[xKey]], [color]);

    // Collect radar point click data for drilldown
    const onPointClick = ev => {
        if (!drillDownDisabled) {
            const targetData = ev.data ? ev.data._origin : {};

            dispatch({
                type: 'SET_DRILLDOWN',
                drilldown: {
                    x: ev.x,
                    y: ev.y,
                    chartElement: getChartElement(ev, chart.current),
                    targetData,
                    queryObj,
                },
            });
        }
    };

    return (
        <Chart
            height={height}
            data={transformedData}
            scale={scale}
            padding="auto"
            renderer={renderer}
            forceFit
            onPointClick={ev => onPointClick(ev)}
            onGetG2Instance={g2Chart => { chart.current = g2Chart; }}
        >
            <Coord type="polar" radius={0.8} />
            <Axis
                name={x}
                line={null}
                tickLine={null}
                grid={{
                    lineStyle: {
                        lineDash: null,
                    },
                    hideFirstLine: false,
                }}
            />
            <Axis
                name={transformedY}
                line={null}
                tickLine={null}
                grid={{
                    type: 'polygon',
                    lineStyle: {
                        lineDash: null,
                    },
                    alternateColor: 'rgba(0, 0, 0, 0.04)',
                }}
            />
            <LegendConfig config={config} />
            <Tooltip />
            <Geom
                type="area"
                position={`${x}*${transformedY}`}
                color={radarColor}
            />
            <Geom
                type="line"
                position={`${x}*${transformedY}`}
                color={radarColor}
                size={2}
            />
            <Geom
                type="point"
                position={`${x}*${transformedY}`}
                color={radarColor}
                shape="circle"
                size={3}
                style={{
                    cursor: drillDownDisabled ? 'auto' : 'pointer',
                    stroke: '#fff',
                    lineWidth: 1,
                    fillOpacity: 1,
                }}
            />
        </Chart>
    );
};

export default connect()(RadarChart);
