/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { Modal, Input, message } from 'antd';

import { putApi, fetchApiErrorMessage } from '@modules/api';

const mapStateToProps = (state) => {
  return {
    isNameEditModalOpen: state.dashboard.isNameEditModalOpen,
    isDuplicateDashboardModalOpen:
      state.dashboard.isDuplicateDashboardModalOpen,
    dashboardName: state.dashboard.name,
  };
};

const DashboardNameModal = ({
  // redux state
  isNameEditModalOpen,
  isDuplicateDashboardModalOpen,
  dashboardName,
  // redux dispatch
  dispatch,
}) => {
  const { slug: projectSlug, dashboardSlug } = useParams();
  const history = useHistory();

  const [name, setName] = useState();
  const [isSavingDashboardName, setIsSavingDashboardName] = useState(false);

  useEffect(() => {
    setName(
      isDuplicateDashboardModalOpen ? `${dashboardName} - Copy` : dashboardName
    );
  }, [dashboardName, isDuplicateDashboardModalOpen]);

  // Update dashboard name
  const saveDashboardName = async () => {
    setIsSavingDashboardName(true);

    if (isNameEditModalOpen) {
      try {
        await putApi(`projects/${projectSlug}/dashboards/${dashboardSlug}`, {
          dashboard: { name },
        });
        dispatch({ type: 'SET_DASHBOARD_NAME', name });
        message.success('Dashboard Name Saved');
      } catch (error) {
        message.error(
          fetchApiErrorMessage(error, 'Unable to Save Dashboard Name')
        );
      }
      setIsSavingDashboardName(false);
    } else if (isDuplicateDashboardModalOpen) {
      try {
        await putApi(
          `projects/${projectSlug}/dashboards/${dashboardSlug}/duplicate`,
          { name }
        );
        history.push(`/projects/${projectSlug}/dashboards`);
        message.success('New Dashboard Created');
      } catch (error) {
        setIsSavingDashboardName(false);
        message.error(
          fetchApiErrorMessage(error, 'Unable to Duplicate Dashboard')
        );
      }
    }
  };

  return (
    <Modal
      title={isNameEditModalOpen ? 'Edit Dashboad Name' : 'Duplicate Dashboard'}
      visible={isNameEditModalOpen || isDuplicateDashboardModalOpen}
      onCancel={() =>
        dispatch({
          type: 'SET_DASHBOARD_MODAL_OPEN',
          key: isNameEditModalOpen
            ? 'isNameEditModalOpen'
            : 'isDuplicateDashboardModalOpen',
          open: false,
        })
      }
      okText={isNameEditModalOpen ? 'Save' : 'Duplicate'}
      okButtonProps={{
        loading: isSavingDashboardName,
        disabled: !name || !name.trim(),
      }}
      onOk={() => saveDashboardName()}
    >
      <Input
        autoFocus
        placeholder={
          isNameEditModalOpen
            ? 'Enter Dashboard Name'
            : 'Enter New Dashboard Name'
        }
        value={name}
        onChange={(evt) => setName(evt.target.value)}
      />
    </Modal>
  );
};

export default connect(mapStateToProps)(DashboardNameModal);
