const generateChartColor = (
    colorFromData, overrideColors,
) => Object.keys(overrideColors).reduce(
    (prev, key) => (
        (colorFromData[key] && !Object.values(colorFromData).includes(overrideColors[key]))
            ? { ...prev, [key]: overrideColors[key] } : prev
    ), colorFromData,
);

export default generateChartColor;
